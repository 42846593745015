import React, { Component, createRef } from "react";
import PieChart from "../../Chart/PieChart";
import { getVehicleImageUrl } from "../../../utils/utils";
import Avatar from "@material-ui/core/Avatar";
import { IconButton, Tooltip } from "@material-ui/core";
import { getVehicleStatus } from "../../../redux/dashboard/dashboard.action";
import { connect } from "react-redux";
import { withAuth0 } from "@auth0/auth0-react";
import { Skeleton } from "@material-ui/lab";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { CSVLink } from "react-csv";
import vehicleTypeList from "../../../data/vehicle-type-list.json"
import { withTranslation } from 'react-i18next';

const refresh_frequency = process.env.REACT_APP_REFRESH_FREQUENCY_MINUTES * 60000 ?? 600000

class VehicleStatusCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedVehicle: "EKICKSCOOTER",
    }
  }

  componentDidMount() {
    if (!this.props?.vehicleStatus)
      this.refresh();
  }

  refresh = () => {
    this.props.getVehicleStatus();
    setTimeout(() => this.refresh(), refresh_frequency);
  }

  changeVehicle = (vehicleType) => {
    vehicleType === this.state.selectedVehicle ? 
      this.setState({selectedVehicle: ''}) : this.setState({selectedVehicle: vehicleType});
  }

  getSeriesName = () => {
    const selectedVehicleData = vehicleTypeList.find(x => x.label === this.state.selectedVehicle);
    if (selectedVehicleData) {
      return `${selectedVehicleData.title}`
    } else {
      return `All Vehicles`;
    }
  }

  render() {
    const {vehicleStatus, t} = this.props;
    const {selectedVehicle} = this.state;
    const statusList = [
      {label: 'LOST', name: t('vehicle-status.lost')},
      {label: 'AVAILABLE', name: t('vehicle-status.available')},
      {label: 'RUNNING', name: t('vehicle-status.running')},
      {label: 'MAINTENANCE_PENDING', name: t('vehicle-status.maintaining-pending')},
      {label: 'MAINTAINING', name: t('vehicle-status.maintaining')},
      {label: 'DAMAGED', name: t('vehicle-status.damaged')},
    ]

    if (!vehicleStatus) {
      return (<div className="col-xl col-lg"><Skeleton variant="rect" width={"100%"} height={500}/></div>);
    }

    const labelOptions = {
        show: true,
        position: 'outside',
        formatter: (params) => {
            return params.value > 0 ? `${params.name}: ${params.value} (${params.percent}%)` : ''
        },
        textStyle: {
          fontFamily: 'Urbanist'
        }
    }

    let statusSum = 0;
    const chartData = statusList.reduce((acc, curr, index) => {
      let statusValue = 0;
      let statusName = curr.name;
      if (selectedVehicle) {
        statusValue = (vehicleStatus[selectedVehicle]&&vehicleStatus[selectedVehicle][curr.label]) ? vehicleStatus[selectedVehicle][curr.label] : 0;
      } else {
        if (curr.label === 'REPORTED') statusName = 'Reported';
        if (curr.label === 'REPAIRING') statusName = 'Repairing';
        for (const [_, value] of Object.entries(vehicleStatus)) {
          statusValue = statusValue + (value[curr.label] || 0);
        }
      }
      acc.push({value: statusValue, name: statusName});
      statusSum = statusSum + acc[index].value;
      return acc;
    }, []);


    const reportData = chartData.reduce((acc, curr, index) => {
      acc.push({
        Vehicle: this.getSeriesName(),
        Status: curr.name,
        Value: curr.value,
        Percentage: `${((curr.value * 100) / statusSum).toFixed(2)}%`,
      });
      return acc;
    }, [])

    return (
      <div className="col-xl col-lg">
        <div className="card shadow mb-4" id="vehicle-status-card">
            <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
              <h6 className="m-0 font-weight-bold" style={{color: '#333399'}}>{t('vehicle-status.title')}</h6>
              <div>
                {vehicleTypeList.map((vehicle, index) => (
                  <Tooltip title={vehicle.title} key={index}>
                    <IconButton
                      aria-label={vehicle.label}
                      size="small"
                      onClick={() => {this.changeVehicle(vehicle.label)}}
                    >
                      <Avatar
                        aria-label="recipe"
                        alt="Vehicle Photo"
                        className={this.state.selectedVehicle === vehicle.label ? "chartIconAvatar" : ""}
                        src={getVehicleImageUrl(vehicle.icon)}
                        style={{padding: 0}}
                      >
                        {vehicle.title}
                      </Avatar>
                    </IconButton>
                  </Tooltip>
                ))}
                <CSVLink data={reportData} filename={"Vehicle Status.csv"}>
                  <Tooltip title="Download">
                    <IconButton
                      size="small"
                      aria-label="Download"
                      style={{ padding: 10 }}
                    >
                      <FontAwesomeIcon icon={faDownload} />
                    </IconButton>
                  </Tooltip>
                </CSVLink>
              </div>
            </div>

            <div className="card-body">
              <PieChart
                seriesName={`${this.getSeriesName()} Status:`}
                label={chartData.map(x => x.name)}
                labelOptions={labelOptions}
                showData={chartData}
              />
            </div>
          </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  vehicleStatus: state.dashboardReducer.vehicleStatus,
});

const mapDispatchToProps = (dispatch) => ({
  getVehicleStatus: () => dispatch(getVehicleStatus())
});

export default connect(mapStateToProps, mapDispatchToProps)(withAuth0(withTranslation()(VehicleStatusCard)));
