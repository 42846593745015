import React, { useState } from "react";
import moment from "moment";
import { Tooltip } from "@material-ui/core";
import { Grid, CardContent } from "@material-ui/core";
import { Button, Dialog, DialogActions, DialogTitle } from "@material-ui/core";
import {Link} from "react-router-dom";
import axios from "axios";
import { grey } from "@material-ui/core/colors";
import {IconButton } from "@material-ui/core";
import ListAltIcon from '@material-ui/icons/ListAlt';
import Table from "react-bootstrap/Table";

import {zendeskConfig} from "../../../auth/zendesk-auth";

const datetime_format = process.env.REACT_APP_DATETIME_FORMAT;

export default function TicketDetails({ uuid }) {
  const [openModal, setopenModal] = useState(false);
  const [ticketCommentList, setTicketCommentList] = useState([]);
  const host = process.env.REACT_APP_ZENDESK_URL;
  const getTicketCommentList = () => {
    return axios
      .get(`${host}/tickets/${uuid}/comments`, zendeskConfig())
      .then((response) => {
        const ticketCommentList = response.data.comments;
        setTicketCommentList(ticketCommentList);
        setopenModal(true);
      })
      .catch((e) => alert(e))
      .finally(() => {});
  };

  return (
    <React.Fragment>
      <Tooltip title="User List">
        <IconButton
          aria-label="User List"
          onClick={() => {
            getTicketCommentList();
          }}
        >
          <ListAltIcon />
        </IconButton>
      </Tooltip>
      <Dialog
        open={openModal}
        aria-labelledby="User List Modal"
        fullWidth={true}
      >
        <DialogTitle
          id="User List Modal"
          style={{
            color: "white",
            backgroundColor: "#333399",
            textAlign: "center",
          }}
        >
          <label>Ticket Comments Details</label>
        </DialogTitle>
        <Grid container spacing={2}>
          <Grid item sm={12} xs={12} md={12} lg={12}>
            <CardContent style={{ paddingBottom: "0px" }}>
              <Table striped bordered hover size="sm">
                <thead>
                  <tr>
                    <th className="text-center">Time</th>
                    <th className="text-center">Comments</th>
                    <th className="text-center">From</th>
                    <th className="text-center">To</th>
                  </tr>
                </thead>
                <tbody>
                  {ticketCommentList?.map((option) => (
                    <tr>
                      <td className="text-center">{moment.utc(option?.created_at).local().format(datetime_format)}</td>
                      <td className="text-center">{option?.plain_body}</td>
                      <td className="text-center">{option?.via.source.from.name}</td>
                      <td className="text-center">{option?.via.source.to.name}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </CardContent>
          </Grid>
        </Grid>

        <DialogActions
          style={{
            justifyContent: "flex-end",
            padding: "10px 40px 15px 40px",
          }}
        >
          <Button
            style={{ color: grey[500] }}
            onClick={() => {
              setopenModal(false);
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
