import React, {Component} from 'react'
import {faClipboardList} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {connect} from "react-redux";
import {withAuth0} from "@auth0/auth0-react";
import {Skeleton} from "@material-ui/lab";
import {withTranslation} from 'react-i18next';
import {getFinishedTasksToday, getUnfinishedTasks} from "../../../redux/task/task.action";

const refresh_frequency = process.env.REACT_APP_REFRESH_FREQUENCY_MINUTES * 60000 ?? 600000

class DailyTaskCard extends Component {

  componentDidMount() {
    if (this.props?.unfinishedTasks === null || this.props?.unfinishedTasks === undefined)
      this.refresh();
  }

  refresh = () => {
    const {getUnfinishedTasks, getFinishedTasksToday} = this.props;
    getUnfinishedTasks();
    getFinishedTasksToday();
    setTimeout(() => this.refresh(), refresh_frequency);
  }

  render() {
    const {unfinishedTasks, t, finishedTasksToday} = this.props;

    if (!unfinishedTasks) {
      return (<div className="col-xl col-md mb-4"><Skeleton variant="rect" width={"100%"} height={"100%"}/></div>);
    }

    const completedTaskCount = finishedTasksToday?.content?.length;
    const dailyTasks = completedTaskCount === 0 ? 0 : completedTaskCount / (unfinishedTasks.content?.length + completedTaskCount);

    return (
      <div className="col-xl col-md mb-4">
        <div className="card border-left-info shadow h-100 py-2">
          <div className="card-body">
            <div className="row no-gutters align-items-center">
              <div className="col mr-2">
                <div className="text-xs font-weight-bold text-info text-uppercase mb-1">{t('small-cards.tasks')}</div>
                <div className="row no-gutters align-items-center">
                  <div className="col-auto">
                    <div
                      className="h5 mb-0 mr-3 font-weight-bold text-gray-800">{dailyTasks ? (dailyTasks*100).toFixed(2) : 0}%
                    </div>
                  </div>
                  <div className="col">
                    <div className="progress progress-sm mr-2">
                      <div className="progress-bar bg-info" role="progressbar" style={{width: `${dailyTasks*100}%`}}
                           aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"/>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-auto">
                <FontAwesomeIcon icon={faClipboardList} className="fa-2x text-gray-300"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    )

  }
}

const mapStateToProps = (state) => ({
  unfinishedTasks: state.taskData.unfinishedTasks,
  finishedTasksToday: state.taskData.finishedTasksToday,
});

const mapDispatchToProps = (dispatch) => ({
  getUnfinishedTasks: (params) => dispatch(getUnfinishedTasks(params)),
  getFinishedTasksToday: (params) => dispatch(getFinishedTasksToday(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withAuth0(withTranslation()(DailyTaskCard)));
