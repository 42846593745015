import React from "react";
import {Popup} from "react-leaflet";
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import {determineVehicleImage} from "../../../utils/utils";
import {Link} from "react-router-dom";


class VehiclePopup extends React.Component {
  static propTypes = {
    vehicle: PropTypes.object.isRequired
  };

  render() {
    const { vehicle } = this.props;

    let img = "";
    if (vehicle?.graphUrl) {
      img = vehicle.graphUrl;
    }else {
      img = determineVehicleImage(vehicle.category)
    }

    return (
      <Popup >
        <Card style={{maxWidth: 445, width:"100%", minWidth:"250px"}}>
            <CardMedia
              key={`vehicle-map-marker-card-media-${vehicle.uuid}`}
              className={ vehicle.category == 'EBIKE' || vehicle.category == 'BIKE' || vehicle.category == 'ECAR' ? "bikeCarImage" : "EscooterEmopedImage" }
              image={img}
            />
            <CardContent>
              <Typography variant="body2" color="textSecondary" component="p">
                <b>Frame No.:</b> <Link to={`/vehicle/${vehicle?.uuid}`} activeClassName="current">{vehicle?.frameNo}</Link><br/>
                <b>QR Code:</b> {vehicle?.qrCode}<br/>
                <b>Status:</b> {vehicle?.status}<br/>
                <b>Battery Level:</b> {vehicle?.batteryLevel ? `${vehicle?.batteryLevel}%` : ""}<br/>
                <b>Region:</b> {vehicle?.regionName ? `${vehicle?.regionName}` : ""}<br/>
              </Typography>
            </CardContent>
        </Card>
      </Popup>
    );
  }
}

export default VehiclePopup;

