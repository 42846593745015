import React, {Component} from "react";
import MUIDataTable from "mui-datatables";
import {withAuth0} from "@auth0/auth0-react";
import {Button, Grid, IconButton, Tooltip} from "@material-ui/core";
import {connect} from "react-redux";
import {search} from "../../../../redux/TaskRule/taskRule.action";
import {queryParams} from "../../../../utils/utils";
import Delete from "../../../../components/common/Delete";
import SearchIcon from "@material-ui/icons/Search";
import EditIcon from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/Add";

class TaskRuleList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openPopUp: false,
    };
    this.isFilterDialogOpen = false;
    this.tableRef = React.createRef();
    this.columnsDisplay = undefined;
  }

  componentDidMount() {
    this.refresh();
  }

  refresh = () => {
    const { search } = this.props;

    // table state
    const state = this.tableRef?.current?.state;

    const params = queryParams(this.props, state);

    search(params);
  };

  handleModalCloseChange = () => {
    this.setState({
      openPopUp: false,
    });
  };

  onChangeColumnDisplay = () => {
    const state = this.tableRef?.current?.state;
    this.columnsDisplay = state.columns.reduce(
      (obj, item) => Object.assign(obj, { [item.name]: item.display }),
      {}
    );
  };

  isDisplay = (name) => {
    if (!this.columnsDisplay || !this.columnsDisplay.hasOwnProperty(name))
      return undefined;
    else return this.columnsDisplay[name];
  };

  render() {
    const { taskRules } = this.props;
    if (!taskRules) return <React.Fragment>Loading...</React.Fragment>;

    const list = taskRules?.content;
    const totalElements = taskRules?.totalElements ?? 0;
    const rowsPerPage = taskRules?.pageable?.pageSize ?? 10;
    const page = taskRules?.pageable?.pageNumber ?? 0;

    const columns = [
      {
        name: "uuid",
        label: "UUID",
        options: {
          filter: true,
          sort: false,
          display: this.isDisplay("uuid") ?? false,
          customFilterListOptions: { render: (v) => `UUID: ${v}` },
          filterType: "textField",
        },
      },

      {
        name: "name",
        label: "Name",
        options: {
          filter: true,
          sort: true,
          display: this.isDisplay("name") ?? true,
        },
      },
      {
        name: "description",
        label: "Description",
        options: {
          filter: true,
          sort: true,
          display: this.isDisplay("description") ?? true,
        },
      },
      {
        name: "type",
        label: "Type",
        options: {
          filter: true,
          sort: true,
          display: this.isDisplay("type") ?? true
        },
      },
      {
        name: "parameter",
        label: "Parameter",
        options: {
          filter: true,
          sort: true,
          display: this.isDisplay("parameter") ?? true
        },
      },
      {
        name: "status",
        label: "Status",
        options: {
          filter: true,
          sort: true,
          display: this.isDisplay("status") ?? true,
        },
      },
      {
        name: "Operations",
        options: {
          filter: false,
          sort: false,
          empty: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div>
                <Tooltip title="Details">
                  <IconButton
                    aria-label="Details"
                    onClick={() => {
                      const { history } = this.props;
                      const routePath = `/operation-rules/task-rule/${tableMeta.rowData[0]}`;
                      history.push(routePath);
                    }}
                  >
                    <SearchIcon />
                  </IconButton>
                </Tooltip>
                <Delete
                  url={`/taskRule/${tableMeta.rowData[0]}`}
                  callback={() => this.refresh()}
                />
              </div>
            );
          },
        },
      },
    ];

    const options = {
      search: false,
      filter: false,
      confirmFilters: false,
      serverSide: true,
      filterType: "dropdown",
      responsive: "vertical",
      rowsPerPageOptions: [10, 20, 50, 100],
      rowsPerPage: rowsPerPage,
      count: totalElements,
      page: page,
      fixedHeader: true,
      tableBodyHeight: "900px",
      onTableChange: (action, tableState) => {
        switch (action) {
          case "viewColumnsChange":
            this.onChangeColumnDisplay();
            break;
          case "onFilterDialogOpen":
            this.isFilterDialogOpen = true;
            break;
          case "filterChange":
            if (this.isFilterDialogOpen) return;
            else this.refresh();
            break;
          case "onFilterDialogClose":
            this.isFilterDialogOpen = false;
          case "changePage":
          case "sort":
          case "changeRowsPerPage":
            this.refresh();
            break;
          case "propsUpdate":
            break;
          default:
            console.log("Not handled action " + action);
        }
      },
      customFilterDialogFooter: (currentFilterList, applyNewFilters) => {
        return (
          <div style={{ marginTop: "40px" }}>
            <Button
              variant="contained"
              style={{ color: "white", backgroundColor: "#36b9cc" }}
              onClick={() => {
                applyNewFilters();
              }}
            >
              Apply
            </Button>
          </div>
        );
      },
      customToolbar: () => {
        return (
          <Tooltip title="Create New Task Rule">
            <IconButton
                title="Add"
                variant="contained"
                color="primary"
                style={{ float: "right" }}
                onClick={() => {
                  const { history } = this.props;
                  const routePath = `/operation-rules/new-task-rule`;
                  history.push(routePath);
                }}
              >
                <AddIcon/>
            </IconButton>
          </Tooltip>
        )
      }
    };

    return (
      <React.Fragment>
        <MUIDataTable
          title={<h1 className="tableTitle">Task Rule List</h1>}
          data={list}
          columns={columns}
          options={options}
          ref={this.tableRef}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  taskRules: state.taskRuleReducer.taskRules,
});

const mapDispatchToProps = (dispatch) => ({
  search: (params) => dispatch(search(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withAuth0(TaskRuleList));
