import XLSX from "xlsx";

import healthcareIcon from '../images/healthcare.png'
import politicalIcon from '../images/political-science.png'
import foodIcon from '../images/restaurant.png'
import natureIcon from '../images/tree.png'
import financeIcon from '../images/profits.png'
import questionIcon from '../images/question.png'

export const determinePlaceImage = (category) => {
  if (!category)
    return undefined;
  if (category === "HEALTH") {
    return healthcareIcon;
  }else if (category === "NATURAL_FEATURE") {
    return natureIcon;
  }else if (category === "FINANCE") {
    return financeIcon;
  }else if (category === "POLITICAL") {
    return politicalIcon;
  }else if (category === "FOOD") {
    return foodIcon;
  }else {
    return questionIcon;
  }
}

const normalIcon = {
  EBIKE: "/icon/available-ebike.svg",
  EKICKSCOOTER: "/icon/available-kickscooter.svg",
  EMOPED: "/icon/available-moped.svg",
  BIKE: "/icon/available-bike.svg",
}

const damagedIcon = {
  EBIKE: "/icon/damaged-ebike.svg",
  EKICKSCOOTER: "/icon/damaged-kickscooter.svg",
  EMOPED: "/icon/damaged-moped.svg",
  BIKE: "/icon/damaged-bike.svg",
}

const lostIcon = {
  EBIKE: "/icon/lost-ebike.svg",
  EKICKSCOOTER: "/icon/lost-kickscooter.svg",
  EMOPED: "/icon/lost-moped.svg",
  BIKE: "/icon/lost-bike.svg",
}

const lowBatteryIcon = {
  EBIKE: "/icon/low-battery-ebike.svg",
  EKICKSCOOTER: "/icon/low-battery-kickscooter.svg",
  EMOPED: "/icon/low-battery-moped.svg",
  BIKE: "/icon/low-battery-bike.svg",
}

const maintenancePendingIcon = {
  EBIKE: "/icon/maintenance-pending-bike.svg",
  EKICKSCOOTER: "/icon/maintenance-pending-kickscooter.svg",
  EMOPED: "/icon/maintenance-pending-moped.svg",
  BIKE: "/icon/maintenance-pending-bike.svg",
}

const maintainingIcon = {
  EBIKE: "/icon/maintaining-ebike.svg",
  EKICKSCOOTER: "/icon/maintaining-kickscooter.svg",
  EMOPED: "/icon/maintaining-moped.svg",
  BIKE: "/icon/maintaining-bike.svg",
}

const reportedIcon = {
  EBIKE: "/icon/reported-ebike.svg",
  EKICKSCOOTER: "/icon/reported-kickscooter.svg",
  EMOPED: "/icon/reported-moped.svg",
  BIKE: "/icon/reported-bike.svg",
}

const reservedIcon = {
  EBIKE: "/icon/reserved-ebike.svg",
  EKICKSCOOTER: "/icon/reserved-kickscooter.svg",
  EMOPED: "/icon/reserved-moped.svg",
  BIKE: "/icon/reserved-bike.svg",
}

const runningIcon = {
  EBIKE: "/icon/running-ebike.svg",
  EKICKSCOOTER: "/icon/running-kickscooter.svg",
  EMOPED: "/icon/running-moped.svg",
  BIKE: "/icon/running-bike.svg",
}

const feedbackIcon = {
  HAPPY: "/icon/ic_happy.png",
  NEUTRAL: "/icon/ic_neutral.png",
  SAD: "/icon/ic_sad.png",
}

export const chatClientObj = {
  loaded: false
}

export const determineVehicleIconUrlonStatus = (vehicle) => {
  return vehicle?.status === 'AVAILABLE' ? normalIcon[`${vehicle?.category}`]
        :vehicle?.status === 'RUNNING' ? runningIcon[`${vehicle?.category}`]
        :vehicle?.status === 'RESERVED' ? reservedIcon[`${vehicle?.category}`]
        :vehicle?.status === 'REPORTED' ? reportedIcon[`${vehicle?.category}`]
        :vehicle?.status === 'MAINTAINING' ? maintainingIcon[`${vehicle?.category}`]
        :vehicle?.status === 'MAINTENANCE_PENDING' ? maintenancePendingIcon[`${vehicle?.category}`]
        :vehicle?.status === 'DAMAGED' ? damagedIcon[`${vehicle?.category}`]
        :vehicle?.status === 'LOST' ? lostIcon[`${vehicle?.category}`]
        :lowBatteryIcon;  
}


export const getVehicleImageUrl = (category) => {
    if (category === "BIKE") {
        return "/img/bike.png";
    } else if (category === "EBIKE") {
        return "/img/EBike.png";
    } else if (category === "EKICKSCOOTER") {
        return "/img/E-Kickscooter.png";
    } else if (category === "EMOPED") {
        return "/img/emoped.png";
    } else if (category === "ECAR") {
        return "/img/Ecar.png";
    } else if (category === "BikeIcon") {
        return "/img/BikeIcon.png";
    } else if (category === "EBikeIcon") {
        return "/img/EBikeIcon.png";
    } else if (category === "EKickscooterIcon") {
        return "/img/E-KickscooterIcon.png";
    } else if (category === "EMopedICON") {
        return "/img/emopedIcon.png";
    } else if (category === "ECarIcon") {
        return "/img/EcarIcon.png";
    } else {
        return "/img/bike.png";

    }

}

export const determineVehicleImage = (category) => {
  if (!category)
    return undefined;
  if (category === "BIKE") {
    return "/img/bike.png";
  }else if (category === "EBIKE") {
    return "/img/EBike.png";
  }else if (category === "EKICKSCOOTER") {
    return "/img/E-Kickscooter.png";
  }else if (category === "EMOPED") {
    return "/img/emoped.png";
  }else if (category === "ECAR") {
    return "/img/ECar.png";
  }else {
    return undefined;
  }
}

export const determineVehicleIcon = (category) => {
  if (!category)
    return undefined;
  if (category === "BIKE") {
    return "/img/BikeIcon.png";
  }else if (category === "EBIKE") {
    return "/img/EBikeIcon.png";
  }else if (category === "EKICKSCOOTER") {
    return  "/img/E-KickscooterIcon.png";
  }else if (category === "EMOPED") {
    return "/img/emopedIcon.png";
  }else if (category === "ECAR") {
    return "/img/EcarIcon.png";
  }else {
    return "/img/BikeIcon.png";
  }
}

export const sub = (s, len = 15) => {
    if (!s || s.length < len) 
        return s;
    

    return s.substring(0, len - 3) + "...";
}

export const groupBy = (objectArray, property) => {
  return objectArray?.reduce(function (acc, obj) {
    const key = obj[property];
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(obj);
    return acc;
  }, {});
}



export const vehiclesGroupByStatus = (objectArray, property) => {
  return objectArray ?. reduce(function (acc, obj) {
      const status = obj[property];
      let key = ""
      // Bike
      if (status === 'AVAILABLE' && obj['category'] === 'BIKE') {
          key = 'Available Bike'
      }
      if ((status === 'RUNNING' || status === 'RESERVED') && obj['category'] === 'BIKE') {
          key = 'Bike in Use'
      }
      if ((status === 'MAINTAINING' || status === 'MAINTENANCE_PENDING') && obj['category'] === 'BIKE') {
          key = 'Bike Under Maintenance'
      }
      if (status === 'REPORTED' && obj['category'] === 'BIKE') {
          key = 'Bike Reported'
      }
      // end

      // EBIKE
      if (status === 'AVAILABLE' && obj['category'] === 'EBIKE') {
          key = 'Available E-Bike'
      }
      if ((status === 'RUNNING' || status === 'RESERVED') && obj['category'] === 'EBIKE') {
          key = 'E-Bike in Use'
      }
      if ((status === 'MAINTAINING' || status === 'MAINTENANCE_PENDING') && obj['category'] === 'EBIKE') {
          key = 'E-Bike Under Maintenance'
      }
      if (status === 'REPORTED' && obj['category'] === 'EBIKE') {
          key = 'E-Bike Reported'
      }
      // end

      // EKICKSCOOTER
      if (status === 'AVAILABLE' && obj['category'] === 'EKICKSCOOTER') {
          key = 'Available E-Kickscooter'
      }
      if ((status === 'RUNNING' || status === 'RESERVED') && obj['category'] === 'EKICKSCOOTER') {
          key = 'E-Kickscooter in Use'
      }
      if ((status === 'MAINTAINING' || status === 'MAINTENANCE_PENDING') && obj['category'] === 'EKICKSCOOTER') {
          key = 'E-Kickscooter Under Maintenance'
      }
      if (status === 'REPORTED' && obj['category'] === 'EKICKSCOOTER') {
          key = 'E-Kickscooter Reported'
      }
      // end

      // EMOPED
      if (status === 'AVAILABLE' && obj['category'] === 'EMOPED') {
          key = 'Available E-Moped'
      }
      if ((status === 'RUNNING' || status === 'RESERVED') && obj['category'] === 'EMOPED') {
          key = 'E-Moped in Use'
      }
      if ((status === 'MAINTAINING' || status === 'MAINTENANCE_PENDING') && obj['category'] === 'EMOPED') {
          key = 'E-Moped Under Maintenance'
      }
      if (status === 'REPORTED' && obj['category'] === 'EMOPED') {
          key = 'E-Moped Reported'
      }
      // end
      // EMOPED
      if (status === 'AVAILABLE' && obj['category'] === 'ECAR') {
          key = 'Available E-Car'
      }
      if ((status === 'RUNNING' || status === 'RESERVED') && obj['category'] === 'ECAR') {
          key = 'E-Car in Use'
      }
      if ((status === 'MAINTAINING' || status === 'MAINTENANCE_PENDING') && obj['category'] === 'ECAR') {
          key = 'E-Car Under Maintenance'
      }
      if (status === 'REPORTED' && obj['category'] === 'ECAR') {
          key = 'E-Car Reported'
      }
      // end

      if (! acc[key]) {
          acc[key] = [];
      }
      if (key) {
          acc[key].push(obj);
      }

      return acc;
  }, {});
}

export const determineFeedbackIcon = (score) => {
  if (isNaN(score))
    return undefined;

  if (score > 0 && score <= 3)
    return feedbackIcon.SAD;
  if (score > 3 && score <= 7)
    return feedbackIcon.NEUTRAL;
  else
    return feedbackIcon.HAPPY;
}



export const queryParams = (props, state) => {
  // get query parameters from URL
  let params = props?.location ? new URLSearchParams(props?.location?.search) : new URLSearchParams();

  // pagination
  params.set("page", state?.page ?? 0);
  params.set("size", state?.rowsPerPage ?? 10);

  // sort
  if (state?.sortOrder?.hasOwnProperty('name') && state?.sortOrder?.hasOwnProperty('direction')) {
    params.set("sort", `${state.sortOrder.name},${state.sortOrder.direction}`);
  }else {
    params.set("sort", "id,desc");
  }

  // filter
  if (state && state["filterList"] && state["columns"]) {
    const filterList = state["filterList"];
    const columns = state["columns"];
    filterList?.forEach((data, index) => {
      if (data[0]) {
        const key = columns[index]["name"];
        const value = filterList[index][0];
        params.set(key, value);
      }
    });
  }

  return params;
}


// revenue card dashbord

export const transformDailyDates = (dates) => {
   const today = new Date()
   const month = today.toLocaleString('default', { month: 'short' });
   const showDate = dates.map((date) => {
     return month + '-' +date;
   });
   return showDate;
}


export const transformMonthlyDates = (dates) => {
    const today = new Date()
    const year = today.getFullYear();
    const showDate = dates && dates.map((date) => {
        return date + '-' + year;
    });
    return showDate;
}

export const downloadXlsx = (data, wbName) => {

  const fileName = wbName + '.xlsx';
  const wb = XLSX.utils.book_new();
  wb.Props = {
    Title: wbName,
  };
  Object.keys(data).forEach((key) =>{
    const ws_data = [];
    let showDate = data[key]?.date;
    const dates = data[key]?.date;
    const showData = data[key]?.data;
    if (key === "daily") {
       showDate = transformDailyDates(dates);
    } else if (key === "monthly") {
       showDate = transformMonthlyDates(dates);
    } else {
       showDate = data[key]?.date;
    }
    ws_data.push(showDate);
    ws_data.push(showData);
    wb.SheetNames.push(key);
    const ws = XLSX.utils.aoa_to_sheet(ws_data);
    wb.Sheets[key] = ws;
  })
  XLSX.writeFile(wb, fileName);

}


export const randomString = (() => {
  const gen = (min, max) => max++ && [...Array(max-min)].map((s, i) => String.fromCharCode(min+i));

  const sets = {
      num: gen(48,57),
      alphaLower: gen(97,122),
      alphaUpper: gen(65,90),
      special: [...`~!@#$%^&*()_+-=[]\{}|;:'",./<>?`]
  };

  function* iter(len, set) {
      if (set.length < 1) set = Object.values(sets).flat(); 
      for (let i = 0; i < len; i++) yield set[Math.random() * set.length|0]
  }

  return Object.assign(((len, ...set) => [...iter(len, set.flat())].join('')), sets);
})();

export const isEmpty = (s) => {
  if (s === null || s === undefined)
    return true;
  if ((typeof s) === 'string') {
    return s.length === 0;
  }else {
    return false;
  }
}

export const fullName = (user) => {
  if (!user)
    return "";
  else if (user.firstName && user.lastName)
    return `${user.firstName} ${user.lastName}`;
  else if(user.firstName)
    return user.firstName;
  else
    return user.lastName;
}

const numberText = {
  1:  'One',
  2:  'Two',
  3:  'Three',
  4:  'Four',
  5:  'Five',
  6:  'Six',
  7:  'Seven',
  8:  'Eight',
  9:  'Nine',
  10: 'Ten',
  11: 'Eleven',
  12: 'Twelve',
  13: 'Thirteen',
  14: 'Fourteen',
  15: 'Fifteen',
  16: 'Sixteen',
  17: 'Seventeen',
  18: 'Eighteen',
  19: 'Nineteen',
  20: 'Twenty',
  30: 'Thirty',
  40: 'Forty',
  50: 'Fifty',
  60: 'Sixty',
  70: 'Seventy',
  80: 'Eighty',
  90: 'Ninety',
  100: 'Hundred',
  1000: 'Thousand',
}

export const numberToWord = (number) => {
  if (number === null || number === undefined)
    return number;
  else
    return numberText[number];
}

export const groupByKey = (array, key) => {
  return array
    ?.reduce((hash, obj) => {
      const k = obj[key] === undefined ? "" : obj[key];
      return Object.assign(hash, { [k]:( hash[k] || [] ).concat(obj)})
    }, {})
}