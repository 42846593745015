import {ActionTypes} from "./types";

const INITIAL_STATE = {
  partnerCurrency: undefined,
  partnerSocialMedia: undefined
};

const partnersReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ActionTypes.SET_PARTNER_CURRENCY:
      return {
        ...state,
        partnerCurrency: action.payload,
      };
    case ActionTypes.SET_PARTNER_SOCIAL_MEDIA:
      return {
        ...state,
        partnerSocialMedia: action.payload,
      };
    default:
      return state;
  }
};

export default partnersReducer;