import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import AddIcon from "@material-ui/icons/Add";
import {withStyles} from "@material-ui/core/styles";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from "prop-types";
import axios from "axios";
import {config} from "../../../../auth/auth";
import {CircularProgress, Grid} from "@material-ui/core";
import CardContent from "@material-ui/core/CardContent";
import "./details.css";
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

const defaultToolbarStyles = {
  iconButton: {
  },
};

const host = process.env.REACT_APP_HOST

class Create extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      open: false,
      name: undefined,
      address: undefined,
      contact: undefined,
      verificationCriteria: undefined,
      verificationMethod: undefined
    };
  }

  static propTypes = {
    refresh: PropTypes.func.isRequired
  };

  handleClick = () => {
    this.setState({open: true});
  }

  cancel = () => {
    this.setState({open: false});
  };

  create = () => {
    const { refresh } = this.props;
    if (this.state.isLoading)
      return;
    const body = {
      businessName: this.state.name,
      businessAddress: this.state.address,
      businessContact: this.state.contact,
      verificationCriteria: this.state.verificationCriteria,
      verificationMethod: this.state.verificationMethod
    }
    this.setState({isLoading: true})
    return axios.post(`${host}/business`, body, config())
      .then((response) => {})
      .catch(e => alert(e))
      .finally(() => {
        this.setState({isLoading: false, open: false});
        refresh();
      });
  };


  onChangeName = (e) => {this.setState({name: e.target.value})};

  onChangeAddress = (e) => {this.setState({address: e.target.value})};

  onChangeContact = (e) => {this.setState({contact: e.target.value})};

  onChangeVerificationCriteria = (e) => {this.setState({verificationCriteria: e.target.value})};

  onChangeVerificationMethod = (e) => {this.setState({verificationMethod: e.target.value})};

  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <Tooltip title={"Create Business"}>
            <IconButton className={classes.iconButton} onClick={this.handleClick} className="primary">
              <AddIcon className={classes.deleteIcon} />
            </IconButton>
          </Tooltip>
          <Dialog open={this.state.open} onClose={this.cancel} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title" className="primary">
              <h4>Create Business</h4>
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                To create a business, please enter here.
              </DialogContentText>
              <Grid container spacing={2} className="create-container">
                <Grid item sm={12} xs={12} md={12} lg={12}>
                  <CardContent>
                    <Grid container spacing={2} className="formLabel">
                      <Grid item md={12} lg={12}>
                        <TextField
                          id="business-create-name-id"
                          label="Business Name"
                          fullWidth
                          value={this.state.name}
                          onChange={this.onChangeName}
                          InputProps={{
                            readOnly: false,
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} className="formLabel">
                      <Grid item md={12} lg={12}>
                        <TextField
                          id="business-create-address-id"
                          label="Address"
                          fullWidth
                          value={this.state.address}
                          InputProps={{
                            readOnly: false,
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          variant="outlined"
                          onChange={this.onChangeAddress}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} className="formLabel">
                      <Grid item md={12} lg={12}>
                        <TextField
                          id="business-create-contact-id"
                          label="Contact"
                          fullWidth
                          value={this.state.contact}
                          InputProps={{
                            readOnly: false,
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          variant="outlined"
                          onChange={this.onChangeContact}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} className="formLabel">
                      <Grid item md={12} lg={6}>
                        <Select
                          id="business-create-verification-method-id"
                          label="Verification Method"
                          value={this.state.verificationMethod}
                          onChange={this.onChangeVerificationMethod}
                          InputProps={{
                            name: 'Verification Method',
                          }}
                          fullWidth
                          InputLabelProps={{
                            shrink: true,
                          }}
                          variant="outlined"
                        >
                          <MenuItem value="EMAIL">Email</MenuItem>
                          <MenuItem value="PHOTO_ID">Photo ID</MenuItem>
                          <MenuItem value="PHONE_NUMBER">Phone Number</MenuItem>
                        </Select>
                      </Grid>
                      <Grid item md={12} lg={6}>
                        <TextField
                          id="business-create-verification-criteria-id"
                          label="Verification Criteria"
                          fullWidth
                          value={this.state.verificationCriteria}
                          InputProps={{
                            readOnly: false,
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          variant="outlined"
                          onChange={this.onChangeVerificationCriteria}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <React.Fragment>
                {this.state.isLoading ? <CircularProgress style={{width:20, height:20}}/> : ""}
                <Button
                  onClick={this.create}
                  style={{color: "white", backgroundColor: "#36b9cc"}}
                >
                  Create
                </Button>
                <Button
                  onClick={this.cancel}
                  color="primary"
                  disabled={this.state.isLoading}
                >
                  Cancel
                </Button>
              </React.Fragment>
            </DialogActions>
          </Dialog>
        </MuiPickersUtilsProvider>
      </React.Fragment>
    );
  }

}

export default withStyles(defaultToolbarStyles, { name: "Create" })(Create);
