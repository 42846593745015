import React, {Component} from 'react'
import {faQrcode} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
  getFinishedTasksToday,
  getSuccessfulTasksToday,
} from "../../../redux/task/task.action";
import {connect} from "react-redux";
import {withAuth0} from "@auth0/auth0-react";
import {withTranslation} from "react-i18next";
import moment from "moment";

const refresh_frequency = process.env.REACT_APP_REFRESH_FREQUENCY_MINUTES * 60000 ?? 600000

class SuccessfulTaskCard extends Component {

  componentDidMount() {
    this.refresh();
  }

  refresh = () => {
    const {getSuccessfulTasksToday, getFinishedTasksToday} = this.props;
    let params = new URLSearchParams();
    // pagination
    params.set("page", "0");
    params.set("size", "1000");
    getFinishedTasksToday(params);
    params.set("status", "FINISHED");
    params.set("fromDateTime", String(moment().startOf('day').format(moment.HTML5_FMT.DATETIME_LOCAL)));
    getSuccessfulTasksToday(params);
    setTimeout(() => this.refresh(), refresh_frequency);
  }

  render() {
    const {finishedTasksToday, successfulTasksToday} = this.props;
    const finishedTasksCount = finishedTasksToday?.content?.length;
    const succeedTaskCount = successfulTasksToday?.content?.length;

    return (
      <div className="col-xl col-md mb-4">
        <div className="card border-left-warning shadow h-100 py-2">
          <div className="card-body">
            <div className="row no-gutters align-items-center">
              <div className="col mr-2">
                <div className="text-xs font-weight-bold text-warning text-uppercase mb-1">Successful Task Rate</div>
                <div className="row no-gutters align-items-center">
                  <div className="col-auto">
                    <div
                      className="h5 mb-0 mr-3 font-weight-bold text-gray-800">{succeedTaskCount ? ((succeedTaskCount / finishedTasksCount) * 100).toFixed(2) : 0}%
                    </div>
                  </div>
                  <div className="col">
                    <div className="progress progress-sm mr-2">
                      <div className="progress-bar bg-warning" role="progressbar"
                           style={{width: `${(succeedTaskCount / finishedTasksCount) * 100}%`}} aria-valuenow="50"
                           aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-auto">
                <FontAwesomeIcon icon={faQrcode} className="fa-2x text-gray-300"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  finishedTasksToday: state.taskData.finishedTasksToday,
  successfulTasksToday: state.taskData.successfulTasksToday
});

const mapDispatchToProps = (dispatch) => ({
  getSuccessfulTasksToday: (params) => dispatch(getSuccessfulTasksToday(params)),
  getFinishedTasksToday: (params) => dispatch(getFinishedTasksToday(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withAuth0(withTranslation()(SuccessfulTaskCard)));

