import React, {useState, useRef, useEffect} from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Overlay from "react-bootstrap/Overlay";
import Popover from "react-bootstrap/Popover";
import AddPhotoAlternateIcon from "@material-ui/icons/AddPhotoAlternate";
import {Avatar, CardActions, Tooltip} from "@material-ui/core";
import CardMedia from "@material-ui/core/CardMedia";
import IconButton from "@material-ui/core/IconButton";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import {green, red} from "@material-ui/core/colors/";
import CancelIcon from "@material-ui/icons/Cancel";
import {config} from "../../../../auth/auth";
import axios from "axios";

const host = process.env.REACT_APP_HOST
export default function GraphOverlay({value, uuid, refresh}) {
  const [show, setShow] = useState(false);
  const [target, setTarget] = useState(null);
  const ref = useRef(null);

  const handleOnGraphClick = (event) => {
    setShow((prevState) => !prevState);
    setTarget(event.target);
  };

  const markParkingParking = (status) => {
    return axios
      .put(`${host}/order/${uuid}/parkingCheck/${status}`, {}, config())
      .then((response) => {
        refresh();
        setShow((prevState) => !prevState);
      })
      .catch((e) => alert(e))
      .finally(() => {
      });
  }

  return (
    <div ref={ref}>
      <Avatar
        alt="Photo"
        src={value}
        onClick={handleOnGraphClick}
      >
        <AddPhotoAlternateIcon/>
      </Avatar>
      {value && <Overlay
        show={show}
        target={target}
        placement="left"
        rootClose
        onHide={() => setShow((prevState) => !prevState)}
        container={ref.current}
        containerPadding={20}
      >
        <Popover>
          <Popover.Title as="h1">Parking Picture</Popover.Title>
          <Popover.Content>
            <CardMedia
              component="img"
              alt="Parking Picture"
              image={value}
              title="Parking Picture"
              style={{width: "250px"}}
            />
            <CardActions style={{justifyContent: 'center'}}>
              <Tooltip title={"Good Parking"} onClick={() => markParkingParking("GOOD")}>
                <IconButton>
                  <CheckCircleIcon style={{color: green[400]}}/>
                </IconButton>
              </Tooltip>
              <Tooltip title={"Bad Parking"} onClick={() => markParkingParking("BAD")}>
                <IconButton>
                  <CancelIcon style={{color: red[900]}}/>
                </IconButton>
              </Tooltip>
            </CardActions>
          </Popover.Content>
        </Popover>
      </Overlay>}
    </div>
  );
}
