import React, {Component} from "react";
import {withAuth0} from "@auth0/auth0-react";
import {
  Channel,
  ChannelHeader,
  ChannelList,
  ChannelListMessenger,
  ChannelPreviewMessenger,
  Chat,
  InfiniteScrollPaginator,
  MessageCommerce,
  MessageInput,
  MessageInputFlat,
  MessageList,
  Thread,
  Window,
} from "stream-chat-react";
import "stream-chat-react/dist/css/index.css";
import "./LiveChat.css";
import {connect} from "react-redux";

class LiveChat extends Component {
  render() {
    const { liveChatConfig, chatClient } = this.props;
    if (!liveChatConfig || !chatClient) {
      return <React.Fragment>Loading...</React.Fragment>;
    }

    const userId = liveChatConfig?.userId

    const options = { state: true, watch: true, presence: true };
    const filters = { type: "messaging", members: { $in: [userId] } };
    const sort = { last_message_at: -1 };

    const Paginator = (props) => (
      <InfiniteScrollPaginator threshold={300} {...props} />
    );

    const theme = "light";

    return (
      <Chat client={chatClient} theme={`messaging ${theme}`}>
        <ChannelList
          List={ChannelListMessenger}
          Preview={ChannelPreviewMessenger}
          filters={filters}
          sort={sort}
          options={options}
          Paginator={Paginator}
        />
        <Channel>
          <Window>
            <ChannelHeader />
            <MessageList Message={MessageCommerce} />
            <MessageInput Input={MessageInputFlat} focus />
          </Window>
          <Thread />
        </Channel>
      </Chat>
    );
  }
}
const mapStateToProps = (state) => ({
  currentAdminUser: state.appData.currentAdminUser,
  liveChatConfig: state.appData.liveChatConfig,
  chatClient: state.appData.chatClient,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withAuth0(LiveChat));
