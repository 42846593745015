import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AreaChart from "../../Chart/AreaChart";
import Button from "@material-ui/core/Button";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { IconButton, Tooltip } from "@material-ui/core";
import {getNewUserRegistration} from "../../../redux/dashboard/dashboard.action";
import {connect} from "react-redux";
import {withAuth0} from "@auth0/auth0-react";
import {Skeleton} from "@material-ui/lab";
import {
  transformDailyDates,
  transformMonthlyDates,
  downloadXlsx
} from "../../../utils/utils";
import { withTranslation } from 'react-i18next';

class RegisterCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedValue: "monthly"
    }
  }

  componentDidMount() {
    const {newUserRegistration, getNewUserRegistration} = this.props;
    if (!newUserRegistration)
      getNewUserRegistration();
  }

  render() {
    const { newUserRegistration,t} = this.props;
    if (!newUserRegistration) {
      return (<div className="col-xl col-lg"><Skeleton variant="rect" width={"100%"} height={500}/></div >);
    }
    let showDate = newUserRegistration[`${this.state.selectedValue}`]?.date;
    const dates = newUserRegistration[`${this.state.selectedValue}`]?.date;
    if (this.state.selectedValue === "daily") {
      showDate = transformDailyDates(dates);
    }else if (this.state.selectedValue === "monthly") {
      showDate = transformMonthlyDates(dates);
    }  else {
      showDate =  newUserRegistration[`${this.state.selectedValue}`]?.date;
    }
    const showData = newUserRegistration[`${this.state.selectedValue}`]?.data;


    return (
      <div className="col-xl col-lg">
        <div className="card shadow mb-4">
          <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
            <h6 className="m-0 font-weight-bold" style={{color: '#333399'}}>
              {t('new-user-registeration.title')}
            </h6>
            <div>
              <Button
                size="medium"
                style={{ marginRight: "5px" }}
                className={this.state.selectedValue === "daily" ? "btnUnderLine" : ""}
                onClick={() => {
                  this.setState({
                    selectedValue: "daily"
                  })
                }}
              >
                {t('common.tab-day')}
              </Button>
              <Button
                size="medium"
                style={{ marginRight: "5px" }}
                className={
                  this.state.selectedValue === "monthly" ? "btnUnderLine" : ""
                }
                onClick={() => {
                  this.setState({
                    selectedValue: "monthly"
                  })
                }}
              >
                {t('common.tab-month')}
              </Button>
              <Button
                size="medium"
                className={
                  this.state.selectedValue === "annually" ? "btnUnderLine" : ""
                }
                onClick={() => {
                  this.setState({
                    selectedValue: "annually"
                  })
                }}
              >
                {t('common.tab-year')}
              </Button>
              <Tooltip title="Download">
                <IconButton size="small" aria-label="Download" style={{padding: 10}}
                            onClick={() => {
                              downloadXlsx(newUserRegistration, "Users Registered");
                            }}
                >
                  <FontAwesomeIcon icon={faDownload}/>
                </IconButton>
              </Tooltip>
            </div>
          </div>

          <div className="card-body">
            <AreaChart
              date={showDate}
              data={showData}
              label="Users"
              areaStyle={{ color: "#ffe5b3" }}
              lineStyle={{ color: "#ffcc66" }}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  newUserRegistration: state.dashboardReducer.newUserRegistration,
});

const mapDispatchToProps = (dispatch) => ({
  getNewUserRegistration: () => dispatch(getNewUserRegistration())
});

export default connect(mapStateToProps, mapDispatchToProps)(withAuth0(withTranslation()(RegisterCard)));

