import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import {withStyles} from "@material-ui/core/styles";
import PropTypes from "prop-types";

import {MuiPickersUtilsProvider} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import RefreshIcon from '@material-ui/icons/Refresh';

const defaultToolbarStyles = {
  iconButton: {
  },
};

class CustomToolbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      open: false
    };
  }

  static propTypes = {
    refresh: PropTypes.func.isRequired
  };


  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <Tooltip title={"REFRESH"}>
            <IconButton className={classes.iconButton} onClick={this.props.refresh}>
              <RefreshIcon className={classes.deleteIcon} />
            </IconButton>
          </Tooltip>
        </MuiPickersUtilsProvider>
      </React.Fragment>
    );
  }

}

export default withStyles(defaultToolbarStyles, { name: "CustomToolbar" })(CustomToolbar);
