import React, {Component} from 'react'
import {faQrcode} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {getDailyOperationalVehicles} from "../../../redux/dashboard/dashboard.action";
import {connect} from "react-redux";
import {withAuth0} from "@auth0/auth0-react";
import {Skeleton} from "@material-ui/lab";
import { withTranslation } from 'react-i18next';

class DailyOperationVehicleCard extends Component {
  componentDidMount() {
    const {dailyOperationalVehicles, getDailyOperationalVehicles} = this.props;
    if (dailyOperationalVehicles === null || dailyOperationalVehicles === undefined)
      getDailyOperationalVehicles();
  }

  render() {
    const {dailyOperationalVehicles,t} = this.props;
    if (dailyOperationalVehicles === null || dailyOperationalVehicles === undefined) {
      return (<div className="col-xl col-md mb-4"> <Skeleton variant="rect" width={"100%"} height={"100%"} /> </div>);
    }
    return (
      <div className="col-xl col-md mb-4">
          <div className="card border-left-warning shadow h-100 py-2">
            <div className="card-body">
              <div className="row no-gutters align-items-center">
                <div className="col mr-2">
                  <div className="text-xs font-weight-bold text-warning text-uppercase mb-1">{t('small-cards.vehicles')}
                  </div>
                  <div className="h5 mb-0 font-weight-bold text-gray-800">{dailyOperationalVehicles ?? 0}</div>
                </div>
                <div className="col-auto">
                  <FontAwesomeIcon icon={faQrcode} className="fa-2x text-gray-300"/>
                </div>
              </div>
            </div>
          </div>
      </div>
    )
  }
}


const mapStateToProps = (state) => ({
  dailyOperationalVehicles: state.dashboardReducer.dailyOperationalVehicles,
});

const mapDispatchToProps = (dispatch) => ({
  getDailyOperationalVehicles: () => dispatch(getDailyOperationalVehicles())
});

export default connect(mapStateToProps, mapDispatchToProps)(withAuth0(withTranslation()(DailyOperationVehicleCard)));
