import React, {Component} from "react";
import MUIDataTable from "mui-datatables";
import {withAuth0} from "@auth0/auth0-react";
import {
  Button,
  Checkbox,
  FormControl,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Tooltip
} from "@material-ui/core";
import {connect} from "react-redux";
import {search} from "../../../../redux/vehicle/vehicle.action";
import SearchIcon from "@material-ui/icons/Search";
import Battery from "./bettery";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import HighlightOff from "@material-ui/icons/HighlightOff";
import VehicleGraph from "./vehicle-graph";
import {queryParams} from "../../../../utils/utils";
import Popover from "@material-ui/core/Popover";
import VehiclePopMap from "./vehicleMapPop";
import CustomToolbarSelect from "./CustomToolbarSelect";
import moment from "moment";
import {search as getCities} from "../../../../redux/city/action";
import CustomToolbar from "../../../../components/List/CustomToolBar/CustomToolbar";

const date_format = process.env.REACT_APP_DATE_FORMAT;
const datetime_format = process.env.REACT_APP_DATETIME_FORMAT;
const VEHICLE_STATUSES = ["AVAILABLE", "RUNNING", "RESERVED", "REPORTED", "MAINTAINING", "MAINTENANCE_PENDING", "DAMAGED", "LOST", "LOW_BATTERY"];
const VEHICLE_CATEGORIES = ["BIKE", "EBIKE", "EKICKSCOOTER", "EMOPED", "ECAR"];

class VehicleList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openPopover: false,
      anchorEl: null,
    };
    this.isFilterDialogOpen = false;
    this.tableRef = React.createRef();
    this.columnsDisplay = undefined;
    this.selectedVehiclesData = [];
  }

  componentDidMount() {
    this.refresh();
    if (!this.props?.cities) {
      this.props.getCities();
    }
  }

  refresh = () => {
    const {search} = this.props;

    // table state
    const state = this.tableRef?.current?.state;
    const params = queryParams(this.props, state);

    if (state && state["columns"]) {
      const statusIndex = state["columns"].findIndex(s => s.name === 'status');
      const categoryIndex = state["columns"].findIndex(s => s.name === 'category');
      const statuses = state["filterList"][statusIndex];
      const categories = state["filterList"][categoryIndex];
      if (statuses?.length !== 0) {
        params.set("status", statuses);
      }
      if (categories?.length !== 0) {
        params.set("category", categories);
      }
    }
    search(params);
  };

  onChangeColumnDisplay = () => {
    const state = this.tableRef?.current?.state;
    this.columnsDisplay = state.columns.reduce(
      (obj, item) => Object.assign(obj, {[item.name]: item.display}),
      {}
    );
  };

  isDisplay = (name) => {
    if (!this.columnsDisplay || !this.columnsDisplay.hasOwnProperty(name))
      return undefined;
    else return this.columnsDisplay[name];
  };

  // popover events
  handleonLocationClick = (event, index) => {
    this.selectedVehiclesData = [];
    const {vehicles} = this.props;
    const list = vehicles?.content;
    const vehicle = list[index];
    this.selectedVehicleData = vehicle;
    this.setState({
      openPopover: true,
      anchorEl: event.currentTarget,
    });
  };

  handlePopOverClose = () => {
    this.setState({openPopover: false, anchorEl: null});
  };

  render() {
    const {vehicles, cities} = this.props;
    if (!vehicles) return <React.Fragment>Loading...</React.Fragment>;

    const list = vehicles?.content;
    const totalElements = vehicles?.totalElements ?? 0;
    const rowsPerPage = vehicles?.pageable?.pageSize < 251 ? vehicles?.pageable?.pageSize : 10;
    const page = vehicles?.pageable?.pageNumber ?? 0;

    const columns = [
      {
        name: "graph",
        label: "Graph",
        options: {
          filter: false,
          sort: false,
          display: this.isDisplay("category") ?? true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return <VehicleGraph value={value}/>;
          },
        },
      },
      {
        name: "uuid",
        label: "UUID",
        options: {
          filter: true,
          sort: false,
          display: this.isDisplay("uuid") ?? true,
          customFilterListOptions: {render: (v) => `UUID: ${v}`},
          filterType: "textField",
        },
      },
      {
        name: "createdAt",
        label: "Created At",
        options: {
          filter: false,
          sort: true,
          display: this.isDisplay("createdAt") ?? true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <Tooltip
                title={
                  value ? moment.utc(value).local().format(datetime_format) : ""
                }
              >
                <div>
                  {value ? moment.utc(value).local().format(date_format) : ""}
                </div>
              </Tooltip>
            );
          },
          filterType: "textField",
        },
      },
      {
        name: "updatedAt",
        label: "Updated At",
        options: {
          filter: false,
          sort: true,
          display: this.isDisplay("updatedAt") ?? false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <Tooltip
                title={
                  value ? moment.utc(value).local().format(datetime_format) : ""
                }
              >
                <div>
                  {value ? moment.utc(value).local().format(date_format) : ""}
                </div>
              </Tooltip>
            );
          },
          filterType: "textField",
        },
      },
      {
        name: "frameNo",
        label: "Frame No",
        options: {
          filter: true,
          sort: true,
          display: this.isDisplay("frameNo") ?? true,
          customFilterListOptions: {render: (v) => `Frame No: ${v}`},
          filterType: "textField",
        },
      },
      {
        name: "qrCode",
        label: "QR Code",
        options: {
          filter: true,
          sort: true,
          display: this.isDisplay("qrCode") ?? true,
          customFilterListOptions: {render: (v) => `QR Code: ${v}`},
          filterType: "textField",
        },
      },
      {
        name: "cityUuid",
        label: "City ID",
        options: {
          filter: true,
          filterType: "custom",
          sort: false,
          display: this.isDisplay("cityUuid") ?? false,
          customFilterListOptions: {
            render: (v) => {
              const city = this.props?.cities?.find(c => c.uuid === v[0]);
              return city && `City: ${city?.cityName},${city?.country}`;
            }
          },
          filterOptions: {
            display: (filterList, onChange, index, column) => {
              return (
                <FormControl>
                  <InputLabel htmlFor='select-multiple-chip'>
                    City
                  </InputLabel>
                  <Select
                    value={filterList[index][0] ?? ""}
                    onChange={event => {
                      filterList[index] = [event.target.value];
                      onChange(filterList[index], index, column);
                    }}
                  >
                    {(cities || []).map((city, index) => (
                      <MenuItem key={index} value={city.uuid}>
                        {city.cityName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              );
            }
          }
        }
      },
      {
        name: "status",
        label: "Vehicle Status",
        options: {
          filter: true,
          sort: true,
          display: true,
          filterType: "custom",
          customFilterListOptions: {
            render: v => v.map(l => l.toUpperCase()),
            update: (filterList, filterPos, index) => {
              filterList[index].splice(filterPos, 1);
              return filterList;
            }
          },
          filterOptions: {
            fullWidth: true,
            logic: (location, filters, row) => {
              if (filters.length) return !filters.includes(location);
              return false;
            },
            display: (filterList, onChange, index, column) => {
              return (
                <FormControl>
                  <InputLabel htmlFor='select-multiple-chip'>
                    Vehicle status
                  </InputLabel>
                  <Select MenuProps={{
                    getContentAnchorEl: () => null,
                  }}
                    multiple
                    value={filterList[index]}
                    renderValue={selected => selected.join(', ')}
                    onChange={event => {
                      filterList[index] = event.target.value;
                      onChange(filterList[index], index, column);
                    }}
                  >
                    {VEHICLE_STATUSES.map(item => (
                      <MenuItem key={item} value={item}>
                        <Checkbox
                          color='primary'
                          checked={filterList[index].indexOf(item) > -1}
                        />
                        <ListItemText primary={item}/>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              );
            }
          }
        },
      },
      {
        name: "category",
        label: "Vehicle category",
        options: {
          filter: true,
          sort: true,
          display: true,
          filterType: "custom",
          customFilterListOptions: {
            render: v => v.map(l => l.toUpperCase()),
            update: (filterList, filterPos, index) => {
              console.log('update');
              console.log(filterList, filterPos, index);
              filterList[index].splice(filterPos, 1);
              return filterList;
            }
          },
          filterOptions: {
            fullWidth: true,
            logic: (location, filters, row) => {
              if (filters.length) return !filters.includes(location);
              return false;
            },
            display: (filterList, onChange, index, column) => {
              return (
                <FormControl>
                  <InputLabel htmlFor='select-multiple-chip'>
                    Vehicle category
                  </InputLabel>
                  <Select MenuProps={{
                    getContentAnchorEl: () => null,
                  }}
                    multiple
                    value={filterList[index]}
                    renderValue={selected => selected.join(', ')}
                    onChange={event => {
                      filterList[index] = event.target.value;
                      onChange(filterList[index], index, column);
                    }}
                  >
                    {VEHICLE_CATEGORIES.map(item => (
                      <MenuItem key={item} value={item}>
                        <Checkbox
                          color='primary'
                          checked={filterList[index].indexOf(item) > -1}
                        />
                        <ListItemText primary={item}/>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              );
            }
          }
        },
      },
      {
        name: "imei",
        label: "IMEI",
        options: {
          filter: false,
          sort: false,
          display: this.isDisplay("imei") ?? false,
        },
      },
      {
        name: "discount",
        label: "Discount",
        options: {
          filter: false,
          sort: false,
          display: this.isDisplay("discount") ?? false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return value >= 0 ? `${value * 100}%` : "";
          },
        },
      },
      {
        name: "batteryLevel",
        label: "Battery",
        options: {
          filter: false,
          sort: true,
          display: this.isDisplay("batteryLevel") ?? true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return <Battery value={value} display={true}/>;
          },
        },
      },
      {
        name: "location",
        label: "Location",
        options: {
          filter: false,
          sort: false,
          display: this.isDisplay("location") ?? true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <IconButton
                onClick={(event) => {
                  this.handleonLocationClick(event, tableMeta["rowIndex"]);
                }}
              >
                <LocationOnIcon/>
              </IconButton>
            );
          },
        },
      },
      {
        name: "cityName",
        label: "City",
        options: {
          filter: false,
          sort: false,
          display: this.isDisplay("cityName") ?? true,
        },
      },
      {
        name: "Operations",
        options: {
          filter: false,
          sort: false,
          empty: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div>
                <Tooltip title="Details">
                  <IconButton
                    onClick={() => {
                      this.props.history.push(
                        `/vehicle/${tableMeta.rowData[1]}`
                      );
                    }}
                  >
                    <SearchIcon/>
                  </IconButton>
                </Tooltip>
              </div>
            );
          },
        },
      },
    ];

    const options = {
      search: false,
      filter: true,
      confirmFilters: true,
      serverSide: true,
      filterType: "dropdown",
      responsive: "vertical",
      rowsPerPageOptions: [10, 20, 50, 100, 250],
      rowsPerPage: rowsPerPage,
      count: totalElements,
      page: page,
      fixedHeader: true,
      tableBodyHeight: "900px",
      customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
        <CustomToolbarSelect
          selectedRows={selectedRows}
          displayData={displayData}
          setSelectedRows={setSelectedRows}
          callback={() => this.refresh()}
        />
      ),
      onTableChange: (action, tableState) => {
        switch (action) {
          case "viewColumnsChange":
            this.onChangeColumnDisplay();
            break;
          case "onFilterDialogOpen":
            this.isFilterDialogOpen = true;
            break;
          case "filterChange":
            if (this.isFilterDialogOpen) return;
            else this.refresh();
            break;
          case "onFilterDialogClose":
            this.isFilterDialogOpen = false;
          case "changePage":
          case "sort":
          case "changeRowsPerPage":
            this.refresh();
            break;
          default:
            console.log("Not handled action " + action);
        }
      },
      customFilterDialogFooter: (currentFilterList, applyNewFilters) => {
        return (
          <div style={{marginTop: "40px"}}>
            <Button
              variant="contained"
              style={{color: "white", backgroundColor: "#333399"}}
              onClick={() => {
                applyNewFilters();
              }}
            >
              Apply
            </Button>
          </div>
        );
      },
      customToolbar: () => {
        return (
          <CustomToolbar refresh={this.refresh} tableProps={this.props}/>
        );
      }
    };

    return (
      <React.Fragment>
        <MUIDataTable
          title={<h1 className="tableTitle">Vehicle List</h1>}
          data={list}
          columns={columns}
          options={options}
          ref={this.tableRef}
        />
        {/* pop over */}
        <Popover
          id="vehicle_map"
          className="vehicle_popover"
          open={this.state.openPopover}
          anchorEl={this.state.anchorEl}
          onClose={this.handlePopOverClose}
          anchorOrigin={{
            vertical: "center",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "center",
            horizontal: "right",
          }}
        >
          <div className="card shadow mb-4">
            <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
              <h6 className="m-0 font-weight-bold" style={{color: '#333399'}}>Vehicle Map</h6>
              <div style={{padding: "4px"}}>
                <Tooltip title="close">
                  <Button onClick={() => this.handlePopOverClose()}>
                    <HighlightOff/>
                  </Button>
                </Tooltip>
              </div>
            </div>
            <div className="card-body">
              <VehiclePopMap vehicle={this.selectedVehicleData}/>
            </div>
          </div>
        </Popover>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  vehicles: state.vehicleData.vehicles,
  cities: state.cityReducer.cities?.content,
});

const mapDispatchToProps = (dispatch) => ({
  search: (params) => dispatch(search(params)),
  getCities: (params) => dispatch(getCities(params)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withAuth0(VehicleList));
