import React from "react";
import PropTypes from "prop-types";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle, InputAdornment, InputLabel, OutlinedInput,
} from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import CardContent from "@material-ui/core/CardContent";
import TextField from "@material-ui/core/TextField";
import { Grid } from "@material-ui/core";
import "./amount-input-popup.css";

const currency = process.env.REACT_APP_CURRENCY

class AmountInputPopup extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      amount: undefined,
      errorMessage: undefined
    };
  }

  static propTypes = {
    close: PropTypes.func.isRequired,
    callback: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    subject: PropTypes.string.isRequired,
  };

  onClickConfirmBtn = () => {
    this.props.callback(this.state.amount);
  };

  onClickCancelBtn = () => {
    this.props.close();
  };

  onChangeAmount = (event) => {
    this.setState({amount: event.target.value});
    if (event.target.value === null || event.target.value === undefined || event.target.value === "") {
      this.setState({errorMessage: "Required"});
    }else if (event.target.value <= 0) {
      this.setState({errorMessage: "Must be greater than 0"});
    }else{
      this.setState({errorMessage: undefined});
    }
  };

  render() {
    const {open, subject} = this.props;

    return (
      <React.Fragment>
        <Dialog
          open={open}
          onClose={this.handleOnClose}
          aria-labelledby="Pupop Modal"
          fullWidth={true}
        >
          <DialogTitle id="Action Modal" className="input-popup-header">
            {subject}
          </DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item sm={12} xs={12} md={12} lg={12}>
                <CardContent style={{paddingBottom:'0px'}}>
                  <Grid container spacing={2} className="formLabel">
                    <Grid
                      item
                      sm={12} xs={12} md={12} lg={12}
                      style={{display:"block"}}
                    >
                      <TextField
                        error={this.state?.errorMessage}
                        helperText={this.state?.errorMessage ?? ""}
                        id="amount-id"
                        label="Amount"
                        fullWidth
                        type="number"
                        value={this.state.amount}
                        onChange={this.onChangeAmount}
                        InputProps={{
                          readOnly: false,
                          startAdornment:(<InputAdornment position="start">{currency}</InputAdornment>)
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        variant="outlined"
                        autoFocus
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions className="input-popup-dialog-actions">
            <Button
              variant="contained"
              color="primary"
              onClick={this.onClickConfirmBtn}
              disabled={!(this.state?.errorMessage === null || this.state?.errorMessage === undefined || this.state?.errorMessage === "")}
            >
              Confirm
            </Button>
            <Button onClick={this.onClickCancelBtn} style={{ color: grey[500] }}>
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

export default AmountInputPopup;
