import React, {useState} from 'react';

import {config} from "../../../auth/auth";
import Modal from "react-bootstrap/Modal";
import Button from "@material-ui/core/Button";
import {ButtonGroup, IconButton, Tooltip} from "@material-ui/core";
import {ReactComponent as BatteryBoxIcon} from "./batteryBox.svg"
import axios from "axios";

const host = process.env.REACT_APP_HOST

const BatteryBoxControl = ({vehicleStatus, uuid}) => {
  const [showModal, setShowModal] = useState(false);

  let icon = <BatteryBoxIcon fill='#757575' style={{height: 20, width: 20}}/>;

  let batteryBox = <Tooltip title="Battery Box Blocked">
    <IconButton onClick={() => alert(`To lock/unlock the battery box, the vehicle status can not be AVAILABLE, RUNNING, or RESERVED.
    \n Current vehicle status: ${vehicleStatus}`)}>
      {icon}
    </IconButton>
  </Tooltip>

  const onClickHandle = () => {
    setShowModal(true);
  }

  if (!(vehicleStatus === 'AVAILABLE' || vehicleStatus === 'RUNNING' || vehicleStatus === 'RESERVED')) {
    batteryBox = <Tooltip title="Battery Box">
      <IconButton onClick={onClickHandle}>
        {icon}
      </IconButton>
    </Tooltip>
  }

  const sendBatteryBoxAction = (action) => {
    axios.put(`${host}/vehicle/${uuid}/${action}`, {}, config());
    setShowModal(false);
  }

  return (
    <React.Fragment>
      <Modal
        show={showModal}
        onHide={() => setShowModal((prevState) => !prevState)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm</Modal.Title>
        </Modal.Header>
        <Modal.Body>What action do you want to perform on battery box?</Modal.Body>
        <Modal.Footer className="text-center" style={{
          justifyContent: "space-between",
        }}>
          <ButtonGroup>
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={() => sendBatteryBoxAction("unlockBatteryBox")}
            >
              Unlock
            </Button>
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={() => sendBatteryBoxAction("lockBatteryBox")}
            >
              Lock
            </Button>
          </ButtonGroup>
          <Button
            size="small"
            variant="default"
            onClick={() => setShowModal(false)}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
      {batteryBox}

    </React.Fragment>
  );
};

export default BatteryBoxControl;