import {ConsoleEventLogTypes} from "./consoleEventLog.types";

const INITIAL_STATE = {
  consoleEventLogs: []
};

const consoleEventLogReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ConsoleEventLogTypes.SET_CONSOLEEVENTLOGS:
      return {
        ...state,
        consoleEventLogs: action.payload,
      };
    default:
      return state;
  }
};

export default consoleEventLogReducer;