import React from "react";
import PropTypes from "prop-types";
import {Skeleton} from "@material-ui/lab";
import IconButton from "@material-ui/core/IconButton";
import {Tooltip} from "@material-ui/core";
import Loading from "../../../../components/common/Loading";
import {active, inactive} from "../../../../redux/business/business.action";
import LockIcon from "@material-ui/icons/Lock";
import LockOpenIcon from "@material-ui/icons/LockOpen";


class BusinessStatusIcon extends React.Component {

  static propTypes = {
    businessUuid: PropTypes.string.isRequired,
    businessStatus: PropTypes.string.isRequired,
    refresh: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      loading:false,
    };
  }

  blockUI = () => {
    this.setState({loading:true});
  }

  unblockUI = () => {
    this.setState({loading:false});
  }

  onClickStatusBtn = () => {
    this.blockUI();
    var promise;
    if (this.props.businessStatus === 'ACTIVE') {
      promise = inactive(this.props.businessUuid);
    }else {
      promise = active(this.props.businessUuid);
    }
    promise
      .then(() => {this.props.refresh()})
      .catch((e) => {console.error(e)})
      .finally(() => {setTimeout(() => {this.unblockUI()}, 500)});
  }

  render() {
    const {businessUuid, businessStatus} = this.props;

    if (!businessUuid)
      return (<Skeleton variant="circle" width={30} height={30} />);

    if (this.state.loading)
      return (<Loading />);

    return (
      <React.Fragment>
        <Tooltip title= {businessStatus === 'ACTIVE' ? 'Block' : 'Active'}>
          <IconButton onClick={this.onClickStatusBtn}>
            {businessStatus === 'ACTIVE' ? <LockIcon /> : <LockOpenIcon/>}
          </IconButton>
        </Tooltip>
      </React.Fragment>
    );
  }
}

export default BusinessStatusIcon;