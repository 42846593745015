import {ActionTypes} from "./regionRule.types";

const INITIAL_STATE = {
    regionRules: undefined,
    regionRule: undefined,
    regionRuleActions: undefined,
    regionRuleNotifications: undefined
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ActionTypes.SET_REGION_RULES:
      return {
        ...state,
        regionRules: action.payload,
      };
    case ActionTypes.SET_REGION_RULE:
    return {
        ...state,
        regionRule: action.payload,
    };
    case ActionTypes.SET_REGION_RULE_ACTIONS:
    return {
        ...state,
        regionRuleActions: action.payload,
    };
    case ActionTypes.SET_REGION_RULE_NOTIFICATIONS:
    return {
        ...state,
        regionRuleNotifications: action.payload,
    };
    default:
      return state;
  }
};

export default reducer;