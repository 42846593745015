import axios from "axios";

import {config, getAccessToken} from "../../auth/auth";
import {RoleActionTypes} from "./role.types";

const host = process.env.REACT_APP_HOST

export const search  = (queryParams) =>{
  const config = {
    headers: {
      'Authorization': `Bearer ${getAccessToken()}`,
      'Content-Type': 'application/json'
    },
    params: queryParams
  }

  return async dispatch => {
    return axios.get(`${host}/role`, config)
      .then(
        response => dispatch(setRoles(response?.data)),
        err => console.log(err)
      );
  }
}


export const getRoleByUuid  = (uuid) => {
  return async dispatch => {
    return axios.get(`${host}/role/${uuid}`, config())
      .then(
        response => dispatch(setRole(response.data)),
        err => console.log(err)
      );
  }
}

export const getUsers = (uuid) => {
  return async dispatch => {
    return axios.get(`${host}/role/${uuid}/user`, config())
      .then(
        response => dispatch(setUsers(response.data)),
        err => console.log(err)
      );
  }
}


export const getPages = (uuid) => {
  return async dispatch => {
    return axios.get(`${host}/role/${uuid}/page/assigned`, config())
      .then(
        response => dispatch(setPages(response.data)),
        err => console.log(err)
      );
  }
}

export const getUnassignedUsers = (uuid) => {
  return async dispatch => {
    return axios.get(`${host}/role/${uuid}/user/unassigned`, config())
      .then(
        response => dispatch(setUnassignedUsers(response?.data ?? [])),
        err => console.log(err)
      );
  }
}

export const getUnassignedPages = (uuid) => {
  return async dispatch => {
    return axios.get(`${host}/role/${uuid}/page/unassigned`, config())
      .then(
        response => dispatch(setUnassignedPages(response?.data ?? [])),
        err => console.log(err)
      );
  }
}

export const assign = (roleUuid, consoleUserUuid) => {
  return async dispatch => {
    return axios.post(`${host}/role/${roleUuid}/user/${consoleUserUuid}`, null, config())
      .then(
        response => dispatch(setUnassignedUsers(response?.data ?? [])),
        err => console.log(err)
      );
  }
}

export const addPageToRole = (roleUuid, page) => {
  return async dispatch => {
    return axios.post(`${host}/role/${roleUuid}/page/${page}`, null, config())
      .then(
        response => dispatch(setUnassignedPages(undefined)),
        err => console.log(err)
      );
  }
}

export const updateRole  = (role) => {
  const body = {
    name: role?.name,
    description: role?.description
  }
  return async dispatch => {
    return axios.put(`${host}/role/${role.uuid}`, body, config())
      .then(
        response => {},
        err => console.log(err)
      );
  }
}

export const setRoles = (payload) => ({
  type: RoleActionTypes.SET_ROLES,
  payload: payload,
});

export const setRole = (payload) => ({
  type: RoleActionTypes.SET_ROLE,
  payload: payload,
});

export const setUsers = (payload) => ({
  type: RoleActionTypes.SET_USERS,
  payload: payload,
});

export const setPages = (payload) => ({
  type: RoleActionTypes.SET_PAGES,
  payload: payload,
});

export const setUnassignedUsers = (payload) => ({
  type: RoleActionTypes.SET_UNASSIGNED_USERS,
  payload: payload,
});

export const setUnassignedPages = (payload) => ({
  type: RoleActionTypes.SET_UNASSIGNED_PAGES,
  payload: payload,
});