import React from 'react';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';
import PropTypes from "prop-types";

const marks = [
  {
    value: 0,
    label: 'Free',
  },
  {
    value: 50,
    label: 'Half Price',
  },
  {
    value: 100,
    label: 'No discount',
  },
  {
    value: 200,
    label: 'Double',
  },
];

class DiscountSlider extends React.Component {

  static propTypes = {
    value: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired
  };

  render() {

    const {value, onChange} = this.props;

    return (
      <div>
        <Typography id="discrete-slider-always" gutterBottom>
          Discount
        </Typography>
        <Slider
          defaultValue={100}
          value={value}
          aria-labelledby="discrete-slider-always"
          marks={marks}
          valueLabelDisplay="on"
          className="primary"
          max={200}
          onChange={onChange}
        />
      </div>
    );
  }
}

export default DiscountSlider;