import store from '../redux/store';

export const hasPermission = (pageName) => {
  const userAssignedRoles = store?.getState()?.appData?.currentAdminUser?.roles;
  if (!userAssignedRoles) {
    return false;
  }

  const pageRoles = store?.getState()?.appData?.pageRoles;
  if (!pageRoles) {
    return false;
  }

  if (pageRoles[`ALL`]?.some( r => userAssignedRoles?.includes(r))) {
    return true;
  } else {
    return pageRoles[`${pageName}`]?.some( r => userAssignedRoles?.includes(r));
  }
}