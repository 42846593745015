import React from "react";
import {ErrorMessage, Field, Form as FormikForm, Formik, useField, useFormikContext,} from "formik";
import "react-datepicker/dist/react-datepicker.css";
import {Checkbox, TextField} from "formik-material-ui";
import "./formElements.css";
import {
  Button,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  Radio,
  RadioGroup,
  Select
} from "@material-ui/core";
import {KeyboardDateTimePicker, MuiPickersUtilsProvider,} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import MenuItem from "@material-ui/core/MenuItem";

export function Form(props) {
  return (
    <Formik initialValues={props.initialValues} {...props}>
      <FormikForm className="needs-validation" novalidate="">
        {props.children}
      </FormikForm>
    </Formik>
  );
}

export function InputTextField(props) {
  const [form, meta] = useField(props);
  const {name, label, placeholder, readOnly = false, upperCase, ...rest} = props;
  return (
    <>
      <Field
        component={TextField}
        className="formTextField"
        name={name}
        variant="outlined"
        label={label}
        id={name}
        disabled={readOnly}
        {...rest}
        {...form}
        error={meta.touched && Boolean(meta.error)}
        helperText={meta.touched && meta.error}
        value={upperCase ? form.value.toUpperCase() : form.value}
        InputLabelProps={{
          shrink: true,
        }}
        placeholder={placeholder || ""}

      />
    </>
  );
}

export function CheckboxField(props) {
  const {name, readOnly, value, label} = props;
  return (
    <label>
      <Field
        component={Checkbox}
        type="checkbox"
        name={name}
        disabled={readOnly}
        id={value}
        value={value}
      />
      {label}
    </label>
  );
}

export const CustomSelect = (props) => {
  const [field] = useField(props);
  const {options, label} = props;

  const getValue = () => {
    if (options && field.value) {
      return options
        .filter(city => field.value.includes(city.value))
        .map(city => city.label);
    }
    return [];
  }

  return (
    <FormControl fullWidth>
      <InputLabel>
        {label}
      </InputLabel>
      <Select
        name={field.name}
        fullWidth
        value={getValue()}
        renderValue={selected => selected.join(', ')}
        multiple
      >
        <FormGroup>
          {options?.map(c =>
            <FormControlLabel key={c.label}
                              control={<CheckboxField name={field.name} value={c.value}/>}
                              label={c.description}/>
          )}
        </FormGroup>
      </Select>
    </FormControl>
  )
}

export function DatePickerField(props) {
  const {name, label, placeholder, readOnly, ...rest} = props;
  const {setFieldValue} = useFormikContext();
  const [field, meta] = useField(props);
  return (
    <>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDateTimePicker
          {...field}
          disabled={readOnly}
          inputVariant="outlined"
          label={label}
          fullWidth
          error={meta.touched && Boolean(meta.error)}
          helperText={meta.touched && meta.error}
          onChange={(val) => {
            setFieldValue(field.name, val);
          }}
          InputLabelProps={{
            shrink: true,
          }}
          defaultValue={""}
          format="dd/MM/yyyy HH:mm"
        />
      </MuiPickersUtilsProvider>
    </>
  );
}

export function NumberField(props) {
  const {name, label, placeholder, readOnly, ...rest} = props;
  return (
    <>
      <Field
        component={TextField}
        className="formTextField"
        type="number"
        name={name}
        variant="outlined"
        id={name}
        InputLabelProps={{
          shrink: true,
          readOnly: {readOnly},
        }}
        label={label}
        {...rest}
      />
    </>
  );
}

export function SelectField(props) {
  const [field, meta] = useField(props);
  return (
    <>
      <Field
        fullWidth
        component={TextField}
        select
        label={props.label}
        {...field}
        error={meta.touched && Boolean(meta.error)}
        helperText={meta.touched && meta.error}
        defaultValue={""}
        variant="outlined"
        InputLabelProps={{
          shrink: true,
        }}
      >
        {props.options.map((optn) => (
          <MenuItem value={optn.value} key={optn.label || optn.value}>
            {optn.description}
          </MenuItem>
        ))}
      </Field>
    </>
  );
}


export function RadioButtonField({options, ...props}) {
  const [field, meta] = useField(props);

  return (
    <>
      <Grid item xs>
        <RadioGroup {...field} {...props}>
          {options.map((option, index) => (
            <FormControlLabel
              control={<Radio/>}
              label={option.description}
              value={option.value}
              key={index}
            />
          ))}
        </RadioGroup>
        <ErrorMessage name={props.name}>
          {msg => <div style={{color: 'red'}}>{msg}</div>}
        </ErrorMessage>
      </Grid>
    </>
  )
}

export function SubmitButton(props) {
  const {title, ...rest} = props;
  const {isSubmitting} = useFormikContext();

  return (
    <Button
      {...rest}
      type="submit"
      disabled={isSubmitting}
      variant="contained"
      color="primary"
      className="submitBtn"
    >
      {title}
    </Button>
  );
}
