import React from 'react';
import ReactEcharts from "echarts-for-react";
import applePay from "../../../images/applePay.svg";
import googlePay from "../../../images/googlePay.svg";
import stcPay from "../../../images/stcPay.svg";
import totalTopUp from "../../../images/totalTopUp.svg";
import bankCard from "../../../images/bankCard.svg";

const TopUpChart = ({date, data, yAxisLabel, bankCardData, stcPayData, googlePayData, applePayData}) => {

  const getOptions = {
      textStyle: {
        fontFamily: 'Urbanist'
      },
      legend: {
        type: 'plain',
        itemWidth: 90,
        itemHeight: 50,
        data: [
          {name: 'Total TopUp', icon: `image://${totalTopUp}`},
          {name: 'applePay', icon: `image://${applePay}`},
          {name: 'googlePay', icon: `image://${googlePay}`},
          {name: 'stcPay', icon: `image://${stcPay}`},
          {name: 'Bank Card', icon: `image://${bankCard}`},
        ],
        orient: "horizontal",
        formatter: function (name) {
          switch (name) {
            case 'applePay':
              return '';
            case 'googlePay':
              return '';
            case 'stcPay':
              return '';
            case 'Bank Card':
              return name;
            default:
              return name;
          }
        }
      },
      xAxis: {
        type: 'category',
        data: date,
        axisPointer: {
          type: 'shadow'
        }
      },
      grid: {
        containLabel: true
      },
      yAxis: {
        type: 'value',
        axisLabel: {
          formatter: yAxisLabel ? `{value} ${yAxisLabel}` : `{value}`,
        }
      },

      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross',
          crossStyle: {
            color: '#333399'
          }
        }
      },
      toolbox: {
        feature: {
          magicType: {show: true, title: 'Data View', type: ['line', 'bar']},
          restore: {show: true, title: 'Restore'},
          saveAsImage: {show: true, title: 'Save as image'}
        }
      },
      series: [
        {
          name: 'Total TopUp',
          data: data,
          type: 'line',
          smooth: true,
          lineStyle: {
            color: '#7A8B8C',
            width: 3
          },
        },
        {
          name: 'applePay',
          type: 'bar',
          data: applePayData,
          itemStyle: {normal: {color: '#5D739A'}},
        },
        {
          name: 'googlePay',
          type: 'bar',
          data: googlePayData,
          itemStyle: {normal: {color: '#8784C7'}},
        },
        {
          name: 'stcPay',
          type: 'bar',
          data: stcPayData,
          itemStyle: {normal: {color: '#6997AF'}},
        },
        {
          name: 'Bank Card',
          type: 'bar',
          data: bankCardData,
          itemStyle: {normal: {color: '#AD84C6'}},
        },
      ]
    };


  return (
    <ReactEcharts style={{width: '100%', height: '50vh'}} option={getOptions}/>
  );
};


export default TopUpChart;