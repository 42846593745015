import React from "react";
import PropTypes from 'prop-types';
import {IconButton, Tooltip} from "@material-ui/core";
import {red, yellow} from "@material-ui/core/colors";
import ThumbUp from '@material-ui/icons/ThumbUp';
import BuildIcon from '@material-ui/icons/Build';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import DirectionsBikeIcon from '@material-ui/icons/DirectionsBike';
import ErrorIcon from '@material-ui/icons/Error';
import HelpIcon from '@material-ui/icons/Help';
import HowToRegIcon from '@material-ui/icons/HowToReg';
import BatteryAlertIcon from '@material-ui/icons/BatteryAlert';
import ExploreOffIcon from '@material-ui/icons/ExploreOff';
import { faTools } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class VehicleStatus extends React.Component {
  static propTypes = {
    value: PropTypes.string.isRequired,
    display: PropTypes.bool
  };

  render() {
    const { value, display} = this.props;

    let icon;
    let title = value ? `${value}` : "No Status Info";
    if (value === 'AVAILABLE') {
      icon = <ThumbUp/>;
    }else if (value === 'RUNNING') {
      icon = <DirectionsBikeIcon/>;
    }else if (value === 'RESERVED') {
      icon = <HowToRegIcon/>;
    }else if (value === 'REPORTED') {
      icon = <ReportProblemIcon style={{ color: yellow[900] }}/>;
    }else if (value === 'MAINTAINING'){
      icon = <FontAwesomeIcon icon={faTools} />;
    }else if (value === 'MAINTENANCE_PENDING'){
      icon = <FontAwesomeIcon icon={faTools} />;
    }else if (value === 'LOST'){
      icon = <ExploreOffIcon style={{ color: red[500] }}/>;
    }else if (value === 'LOW_BATTERY'){
      icon = <BatteryAlertIcon style={{ color: red[500] }}/>;
    }else if (value === 'DAMAGED' ){
      icon = <ErrorIcon  style={{ color: red[500] }}/>;
    }else {
      icon = <HelpIcon style={{ color: red[500] }}/>;
      title = "Unknown Status"
    }

    return (
      <React.Fragment>
        <Tooltip title={title}>
          <IconButton>
            {icon}
          </IconButton>
        </Tooltip>
      </React.Fragment>
    );
  }
}

export default VehicleStatus;