import React from "react";
import PropTypes from "prop-types";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import CardActions from "@material-ui/core/CardActions";

class RegionPopup extends React.Component {
  static propTypes = {
    region: PropTypes.object.isRequired,
  };

  render() {
    const { region } = this.props;
    return (
      <Card style={{ maxWidth: 445, width: "100%", minWidth: "300px" }}>
        <CardContent>
          <Typography variant="body2" color="textSecondary" component="p">
            <b>Region UUID:</b> {region?.uuid}
            <br />
            <b>Region Name:</b> {region?.regionName}
            <br />
            <b>Category:</b> {region?.category}
            <br />
            <b>Status:</b> {region?.status}
            <br />
            <b>Speed Limit:</b>
            {region?.speedLimit}
            <br />
          {/*  <b>Discount:</b>
            {region?.discount}%<br /> */}
          </Typography>
        </CardContent>
        <CardActions className="right-align">
          <Button
            size="small"
            className="editRegion"
            variant="contained"
            color="primary"
          >
            Edit
          </Button>
          <Button
            size="small"
            className="deleteRegion"
            variant="contained"
            color="secondary"
          >
            Delete
          </Button>
        </CardActions>
      </Card>
    );
  }
}

export default RegionPopup;
