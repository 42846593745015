import React, {Component} from "react";
import MUIDataTable from "mui-datatables";
import {withAuth0} from "@auth0/auth0-react";
import {Button, Grid, IconButton, Tooltip} from "@material-ui/core";
import {connect} from "react-redux";
import {search} from "../../../../redux/city/action";
import {queryParams} from "../../../../utils/utils";
import Status from "../../../../components/common/Status";
import Delete from "../../../../components/common/Delete";
import SearchIcon from "@material-ui/icons/Search";
import EditIcon from "@material-ui/icons/Edit";
import VehiclesSupported from "./VehiclesSupported";
import Regions from "./Regions";
import CityCreateModal from "./cityCreate";
import CityEditModal from "./editCity";
import AddIcon from "@material-ui/icons/Add";

class City extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openPopUp: false,
    };
    this.isFilterDialogOpen = false;
    this.tableRef = React.createRef();
    this.columnsDisplay = undefined;
  }

  componentDidMount() {
    this.refresh();
  }

  refresh = () => {
    const { search } = this.props;

    // table state
    const state = this.tableRef?.current?.state;

    const params = queryParams(this.props, state);

    search(params);
  };

  onSucessCity() {
    this.refresh();
  }
  handleModalCloseChange = () => {
    this.setState({
      openPopUp: false,
    });
  };

  onChangeColumnDisplay = () => {
    const state = this.tableRef?.current?.state;
    this.columnsDisplay = state.columns.reduce(
      (obj, item) => Object.assign(obj, { [item.name]: item.display }),
      {}
    );
  };

  isDisplay = (name) => {
    if (!this.columnsDisplay || !this.columnsDisplay.hasOwnProperty(name))
      return undefined;
    else return this.columnsDisplay[name];
  };

  render() {
    const { cities } = this.props;
    if (!cities) return <React.Fragment>Loading...</React.Fragment>;

    const list = cities?.content;
    const totalElements = cities?.totalElements ?? 0;
    const rowsPerPage = cities?.pageable?.pageSize ?? 10;
    const page = cities?.pageable?.pageNumber ?? 0;

    const columns = [
      {
        name: "uuid",
        label: "UUID",
        options: {
          filter: true,
          sort: false,
          display: this.isDisplay("uuid") ?? true,
          customFilterListOptions: { render: (v) => `UUID: ${v}` },
          filterType: "textField",
        },
      },

      {
        name: "cityName",
        label: "Name",
        options: {
          filter: false,
          sort: false,
          display: this.isDisplay("cityName") ?? true,
        },
      },
      {
        name: "country",
        label: "Country",
        options: {
          filter: false,
          sort: false,
          display: this.isDisplay("country") ?? true,
        },
      },
      {
        name: "vehiclesSupported",
        label: "Vehicles Supported",
        options: {
          filter: false,
          sort: false,
          display: this.isDisplay("vehiclesSupported") ?? true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return <VehiclesSupported value={value} />;
          },
        },
      },
      {
        name: "regions",
        label: "Regions",
        options: {
          filter: false,
          sort: false,
          display: this.isDisplay("regions") ?? true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return <Regions value={value} />;
          },
        },
      },
      {
        name: "status",
        label: "Status",
        options: {
          filter: false,
          sort: false,
          display: this.isDisplay("status") ?? true,
        },
      },
      {
        name: "uuid",
        label: " ",
        options: {
          filter: false,
          sort: false,
          empty: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div>
                <CityEditModal city={tableMeta?.tableData[tableMeta.rowIndex]} callback={() => this.refresh()}/>
              </div>
            );
          },
        },
      },
    ];

    const options = {
      filter: false,
      search: false,
      confirmFilters: false,
      serverSide: true,
      filterType: "dropdown",
      responsive: "vertical",
      rowsPerPageOptions: [10, 20, 50, 100],
      rowsPerPage: rowsPerPage,
      count: totalElements,
      page: page,
      fixedHeader: true,
      tableBodyHeight: "900px",
      onTableChange: (action, tableState) => {
        switch (action) {
          case "viewColumnsChange":
            this.onChangeColumnDisplay();
            break;
          case "onFilterDialogOpen":
            this.isFilterDialogOpen = true;
            break;
          case "filterChange":
            if (this.isFilterDialogOpen) return;
            else this.refresh();
            break;
          case "onFilterDialogClose":
            this.isFilterDialogOpen = false;
          case "changePage":
          case "sort":
          case "changeRowsPerPage":
            this.refresh();
            break;
          default:
            console.log("Not handled action " + action);
        }
      },
      customFilterDialogFooter: (currentFilterList, applyNewFilters) => {
        return (
          <div style={{ marginTop: "40px" }}>
            <Button
              variant="contained"
              style={{ color: "white", backgroundColor: "#36b9cc" }}
              onClick={() => {
                applyNewFilters();
              }}
            >
              Apply
            </Button>
          </div>
        );
      },
      customToolbar: () => {
        return (
          <IconButton
              variant="contained"
              color="primary"
              style={{ float: "right" }}
              onClick={() => {
                this.setState({
                  openPopUp: true,
                });
              }}
            >
              <AddIcon/>
          </IconButton>
        )
      }
    };

    return (
      <React.Fragment>
        <MUIDataTable
          title={<h1 className="tableTitle">City List</h1>}
          data={list}
          columns={columns}
          options={options}
          ref={this.tableRef}
        />
        <CityCreateModal
          open={this.state.openPopUp}
          onCloseModal={() => this.handleModalCloseChange()}
          callback={() => this.onSucessCity()}
        ></CityCreateModal>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  cities: state.cityReducer.cities,
});

const mapDispatchToProps = (dispatch) => ({
  search: (params) => dispatch(search(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withAuth0(City));
