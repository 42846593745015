import React from "react";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { IconButton, Tooltip } from "@material-ui/core";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { green, grey } from "@material-ui/core/colors/";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import PropTypes from "prop-types";
import axios from "axios";

import {config} from "../../../auth/auth";
import {CircularProgress, Grid} from "@material-ui/core";
import CardContent from "@material-ui/core/CardContent";
import "./details.css";
import {DateTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import MomentUtils from '@date-io/moment';
import moment from "moment";


const datetime_format = process.env.REACT_APP_DATETIME_FORMAT;
const host = process.env.REACT_APP_HOST
const LOCAL_DATE_TIME_FORMAT = process.env.REACT_APP_LOCALDATETIME_FORMAT;

class EndOrderPopUp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      open: false,
      cost: 0,
      status: this.props.status,
      endAt: moment(moment.now()).local(),
      error: false
    };
  }

  static propTypes = {
    refresh: PropTypes.func.isRequired,
    cost: PropTypes.number.isRequired
  };

  cancel = () => {
    this.setState({open: false});
  };

  endOrder = (uuid) => {
    const {refresh} = this.props;
    if (this.state.isLoading)
      return;
    if (this.state.error) {
      return;
    }
    const body = {
      cost: parseFloat(this.state.cost),
      endAt: moment(this.state.endAt).utc()
    }
    this.setState({isLoading: true})
    return axios.put(`${host}/order/${uuid}/end`, body, config())
      .catch(e => alert(e))
      .finally(() => {
        this.setState({isLoading: false, open: false, cost: 0, uuid: undefined});
        refresh();
      });
  };

  onChangeCost = (e) => {
    this.state.error = (!e.target.value && e.target.value !== 0) || e.target.value < 0;
    this.setState({cost: e.target.value})
  };
  onChangeEndAt = (endDateTime) => {
    this.setState({endAt: moment.utc(endDateTime).local().format(LOCAL_DATE_TIME_FORMAT)})
  };

  componentDidUpdate(prevProps) {
    if (this.props?.open !== prevProps.open) {
      this.setState({open: this.props?.open});
    }
  }

  render() {
    const {status, uuid} = this.props;

    return (
      <React.Fragment>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          {status != "SUCCEEDED" && status != "REFUNDED" ? (
            <Tooltip title={"Finish the order"}>
              <IconButton
                onClick={() => {
                  this.setState({
                    open: true,
                  });
                }}
              >
                <CheckCircleIcon
                  style={{
                    color: green[400],
                  }}
                />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title="Order Finished">
              <IconButton>
                <CheckCircleIcon
                  style={{
                    color: grey[400],
                  }}
                />
              </IconButton>
            </Tooltip>
          )}
          <Dialog
            open={this.state.open}
            onClose={this.cancel}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title" className="primary">
              <h4>End The Current Order</h4>
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                To End the Order Maunally, please put the end datetime and the cost
              </DialogContentText>
              <Grid container spacing={2}>
                <Grid item sm={12} xs={12} md={12} lg={12}>
                  <CardContent>
                    <Grid container spacing={2} className="formLabel">
                      <Grid item md={12} lg={12}>
                        <TextField
                          id="news-create-content-id"
                          label="Cost"
                          fullWidth
                          type="number"
                          error={this.state.error}
                          helperText={this.state.error && "Cost must have a value and can not be negative."}
                          value={this.state.cost}
                          InputProps={{
                            readOnly: false,
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          variant="outlined"
                          onChange={this.onChangeCost}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} className="formLabel">
                      <Grid item md={12} lg={12}>
                        <DateTimePicker
                          label="End Time"
                          inputVariant="outlined"
                          value={this.state.endAt}
                          onChange={this.onChangeEndAt}
                          format={datetime_format}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <React.Fragment>
                {this.state.isLoading ? <CircularProgress style={{width: 20, height: 20}}/> : ""}
                <Button
                  onClick={() => this.endOrder(uuid)}
                  style={{color: "white", backgroundColor: "#36b9cc"}}
                >
                  END
                </Button>
                <Button
                  onClick={this.cancel}
                  color="primary"
                  disabled={this.state.isLoading}
                >
                  Cancel
                </Button>
              </React.Fragment>
            </DialogActions>
          </Dialog>
        </MuiPickersUtilsProvider>
      </React.Fragment>
    );
  }

}

export default EndOrderPopUp;
