import React from "react";
import {LayerGroup, LayersControl, MapContainer, TileLayer,} from "react-leaflet";
import {useFullScreenHandle} from "react-full-screen";
import "react-leaflet-fullscreen/dist/styles.css";
import VehicleMarker from "../../../../components/Map/VehicleMap/VehicleMarker";

export default function VehiclePopMap(props) {
  const vehicle = props.vehicle;
  const position = [vehicle?.location?.lat, vehicle?.location?.lng];
  const handle = useFullScreenHandle();

  return (
    <React.Fragment>
      <MapContainer
        center={position}
        zoom={13}
        scrollWheelZoom={true}
        key={`vehicle-detail-map-position-${JSON.stringify(position)}`}
      >
        <LayersControl position="topright">
          <LayersControl.BaseLayer checked name="Black And White">
            <TileLayer
              attribution='Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> Imagery © <a href="https://www.mapbox.com/">Mapbox</a>'
              url="https://api.mapbox.com/styles/v1/mapbox/dark-v10/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoidHJhY2RldmVsb3BlciIsImEiOiJja2szOGJteHMxOGxhMm9wYzFhbmgwazkyIn0._UOy-teYfb2kzsEMdr8YkA"
            />
          </LayersControl.BaseLayer>
          <LayersControl.BaseLayer name="Normal Map">
            <TileLayer
              attribution='Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> Imagery © <a href="https://www.mapbox.com/">Mapbox</a>'
              url="https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoidHJhY2RldmVsb3BlciIsImEiOiJja2szOGJteHMxOGxhMm9wYzFhbmgwazkyIn0._UOy-teYfb2kzsEMdr8YkA"
            />
          </LayersControl.BaseLayer>
          <LayersControl.BaseLayer name="Satellite Map">
            <TileLayer
              attribution='Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> Imagery © <a href="https://www.mapbox.com/">Mapbox</a>'
              url="https://api.mapbox.com/styles/v1/mapbox/satellite-v9/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoidHJhY2RldmVsb3BlciIsImEiOiJja2szOGJteHMxOGxhMm9wYzFhbmgwazkyIn0._UOy-teYfb2kzsEMdr8YkA"
            />
          </LayersControl.BaseLayer>
          <LayersControl.Overlay checked name="vehicle">
            <LayerGroup>
              <VehicleMarker
                vehicle={vehicle}
                key={`vehicle-map-marker-key-${vehicle.uuid}`}
              />
            </LayerGroup>
          </LayersControl.Overlay>
        </LayersControl>
      </MapContainer>
    </React.Fragment>
  );
}
