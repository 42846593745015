import React from "react";
import PropTypes from "prop-types";
import {Button, Dialog, DialogActions, DialogTitle, Grid,} from "@material-ui/core";

import axios from "axios";
import {config} from "../../../auth/auth";
import {grey} from "@material-ui/core/colors";
import CardContent from "@material-ui/core/CardContent";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import {search} from "../../../redux/city/action";
import {getAdminUsers} from "../../../redux/adminUser/adminUser.action";
import {getGroundTeamUsers} from "../../../redux/groundTeamUser/groundTeamUser.action";
import {search as searchVehicleList} from "../../../redux/vehicle/vehicle.action";
import {withAuth0} from "@auth0/auth0-react";
import { success } from "../../../redux/alert/alert.action";
import { error } from "../../../redux/alert/alert.action";
import {connect} from "react-redux";

const host = process.env.REACT_APP_HOST

class TaskCreateModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      assigneeUuid: "",
      assignerUuid: "",
      category: "",
      cityUuid: "",
      note: "",
      vehicleUuid: "",
      open: this.props.open,
    };
    this.regionData = {};
  }

  componentDidMount() {
    this.refreshCities();
    this.refreshAdminUsers();
    this.refreshGroundTeamUsers();
    this.refreshVehicleList();
  }

  static propTypes = {
    callback: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
  };

  cancel = () => {
    this.setState({
      discount: 0,
      open: false,
      regionName: "",
      category: "",
      cityUuid: "",
    });
    this.props.onCloseModal();
  };

  confirm = (callback) => {
    const { edit } = this.props;
    if (edit) {
      this.saveRegion(callback);
    } else {
      this.createNewTask(callback);
    }
  };

  refreshCities = () => {
    let params = new URLSearchParams();
    params.set("page", "0");
    params.set("size", "1000");
    this.props.search(params);
  };
  
  refreshAdminUsers = () => {
    this.props.getAdminUsers();
  };

  refreshGroundTeamUsers = () => {
    let params = new URLSearchParams();
    params.set("page", "0");
    params.set("size", "1000");
    this.props.getGroundTeamUsers();
  };

  refreshVehicleList = () => {
    const { searchVehicleList, filters } = this.props;
    let params = new URLSearchParams();
    params.set("page", "0");
    params.set("size", "1000");
    searchVehicleList(params);
  };

  saveRegion = (callback) => {
    const { regionDetails } = this.props;
    const body = {
      assigneeUuid: this.state.assigneeUuid,
      assignerUuid: this.state.assignerUuid,
      category: this.state.category,
      cityUuid: this.state.cityUuid,
      lat: this.state.lat,
      lng: this.state.lng,
      note: this.state.note,
      vehicleUuid: this.state.vehicleUuid,
    };
    return axios
      .put(`${host}/region/${regionDetails.uuid}`, body, config())
      .then((response) => callback())
      .catch((e) => alert(e))
      .finally(() => {
        this.setState({
          discount: 0,
          open: false,
          assigneeUuid: "",
          assignerUuid: "",
          category: "",
          cityUuid: "",
          lat: "",
          lng: "",
          note: "",
          vehicleUuid: "",
        });
        this.props.onCloseModal();
      });
  };

  createNewTask = (callback) => {
    const { lat, lng, vehicleId,currentAdminUser } = this.props;
    const body = {
      assigneeUuid: this.state.assigneeUuid,
      assignerUuid: currentAdminUser?.uuid,
      category: this.state.category,
      cityUuid: this.state.cityUuid,
      lat: lat,
      lng: lng,
      note: this.state.note,
      vehicleUuid: vehicleId,
    };
    if (!vehicleId) {
      delete body["vehicleUuid"];
    }
    return axios
      .post(`${host}/task/`, body, config())
      .then((response) => {
        this.props.success("Task created successfully");
        callback();
      })
      .catch((e) => {
        this.props.error(e?.response?.data?.message ? e?.response?.data?.message : e.toString());
      })
      .finally(() => {
        this.setState({
          open: false,
          assigneeUuid: "",
          assignerUuid: "",
          category: "",
          cityUuid: "",
          note: "",
          vehicleUuid: "",
        });
        this.props.onCloseModal();
      });
  };

  onChangeAssigneeUuid = (e) => {
    this.setState({ assigneeUuid: e.target.value });
  };
  onChangeAssignerUuid = (e) => {
    this.setState({ assignerUuid: e.target.value });
  };
  onChangeCategory = (e) => {
    this.setState({ category: e.target.value });
  };
  onChangeCityUuid = (e) => {
    this.setState({ cityUuid: e.target.value });
  };
  onChangeNote = (e) => {
    this.setState({ note: e.target.value });
  };
  onChangeVehicleUuid = (e) => {
    this.setState({ vehicleUuid: e.target.value });
  };
  // end

  componentDidUpdate(prevProps) {
    if (this.props?.open !== prevProps.open) {
      this.setState({ open: this.props?.open });
      const { currentAdminUser, vehicleId, } = this.props;
      if (vehicleId) {
        this.setState({
          assignerUuid: currentAdminUser?.uuid,
          assignerName: currentAdminUser?.firstName,
          category: "COLLECTION",
        });
      } else {
        this.setState({
          assignerUuid: currentAdminUser?.uuid,
          assignerName: currentAdminUser?.firstName,
          category: "DISTRIBUTION",
        });
      }
    }
  }
  render() {
    const {
      callback,
      cities,
      adminUsers,
      groundTeamUsers,
      vehicles,
      lat,
      lng,
      currentAdminUser,
      vehicleId,
      vehicleCategory
    } = this.props;
    const users = groundTeamUsers;
    if (!cities && !users && !vehicles) return <React.Fragment />;
    return (
      <React.Fragment>
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="Task Action Modal"
          fullWidth={true}
        >
          <DialogTitle
            id="Task Action Modal"
            style={{
              color: "white",
              backgroundColor: "#36b9cc",
              textAlign: "center",
            }}
          >
            <label>Create Task</label>
          </DialogTitle>
          <Grid container spacing={2}>
            <Grid item sm={12} xs={12} md={12} lg={12}>
              <CardContent style={{ paddingBottom: "0px" }}>
                {/* Assignee */}
                <Grid container spacing={2} className="formLabel">
                  <Grid item sm={12} xs={12} md={12} lg={12}>
                    <TextField
                      id="assigneeUuid"
                      select
                      label="Assignee"
                      value={this.state.assigneeUuid}
                      fullWidth
                      onChange={this.onChangeAssigneeUuid}
                      InputProps={{
                        readOnly: false,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                    >
                      {users?.content.map((option) => (
                        <MenuItem key={option.uuid} value={option.uuid}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                </Grid>
                {/* assignerUuid */}
                <Grid container spacing={2} className="formLabel">
                  <Grid item sm={12} xs={12} md={12} lg={12}>
                    <TextField
                      id="assignerUuid"
                      label="Assigner"
                      value={this.state.assignerName}
                      fullWidth
                      onChange={this.onChangeAssignerUuid}
                      InputProps={{
                        readOnly: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                    >
                    </TextField>
                  </Grid>
                </Grid>
                {/* category */}
                <Grid container spacing={2} className="formLabel">
                  <Grid item sm={12} xs={12} md={12} lg={12}>
                    <TextField
                      id="category"
                      select
                      label="Category"
                      value={this.state.category}
                      onChange={this.onChangeCategory}
                      InputProps={{
                        readOnly: false,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      fullWidth
                    >
                      <MenuItem
                        key="DISTRIBUTION"
                        value="DISTRIBUTION"
                        style={{
                          display: !vehicleId ? "block" : "none",
                        }}
                      >
                        Distribution
                      </MenuItem>
                      <MenuItem
                        key="COLLECTION"
                        value="COLLECTION"
                      >
                        Collection
                      </MenuItem>
                      <MenuItem
                        key="REPAIR"
                        value="REPAIR"
                        style={{
                          display: vehicleId ? "block" : "none",
                        }}
                      >
                        Repair
                      </MenuItem>
                      { vehicleCategory ==='BIKE' ?
                        <MenuItem
                          key="LOW_BATTERY"
                          value="LOW_BATTERY"
                          style={{
                            display: vehicleId ? "block" : "none",
                          }}
                          disabled="true"
                        >
                          Low Battery
                        </MenuItem>
                        :
                        <MenuItem
                          key="LOW_BATTERY"
                          value="LOW_BATTERY"
                          style={{
                            display: vehicleId ? "block" : "none",
                          }}
                        >
                          Low Battery
                        </MenuItem>
                      }
                      <MenuItem
                        key="GENERAL_MAINTENANCE"
                        value="GENERAL_MAINTENANCE"
                        style={{
                          display: vehicleId ? "block" : "none",
                        }}
                      >
                        General Maintenance
                      </MenuItem>
                      <MenuItem
                        key="COVID_CLEANING"
                        value="COVID_CLEANING"
                        style={{
                          display: vehicleId ? "block" : "none",
                        }}
                      >
                        Covid Cleaning
                      </MenuItem>
                    </TextField>
                  </Grid>
                </Grid>
                {/* city */}
                <Grid container spacing={2} className="formLabel">
                  <Grid item sm={12} xs={12} md={12} lg={12}>
                    <TextField
                      id="cityUuid"
                      select
                      label="City"
                      value={this.state.cityUuid}
                      fullWidth
                      onChange={this.onChangeCityUuid}
                      InputProps={{
                        readOnly: false,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                    >
                      {cities?.content.map((option) => (
                        <MenuItem key={option.uuid} value={option.uuid}>
                          {option.cityName}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                </Grid>
                {/* latitude */}
                <Grid container spacing={2} className="formLabel">
                  <Grid item sm={12} xs={12} md={12} lg={12}>
                    <TextField
                      id="lat"
                      label="Latitude"
                      fullWidth
                      type="text"
                      value={lat}
                      InputProps={{
                        readOnly: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
                {/* longitude */}
                <Grid container spacing={2} className="formLabel">
                  <Grid item sm={12} xs={12} md={12} lg={12}>
                    <TextField
                      id="lng"
                      label="Longitude"
                      fullWidth
                      type="text"
                      value={lng}
                      InputProps={{
                        readOnly: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
                {/* note */}
                <Grid container spacing={2} className="formLabel">
                  <Grid item sm={12} xs={12} md={12} lg={12}>
                    <TextField
                      id="lng"
                      label="Note"
                      fullWidth
                      type="text"
                      value={this.state.note}
                      onChange={this.onChangeNote}
                      InputProps={{
                        readOnly: false,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                    />
                  </Grid>
                </Grid>

                {/* <Grid container spacing={2} className="formLabel">
                  <Grid item sm={12} xs={12} md={12} lg={12}>
                    <TextField
                      id="vehicleUuid"
                      select
                      label="Vehicle"
                      value={this.state.vehicleUuid}
                      fullWidth
                      onChange={this.onChangeVehicleUuid}
                      InputProps={{
                        readOnly: false,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                    >
                      {vehicles?.content.map((option) => (
                        <MenuItem key={option.uuid} value={option.uuid}>
                          {option.frameNo}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                </Grid> */}
              </CardContent>
            </Grid>
          </Grid>

          <DialogActions
            style={{
              justifyContent: "space-between",
              padding: "10px 40px 15px 40px",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={() => this.confirm(callback)}
            >
              Save
            </Button>
            <Button onClick={this.cancel} style={{ color: grey[500] }}>
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  cities: state.cityReducer.cities,
  adminUsers: state.adminUsersData.adminUsers,
  vehicles: state.vehicleData.vehicles,
  groundTeamUsers: state.groundTeamUserReducer.groundTeamUsers,
  currentAdminUser: state.appData.currentAdminUser,
});

const mapDispatchToProps = (dispatch) => ({
  search: (params) => dispatch(search(params)),
  getAdminUsers: () => dispatch(getAdminUsers()),
  getGroundTeamUsers: () => dispatch(getGroundTeamUsers()),
  searchVehicleList: (params) => dispatch(searchVehicleList(params)),
  success: (msg) => dispatch(success(msg)),
  error: (msg) => dispatch(error(msg))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withAuth0(TaskCreateModal));
