import React from "react";
import PropTypes from 'prop-types';
import {IconButton, Tooltip} from "@material-ui/core";
import {green, red, yellow} from "@material-ui/core/colors";
import Battery20Icon from '@material-ui/icons/Battery20';
import Battery30Icon from '@material-ui/icons/Battery30';
import Battery60Icon from '@material-ui/icons/Battery60';
import Battery80Icon from '@material-ui/icons/Battery80';
import BatteryUnknownIcon from '@material-ui/icons/BatteryUnknown';
import BatteryFullIcon from '@material-ui/icons/BatteryFull';

class Battery extends React.Component {
  static propTypes = {
    value: PropTypes.number.isRequired,
    display: PropTypes.bool
  };

  render() {
    const { value, display } = this.props;

    let icon;
    if (value < 20) {
      icon = <Battery20Icon style={{ color: red[600] }}/>;
    }else if (value < 40) {
      icon = <Battery30Icon style={{ color: yellow[900] }}/>;
    }else if (value < 60) {
      icon = <Battery60Icon style={{ color: green[400] }}/>;
    }else if (value < 90) {
      icon = <Battery80Icon style={{ color: green[500] }}/>;
    }else if (value >= 90){
      icon = <BatteryFullIcon style={{ color: green[700] }}/>;
    }else {
      icon = <BatteryUnknownIcon style={{ color: red[500] }}/>;
    }
    const batteryLevel = (value || value === 0) ? `${value}%` : "";
    return (
      <React.Fragment>
        <Tooltip title={(value || value === 0) ? `${value}%` : "No Battery Info"}>
          <IconButton>
            {icon}
          </IconButton>
        </Tooltip>
        {display ? batteryLevel : ""}
      </React.Fragment>
    );
  }
}

export default Battery;