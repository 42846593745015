import React from "react";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { IconButton, Tooltip } from "@material-ui/core";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { grey } from "@material-ui/core/colors/";
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import PropTypes from "prop-types";
import axios from "axios";

import {config} from "../../../../auth/auth";
import {CircularProgress, Grid} from "@material-ui/core";
import CardContent from "@material-ui/core/CardContent";
import "./details.css";


const host = process.env.REACT_APP_HOST

class MembershipAccountRefund extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      open: false,
      amount: 0,
      uuid: this.props.uuid,
    };
  }

  static propTypes = {
    refresh: PropTypes.func.isRequired
  };

  cancel = () => {
    this.setState({open: false});
  };

  refund = () => {
    const { refresh, uuid } = this.props;
    if (this.state.isLoading)
      return;
    const body = {
      amount: parseFloat(this.state.amount),
    }
    this.setState({isLoading: true})
    return axios.post(`${host}/membershipAccount/${uuid}/refund`, body, config())
      .then((response) => {})
      .catch(e => alert(e))
      .finally(() => {
        this.setState({isLoading: false, open: false, amount: 0 });
        refresh();
      });
  };

  onChangeAmount = (e) => {
    this.setState({amount: e.target.value})
  };

  componentDidUpdate(prevProps) {
    if (this.props?.open !== prevProps.open) {
      this.setState({ open: this.props?.open });
    }
  }

  render() {
    const { status } = this.props;
    return (
      <React.Fragment>
        {status === 'ACTIVE' ?
          <Tooltip title={"Refund Active Membership to User"}>
            <IconButton
              onClick={() => {
                this.setState({
                  open: true,
                });
              }}
            >
              <AccountBalanceIcon
                style={{
                  color: grey[800],
                }}
              />
            </IconButton>
          </Tooltip> :
          <Tooltip title={"Membership Non-Refundable"}>
            <IconButton
              onClick={() => {}}
            >
              <AccountBalanceIcon
                style={{
                  color: grey[300],
                }}
              />
            </IconButton>
          </Tooltip>
        }
        <Dialog 
            open={this.state.open} 
            onClose={this.cancel} 
            aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title" className="primary">
            <h4>Refund Active Membership to User</h4>
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <div style={{color: 'red'}}>THIS ACTION CANNOT BE REVERTED BACK!</div>
              Please double check whether the user information is right. After you confirm, the money will be refunded.
            </DialogContentText>
            <Grid container spacing={2}>
              <Grid item sm={12} xs={12} md={12} lg={12}>
                  <CardContent>
                    <Grid container spacing={2} className="formLabel">
                      <Grid item md={12} lg={12}>
                        <TextField
                          id="news-create-content-id"
                          label="Refund Amount"
                          fullWidth
                          value={this.state.amount}
                          InputProps={{
                            readOnly: false,
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          variant="outlined"
                          onChange={this.onChangeAmount}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
              <React.Fragment>
                {this.state.isLoading ? <CircularProgress style={{width:20, height:20}}/> : ""}
                <Button
                  onClick={this.refund}
                  style={{color: "white", backgroundColor: "red"}}
                  disabled={this.state.amount<=0}
                >
                  Refund
                </Button>
                <Button
                  onClick={this.cancel}
                  color="primary"
                  disabled={this.state.isLoading}
                >
                  Cancel
                </Button>
              </React.Fragment>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }

}

export default MembershipAccountRefund;
