import React, {Component} from "react";
import {connect} from "react-redux";
import {withAuth0} from "@auth0/auth0-react";
import {getBatteryByUuid} from "../../../redux/battery/battery.action";
import {Grid} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import Avatar from "@material-ui/core/Avatar";
import {green, yellow} from "../../OperationCenter/OrderInformation/OrderDetail";
import BatteryStdIcon from '@material-ui/icons/BatteryStd';
import "./battery-management.css"
import CardContent from "@material-ui/core/CardContent";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import moment from "moment";
import TextField from "@material-ui/core/TextField";
import WarrantyLeft from "./battery-detail-warranty-left";

const dateFormat = process.env.REACT_APP_DATE_FORMAT;


class BatteryDetail extends Component {

  componentDidMount() {
    const uuid = this.props.location.pathname.replace("/battery/", "");

    if (!this.props?.battery || this.props?.battery?.uuid !== uuid){
      this.props.getBatteryByUuid(uuid);
    }
  }

  render() {
    const {battery} = this.props;

    if (!battery)
      return <React.Fragment> Loading... </React.Fragment>

    const chargingCycle = battery?.chargingCycle && battery?.warrantyTimes && `${battery?.chargingCycle}/${battery?.warrantyTimes}`;

    var warrantyPeriod = undefined;
    if (battery?.warrantyPeriod && battery?.manufactureOn) {
      const start = moment(battery?.manufactureOn);
      const end = moment();
      const duration = moment.duration(end.diff(start));
      const day = Math.round(duration.asDays());
      warrantyPeriod = `${day}/${battery?.warrantyPeriod}`;
    }

    return (
      <React.Fragment>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12} lg={8}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} lg={12}>
                <Card>
                  <CardHeader
                    avatar={
                      <Avatar
                        aria-label="Battery"
                        className="battery-detail-avatar"
                        alt="Battery Icon"
                      >
                        <BatteryStdIcon className="battery-detail-avatar-icon"/>
                      </Avatar>
                    }
                    titleTypographyProps={{ variant: "h5" }}
                    title={`Battery: ${battery?.uuid}`}
                    subheader={`Manufactured On: ${battery?.manufactureOn}`}
                  />
                </Card>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={12} lg={4}>
            <Card className="height100">
              <CardContent>
                <Row>
                  <Col xs={12} md={6}>
                    <div className="text-center">
                      <h4>Charging Cycle</h4>
                    </div>
                    <div className="text-center">
                      <h3 className="primary">
                        {chargingCycle ?? 'N/A'}
                      </h3>
                    </div>
                  </Col>
                  <Col xs={12} md={6}>
                    <div className="text-center">
                      <h4>Warranty Period</h4>
                    </div>
                    <div className="text-center">
                      <h3 className="primary">
                        {warrantyPeriod}
                      </h3>
                    </div>
                  </Col>
                </Row>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item md={12} lg={8}>
            <Card className="height100">
              <CardHeader title="Details" className="primary" />
              <CardContent>
                <Row>
                  <Col xs={12} md={6} className="padding10">
                    <TextField
                      id="battery-detail-charging-cycle-id"
                      label="Charging Cycle"
                      value={battery?.chargingCycle}
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                    />
                  </Col>
                  <Col xs={12} md={6} className="padding10">
                    <TextField
                      id="battery-detail-warranty-times-id"
                      label="Warranty Times"
                      value={battery?.warrantyTimes}
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={6} className="padding10">
                    <TextField
                      id="battery-detail-warranty-period-id"
                      label="Warranty Period"
                      value={battery?.warrantyPeriod}
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                    />
                  </Col>
                  <Col xs={12} md={6} className="padding10">
                    <TextField
                      id="battery-detail-manufacture-on-id"
                      label="Manufacture On"
                      value={moment(battery?.manufactureOn).local().format(dateFormat)}
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={6} className="padding10">
                    <TextField
                      id="battery-detail-battery-level-id"
                      label="Battery Level"
                      value={battery?.batteryLevel && `${battery?.batteryLevel}%`}
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                    />
                  </Col>
                  <Col xs={12} md={6} className="padding10">
                    <TextField
                      id="battery-detail-status-id"
                      label="Status"
                      value={battery?.status}
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={6} className="padding10">
                    <TextField
                      id="battery-detail-latest-usage-on-id"
                      label="Latest Usage On"
                      value={moment(battery?.latestUsageOn).local().format(dateFormat)}
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                    />
                  </Col>
                </Row>
              </CardContent>
            </Card>
          </Grid>
          <Grid item md={12} lg={4}>
            <Card className="height100">
              <CardHeader title="Warranty Left" className="primary" />
              <CardContent>
                <WarrantyLeft battery={battery}/>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  battery: state.batteryReducer.battery,
});

const mapDispatchToProps = (dispatch) => ({
  getBatteryByUuid: (uuid) => dispatch(getBatteryByUuid(uuid)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withAuth0(BatteryDetail));
