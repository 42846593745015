import {ActionTypes} from "./types";
import axios from "axios";
import {config} from "../../auth/auth";
import store from "../store";

const host = process.env.REACT_APP_HOST

export const getPrivacyPolicy = () =>{

  const partnerUuid = store.getState().appData.currentAdminUser?.partnerUuid;

  return async dispatch => {
    return axios.get(`${host}/partner/${partnerUuid}/privacyPolicy`, config())
      .then(
        response => {
          dispatch(_setPrivacyPolicyUrl(response.data?.url));
          dispatch(_setPrivacyPolicyContent(response.data?.content));
        },
        err => console.log(err)
      );
  }
}
export const setPrivacyPolicyUrl = (url) =>{
  return async dispatch => {dispatch(_setPrivacyPolicyUrl(url));};
}
export const setPrivacyPolicyContent = (content) =>{
  return async dispatch => {dispatch(_setPrivacyPolicyContent(content));};
}

export const postPrivacyPolicy  = (body) => {
  const partnerUuid = store.getState().appData.currentAdminUser?.partnerUuid;
  return async dispatch => {
    return axios.post(`${host}/partner/${partnerUuid}/privacyPolicy`,body, config())
      .then(
        response => {
          dispatch(_setPrivacyPolicyUrl(body?.url));
          dispatch(_setPrivacyPolicyContent(body?.content));
        },
        err => console.log(err)
      );
  }
}

export const _setPrivacyPolicyUrl = (payload) => ({
  type: ActionTypes.SET_PRIVACY_POLICY_URL,
  payload: payload,
});
export const _setPrivacyPolicyContent = (payload) => ({
  type: ActionTypes.SET_PRIVACY_POLICY_CONTENT,
  payload: payload,
});