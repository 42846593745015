import React, {useState} from 'react';
import VoucherTypeModal from "./VoucherTypeModal";
import DiscountModal from "./DiscountModal";
import {Dialog, IconButton, Tooltip} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import DiscountCodeModal from "./DiscountCodeModal";
import MultiStepForm, {FormStep} from "../../../../components/Form/MultistepForm/MultiStepForm";
import * as Yup from "yup";
import NormalVoucherCreate from "./NormalVoucherCreate";
import axios from "axios";
import {config} from "../../../../auth/auth";

const host = process.env.REACT_APP_HOST;

const initialDiscountState = {
  type: '',
  amount: '',
  discountType: '',
  discountValue: '',
  reusable: false,
  code: '',
  codeNumbers: '',
  startAt: '',
  endAt: '',
  quantity: 1,
  citiesUuid: []
}

const normalVoucherValidation = Yup.object({
  amount: Yup.number()
    .min(1, "Min amount is 1")
    .max(1000, "Max amount is 1000")
    .required().label("Voucher value "),
  reusable: Yup.bool().oneOf([true, false], "Required").required().label("Reusable  "),
  quantity: Yup.number()
    .min(1, "Min quantity is 1")
    .max(1000, "Max quantity is 1000").required().label("Quantity "),
  startAt: Yup.date().required("Field Required"),
  endAt: Yup.date()
    .min(Yup.ref("startAt"), "Must be after 'From' date")
    .required("Field Required"),
  citiesUuid: Yup.array()
});

const voucherTypeValidation = Yup.object({
  type: Yup.string().required().label("Voucher Type ")
});

const discountDetailsValidation = Yup.object({
  discountType: Yup.string().required().label("Discount type "),
  discountValue: Yup.string().required().label("Discount rate "),
  startAt: Yup.date().required("Field Required"),
  endAt: Yup.date()
    .min(Yup.ref("startAt"), "Must be after 'From' date")
    .required("Field Required"),
  quantity: Yup.number()
    .min(1, "Min quantity is 1")
    .max(1000, "Max quantity is 1000").required().label("Quantity "),
  citiesUuid: Yup.array()
});

const discountCodeValidation = Yup.object({
  code: Yup.string()
    .max(8, "Code includes max 8 characters")
    .matches("^[^0]", "Must not start with '0'")
    .required().label("Field")
});


const VoucherCreateModal = ({callback, cities}) => {
  const [openModal, setOpenModal] = useState(false);

  const createVoucher = (values) => {
    const body = {
      ...values,
      code: values.code.toUpperCase() + values.codeNumbers,
      discountType: values.discountType === '' ? undefined : values.discountType
    }

    axios
      .post(`${host}/voucher/`, body, config())
      .catch((e) => alert(e))
      .finally(() => {
        callback();
        setOpenModal(false);
      });
  }


  return (
    <React.Fragment>
      <Tooltip title="Create Voucher">
        <IconButton
          variant="contained"
          color="primary"
          onClick={() => setOpenModal(true)}
        >
          <AddIcon/>
        </IconButton>
      </Tooltip>
      <Dialog
        open={openModal}
        aria-labelledby="User Action Modal"
        fullWidth={true}
      >
        <MultiStepForm
          exceptionStep="SYSTEM_TOPUP"
          setOpenModal={setOpenModal}
          initialValues={initialDiscountState}
          onSubmit={(values) => createVoucher(values)}
        >
          <FormStep stepName="CreateVoucher"
                    validationSchema={normalVoucherValidation}>
            <NormalVoucherCreate cities={cities}/>
          </FormStep>
          <FormStep stepName="VoucherType"
                    validationSchema={voucherTypeValidation}>
            <VoucherTypeModal/>
          </FormStep>
          <FormStep stepName="DiscountDetails"
                    validationSchema={discountDetailsValidation}>
            <DiscountModal cities={cities}/>
          </FormStep>
          <FormStep stepName="DiscountCode"
                    validationSchema={discountCodeValidation}>
            <DiscountCodeModal/>
          </FormStep>
        </MultiStepForm>
      </Dialog>
    </React.Fragment>
  );
};

export default VoucherCreateModal;