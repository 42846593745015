import React from 'react';
import {DialogTitle, Grid} from "@material-ui/core";
import {InputTextField, NumberField, SelectField} from "../../../../components/Form/FormElements";

const PERIOD_UNIT = [
  {type: "DAY", description: "Day", value: "DAY"},
  {type: "WEEK", description: "Week", value: "WEEK"},
  {type: "MONTH", description: "Month", value: "MONTH"},
  {type: "YEAR", description: "Year", value: "YEAR"}];

const STATUS = [
  {type: "ACTIVE", description: "Active", value: "ACTIVE"},
  {type: "INACTIVE", description: "Inactive", value: "INACTIVE"}];


const CURRENCY = [
  {type: "SAR", description: "SAR", value: "SAR"}];

const UpdateMembershipDetails = () => {

  return (
    <React.Fragment>
      <DialogTitle
        id="User Action Modal"
        style={{
          color: "white",
          backgroundColor: "#333399",
          textAlign: "center",
        }}
      >
        <label>Update Membership Details</label>
      </DialogTitle>
      <Grid container spacing={2} className="formLabel px-3">
        <Grid item xs={12}>
          <InputTextField name="description" label="Description"/>
        </Grid>
      </Grid>
      <Grid container spacing={2} className="formLabel px-3">
        <Grid item sm={12} xs={12} md={12} lg={12}>
          <NumberField
            readOnly={false}
            name="price"
            label="Price"
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} className="formLabel px-3">
        <Grid item xs={12}>
          <NumberField
            readOnly={false}
            name="period"
            label="Period"
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2} className="formLabel px-3">
          <Grid item xs={12}>
            <SelectField name="periodUnit" label="Period Unit" options={PERIOD_UNIT}/>
          </Grid>
        </Grid>
        <Grid container spacing={2} className="formLabel px-3">
          <Grid item xs={12}>
            <SelectField name="status" label="Status" options={STATUS}/>
          </Grid>
        </Grid>
        <Grid container spacing={2} className="formLabel px-3">
          <Grid item xs={12}>
            <SelectField name="currency" label="Currency" options={CURRENCY}/>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default UpdateMembershipDetails;