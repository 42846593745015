import {ActionTypes} from "./regionRule.types";
import axios from "axios";
import {config, getAccessToken} from "../../auth/auth";

const host = process.env.REACT_APP_HOST

export const search = (queryParams) => {
    const config = {
        headers: {
            'Authorization': `Bearer ${getAccessToken()}`,
            'Content-Type': 'application/json'
        },
        params: queryParams
    }

    return async dispatch => {
        return axios.get(`${host}/regionRule`, config)
            .then(
                response => dispatch(setRegionRules(response.data)),
                err => console.log(err)
            );
    }
}

export const getRegionRuleByUuid = (uuid) => {
    return async dispatch => {
        return axios.get(`${host}/regionRule/${uuid}`, config())
            .then(
                response => dispatch(setRegionRule(response.data)),
                err => console.log(err)
            );
    }
}

export const searchRegionRuleActions  = (region_rule_uuid) => {
    return async dispatch => {
      return axios.get(`${host}/regionRuleAction/?regionRuleUuid=${region_rule_uuid}`, config())
        .then(
          response => dispatch(setRegionRuleActions(response.data)),
          err => console.log(err)
        );
    }
  }

export const searchRegionRuleNotifications  = (region_rule_uuid) => {
    return async dispatch => {
      return axios.get(`${host}/regionRuleNotification/?regionRuleUuid=${region_rule_uuid}`, config())
        .then(
          response => dispatch(setRegionRuleNotifications(response.data)),
          err => console.log(err)
        );
    }
  }

export const setRegionRules = (payload) => ({
    type: ActionTypes.SET_REGION_RULES,
    payload: payload,
});

export const setRegionRule = (payload) => ({
    type: ActionTypes.SET_REGION_RULE,
    payload: payload,
});

export const setRegionRuleActions = (payload) => ({
    type: ActionTypes.SET_REGION_RULE_ACTIONS,
    payload: payload,
});

export const setRegionRuleNotifications = (payload) => ({
    type: ActionTypes.SET_REGION_RULE_NOTIFICATIONS,
    payload: payload,
});