import React from "react";
import PropTypes from "prop-types";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";

class RegionPopup extends React.Component {
  static propTypes = {
    region: PropTypes.object.isRequired,
  };

  render() {
    const { region } = this.props;
    return (
      <Card style={{ maxWidth: 445, width: "100%", minWidth: "300px" }}>
        <CardContent>
          <Typography variant="body2" color="textSecondary" component="p">
            <b>Region UUID:</b> {region?.uuid}
            <br />
            <b>Region Name:</b> {region?.regionName}
            <br />
            <b>Category:</b> {region?.category}
            <br />
            <b>Status:</b> {region?.status}
            <br />
            <b>Speed Limit:</b>
            {region?.speedLimit}
            <br />
            <b>Discount:</b>
            {region?.discount}%<br />
          </Typography>
        </CardContent>
      </Card>
    );
  }
}

export default RegionPopup;
