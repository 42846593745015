import {ActionTypes} from "./transaction.types";
import axios from "axios";
import {config, getAccessToken} from "../../auth/auth";

const host = process.env.REACT_APP_HOST

export const search  = (queryParams) =>{
  const config = {
    headers: {
      'Authorization': `Bearer ${getAccessToken()}`,
      'Content-Type': 'application/json'
    },
    params: queryParams
  }

  return async dispatch => {
    return axios.get(`${host}/transaction`, config)
      .then(
        response =>dispatch(setTransactions(response.data)),
        err => console.log(err)
      );
  }
}

export const refresh = (trxUuid) => {
  return axios.get(`${host}/transaction/${trxUuid}/payment`, config());
}

export const setTransactions = (payload) => ({
  type: ActionTypes.SET_TRANSACTIONS,
  payload: payload,
});