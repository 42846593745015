import React from "react";
import PropTypes from 'prop-types';
import {CircularProgress} from "@material-ui/core";
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import {green, grey, red, orange} from "@material-ui/core/colors/";
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import TimerIcon from '@material-ui/icons/Timer';

class NotificationTaskStatus extends React.Component {
  constructor(props) {
    super(props);
  }

  static propTypes = {
    status: PropTypes.string.isRequired
  };

  render() {
    const {status} = this.props;
    if (status === 'CREATING' || status === 'EXECUTING') {
      return (
        <React.Fragment>
          <CircularProgress style={{width: 17, height: 17}}/>
          <span style={{color: green[400]}}>{status}</span>
        </React.Fragment>);
    } else if (status === 'SUCCESS') {
      return (
        <React.Fragment>
          <CheckCircleOutlineIcon style={{color: green[600]}}/>
          <span style={{color: green[600]}}>{status}</span>
        </React.Fragment>);
    } else if (status === 'FAILURE') {
      return (
        <React.Fragment>
          <ErrorOutlineIcon style={{color: red[400]}}/>
          <span style={{color: red[400]}}>{status}</span>
        </React.Fragment>
      );
    } else if (status === 'WAITING') {
      return (
        <React.Fragment>
          <TimerIcon style={{color: orange[400]}}/>
          <span style={{color: orange[400]}}>{status}</span>
        </React.Fragment>);
    } else {
      return (status);
    }
  }
}

export default NotificationTaskStatus;