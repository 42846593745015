import React, {useState} from 'react';
import {Form, Formik} from 'formik';
import FormNavigation from "./FormNavigation";

const MultiStepForm = ({children, initialValues, onSubmit, setOpenModal, exceptionStep}) => {
  const [stepNumber, setStepNumber] = useState(1);
  const steps = React.Children.toArray(children);

  const step = steps[stepNumber];
  const totalSteps = steps.length;
  const isLastStep = stepNumber === totalSteps - 1 || stepNumber === 0;

  const next = values => {
    if (stepNumber===1 && values.type === exceptionStep){
      setStepNumber(0);
    }else {
      setStepNumber(Math.min(stepNumber + 1, totalSteps - 1));
    }
  };

  const previous = values => {
    if (stepNumber === 0) {
      setStepNumber(1);
    }else {
      setStepNumber(Math.max(stepNumber - 1, 0));
    }
  };

  const cancelModal = () => {
    setStepNumber(1);
    setOpenModal(false);
  }


  const handleSubmit = async (values, bag) => {
    if (step.props.onSubmit) {
      await step.props.onSubmit(values);
    }
    if (isLastStep) {

      return onSubmit(values, bag);
    } else {
      bag.setTouched({});
      next(values);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={step.props.validationSchema}
    >
      {(formik) =>
        <Form>
          {step}
          <FormNavigation
            isLastStep={isLastStep}
            hasPrevious={stepNumber > 1 || stepNumber === 0}
            prevStep={() => previous(formik.values)}
            onCancel={cancelModal}
          />
        </Form>}
    </Formik>
  );
};

export default MultiStepForm;

export const FormStep = ({stepName, children}) => children;