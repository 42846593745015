import React, {Component} from 'react'
import RedistributionTaskCreationMap from '../../../../components/Map/RedistributionTaskCreationMap'
import TaskCreationPopUp from '../../../../components/PopUp/TaskCreationPopUp'

export default class RedistributionTask extends Component {
    
    state = {
        isOpen:false,
        category:''
    }
    togglePopup = (category) => {
        const newOpen = !this.state.isOpen
        const newCategory = category
        this.setState({isOpen:newOpen, category:newCategory})
    }

    render() {
        return (
            <div>
                <h1 className="h3 mb-2 text-gray-800">Task Creation</h1>
                <p>You can choose the vehicle you would like to redistribute and where you would like to deploy on the map.</p>
                
                    {this.state.isOpen && <TaskCreationPopUp
                        category={this.state.category}
                        handleClose={this.togglePopup}
                    />}
                <br/>
                <div className="row">
                    <RedistributionTaskCreationMap togglePopup={this.togglePopup}/>
                </div>
            </div>
        )
    }
}
