import React, {Component} from "react";
import {LayerGroup, LayersControl, MapContainer, TileLayer,} from "react-leaflet";
import {search} from "../../../redux/task/task.action";
import {connect} from "react-redux";
import {withAuth0} from "@auth0/auth0-react";
import TaskMarker from "../TaskMap/TaskMarker";

const MapFilterByStatus = [
  {label:"UNASSIGNED" , name: 'Unassigned'},
  {label:"ASSIGNED" , name: 'Assigned'},
  {label:"FINISHED" , name: 'Successfully Finished'},
  {label:"FAILED" , name: 'Failed'},
  {label:"CANCELED" , name: 'Canceled'},
];


class TaskMap extends Component {
  constructor(props) {
    super(props);
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (prevProps.filters !== this.props.filters) {
      this.refresh()
    }
  }

  refresh = () => {
    const { search, filters, history } = this.props;
    let params = new URLSearchParams();
    // pagination
    params.set("page", "0");
    params.set("size", "10");
    //filters
    filters?.map((f) => params.set(f.key, f.value));
    search(params);
  };

  render() {
    const { tasks, history } = this.props;
    const position = [22.402795,39.078354];

    if (!tasks) {
      return <React.Fragment>Loading...</React.Fragment>;
    }

    const todayTasks = (tasks.content || []).reduce((acc, curr) => {
      acc.push(curr);
      return acc;
    }, []);

    return (
      <div key="task-map-key-1">
        <div className="row">
          <div className="col-xl col-lg">
            <div className="card shadow mb-4">
              <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                <h6 className="m-0 font-weight-bold" style={{color: '#333399'}}>Task Map</h6>
              </div>

              <div className="card-body">
                <MapContainer
                  center={position}
                  zoom={13}
                  scrollWheelZoom={true}
                  style={{ height: "80vh" }}
                >
                  <LayersControl position="topright">
                    <LayersControl.BaseLayer checked name="Black And White">
                      <TileLayer
                        attribution='Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> Imagery © <a href="https://www.mapbox.com/">Mapbox</a>'
                        url="https://api.mapbox.com/styles/v1/mapbox/dark-v10/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoidHJhY2RldmVsb3BlciIsImEiOiJja2szOGJteHMxOGxhMm9wYzFhbmgwazkyIn0._UOy-teYfb2kzsEMdr8YkA"
                      />
                    </LayersControl.BaseLayer>
                    <LayersControl.BaseLayer name="Normal Map">
                      <TileLayer
                        attribution='Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> Imagery © <a href="https://www.mapbox.com/">Mapbox</a>'
                        url="https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoidHJhY2RldmVsb3BlciIsImEiOiJja2szOGJteHMxOGxhMm9wYzFhbmgwazkyIn0._UOy-teYfb2kzsEMdr8YkA"
                      />
                    </LayersControl.BaseLayer>
                    <LayersControl.BaseLayer name="Google Map">
                      <TileLayer
                        attribution='Map data &copy; <a href="https://www.google.com/maps">Google Map</a> Imagery © <a href="https://www.google.com/">Google</a>'
                        url="http://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}"
                        subdomains={['mt0','mt1','mt2','mt3']}
                      />
                    </LayersControl.BaseLayer>
                    { MapFilterByStatus.map((status, index) => (
                        <LayersControl.Overlay checked name={status.name} key={index}>
                          <LayerGroup>
                            {todayTasks.length &&
                              todayTasks?.filter(task => task.status === status.label).map((task, index) => <TaskMarker task={task} refresh={this.refresh} history={history} key={index}/>)}
                          </LayerGroup>
                        </LayersControl.Overlay>
                      ))
                    }
                  </LayersControl>
                </MapContainer>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  tasks: state.taskData.tasks,
});

const mapDispatchToProps = (dispatch) => ({
  search: (params) => dispatch(search(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withAuth0(TaskMap));
