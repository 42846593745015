import React, { useState } from "react";
import moment from "moment";
import { Tooltip } from "@material-ui/core";
import { Grid, CardContent } from "@material-ui/core";
import { Button, Dialog, DialogActions, DialogTitle } from "@material-ui/core";
import {Link} from "react-router-dom";
import axios from "axios";
import { config } from "../../../auth/auth";
import { grey } from "@material-ui/core/colors";
import {IconButton } from "@material-ui/core";
import ListAltIcon from '@material-ui/icons/ListAlt';
import Table from "react-bootstrap/Table";
const host = process.env.REACT_APP_HOST;
const datetime_format = process.env.REACT_APP_DATETIME_FORMAT;

export default function VoucherUserList({ uuid }) {
  const [openModal, setopenModal] = useState(false);
  const [userList, setuserList] = useState([]);

  const getUserList = () => {
    return axios
      .get(`${host}/voucher/${uuid}/user`, config())
      .then((response) => {
        const userList = response.data.content;
        setuserList(userList);
        setopenModal(true);
      })
      .catch((e) => alert(e))
      .finally(() => {});
  };

  return (
    <React.Fragment>
      <Tooltip title="User List">
        <IconButton
          aria-label="User List"
          onClick={() => {
            getUserList();
          }}
        >
          <ListAltIcon />
        </IconButton>
      </Tooltip>
      <Dialog
        open={openModal}
        aria-labelledby="User List Modal"
        fullWidth={true}
      >
        <DialogTitle
          id="User List Modal"
          style={{
            color: "white",
            backgroundColor: "#333399",
            textAlign: "center",
          }}
        >
          <label>User List</label>
        </DialogTitle>
        <Grid container spacing={2}>
          <Grid item sm={12} xs={12} md={12} lg={12}>
            <CardContent style={{ paddingBottom: "0px" }}>
              <Table striped bordered hover size="sm">
                <thead>
                  <tr>
                    <th className="text-center">User ID</th>
                    <th className="text-center">Usage Time</th>
                  </tr>
                </thead>
                <tbody>
                  {userList?.map((option) => (
                    <tr>
                      <td className="text-center"><Link to={`/user/${option?.userUuid}`}>{option?.userUuid}</Link></td>
                      <td className="text-center">{moment.utc(option?.usageTime).local().format(
                            datetime_format
                          )}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </CardContent>
          </Grid>
        </Grid>

        <DialogActions
          style={{
            justifyContent: "flex-end",
            padding: "10px 40px 15px 40px",
          }}
        >
          <Button
            style={{ color: grey[500] }}
            onClick={() => {
              setopenModal(false);
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
