import React, {Component} from "react";
import MUIDataTable from "mui-datatables";
import {withAuth0} from "@auth0/auth0-react";
import {FormControl, InputLabel, MenuItem, Select, Tooltip} from "@material-ui/core";
import {connect} from "react-redux";
import {search} from "../../../redux/membership/action";
import {queryParams} from "../../../utils/utils";
import Status from "../../../components/common/Status";
import MembershipCreate from "./MembershipCreate";
import MembershipEditModal from "./MembershipEditModal";
import { search as getCities } from "../../../redux/city/action";

const MEMBERSHIP_STATUSES = ["ACTIVE", "INACTIVE"];
const MEMBERSHIP_PERIOD_UNIT = ["DAY", "WEEK", "MONTH", "YEAR"];

class MembershipStructure extends Component {
  constructor(props) {
    super(props);
    this.isFilterDialogOpen = false;
    this.tableRef = React.createRef();
    this.columnsDisplay = undefined;
  }

  componentDidMount() {
    this.refresh();
  }

  refresh = () => {
    const {search, getCities} = this.props;

    // table state
    const state = this.tableRef?.current?.state;
    const params = queryParams(this.props, state);

    if (state && state["columns"]) {
      const statusIndex = state["columns"].findIndex(s => s.name === 'status');
      const periodUnitIndex = state["columns"].findIndex(s => s.name === 'periodUnit');
      const status = state["filterList"][statusIndex];
      const periodUnit = state["filterList"][periodUnitIndex];
      if (status) {
        params.set("status", status);
      }
      if (periodUnit) {
        params.set("periodUnit", periodUnit);
      }
    }

    search(params);
    getCities();
  };

  onChangeColumnDisplay = () => {
    const state = this.tableRef?.current?.state;
    this.columnsDisplay = state.columns.reduce(
      (obj, item) => Object.assign(obj, {[item.name]: item.display}),
      {}
    );
  };

  isDisplay = (name) => {
    if (!this.columnsDisplay || !this.columnsDisplay.hasOwnProperty(name))
      return undefined;
    else return this.columnsDisplay[name];
  };

  render() {
    const {memberships, cities} = this.props;
    if (!memberships) return <React.Fragment>Loading...</React.Fragment>;

    const list = memberships?.content;
    const totalElements = memberships?.totalElements ?? 0;
    const rowsPerPage = memberships?.pageable?.pageSize ?? 10;
    const page = memberships?.pageable?.pageNumber ?? 0;

    const columns = [
      {
        name: "uuid",
        label: "UUID",
        options: {
          filter: false,
          sort: false,
          display: this.isDisplay("uuid") ?? true,
          customFilterListOptions: {render: (v) => `UUID: ${v}`},
          filterType: "textField",
        },
      },
      {
        name: "name",
        label: "Membership Name",
        options: {
          filter: false,
          sort: false,
          display: this.isDisplay("name") ?? true,
        },
      },
      {
        name: "period",
        label: "Membership Period",
        options: {
          filter: true,
          sort: false,
          display: this.isDisplay("membershipPeriod") ?? true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <Tooltip>
                <div>{value}</div>
              </Tooltip>
            );
          },
        },
      },
      {
        name: "periodUnit",
        label: "Membership Period Unit",
        options: {
          filter: true,
          filterType: "custom",
          sort: false,
          display: this.isDisplay("periodUnit") ?? true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <Tooltip>
                <div>{value}</div>
              </Tooltip>
            );
          },
          filterOptions: {
            fullWidth: true,
            display: (filterList, onChange, index, column) => {
              return (
                <FormControl>
                  <InputLabel htmlFor='select-multiple-chip'>
                    Period Unit
                  </InputLabel>
                  <Select
                    value={filterList[index][0] ?? ""}
                    onChange={event => {
                      filterList[index] = [event.target.value];
                      onChange(filterList[index], index, column);
                    }}
                  >
                    {MEMBERSHIP_PERIOD_UNIT.map((status, index) => (
                      <MenuItem key={status} value={status}>
                        {status}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              );
            }
          }
        },
      },
      {
        name: "limits",
        label: "Daily Limitations (Mins)",
        options: {
          filter: false,
          sort: false,
          display: this.isDisplay("limits") ?? true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <Tooltip>
                {value.length > 0 ? <div>{value.find(obj => obj.limitUnit === 'DAY')?.limitation}</div> :
                  <div>Not Set</div>}
              </Tooltip>
            );
          },
        },
      },
      {
        name: "limits",
        label: "Total Limitations (Mins)",
        options: {
          filter: false,
          sort: false,
          display: this.isDisplay("limits") ?? true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <Tooltip>
                {value.length > 0 ? <div>{value.find(obj => obj.limitUnit === 'PERIOD')?.limitation}</div> :
                  <div>Not Set</div>}
              </Tooltip>
            );
          },
        },
      },
      {
        name: "cityName",
        label: "City",
        options: {
          filter: false,
          sort: false,
          display: this.isDisplay("cityName") ?? true,
        },
      },
      {
        name: "price",
        label: "Price",
        options: {
          filter: false,
          sort: false,
          display: this.isDisplay("price") ?? true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <Tooltip>
                <div>{value}</div>
              </Tooltip>
            );
          },
        },
      },
      {
        name: "currency",
        label: "Currency",
        options: {
          filter: false,
          sort: false,
          display: this.isDisplay("currency") ?? true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <Tooltip>
                <div>{value}</div>
              </Tooltip>
            );
          },
        },
      },
      {
        name: "status",
        label: "Status",
        options: {
          filter: true,
          filterType: "custom",
          sort: false,
          display: this.isDisplay("status") ?? true,
          customBodyRender: (value, tableMeta, updateValue) => {
            const uuid = tableMeta.rowData[0];
            return (
              <Status
                checked={value === "ACTIVE" || value === "active"}
                activeUrl={`/membership/${uuid}/active`}
                inactiveUrl={`/membership/${uuid}/deactivate`}
                onChanged={() => this.refresh()}
              />
            );
          },
          filterOptions: {
            fullWidth: true,
            display: (filterList, onChange, index, column) => {
              return (
                <FormControl>
                  <InputLabel htmlFor='select-multiple-chip'>
                    Status
                  </InputLabel>
                  <Select
                    value={filterList[index][0] ?? ""}
                    onChange={event => {
                      filterList[index] = [event.target.value];
                      onChange(filterList[index], index, column);
                    }}
                  >
                    {MEMBERSHIP_STATUSES.map((status, index) => (
                      <MenuItem key={status} value={status}>
                        {status}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              );
            }
          }
        },
      },
      {
        name: "Operations",
        options: {
          filter: false,
          sort: false,
          empty: true,
          customBodyRender: (value, tableMeta) => {
            return (
              <div>
                <MembershipEditModal membership={list[tableMeta.rowIndex]} callback={() => this.refresh()}/>
              </div>
            );
          },
        },
      },
    ];

    const options = {
      filter: true,
      confirmFilters: false,
      serverSide: true,
      filterType: "dropdown",
      responsive: "vertical",
      rowsPerPageOptions: [10, 20, 50, 100],
      rowsPerPage: rowsPerPage,
      count: totalElements,
      page: page,
      search: false,
      fixedHeader: true,
      tableBodyHeight: "900px",
      onTableChange: (action, tableState) => {
        switch (action) {
          case "viewColumnsChange":
            this.onChangeColumnDisplay();
            break;
          case "onFilterDialogOpen":
            this.isFilterDialogOpen = true;
            break;
          case "filterChange":
            if (this.isFilterDialogOpen) return;
            else this.refresh();
            break;
          case "onFilterDialogClose":
            this.isFilterDialogOpen = false;
          case "changePage":
          case "sort":
          case "changeRowsPerPage":
            this.refresh();
            break;
          default:
            console.log("Not handled action " + action);
        }
      },
      customToolbar: () => {
        return (
          <>
            <MembershipCreate callback={() => this.refresh()} cities={cities}/>
          </>

        );
      }
    };

    return (
      <React.Fragment>
        <MUIDataTable
          title={<h1 className="tableTitle">Membership Structure</h1>}
          data={list}
          columns={columns}
          options={options}
          ref={this.tableRef}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  memberships: state.membershipReducer.memberships,
  cities: state.cityReducer.cities,
});

const mapDispatchToProps = (dispatch) => ({
  search: (params) => dispatch(search(params)),
  getCities: (params) => dispatch(getCities(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withAuth0(MembershipStructure));
