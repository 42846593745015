import React from "react";
import {LayersControl, MapConsumer, MapContainer, TileLayer,} from "react-leaflet";
import L from "leaflet";
import "leaflet-routing-machine";
import "leaflet-routing-machine/dist/leaflet-routing-machine.css";
import "leaflet/dist/leaflet.css";
import {connect} from "react-redux";
import {withAuth0} from "@auth0/auth0-react";
import {searchRoutes} from "../../../../redux/order/action";
import "./orderMap.css";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import {Button, Grid} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import moment from "moment";

const datetime_format = process.env.REACT_APP_DATETIME_FORMAT;

class OrderMapComponent extends React.Component {
  constructor(props) {
    super(props);
    this.ordersRef = undefined;
    this.map = undefined;
    this.routingControls = [];
    this.presentPageNumber = 0;
    this.totalPages = 0;
    this.presentCount = 10;
    let date = moment();
    this.orderMapRoutes = [];
    this.pageNumber = undefined;
    let toDate = date.subtract(10, "day").format("YYYY-MM-DDTHH:mm");
    this.state = {
      loading: true,
      searchLoader: false,
      showFilters: false,
      fromDate: toDate,
      toDate: moment(new Date()).format("YYYY-MM-DDTHH:mm"),
      loadRoutes: 10,
    };
    this.toDate = moment(new Date()).format("YYYY-MM-DDTHH:mm");
    this.fromDate = toDate;
    this.loadRoutes = 10;
    this.loadMapRoutes = false;
    this.refreshFunctClicked = false;
  }

  componentDidMount() {
    this.refresh();
  }

  refresh = () => {
    const { filters } = this.props;
    let params = new URLSearchParams();
    filters?.map((f) => params.set(f.key, f.value));
    // pagination
    params.set("page", this.presentPageNumber.toString());
    params.set("size", "20");
    // based on load more Function presentPageNumber Changes
    if (this.presentPageNumber === 0) {
      this.searchOrderRoutes(params);
    } else if (
      this.presentPageNumber &&
      this.presentPageNumber < this.totalPages
    ) {
      this.searchOrderRoutes(params);
    } else {
      this.setState({ loading: false });
      const loadMoreBtnEle = document.getElementsByClassName("loadMoreBtn")[0];
      loadMoreBtnEle.style.display = "none";
    }
  };
  searchOrderRoutes = (params) => {
    const { searchRoutes } = this.props;
    searchRoutes(params).then((res) => {
      this.totalPages = res?.payload.totalPages;
      this.pageNumber = res?.payload.number;
      this.setState({ loading: false });
    });
  };

  loadOrders = () => {
    this.loadMapRoutes = true;
    this.refreshFunctClicked = false;
    this.presentPageNumber = this.pageNumber + 1;
    this.refresh();
    this.setState({ loading: true });
  };

  loadOrderMapRoutes = () => {
    // orderRoutes
    const {
      orderRoutes,
      fromComponent,
      fromOrderDetailsComponent,
      order,
    } = this.props;
    let ordersData = orderRoutes?.content;
    let fitSelectedRoutesValue = false;
    if (ordersData.length === 0)
      return <React.Fragment>Loading...</React.Fragment>;

    if(this.refreshFunctClicked) {
      return false;
    }

    if (fromComponent || fromOrderDetailsComponent) {
      fitSelectedRoutesValue = true;
      this.clearRoutesOnMap();
      const loadMoreBtnEle = document.getElementsByClassName("loadMoreBtn")[0];
      loadMoreBtnEle.style.display = "none";
    }
    let filterOrdersData = [];

    // filter data where rideHistoryLocations exists
    if (ordersData.length) {
      filterOrdersData = ordersData.filter((order) => {
        return order?.rideHistoryLocations;
      });
      if (filterOrdersData.length === 0) {
        return false;
      }
    } else {
      return false;
    }
    filterOrdersData?.map((order) => {
      const wayPointsData = this.buildWaypoints(order?.rideHistoryLocations);
      if (wayPointsData.length) {
        var routing = L.polyline(
          wayPointsData,
          {
            opacity: 0.6,
            color: "#7CFC00",
            weight: 3,
          }
          );
          routing.on("mouseover", (e) => {
            var popUpHtml = `<Card style={{ maxWidth: 445, width: "100%", minWidth: "300px" }}>
                                <CardContent>
                                  <Typography variant="body2" color="textSecondary" component="p">
                                    <b>Order ID:</b> <a href="/order/${order?.uuid}">${order?.uuid}</a>
                                    <br />
                                    <b>Order Status:</b> ${order?.status}
                                    <br />
                                    <b>Vehicle QR Code:</b> ${
                                      order?.vehicleQrCode ||
                                      order?.vehicle?.qrCode
                                    }
                                    <br />
                                    <b>User Name:</b> ${
                                      order?.userName ||
                                      order?.user?.firstName
                                    }
                                    <br />
                                    <b>Order Start Time:</b> ${moment(
                                      order?.startAt
                                    )
                                      .local()
                                      .format(datetime_format)}
                                    <br />
                                    <b>Riding period:</b> ${
                                      order?.vehicleUuid ||
                                      order?.vehicle?.uuid
                                    }
                                    <br />
                                    <b>Cost:</b> ${order?.cost} ${
              order?.currency
            }
                                    <br />
                                  </Typography>
                                </CardContent>
                              </Card>`;
            routing.bindPopup(popUpHtml);
            routing.openPopup();
          });
          routing.addTo(this.map)
        this.routingControls.push(routing);
      }
    });
  };

  // constructing waypoints
  buildWaypoints = (rideHistory) => {
    let wayPointsData = [];
    if (rideHistory.length) {
      rideHistory.map((value, i) => {
        if (rideHistory[i][0] !== 0 && rideHistory[i][1] !== 0) {
          wayPointsData.push(
            L.latLng(rideHistory[i][0], rideHistory[i][1])
          );
        }
      });
    }
    return wayPointsData;
  };

  // These are for Filter fields
  onChangeFromDate = (e) => {
    this.setState({ fromDate: e.target.value });
  };
  onChangeToDate = (e) => {
    this.setState({ toDate: e.target.value });
  };

  // filter records
  filterRecords = () => {
    this.setState({ searchLoader: true });
    let params = new URLSearchParams();
    params.set("startDateTime", this.state.fromDate);
    params.set("endDateTime", this.state.toDate);
    params.set("size", "1000");
    const { searchRoutes } = this.props;
    searchRoutes(params).then((res) => {
      this.setState({ searchLoader: false });
      this.clearRoutesOnMap();
      this.refreshFunctClicked = false;
      this.loadOrderMapRoutes();
    });
  };

  // start from the first
  refreshOrderMap = () => {
    if (this.map) {
      this.refreshFunctClicked = true;
      this.clearRoutesOnMap();
      this.pageNumber = 0;
      const loadMoreBtnEle = document.getElementsByClassName("loadMoreBtn")[0];
      loadMoreBtnEle.style.display = "block";
      this.setState({ loading: false});
    }
  };

  // Clear routes on the Order Map
  clearRoutesOnMap = () => {
    if (this.map) {
      this.map.eachLayer((layer) => {
        if (this.map.hasLayer(layer) && layer?.options?.tag === "routing")
          this.map.removeLayer(layer);
      });
    }
  };

  showfiltersPanel = () => {
    this.setState({ showFilters: true });
  };

  render() {
    const {
      orderRoutes,
      fromComponent,
      fromOrderDetailsComponent,
    } = this.props;
    if (!orderRoutes) return <React.Fragment>Loading...</React.Fragment>;
    const ordeList = orderRoutes?.content;

    let showfilterCard = true;
    if(fromComponent || fromOrderDetailsComponent) {
      showfilterCard = false;
    }

    const position = ordeList[0]?.startLocation[0] ?? [22.402795,39.078354];
    return (
      <React.Fragment>
        <Card  style={{
                    display: showfilterCard ? "block" : "none",
                  }}>
          <CardContent>
            <Grid container spacing={2} justify="center" alignItems="center">
              <Grid item xs={12} md={3} lg={3}>
                <TextField
                  id="datetime-local"
                  label="From"
                  fullWidth
                  type="datetime-local"
                  value={this.state.fromDate}
                  variant="outlined"
                  onChange={this.onChangeFromDate}
                  style={{
                    display: this.state.showFilters ? "block" : "none",
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={3} lg={3}>
                <TextField
                  id="datetime-local"
                  label="To"
                  fullWidth
                  type="datetime-local"
                  value={this.state.toDate}
                  style={{
                    display: this.state.showFilters ? "block" : "none",
                  }}
                  variant="outlined"
                  onChange={this.onChangeToDate}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={3} lg={1}></Grid>
              <Grid item xs={12} md={3} lg={1}>


              </Grid>
              <Grid item xs={12} md={1} lg={1}>
                <Button
                  variant="contained"
                  disableElevation
                  className="filters"
                  onClick={() => this.showfiltersPanel()}
                  style={{
                    marginTop: "2px",
                    display: !this.state.showFilters ? "block" : "none",
                    color: "white",
                    backgroundColor: "#333399"
                  }}
                >
                  Filters
                </Button>
                 <Button
                  variant="contained"
                  disableElevation
                  className="search"
                  style={{
                    marginTop: "2px",
                    display: this.state.showFilters ? "block" : "none",
                    float: "right",
                    color: "white",
                    backgroundColor: "#333399"
                  }}
                  onClick={() => this.filterRecords()}
                  disabled={this.state?.searchLoader}
                >
                  {this.state.searchLoader ? (
                    <CircularProgress
                      style={{ width: 22, height: 22 }}
                      className="white"
                    />
                  ) : (
                    "Search"
                  )}
                </Button>
              </Grid>
              <Grid item xs={12} md={1} lg={2}>
                  <Button
                      variant="contained"
                      style={{
                        marginTop: "2px",
                        color: "white",
                        backgroundColor: "#333399"
                  }}
                      disableElevation
                      className="loadMoreBtn"
                      onClick={() => this.loadOrders()}
                      disabled={this.state?.loading}
                    >
                      {this.state.loading ? (
                        <CircularProgress
                          style={{ width: 15, height: 15 }}
                          className="white"
                        />
                      ) : (
                        "Load more"
                      )}
                    </Button>
              </Grid>
              <Grid item xs={12} md={1} lg={1}>
               <Button
                  variant="contained"
                  style={{
                    marginTop: "2px",
                    color: "white",
                    backgroundColor: "#333399"
               }}
                  disableElevation
                  className="refresh"
                  onClick={() => this.refreshOrderMap()}
                >
                  Refresh
                </Button>

              </Grid>
             
            </Grid>
          </CardContent>
        </Card>

        <Card>
          <CardHeader
            title="Order Map"
            style={{ paddingBottom: "0px", paddingTop: "9px" }}
          />
          <CardContent>
            <MapContainer
              center={position}
              zoom={7}
              scrollWheelZoom={true}
              style={{
                height:
                  !fromComponent || !fromOrderDetailsComponent
                    ? "85vh"
                    : "50vh",
              }}
            >
              <LayersControl position="topright">
                <LayersControl.BaseLayer
                  checked
                  name="Black And White"
                  tag="mapBaseLayer"
                >
                  <TileLayer
                    attribution='Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> Imagery © <a href="https://www.mapbox.com/">Mapbox</a>'
                    url="https://api.mapbox.com/styles/v1/mapbox/dark-v10/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoidHJhY2RldmVsb3BlciIsImEiOiJja2szOGJteHMxOGxhMm9wYzFhbmgwazkyIn0._UOy-teYfb2kzsEMdr8YkA"
                  />
                </LayersControl.BaseLayer>
                <LayersControl.BaseLayer name="Normal Map" tag="mapBaseLayer">
                  <TileLayer
                    attribution='Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> Imagery © <a href="https://www.mapbox.com/">Mapbox</a>'
                    url="https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoidHJhY2RldmVsb3BlciIsImEiOiJja2szOGJteHMxOGxhMm9wYzFhbmgwazkyIn0._UOy-teYfb2kzsEMdr8YkA"
                  />
                </LayersControl.BaseLayer>
                <LayersControl.BaseLayer
                  name="Satellite Map"
                  tag="mapBaseLayer"
                >
                  <TileLayer
                    attribution='Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> Imagery © <a href="https://www.mapbox.com/">Mapbox</a>'
                    url="https://api.mapbox.com/styles/v1/mapbox/satellite-v9/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoidHJhY2RldmVsb3BlciIsImEiOiJja2szOGJteHMxOGxhMm9wYzFhbmgwazkyIn0._UOy-teYfb2kzsEMdr8YkA"
                  />
                </LayersControl.BaseLayer>
                <MapConsumer>
                  {(map) => {
                    if (ordeList.length) {
                      this.map = map;
                      this.orderMapRoutes = ordeList;
                      this.loadOrderMapRoutes();
                      return true;
                    } else {
                      // map.setView([44.907852, 7.673789], 16);
                      return true;
                    }
                  }}
                </MapConsumer>
              </LayersControl>
            </MapContainer>
          </CardContent>
        </Card>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  orderRoutes: state.orderReducer.orderRoutes,
  order: state.orderReducer.order,
});
const mapDispatchToProps = (dispatch) => ({
  searchRoutes: (params) => dispatch(searchRoutes(params)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withAuth0(OrderMapComponent));

