import {ActionTypes} from "./types";
import axios from "axios";
import {config} from "../../auth/auth";
import store from "../store";

const host = process.env.REACT_APP_HOST

export const getTermsAndConditions = () =>{

  const partnerUuid = store.getState().appData.currentAdminUser?.partnerUuid;

  return async dispatch => {
    return axios.get(`${host}/partner/${partnerUuid}/termsAndConditions`, config())
      .then(
        response => {
          dispatch(_setUrl(response.data?.url));
          dispatch(_setContent(response.data?.content));
        },
        err => console.log(err)
      );
  }
}
export const setUrl = (url) =>{
  return async dispatch => {dispatch(_setUrl(url));};
}
export const setContent = (content) =>{
  return async dispatch => {dispatch(_setContent(content));};
}

export const postTermsAndConditions  = (body) => {
  const partnerUuid = store.getState().appData.currentAdminUser?.partnerUuid;
  return async dispatch => {
    return axios.post(`${host}/partner/${partnerUuid}/termsAndConditions`,body, config())
      .then(
        response => {
          dispatch(_setUrl(body?.url));
          dispatch(_setContent(body?.content));
        },
        err => console.log(err)
      );
  }
}

export const _setUrl = (payload) => ({
  type: ActionTypes.SET_URL,
  payload: payload,
});
export const _setContent = (payload) => ({
  type: ActionTypes.SET_CONTENT,
  payload: payload,
});