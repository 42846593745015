import {GroundTeamUserActionTypes} from "./groundTeamUser.types";

const INITIAL_STATE = {
    groundTeamUsers: undefined,
    groundTeamDetails: {},
    groundTeamWorkingHours:[],
    groundTeamWorkRecord: undefined
};

const groundTeamUserReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GroundTeamUserActionTypes.SET_GROUND_TEAM_USERS:
            return {
                ...state,
                groundTeamUsers: action.payload
            };
        case GroundTeamUserActionTypes.SET_GROUND_TEAM_DETAILS:
            return {
                ...state,
                groundTeamDetails: action.payload
            };
            break;
        case GroundTeamUserActionTypes.SET_GROUND_TEAM_DAILY_WORKING_HOURS:
              return {
                  ...state,
                  groundTeamWorkingHours: action.payload
              };
              break;
        case GroundTeamUserActionTypes.SET_GROUND_TEAM_WORK_RECORD:
            return {
                ...state,
                groundTeamWorkRecords: action.payload
            };
            break;  
        default:
            return state;
    }
};

export default groundTeamUserReducer;
