import React, {useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Button from "@material-ui/core/Button";
import {faDownload} from "@fortawesome/free-solid-svg-icons";
import {ButtonGroup, IconButton, Tooltip} from "@material-ui/core";
import {getRevenue} from "../../../redux/dashboard/dashboard.action";
import { getTopUp } from "../../../redux/dashboard/dashboard.action";
import {getCurrency} from "../../../redux/partners/action";
import {connect} from "react-redux";
import {withAuth0} from "@auth0/auth0-react";
import {Skeleton} from "@material-ui/lab";
import {
  transformDailyDates,
  transformMonthlyDates,
  downloadXlsx
} from "../../../utils/utils";
import {withTranslation} from 'react-i18next';
import {makeStyles} from "@material-ui/core/styles";
import {search} from "../../../redux/city/action";
import TextField from "@material-ui/core/TextField";
import TopUpChart from "../../Chart/TopUpChart";

const refresh_frequency =
  process.env.REACT_APP_REFRESH_FREQUENCY_MINUTES * 60000 ?? 600000;

const useStyles = makeStyles(() => ({
  activeBtn: {
    color: "white",
    backgroundColor: "#333399",
    '&:hover': {
      color: "white",
      backgroundColor: "#333399",
      borderColor: "#333399",
      border: '1px solid',
    }
  },
  neutralBtn: {
    color: "#333399",
    backgroundColor: "white",
    borderColor: "#333399",
    border: '1px solid',
    '&:hover': {
      color: "white",
      backgroundColor: "#333399",
      transition: '0.3s',
    }
  }
}))

const TopUpCard = ({topUp, t, partnerCurrency, getTopUp, getCurrency, search, cities}) => {
  const [selectedPeriod, setSelectedPeriod] = useState("monthly");
  const [city, setCity] = useState('');
  const styles = useStyles();

  useEffect(() => {
    if (!topUp) {
      refresh();
    }
  }, [])

  const refresh = () => {
    getTopUp();
    getCurrency();
    search();
    setTimeout(() => refresh(), refresh_frequency);
  };

  if (!topUp) {
    return (
      <div className="col-xl col-lg">
        <Skeleton variant="rect" width={"100%"} height={500}/>
      </div>
    );
  }

  let showDate = topUp[`${selectedPeriod}`]?.date;
  if (selectedPeriod === "daily") {
    showDate = transformDailyDates(showDate);
  } else if (selectedPeriod === "monthly") {
    showDate = transformMonthlyDates(showDate);
  }

  const showData = topUp[`${selectedPeriod}`]?.data;
  const bankCardData = topUp[`${selectedPeriod}`]?.bankCardData;
  const stcPayData = topUp[`${selectedPeriod}`]?.stcPayData;
  const googlePayData = topUp[`${selectedPeriod}`]?.googlePayData;
  const applePayData = topUp[`${selectedPeriod}`]?.applePayData;

  const handleSetPeriod = (period) => {
    setSelectedPeriod(period);
  }

  const onChangeCityUuid = (e) => {
    setCity(e.target.value);
    getTopUp(e.target.value);
  }

  return (
    <div className="col-xl col-lg">
      <div className="card shadow mb-4">
        <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
          <div style={{padding: "4px"}}>
            <h6 className="m-0 font-weight-bold"
                style={{color: '#333399', paddingBottom: '5px'}}>TopUp Overview</h6>
            <TextField
              id="cityUuid"
              select
              // label="City"
              placeholder="City"
              value={city}
              fullWidth
              SelectProps={{
                native: true,
              }}
              onChange={onChangeCityUuid}
              variant="outlined"
            >
              <option key="" value="">All Cities</option>
              
              {cities?.content.map((option) => (
                <option key={option.uuid} value={option.uuid}>
                  {option.cityName}
                </option>
              ))}
            </TextField>
          </div>
          <div style={{padding: "4px"}}>
            <ButtonGroup>
              <Button
                className={selectedPeriod === "daily" ? styles.activeBtn : styles.neutralBtn}
                onClick={() => handleSetPeriod('daily')}
              >
                {t('common.tab-day')}
              </Button>
              <Button
                className={selectedPeriod === "monthly" ? styles.activeBtn : styles.neutralBtn}
                onClick={() => handleSetPeriod('monthly')}
              >
                {t('common.tab-month')}
              </Button>
              <Button
                className={selectedPeriod === "annually" ? styles.activeBtn : styles.neutralBtn}
                onClick={() => handleSetPeriod('annually')}
              >
                {t('common.tab-year')}
              </Button>
            </ButtonGroup>
            <Tooltip title=" Download">
              <IconButton
                size="small"
                aria-label=" Download"
                style={{padding: 10}}
                onClick={() => {
                  downloadXlsx(topUp, " TopUp");
                }}
              >
                <FontAwesomeIcon icon={faDownload}/>
              </IconButton>
            </Tooltip>
          </div>
        </div>

        <div className=" card-body">
          <TopUpChart
            date={showDate}
            data={showData}
            bankCardData={bankCardData}
            stcPayData={stcPayData}
            googlePayData={googlePayData}
            applePayData={applePayData}
            yAxisLabel={partnerCurrency}
          />
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  topUp: state.dashboardReducer.topUp,
  partnerCurrency: state.partnerReducer.partnerCurrency,
  cities: state.cityReducer.cities
});

const mapDispatchToProps = (dispatch) => ({
    getTopUp: (cityUuid) => dispatch(getTopUp(cityUuid)),
    getCurrency: () => dispatch(getCurrency()),
    search: (params) => dispatch(search(params))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withAuth0(withTranslation()(TopUpCard)));
