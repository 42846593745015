import React from "react";
import PropTypes from 'prop-types';

class Amount extends React.Component {
  static propTypes = {
    value: PropTypes.number.isRequired,
    currency: PropTypes.string.isRequired
  };

  render() {
    const { value, currency } = this.props;
    if (!value || !currency)
      return (<React.Fragment/>);

    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: currency,
    });

    const ret = formatter.format(value);

    return (
      <React.Fragment>
        {ret}
      </React.Fragment>
    );
  }
}

export default Amount;