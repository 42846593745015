import React, {Component} from "react";
import {connect} from "react-redux";
import {withAuth0} from "@auth0/auth0-react";
import {getPages, getRoleByUuid, getUsers, setRole, updateRole} from "../../../../redux/role/role.action";
import {Button, CircularProgress, Grid} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import TextField from "@material-ui/core/TextField";
import UserSelector from "./user-selector";
import RoleUsers from "./role-users";
import RolePages from "./role-pages";
import PageSelector from "./page-selector";


class RoleDetail extends Component {

  constructor(props) {
    super(props);
    this.isDirty = false;
    this.state = {
      loading: false
    }
  }

  uuid = () => {
    return this.props.location.pathname.replace("/role/", "");
  }

  componentDidMount() {
    this.refresh();
  }

  refresh = () => {
    this.props.getRoleByUuid(this.uuid());
    this.props.getUsers(this.uuid());
    this.props.getPages(this.uuid());
    this.isDirty = false;
  };

  onChangeDetails = (fieldName, value) => {
    let updated = Object.assign({}, this.props.role, {[fieldName]: value});
    this.props.setRole(updated);
    this.isDirty = true;
  }

  onClickSaveBtn = () => {
    this.setState({loading: true});
    this.props.updateRole(this.props.role)
      .then(() => {
        this.refresh();
      }).catch((e) => {
      console.error(e);
    })
      .finally(() => {
        setTimeout(() => {
          this.setState({loading: false})
        }, 500);
      });
  }

  callbackOnDelete = () => {
    this.props.history.push(`/admin-settings/role`)
  }

  render() {
    const {role} = this.props;

    if (!role)
      return <React.Fragment> Loading... </React.Fragment>

    return (
      <React.Fragment>
        <Grid container spacing={2}>
          <Grid item md={12} lg={8}>
            <Grid container spacing={2}>
              <Grid item md={12}>
                <Card>
                  <CardHeader
                    className="primary"
                    titleTypographyProps={{variant: "h4"}}
                    title={role?.name}
                    subheader={`Role ID: ${role?.uuid}`}
                  />
                  <CardActions disableSpacing>
                    <UserSelector roleUuid={`${role.uuid}`} roleName={`${role.name}`} callback={() => this.refresh()}/>
                    <PageSelector roleUuid={`${role.uuid}`} roleName={`${role.name}`} callback={() => this.refresh()}/>
                  </CardActions>
                </Card>
              </Grid>
              <Grid item md={12}>
                <Card>
                  <CardHeader title="Settings" className="primary"/>
                  <CardContent>
                    <Row>
                      <Col xs={12} className="padding10">
                        <TextField
                          id="role-detail-name-id"
                          label="Name"
                          value={role?.name}
                          fullWidth
                          InputProps={{
                            readOnly: true
                          }}
                          InputLabelProps={{
                            shrink: true
                          }}
                          variant="outlined"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} className="padding10">
                        <TextField
                          id="role-description-id"
                          label="Contact"
                          value={role?.description}
                          fullWidth
                          InputProps={{}}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          variant="outlined"
                          onChange={(e) => {
                            this.onChangeDetails("description", e.target.value)
                          }}
                        />
                      </Col>
                    </Row>
                    <Row style={{justifyContent: "flex-end", marginRight: "auto"}}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => this.onClickSaveBtn()}
                        disabled={this.state?.loading || !this.isDirty}
                      >
                        {this.state.loading ?
                          <CircularProgress style={{width: 22, height: 22}} className="white"/> : 'Save'}
                      </Button>
                    </Row>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>

          <Grid item md={12} lg={4}>
            <Grid container spacing={2}>
              <Grid item md={12}>
                <RoleUsers role={role} users={this.props?.users ?? []}></RoleUsers>
              </Grid>
              <Grid item md={12}>
                <RolePages role={role} pages={this.props?.pages ?? []}></RolePages>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  role: state.roleReducer.role,
  users: state.roleReducer.users,
  pages: state.roleReducer.pages,
});

const mapDispatchToProps = (dispatch) => ({
  getRoleByUuid: (uuid) => dispatch(getRoleByUuid(uuid)),
  updateRole: (payload) => dispatch(updateRole(payload)),
  setRole: (payload) => dispatch(setRole(payload)),
  getUsers: (payload) => dispatch(getUsers(payload)),
  getPages: (payload) => dispatch(getPages(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withAuth0(RoleDetail));
