import axios from "axios";
import store from "../store";
import {ActionTypes} from "./types";
import {config} from "../../auth/auth";

const host = process.env.REACT_APP_HOST

export const getCurrency = () => {

  const partnerUuid = store.getState().appData.currentAdminUser?.partnerUuid;

  return async dispatch => {
    return axios.get(`${host}/partner/${partnerUuid}/currency`, config())
      .then(
        response => dispatch(setCurrency(response.data)),
        err => console.log(err)
      );
  }
}

export const getSocialMedia = () => {
  return async dispatch => {
    return axios.get(`${host}/partner/socialMedia`, config())
      .then(
        response => dispatch(setSocialMedia(response.data)),
        err => console.log(err)
      );
  }
}

export const setCurrency = (payload) => ({
  type: ActionTypes.SET_PARTNER_CURRENCY,
  payload: payload,
});
export const setSocialMedia = (payload) => ({
  type: ActionTypes.SET_PARTNER_SOCIAL_MEDIA,
  payload: payload,
});

