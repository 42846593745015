import {AlertTypes} from "./alert.types";
import {_setCustomerServiceChannels} from "../app/app.action";

export const setCustomerServiceChannels = (payload) => {
  return async dispatch => {
    dispatch(_setCustomerServiceChannels(payload));
  }
}

export const error = (msg) => {
  return async dispatch => {
    dispatch(setOpen(true));
    dispatch(setSeverity("error"));
    dispatch(setMessage(msg));
  }
}

export const warm = (msg) => {
  return async dispatch => {
    dispatch(setOpen(true));
    dispatch(setSeverity("warning"));
    dispatch(setMessage(msg));
  }
}

export const info = (msg) => {
  return async dispatch => {
    dispatch(setOpen(true));
    dispatch(setSeverity("info"));
    dispatch(setMessage(msg));
  }
}

export const success = (msg) => {
  return async dispatch => {
    dispatch(setOpen(true));
    dispatch(setSeverity("success"));
    dispatch(setMessage(msg));
  }
}

export const close = () => {
  return async dispatch => {
    dispatch(setOpen(false));
  }
}

export const setOpen = (payload) => ({
  type: AlertTypes.SET_OPEN,
  payload: payload,
});
export const setMessage = (payload) => ({
  type: AlertTypes.SET_MESSAGE,
  payload: payload,
});
export const setSeverity = (payload) => ({
  type: AlertTypes.SET_SEVERITY,
  payload: payload,
});




