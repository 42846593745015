import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import EditIcon from "@material-ui/icons/Edit";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import PropTypes from "prop-types";
import axios from "axios";

import {config} from "../../../auth/auth";
import {CircularProgress} from "@material-ui/core";

const host = process.env.REACT_APP_HOST

class Edit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      open: false,
      channel: undefined,
      subject: undefined,
      content: undefined,
    };
  }

  static propTypes = {
    value: PropTypes.object,
    tableMeta: PropTypes.object.isRequired,
    callback: PropTypes.func.isRequired
  };

  handleClick = () => {
    this.setState({open: true});
  }

  cancel = () => {
    this.setState({open: false});
  };
  create = (callback) => {
    if (this.state.isLoading)
      return;
    const body = {
      channel: this.state.channel ?? this.props?.tableMeta?.rowData[3],
      subject: this.state.subject ?? this.props?.tableMeta?.rowData[4],
      content: this.state.content ?? this.props?.tableMeta?.rowData[5]
    }
    const uuid = this.props.tableMeta.rowData[0];
    this.setState({isLoading: true})
    return axios.put(`${host}/messageTemplate/${uuid}`, body, config())
      .then((response) => {})
      .catch(e => alert(e))
      .finally(() => {
        this.setState({isLoading: false, open: false});
        callback();
      });
  };

  handleChannelChange = (e) => {
    this.setState({channel: e.target.value});
  };

  handleSubjectChange = (e) => {
    this.setState({subject: e.target.value});
  };

  handleContentChange = (e) => {
    this.setState({content: e.target.value});
  };

  render() {
    const { value, tableMeta, callback } = this.props;
    const channel = this.state.channel ?? tableMeta?.rowData[3];
    const subject = this.state.subject ?? tableMeta?.rowData[4];
    const content = this.state.content ?? tableMeta?.rowData[5];
    return (
      <React.Fragment>
        <Tooltip title={"Edit"}>
          <IconButton onClick={this.handleClick}>
            <EditIcon />
          </IconButton>
        </Tooltip>
        <Dialog open={this.state.open} onClose={this.cancel} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Notification</DialogTitle>
          <DialogContent>
            <DialogContentText>
              To update the notification, please enter your new subject and content here.
            </DialogContentText>
            <FormControl fullWidth>
              <InputLabel htmlFor="max-width">Channel</InputLabel>
              <Select
                disabled={this.state.isLoading}
                value={channel}
                onChange={this.handleChannelChange}
                inputProps={{
                  name: 'Channel',
                  id: 'Channel',
                }}
              >
                <MenuItem value="APP_NOTIFICATION">Notification</MenuItem>
                <MenuItem value="SYSTEM_NOTIFICATION">System Notification</MenuItem>
              </Select>
            </FormControl>
            <TextField
              error={!subject}
              helperText={subject ? "" : "Required"}
              disabled={this.state.isLoading}
              autoFocus
              value={subject}
              margin="dense"
              id="subject"
              label="Subject"
              type="text"
              fullWidth
              onChange={this.handleSubjectChange}
            />
            <TextField
              error={!content}
              helperText={content ? "" : "Required"}
              disabled={this.state.isLoading}
              multiline
              value={content}
              rows="6"
              margin="dense"
              id="content"
              label="Content"
              type="text"
              fullWidth
              onChange={this.handleContentChange}
            />
          </DialogContent>
          <DialogActions>
              <React.Fragment>
                {this.state.isLoading ? <CircularProgress style={{width:20, height:20}}/> : ""}
                <Button
                  onClick={() => this.create(callback)}
                  style={{color: "white", backgroundColor: "#36b9cc"}}
                  disabled={this.state.isLoading || !content || !subject}
                >
                  Save
                </Button>
                <Button
                  onClick={this.cancel}
                  color="primary"
                  disabled={this.state.isLoading}
                >
                  Cancel
                </Button>
              </React.Fragment>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }

}

export default Edit;
