import React from "react";
import PropTypes from 'prop-types';
import Brightness1Icon from "@material-ui/icons/Brightness1";
import {green, grey, red, yellow} from "@material-ui/core/colors";

class ConsoleActionStatus extends React.Component {
  static propTypes = {
    status: PropTypes.string.isRequired,
  };

  render() {
    const { status } = this.props;
    if (!status)
      return (<React.Fragment/>);
    let color;
    let statusText;
    if(status >= '200' && status < '300')
      {color = green[500];
      statusText = 'Succeeded' }
    else 
      {color = red[500];
      statusText = 'Failed' }
    return (
      <React.Fragment>
        <Brightness1Icon style={{width:16, height:16, color: color}}/>
        {statusText}
      </React.Fragment>
    );
  }
}

export default ConsoleActionStatus;