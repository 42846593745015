import React, {Component} from 'react';
import {search} from "../../../../redux/topUpTemplate/topUpTemplate.action";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {withAuth0} from "@auth0/auth0-react";
import {Button} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import {Grid} from "@material-ui/core";
import CardContent from "@material-ui/core/CardContent";
import {grey} from "@material-ui/core/colors";
import androidTopUpTemplate from "../../../../images/Android Preview.png";
import iosTopUpTemplate from "../../../../images/iOS Preview.png";
import "./topUpTemplate.css";
import axios from 'axios';
import {config} from "../../../../auth/auth";
import Input from "../../../../components/common/Input";
import TopUpTemplateOption from "./TopUpTemplateOption";
import PropTypes from "prop-types";
import {numberToWord} from "../../../../utils/utils";

const host = process.env.REACT_APP_HOST

class TopUpTemplateGroup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      topUpTemplates: this.props.topUpTemplates
    }
  }

  static propTypes = {
    topUpTemplates: PropTypes.array.isRequired,
    postSaveBtn: PropTypes.func.isRequired
  };

  onChange = (uuid, attribute, value) => {
    this.setState(state => {
      const i = state.topUpTemplates.findIndex((obj => obj.uuid === uuid));
      state.topUpTemplates[i][attribute] = value;
      const topUpTemplates = state.topUpTemplates.map((x) => x);
      return {topUpTemplates};
    });
  }

  onClickSaveBtn = () => {
    const requests = this.state.topUpTemplates.map(t => {
      const body = {
        bonusAmount: t.bonusAmount,
        currency: t.currency,
        topUpAmount: t.topUpAmount,
      };
      return axios.put(`${host}/topUpTemplate/${t.uuid}`, body, config());
    });
    return axios.all(requests)
      .then(() => this.props.postSaveBtn())
      .catch((e) => alert(e))
     ;
  };

  render() {
    const {topUpTemplates} = this.props;
    if (!topUpTemplates) return <React.Fragment/>

    const cityName = this.state.topUpTemplates ? this.state.topUpTemplates[0]?.cityName : "";

    return (
        <div className="row">
          <div className="col-xl col-lg">
            <div className="card shadow mb-4" style={{width: '100%'}}>
              <div className="card-header-one py-3 d-flex flex-row align-items-center justify-content-between">
                <h6 className="m-0 font-weight-bold" style={{color: '#FFFFFF'}}>Edit Top Up Templates {cityName}</h6>
              </div>
              <div className="card-body">
                <Grid container>
                  {this.state.topUpTemplates?.map( (topUpTemplate, i) => (
                      <TopUpTemplateOption header={`Option ${numberToWord(i + 1)}`}
                                           uuid={topUpTemplate.uuid}
                                           topUpAmount={topUpTemplate.topUpAmount}
                                           bonusAmount={topUpTemplate.bonusAmount}
                                           onChange={this.onChange}/>
                  ))}
                </Grid>

                <div className="row">
                  <div className="col-xl col-lg">
                    <Button
                      variant="contained"
                      color="primary"
                      style={{width: "150px"}}
                      onClick={this.onClickSaveBtn}
                    >
                      Save
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          {/* preview the topup template */}
          <div className="col-xl col-lg">
            <div className="card shadow mb-4" style={{width: '100%'}}>
              <div className="card-header-one py-3 d-flex flex-row align-items-center justify-content-between">
                <h6 className="m-0 font-weight-bold" style={{color: '#FFFFFF'}}>Preview Top Up Templates {cityName}</h6>
              </div>

              <div className="card-body" >
                <React.Fragment>
                  <Grid container spacing={2}>
                    <Grid item sm={12} xs={12} md={12} lg={12}>
                      <CardContent style={{paddingBottom: "0px"}}>
                        <Grid container className="formLabel">
                          <Grid item sm={6} xs={6} md={6} lg={6}>
                            <div className = "head-text">
                              <div className = "head-image">
                                <img 
                                  src = {iosTopUpTemplate} 
                                  alt = "iOS" 
                                  style={{
                                    width: 285,
                                    height: 585,
                                  }} 
                                />
                              </div>
                              {this.state.topUpTemplates?.map( (topUpTemplate, i) => (
                                <React.Fragment>
                                  <div className={`text-on-image-topup-${i+1}`}>
                                    <p> {topUpTemplate?.topUpAmount} {topUpTemplate?.currency}</p>
                                  </div>
                                  <div className={`text-on-image-bonus-${i+1}`}>
                                    <p> {i === 0 ? "Get" : ""} {topUpTemplate?.bonusAmount} {i === 0 ? "bonus" : ""}  </p>
                                  </div>
                                </React.Fragment>
                              ))}
                            </div>
                          </Grid>

                          <Grid item sm={6} xs={6} md={6} lg={6}>
                            <div className = "head-text">
                              <div className = "head-image">
                                <img 
                                  src = {androidTopUpTemplate} 
                                  alt = "Freedom Blog" 
                                  style={{
                                    width: 285,
                                    height: 585,
                                  }} 
                                />
                              </div>
                              {this.state.topUpTemplates?.map( (topUpTemplate, i) => (
                                <React.Fragment>
                                  <div className={`android-text-on-image-topup-${i+1}`}>
                                    <p> {topUpTemplate?.topUpAmount} {topUpTemplate?.currency}</p>
                                  </div>
                                  <div className={`android-text-on-image-bonus-${i+1}`}>
                                    <p> {topUpTemplate?.bonusAmount} </p>
                                  </div>
                                </React.Fragment>
                              ))}
                            </div>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Grid>
                  </Grid>
                </React.Fragment>
              </div>
            </div>
          </div>
        </div>
    );
  }
}

export default TopUpTemplateGroup;
