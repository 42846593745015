import {ActionTypes} from "./types";

const INITIAL_STATE = {
  vouchers: undefined
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ActionTypes.SET_VOUCHERS:
      return {
        ...state,
        vouchers: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;