import React, {Component} from "react";
import {connect} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faBars,
  faBell,
  faEnvelope,
  faLanguage,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import {
  setChatClient,
  setUnreadMessagesCount,
} from "../../redux/app/app.action";
import {withAuth0} from "@auth0/auth0-react";
import {Link, withRouter} from "react-router-dom";
import {StreamChat} from "stream-chat";
import "stream-chat-react/dist/css/index.css";
import ChannelListComponent from "./CommonChannelList";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import MuiAlert from "@material-ui/lab/Alert";
import Avatar from "@material-ui/core/Avatar";
import axios from "axios";
import {config} from "../../auth/auth";
import {determineVehicleImage} from "../../utils/utils";
import "./header.css";
import firebase from "firebase/app";
import "firebase/database";
import moment from "moment";
import i18n from '../../i18n';

const partner_logo = process.env.REACT_APP_PARTNER_LOGO;
const main_website_url = process.env.REACT_APP_MAIN_WEB_SITE;
const auth0_client_id = process.env.REACT_APP_AUTH0_CLIENT_ID;
const host = process.env.REACT_APP_HOST;
const env = process.env.REACT_APP_ENV

function Alert(props) {
  return <MuiAlert elevation={6} {...props} />;
}
class Header extends Component {
  searchDropDownRef = React.createRef();
  alertDropDownRef = React.createRef();

  constructor(props) {
    super(props);
    this.messageData = "";
    this.state = {
      openNotification: false,
      newMessage: "",
      searchValue: "",
      searchResult: "",
      dropdownToggle: false,
      alertToggle: false,
      alertList: [],
    };
  }

  initLiveChat = () => {
    const { setUnreadMessagesCount, chatClient, liveChatConfig } = this.props;
    if (!chatClient && liveChatConfig && liveChatConfig !== "nope") {
      const chatClient = StreamChat.getInstance(liveChatConfig?.apiKey);
      chatClient
        .connectUser(
          {
            id: liveChatConfig?.userId,
            image: `${partner_logo}`,
          },
          liveChatConfig?.userToken
        )
        .then((data) => {
          this.props.setChatClient(chatClient);
          if (data.me.unread_channels !== undefined) {
            const unreadCount = data.me.unread_channels;
            setUnreadMessagesCount(unreadCount);
          }
        })
        .catch((e) => {
          console.error(e);
        });
      chatClient.on("message.new", (data) => {
        if (data?.user.id !== liveChatConfig?.userId) {
          this.messageData = data;
          const unreadCount = data.unread_channels;
          setUnreadMessagesCount(unreadCount);
          this.setState({
            openNotification: true,
            newMessage: data.message.text,
          });
        }
      });
      chatClient.on("message.read", (data) => {
        const unreadCount = data.unread_channels;
        setUnreadMessagesCount(unreadCount);
      });
    }
  }

  componentDidMount() {
    const {currentAdminUser} = this.props;
    const channelsRef = firebase
      .database()
      .ref(
        `${env}/console/log/system/${currentAdminUser?.partnerUuid}/vehicle_notification`
      );
    channelsRef.on("value", (snapshot) => {
      const data = snapshot.val();
      this.getVehicleLogs(0, 10);
    });
  };

  UNSAFE_componentWillMount() {
    document.addEventListener("mousedown", this.handleClickOutside, false);
  }
  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside, false);
  }

  getVehicleLogs = (page, size, loadMore) => {
    if (loadMore) {
      this.setState({ alertToggle: true });
    } else {
      this.setState({ alertToggle: false });
    }
    return axios
      .get(`${host}/vehicleLog?page=${page}&size=${size}`, config())
      .then((response) => {
        if (response && response.data) {
          if (loadMore) {
            let alertListTemp = this.state.alertList?.content || [];
            response.data.content = [...alertListTemp, ...response.data.content]
          }
          this.setState({ alertList: response.data });
        }
      })
      .catch((e) => {console.error(e)});
  };

  markAsRead= (vehicleAlert) => {
    return axios
      .put(`${host}/vehicleLog/${vehicleAlert.uuid}/markAsRead`, {}, config())
      .then((response) => {
        this.setState({alertToggle: false});
        this.getVehicleLogs(0, 10);
        this.props.history.push(`/vehicle/${vehicleAlert?.vehicle?.uuid}`);
      })
      .catch((e) => alert(e));
  }

  handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ openNotification: false, newMessage: "" });
  };

  updateSearchValue(evt) {
    this.setState({ searchValue: evt.target.value });
  }

  search() {
    if (this.state.searchValue) {
      return axios
        .get(`${host}/search?param=${this.state.searchValue}`, config())
        .then((response) => {
          if (response && response.data) {
            this.setState({ searchResult: response.data });
          }
        })
        .catch((e) => alert(e))
        .finally(() => this.setState({ dropdownToggle: true }));
    }
  }

  handleClickOutside = (e) => {
    if (!this.searchDropDownRef.current.contains(e.target)) {
      this.setState({ dropdownToggle: false });
    }

    if (!this.alertDropDownRef.current.contains(e.target)) {
      this.setState({ alertToggle: false });
    }
  };

  changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  }

  userSearchResult = (user, index) => {
    return <a
      className="dropdown-item search-item"
      href={`/user/${user?.uuid}`}
      key={index}
    >
      <div className="d-flex">
        <div className="pr-3">
          <Avatar
            className="avatar"
            aria-label="avatar"
            alt="avatar"
            src={user?.graphUrl}
          >
            {`${user?.firstName
              ?.charAt(0)
              .toUpperCase()}${user?.lastName
              ?.charAt(0)
              .toUpperCase()}`}
          </Avatar>
          <img
            className="avatar-icon"
            aria-label="cancel"
            style={{width: 20, height: 20, margin: 2}}
            alt="cancel"
            src={
              `${user?.status}`
                ? "/icon/active.png"
                : "/icon/not-active.png"
            }
          />
        </div>
        <div>
          <div className="d-flex">
            <div className="pr-4">ID: {user?.uuid}</div>
            <div>
              {user?.firstName} {user?.lastName}
            </div>
          </div>
          <div className="search-info">{user?.email}</div>
          <div className="search-info">{user?.phone}</div>
        </div>
      </div>
    </a>
  }

  vehicleSearchResult = (vehicle, index) => {
    return <a
      className="dropdown-item search-item"
      href={`/vehicle/${vehicle?.uuid}`}
      key={index}
    >
      <div className="d-flex">
        <div className="pr-3">
          <Avatar
            className="avatar"
            aria-label="avatar"
            alt="avatar"
            src={
              vehicle.graphUrl
                ? vehicle.graphUrl
                : determineVehicleImage(vehicle?.category)
            }
          ></Avatar>
        </div>
        <div>
          <div className="d-flex">
            <div className="pr-4">ID: {vehicle?.uuid}</div>
            <div>{vehicle?.location}</div>
          </div>
          <div className="search-info">{vehicle?.status}</div>
          <div className="search-info">{vehicle?.qrCode}</div>
          <div className="search-info">{vehicle?.frameNo}</div>
        </div>
      </div>
    </a>
  }

  orderSearchResult = (order, index) => {
    return <a
      className="dropdown-item search-item"
      href={`/order/${order?.uuid}`}
      key={index}
    >
      <div className="d-flex">
        <div className="pr-3">
          <Avatar
            className="avatar"
            aria-label="avatar"
            alt="avatar"
            src={order?.graphUrl}
          >
            {`${order?.userFirstName
              ?.charAt(0)
              .toUpperCase()}${order?.userLastName
              ?.charAt(0)
              .toUpperCase()}`}
          </Avatar>

        </div>
        <div>
          <div className="d-flex">
            <div className="pr-4">ID: {order?.uuid}</div>
            <div>
              {order?.userFirstName} {order?.userLastName}
            </div>
          </div>
          <div className="search-info">{order?.cost}</div>
          <div className="search-info">{order?.status}</div>
        </div>
      </div>
    </a>
  }

  render() {
    const {unreadMessageCount, chatClient} = this.props;
    const {user, logout} = this.props.auth0;
    const {searchResult, dropdownToggle, alertList, alertToggle} = this.state;

    this.initLiveChat();

    return (
      <React.Fragment>
        <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
          <button
            id="sidebarToggleTop"
            className="btn btn-link d-md-none rounded-circle mr-3"
          >
            <FontAwesomeIcon icon={faBars} />
          </button>

          <div
            className="d-none d-sm-inline-block form-inline mr-auto ml-md-3 my-2 my-md-0 mw-100 navbar-search"
            ref={this.searchDropDownRef}
          >
            <div className="input-group">
              <input
                type="text"
                className="form-control bg-light border-0 small"
                placeholder="Search for..."
                aria-label="Search"
                aria-describedby="basic-addon2"
                onChange={(evt) => this.updateSearchValue(evt)}
                onKeyPress={event => {
                  if (event.key === "Enter") {
                    this.search();
                  }
                }
                }
              />
              <div className="input-group-append">
                <button
                  className="btn dropdown-toggle disable-arrow rounded-right"
                  style={{color: '#fff', backgroundColor: '#333399', borderColor: '#333399'}}
                  onClick={() => this.search()}
                >
                  <FontAwesomeIcon icon={faSearch} />
                </button>
              </div>
              <div
                className={`dropdown-menu dropdown-menu-left p-2 shadow animated--grow-in search-dropdown ${
                  dropdownToggle ? "show" : ""
                }`}
              >
                {searchResult &&
                searchResult.users.length === 0 &&
                searchResult.vehicles.length === 0 &&
                searchResult.orders.length === 0 && (
                  <div>No search result</div>
                )}
                {searchResult &&
                searchResult?.users.map((user, index) => this.userSearchResult(user, index))}
                {searchResult &&
                searchResult?.vehicles.map((vehicle, index) => this.vehicleSearchResult(vehicle, index))}
                {searchResult &&
                searchResult?.orders.map((order, index) => this.orderSearchResult(order, index))}
              </div>
            </div>
          </div>

          <ul className="navbar-nav ml-auto">
            <li className="nav-item dropdown no-arrow d-sm-none">
              <a
                className="nav-link dropdown-toggle"
                href="#top"
                id="searchDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <FontAwesomeIcon icon={faSearch} />
              </a>
              <div
                className="dropdown-menu dropdown-menu-right p-3 shadow animated--grow-in"
                aria-labelledby="searchDropdown"
              >
                <div className="form-inline mr-auto w-100 navbar-search">
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control bg-light border-0 small"
                      placeholder="Search for..."
                      aria-label="Search"
                      aria-describedby="basic-addon2"
                      onChange={(evt) => this.updateSearchValue(evt)}
                    />
                    <div className="input-group-append">
                      <button
                        className="btn"
                        type="button"
                        style={{color: '#fff', backgroundColor: '#333399', borderColor: '#333399'}}
                        onClick={() => this.search()}
                      >
                        <FontAwesomeIcon icon={faSearch} />
                      </button>
                    </div>
                  </div>
                  {searchResult &&
                  searchResult?.users.map((user, index) => this.userSearchResult(user, index))}
                  {searchResult &&
                  searchResult?.vehicles.map((vehicle, index) => this.vehicleSearchResult(vehicle, index))}
                  {searchResult &&
                  searchResult?.orders.map((order, index) => this.orderSearchResult(order, index))}
                </div>
              </div>
            </li>
            {/*
            <li className="nav-item dropdown no-arrow">
              <a
                className="nav-link dropdown-toggle"
                href="#top"
                id="transDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <FontAwesomeIcon icon={faLanguage} />
              </a>

              <div
                className="dropdown-menu dropdown-menu-right shadow animated--grow-in"
                aria-labelledby="transDropdown"
              >
                <a
                  className="dropdown-item"
                  data-toggle="modal"
                  data-target="#languagezh"
                  onClick={() => this.changeLanguage('en')}
                >
                  <i
                    className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"
                    alt="this is Chinese"
                  ></i>
                  English
                </a>
                <a
                  className="dropdown-item"
                  data-toggle="modal"
                  data-target="#languagepl"
                  onClick={() => this.changeLanguage('pl')}
                >
                  <i
                    className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"
                    alt="this is Chinese"
                  ></i>
                  Polish
                </a>
                <a
                  className="dropdown-item"
                  data-toggle="modal"
                  data-target="#languagees"
                  onClick={() => this.changeLanguage('es')}
                >
                  <i
                    className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"
                    alt="this is Chinese"
                  ></i>
                  Spanish
                </a>
              </div>
            </li>
            */}
            <li className="nav-item dropdown no-arrow mx-1">
              <a
                className="nav-link dropdown-toggle"
                href="#top"
                id="alertsDropdown"
                role="button"
                onClick={() => this.setState({ alertToggle: true })}
              >
                <FontAwesomeIcon icon={faBell} />
                <span className="badge badge-danger badge-counter">{alertList?.totalElements}</span>
              </a>

              <div
                className={`dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in alert-list ${alertToggle ? 'show' : ''}`}
                aria-labelledby="alertsDropdown"
                ref={this.alertDropDownRef}
              >
                <h6 className="dropdown-header"></h6>
                {alertList &&
                alertList.content &&
                alertList.content.map((alert, index) => (
                  <a
                    className="dropdown-item d-flex align-items-center pointer"
                    onClick={() => this.markAsRead(alert)}
                    key={index}
                  >
                    <div className="mr-3">
                      <div className="icon-circle bg-warning">
                        <i className="fas fa-exclamation-triangle text-white"></i>
                      </div>
                    </div>
                    <div>
                      <div className="small text-gray-500">
                        {moment(alert.createdAt).format('MMMM Do, YYYY')}
                      </div>
                      <span className="font-weight-bold">
                          {alert.level}: {alert?.vehicle?.uuid} {alert.content}
                        </span>
                    </div>
                  </a>
                ))}
                <a
                  className="dropdown-item text-center small text-gray-500 pointer"
                  onClick={() => this.getVehicleLogs((alertList.number + 1), 10, true)}
                >
                  Show All Alerts
                </a>
              </div>
            </li>

            <li className="nav-item dropdown no-arrow mx-1">
              <a
                className="nav-link dropdown-toggle"
                href="#top"
                id="messagesDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <FontAwesomeIcon icon={faEnvelope} />
                <span className="badge badge-danger badge-counter">
                  {unreadMessageCount}
                </span>
              </a>

              <div
                className="dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in messagesDropdown"
                aria-labelledby="messagesDropdown"
              >
                <h6 className="dropdown-header">Message Center</h6>
                <ChannelListComponent
                  chatClient={chatClient}
                ></ChannelListComponent>
                {/* {chatClient && (
                  <ChannelListComponent
                    chatClient={chatClient}
                  ></ChannelListComponent>
                )} */}
              </div>
            </li>

            <div className="topbar-divider d-none d-sm-block"></div>

            <li className="nav-item dropdown no-arrow">
              <a
                className="nav-link dropdown-toggle"
                href="#top"
                id="userDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <span className="mr-2 d-none d-lg-inline text-gray-600 small">
                  {user.name}
                </span>
                <img
                  className="img-profile rounded-circle"
                  alt="this is profile"
                  src={user.picture}
                />
              </a>

              <div
                className="dropdown-menu dropdown-menu-right shadow animated--grow-in"
                aria-labelledby="userDropdown"
              >
                {/*
                <a
                  className="dropdown-item"
                  href="#top"
                  data-toggle="modal"
                  data-target="#logoutModal"
                >
                  <i
                    className="fas fa-user fa-sm fa-fw mr-2 text-gray-400"
                    alt="this is profile"
                  ></i>
                  Profile
                </a>
                <a
                  className="dropdown-item"
                  href="#top"
                  data-toggle="modal"
                  data-target="#logoutModal"
                >
                  <i
                    className="fas fa-cogs fa-sm fa-fw mr-2 text-gray-400"
                    alt="this is profile"
                  ></i>
                  Settings
                </a>
                <a
                  className="dropdown-item"
                  href="#top"
                  data-toggle="modal"
                  data-target="#logoutModal"
                >
                  <i
                    className="fas fa-list fa-sm fa-fw mr-2 text-gray-400"
                    alt="this is profile"
                  ></i>
                  Activity Log
                </a>
                
                <div className="dropdown-divider"></div>
*/}
                <a
                  className="dropdown-item"
                  data-toggle="modal"
                  data-target="#logoutModal"
                  onClick={() =>
                    logout({
                      returnTo: main_website_url,
                      client_id: auth0_client_id,
                    })
                  }
                >
                  <i
                    className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"
                    alt="this is profile"
                  ></i>
                  Logout
                </a>
              </div>
            </li>
          </ul>
        </nav>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          open={this.state.openNotification}
          autoHideDuration={6000}
          onClose={this.handleClose}
          message={this.getMessage}
          action={
            <React.Fragment>
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={this.handleClose}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </React.Fragment>
          }
        >
          <Alert onClose={this.handleClose}>
            <Link
              className="dropdown-item d-flex align-items-center"
              to="/customer-service/live-chat"
            >
              <div>
                {this.messageData?.user?.name || "Unnamed Channel"}
                <div>{this.state.newMessage} </div>
              </div>
            </Link>
          </Alert>
        </Snackbar>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  unreadMessageCount: state.appData.unreadMessageCount,
  chatClient: state.appData.chatClient,
  currentAdminUser: state.appData.currentAdminUser,
  liveChatConfig: state.appData.liveChatConfig,
});

const mapDispatchToProps = (dispatch) => ({
  setUnreadMessagesCount: (payload) =>
    dispatch(setUnreadMessagesCount(payload)),
  setChatClient: (payload) => dispatch(setChatClient(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withAuth0(Header)));
