import React, {useState} from 'react';
import {IconButton, Tooltip} from "@material-ui/core";
import ListIcon from '@material-ui/icons/List';
import * as ReactDOM from "react-dom";

import classes from '../../../Modal/Modal.module.css'
import Backdrop from "../../../Modal/Backdrop";
import PoiEditModal from "./POIEditModal";

const PoiEdit = ({pointOfInterest, setOpenSuccessModal}) => {
  const [openModal, setOpenModal] = useState(false);

  return (
    <React.Fragment>
      <Tooltip title="Edit">
        <IconButton
          variant="contained"
          color="primary"
          onClick={() => setOpenModal(true)}
        >
          <ListIcon/>
        </IconButton>
      </Tooltip>
      {openModal && ReactDOM.createPortal(
        <Backdrop onClick={() => setOpenModal(false)}/>,
        document.getElementById('backdrop-root'))}

      {openModal && ReactDOM.createPortal(
        <div className={classes.modal}>
          <PoiEditModal pointOfInterest={pointOfInterest} setOpenModal={setOpenModal} setOpenSuccessModal={setOpenSuccessModal}/>
        </div>,
        document.getElementById('overlay-root'))}

    </React.Fragment>
  );
};

export default PoiEdit;