import {GroundTeamUserActionTypes} from "./groundTeamUser.types";
import axios from "axios";
import {getAccessToken} from "../../auth/auth";

const host = process.env.REACT_APP_HOST
const maxItemSize = process.env.REACT_APP_MAX_ITEM_SIZE

export const getGroundTeamUsers = (queryParams) => {
  const config = {
    headers: {
      'Authorization': `Bearer ${getAccessToken()}`,
      'Content-Type': 'application/json'
    },
    params: queryParams ? queryParams : {
      page: 0,
      size: 1000
    }
  }

  return async dispatch => {
    return axios.get(`${host}/groundTeam`, config)
      .then(
        response => dispatch(setGroundTeamUsers(response.data)),
        err => console.log(err)
      );
  }
}

export const getGroundTeamDetails = (path) => {
  const config = {
    headers: {
      'Authorization': `Bearer ${
        getAccessToken()
      }`,
      'Content-Type': 'application/json'
    }
  }
  let url = `${host}${path}`;
  return async dispatch => {
    const request = axios.get(url, config);

    return request.then(response => {
      dispatch(setGroundTeamDetails(response.data));
    }, err => {
      console.log(err);
    });
  }
}

export const getDailyWorkingHours = (path) => {
  const config = {
    headers: {
      'Authorization': `Bearer ${
        getAccessToken()
      }`,
      'Content-Type': 'application/json'
    },
    params: {
      startDate: '2020-01-01'
    }
  }
  let url = `${host}${path}`;
  return async dispatch => {
    const request = axios.get(url, config);

    return request.then(response => {
      dispatch(setGroundTeamDailyWorkingHours(response.data));
    }, err => {
      console.log(err);
    });
  }
}

export const getDailyWorkingRecord = (queryParams) => {
  const config = {
    headers: {
      'Authorization': `Bearer ${
        getAccessToken()
      }`,
      'Content-Type': 'application/json'
    },
    params: queryParams
  }
  let url = `${host}/groundTeamWorkRecord`;
  return async dispatch => {
    const request = axios.get(url, config);

    return request.then(response => {
      dispatch(setGroundTeamWorkRecord(response.data));
    }, err => {
      console.log(err);
    });
  }
}


export const setGroundTeamUsers = (payload) => ({
  type: GroundTeamUserActionTypes.SET_GROUND_TEAM_USERS,
  payload: payload,
});
export const setGroundTeamDetails = (teamDetails) => ({
  type: GroundTeamUserActionTypes.SET_GROUND_TEAM_DETAILS,
  payload: teamDetails
});
export const setGroundTeamDailyWorkingHours = (dailyWorkingHours) => ({
  type: GroundTeamUserActionTypes.SET_GROUND_TEAM_DAILY_WORKING_HOURS,
  payload: dailyWorkingHours
});

export const setGroundTeamWorkRecord = (payload) => ({
  type: GroundTeamUserActionTypes.SET_GROUND_TEAM_WORK_RECORD,
  payload: payload,
});