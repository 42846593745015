import React, { Component } from "react";
import {
  Chat,
  Channel,
  ChannelHeader,
  ChannelList,
  ChannelListMessenger,
  ChannelPreviewMessenger,
  InfiniteScrollPaginator,
  MessageInput,
  MessageInputFlat,
  MessageList,
  Thread,
  Window,
} from "stream-chat-react";
import { CustomChannelPreview } from "./CustomChannelPreview";
import { chatClientObj } from "../../utils/utils";
import { withAuth0} from "@auth0/auth0-react";
import { connect } from "react-redux";

class ChannelListComponent extends Component {
  shouldComponentUpdate(nextProps, nextState) {
    return !chatClientObj.loaded;
  }
  render() {
    const { chatClient, liveChatConfig } = this.props;
    if (!chatClient) {
      return <React.Fragment>Loading...</React.Fragment>;
    }
    if (!chatClientObj.loaded) {
      chatClientObj.loaded = true;
    }
    const userId = liveChatConfig?.userId
    const options = { state: true, watch: true, presence: true };
    const filters = { type: "messaging", members: { $in: [userId] } };
    const sort = { last_message_at: -1 };
    const Paginator = (props) => (
      <InfiniteScrollPaginator threshold={300} {...props} />
    );
    const theme = "light";
    return (
      <Chat client={chatClient} theme={`messaging ${theme}`}>
        <ChannelList
          List={ChannelListMessenger}
          Preview={CustomChannelPreview}
          filters={filters}
          sort={sort}
          options={options}
          Paginator={Paginator}
        />
      </Chat>
    );
  }
}

const mapStateToProps = (state) => ({
  liveChatConfig: state.appData.liveChatConfig,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withAuth0(ChannelListComponent));
