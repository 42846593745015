import {ActionTypes} from "./types";

const INITIAL_STATE = {
  notificationTasks: undefined,
  notificationTask: undefined
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ActionTypes.SET_NOTIFICATION_TASKS:
      return {
        ...state,
        notificationTasks: action.payload,
      };
    case ActionTypes.SET_NOTIFICATION_TASK:
      return {
        ...state,
        notificationTask: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;