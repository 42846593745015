import {VehicleActionTypes} from "./vehicle.types";
import axios from "axios";
import {config, getAccessToken} from "../../auth/auth";

const host = process.env.REACT_APP_HOST

export const search  = (queryParams) =>{
  const config = {
    headers: {
      'Authorization': `Bearer ${getAccessToken()}`,
      'Content-Type': 'application/json'
    },
    params: queryParams
  }

  return async dispatch => {
    return axios.get(`${host}/vehicle`, config)
      .then(
        response => dispatch(setVehicles(response.data)),
        err => console.log(err)
      );
  }
}

export const searchVehicleMapData = (queryParams) =>{
  const config = {
    headers: {
      'Authorization': `Bearer ${getAccessToken()}`,
      'Content-Type': 'application/json'
    },
    params: queryParams
  }

  return async dispatch => {
    return axios.get(`${host}/vehicle`, config)
      .then(
        response => dispatch(setVehiclesMapData(response.data)),
        err => console.log(err)
      );
  }
}

export const getVehicleByUuid  = (uuid) => {
  return async dispatch => {
    return axios.get(`${host}/vehicle/${uuid}`, config())
      .then(
        response => dispatch(setVehicle(response.data)),
        err => console.log(err)
      );
  }
}

export const updateVehicle = (vehicle) => {
  const body = {
    cityUuid: vehicle?.cityUuid,
    discount: vehicle?.discount,
    frameNo: vehicle?.frameNo,
    imei: vehicle?.imei,
    qrCode: vehicle?.qrCode,
    status: vehicle?.status,
    regionUuid: vehicle?.regionUuid,
    withHelmet: vehicle?.withHelmet
  };
  return async dispatch => {
    return axios.put(`${host}/vehicle/${vehicle.uuid}`, body, config())
      .then(
        response => {},
        err => console.log(err)
      );
  }
};

export const setVehiclesMapData = (vehicles) => ({
  type: VehicleActionTypes.SET_VEHICLES_MAP_DATA,
  payload: vehicles,
}); 

export const setVehicles = (vehicles) => ({
  type: VehicleActionTypes.SET_VEHICLES,
  payload: vehicles,
});

export const setVehicle = (vehicle) => ({
  type: VehicleActionTypes.SET_VEHICLE,
  payload: vehicle,
});