import {ActionTypes} from "./types";
import axios from "axios";
import {getAccessToken} from "../../auth/auth";

const host = process.env.REACT_APP_HOST

export  const regionSearch  = (queryParams) =>{
  const config = {
    headers: {
      'Authorization': `Bearer ${getAccessToken()}`,
      'Content-Type': 'application/json'
    },
    params: queryParams
  }

  return async dispatch => {
    return axios.get(`${host}/region`, config)
      .then(
        response =>dispatch(setRegions(response.data)),
        err => console.log(err)
      );
  }
}

export const setRegions = (payload) => ({
  type: ActionTypes.SET_REGIONS,
  payload: payload,
});