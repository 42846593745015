import React, {Component} from "react";
import RegionConfigurationMap from "../../../../components/Map/RegionConfigurationMap";
import {regionSearch} from "../../../../redux/region/action";
import {withAuth0} from "@auth0/auth0-react";
import {connect} from "react-redux";

class Region extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.refresh();
  }

  onSuccessRegion() {
    this.refresh();
  }

  refresh = () => {
    const { regionSearch } = this.props;
    let params = new URLSearchParams();
    params.set("page", "0");
    params.set("size", "1000");
    regionSearch(params);
  };


  render() {
    const { regions } = this.props;
    if (!regions) return <React.Fragment />;
    return (
      <div>
        <h1 className="h3 mb-2 text-gray-800">Region Configuration</h1>
        <p className="mb-4">
          You can set up different operational regions on the map.
        </p>
        <br />
        <div className="row">
          <div className="col-xl col-lg">
            <div className="card shadow mb-4">
              <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                <h6 className="m-0 font-weight-bold" style={{color: '#333399'}}>
                  Region Configuration
                </h6>
              </div>

              <div className="card-body">
                <RegionConfigurationMap regions={regions} callback={() => this.onSuccessRegion()}/>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  regions: state.regionReducer.regions,
});

const mapDispatchToProps = (dispatch) => ({
  regionSearch: (params) => dispatch(regionSearch(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withAuth0(Region));