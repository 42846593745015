import React, {Component} from "react";
import {connect} from "react-redux";
import {getVehicleByUuid, setVehicle, updateVehicle} from "../../../../redux/vehicle/vehicle.action";
import {withAuth0} from "@auth0/auth0-react";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import {Button, CircularProgress, Grid} from "@material-ui/core";
import {determineVehicleIconUrlonStatus, determineVehicleImage} from "../../../../utils/utils";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import TextField from "@material-ui/core/TextField";
import "./details.css";
import moment from "moment";
import VehicleMarker from "../../../../components/Map/VehicleMap/VehicleMarker";

import {LayerGroup, LayersControl, MapContainer, Marker, TileLayer,} from "react-leaflet";
import {Icon} from "leaflet";
import Battery from "../VehicleList/bettery";
import VehicleStatus from "../VehicleList/vehicle-status";
import VehicleLockStatus from "../VehicleList/vehicle-lock-status";
import RefreshIcon from "@material-ui/icons/Refresh";
import TaskList from "../../../TaskManagement/TaskList";
import OrderList from "../../OrderInformation/OrderList/OrderList";
import VehiclePopup from "../../../../components/Map/VehicleMap/VehiclePopup";
import Location from "../../../../components/common/Location";
import MenuItem from "@material-ui/core/MenuItem";
import {search} from "../../../../redux/city/action";
import HelmetControl from "../../../../components/VehicleControl/helmet/HelmetControl";
import HeadlightControl from "../../../../components/VehicleControl/headlight/HeadlightControl";
import VehicleAlarmControl from "../../../../components/VehicleControl/alarm/VehicleAlarmControl";
import BatteryBoxControl from "../../../../components/VehicleControl/batteryBox/BatteryBoxControl";
import TaskCreateModal from "../../../../components/Map/RedistributionTaskCreationMap/TaskCreate";
import {ReactComponent as TaskAddIcon} from './task-creation-button.svg';
import { success } from "../../../../redux/alert/alert.action";
import { error } from "../../../../redux/alert/alert.action";
import GroundTeamEventLogList from "../../../TaskManagement/GroundTeamEventLog/GroundTeamEventLogList";
import ConsoleEventLogList from "../../../SystemLog/ConsoleEventLogList";


const datetime_format = process.env.REACT_APP_DATETIME_FORMAT;

class VehicleDetail extends Component {
  constructor(props) {
    super(props);
    this.isDirty = false;
    this.state = {
      loading: false,
      openPopup: false
    }
    this.isFilterDialogOpen = false;
  }

  componentDidMount() {
    this.refresh();
    this.refreshCities();
  }

  refresh = () => {
    this.uuid = this.props.location.pathname.replace("/vehicle/", "");
    this.props.getVehicleByUuid(this.uuid);
    this.isDirty = false;
  };

  refreshCities = () => {
    let params = new URLSearchParams();
    params.set("page", "0");
    params.set("size", "1000");
    this.props.search(params);
  };

  onChangeDetails = (fieldName, value) => {
    let updated = Object.assign({}, this.props.vehicle, {[fieldName]: value});
    this.props.setVehicle(updated);
    this.isDirty = true;
  }

  onClickSaveBtn = () => {
    this.setState({loading: true});
    this.props.updateVehicle(this.props.vehicle)
      .then(() => {
        this.refresh();
        this.props.success("Success")
      }).catch((e) => {
      console.error(e);
      this.props.error(e?.response?.data?.message ? e?.response?.data?.message : e.toString())
    })
      .finally(() => {
        setTimeout(() => {this.setState({loading: false})}, 500);
      });
  }

  onClickTaskAddBtn = () => {
    this.setState({openPopup:true});
  }

  handleModalCloseChange = () => {
    this.setState({
      openPopup: false
    });
  }

  onSuccessTaskCreation() {
    this.setState({
      openPopup: false
    });
    this.refresh();
  }

  onChangeStatus = (e) => {
    this.onChangeDetails("status", e.target.value);
  };

  onChangeCity = (e) => {
    this.onChangeDetails("cityUuid", e.target.value);
  };

  onChangeDiscount = (e) => {
    this.onChangeDetails("discount", e.target.value);
  };

  onChangeWithHelmet = (e) => {
    this.onChangeDetails("withHelmet", e.target.value);
  };

  render() {
    const {vehicle, cities} = this.props;
    if (!vehicle) {
      return <React.Fragment>Loading...</React.Fragment>;
    }

    const position = [vehicle?.location?.lat, vehicle?.location?.lng];
    const iconUrl = determineVehicleIconUrlonStatus(vehicle);
    const vehicleIcon = new Icon({
      iconUrl: iconUrl,
      iconSize: [25, 25]
    });

    let img;
    if (vehicle?.graphUrl) {
      img = vehicle.graphUrl;
    } else {
      img = determineVehicleImage(vehicle?.category);
    }

    return (
      <div>
        <div>
          <React.Fragment>
            <Grid container spacing={2}>
              <Grid item md={12} lg={8}>
                <Card style={{height: "100%"}}>
                  <CardHeader
                    avatar={
                      <Avatar
                        aria-label="recipe"
                        style={{width: 100, height: 100}}
                        alt="Vehicle Photo"
                        src={img}
                      >
                        V
                      </Avatar>
                    }
                    titleTypographyProps={{variant: "h5"}}
                    title={vehicle?.qrCode}
                    subheader={`UUID: ${vehicle?.uuid}`}
                  />

                  <CardActions disableSpacing>
                    <Battery value={vehicle?.batteryLevel}/>
                    <Location
                      location={vehicle?.location}
                      displayLocation={false}
                      vehicleUuid={vehicle?.uuid}
                    />
                    <VehicleStatus value={vehicle?.status}/>
                    <VehicleLockStatus
                      value={vehicle?.lockStatus}
                      uuid={vehicle?.uuid}
                      refresh={this.refresh}
                    />
                    <HelmetControl
                      value={vehicle?.helmetLockStatus}
                      withHelmet={vehicle?.withHelmet}
                      uuid={vehicle?.uuid}
                      refresh={this.refresh}
                    />
                    <HeadlightControl
                      uuid={vehicle?.uuid}
                      refresh={this.refresh}
                    />
                    <VehicleAlarmControl
                      vehicleStatus={vehicle?.status}
                      uuid={vehicle?.uuid}
                      refresh={this.refresh}
                    />
                    <BatteryBoxControl
                      vehicleStatus={vehicle?.status}
                      uuid={vehicle?.uuid}
                      refresh={this.refresh}
                    />
                    <IconButton onClick={() => this.onClickTaskAddBtn()}>
                      <TaskAddIcon height={20} width={20}/>
                    </IconButton>
                  </CardActions>
                </Card>
              </Grid>
              <Grid item md={12} lg={4}>
                <Card style={{height: "100%"}}>
                  <CardHeader
                    title="Number of Orders"
                    titleTypographyProps={{variant: "h6"}}
                    className="primary"
                    action={
                      <IconButton aria-label="settings">
                        <RefreshIcon/>
                      </IconButton>
                    }
                  />
                  <CardContent style={{textAlign: "center"}}>
                    <a href="">
                      <h3>{vehicle?.countOfOrders}</h3>
                    </a>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item md={12} lg={8}>
                <Card style={{height: "100%"}}>
                  <CardHeader title="Details" className="primary"/>
                  <CardContent>
                    <Row>
                      <Col xs={12} md={6} className="userDetailsFormLabel">
                        <TextField
                          id="uuid"
                          label="Vehicle UUID"
                          className=""
                          value={vehicle?.uuid}
                          fullWidth
                          InputProps={{
                            readOnly: true,
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          variant="outlined"
                        />
                      </Col>
                      <Col xs={12} md={6} className="userDetailsFormLabel">
                        <TextField
                          id="rqCode"
                          label="QR Code"
                          className=""
                          value={vehicle?.qrCode}
                          fullWidth
                          InputProps={{
                            readOnly: true,
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          variant="outlined"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} md={6} className="userDetailsFormLabel">
                        <TextField
                          id="createdAt"
                          label="Registered time"
                          className=""
                          value={moment.utc(vehicle?.createdAt)
                            .local()
                            .format(datetime_format)}
                          fullWidth
                          InputProps={{
                            readOnly: true,
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          variant="outlined"
                        />
                      </Col>
                      <Col xs={12} md={6} className="userDetailsFormLabel">
                        <TextField
                          id="updatedAt"
                          label="Latest update time"
                          className=""
                          value={moment.utc(vehicle?.updatedAt)
                            .local()
                            .format(datetime_format)}
                          fullWidth
                          InputProps={{
                            readOnly: true,
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          variant="outlined"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} md={6} className="userDetailsFormLabel">
                        <TextField
                          id="status"
                          label="Status"
                          className=""
                          value={vehicle?.status}
                          select
                          onChange={this.onChangeStatus}
                          fullWidth
                          InputLabelProps={{
                            shrink: true,
                          }}
                          variant="outlined"
                        >
                          <MenuItem key="AVAILABLE" value="AVAILABLE">
                            Available
                          </MenuItem>
                          <MenuItem key="RUNNING" value="RUNNING" disabled="true">
                            Running
                          </MenuItem>
                          <MenuItem key="RESERVED" value="RESERVED">
                            Reserved
                          </MenuItem>
                          <MenuItem key="REPORTED" value="REPORTED">
                            Reported
                          </MenuItem>
                          <MenuItem key="MAINTAINING" value="MAINTAINING">
                            Maintaining
                          </MenuItem>
                          <MenuItem key="MAINTENANCE_PENDING" value="MAINTENANCE_PENDING">
                            Maintenance Pending 
                          </MenuItem>
                          <MenuItem key="DAMAGED" value="DAMAGED">
                            Damaged
                          </MenuItem>
                          <MenuItem key="LOST" value="LOST">
                            Lost
                          </MenuItem>
                        </TextField>
                      </Col>
                      <Col xs={12} md={6} className="userDetailsFormLabel">
                        <TextField
                          id="imei"
                          label="IMEI"
                          className=""
                          value={vehicle?.imei}
                          fullWidth
                          InputProps={{
                            readOnly: true,
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          variant="outlined"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} md={6} className="userDetailsFormLabel">
                        <TextField
                          id="lockStatus"
                          label="Lock Status"
                          className=""
                          value={vehicle?.lockStatus}
                          fullWidth
                          InputProps={{
                            readOnly: true,
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          variant="outlined"
                        />
                      </Col>
                      <Col xs={12} md={6} className="userDetailsFormLabel">
                        <TextField
                          id="lastSyncAt"
                          label="Last sync time"
                          className=""
                          value={
                            vehicle?.lastSyncAt
                              ? moment.utc(vehicle?.lastSyncAt, true).local().format(
                                  datetime_format
                                )
                              : ""
                          }
                          fullWidth
                          InputProps={{
                            readOnly: true,
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          variant="outlined"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} md={6} className="userDetailsFormLabel">
                        <TextField
                          id="batteryLevel"
                          label="Battery level"
                          className=""
                          value={vehicle?.batteryLevel}
                          fullWidth
                          InputProps={{
                            readOnly: true,
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          variant="outlined"
                        />
                      </Col>
                      <Col xs={12} md={6} className="userDetailsFormLabel">
                        <TextField
                          id="frameNO"
                          label="Frame No"
                          value={vehicle?.frameNo}
                          fullWidth
                          InputProps={{
                            readOnly: true,
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          variant="outlined"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} md={6} className="userDetailsFormLabel">
                        <TextField
                          id="city"
                          label="City"
                          select
                          className=""
                          value={vehicle?.cityUuid}
                          onChange={this.onChangeCity}
                          fullWidth
                          InputLabelProps={{
                            shrink: true,
                          }}
                          variant="outlined"
                        >
                          {cities?.content.map((option) => {
                            const vehicleSupported = option?.vehiclesSupported;
                            if (vehicleSupported.indexOf(vehicle?.category) > -1) {
                              return (
                                <MenuItem key={option.uuid} value={option.uuid}>
                                  {option.cityName}
                                </MenuItem>
                              );
                            }
                          })}
                        </TextField>
                      </Col>
                      <Col xs={12} md={6} className="userDetailsFormLabel">
                        <TextField
                          id="region"
                          label="Region"
                          className=""
                          value={vehicle?.regionName}
                          fullWidth
                          InputProps={{
                            readOnly: true,
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          variant="outlined"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} md={6} className="userDetailsFormLabel">
                        <TextField
                          id="latestOrderAt"
                          label="Latest order time"
                          className=""
                          value={vehicle && vehicle.status === "RUNNING" ?
                            moment.utc(vehicle?.currentOrderStartAt).local().format(datetime_format) :
                            moment.utc(vehicle?.latestOrderEndAt).local().format(datetime_format)}
                          fullWidth
                          InputProps={{
                            readOnly: true,
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          variant="outlined"
                        />
                      </Col>
                      <Col xs={12} md={6} className="userDetailsFormLabel">
                        <TextField
                          id="latestCheckingAt"
                          label="Latest checking time"
                          className=""
                          value={
                            vehicle?.latestCheckingAt
                              ? moment.utc(vehicle?.latestCheckingAt, true).local().format(datetime_format)
                              : ""
                          }
                          fullWidth
                          InputProps={{
                            readOnly: true,
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          variant="outlined"
                        />
                      </Col>
                    </Row>
                    <Row>
                      {/*<Col xs={12} md={6} className="padding10">
                        <DiscountSlider
                          value={vehicle?.discount && parseInt(vehicle?.discount * 100)}
                          onChange={(e, newValue) => {
                            this.onChangeDetails("discount", newValue/100)
                          }}/>
                        </Col> */}
                      <Col xs={12} md={6} className="userDetailsFormLabel">
                        <TextField
                          id="cleanedAt"
                          label="Latest Cleaning Time"
                          className=""
                          value={
                            vehicle?.cleanedAt
                              ? moment.utc(vehicle?.cleanedAt, true).local().format(datetime_format)
                              : ""
                          }
                          fullWidth
                          InputProps={{
                            readOnly: true,
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          variant="outlined"
                        />
                      </Col>
                      <Col xs={12} md={6} className="userDetailsFormLabel">
                        <TextField
                          id="withHelmet"
                          label="Helmet Equiped"
                          className=""
                          select
                          onChange={this.onChangeWithHelmet}
                          value= {vehicle?.withHelmet}
                          fullWidth
                          InputLabelProps={{
                            shrink: true,
                          }}
                          variant="outlined"
                        >
                          <MenuItem key="TRUE" value="TRUE">
                            TRUE
                          </MenuItem>
                          <MenuItem key="FALSE" value="FALSE">
                            FALSE
                          </MenuItem>
                          <MenuItem key="PENDING" value="PENDING">
                            PENDING
                          </MenuItem>
                        </TextField>
                      </Col>

                    </Row>
                    <Row
                      style={{ justifyContent: "flex-end", marginRight: "auto" }}
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => this.onClickSaveBtn()}
                        disabled={this.state?.loading || !this.isDirty}
                      >
                        {this.state.loading ? <CircularProgress style={{width:22, height:22}} className="white"/> : 'Save'}
                      </Button>
                    </Row>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item md={12} lg={4}>
                <Card style={{ height: "100%" }}>
                  <CardHeader title="Location" className="primary" />
                  <CardContent>
                    <MapContainer
                      center={position}
                      zoom={13}
                      scrollWheelZoom={true}
                      key={`vehicle-detail-map-position-${JSON.stringify(
                        position
                      )}`}
                    >
                      <LayersControl position="topright">
                        <LayersControl.BaseLayer checked name="Black And White">
                          <TileLayer
                            attribution='Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> Imagery © <a href="https://www.mapbox.com/">Mapbox</a>'
                            url="https://api.mapbox.com/styles/v1/mapbox/dark-v10/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoidHJhY2RldmVsb3BlciIsImEiOiJja2szOGJteHMxOGxhMm9wYzFhbmgwazkyIn0._UOy-teYfb2kzsEMdr8YkA"
                          />
                        </LayersControl.BaseLayer>
                        <LayersControl.BaseLayer name="Normal Map">
                          <TileLayer
                            attribution='Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> Imagery © <a href="https://www.mapbox.com/">Mapbox</a>'
                            url="https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoidHJhY2RldmVsb3BlciIsImEiOiJja2szOGJteHMxOGxhMm9wYzFhbmgwazkyIn0._UOy-teYfb2kzsEMdr8YkA"
                          />
                        </LayersControl.BaseLayer>
                        <LayersControl.BaseLayer name="Satellite Map">
                          <TileLayer
                            attribution='Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> Imagery © <a href="https://www.mapbox.com/">Mapbox</a>'
                            url="https://api.mapbox.com/styles/v1/mapbox/satellite-v9/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoidHJhY2RldmVsb3BlciIsImEiOiJja2szOGJteHMxOGxhMm9wYzFhbmgwazkyIn0._UOy-teYfb2kzsEMdr8YkA"
                          />
                        </LayersControl.BaseLayer>
                        <LayersControl.Overlay checked name="Bike">
                          <LayerGroup>
                            <VehicleMarker
                              vehicle={vehicle}
                              key={`vehicle-map-marker-key-${vehicle?.uuid}`}
                            />
                          </LayerGroup>
                        </LayersControl.Overlay>
                      </LayersControl>
                    </MapContainer>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item md={12} lg={12}>
                <Card>
                  <OrderList
                    key={`vehicle-order-list-${this.props.location.pathname.replace("/vehicle/", "")}`}
                    filters={[{ key: "vehicleUuid", value: `${this.props.location.pathname.replace("/vehicle/", "")}` }]}
                  />
                </Card>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item md={12} lg={12}>
                <Card>
                  <TaskList
                    key={`vehicle-task-list-${this.props.location.pathname.replace("/vehicle/", "")}`}
                    filters={[{ key: "vehicleUuid", value: `${this.props.location.pathname.replace("/vehicle/", "")}` }]}
                  />
                </Card>
              </Grid>
              <Grid item md={12} lg={12}>
                <Card>
                  <GroundTeamEventLogList
                    key={`vehicle-ground-team-event-log-list-${this.props.location.pathname.replace("/vehicle/", "")}`}
                    filters={[{ key: "vehicleUuid", value: `${this.props.location.pathname.replace("/vehicle/", "")}` }]}
                  />
                </Card>
              </Grid>
              <Grid item md={12} lg={12}>
                <Card>
                  <ConsoleEventLogList
                    key={`vehicle-task-list-${this.props.location.pathname.replace("/vehicle/", "")}`}
                    filters={[{ key: "vehicleUuid", value: `${this.props.location.pathname.replace("/vehicle/", "")}` }]}
                  />
                </Card>
              </Grid>
            </Grid>
          </React.Fragment>
        </div>
          <TaskCreateModal
          open={this.state.openPopup}
          lat={vehicle?.location?.lat}
          lng={vehicle?.location?.lng}
          vehicleId={vehicle?.uuid}
          onCloseModal={() => this.handleModalCloseChange()}
          callback={() => this.onSuccessTaskCreation()}
          ></TaskCreateModal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  vehicle: state.vehicleData.vehicle,
  cities: state.cityReducer.cities,
});

const mapDispatchToProps = (dispatch) => ({
  getVehicleByUuid: (uuid) => dispatch(getVehicleByUuid(uuid)),
  setVehicle: (payload) => dispatch(setVehicle(payload)),
  updateVehicle: (payload) => dispatch(updateVehicle(payload)),
  search: (params) => dispatch(search(params)),
  success: (message) => dispatch(success(message)),
  error: (message) => dispatch(error(message))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withAuth0(VehicleDetail));
