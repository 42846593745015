import React, {Component} from "react";
import {connect} from "react-redux";
import {getTaskByUuid} from "../../../redux/task/task.action";
import {withAuth0} from "@auth0/auth0-react";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import {Grid, Tooltip} from "@material-ui/core";
import {determineVehicleImage, sub} from "../../../utils/utils";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import TextField from "@material-ui/core/TextField";
import "./details.css";
import moment from "moment";

import Battery from "../../OperationCenter/VehicleInformation/VehicleList/bettery";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import { green, grey, red } from "@material-ui/core/colors/";
import VehicleStatus from "../../OperationCenter/VehicleInformation/VehicleList/vehicle-status";
import TaskMap from "./TaskDetailMap";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import {getGroundTeamUsers} from "../../../redux/groundTeamUser/groundTeamUser.action";
import MenuItem from "@material-ui/core/MenuItem";
import axios from "axios";
import {config} from "../../../auth/auth";
import CardMedia from "@material-ui/core/CardMedia";
import { success } from "../../../redux/alert/alert.action"
import { error } from "../../../redux/alert/alert.action";



const dateTimeFormat = process.env.REACT_APP_DATETIME_FORMAT;
const timeFormat = process.env.REACT_APP_TIME_FORMAT;
const host = process.env.REACT_APP_HOST

class TaskDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      assignee: "",
      timePeriod: 0,
    };
  }

  componentDidMount() {
    this.getTaskDetails();
    const {getGroundTeamUsers} = this.props;

    // table state
    let params = new URLSearchParams();
    params.set("page", "0");
    params.set("size", "1000");

    getGroundTeamUsers(params);
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (prevProps.task !== this.props.task) {
      this.setTimePeriod();
    }
  }

  componentWillUnmount() {
    clearInterval(this.myInterval)
  }

  setTimePeriod() {
    if (this.props.task && this.props.task.status === "CANCELED") {
      clearInterval(this.myInterval);
      return;
    }
    const assignedTime = moment.utc(this.props.task.assignedAt).local();
    const currentTime = this.props.task.finishedAt
      ? moment.utc(this.props.task.finishedAt).local()
      : moment.utc().local();
    const milliseconds = currentTime.diff(assignedTime);
    const seconds = Math.floor((milliseconds / 1000) % 60);
    const minutes = Math.floor((milliseconds / (1000 * 60)) % 60);
    const hours = Math.floor(milliseconds / (1000 * 60 * 60));
    const secondString = seconds < 10 ? "0" + seconds : seconds;
    const minuteString = minutes < 10 ? "0" + minutes : minutes;
    const hourString = hours < 10 ? "0" + hours : hours;

    if (this.props.task && (this.props.task.status === "FINISHED" || this.props.task.status === "FAILED")) {
      clearInterval(this.myInterval);
      this.setState(() => ({
        timePeriod: `${hourString}:${minuteString}:${secondString}`
      }))
      return;
    }
    this.myInterval = setTimeout(() => {
      this.setState(() => ({
        timePeriod: `${hourString}:${minuteString}:${secondString}`
      }))
      //this.setTimePeriod();
    }, 1000)
  }

  getTaskDetails = () => {
    const uuid = this.props.location.pathname.replace("/task/", "");
    this.props.getTaskByUuid(uuid);
  };

  onChangeAssignedPerson = (e) => {
    const { task } = this.props;

    return axios
      .put(`${host}/task/${task.uuid}/assignee?assigneeUuid=${e.target.value}`, {}, config())
      .then((response) => {
        this.setState({ assignee: e.target.value });
        this.getTaskDetails();
        this.props.success('successfully assigned');
      })
      .catch((e) => {
        this.props.error(e?.response?.data?.message ? e?.response?.data?.message : e.toString())
      })
      .finally(() => {});
  };

  markTaskStatus(status) {
    return axios
    .put(`${host}/task/${this.props.task.uuid}/status?status=${status}`, {}, config())
    .then((response) => {
      this.getTaskDetails();
      this.props.success(`Task ${this.props.task.uuid} marked as ${status}`)
    })
    .catch((e) => {
      this.props.error(e?.response?.data?.message ? e?.response?.data?.message : e.toString())
    })
    .finally(() => {});
  }

  render() {
    const { task, history, groundTeamUsers } = this.props;
    if (!task || !task?.uuid) {
      return <React.Fragment>Loading...</React.Fragment>;
    }
    this.state.assignee = task?.assignee?.uuid;

    return (
      <React.Fragment>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={8}>
            {/* UUID CARD START */}
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Card>
                  <CardHeader
                    avatar={
                      <Avatar
                        aria-label="Order"
                        style={{ width: 80, height: 80 }}
                        alt="Order Photo"
                        src={"/img/OrderIcon.png"}
                      />
                    }
                    titleTypographyProps={{ variant: "h5" }}
                    title={`UUID: ${task.uuid}`}
                  />
                  {task.status !== "FINISHED" && task.status !== "FAILED" && task.status !== "CANCELED"?
                    <CardActions disableSpacing>
                      <Tooltip title={"Mark as Completed"} onClick={() => this.markTaskStatus("FINISHED")}>
                        <IconButton>
                          <CheckCircleIcon style={{ color: green[400] }} />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title={"Mark as Failed"} onClick={() => this.markTaskStatus("FAILED")}>
                        <IconButton>
                          <CancelIcon style={{ color: red[900] }} />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title={"Mark as Cancelled"} onClick={() => this.markTaskStatus("CANCELED")}>
                        <IconButton>
                          <img
                            aria-label="cancel"
                            style={{ width: 20, height: 20, margin: 2 }}
                            alt="cancel"
                            src={"/icon/cancel.svg"}
                          />
                        </IconButton>
                      </Tooltip>
                    </CardActions> :
                    <CardActions disableSpacing>
                      <Tooltip title={"Task Closed"} onClick={() => alert(`The task is: ${task.status}`)}>
                        <IconButton>
                          <CheckCircleIcon style={{ color: grey[400] }} />
                        </IconButton>
                      </Tooltip>
                    </CardActions>
                  }
                </Card>
              </Grid>
            </Grid> 
            {/* ! UUID CARD FINISH */}

            {/* VEHICLE CARD START */}
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Card>
                  <CardHeader
                    avatar={
                      <Avatar
                        aria-label="Vehicle"
                        style={{ width: 80, height: 80 }}
                        alt="Vehicle Photo"
                        src={task?.vehicle?.category ? determineVehicleImage(task?.vehicle?.category) : ""}
                        onClick={() =>
                          history.push(`/vehicle/${task?.vehicle?.uuid}`)
                        }
                      >
                        V
                      </Avatar>
                    }
                    titleTypographyProps={{ variant: "h5" }}
                    title={`${sub(task?.vehicle?.qrCode)}`}
                    subheader={`UUID: ${sub(task?.vehicle?.uuid)}`}
                  />

                  <CardActions disableSpacing>
                    <Battery value={task?.vehicle?.batteryLevel} />
                    <VehicleStatus value={task?.vehicle?.status} />
                  </CardActions>
                </Card>
              </Grid>
            </Grid>
            {/* ! VEHICLE CARD FINISH */}

            {/* ASSIGNED PERSON CARD START */}
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Card>
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={12} lg={2}>
                        <Avatar
                          aria-label="User"
                          style={{ width: 80, height: 80 }}
                          alt="User Photo"
                          src={task?.assignee?.picture}
                        >
                          {task?.assignee? `${task?.assignee?.firstName?.charAt(0).toUpperCase()}
                            ${task?.assignee?.lastName?.charAt(0).toUpperCase()}`
                          : 'NONE'}
                        </Avatar>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={6}>
                        {task.status !== "FINISHED" && task.status !== "FAILED" && task.status !== "CANCELED" ?
                          <TextField
                            id="assignee"
                            select
                            label="Assigned Person"
                            value={this.state.assignee}
                            onChange={this.onChangeAssignedPerson}
                            fullWidth
                            InputProps={{
                              readOnly: false,
                            }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            variant="outlined"
                            style={{ marginTop: "15px" }}
                          >
                            {groundTeamUsers?.content.map((option) => (
                              <MenuItem key={option.uuid} value={option.uuid}>
                                {option.name}
                              </MenuItem>
                            ))}
                          </TextField> :
                          <TextField
                            id="assignee"
                            label="Assigned Person"
                            value={task?.assignee ? 
                                  `${task?.assignee?.firstName} ${task?.assignee?.lastName}` :
                                  "NOT ASSIGNED"
                                }
                            onChange={this.onChangeAssignedPerson}
                            fullWidth
                            InputProps={{
                              readOnly: true,
                            }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            variant="outlined"
                            style={{ marginTop: "15px" }}
                          ></TextField>
                        }
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
            {/* ! ASSIGNED PERSON CARD FINISH */}

            {/* ASSIGNER PERSON CARD START */}
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Card>
                  <CardHeader
                    avatar={
                      task?.assigner !== undefined ? <Avatar
                        aria-label="assigner"
                        style={{ width: 80, height: 80 }}
                        alt="assigner Photo"
                        src={task?.assigner?.picture}
                      >
                        {`${task?.assigner?.firstName
                          ?.charAt(0)
                          .toUpperCase()}${task?.assigner?.lastName
                          ?.charAt(0)
                          .toUpperCase()}`}
                      </Avatar>: <Avatar
                        aria-label="assigner"
                        style={{ width: 80, height: 80 }}
                        alt="assigner Photo"
                        src={task?.assigner?.picture}
                      >
                        AI
                      </Avatar>
                    }
                    titleTypographyProps={{ variant: "h5" }}
                    title={
                      task?.assigner !== undefined ? 
                      "Admin - " + sub(`${task?.assigner?.firstName} ${task?.assigner?.lastName}`) :
                      "Automatic Created Task"
                    }
                    subheader={
                      task?.assigner !== undefined ?
                      `UUID: ${task?.assigner?.uuid}`:
                      ""
                    }
                  />
                </Card>
              </Grid>
            </Grid>
            {/* ! ASSIGNER PERSON CARD FINISH */}

            {/* TASK DETAIL CARD START */}
            <Grid container spacing={2}>
              <Grid item md={12} lg={12}>
                <Card>
                  <CardHeader title="Task Details" />
                  <CardContent>
                    <Row>
                      <Col xs={12} md={6} className="userDetailsFormLabel">
                        {" "}
                        <TextField
                          id="uuid"
                          label="Task UUID"
                          className=""
                          value={task?.uuid}
                          fullWidth
                          InputProps={{
                            readOnly: true,
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          variant="outlined"
                        />
                      </Col>
                      <Col xs={12} md={6} className="userDetailsFormLabel">
                        {" "}
                        <TextField
                          id="Category"
                          label="Category"
                          className=""
                          value={task?.category}
                          fullWidth
                          InputProps={{
                            readOnly: true,
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          variant="outlined"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} md={6} className="userDetailsFormLabel">
                        {" "}
                        <TextField
                          id="createdAt"
                          label="Task Created"
                          className=""
                          value={task && task.createdAt 
                            && moment.utc(task.createdAt).local().format(dateTimeFormat)}
                          fullWidth
                          InputProps={{
                            readOnly: true,
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          variant="outlined"
                        />
                      </Col>
                      <Col xs={12} md={6} className="userDetailsFormLabel">
                        {" "}
                        <TextField
                          id="updatedAt"
                          label="Latest Status Update"
                          className=""
                          value={task && task.updatedAt 
                            && moment.utc(task.updatedAt).local().format(dateTimeFormat)}
                          fullWidth
                          InputProps={{
                            readOnly: true,
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          variant="outlined"
                        />
                      </Col>
                    </Row>

                    <Row>
                      <Col xs={12} md={6} className="userDetailsFormLabel">
                        {" "}
                        <TextField
                          id="status"
                          label="Task Status"
                          className=""
                          value={task?.status}
                          fullWidth
                          InputProps={{
                            readOnly: true,
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          variant="outlined"
                        />
                      </Col>
                      <Col xs={12} md={6} className="userDetailsFormLabel">
                        {" "}
                        <TextField
                          id="isNote"
                          label="Task Note"
                          className=""
                          value={task?.note}
                          fullWidth
                          InputProps={{
                            readOnly: true,
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          variant="outlined"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} md={6} className="userDetailsFormLabel">
                        {" "}
                        <TextField
                          id="finishedAt"
                          label="Task Completion "
                          className=""
                          value={task && task.finishedAt 
                            && moment.utc(task.finishedAt).local().format(dateTimeFormat)}
                          fullWidth
                          InputProps={{
                            readOnly: true,
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          variant="outlined"
                        />
                      </Col>
                      <Col xs={12} md={6} className="userDetailsFormLabel">
                        {" "}
                        <TextField
                          id="assignedAt"
                          label="The Latest Assigned Time"
                          className=""
                          value={task && task.assignedAt 
                            && moment.utc(task.assignedAt).local().format(dateTimeFormat)}
                          fullWidth
                          InputProps={{
                            readOnly: true,
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          variant="outlined"
                        />
                      </Col>
                    </Row>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
            {/* ! TASK DETAIL CARD START */}
          </Grid>
          <Grid item md={12} lg={4}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Card style={{ height: "100%" }}>
                  <CardContent>
                    <Row>
                      <Col xs={12} md={12} className="userDetailsFormLabel">
                        <div className="text-center">
                          {" "}
                          <h4 className="primary">
                            Time Period(from the task last assigned)
                          </h4>
                        </div>
                        <div className="text-center">
                          {
                            task.status === "CANCELED" ? (
                              <h3 style={{ marginTop: "34px" }}>{" "}Not Applicable</h3>
                            ) : (
                              <h3 style={{ marginTop: "34px" }}>
                                {" "}
                                <AccessTimeIcon
                                  style={{ marginTop: "-4px" }}
                                ></AccessTimeIcon>{" "}
                                  &nbsp; <span>{task && task.assignedAt && this.state.timePeriod}</span>
                              </h3>
                            )
                          }
                        </div>
                      </Col>
                    </Row>
                  </CardContent>
                  <CardActions disableSpacing></CardActions>
                </Card>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <TaskMap
                  key={`task-list-${this.uuid}`}
                  history={this.props.history}
                  filters={[{ key: "uuid", value: `${task.uuid}` }]}
                ></TaskMap>
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <Card>
                  <CardHeader
                    title="Task Picture"
                    titleTypographyProps={{ variant: "h6" }}
                  />
                  {task.graphUrl && <CardContent>
                    <Grid container spacing={2}>
                      <Grid item md={12} lg={1}></Grid>
                      <Grid item md={12} lg={8}>
                        <CardMedia
                          component="img"
                          alt="Parking Picture"
                          image={task?.graphUrl}
                          title="Parking Picture"
                        />
                      </Grid>
                      <Grid item md={12} lg={1}></Grid>
                    </Grid>
                  </CardContent>}
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  task: state.taskData.task,
  groundTeamUsers: state.groundTeamUserReducer.groundTeamUsers,
});

const mapDispatchToProps = (dispatch) => ({
  getTaskByUuid: (uuid) => dispatch(getTaskByUuid(uuid)),
  getGroundTeamUsers: (params) => dispatch(getGroundTeamUsers(params)),
  success: (msg) => dispatch(success(msg)),
  error: (msg) => dispatch(error(msg))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withAuth0(TaskDetail));