import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import PersonIcon from '@material-ui/icons/Person';
import CancelIcon from "@material-ui/icons/Cancel";
import TrashIcon from "@material-ui/icons/Delete";
import { green, red, grey} from "@material-ui/core/colors/";
import Modal from "react-bootstrap/Modal";
import TextField from "@material-ui/core/TextField";
import { getGroundTeamUsers } from "../../../redux/groundTeamUser/groundTeamUser.action";
import MenuItem from "@material-ui/core/MenuItem";
import axios from "axios";
import { config } from "../../../auth/auth";
import { connect } from "react-redux";
import { withAuth0 } from "@auth0/auth0-react";
import {
  Button,
  Dialog,
  DialogActions, DialogContent, DialogContentText,
  DialogTitle,
} from "@material-ui/core";

const host = process.env.REACT_APP_HOST;

const defaultToolbarSelectStyles = {
  iconButton: {
  },
  iconContainer: {
    marginRight: "24px",
  },
  inverseIcon: {
    transform: "rotate(90deg)",
  },
};

class CustomToolbarSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showAssignedUsers: false,
      assignee: null,
      open: false
    };
    this.selectedAction = undefined;
  }
  componentDidMount() {
    this.refresh();
  }

  refresh = () => {
    this.props.getGroundTeamUsers();
  };

  handleClickMarkSuccess = () => {
    this.selectedAction = 'FINISHED';
    this.setState({open: true})
  };

  handleClickMarkFailed = () => {
    this.selectedAction = 'FAILED';
    this.setState({open: true})
  };

  // Assigned Task
  handleClickAssigned = () => {
    this.setState({showAssignedUsers: true})
  }

  // Delete Task
  handleClickDeleted= () => {
    this.selectedAction = 'DELETE';
    this.setState({open: true})
  };

  // Close Assigned Usser Modal
  onCloseModal = () => {
    this.setState({showAssignedUsers: false})
  }

  // Confirm Assigned Users
  onConfirm = () => {
    console.log(this.props);
    const { tasks, selectedRows, callback } = this.props;
    const taskData = tasks?.content;
    selectedRows.data.map((row, index)=> {
      const rowData = taskData[row.index];
      this.assignTask(rowData, index);

    })
  }
  // For delete tasks
  confirmAction = () => {
    const { tasks, selectedRows } = this.props;
    const taskData = tasks?.content;
    selectedRows.data.map((row, index)=> {
      const rowData = taskData[row.index];
      if(this.selectedAction === 'DELETE') { 
        this.deleteTask(rowData, index);
      } else {
        this.changeStatus(rowData, index);
      }
      
    })
  }
  // end

  // change status
  changeStatus = (rowData, index) => {
      const { selectedRows, callback } = this.props;
      return axios
        .put(`${host}/task/${rowData.uuid}/status?status=${this.selectedAction}`, { }, config())
        .then((response) => {
            const count = selectedRows.data.length - 1;
            if(index === count) {
              callback();
              this.setState({showAssignedUsers: false})
            }
        })
        .catch((e) => alert(e))
        .finally(() => {});
    }



  // Assigning Task
  assignTask = (rowData, index) => {
    const { selectedRows, callback } = this.props;
    const URL = encodeURI(`${host}/task/${rowData.uuid}/assignee?assigneeUuid=${this.state.assignee}`);
    return axios
      .put(URL, {}, config())
      .then((response) => {
          const count = selectedRows.data.length - 1;
          if(index === count) {
            callback();
            this.setState({showAssignedUsers: false})
          }
      })
      .catch((e) => alert(e))
      .finally(() => {});
  }

  // Delete Task
  deleteTask = (rowData, index) => {
    const { selectedRows, callback } = this.props;
    return axios
      .delete(`${host}/task/${rowData.uuid}`, config())
      .then((response) => {
          const count = selectedRows.data.length - 1;
          if(index === count) {
            callback();
            this.setState({showAssignedUsers: false})
          }
      })
      .catch((e) => alert(e))
      .finally(() => {});
  }

  // On Change Assigned Person
  onChangeAssignedPerson = (e) => {
    this.setState({ assignee: e.target.value }); 
  };

  // Close Confirmation Modal
  closeConfirmModal = () => {
    this.setState({open: false})
  }

  render() {
    const { classes, groundTeamUsers } = this.props;
    const users = groundTeamUsers;

    return (
      <div className={classes.iconContainer}>
        <Tooltip title={"Mark as Completed"}>
            <IconButton className={classes.iconButton} onClick={this.handleClickMarkSuccess}>
                  <CheckCircleIcon className={classes.icon} style={{color: green[400],}}/>
            </IconButton>
        </Tooltip>
        <Tooltip title={"Mark as Failed"}>
            <IconButton className={classes.iconButton} onClick={this.handleClickMarkFailed}>
                  <CancelIcon className={classes.icon} style={{color: red[400],}}/>
            </IconButton>
        </Tooltip>
        <Tooltip title={"Assign to User"}>
          <IconButton className={classes.iconButton} onClick={this.handleClickAssigned}>
          <PersonIcon className={classes.icon} />
          </IconButton>
        </Tooltip>
        {/* <Tooltip title={"Delete selected"}>
            <IconButton className={classes.iconButton} onClick={this.handleClickDeleted}>
              <TrashIcon className={classes.icon} />
            </IconButton>
        </Tooltip> */}
          <Modal
              show={this.state.showAssignedUsers}
              onHide={() => this.onCloseModal()}
              animation={false}
            >
              <Modal.Header closeButton>
                <Modal.Title>Assign to user</Modal.Title>
              </Modal.Header>
              <Modal.Body style={{ padding: "0px" }}>
                <div style={{ padding: "15px"}}>
                     <TextField
                          id="assignee"
                          select
                          label="Assigned Person"
                          value={this.state.assignee}
                          onChange={this.onChangeAssignedPerson}
                          fullWidth
                          InputProps={{
                            readOnly: false,
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          variant="outlined"
                          style={{ marginTop: "15px" }}
                        >
                          {users?.content.map((option) => (
                            <MenuItem key={option.uuid} value={option.uuid}>
                              {option.name}
                            </MenuItem>
                          ))}
                     </TextField>
                </div>
                  
              </Modal.Body>
              <Modal.Footer>
              <Button
                  size="small"
                  variant="secondary"
                  onClick={() => this.onCloseModal()}
                >
                  No
                </Button>
                  <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={() => this.onConfirm()}
                >
                  Yes
                </Button>
               
              </Modal.Footer>
          </Modal>

          <Dialog
            open={this.state.open}
            onClose={() => this.closeConfirmModal()}
            aria-labelledby="responsive-dialog-title"
            >
            <DialogTitle id="responsive-dialog-title">{"Are you absolutely sure?"}</DialogTitle>
              <DialogContent>
                <DialogContentText>
                          <span   style={{
                                  display: this.selectedAction === 'DELETE' ? "block" : "none",
                              }}>This action cannot be undone. This will be permanently delete.</span>
                          <span   style={{
                                  display: this.selectedAction === 'FINISHED' ? "block" : "none",
                             }}>This action cannot be undone. This will mark tasks as finished.</span>
                          <span   style={{
                                  display: this.selectedAction === 'FAILED' ? "block" : "none",
                             }}>This action cannot be undone. This will mark tasks as failed.</span>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => this.closeConfirmModal()} style={{ color: grey[500] }}>
                  Cancel
                </Button>
                {/* <Button style={{ display: this.selectedAction !== 'FINISHED' && this.selectedAction !== 'FAILED'? "block" : "none",}} 
                        onClick={() => this.confirmAction()} style={{ color: red[600] }}>
                  Delete
                </Button> */}
                <Button variant="contained" color="primary" onClick={() => this.confirmAction()}>
                  okay
                </Button>
              </DialogActions>
          </Dialog>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  tasks: state.taskData.tasks,
  groundTeamUsers: state.groundTeamUserReducer.groundTeamUsers,
});

const mapDispatchToProps = (dispatch) => ({
  getGroundTeamUsers: () => dispatch(getGroundTeamUsers()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withAuth0(withStyles(defaultToolbarSelectStyles, { name: "CustomToolbarSelect" })(CustomToolbarSelect)));