import axios from "axios";

import {config, getAccessToken} from "../../auth/auth";
import {DashboardActionTypes} from "./dashboard.types";

const host = process.env.REACT_APP_HOST

export const getDailyOrders = () => {
  return async dispatch => {
    return axios.get(`${host}/statistics/dailyOrders`, config()).then(response => dispatch(setDailyOrders(response.data)), err => console.log(err));
  }
}
export const getRevenueCurrentMonth = () => {
  return async dispatch => {
    return axios.get(`${host}/statistics/revenueCurrentMonth`, config()).then(response => dispatch(setRevenueCurrentMonth(response.data)), err => console.log(err));
  }
}
export const getDailyTasks = () => {
  return async dispatch => {
    return axios.get(`${host}/statistics/dailyTasks`, config()).then(response => dispatch(setDailyTasks(response.data)), err => console.log(err));
  }
}
export const getDailyOperationalVehicles = () => {
  return async dispatch => {
    return axios.get(`${host}/statistics/dailyOperationalVehicles`, config()).then(response => dispatch(setDailyOperationalVehicles(response.data)), err => console.log(err));
  }
}

export const getRevenue = (cityUuid = undefined) => {

  const config = {
    headers: {
      'Authorization': `Bearer ${getAccessToken()}`,
      'Content-Type': 'application/json'
    },
    params: cityUuid ? {cityUuid} : {}
  }

  return async dispatch => {
    return axios.get(`${host}/statistics/revenue`, config)
      .then(response => dispatch(setRevenue(response.data)), err => console.log(err));
  }
}

export const getTopUp = (cityUuid = undefined) => {

  const config = {
    headers: {
      'Authorization': `Bearer ${getAccessToken()}`,
      'Content-Type': 'application/json'
    },
    params: cityUuid ? {cityUuid} : {}
  }

  return async dispatch => {
    return axios.get(`${host}/statistics/topup`, config)
      .then(response => dispatch(setTopUp(response.data)), err => console.log(err));
  }
}

export const getVehicleStatus = () => {
  return async dispatch => {
    return axios.get(`${host}/statistics/vehicleStatus`, config()).then(response => dispatch(setVehicleStatus(response.data)), err => console.log(err));
  }
}
export const getVehicleBatteryStatus = () => {
  return async dispatch => {
    return axios.get(`${host}/statistics/vehicleBatteryStatus`, config()).then(response => dispatch(setVehicleBatteryStatus(response.data)), err => console.log(err));
  }
}
export const getOrder = () => {
  return async dispatch => {
    return axios.get(`${host}/statistics/orderOverview`, config()).then(response => dispatch(setOrder(response.data)), err => console.log(err));
  }
}
export const getNewUserRegistration = () => {
  return async dispatch => {
    return axios.get(`${host}/statistics/newUserRegistration`, config()).then(response => dispatch(setNewUserRegistration(response.data)), err => console.log(err));
  }
}
export const getMigrationProgress = () => {
  return async dispatch => {
    return axios.get(`${host}/statistics/migrationProgress`, config()).then(response => dispatch(setMigrationProgress(response.data)), err => console.log(err));
  }
}

export const getUserSatisfaction = () => {
  return async dispatch => {
    return axios.get(`${host}/statistics/userSatisfaction`, config()).then(response => dispatch(setUserSatisfaction(response.data)), err => console.log(err));
  }
}

export const setDailyOrders = (payload) => ({type: DashboardActionTypes.SET_DAILY_ORDERS, payload: payload,});
export const setRevenueCurrentMonth = (payload) => ({
  type: DashboardActionTypes.SET_REVENUE_CURRENT_MONTH,
  payload: payload,
});
export const setDailyTasks = (payload) => ({type: DashboardActionTypes.SET_DAILY_TASKS, payload: payload,});
export const setDailyOperationalVehicles = (payload) => ({
  type: DashboardActionTypes.SET_DAILY_OPERATIONAL_VEHICLES,
  payload: payload,
});
export const setRevenue = (payload) => ({type: DashboardActionTypes.SET_REVENUE, payload: payload,});
export const setTopUp = (payload) => ({type: DashboardActionTypes.SET_TOPUP, payload: payload,});
export const setVehicleStatus = (payload) => ({type: DashboardActionTypes.SET_VEHICLE_STATUS, payload: payload,});
export const setVehicleBatteryStatus = (payload) => ({
  type: DashboardActionTypes.SET_VEHICLE_BATTERY_STATUS,
  payload: payload,
});
export const setOrder = (payload) => ({type: DashboardActionTypes.SET_ORDER, payload: payload,});
export const setNewUserRegistration = (payload) => ({
  type: DashboardActionTypes.SET_NEW_USER_REGISTRATION,
  payload: payload,
});
export const setMigrationProgress = (payload) => ({
  type: DashboardActionTypes.SET_MIGRATION_PROGRESS,
  payload: payload,
});
export const setUserSatisfaction = (payload) => ({
  type: DashboardActionTypes.SET_USER_SATISFACTION,
  payload: payload,
});