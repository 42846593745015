import axios from "axios";
import {ActionTypes} from "./types";
import {config} from "../../auth/auth";

const host = process.env.REACT_APP_HOST

export const getPartnerSetting = () => {

  return async dispatch => {
    return axios.get(`${host}/partner-setting`, config())
      .then(
        response => dispatch(setPartnerSetting(response.data)),
        err => console.log(err)
      );
  }
}

export const setPartnerSetting = (payload) => ({
  type: ActionTypes.SET_PARTNER_SETTING,
  payload: payload,
});


