import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import {BrowserRouter} from 'react-router-dom';
import Auth0ProviderWithHistory from './auth/auth0-provider-with-history';
import {Provider} from 'react-redux';
import store from './redux/store';
import './i18n';
import { ThemeProvider } from '@material-ui/core/styles';
import theme from './theme';


ReactDOM.render(
  <BrowserRouter>
    <Auth0ProviderWithHistory>
     <Provider store={store}>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>,
      </Provider>
    </Auth0ProviderWithHistory>
  </BrowserRouter>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
