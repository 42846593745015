import React, {Component} from "react";
import ReactDOMServer from "react-dom/server";
import * as L from "leaflet";
import "leaflet-draw";
import RegionPopup from "./regionPopup";
import RegionCreateModal from "./regionCreate";
import {search} from "../../../redux/city/action";
import {withAuth0} from "@auth0/auth0-react";
import {connect} from "react-redux";
import $ from "jquery";
import RegionDelete from "./delete/regionDelete";

const host = process.env.REACT_APP_HOST;

class RegionConfigurationMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openPopUp: false,
      editRegion: false,
      openDeleteRegion: false,
      regionDetails: {},
    };
    this.coordinates = [];
    this.selectedLayer = null;
    this.createdLayer = null;
  }

  componentDidMount() {
    const {regions} = this.props;
    if (!regions) {
      return true;
    }
    this.refresh();
    // create map
    this.map = L.map(
      "map",
      {
        center: [51.505, -0.09],
        zoom: 12,
        layers: [
          L.tileLayer(
            "https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}",
            {
              attribution:
                'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
              maxZoom: 24,
              id: "mapbox/satellite-v9",
              tileSize: 512,
              zoomOffset: -1,
              accessToken:
                "pk.eyJ1IjoidHJhY2RldmVsb3BlciIsImEiOiJja2szOGJteHMxOGxhMm9wYzFhbmgwazkyIn0._UOy-teYfb2kzsEMdr8YkA",
            }
          ),
        ],
      },
      {drawControl: true}
    );

    const satellite = L.tileLayer('http://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}', {
        maxZoom: 24,
        subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
        attribution:
          'Map data &copy; <a href="https://www.google.com/map">GoogleMap</a> Imagery © <a href="https://www.google.com/">Google</a>',
      }
    );
    const NormalMap = L.tileLayer(
      "https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/{z}/{x}/{y}?access_token={accessToken}",
      {
        attribution:
          'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
        maxZoom: 24,
        id: "mapbox/Normal-v9",
        tileSize: 512,
        zoomOffset: -1,
        accessToken:
          "pk.eyJ1IjoidHJhY2RldmVsb3BlciIsImEiOiJja2szOGJteHMxOGxhMm9wYzFhbmgwazkyIn0._UOy-teYfb2kzsEMdr8YkA",
      }
    );
    const darkMap = L.tileLayer(
      "https://api.mapbox.com/styles/v1/mapbox/dark-v10/tiles/{z}/{x}/{y}?access_token={accessToken}",
      {
        attribution:
          'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
        maxZoom: 24,
        id: "mapbox/Dark-v9",
        tileSize: 512,
        zoomOffset: -1,
        accessToken:
          "pk.eyJ1IjoidHJhY2RldmVsb3BlciIsImEiOiJja2szOGJteHMxOGxhMm9wYzFhbmgwazkyIn0._UOy-teYfb2kzsEMdr8YkA",
      }
    );
    const baseMaps = {
      Google: satellite,
      Normal: NormalMap,
      DarkMap: darkMap,
    };

    L.control.layers(baseMaps).addTo(this.map);

    const editableLayers = new L.FeatureGroup();
    this.map.addLayer(editableLayers);


    const drawPluginOptions = {
      position: "topright",
      draw: {
        polyline: false,
        circlemarker: false,
        circle: false,
        polygon: {
          allowIntersection: false,
          drawError: {
            color: "#e1e100",
            message:
              "<strong>Polygon draw does not allow intersections!<strong> (allowIntersection: false)",
          },
          shapeOptions: {
            color: "#97009c",
          },
        },
        rectangle: true,
        marker: false,
      },
      edit: {
        featureGroup: editableLayers,
        remove: false,
        edit: false,
      },
    };

    // Initialise the draw control and pass it the FeatureGroup of editable layers
    const drawControl = new L.Control.Draw(drawPluginOptions);
    this.map.addControl(drawControl);

    this.map.on("draw:created", (e) => {
      var type = e.layerType,
        layer = e.layer;
      this.createdLayer = e.layer;

      if (type === "polygon") {
        let geoCoordintaes = layer.toGeoJSON()["geometry"]["coordinates"][0];
        this.coordinates = this.flipCoOrdinates(geoCoordintaes);
        let layerPopUp = layer.bindPopup();
        this.setState({
          openPopUp: true,
        });
      }
      if (type === "rectangle") {
        let geoCoordintaes = layer.toGeoJSON()["geometry"]["coordinates"][0];
        this.coordinates = this.flipCoOrdinates(geoCoordintaes);
        let layerPopUp = layer.bindPopup();
        this.setState({
          openPopUp: true,
        });
      }

      editableLayers.addLayer(layer);
    });
    this.loadRegions();
  }
  refresh = () => {
    const {search} = this.props;
    search();
  };

  onSuccessRegion() {
    if (this.createdLayer) {
      this.map.removeLayer(this.createdLayer);
    }
    if (this.selectedLayer) {
      this.map.removeLayer(this.selectedLayer);
    }

    const {callback} = this.props;
    callback();
  }

  handleModalCloseChange = () => {
    const {callback} = this.props;
    callback();
    this.setState({
      openPopUp: false,
      editRegion: false,
      openDeleteRegion: false,
    });
  };

  loadRegions = () => {
    const {regions: regionData} = this.props;
    if (!regionData) {
      return true;
    }
    const regionArray = this.sortRegionsOrder();
    this.selectedLayer = L.geoJSON(regionArray, {
      style: (feature) => {
        switch (feature.properties.category) {
          case "OPERATION_REGION":
            return {color: "#BEFFC1"};
          case "NO_PARKING":
            return {color: "#FFFEBE"};
          case "FORBIDDEN":
            return {color: "#FFBEBE"};
          case "LOW_SPEED":
            return {color: "#BEECFF"};
        }
      },
      onEachFeature: (feature, layer) => {
        if (feature.properties) {
          layer.myTag = "regionGeoJSON";
          var layerPopup = layer
            .bindPopup(
              ReactDOMServer.renderToString(
                <RegionPopup region={feature.properties}/>
              )
            )
            .on("click", (e) => {
              $(".leaflet-popup-content-wrapper .deleteRegion").on(
                "click",
                (e) => {
                  layerPopup.closePopup();
                  this.setState({
                    openDeleteRegion: true,
                    regionDetails: feature.properties,
                  });
                }
              );
              $(".leaflet-popup-content-wrapper .editRegion").on(
                "click",
                (e) => {
                  layerPopup.closePopup();
                  this.setState({
                    editRegion: true,
                    regionDetails: feature.properties,
                    openPopUp: true,
                  });
                  // console.log(feature.properties);
                }
              );
            });

        }
      },
    }).addTo(this.map);
  };

  // remove geojson Layers
  removeGeoJsonLayers = () => {
    this.map.eachLayer((layer) => {
      if (layer.myTag && layer.myTag === "regionGeoJSON") {
        this.map.removeLayer(layer);
      }
    });
  };

  sortRegionsOrder = () => {
    const {regions} = this.props;
    const regionData = regions?.content || [];
    if (
      regionData === null ||
      regionData === undefined ||
      regionData.length === 0
    ) {
      return [];
    }
    this.removeGeoJsonLayers();
    const allRegions = {
      OPERATING: [],
      LOW_SPEED: [],
      NO_PARKING: [],
      FORBIDDEN: [],
    };
    regionData.forEach((region) => {
      let coordinatesData = this.flipCoOrdinates(region.boundary) || [];
      let geoJSON = {
        type: "Feature",
        properties: {
          category: region.category,
          regionName: region.regionName,
          status: region.status,
          discount: region.discount,
          uuid: region.uuid,
          speedLimit: region.speedLimit,
          cityUuid: region.cityUuid
        },
        geometry: {
          type: "Polygon",
          coordinates: [coordinatesData],
        },
      };
      switch (region.category) {
        case "OPERATING":
          allRegions["OPERATING"].push(geoJSON);
          break;
        case "LOW_SPEED":
          allRegions["LOW_SPEED"].push(geoJSON);
          break;
        case "NO_PARKING":
          allRegions["NO_PARKING"].push(geoJSON);
          break;
        default:
          allRegions["FORBIDDEN"].push(geoJSON);
      }
    });

    // now sort the regions, smaller last (high priority)
    allRegions["OPERATING"].sort(this.regionAreaCompare);
    allRegions["LOW_SPEED"].sort(this.regionAreaCompare);
    allRegions["NO_PARKING"].sort(this.regionAreaCompare);
    allRegions["FORBIDDEN"].sort(this.regionAreaCompare);

    // order of the regions is important, lowest priority first
    if (allRegions) {
      return Object.values(allRegions).flat();
    } else {
      return [];
    }
  };

  // coordinates is an array, the first element is coordinatesData
  // coordinatesData is an array of coordinates
  // e.g.
  // coordinates = [
  //   [
  //     [-1.040955, 51.910393],
  //     [-1.040955, 51.964577],
  //     [-0.856934, 51.964577],
  //     [-0.856934, 51.910393],
  //     [-1.040955, 51.910393]
  //   ]
  // ]
  // this function will calculate the area of the boundary box (rectangle), not the area of the polygon
  area = (coordinates) => {
    return (Math.max(...(coordinates[0].map( x => x[0] ))) -
          Math.min(...(coordinates[0].map( x => x[0] )))) *
          (Math.max(...(coordinates[0].map( x => x[1] ))) -
          Math.min(...(coordinates[0].map( x => x[1] ))))
  };

  // compare function for sorting regions, smaller gets higher priority
  regionAreaCompare = (a, b) => {
    const areaA = this.area(a.geometry.coordinates);
    const areaB = this.area(b.geometry.coordinates);
    if (areaA < areaB)
      return 1;
    else if (areaA > areaB)
      return -1;
    else
      return 0;
  };


  // Alter Coordinates
  flipCoOrdinates = (coords) => {
    let latLngCoordinates = [];
    if (coords.length) {
      coords.map((fubar, i) =>
        latLngCoordinates.push([coords[i][1], coords[i][0]])
      );
    }
    return latLngCoordinates;
  };

  componentDidUpdate(prevProps) {
    if (this.props?.regions !== prevProps.regions) {
      this.loadRegions();
    }
  }

  render() {
    const {cities, regions} = this.props;
    if (!regions) return <React.Fragment/>;
    return (
      <>
        <RegionDelete
          openModal={this.state.openDeleteRegion}
          onCloseModal={() => this.handleModalCloseChange()}
          region={this.state.regionDetails}
          callback={() => this.onSuccessRegion()}
        />
        <RegionCreateModal
          open={this.state.openPopUp}
          coordinates={this.coordinates}
          cities={cities}
          edit={this.state.editRegion}
          regionDetails={this.state.regionDetails}
          onCloseModal={() => this.handleModalCloseChange()}
          callback={() => this.onSuccessRegion()}
        />
        <div id="map" style={{height: "100vh"}}/>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  cities: state.cityReducer.cities,
});

const mapDispatchToProps = (dispatch) => ({
  search: (params) => dispatch(search(params)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withAuth0(RegionConfigurationMap));
