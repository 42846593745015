import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {withAuth0} from "@auth0/auth0-react";
import {getSocialMedia} from "../../../redux/partners/action";
import {Form, Formik} from "formik";
import * as Yup from "yup";
import Success from "../../../components/PopUp/Success";
import {InputTextField} from "../../../components/Form/FormElements";
import Button from "@material-ui/core/Button";
import axios from "axios";
import {config} from "../../../auth/auth";
import store from "../../../redux/store";

const validationSchema = Yup.object({
  facebookUrl: Yup.string().matches('\\b(facebook)\\b', "URL must contain 'facebook'").url("Must be a valid URL"),
  instagramUrl: Yup.string().matches('\\b(instagram)\\b', "URL must contain 'instagram'").url("Must be a valid URL"),
  twitterUrl: Yup.string().matches('\\b(twitter)\\b', "URL must contain 'twitter'").url("Must be a valid URL"),
})

const host = process.env.REACT_APP_HOST

const SocialMedia = ({partnerSocialMedia, getSocialMedia}) => {
  const [openModal, setOpenModal] = useState(false)

  useEffect(() => {
    getSocialMedia();
  }, [getSocialMedia]);

  if (!partnerSocialMedia) {
    return <React.Fragment>Loading...</React.Fragment>
  }

  const handleSubmit = (values) => {
    const partner_uuid = store.getState().appData.currentAdminUser?.partnerUuid;

    return axios
      .post(`${host}/partner/${partner_uuid}/socialMedia`, values, config())
      .catch((e) => alert(e))
      .finally(() => {
        setOpenModal(true);
      });
  }


  return (
    <React.Fragment>
      <div className="card shadow mb-4 " key="terms-and-conditions-key">
        <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
          <h4 className="m-0 font-weight-bold" style={{color: '#333399'}}>
            Social Media Links Configuration
          </h4>
        </div>
        <div className="card-body">
          <p className="mb-2">
            This page allows you to configure your social media links.
          </p>
          <Formik
            initialValues={partnerSocialMedia}
            validationSchema={validationSchema}
            onSubmit={(values) => handleSubmit(values)}>
            {(formik) => (
              <Form>
                <InputTextField name="facebookUrl" label="Facebook"/>
                <InputTextField name="twitterUrl" label="Twitter"/>
                <InputTextField name="instagramUrl" label="Instagram"/>
                <Button
                  variant="contained"
                  style={{color: "white", backgroundColor: "#333399"}}
                  type="submit"
                >Submit</Button>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      <Success
        open={openModal}
        header="Success"
        content="Updated Social Media Links Successfully."
        onClose={() => setOpenModal(false)}
      />
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  partnerSocialMedia: state.partnerReducer.partnerSocialMedia
});

const mapDispatchToProps = (dispatch) => ({
  getSocialMedia: () => dispatch(getSocialMedia())
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withAuth0(SocialMedia));