import React from 'react';
import {DialogTitle, Grid} from "@material-ui/core";
import {NumberField} from "../../../../components/Form/FormElements";

const UpdateMembershipLimit = () => {

  return (
      <React.Fragment>
        <DialogTitle
          id="User Action Modal"
          style={{
            color: "white",
            backgroundColor: "#333399",
            textAlign: "center",
          }}
        >
          <label>Update Membership Limit</label>
        </DialogTitle>
        <Grid container spacing={2} className="formLabel px-3">
          <Grid item sm={12} xs={12} md={12} lg={12}>
            <NumberField
              readOnly={false}
              name="limitationDay"
              label="Limitation for Day"
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} className="formLabel px-3">
          <Grid item sm={12} xs={12} md={12} lg={12}>
            <NumberField
              readOnly={false}
              name="limitationPeriod"
              label="Limitation for Period"
            />
          </Grid>
        </Grid>
      </React.Fragment>
      );
};

export default UpdateMembershipLimit;