import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AreaChart from "../../Chart/AreaChart";
import Button from "@material-ui/core/Button";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { IconButton, Tooltip } from "@material-ui/core";
import {getOrder} from "../../../redux/dashboard/dashboard.action";
import {connect} from "react-redux";
import {withAuth0} from "@auth0/auth0-react";
import {Skeleton} from "@material-ui/lab";
import {
  transformDailyDates,
  transformMonthlyDates,
  downloadXlsx
} from "../../../utils/utils";
import { withTranslation } from 'react-i18next';

class OrderCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedValue: "monthly",
    };
  }

  componentDidMount() {
    const { order, getOrder } = this.props;
    if (!order) getOrder();
  }

  render() {
    const { order,t } = this.props;
    if (!order) {
      return (
        <div className="col-xl col-lg">
          <Skeleton variant="rect" width={"100%"} height={500} />
        </div>
      );
    }
    let showDate = order[`${this.state.selectedValue}`]?.date;
    const dates = order[`${this.state.selectedValue}`]?.date;
    if (this.state.selectedValue === "daily") {
      showDate = transformDailyDates(dates);
    } else if (this.state.selectedValue === "monthly") {
      showDate = transformMonthlyDates(dates);
    } else {
      showDate = order[`${this.state.selectedValue}`]?.date;
    }
    const showData = order[`${this.state.selectedValue}`]?.data;

    return (
      <div className="col-xl col-lg">
        <div className="card shadow mb-4">
          <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
            <h6 className="m-0 font-weight-bold" style={{color: '#333399'}}>{t('order-overview.title')}</h6>
            <div style={{ padding: "4px" }}>
              <Button
                size="medium"
                style={{ marginRight: "5px" }}
                className={
                  this.state.selectedValue === "daily" ? "btnUnderLine" : ""
                }
                onClick={() => {
                  this.setState({
                    selectedValue: "daily",
                  });
                }}
              >
                {t('common.tab-day')}
              </Button>
              <Button
                size="medium"
                style={{ marginRight: "5px" }}
                className={
                  this.state.selectedValue === "monthly" ? "btnUnderLine" : ""
                }
                onClick={() => {
                  this.setState({
                    selectedValue: "monthly",
                  });
                }}
              >
                {t('common.tab-month')}
              </Button>
              <Button
                size="medium"
                className={
                  this.state.selectedValue === "annually" ? "btnUnderLine" : ""
                }
                onClick={() => {
                  this.setState({
                    selectedValue: "annually",
                  });
                }}
              >
                {t('common.tab-year')}
              </Button>
              <Tooltip title="Download">
                <IconButton
                  size="small"
                  aria-label="Download"
                  style={{ padding: 10 }}
                  onClick={() => {
                    downloadXlsx(order, "Orders");
                  }}
                >
                  <FontAwesomeIcon icon={faDownload}></FontAwesomeIcon>
                </IconButton>
              </Tooltip>
            </div>
          </div>

          <div className="card-body">
            <AreaChart
              date={showDate}
              data={showData}
              label="Orders"
              areaStyle={{ color: "#99e6e6" }}
              lineStyle={{ color: "#33cccc" }}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  order: state.dashboardReducer.order,
});

const mapDispatchToProps = (dispatch) => ({
  getOrder: () => dispatch(getOrder()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withAuth0(withTranslation()(OrderCard)));
