import React, {Component} from 'react'
import ReactEcharts from 'echarts-for-react'


export default class PieChart extends Component {

    getOption = ()=>{
        
        const {seriesName, label, labelOptions, showData} = this.props

        let option = {
            tooltip: {
                trigger: 'item',
                textStyle: {
                    fontFamily: 'Urbanist'
                },
                formatter: '{a} <br/>{b}: {c} ({d}%)'
            },            
            legend: {
                orient: 'horizontal',
                bottom: 0,
                padding: [5, 0, 0, 0],
                textStyle: {
                    fontFamily: 'Urbanist'
                },
                data: label,
            },
            series: [
                {
                    name: seriesName,
                    type: 'pie',
                    radius: ['50%', '70%'],
                    avoidLabelOverlap: false,
                    label: labelOptions,
                    emphasis: {
                        label: {
                            show: true,
                            fontSize: '10',
                            fontWeight: 'bold',
                            fontFamily: 'Urbanist'
                        }
                    },
                    labelLine: {
                        show: false
                    },
                    data: showData
                }
            ]
        };
        return option;
    }
    render() {
        return (
            <ReactEcharts style={{width:'100%',height:'50vh'}} option={this.getOption()}/>
        )
    }
}

//https://echarts.apache.org/examples/zh/index.html