import React, {Component} from "react";
import {connect} from "react-redux";
import {getUserDetails, setBusiness, setUserDetails, updateUser} from "../../../../redux/user/user.action";
import {withAuth0} from "@auth0/auth0-react";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import {Button, CircularProgress, Grid, Snackbar, Tooltip} from "@material-ui/core";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import TextField from "@material-ui/core/TextField";
import "./details.css";
import moment from "moment";
import NotificationsIcon from "@material-ui/icons/Notifications";
import PaymentIcon from "@material-ui/icons/Payment";
import LockIcon from "@material-ui/icons/Lock";
import MoneyOffIcon from "@material-ui/icons/MoneyOff";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPassport} from "@fortawesome/free-solid-svg-icons";
import OrderList from "../../OrderInformation/OrderList/OrderList";
import OrderMapComponent from "../../OrderInformation/OrderMap/index";
import TransactionList from "../../TransactionInformation/TransactionList/TransactionList";
import UserActionModal from "../UserModals/UserActions-modal";
import Form from "react-bootstrap/Form";
import {Alert, Autocomplete} from "@material-ui/lab";
import {search} from "../../../../redux/business/business.action";
import {getCurrency} from "../../../../redux/partners/action";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import Modal from "react-bootstrap/Modal";
import DrivingLicense from "../DrivingLicenseVerfication";
import axios from "axios";
import {config} from "../../../../auth/auth";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import UserMigratePopUp from "../../../../components/PopUp/UserMigratePopUp/UserMigratePopUp";
import {close, success} from "../../../../redux/alert/alert.action";
import ConsoleEventLogList from "../../../SystemLog/ConsoleEventLogList";

const datetime_format = process.env.REACT_APP_DATETIME_FORMAT;
const host = process.env.REACT_APP_HOST

class UserDetails extends Component {
  constructor(props) {
    super(props);
    this.isDirty = false;
    this.drivingLicensePath = "";
    this.state = {
      loading: false,
      showDrivingLicense: false,
      openPopUp: false,
      clickedAction: "",
    };
  }
  componentDidMount() {
    this.getDetails();
    this.props.search();
  }
  refresh = () => {
    this.uuid = this.props.location.pathname.replace("/user/", "");
    this.props.getUserDetails(this.uuid);
  };

  getDetails() {
    const { history } = this.props;
    this.uuid = this.props.location.pathname.replace("/user/", "");
    this.props.getUserDetails(history.location.pathname);
    this.isDirty = false;
    this.props.getCurrency();
  }

  onChangeBusiness = (event, value) => {
    if (this.props?.userDetails?.uuid === value?.uuid){
      console.warn("Ignore, because select same business.");
      return;
    }

    var businessUuid = value?.uuid;
    if (!value || !value?.uuid){
      // reset
      businessUuid = null;
    }

    this.setState({loading: true});

    setBusiness(this.props.userDetails.uuid, businessUuid)
      .then(() => {
        this.getDetails();
      }).catch((e) => {
        console.error(e);
      }).finally(() => {
        setTimeout(() => {
          this.setState({loading: false});
        }, 500)
      })
  }

  handleModalCloseChange = () => {
    this.setState({
      openPopUp: false,
    });
  };

  onChangeDetails = (fieldName, value) => {
    let updated = Object.assign({}, this.props.userDetails, {[fieldName]: value});
    this.props.setUserDetails(updated);
    this.isDirty = true;
  }

  onClickSaveBtn = () => {
    this.setState({loading: true});
    this.props.updateUser(this.props.userDetails)
      .then(() => {
        this.getDetails();
      }).catch((e) => {
      console.error(e);
    })
      .finally(() => {
        setTimeout(() => {this.setState({loading: false})}, 500);
      });
  }

  onSelectDrivingLicense = (uuid) => {
    const { history } = this.props;
    const routePath = "/user/" + uuid + "/drivingLicence";
    if (history.location.search === "?drivingLicenceStatus=VERIFYING") {
      history.push(routePath);
    } else {
      this.drivingLicensePath = routePath;
      this.setState({ showDrivingLicense: true });
    }
  };
  onCloseDrivingLicenseModal = () => {
    this.getDetails();
    this.setState({ showDrivingLicense: false });
  };
  
  changeUserStatus = (status, userId) => {
    return axios
      .put(`${host}/user/${userId}`, { userStatus: status }, config())
      .then((response) => {
        this.getDetails();
        this.props.success("Success");
      })
      .catch((e) => alert(e));
  }

  render() {
    const { userDetails, businesses, partnerCurrency } = this.props;
    if (!userDetails?.uuid) {
      return <React.Fragment>Loading...</React.Fragment>;
    }
    let name = `${userDetails?.firstName} ${userDetails?.lastName}`;

    const businessValue = businesses ? businesses?.find(b => b.uuid === userDetails.businessUuid) : {businessName: ""}
    const { showDrivingLicense } = this.state;
    return (
      <React.Fragment>
        <Grid container spacing={2}>
          <Grid item sm={12} xs={12} md={12} lg={8}>
            <Card>
              <CardHeader
                avatar={
                  <Avatar
                    aria-label="User"
                    style={{ width: 80, height: 80 }}
                    alt="User Photo"
                    src={userDetails?.avatar}
                  >
                    {userDetails?.firstName?.charAt(0) ?? "V"}
                  </Avatar>
                }
                titleTypographyProps={{ variant: "h5" }}
                title={name}
                subheader={`UUID: ${userDetails.uuid}`}
              />

              <CardActions disableSpacing>
                <Tooltip title="Driving License">
                  <IconButton
                    aria-label="Driving License"
                    disabled={
                      userDetails.drivingLicenceStatus === "NO_UPLOAD"
                    }
                    onClick={() =>
                      this.onSelectDrivingLicense(
                        userDetails.uuid
                      )
                    }
                  >
                    <FontAwesomeIcon icon={faPassport} />
                </IconButton>
                </Tooltip>
                <Tooltip title="TopUP">
                  <IconButton
                    onClick={() => {
                      this.setState({
                        openPopUp: true,
                        clickedAction: "TopUp",
                      });
                    }}
                  >
                    <PaymentIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Penality">
                  <IconButton
                    onClick={() => {
                      this.setState({
                        openPopUp: true,
                        clickedAction: "penality",
                      });
                    }}
                  >
                    <MoneyOffIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Notification">
                  <IconButton
                    onClick={() => {
                      this.setState({
                        openPopUp: true,
                        clickedAction: "Notification",
                      });
                    }}
                  >
                    <NotificationsIcon />
                  </IconButton>
                </Tooltip>
                {userDetails.userStatus ==="ACTIVE" ? (
                  <Tooltip title="Block">
                    <IconButton
                      aria-label="Block"
                      onClick={() => this.changeUserStatus("INACTIVE", userDetails.uuid)}
                    >
                      <LockIcon />
                    </IconButton>
                  </Tooltip>
                ) : (
                  <Tooltip title="Activate">
                    <IconButton
                      aria-label="Block"
                      onClick={() => this.changeUserStatus("ACTIVE", userDetails.uuid)}
                    >
                      <LockOpenIcon />
                    </IconButton>
                  </Tooltip>
                )}
                <UserMigratePopUp
                  refresh={this.refresh}
                  uuid= {userDetails?.uuid}
                  isMigrated= {userDetails?.isMigrated}
                />
              </CardActions>
            </Card>
          </Grid>
          <Grid item sm={12} xs={12} md={12} lg={4}>
            <Card style={{ height: "100%" }}>
              <CardContent>
                <Row>
                  <Col xs={12} md={5} className="userDetailsFormLabel">
                    <div className="text-center">
                      {" "}
                      <h4>Total Order</h4>
                    </div>
                    <div className="text-center">
                      <h3 className="primary">
                        {userDetails?.countOfOrder}
                      </h3>
                    </div>
                  </Col>
                  <Col xs={12} md={7} className="userDetailsFormLabel">
                    <div className="text-center">
                      {" "}
                      <h4>Total Order Value</h4>
                    </div>
                    <div className="text-center">
                      <h3 className="primary">
                        {" "}
                        {userDetails.city === 'Ayla,JO' ? 'JOD' : partnerCurrency} {userDetails?.costOfOrders}
                      </h3>
                    </div>
                  </Col>
                </Row>
              </CardContent>
              <CardActions disableSpacing></CardActions>
            </Card>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item sm={12} xs={12} md={12} lg={8}>
            <Card>
              <CardHeader title="Details" />
              <CardContent>
                <Row>
                  <Col xs={12} md={6} className="userDetailsFormRowCol">
                    <TextField
                      id="firstName"
                      label="First Name"
                      className=""
                      value={userDetails?.firstName}
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                    />
                  </Col>
                  <Col xs={12} md={6} className="userDetailsFormRowCol">
                    <TextField
                      id="lastName"
                      label="Last Name"
                      fullWidth
                      value={userDetails?.lastName}
                      InputProps={{
                        readOnly: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                    />
                  </Col>
                  <Col xs={12} md={6} className="userDetailsFormRowCol">
                    <TextField
                      id="email"
                      label="Email"
                      fullWidth
                      value={userDetails?.email}
                      InputProps={{
                        readOnly: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                    />
                  </Col>
                  <Col xs={12} md={6} className="userDetailsFormRowCol">
                    <TextField
                      id="phone"
                      label="Phone"
                      fullWidth
                      value={userDetails?.phone}
                      InputProps={{
                        readOnly: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                    />
                  </Col>
                  <Col xs={12} md={6} className="userDetailsFormRowCol">
                    <TextField
                      id="birthDate"
                      label="Date of Birth"
                      fullWidth
                      type="date"
                      value={userDetails?.birthDate}
                      InputProps={{
                        readOnly: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                    />
                  </Col>
                  <Col xs={12} md={6} className="userDetailsFormRowCol">
                    <TextField
                      id="balance"
                      label="Balance"
                      fullWidth
                      type="number"
                      value={userDetails?.balance}
                      InputProps={{
                        readOnly: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                    />
                  </Col>
                  <Col xs={12} md={6} className="userDetailsFormRowCol">
                    <TextField
                      id="drivingLicenceStatus"
                      label="Driving License Status"
                      fullWidth
                      value={userDetails?.drivingLicenceStatus}
                      InputProps={{
                        readOnly: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                    />
                  </Col>
                  <Col xs={12} md={6} className="userDetailsFormRowCol">
                    <TextField
                      id="regMethod"
                      label="Registration Method"
                      fullWidth
                      value={userDetails?.regMethod}
                      InputProps={{
                        readOnly: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                    />
                  </Col>
                  <Col xs={12} md={6} className="userDetailsFormRowCol">
                    <TextField
                      id="regTime"
                      label="Registration Date"
                      fullWidth
                      value={moment.utc(userDetails?.regTime).local().format(datetime_format)}
                      InputProps={{
                        readOnly: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                    />
                  </Col>
                  <Col xs={12} md={6} className="userDetailsFormRowCol">
                    <TextField
                      id="userStatus"
                      label="User Status"
                      fullWidth
                      value={userDetails?.userStatus}
                      InputProps={{
                        readOnly: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                    />
                  </Col>
                  <Col xs={12} md={6} className="userDetailsFormRowCol">
                    <TextField
                      id="countOfOrder"
                      label="Order Count"
                      type="number"
                      fullWidth
                      value={userDetails?.countOfOrder}
                      InputProps={{
                        readOnly: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                    />
                  </Col>
                  <Col xs={12} md={6} className="userDetailsFormRowCol">
                    <Autocomplete
                      id="user-details-business-select-id"
                      style={{ width: "100%" }}
                      options={businesses ?? []}
                      autoHighlight
                      value={businessValue}
                      disabled={this.state.loading ?? false}
                      getOptionSelected={(option, value) => option.uuid === value}
                      getOptionLabel={(option) => option?.businessName}
                      renderOption={(option) => (
                        <React.Fragment>
                          <ListItem>
                            <ListItemAvatar>
                              <Avatar alt={option?.businessName} src={option?.logo} />
                            </ListItemAvatar>
                            <ListItemText primary={option?.businessName} secondary={option?.businessContact} />
                          </ListItem>
                        </React.Fragment>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Business"
                          variant="outlined"
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <React.Fragment>
                                {this.state.loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            ),
                          }}
                        />
                      )}
                      onChange={this.onChangeBusiness}
                    />
                  </Col>
                  {/* <Col xs={12} md={6} className="userDetailsFormRowCol">
                    <DiscountSlider
                      value={userDetails?.discount && parseInt(userDetails?.discount * 100)}
                      onChange={(e, newValue) => {
                        this.onChangeDetails("discount", newValue/100)
                      }}/>
                    </Col> */}
                  <Col xs={12} md={6} className="userDetailsFormRowCol">
                    <Form>
                      <Form.Check
                        name="isPaymentBind"
                        label="Payment Binded"
                        id="paymentBind"
                        className="paymentBind"
                        feedbackTooltip
                        checked={userDetails?.isPaymentBind}
                        onChange={() => {}}
                      />
                    </Form>
                  </Col>
                </Row>
                <Row style={{ justifyContent: "flex-end", marginRight: "auto" }}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => this.onClickSaveBtn()}
                    disabled={this.state?.loading || !this.isDirty}
                  >
                    {this.state.loading ? <CircularProgress style={{width:22, height:22}} className="white"/> : 'Save'}
                  </Button>
                </Row>
              </CardContent>
            </Card>
          </Grid>
          <Grid item md={12} lg={4}>
            <OrderMapComponent
              key={`order-list-${this.props.location.pathname.replace("/user/", "")}`}
              history={this.props.history}
              fromComponent ={true}
              filters={[{ key: "userUuid", value: `${this.props.location.pathname.replace("/user/", "")}` }]}
            ></OrderMapComponent>
          </Grid>
          <Grid item lg={12}>
            <OrderList
              key={`order-list-${this.props.location.pathname.replace("/user/", "")}`}
              history={this.props.history}
              filters={[{ key: "userUuid", value: `${this.props.location.pathname.replace("/user/", "")}` }]}
            />
          </Grid>
          <Grid item lg={12}>
            <TransactionList
              key={`transaction-list-${this.props.location.pathname.replace("/user/", "")}`}
              filters={[{ key: "userUuid", value: `${this.props.location.pathname.replace("/user/", "")}` }]}
            ></TransactionList>
          </Grid>
          <Grid item lg={12}>
            <ConsoleEventLogList
              key={`transaction-list-${this.props.location.pathname.replace("/user/", "")}`}
              filters={[{ key: "userUuid", value: `${this.props.location.pathname.replace("/user/", "")}` }]}
            ></ConsoleEventLogList>
          </Grid>
        </Grid>
        <UserActionModal
          open={this.state.openPopUp}
          action={this.state.clickedAction}
          userId={userDetails?.uuid}
          onCloseModal={() => this.handleModalCloseChange()}
          callback={() => this.getDetails()}
        ></UserActionModal>

        <Modal
          show={showDrivingLicense}
          dialogClassName="drivingLicenceModal"
          onHide={() => this.onCloseDrivingLicenseModal()}
          animation={false}
          centered
          style={{ maxWidth: "90% !important" }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Driving Licence verification</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ padding: "0px" }}>
            <DrivingLicense LicensePath={this.drivingLicensePath} />
          </Modal.Body>
        </Modal>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  userDetails: state.usersData.userDetails,
  businesses: state.businessReducer.businesses?.content,
  partnerCurrency: state.partnerReducer.partnerCurrency
});
const mapDispatchToProps = (dispatch) => ({
  getUserDetails: (path) => dispatch(getUserDetails(path)),
  setUserDetails: (payload) => dispatch(setUserDetails(payload)),
  updateUser: (payload) => dispatch(updateUser(payload)),
  search: (path) => dispatch(search(path)),
  getCurrency: () => dispatch(getCurrency()),
  success: (msg) => dispatch(success(msg)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withAuth0(UserDetails));
