import React, {Component} from "react";
import MUIDataTable from "mui-datatables";
import {withAuth0} from "@auth0/auth0-react";
import {
  Tooltip,
  Button, FormLabel, FormGroup, TextField,
} from "@material-ui/core";
import {connect} from "react-redux";
import moment from "moment";
import {queryParams, sub} from "../../../utils/utils";
import PropTypes from "prop-types";
import {withRouter} from 'react-router-dom';
import {getDailyWorkingRecord} from "../../../redux/groundTeamUser/groundTeamUser.action";
import {MuiPickersUtilsProvider, DateTimePicker} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";


const DATETIME_FORMAT = process.env.REACT_APP_DATETIME_FORMAT;
const LOCAL_DATE_TIME_FORMAT = process.env.REACT_APP_LOCALDATETIME_FORMAT;

class GroundTeamWorkRecordList extends Component {
  constructor(props) {
    super(props);
    this.isFilterDialogOpen = false;
    this.tableRef = React.createRef();
    this.columnsDisplay = undefined;
  }

  static propTypes = {
    filters: PropTypes.object,
  };

  componentDidMount() {
    this.refresh();
  }

  refresh = () => {
    const {getDailyWorkingRecord, uuid, filters} = this.props;

    const state = this.tableRef?.current?.state;

    const params = queryParams(this.props, state);

    filters?.map((f) => params.set(f.key, f.value));

    if (state && state["columns"]) {
      const workPeriodIndex = state["columns"].findIndex(s => s.name === 'workPeriod');
      const startAtIndex = state["columns"].findIndex(s => s.name === 'startAt');
      const endAtIndex = state["columns"].findIndex(s => s.name === 'endAt');

      const workPeriod = state["filterList"][workPeriodIndex];
      const startAt = state["filterList"][startAtIndex];
      const endAt = state["filterList"][endAtIndex];

      if (startAt?.length !== 0) {
        const from = startAt[0] ?? moment().subtract(100, 'year');
        const until = startAt[1] ?? moment();
        params.set('startAtFrom', from.format(LOCAL_DATE_TIME_FORMAT));
        params.set('startAtUntil', until.format(LOCAL_DATE_TIME_FORMAT));
      }

      if (endAt?.length !== 0) {
        const from = endAt[0] ?? moment().subtract(100, 'year');
        const until = endAt[1] ?? moment();
        params.set('endAtFrom', from.format(LOCAL_DATE_TIME_FORMAT));
        params.set('endAtUntil', until.format(LOCAL_DATE_TIME_FORMAT));
      }

      if (workPeriod?.length !== 0) {
        const min = workPeriod[0] ?? "-9999999";
        const max = workPeriod[1] ?? "9999999";
        params.set('minWorkPeriod', min);
        params.set('maxWorkPeriod', max);
      }
    }

    if (uuid !== null && uuid !== undefined) {
      params.set("groundTeamUserUuid", uuid);
    }

    getDailyWorkingRecord(params);
  };

  onChangeColumnDisplay = () => {
    const state = this.tableRef?.current?.state;
    this.columnsDisplay = state.columns.reduce((obj, item) => Object.assign(obj, {[item.name]: item.display}), {});
    }

  isDisplay = (name) => {
    if (!this.columnsDisplay || !this.columnsDisplay.hasOwnProperty(name))
      return undefined;
    else
      return this.columnsDisplay[name];
  }

  render() {
    const { groundTeamWorkRecords } = this.props;
    if (!groundTeamWorkRecords)
      return (<React.Fragment>Loading...</React.Fragment>);

    const list = groundTeamWorkRecords?.content;
    const totalElements = groundTeamWorkRecords?.totalElements ?? 0;
    const rowsPerPage = groundTeamWorkRecords?.pageable?.pageSize ?? 10;
    const page = groundTeamWorkRecords?.pageable?.pageNumber ?? 0;

    const columns = [

      {
        name: "startAt",
        label: "Work Start Time",
        options: {
          filter: true,
          sort: true,
          filterType: 'custom',
          display: this.isDisplay("startAt") ?? true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <Tooltip title={value ? moment.utc(value).local().format(DATETIME_FORMAT) : ""}>
                <div>
                  {value ? moment.utc(value).local().format(DATETIME_FORMAT) : ""}
                </div>
              </Tooltip>
            );
          },
          customFilterListOptions: {
            render: v => {
              if (v[0] && v[1]) {
                return [`Start time: ${v[0]?.format(DATETIME_FORMAT)} ~ ${v[1]?.format(DATETIME_FORMAT)}`];
              } else if (v[0]) {
                return [`Start time: ${v[0]?.format(DATETIME_FORMAT)} ~ N/A`];
              } else if (v[1]) {
                return [`Start time: N/A ~ ${v[1]?.format(DATETIME_FORMAT)}`];
              }
              return [];
            },
            update: (filterList, filterPos, index) => {
              console.log('customFilterListOnDelete: ', filterList, filterPos, index);
              filterList[index] = [];
              return filterList;
            },
          },
          filterOptions: {
            names: [],
            display: (filterList, onChange, index, column) => (
              <div>
                <FormLabel>Work Start Time</FormLabel>
                <FormGroup row>
                  <DateTimePicker
                    renderInput={(props) => <TextField {...props} />}
                    label="from"
                    inputVariant="outlined"
                    value={filterList[index][0] ?? null}
                    defaultValue={null}
                    onChange={(newValue) => {
                      filterList[index][0] = newValue;
                      onChange(filterList[index], index, column);
                    }}
                    format={DATETIME_FORMAT}
                    style={{width: '45%', marginRight: '5%'}}
                  />
                  <DateTimePicker
                    renderInput={(props) => <TextField {...props} />}
                    label="until"
                    inputVariant="outlined"
                    value={filterList[index][1] ?? null}
                    defaultValue={null}
                    onChange={(newValue) => {
                      filterList[index][1] = newValue;
                      onChange(filterList[index], index, column);
                    }}

                    format={DATETIME_FORMAT}
                    style={{width: '45%'}}
                  />
                </FormGroup>
              </div>
            )
          }
        },
      },
      {
        name: "endAt",
        label: "Work End Time",
        options: {
          filter: true,
          sort: true,
          filterType: 'custom',
          display: this.isDisplay("endAt") ?? true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <Tooltip title={value ? moment.utc(value).local().format(DATETIME_FORMAT) : ""}>
                <div>
                  {value ? moment.utc(value).local().format(DATETIME_FORMAT) : ""}
                </div>
              </Tooltip>
            );
          },
          customFilterListOptions: {
            render: v => {
              if (v[0] && v[1]) {
                return [`End time: ${v[0]?.format(DATETIME_FORMAT)} ~ ${v[1]?.format(DATETIME_FORMAT)}`];
              } else if (v[0]) {
                return [`End time: ${v[0]?.format(DATETIME_FORMAT)} ~ N/A`];
              } else if (v[1]) {
                return [`End time: N/A ~ ${v[1]?.format(DATETIME_FORMAT)}`];
              }
              return [];
            },
            update: (filterList, filterPos, index) => {
              console.log('customFilterListOnDelete: ', filterList, filterPos, index);
              filterList[index] = [];
              return filterList;
            },
          },
          filterOptions: {
            names: [],
            display: (filterList, onChange, index, column) => (
              <div>
                <FormLabel>Work End Time</FormLabel>
                <FormGroup row>
                  <DateTimePicker
                    renderInput={(props) => <TextField {...props} />}
                    label="from"
                    inputVariant="outlined"
                    value={filterList[index][0] ?? null}
                    defaultValue={null}
                    onChange={(newValue) => {
                      filterList[index][0] = newValue;
                      onChange(filterList[index], index, column);
                    }}
                    format={DATETIME_FORMAT}
                    style={{width: '45%', marginRight: '5%'}}
                  />
                  <DateTimePicker
                    renderInput={(props) => <TextField {...props} />}
                    label="until"
                    inputVariant="outlined"
                    value={filterList[index][1] ?? null}
                    defaultValue={null}
                    onChange={(newValue) => {
                      filterList[index][1] = newValue;
                      onChange(filterList[index], index, column);
                    }}

                    format={DATETIME_FORMAT}
                    style={{width: '45%'}}
                  />
                </FormGroup>
              </div>
            )
          }
        },
      },

      {
        name: "workPeriod",
        label: "Work Duration",
        options: {
          filter: true,
          sort: true,
          filterType: 'custom',
          customFilterListOptions: {
            render: v => {
              if (v[0] && v[1]) {
                return [`MinWorkPeriod: ${v[0]}`, `MaxWorkPeriod: ${v[1]}`];
              } else if (v[0]) {
                return `MinWorkPeriod: ${v[0]}`;
              } else if (v[1]) {
                return `MaxWorkPeriod: ${v[1]}`;
              }
              return [];
            },
            update: (filterList, filterPos, index) => {
              console.log('customFilterListOnDelete: ', filterList, filterPos, index);

              if (filterPos === 0) {
                filterList[index].splice(filterPos, 1, '');
              } else if (filterPos === 1) {
                filterList[index].splice(filterPos, 1);
              } else if (filterPos === -1) {
                filterList[index] = [];
              }

              return filterList;
            },
          },
          filterOptions: {
            display: (filterList, onChange, index, column) => (
              <div>
                <FormLabel>Work Duration</FormLabel>
                <FormGroup row>
                  <TextField
                    label='minWorkPeriod'
                    value={filterList[index][0] || ''}
                    onChange={event => {
                      filterList[index][0] = event.target.value;
                      onChange(filterList[index], index, column);
                    }}
                    style={{width: '45%', marginRight: '5%'}}
                  />
                  <TextField
                    label='maxWorkPeriod'
                    value={filterList[index][1] || ''}
                    onChange={event => {
                      filterList[index][1] = event.target.value;
                      onChange(filterList[index], index, column);
                    }}
                    style={{width: '45%'}}
                  />
                </FormGroup>
              </div>
            ),
          },
          print: false,
        },
      },

      {
        name: "successfulTasks",
        label: "No. Successful Tasks",
        options: {
          filter: false,
          sort: true,
          display: this.isDisplay("endsuccessfulTasksAt") ?? true,
        },
      },

      {
        name: "failedTasks",
        label: "No. Failed Tasks",
        options: {
          filter: false,
          sort: true,
          display: this.isDisplay("failedTasks") ?? true,
        },
      },
      {
        name: "firstName",
        label: "First Name",
        options: {
          filter: false,
          sort: false,
          display: this.isDisplay("firstName") ?? true,
          customFilterListOptions: { render: (v) => `First Name: ${v}` },
          filterType: "textField",
        },
      },
      {
        name: "lastName",
        label: "Last Name",
        options: {
          filter: false,
          sort: false,
          display: this.isDisplay("lastName") ?? true,
          customFilterListOptions: { render: (v) => `Last Name: ${v}` },
          filterType: "textField",
        },
      },
    ];

    const options = {
      search: false,
      filter: true,
      confirmFilters: true,
      serverSide: true,
      filterType: "dropdown",
      responsive: 'vertical',
      rowsPerPageOptions: [10, 20, 50, 100],
      rowsPerPage: rowsPerPage,
      count: totalElements,
      page: page,
      fixedHeader: true,
      tableBodyHeight: "900px",
      onTableChange: (action, tableState) => {
        switch (action) {
          case "viewColumnsChange":
            this.onChangeColumnDisplay();
            break;
          case "onFilterDialogOpen":
            this.isFilterDialogOpen = true;
            break;
          case "filterChange":
            if (this.isFilterDialogOpen) return;
            else this.refresh();
            break;
          case "onFilterDialogClose":
            this.isFilterDialogOpen = false;
          case "changePage":
          case "sort":
          case "changeRowsPerPage":
            this.refresh();
            break;
          default:
            console.log("Not handled action " + action);
        }
      },
      customFilterDialogFooter: (currentFilterList, applyNewFilters) => {
        return (
          <div style={{marginTop: "40px"}}>
            <Button
              variant="contained"
              style={{color: "white", backgroundColor: "#36b9cc"}}
              onClick={() => {
                applyNewFilters();
              }}
            >
              Apply
            </Button>
          </div>
        );
      }
    };

    return (
      <React.Fragment>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <MUIDataTable
            title={<h1 className="tableTitle">Work Record</h1>}
            data={list}
            columns={columns}
            options={options}
            ref={this.tableRef}
          />
        </MuiPickersUtilsProvider>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  groundTeamWorkRecords: state.groundTeamUserReducer.groundTeamWorkRecords,
});

const mapDispatchToProps = (dispatch) => ({
  getDailyWorkingRecord: (uuid, params) => dispatch(getDailyWorkingRecord(uuid, params)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withAuth0(GroundTeamWorkRecordList)));
