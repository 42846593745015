import React from 'react';
import ReactEcharts from "echarts-for-react";

const RevenueChart = ({date, data, orderData, membershipData, yAxisLabel}) => {

  const getOptions = {
      textStyle: {
        fontFamily: 'Urbanist'
      },
      legend: {
        type: 'plain',
        data: [
          {name: 'Total Revenue'},
          {name: 'Order'},
          {name: 'Membership'},
        ],
        orient: "horizontal",
      },
      xAxis: {
        type: 'category',
        data: date,
        axisPointer: {
          type: 'shadow'
        }
      },
      grid: {
        containLabel: true
      },
      yAxis: {
        type: 'value',
        axisLabel: {
          formatter: yAxisLabel ? `{value} ${yAxisLabel}` : `{value}`,
        }
      },

      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross',
          crossStyle: {
            color: '#333399'
          }
        }
      },
      toolbox: {
        feature: {
          magicType: {show: true, title: 'Data View', type: ['line', 'bar']},
          restore: {show: true, title: 'Restore'},
          saveAsImage: {show: true, title: 'Save as image'}
        }
      },
      series: [
        {
          name: 'Total Revenue',
          data: data,
          type: 'line',
          smooth: true,
          lineStyle: {
            color: '#7A8B8C',
            width: 3
          },
        },
        {
          name: 'Order',
          type: 'bar',
          data: orderData,
          itemStyle: {normal: {color: '#5D739A'}},
        },
        {
          name: 'Membership',
          type: 'bar',
          data: membershipData,
          itemStyle: {normal: {color: '#8784C7'}},
        }
      ]
    };


  return (
    <ReactEcharts style={{width: '100%', height: '50vh'}} option={getOptions}/>
  );
};


export default RevenueChart;