import React, {Component} from 'react'
import {faDownload} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import RevenueCurrentMonthCard from '../../components/Card/RevenueCurrentMonthCard'
import DailyOrderCard from '../../components/Card/DailyOrderCard'
import DailyTaskCard from '../../components/Card/DailyTaskCard'
import RevenueCard from '../../components/ChartCard/RevenueCard'
import TopUpCard from '../../components/ChartCard/TopUpCard'
import VehicleStatusCard from '../../components/ChartCard/VehicleStatusCard'
import DailyOperationVehicleCard from '../../components/Card/DailyOperationVehicleCard'
import VehicleBatteryStatusCard from '../../components/ChartCard/VehicleBatteryStatusCard'
import OrderCard from '../../components/ChartCard/OrderCard'
import UserSatisfactionCard from '../../components/ChartCard/UserSatisfactionCard'
import RegisterCard from '../../components/ChartCard/RegisterCard'
import VehicleMap from "../OperationCenter/VehicleInformation/VehicleMap/VehicleMap";
import { withTranslation } from 'react-i18next';

class KpiDashboard extends Component {
    render() {
        const { t } = this.props;
        return (
            <div>
                <div className="d-sm-flex align-items-center justify-content-between mb-4">
                    <h1 className="h3 mb-0 text-gray-800">{t('kpi-dashboard.title')}</h1>
                    <a href="#" className="d-none d-sm-inline-block btn btn-sm shadow-sm" onClick={() => window.print()} style={{color: '#fff', backgroundColor: '#333399', borderColor: '#333399'}}><FontAwesomeIcon icon={faDownload} className="fa-sm text-white-50"></FontAwesomeIcon> Generate Report</a>
                </div>
                
                <div className="row">
                    <DailyOrderCard/>
                    <RevenueCurrentMonthCard/>
                    <DailyTaskCard/>
                    <DailyOperationVehicleCard/>
                </div>

                <div className="row">
                    <VehicleBatteryStatusCard/>
                    <VehicleStatusCard/>
                </div>


                <div className="row">
                    <RevenueCard/>
                </div>

                <div className="row">
                    <TopUpCard/>
                </div>

                <div className="row">
                    <OrderCard/>
                </div>

                <div className="row">
                    <RegisterCard/>
                </div>

                <div className="row">
                    <UserSatisfactionCard/>
                </div>

                {/*<div className="row">*/}
                {/*    <RegisterBehaviourCard/>*/}
                {/*</div>*/}

                <div className="row">
                    <VehicleMap/>
                </div>
                
            </div>
        )
    }
}

export default withTranslation()(KpiDashboard)


