import React from "react";
import PropTypes from 'prop-types';
import {CircularProgress, IconButton, Tooltip} from "@material-ui/core";
import {red} from "@material-ui/core/colors";
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import HelpIcon from '@material-ui/icons/Help';
import Button from "@material-ui/core/Button";
import Modal from "react-bootstrap/Modal";

import axios from "axios";

import {config} from "../../../../auth/auth";

const host = process.env.REACT_APP_HOST

class VehicleLockStatus extends React.Component {
  static propTypes = {
    value: PropTypes.string.isRequired,
    uuid: PropTypes.string.isRequired
  };

  constructor(props) {
    super(props);
    this.confirmModalText = "";
    this.state = {
      selectedIndex:0,
      showConfirmModal: false,
      refreshing: false
    };
  }

  onCloseConfirmModal = () => {
    this.setState({ showConfirmModal: false });
  };

  onConfirm = () => {
    if(this.state.refreshing){
        this.stopRefresh();
        this.setState({ showConfirmModal: false, refreshing: false });
    }else{
        const { value } = this.props;
        if(value==='LOCKED')
            axios.put(`${host}/vehicle/${this.props.uuid}/unlock`, undefined, config());
        else
            axios.put(`${host}/vehicle/${this.props.uuid}/lock`, undefined, config());

        this.setState({ showConfirmModal: false, refreshing: true });
        this.timerID = setInterval(() => this.timedRefresh(), 1000);
    }
  };

  timedRefresh = () => {
    this.props.refresh();
  }

  stopRefresh = ()=>{
    clearInterval(this.timerID);
    this.timerID = undefined;
  }

  componentWillUnmount() {
    this.stopRefresh();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.value !== this.props.value) {
        this.stopRefresh();
        this.setState({ refreshing: false });
    }
  }

  onClick = ()=>{
    const { value } = this.props;

    if(this.state.refreshing){
        this.confirmModalText = "Stop waiting?"
        this.setState({ showConfirmModal: true });
    }else{

        if(value==='LOCKED')
            this.confirmModalText = "Are you sure you want to unlock this vehicle?"
        else if (value === 'UNLOCKED')
            this.confirmModalText = "Are you sure you want to lock this vehicle?"

        this.setState({ showConfirmModal: true });
    }
  }

  render() {
    const { value, display} = this.props;

    let icon;
    let title = value ? `${value}` : "Unknown";

    if (value === 'LOCKED') {
      icon = <LockIcon/>;
    }else if (value === 'UNLOCKED') {
      icon = <LockOpenIcon />;
    }else{
      icon = <HelpIcon style={{ color: red[500] }}/>;
      title = "Lock status unknown"
    }

    const refreshing = this.state.refreshing

    return (

      <React.Fragment>

        <Modal
          show={this.state.showConfirmModal}
          onHide={() => this.onCloseConfirmModal()}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Confirm</Modal.Title>
          </Modal.Header>
          <Modal.Body>{this.confirmModalText}</Modal.Body>
          <Modal.Footer className="text-right">
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={() => this.onConfirm()}
            >
              Yes
            </Button>
            <Button
              size="small"
              variant="secondary"
              onClick={() => this.onCloseConfirmModal()}
            >
              No
            </Button>
          </Modal.Footer>
        </Modal>

        <Tooltip title={title}>
          <IconButton onClick={this.onClick}>
            {icon}
            { refreshing && <CircularProgress size={15}/> }
          </IconButton>
        </Tooltip>
        {display ? title : ""}
      </React.Fragment>
    );
  }
}

export default VehicleLockStatus;