import React, {Component} from "react";
import MUIDataTable from "mui-datatables";
import {withAuth0} from "@auth0/auth0-react";
import {IconButton, Tooltip} from "@material-ui/core";
import {connect} from "react-redux";
import {search} from "../../../redux/battery/battery.action";
import {queryParams} from "../../../utils/utils";
import PropTypes from "prop-types";
import {Skeleton} from "@material-ui/lab";
import SearchIcon from "@material-ui/icons/Search";
import moment from "moment";
import BatteryHealthyMonitor from './battery-healthy';


const date_format = process.env.REACT_APP_DATE_FORMAT;

class BatteryList extends Component {
  constructor(props) {
    super(props);
    this.isFilterDialogOpen = false;
    this.tableRef = React.createRef();
    this.columnsDisplay = undefined;
  }

  static propTypes = {
    filters: PropTypes.object,
  };

  componentDidMount() {
    if (!this.props.businesses)
      this.refresh();
  }

  refresh = () => {
    const {search, filters} = this.props;

    // table state
    const state = this.tableRef?.current?.state;

    const params = queryParams(this.props, state);

    filters?.map(f => params.set(f.key, f.value));

    search(params);
  }

  onChangeColumnDisplay = () => {
    const state = this.tableRef?.current?.state;
    this.columnsDisplay = state.columns.reduce((obj, item) => Object.assign(obj, {[item.name]: item.display}), {});
  }

  isDisplay = (name) => {
    if (!this.columnsDisplay || !this.columnsDisplay.hasOwnProperty(name))
      return undefined;
    else
      return this.columnsDisplay[name];
  }

  render() {
    const { batteries } = this.props;
    if (!batteries)
      return (<Skeleton variant="rect" width={"100%"} height={800}/>);

    const list = batteries?.content;
    const totalElements = batteries?.totalElements ?? 0;
    const rowsPerPage = batteries?.pageable?.pageSize ?? 10;
    const page = batteries?.pageable?.pageNumber ?? 0;

    const columns = [
      {
        name: "uuid",
        label: "ID",
        options: {
          filter: false,
          sort: false,
          filterType: "textField",
          display: this.isDisplay("uuid") ?? true,
        },
      },
      {
        name: "vehicleUuid",
        label: "Vehicle ID",
        options: {
          filter: false,
          sort: false,
          filterType: "textField",
          display: this.isDisplay("vehicleUuid") ?? false,
        },
      },
      {
        name: "chargingCycle",
        label: "Charging Cycle",
        options: {
          filter: false,
          sort: false,
          filterType: "textField",
          display: this.isDisplay("chargingCycle") ?? true,
        },
      },
      {
        name: "warrantyTimes",
        label: "Warranty Times",
        options: {
          filter: false,
          sort: false,
          filterType: "textField",
          display: this.isDisplay("warrantyTimes") ?? true,
        },
      },
      {
        name: "warrantyPeriod",
        label: "Warranty Period",
        options: {
          filter: false,
          sort: false,
          filterType: "textField",
          display: this.isDisplay("warrantyPeriod") ?? true,
        },
      },
      {
        name: "manufactureOn",
        label: "Manufacture On",
        options: {
          filter: false,
          sort: false,
          filterType: "textField",
          display: this.isDisplay("manufactureOn") ?? true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (<div>{value ? moment(value).format(date_format) : ""}</div>);
          },
        },
      },
      {
        name: "latestUsageOn",
        label: "Latest Usage On",
        options: {
          filter: false,
          sort: false,
          filterType: "textField",
          display: this.isDisplay("latestUsageOn") ?? true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (<div>{value ? moment(value).format(date_format) : ""}</div>);
          },
        },
      },
      {
        name: "batteryLevel",
        label: "Battery Level",
        options: {
          filter: false,
          sort: false,
          filterType: "textField",
          display: this.isDisplay("batteryLevel") ?? true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (value ? `${value}%` : "");
          },
        },
      },
      {
        name: "status",
        label: "Status",
        options: {
          filter: false,
          sort: false,
          filterType: "textField",
          display: this.isDisplay("status") ?? true,
        },
      },
      {
        name: "Operations",
        options: {
          filter: false,
          sort: false,
          empty: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div>
                <Tooltip title="Details">
                  <IconButton
                    onClick={() => {
                      this.props.history.push(
                        `/battery/${tableMeta.rowData[0]}`
                      );
                    }}
                  >
                    <SearchIcon />
                  </IconButton>
                </Tooltip>
              </div>
            );
          },
        },
      },
    ];

    const options = {
      viewColumns: false,
      download: true,
      print: true,
      search: false,
      filter: false,
      confirmFilters: false,
      serverSide: true,
      filterType: "dropdown",
      responsive: "vertical",
      selectableRows: "none",
      rowsPerPageOptions: [10, 20, 50, 100],
      rowsPerPage: rowsPerPage,
      count: totalElements,
      page: page,
      fixedHeader: true,
      tableBodyHeight: "900px",
      onTableChange: (action, tableState) => {
        switch (action) {
          case "viewColumnsChange":
            this.onChangeColumnDisplay();
            break;
          case "onFilterDialogOpen":
            this.isFilterDialogOpen = true;
            break;
          case "filterChange":
            if (this.isFilterDialogOpen)
              return;
            else
              this.refresh();
            break;
          case "onFilterDialogClose":
            this.isFilterDialogOpen = false;
          case "changePage":
          case "sort":
          case "changeRowsPerPage":
            this.refresh();
            break;
          default:
            console.log("Not handled action " + action);
        }
      }
    };

    return (
      <div>
        {/*<div className="row">
          <BatteryHealthyMonitor/>
    </div>*/}
        <div>
          <React.Fragment>
            <MUIDataTable
              title={<h1 className="tableTitle">Battery List</h1>}
              data={list}
              columns={columns}
              options={options}
              ref={this.tableRef}
            />
          </React.Fragment>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  batteries: state.batteryReducer.batteries
});

const mapDispatchToProps = (dispatch) => ({
  search: (params) => dispatch(search(params)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withAuth0(BatteryList));
