import React, {useState} from 'react';
import LoyaltyIcon from '@material-ui/icons/Loyalty';
import * as Yup from "yup";
import axios from "axios";
import {config} from "../../../auth/auth";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import {Modal} from "@material-ui/core";
import {Form, Formik} from "formik";
import {CheckboxField, NumberField} from "../../../components/Form/FormElements";
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(1),
  },
}));

const validationSchema = Yup.object({
  isEnableMembershipAlarm: Yup.bool().oneOf([true, false], "Required").required(),
  lowMembershipLimitThreshold: Yup.number()
    .min(1, "Min value is 1")
    .max(1000, "Max limit is 1000").label("Low Membership Limit Threshold "),
})

const host = process.env.REACT_APP_HOST

const UpdateMembershipAlarmModal = ({refresh, uuid, currentLimit}) => {
  const [showModal, setShowModal] = useState(false);
  const classes = useStyles();

  const handleOpen = () => {
    setShowModal(true);
  }
  const handleClose = () => {
    setShowModal(false);
  }

  const handleSubmit = (values) => {
    return axios
      .put(`${host}/partner-setting/${uuid}/membership-limit`, values, config())
      .catch((e) => alert(e))
      .finally(() => {
        setShowModal(false);
        refresh();
      });
  }

  return (
    <React.Fragment>
      <Tooltip title={"Update Membership Notification"}>
        <IconButton onClick={() => handleOpen()}>
          <LoyaltyIcon/>
        </IconButton>
      </Tooltip>
      <Modal
        open={showModal}
        onClose={handleClose}
        className={classes.modal}
      >
        <div className="card shadow mb-4 " key="terms-and-conditions-key">
          <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
            <h4 className="m-0 font-weight-bold" style={{color: '#333399'}}>
              Update Membership Notification
            </h4>
          </div>
          <div className="card-body">
            <p className="mb-2">
              This page allows you Update Membership Alarm and Limit for Notification
            </p>
            <Formik
              initialValues={{lowMembershipLimitThreshold: currentLimit}}
              validationSchema={validationSchema}
              onSubmit={(values) => handleSubmit(values)}>
              {(formik) => (
                <Form>
                  <CheckboxField name="isEnableMembershipAlarm" label="Activate Low Membership Limit Alarm (required)" row/>
                  <NumberField readOnly={false} name="lowMembershipLimitThreshold" label="Low Membership Limit"/>
                  <Button
                    variant="contained"
                    style={{color: "white", backgroundColor: "#333399"}}
                    type="submit"
                  >Submit</Button>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default UpdateMembershipAlarmModal;