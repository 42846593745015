import React, {Component} from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
  faAddressCard,
  faCog,
  faComments,
  faDollarSign,
  faGlobe,
  faHistory,
  faInfoCircle,
  faLaptop,
  faList,
  faMapMarkedAlt,
  faNewspaper,
  faPlusSquare,
  faQrcode,
  faRoute,
  faTachometerAlt,
  faUserCog,
  faUserFriends
} from '@fortawesome/free-solid-svg-icons';
import {Link} from 'react-router-dom';
import {withTranslation} from 'react-i18next';
import {hasPermission} from "../../utils/permission-utils";
import newIcon from '../../images/new.png';
import newIconRot from '../../images/newRot.png';
import TrcLogo from './trc-logo.png'
import "./styles.css"


class Sidebar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            toggledClass: false,
        };
      }
    render() {
        const { t } = this.props;
        return (
            <div className='dropDownVisibility'>
                <ul   className={'navbar-nav bg-gradient-info sidebar sidebar-dark accordion ' + ( this.state.toggledClass ? "toggled" : "")} id="accordionSidebar">
                    <a className="sidebar-brand d-flex align-items-center justify-content-center" href="">
                        <img src={TrcLogo} alt='Trc Logo' style={{width:'30%'}}/>
                    </a>

                    <hr className="sidebar-divider my-0"/>

                    <li className="nav-item">
                        <Link className={`nav-link ${hasPermission('DASHBOARD') || 'disabled-link'}`} to="/kpi-dashboard">
                            <FontAwesomeIcon icon={faTachometerAlt}/>
                            <span> {t('sidebar.kpi-dashboard')}</span>
                        </Link>
                    </li>

                    <hr className="sidebar-divider"/>

                    <div className="sidebar-heading">
                        {t('sidebar.task-title')}
                    </div>

                    <li className="nav-item">
                        <Link className={`nav-link ${hasPermission('TASK_MAP') || 'disabled-link'}`} to="/task-management/task-map" >
                            <FontAwesomeIcon icon={faMapMarkedAlt} />
                            <span> {t('sidebar.task-map')}</span>
                        </Link>
                    </li>

                    <li className="nav-item">
                        <Link className={`nav-link ${hasPermission('TASK_LIST') || 'disabled-link'}`} to="/task-management/task-list">
                            <FontAwesomeIcon icon={faList}/>
                            <span> {t('sidebar.task-list')}</span>
                        </Link>
                    </li>
                    
                    <li className="nav-item">
                        <Link className={`nav-link ${hasPermission('TASK_CREATION') || 'disabled-link'}`} to="/task-management/task-creation">
                            <FontAwesomeIcon icon={faPlusSquare} />
                            <span> {t('sidebar.task-creation')}</span>
                        </Link>
                    </li>

                    {/* <li className="nav-item">
                        <Link className="nav-link" to="/task-management/team-metrics">
                            <FontAwesomeIcon icon={faUserTag} />
                            <span> Team Metrics</span>
                        </Link>
                    </li> */}
                    <li className="nav-item">
                        <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseGroundTeam" aria-expanded="true" aria-controls="collapsePages">
                            <FontAwesomeIcon icon={faUserFriends}/>
                            <span> {t('sidebar.ground-team')} </span></a>
                        <div id="collapseGroundTeam" className="collapse sidebar-collapse" aria-labelledby="headingOrders" data-parent="#accordionSidebar">
                            <div className="bg-white py-2 collapse-inner rounded">
                           {/* <Link className="collapse-item" to="/task-management/team-metrics">Team Metrics</Link> */}
                            <Link className={`collapse-item ${hasPermission('GROUND_TEAM_LIST') || 'disabled-link'}`} to="/ground-team-information/ground-team-list">Ground Team List</Link>
                            <Link className={`collapse-item ${hasPermission('WORK_RECORD') || 'disabled-link'}`} to="/ground-team-information/work-record">Work Record</Link>
                            {/* <Link className="collapse-item" to="/ground-team/live-chat">Internal Chat</Link> */}
                            </div>
                        </div>
                    </li>
                    
                    <hr className="sidebar-divider"/>

                    <div className="sidebar-heading">
                        {t('sidebar.operation-title')}
                    </div>

                    <li className="nav-item">
                        <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseVehicles" aria-expanded="true" aria-controls="collapsePages">
                            <FontAwesomeIcon icon={faQrcode}/>
                            <span> {t('sidebar.vehicle-information')}</span>
                        </a>
                        <div id="collapseVehicles" className="collapse sidebar-collapse" aria-labelledby="headingVehicles" data-parent="#accordionSidebar">
                            <div className="bg-white py-2 collapse-inner rounded">
                                <Link className={`collapse-item ${hasPermission('VEHICLE_LIST') || 'disabled-link'}`} to="/vehicle-information/vehicle-list">Vehicle List</Link>
                                <Link className={`collapse-item ${hasPermission('VEHICLE_MAP') || 'disabled-link'}`} to="/vehicle-information/vehicle-map">Vehicle Map</Link>
                                {/* <Link className="collapse-item" to="/vehicle-information/vehicle-search">Vehicle Search</Link> */}
                            </div>
                        </div>
                    </li>



                    <li className="nav-item">
                        <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseUsers" aria-expanded="true" aria-controls="collapsePages">
                            <FontAwesomeIcon icon={faAddressCard}/>
                            <span> {t('sidebar.user-information')}</span></a>
                        <div id="collapseUsers" className="collapse sidebar-collapse" aria-labelledby="headingUsers" data-parent="#accordionSidebar">
                            <div className="bg-white py-2 collapse-inner rounded">
                                <Link className={`collapse-item ${hasPermission('USER_LIST') || 'disabled-link'}`} to="/user-information/user-list">User List</Link>
                                <Link className={`collapse-item ${hasPermission('SUSPICIOUS_USERS') || 'disabled-link'}`} to="/user-information/user-list?userStatus=INACTIVE">Suspicious Users</Link>
                                <Link className={`collapse-item ${hasPermission('SUSPICIOUS_USERS') || 'disabled-link'}`} to="/user-information/user-list?userStatus=AWAITING_DELETE">Deleting Users</Link>
                                <Link className={`collapse-item ${hasPermission('DRIVER_LICENSE_CHECK') || 'disabled-link'}`} to="/user-information/drivingLicencePage">Driver License Check</Link>
                                <Link className={`collapse-item ${hasPermission('MEMBERSHIP_ACCOUNT_LIST') || 'disabled-link'}`} to="/user-information/membership-account-list">Membership Account List</Link>
                            </div>
                        </div>
                    </li>

                    <li className="nav-item">
                        <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseOrderss" aria-expanded="true" aria-controls="collapsePages">
                            <FontAwesomeIcon icon={faRoute}/>
                            <span> {t('sidebar.order-information')}</span></a>
                        <div id="collapseOrderss" className="collapse sidebar-collapse " aria-labelledby="headingOrders" data-parent="#accordionSidebar">
                            <div className="bg-white py-2 collapse-inner rounded ">
                                <Link className={` collapse-item ${hasPermission('ORDER_LIST') || 'disabled-link'}`} to="/order-information/order-list">Order List</Link>
                                <Link className={`collapse-item ${hasPermission('ORDER_MAP') || 'disabled-link'}`} to="/order-information/order-map">Order Map</Link>
                                {/* <Link className="collapse-item" to="/order-information/order-search">Order Search</Link> */}
                                <Link className={`collapse-item ${hasPermission('PARKING_PICTURE_CHECK') || 'disabled-link'}`} to="/order-information/parking-picture-checking">Parking Picture Check</Link>
                                <Link className={`collapse-item ${hasPermission('QUESTIONABLE_ORDER') || 'disabled-link'}`} to="/order-information/order-list?isSuspicious=true">Questionable Order</Link>
                            </div>
                        </div>
                    </li>

                    <li className="nav-item">
                        <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseCustomer" aria-expanded="true" aria-controls="collapsePages">
                            <FontAwesomeIcon icon={faComments}/>
                            <span> {t('sidebar.customer-service')}</span></a>
                        <div id="collapseCustomer" className="collapse sidebar-collapse" aria-labelledby="headingOrders" data-parent="#accordionSidebar">
                            <div className="bg-white py-2 collapse-inner rounded">
                                <Link className={`collapse-item ${hasPermission('LIVE_CHAT') || 'disabled-link'}`} to="/customer-service/live-chat">Live Chat</Link>
                                {/*<Link className="collapse-item" to="/customer-service/live-chat">Ticket System</Link>
                                <Link className="collapse-item" to="/customer-service/live-chat">Toll-Free-Number</Link>
                                 <Link className="collapse-item" to="/customer-service/ticket-system">Ticket System</Link> */}
                            </div>
                        </div>
                    </li>

                    <hr className="sidebar-divider"/>
{/*
                    <div className="sidebar-heading">
                        Statistics
                    </div>

                    <li className="nav-item">
                        <a className="nav-link" href="usage-statistics.html">
                            <FontAwesomeIcon icon={faCompass}/>
                            <span> Usage Statistics</span>
                        </a>
                    </li>

                    <li className="nav-item">
                        <a className="nav-link" href="revenue-statistics.html">
                            <FontAwesomeIcon icon={faHandHoldingUsd}/>
                            <span> Revenue Statistics</span>
                        </a>
                    </li>

                    <li className="nav-item">
                        <a className="nav-link" href="user-statistics.html">
                            <FontAwesomeIcon icon={faUserTag}/>
                            <span> User Statistics</span>
                        </a>
                    </li>

                    <li className="nav-item">
                        <a className="nav-link" href="high-demand.html">
                            <FontAwesomeIcon icon={faCrosshairs}/>
                            <span> High Demand Area</span>
                        </a>
                    </li>

                    <hr className="sidebar-divider"/>
*/}
                    <div className="sidebar-heading">
                        {t('sidebar.system-title')}
                    </div>

                    <li className="nav-item">
                        <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseInformation" aria-expanded="true" aria-controls="collapsePages">
                            <FontAwesomeIcon icon={faInfoCircle}/>
                            <span> {t('sidebar.information-settings')} </span>
                        </a>
                        <div id="collapseInformation" className="collapse sidebar-collapse" aria-labelledby="headingInformation" data-parent="#accordionSidebar">
                            <div className="bg-white py-2 collapse-inner rounded">
                                <Link className={`collapse-item ${hasPermission('NOTIFICATION_TEMPLATE') || 'disabled-link'}`} to="/information-settings/notification-list">Notification Template</Link>
                                <Link className={`collapse-item ${hasPermission('NOTIFICATION_TASK') || 'disabled-link'}`} to="/information-settings/notification-task">Notification Task</Link>
                                <Link className={`collapse-item ${hasPermission('LEGAL_INFORMATION') || 'disabled-link'}`} to="/information-settings/legal-information">Legal Information</Link>
                                <Link className={`collapse-item ${hasPermission('SOCIAL_MEDIA') || 'disabled-link'}`} to="/information-settings/social-media">Social Media</Link>
                                <Link className={`collapse-item ${hasPermission('NOTIFICATION_TEMPLATE') || 'disabled-link'}`} to="/information-settings/partner-setting">Alarm Setting</Link>
                                {/* <Link className="collapse-item" to="/information-settings/how-to-ride">How to Ride</Link> */}
                            </div>
                        </div>
                    </li>

                    <li className="nav-item">
                        <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseRules" aria-expanded="true" aria-controls="collapsePages">
                            <FontAwesomeIcon icon={faCog}/>
                            <span> {t('sidebar.operation-rules')}</span></a>
                        <div id="collapseRules" className="collapse sidebar-collapse" aria-labelledby="headingRules" data-parent="#accordionSidebar">
                            <div className="bg-white py-2 collapse-inner rounded">
                                <Link className={`collapse-item ${hasPermission('REGION_RULES') || 'disabled-link'}`} to="/operation-rules/region-rule-list">Region Rules</Link>
                                <Link className={`collapse-item ${hasPermission('TASK_RULES') || 'disabled-link'}`} to="/operation-rules/task-rule-list">Task Rules</Link>
                           </div>
                        </div>
                    </li>

                    <li className="nav-item">
                        <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseGeofencing" aria-expanded="true" aria-controls="collapsePages">
                            <FontAwesomeIcon icon={faGlobe}/>
                            <span> {t('sidebar.geofencing-settings')} </span></a>
                        <div id="collapseGeofencing" className="collapse sidebar-collapse" aria-labelledby="headingGeofencing" data-parent="#accordionSidebar">
                            <div className="bg-white py-2 collapse-inner rounded">
                              <div>
                                <Link className={`collapse-item ${hasPermission('CITY_LIST') || 'disabled-link'}`} to="/geofencing-settings/city">City List</Link>
                                <Link className={`collapse-item ${hasPermission('COUNTRY_LIST') || 'disabled-link'}`} to="/geofencing-settings/country">Country List</Link>
                                <Link className={`collapse-item ${hasPermission('REGION_LIST') || 'disabled-link'}`} to="/geofencing-settings/region-list">Region List</Link>
                                <Link className={`collapse-item ${hasPermission('REGION_MAP') || 'disabled-link'}`} to="/geofencing-settings/region-map">Region Map</Link>
                                <Link className={`collapse-item ${hasPermission('PARKING') || 'disabled-link'}`} to="/geofencing-settings/parking">Parking Map</Link>
                                <Link className={`collapse-item ${hasPermission('PARKING_LIST') || 'disabled-link'}`} to="/geofencing-settings/parking-list">Parking List</Link>
                                <Link className={`collapse-item ${hasPermission('POINT_OF_INTEREST') || 'disabled-link'}`} to="/geofencing-settings/point-of-interest">Point of Interest</Link>
                              </div>
                                {/*  */}
                            </div>
                        </div>
                    </li>

                    <li className="nav-item">
                        <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapsePricing" aria-expanded="true" aria-controls="collapsePages">
                            <FontAwesomeIcon icon={faDollarSign}/>
                            <span> {t('sidebar.pricing-settings')} </span></a>
                        <div id="collapsePricing" className="collapse sidebar-collapse" aria-labelledby="headingPricing" data-parent="#accordionSidebar">
                            <div className="bg-white py-2 collapse-inner rounded">
                                <Link className={`collapse-item ${hasPermission('PRICING_STRUCTURE') || 'disabled-link'}`} to="/pricing-settings/pricing-structure">Pricing Structure</Link>
                                <Link className={`collapse-item ${hasPermission('VOUCHER') || 'disabled-link'}`} to="/pricing-settings/voucher">Voucher</Link>
                                <Link className={`collapse-item ${hasPermission('TOP_UP_TEMPLATE') || 'disabled-link'}`} to="/pricing-settings/top-up-template">TopUp Template</Link>
                                <Link className={`collapse-item ${hasPermission('MEMBERSHIP_STRUCTURE') || 'disabled-link'}`} to="/pricing-settings/membership-structure">Membership</Link>
                                {/*
                                <Link className="collapse-item" to="/pricing-settings/discounting">Discounting</Link> 
                                <Link className="collapse-item" to="/pricing-settings/voucher">Voucher</Link> */}
                            </div>
                        </div>
                    </li>

                    <li className="nav-item">
                        <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseMarketing" aria-expanded="true" aria-controls="collapsePages">
                            <FontAwesomeIcon icon={faNewspaper}/>
                            <span> News </span>
                        </a>
                        <div id="collapseMarketing" className="collapse sidebar-collapse" aria-labelledby="headingInformation" data-parent="#accordionSidebar">
                            <div className="bg-white py-2 collapse-inner rounded">
                                <Link className={`collapse-item ${hasPermission('POPUP_NEWS') || 'disabled-link'}`} to="/news/pop-up-news">PopUp News</Link>
                                {/* <Link className="collapse-item" to="/marketing-settings/marketing-rules">Marketing Rules</Link> */}
                            </div>
                        </div>
                    </li>

                    <li className="nav-item">
                        <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseAdmin" aria-expanded="true" aria-controls="collapsePages">
                            <FontAwesomeIcon icon={faUserCog}/>
                            <span> {t('sidebar.admin-settings')} <img src={newIconRot} className="newIcon" alt="new" /></span></a>
                        <div id="collapseAdmin" className="collapse sidebar-collapse" aria-labelledby="headingAdmin" data-parent="#accordionSidebar">
                            <div className="bg-white py-2 collapse-inner rounded">
                                <Link className={`collapse-item ${hasPermission('ADMIN_USERS') || 'disabled-link'}`} to="/admin-settings/admin-users"> Admin Users</Link>
                                <Link className={`collapse-item ${hasPermission('ADMIN_ROLES') || 'disabled-link'}`} to="/admin-settings/role">Admin Roles</Link>
                                <Link className={`collapse-item ${hasPermission('CORPORATE') || 'disabled-link'}`} to="/admin-settings/business">Corporate</Link>
                                <Link className={`collapse-item ${hasPermission('MIGRATION_PROCESS') || 'disabled-link'}`} to="/admin-settings/migration-process">Migration Process</Link>
                                <Link className={`collapse-item ${hasPermission('DOWNLOAD_DATA') || 'disabled-link'}`} to="/admin-settings/download-data">Download Data</Link>
                                <Link className={`collapse-item ${hasPermission('DOWNLOAD_DATA') || 'disabled-link'}`} to="/admin-settings/trac-it-support"><img src={newIcon} className="newIcon" alt="new" /> TRAC IT Support</Link>
                                {/* <a className="collapse-item" href="admin-log.html">Admin Log</a> */}
                            </div>
                        </div>
                    </li>
{/*
                    <hr className="sidebar-divider"/>

                    <div className="sidebar-heading">
                        Artificial Intelligence
                    </div>

                    <li className="nav-item">
                        <a className="nav-link" href="ai-settings.html">
                            <FontAwesomeIcon icon={faBrain}/>
                            <span> AI Goals</span>
                        </a>
                    </li>
*/}
                    <hr className="sidebar-divider"/>

                    <div className="sidebar-heading">
                        Maintenance
                    </div>
{/*
                    <li className="nav-item">
                        <Link className="nav-link" to="/shopping/spare-parts">
                            <FontAwesomeIcon icon={faDolly} />
                            <span> {t('sidebar.spare-parts')}</span>
                        </Link>
                    </li>
*/}
                    <li className="nav-item">
                        <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseWarranty" aria-expanded="true" aria-controls="collapsePages">
                            <FontAwesomeIcon icon={faHistory}/>
                            <span> Warranty</span></a>
                        <div id="collapseWarranty" className="collapse sidebar-collapse" aria-labelledby="headingWarranty" data-parent="#accordionSidebar">
                            <div className="bg-white py-2 collapse-inner rounded">
                                <Link className={`collapse-item ${hasPermission('BATTERY') || 'disabled-link'}`} to="/warranty/battery-management">Battery</Link>
                                <Link className={`collapse-item ${hasPermission('PRODUCT') || 'disabled-link'}`} to="/warranty/product">Product</Link>
                            </div>
                        </div>
                    </li>



                    <hr className="sidebar-divider d-none d-md-block"/>

                    <div className="text-center d-none d-md-inline">
                        <button className="rounded-circle border-0" id="sidebarToggle" 
                        onClick={(e) => {
                                e.preventDefault();
                                this.setState({
                                    toggledClass: !this.state.toggledClass,
                                         });}}>

                        </button>
                    </div>
                    <div className="sidebar-heading">
                        V2023.02.01
                    </div>
                </ul>
                
            </div>
        )
    }
}

export default withTranslation()(Sidebar)
