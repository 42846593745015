import React from "react";
import {IconButton, Tooltip} from "@material-ui/core";
import RedeemIcon from '@material-ui/icons/Redeem';
import PropTypes from "prop-types";
import Loading from "../../../../components/common/Loading";
import {unbindCard} from "../../../../redux/user/user.action";


class UnbindCard extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false
    };
  }

  static propTypes = {
    userUuid: PropTypes.string.isRequired,
    isBindCard: PropTypes.bool.isRequired,
    callback: PropTypes.func.isRequired,
  };

  onClickBtn = () => {
    this.setState({
      isLoading: true
    });

    unbindCard(this.props.userUuid)
      .then(r => {
        this.props.callback();
      }).finally(() => {
        this.setState({
          isLoading: false
        });
      });
  }

  render() {
    const { userUuid, isBindCard} = this.props;
    if (!userUuid)
      return (<React.Fragment/>);

    if (this.state.isLoading)
      return (<Loading/>);

    return (
      <Tooltip title="Unbind Card">
        <IconButton
          aria-label="UnbindCard"
          disabled={!isBindCard}
          onClick={() => this.onClickBtn()}
        >
          <RedeemIcon />
        </IconButton>
      </Tooltip>
    );
  }
}

export default UnbindCard;