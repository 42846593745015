import React, {Component} from "react";
import MUIDataTable from "mui-datatables";
import {withAuth0} from "@auth0/auth0-react";
import {Button, Tooltip} from "@material-ui/core";
import {connect} from "react-redux";
import moment from "moment";

import {search} from "../../../redux/zendeskTicket/action";
import {queryParams, sub} from "../../../utils/utils";
import Status from "./Status";
import CustomToolbar from "./CustomToolbar";
import Edit from "./Edit";
import Delete from "../../../components/common/Delete";
import TicketDetails from "./TicketDetails";

const date_format = process.env.REACT_APP_DATE_FORMAT;
const datetime_format = process.env.REACT_APP_DATETIME_FORMAT;

class ZendeskTicketsList extends Component {
  constructor(props) {
    super(props);
    this.isFilterDialogOpen = false;
    this.tableRef = React.createRef();
    this.columnsDisplay = undefined;
  }

  componentDidMount() {
    this.refresh();
  }

  refresh = () => {
    const {search} = this.props;
    search();
  }

  onChangeColumnDisplay = () => {
    const state = this.tableRef?.current?.state;
    this.columnsDisplay = state.columns.reduce((obj, item) => Object.assign(obj, {[item.name]: item.display}), {});
  }

  isDisplay = (name) => {
    if (!this.columnsDisplay || !this.columnsDisplay.hasOwnProperty(name))
      return undefined;
    else
      return this.columnsDisplay[name];
  }

  render() {
    const { zendeskTickets } = this.props;
    if (!zendeskTickets)
      return (<React.Fragment>Loading...</React.Fragment>);
    
    const list = zendeskTickets?.tickets ?? 0;
    const totalElements = zendeskTickets?.count;

    const columns = [
      {
        name: "id",
        label: "ID",
        options: {
          filter: true,
          sort: false,
          display: this.isDisplay("id") ?? true,
          customFilterListOptions: { render: (v) => `ID: ${v}` },
          filterType: "textField",
        },
      },
      {
        name: "created_at",
        label: "Created",
        options: {
          filter: false,
          sort: true,
          display: this.isDisplay("created_at") ??  true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <Tooltip title={value ? moment(value).format(date_format) : ""}>
                <div>{value ? moment(value).format(date_format) : ""}</div>
              </Tooltip>
            );
          },
        },
      },
      {
        name: "updated_at",
        label: "Latest Updated",
        options: {
          filter: false,
          sort: true,
          display: this.isDisplay("updated_at") ??  true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <Tooltip title={value ? moment(value).format(date_format) : ""}>
                <div>{value ? moment(value).format(date_format) : ""}</div>
              </Tooltip>
            );
          },
        },
      },
      {
        name: "type",
        label: "Type",
        options: {
          filter: true,
          sort: true,
          display: this.isDisplay("type") ??  true
        },
      },
      {
        name: "subject",
        label: "Subject",
        options: {
          filter: false,
          sort: false,
          display: this.isDisplay("subject") ??  true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <Tooltip title={value}>
                <div>{sub(value,50)}</div>
              </Tooltip>
            );
          },
        },
      },
      {
        name: "status",
        label: "Status",
        index: 1,
        options: {
          filter: true,
          sort: true,
          display: this.isDisplay("status") ??  true,
        },
      },
      {
        name: "Operations",
        options: {
          filter: false,
          sort: false,
          empty: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div>
                <TicketDetails uuid={tableMeta.rowData[0]}></TicketDetails>
              </div>
            );
          },
        },
      },
    ];

    const options = {
      search: false,
      filter: true,
      confirmFilters: true,
      serverSide: false,
      filterType: "dropdown",
      responsive: "vertical",
      rowsPerPageOptions: [10, 20, 50, 100],
      count: totalElements,
      fixedHeader: true,
      tableBodyHeight: "900px",
      onTableChange: (action, tableState) => {
        switch (action) {
          case "viewColumnsChange":
            this.onChangeColumnDisplay();
            break;
          case "onFilterDialogOpen":
            this.isFilterDialogOpen = true;
            break;
          case "filterChange":
            if (this.isFilterDialogOpen)
              return;
            else
              this.refresh();
            break;
          case "onFilterDialogClose":
            this.isFilterDialogOpen = false;
          case "changePage":
          case "sort":
          case "changeRowsPerPage":
            this.refresh();
            break;
          default:
            console.log("Not handled action " + action);
        }
      },
      customFilterDialogFooter: (currentFilterList, applyNewFilters) => {
        return (
          <div style={{marginTop: "40px"}}>
            <Button variant="contained"
                    style={{color: "white", backgroundColor: "#36b9cc"}}
                    onClick={() => {applyNewFilters()}}
            >
              Apply
            </Button>
          </div>
        );
      },
      customToolbar: () => {
        return (
          <CustomToolbar refresh={this.refresh} tableProps={this.props}/>
        );
      }
    };

    return (
      <React.Fragment>
        <MUIDataTable
          title={<h1 className="tableTitle">Help Desk</h1>}
          data={list}
          columns={columns}
          options={options}
          ref={this.tableRef}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  zendeskTickets: state.zendeskTicketsReducer.zendeskTickets
});

const mapDispatchToProps = (dispatch) => ({
  search: () => dispatch(search()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withAuth0(ZendeskTicketsList));
