import React from 'react';

import {config} from "../../../auth/auth";
import {IconButton, Tooltip} from "@material-ui/core";
import { ReactComponent as AlarmIcon }  from "./alarmBell.svg"
import axios from "axios";
const host = process.env.REACT_APP_HOST

const VehicleAlarmControl = ({uuid}) => {

  let icon =  <AlarmIcon fill='#757575' style={{height: 30, width: 30}}/>;

  const onClickHandler = () => {
    return axios.post(`${host}/vehicle/${uuid}/alarm`, {}, config());
  }

  return (
    <React.Fragment>
      <Tooltip title="Ring Alarm">
        <IconButton onClick={onClickHandler}>
          {icon}
        </IconButton>
      </Tooltip>

    </React.Fragment>
  );
};

export default VehicleAlarmControl;