import {ActionTypes} from "./types";

const INITIAL_STATE = {
  orders: undefined,
  order: undefined,
  orderRoutes: undefined,
  orderWithParkingPictureToCheck: {},
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ActionTypes.SET_ORDERS:
      return {
        ...state,
        orders: action.payload
      };
    case ActionTypes.SET_ORDER:
      return {
        ...state,
        order: action.payload
      };
    case ActionTypes.SET_ORDER_ROUTES:
      return {
        ...state,
        orderRoutes: action.payload
      };
    case ActionTypes.SET_ORDER_WITH_PARKING_PICTURE_TO_CHECK:
      return {
        ...state,
        orderWithParkingPictureToCheck: action.payload
      }
    default:
      return state;
  }
};

export default reducer;
