import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});

function createData(uuid, name, employmentdate, workinghours, tasksucceed, taskfailed) {
  return {
    uuid,
    name,
    employmentdate,
    workinghours,
    tasksucceed,
    taskfailed,
    history: [
      { startdate: '2021-01-22 8:38:00', endtime: '2021-01-22 16:32:20', workinghours: 7.91, tasksucceed: 23, taskfailed: 2 },
      { startdate: '2021-01-23 8:38:20', endtime: '2021-01-23 16:03:20', workinghours: 7.42, tasksucceed: 12, taskfailed: 4 },
    ],
  };
}

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.uuid}
        </TableCell>
        <TableCell align="right">{row.name}</TableCell>
        <TableCell align="right">{row.employmentdate}</TableCell>
        <TableCell align="right">{row.workinghours}</TableCell>
        <TableCell align="right">{row.tasksucceed}</TableCell>
        <TableCell align="right">{row.taskfailed}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                History
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Start Date</TableCell>
                    <TableCell>End Date</TableCell>
                    <TableCell align="right">Working Hours</TableCell>
                    <TableCell align="right">Successful Tasks</TableCell>
                    <TableCell align="right">Failed Tasks</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.history.map((historyRow) => (
                    <TableRow key={historyRow.startdate}>
                      <TableCell component="th" scope="row">
                        {historyRow.startdate}
                      </TableCell>
                      <TableCell>{historyRow.endtime}</TableCell>
                      <TableCell align="right">{historyRow.workinghours}</TableCell>
                      <TableCell align="right">{historyRow.tasksucceed}</TableCell>
                      <TableCell align="right">{historyRow.taskfailed}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}


const rows = [
  createData(1, 'X Niu', '2021-01-20', 15.32, 35, 6),
];

export default function CollapsibleTable() {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell> ID</TableCell>
            <TableCell align="right">Name</TableCell>
            <TableCell align="right">Employment Date</TableCell>
            <TableCell align="right">Working Hours&nbsp;(h)</TableCell>
            <TableCell align="right">Successfully Task</TableCell>
            <TableCell align="right">Failed Task</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <Row key={row.name} row={row} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
