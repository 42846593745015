import React, { Component } from 'react'
import page1 from './1.jpg'
import page2 from './2.jpg'

export default class index extends Component {
    render() {
        return (
            <div>
                <h3>E-Kickscooter Warranty</h3>
                <img src={page1}></img>
                <img src={page2}></img>
            </div>   
        );
    }
}
