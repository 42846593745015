import React, { Component } from 'react'
import './index.css'

export default class TaskCreationPopUp extends Component {
    render() {
        return (
            <div className="popup-box">
                <div className="box">
                    <span className="close-icon" onClick={this.props.handleClose}>x</span>
                    {   <>
                            <h5>Create the {this.props.category} Task</h5>

                            <p>Assign to:
                            <select name="person" id="operationTeam">
                                <option value="unassigned">Unassigned</option>
                                <option value="david">David</option>
                                <option value="henrry">Henrry</option>
                            </select>
                            </p>

                            <p>Note:
                            <input name="person" id="operationTeam">
                            </input>
                            </p>

                            <button onClick={this.props.handleClose}>Confirm</button>
                        </>
                    }

                </div>
            </div>
        )
    }
}
