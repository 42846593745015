import React, {Component} from "react";
import {LayerGroup, LayersControl, MapConsumer, MapContainer, TileLayer,} from "react-leaflet";
import * as L from "leaflet";
import {Icon} from "leaflet";
import "./index.css";
import {groupBy} from "../../../utils/utils";
import {search} from "../../../redux/parkings/parkings.action";
import {connect} from "react-redux";
import {withAuth0} from "@auth0/auth0-react";
import ParkingMarker from "./ParkingMarker";
import TaskCreateModal from "./ParkingCreate";

class ParkingConfigurationMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openPopUp: false,
      parkingLat: "",
      parkingLng: "",
      lat: 22.402795,
      lng: 39.078354,
      currentPos: null,
    };
    this.createdLayer = undefined;
    this.map = undefined;
  }

  componentDidMount() {
    this.refresh();
  }

  refresh = () => {
    let params = new URLSearchParams();
    params.set("page", "0");
    params.set("size", "1000");
    this.props.search(params);
  };

  onSuccessParkingCreation() {
    this.setState({
      openPopUp: false,
      parkingLat: "",
      parkingLng: "",
    });
    this.refresh();
  }

  handleModalCloseChange = () => {
    if (this.createdLayer) {
      this.map.removeLayer(this.createdLayer);
    }
    this.setState({
      openPopUp: false,
      parkingLat: "",
      parkingLng: "",
    });
  };

  //   loading the drawing option for redistribution
  loadDrawingOption = (map) => {
    if (this.editableLayers) {
      return null;
    }
    this.map = map;
    this.editableLayers = new L.FeatureGroup();
    map.addLayer(this.editableLayers);
    var TaskCreateIcon = L.Icon.extend({
      options: {
        shadowUrl: null,
        iconAnchor: new L.Point(12, 12),
        iconSize: new L.Point(24, 24),
        iconUrl:
          "https://upload.wikimedia.org/wikipedia/commons/6/6b/Information_icon4_orange.svg",
      },
    });
    var drawPluginOptions = {
      position: "topright",
      draw: {
        polyline: false,
        circlemarker: false,
        circle: false,
        polygon: false,
        rectangle: false,
        marker: {
          icon: new TaskCreateIcon(),
        },
      },
      edit: {
        featureGroup: this.editableLayers,
        remove: false,
        edit: false,
      },
    };
    var drawControl = new L.Control.Draw(drawPluginOptions);
    map.addControl(drawControl);

    map.on("draw:created", (e) => {
      var type = e.layerType,
        layer = e.layer;
      this.createdLayer = e.layer;

      if (type === "marker") {
        this.setState({
          openPopUp: true,
          parkingLat: layer.getLatLng()["lat"],
          parkingLng: layer.getLatLng()["lng"],
        });
      }
      this.editableLayers.addLayer(layer);
    });
  };
  // end

  render() {
    const parkings = this.props.parkings?.content;
    if (!parkings) return <React.Fragment>Loading...</React.Fragment>;
    const position = [this.state.lat, this.state.lng];
    const parkingsGroupByStatus = groupBy(parkings, "status");
    const parkingsSatatus = [
      "ACTIVE",
      "INACTIVE",
    ];
    return (
      <div className="col-xl col-lg">
        <div className="card shadow mb-4">
          <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
            <h6 className="m-0 font-weight-bold" style={{color: '#333399'}}>Parking Creation</h6>
          </div>

          <div className="card-body">
            <MapContainer
              center={position}
              zoom={13}
              scrollWheelZoom={true}
              doubleClickZoom={false}
              style={{ height: "100vh" }}
            >
              <LayersControl position="topright">
                <LayersControl.BaseLayer checked name="Black And White">
                  <TileLayer
                    attribution='Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> Imagery © <a href="https://www.mapbox.com/">Mapbox</a>'
                    url="https://api.mapbox.com/styles/v1/mapbox/dark-v10/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoidHJhY2RldmVsb3BlciIsImEiOiJja2szOGJteHMxOGxhMm9wYzFhbmgwazkyIn0._UOy-teYfb2kzsEMdr8YkA"
                  />
                </LayersControl.BaseLayer>
                <LayersControl.BaseLayer name="Normal Map">
                  <TileLayer
                    attribution='Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> Imagery © <a href="https://www.mapbox.com/">Mapbox</a>'
                    url="https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoidHJhY2RldmVsb3BlciIsImEiOiJja2szOGJteHMxOGxhMm9wYzFhbmgwazkyIn0._UOy-teYfb2kzsEMdr8YkA"
                  />
                </LayersControl.BaseLayer>
                <LayersControl.BaseLayer name="Google Map">
                  <TileLayer
                    attribution='Map data &copy; <a href="https://www.google.com/maps">Google Map</a> Imagery © <a href="https://www.google.com/">Google</a>'
                    url="http://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}"
                    subdomains={['mt0','mt1','mt2','mt3']}
                  />
                </LayersControl.BaseLayer>
                  {parkingsSatatus?.map((status) => (
                    <LayersControl.Overlay checked name={status}>
                      <LayerGroup>
                        {parkingsGroupByStatus[`${status}`]?.map(
                          (parking) => {
                              return (
                                <ParkingMarker
                                  parking={parking}
                                  callback={() =>
                                    this.onSuccessParkingCreation()
                                  }
                                  key={`parking-map-marker-key-${parking.uuid}`}
                                />
                              );
                          }
                        )}
                      </LayerGroup>
                    </LayersControl.Overlay>
                  ))}
                <MapConsumer>
                  {(map) => {
                    this.loadDrawingOption(map);
                    return null;
                  }}
                </MapConsumer>
              </LayersControl>
            </MapContainer>
          </div>
        </div>
        <TaskCreateModal
          open={this.state.openPopUp}
          lat={this.state.parkingLat}
          lng={this.state.parkingLng}
          onCloseModal={() => this.handleModalCloseChange()}
          callback={() => this.onSuccessParkingCreation()}
        ></TaskCreateModal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  parkings: state.parkingsReducer.parkings,
});

const mapDispatchToProps = (dispatch) => ({
  search: (params) => dispatch(search(params)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withAuth0(ParkingConfigurationMap));
