import React, { useState, useEffect } from 'react'
import {faCalendar} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

function OperationTeamOnlineTimeCard() {
  const [seconds, setSeconds ] =  useState(0);

  useEffect(()=>{
    let myInterval = setInterval(() => {
        setSeconds(seconds + 1);
    }, 1000)
    return ()=> {
      clearInterval(myInterval);
    };
  });

  const addZeroBefore = (time) => {
    return (time < 10 ? '0' : '') + time;
  }

  return (
    <div className="col-xl col-md mb-4">
      <div className="card border-left-primary shadow h-100 py-2">
        <div className="card-body">
          <div className="row no-gutters align-items-center">
            <div className="col mr-2">
              <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">OPERATION TEAM ONLINE TIME</div>
              {
                new Date().getHours() < 9 ? (
                  <div className="h5 mb-0 font-weight-bold text-gray-800">00:00:00</div>
                ) : (
                  <div className="h5 mb-0 font-weight-bold text-gray-800">{addZeroBefore(new Date().getHours() - 9)}:{addZeroBefore(new Date().getMinutes())}:{addZeroBefore(new Date().getSeconds())}</div>
                )
              }
            </div>
            <div className="col-auto">
              <FontAwesomeIcon icon={faCalendar} className="fa-2x text-gray-300"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OperationTeamOnlineTimeCard;
