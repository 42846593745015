import React from "react";
import PropTypes from 'prop-types';
import TextField from "@material-ui/core/TextField";

class Input extends React.Component {

  static propTypes = {
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    value: PropTypes.any.isRequired,
    onChange: PropTypes.func,
    readOnly: PropTypes.bool,
  };

  formatValue = (value) => {
    if (value === null || value === undefined)
      return value;

    if (this.props.type === 'number') {
      if(typeof value === 'number') return value;
      else return Number(value);
    }

    return value;
  }

  render() {
    return (
      <TextField
        id={this.props.id}
        label={this.props.label}
        type={this.props.type}
        fullWidth
        value= {this.props.value}
        onChange={(e) => this.props.onChange(this.props.id, this.formatValue(e.target.value))}
        InputProps={{
          readOnly: !!this.props.readOnly,
        }}
        InputLabelProps={{
          shrink: true,
        }}
        variant="outlined"
      />
    );
  }
}

export default Input;