import React from "react";
import PropTypes from "prop-types";
import {Button, CircularProgress, Dialog, DialogActions, DialogTitle, Grid,} from "@material-ui/core";

import axios from "axios";
import {config} from "../../../../auth/auth";
import {grey} from "@material-ui/core/colors";
import CardContent from "@material-ui/core/CardContent";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import {search} from "../../../../redux/country/action";
import {error} from "../../../../redux/alert/alert.action";
import {connect} from "react-redux";
import Box from "@material-ui/core/Box";
import {Autocomplete} from "@material-ui/lab";

const host = process.env.REACT_APP_HOST

class CityCreateModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      cityName: undefined,
      country: undefined,
      vehiclesSupported: undefined,
      open: this.props.open,
    };
  }

  static propTypes = {
    callback: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
  };

  cancel = () => {
    this.setState({
      open: false,
      cityName: undefined,
      country: undefined,
      vehiclesSupported: undefined,
    });
    this.props.onCloseModal();
  };

  confirm = (callback) => {
    this.createNewCity(callback);
  };

  createNewCity = (callback) => {
    const body = {
      cityName: this.state.cityName,
      countryUuid: this.state.country,
      vehiclesSupported: this.state.vehiclesSupported,
    };
    return axios
      .post(`${host}/city/`, body, config())
      .then((response) => callback())
      .catch((e) => this.props.error(`${e?.response?.data?.error}: ${e?.response?.data?.message}`))
      .finally(() => {
        this.setState({
          open: false,
          cityName: undefined,
          country: undefined,
          vehiclesSupported: undefined,
        });
        this.props.onCloseModal();
      });
  };

  onChangeCityName = (e) => {
    this.setState({ cityName: e.target.value });
  };
  onChangeCountry = (event, value) => {
    this.setState({ country: value?.uuid });
  };
  onChangevehiclesSupported = (e) => {
    this.setState({ vehiclesSupported: e.target.value });
  };

  // end

  componentDidUpdate(prevProps) {
    if (this.props?.open !== prevProps.open) {
      this.setState({ open: this.props?.open });
    }
  }

  isDisableSaveBtn = () => {
    return !this.state.cityName || !this.state.country || !this.state.vehiclesSupported;
  }

  render() {
    const {countries, search, callback} = this.props;
    if (!callback) return <React.Fragment />;

    if (this.state.open && !countries) {
      search();
    }

    return (
      <React.Fragment>
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="City Action Modal"
          fullWidth={true}
        >
          <DialogTitle
            id="City Action Modal"
            style={{
              color: "white",
              backgroundColor: "#36b9cc",
              textAlign: "center",
            }}
          >
            <label>Create City</label>
          </DialogTitle>
          <Grid container spacing={2}>
            <Grid item sm={12} xs={12} md={12} lg={12}>
              <CardContent style={{ paddingBottom: "0px" }}>
                <Grid container spacing={2} className="formLabel">
                  <Grid item sm={12} xs={12} md={12} lg={12}>
                    <TextField
                      id="cityName"
                      label="City Name"
                      fullWidth
                      value={this.state.cityName ?? ""}
                      onChange={this.onChangeCityName}
                      InputProps={{
                        readOnly: false,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={2} className="formLabel">
                  <Grid item sm={12} xs={12} md={12} lg={12}>
                    <Autocomplete
                      id="city-create-model-country-select-id"
                      style={{ width: "100%" }}
                      options={countries ?? []}
                      autoHighlight
                      value={undefined}
                      disabled={this.state.isLoading ?? false}
                      getOptionSelected={(option, value) => option.isoCode === value}
                      getOptionLabel={(option) => `${option?.isoCode}-${option?.name}`}
                      renderOption={(option) => (
                        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }}>
                          <img
                            loading="lazy"
                            width="20"
                            src={`https://flagcdn.com/w20/${option?.isoCode?.toLowerCase()}.png`}
                            srcSet={`https://flagcdn.com/w40/${option?.isoCode?.toLowerCase()}.png 2x`}
                            alt=""
                          />
                          -{option?.isoCode}-{option?.name}
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Country"
                          variant="outlined"
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <React.Fragment>
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            ),
                          }}
                        />
                      )}
                      onChange={this.onChangeCountry}
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={2} className="formLabel">
                  <Grid item sm={12} xs={12} md={12} lg={12}>
                    <TextField
                      id="vehiclesSupported"
                      select
                      label="Vehicles Supported"
                      InputProps={{
                        readOnly: false,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      fullWidth
                      SelectProps={{
                        multiple: true,
                        value: this.state.vehiclesSupported ?? [],
                        onChange: this.onChangevehiclesSupported,
                      }}
                    >
                      <MenuItem key="EMOPED" value="EMOPED">
                        EMOPED
                      </MenuItem>
                      <MenuItem key="BIKE" value="BIKE">
                        BIKE
                      </MenuItem>
                      <MenuItem key="EKICKSCOOTER" value="EKICKSCOOTER">
                        EKICKSCOOTER
                      </MenuItem>
                      <MenuItem key="ECAR" value="ECAR">
                        ECAR
                      </MenuItem>
                      <MenuItem key="EBIKE" value="EBIKE">
                        EBIKE
                      </MenuItem>
                    </TextField>
                  </Grid>
                </Grid>
              </CardContent>
            </Grid>
          </Grid>

          <DialogActions
            style={{
              justifyContent: "space-between",
              padding: "10px 40px 15px 40px",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={() => this.confirm(callback)}
              disabled={this.isDisableSaveBtn()}
            >
              Save
            </Button>
            <Button onClick={this.cancel} style={{ color: grey[500] }}>
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  countries: state.countryReducer.countries?.content,
});

const mapDispatchToProps = (dispatch) => ({
  search: () => dispatch(search()),
  error: (msg) => dispatch(error(msg))
});

export default connect(mapStateToProps, mapDispatchToProps)(CityCreateModal);
