import {UserActionTypes} from "./user.types";

const INITIAL_STATE = {
  users: undefined,
  userDetails: {},
  drivingLicence: {}
};

const userReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UserActionTypes.SET_USERS:
      return {
        ...state,
        users: action.payload,
      };
    break;
    case UserActionTypes.SET_USER_COUNT:
        return {
          ...state,
          usersCount: action.payload,
        };
    break;
    case UserActionTypes.SET_USER_ROWS_PER_PAGE:
        return {
              ...state,
              rowsPerPage: action.payload,
            };
    break;
    case UserActionTypes.SET_USER_DETAILS:
      return {
          ...state,
          userDetails: action.payload
      };
      break;
    case UserActionTypes.SET_DRIVING_LICENSE:
        return {
            ...state,
            drivingLicence: action.payload
              };
    break;
    default:
      return state;
  }
};

export default userReducer;
