import React, {Component} from "react";
import {connect} from "react-redux";
import {getOrderByUuid} from "../../../../redux/order/action";
import {withAuth0} from "@auth0/auth0-react";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import {Grid, InputAdornment, Tooltip} from "@material-ui/core";
import {determineFeedbackIcon, determineVehicleImage, sub} from "../../../../utils/utils";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import TextField from "@material-ui/core/TextField";
import "./details.css";
import moment from "moment";
import Battery from "../../VehicleInformation/VehicleList/bettery";
import UserStatus from "./userStatus";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import {green, grey, red, yellow} from "@material-ui/core/colors/";
import VehicleStatus from "../../VehicleInformation/VehicleList/vehicle-status";
import OrderMapComponent from "../OrderMap/index";
import CardMedia from "@material-ui/core/CardMedia";
import TimePeriod from "./timePeriod";
import Suspicious from "../OrderList/Suspicious";
import Refund from "./refund";
import axios from "axios";
import {config} from "../../../../auth/auth";
import EndOrderPopUp from "../../../../components/PopUp/EndOrderPopUp/EndOrderPopUp.jsx";

const datetime_format = process.env.REACT_APP_DATETIME_FORMAT;
const host = process.env.REACT_APP_HOST

class OrderDetail extends Component {
  constructor(props) {
    super(props);
    this.isFilterDialogOpen = false;
    this.state = {
      parkingGoodCSS: {
        fontSize: 30,
      },
      parkingBadCSS: {
        fontSize: 30,
      },
    };
  }

  componentDidMount() {
    this.uuid = this.props.location.pathname.replace("/order/", "");
    this.props.getOrderByUuid(this.uuid);
  }

  refresh = () => {
    this.uuid = this.props.location.pathname.replace("/order/", "");
    this.props.getOrderByUuid(this.uuid);
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props?.order !== prevProps.order) {
      switch (this.props.order.parkingCheck) {
        case 'GOOD':
          this.goodParkingStyling();
          break;
        case 'BAD':
          this.badParkingStyling();
          break;
        default:
          this.notCheckedParkingStyling();
      }
    }
  }

  notCheckedParkingStyling = () => {
    this.setState({
      parkingGoodCSS: {
        fontSize: 30,
      },
      parkingBadCSS: {
        fontSize: 30,
      },
    })
  }

  goodParkingStyling = () => {
    this.setState({
      parkingGoodCSS: {
        color: green[400],
        fontSize: 30,
      },
      parkingBadCSS: {
        color: grey[400],
        fontSize: 30,
      },
    })
  }

  badParkingStyling = () => {
    this.setState({
      parkingGoodCSS: {
        color: grey[400],
        fontSize: 30,
      },
      parkingBadCSS: {
        color: red[400],
        fontSize: 30,
      },
    });
  }

  onParkingGood = (uuid) => {
    this.goodParkingStyling();
    return axios.put(`${host}/order/${uuid}/parkingCheck/GOOD`, {}, config())
      .catch((e) => alert(e));
  };
  onParkingBad = (uuid) => {
    this.badParkingStyling();
    return axios.put(`${host}/order/${uuid}/parkingCheck/BAD`, {}, config())
      .catch((e) => alert(e));
  };

  handleModalCloseChange = () => {
    this.setState({
      openEndOrderPopUp: false,
    });
  };

  convertToPercent(discount) {
    if (discount === null || discount === undefined) {
      return "0%";
    }
    return (+discount * 100) + "%";
  }

  render() {
    const {order, history} = this.props;
    if (!order || !order?.uuid) {
      return <React.Fragment>Loading...</React.Fragment>;
    }

    const vehicle = order?.vehicle;
    const user = order?.user;
    let feedbackIcon = determineFeedbackIcon(order?.userSatisfaction);
    let img = "";
    if (vehicle?.category) {
      img = determineVehicleImage(vehicle?.category);
    }
    let name = sub(`${user?.firstName} ${user?.lastName}`);

    return (
      <React.Fragment>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12} lg={8}>
            {/* order card */}
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} lg={12}>
                <Card>
                  <CardHeader
                    avatar={
                      <Avatar
                        aria-label="Order"
                        style={{width: 80, height: 80}}
                        alt="Order Photo"
                        src={"/img/OrderIcon.png"}
                      >
                        V
                      </Avatar>
                    }
                    titleTypographyProps={{variant: "h5"}}
                    title={`UUID: ${order?.uuid}`}
                  />

                  <CardActions disableSpacing>
                    <EndOrderPopUp
                      refresh={this.refresh}
                      uuid={sub(order?.uuid)}
                      status={sub(order?.status)}
                    />
                    <Refund
                      status={order?.status}
                      orderUuid={order?.uuid}
                      callback={() => this.refresh()}
                    />
                    <Suspicious
                      suspiciousStatus={order?.isSuspicious}
                      orderUuid={order?.uuid}
                      userUuid={order?.user?.uuid}
                      callback={() => this.refresh()}
                    />
                  </CardActions>
                </Card>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={12} md={12} lg={12}>
                <Card>
                  <CardHeader
                    avatar={
                      <Avatar
                        aria-label="Vehicle"
                        style={{width: 80, height: 80}}
                        alt="Vehicle Photo"
                        src={img}
                        onClick={() =>
                          history.push(`/vehicle/${vehicle?.uuid}`)
                        }
                      >
                        V
                      </Avatar>
                    }
                    titleTypographyProps={{variant: "h5"}}
                    title={`${sub(vehicle?.qrCode)}`}
                    subheader={`UUID: ${sub(vehicle?.uuid)}`}
                    style={{cursor: "pointer"}}
                  />

                  <CardActions disableSpacing>
                    <Battery value={vehicle?.batteryLevel}/>
                    <VehicleStatus value={vehicle?.status}/>
                  </CardActions>
                </Card>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} lg={12}>
                <Card>
                  <CardHeader
                    avatar={
                      <Avatar
                        aria-label="User"
                        style={{width: 80, height: 80}}
                        alt="User Photo"
                        src={user?.picture}
                        onClick={() => history.push(`/user/${user?.uuid}`)}
                      >
                        {`${user?.firstName
                          ?.charAt(0)
                          .toUpperCase()}${user?.lastName
                          ?.charAt(0)
                          .toUpperCase()}`}
                      </Avatar>
                    }
                    titleTypographyProps={{variant: "h5"}}
                    title={name}
                    subheader={`UUID: ${user?.uuid}`}
                    style={{cursor: "pointer"}}
                  />

                  <CardActions disableSpacing>
                    <UserStatus status={user?.userStatus}/>
                  </CardActions>
                </Card>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} lg={12}>
                <Card>
                  <CardHeader title="Details"/>
                  <CardContent>
                    <Row>
                      <Col xs={12} md={6} className="userDetailsFormLabel">
                        {" "}
                        <TextField
                          id="uuid"
                          label="Order UUID"
                          className=""
                          value={order.uuid}
                          fullWidth
                          InputProps={{
                            readOnly: true,
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          variant="outlined"
                        />
                      </Col>
                      <Col xs={12} md={6} className="userDetailsFormLabel">
                        {" "}
                        <TextField
                          id="updatedAt"
                          label="Latest Update Time"
                          className=""
                          value={order.updatedAt ? moment.utc(order?.updatedAt).local().format(
                            datetime_format
                          ) : ""}
                          fullWidth
                          InputProps={{
                            readOnly: true,
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          variant="outlined"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} md={6} className="userDetailsFormLabel">
                        {" "}
                        <TextField
                          id="createdAt"
                          label="Order Created"
                          className=""
                          value={order.createdAt ? moment.utc(order?.createdAt).local().format(
                            datetime_format
                          ) : moment.utc(order?.startAt).local().format(
                            datetime_format
                          )}
                          fullWidth
                          InputProps={{
                            readOnly: true,
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          variant="outlined"
                        />
                      </Col>
                      <Col xs={12} md={6} className="userDetailsFormLabel">
                        {" "}
                        <TextField
                          id="uploadAt"
                          label="User Order End Time"
                          className=""
                          value={order.uploadAt ? moment.utc(order?.uploadAt).local().format(
                            datetime_format
                          ) : ""}
                          fullWidth
                          InputProps={{
                            readOnly: true,
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          variant="outlined"
                        />
                      </Col>
                    </Row>

                    <Row>
                      <Col xs={12} md={6} className="userDetailsFormLabel">
                        {" "}
                        <TextField
                          id="startAt"
                          label="Start Time"
                          className=""
                          value={moment.utc(order?.startAt).local().format(datetime_format)}
                          fullWidth
                          InputProps={{
                            readOnly: true,
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          variant="outlined"
                        />
                      </Col>
                      <Col xs={12} md={6} className="userDetailsFormLabel">
                        {" "}
                        <TextField
                          id="endAt"
                          label="System Order End Time"
                          className=""
                          value={order.endAt ? moment.utc(order?.endAt).local().format(
                            datetime_format
                          ) : ""}
                          fullWidth
                          InputProps={{
                            readOnly: true,
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          variant="outlined"
                        />
                      </Col>
                    </Row>

                    <Row>
                      <Col xs={12} md={6} className="userDetailsFormLabel">
                        {" "}
                        <TextField
                          id="unlockAt"
                          label="Unlock Time"
                          className=""
                          value={order.unlockAt ? moment.utc(order?.unlockAt).local().format(
                            datetime_format
                          ) : moment.utc(order?.startAt).local().format(
                            datetime_format
                          )}
                          fullWidth
                          InputProps={{
                            readOnly: true,
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          variant="outlined"
                        />
                      </Col>
                      <Col xs={12} md={6} className="userDetailsFormLabel">
                        {" "}
                        <TextField
                          id="lockAt"
                          label="Lock Time"
                          className=""
                          value={order.lockAt ? moment.utc(order?.lockAt).local().format(
                            datetime_format
                          ) : ""}
                          fullWidth
                          InputProps={{
                            readOnly: true,
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          variant="outlined"
                        />
                      </Col>
                    </Row>

                    <Row>
                      <Col xs={12} md={6} className="userDetailsFormLabel">
                        {" "}
                        <TextField
                          id="pauseCost"
                          label="Riding Cost"
                          className=""
                          value={order?.cost- order?.pauseCost}
                          fullWidth
                          InputProps={{
                            readOnly: true,
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          variant="outlined"
                        />
                      </Col>
                      <Col xs={12} md={6} className="userDetailsFormLabel">
                        {" "}
                        <TextField
                          id="ridingSeconds"
                          label="Riding Time (Seconds)"
                          className=""
                          value={order?.ridingSeconds}
                          fullWidth
                          InputProps={{
                            readOnly: true,
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          variant="outlined"
                        />
                      </Col>
                    </Row>

                    <Row>
                      <Col xs={12} md={6} className="userDetailsFormLabel">
                        {" "}
                        <TextField
                          id="pauseCost"
                          label="Pause Cost"
                          className=""
                          value={order?.pauseCost}
                          fullWidth
                          InputProps={{
                            readOnly: true,
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          variant="outlined"
                        />
                      </Col>
                      <Col xs={12} md={6} className="userDetailsFormLabel">
                        {" "}
                        <TextField
                          id="pauseSeconds"
                          label="Pause Time (Seconds)"
                          className=""
                          value={order?.pauseSeconds}
                          fullWidth
                          InputProps={{
                            readOnly: true,
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          variant="outlined"
                        />
                      </Col>
                    </Row>


                    <Row>
                      <Col xs={12} md={6} className="userDetailsFormLabel">
                        {" "}
                        <TextField
                          id="status"
                          label="Status"
                          className=""
                          value={order?.status}
                          fullWidth
                          InputProps={{
                            readOnly: true,
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          variant="outlined"
                        />
                      </Col>
                      <Col xs={12} md={6} className="userDetailsFormLabel">
                        {" "}
                        <TextField
                          id="isSuspicious"
                          label="Suspicious"
                          className=""
                          value={order?.isSuspicious}
                          fullWidth
                          InputProps={{
                            readOnly: true,
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          variant="outlined"
                        />
                      </Col>
                    </Row>

                    <Row>
                      <Col xs={12} md={6} className="userDetailsFormLabel">
                        {" "}
                        <TextField
                          id="cost"
                          label="Total Cost"
                          className=""
                          value={order?.cost}
                          fullWidth
                          InputProps={{
                            readOnly: true,
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          variant="outlined"
                        />
                      </Col>
                      <Col xs={12} md={6} className="userDetailsFormLabel">
                        {" "}
                        <TextField
                          id="discount"
                          label="Discount"
                          className=""
                          value={this.convertToPercent(order?.discount)}
                          fullWidth
                          InputProps={{
                            readOnly: true,
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          variant="outlined"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col className="userDetailsFormLabel">
                        {" "}
                        <TextField
                          id="feedback"
                          label="Feedback"
                          value={order?.userDescription}
                          fullWidth
                          multiline
                          style={{paddingTop: 3}}
                          InputProps={{
                            readOnly: true,
                            startAdornment: (
                              <InputAdornment position="start">
                                <Avatar aria-label="Feedback Icon" alt="Feedback Face Icon" src={feedbackIcon}
                                />
                              </InputAdornment>
                            ),
                          }}
                          variant="outlined"
                        />
                      </Col>
                    </Row>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={12} lg={4}>
            <TimePeriod order={order}/>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} lg={12}>
                <OrderMapComponent
                  key={`order-list-${order.uuid}`}
                  history={this.props.history}
                  fromOrderDetailsComponent={true}
                  filters={[{key: "orderUuid", value: `${order.uuid}`}]}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} lg={12}>
                <Card>
                  <CardHeader
                    title="Parking Picture"
                    titleTypographyProps={{variant: "h6"}}
                  />
                  {order.graphUrl && <CardContent>
                    <Grid container spacing={2}>
                      <Grid item md={12} lg={1}></Grid>
                      <Grid item md={12} lg={8}>
                        <CardMedia
                          component="img"
                          alt="Parking Picture"
                          image={order?.graphUrl}
                          title="Parking Picture"
                        />
                      </Grid>
                      <Grid item md={12} lg={2}>
                        <div
                          style={{
                            display: "flex",
                            width: "100%",
                            flexDirection: "column",
                            marginTop: "25%",
                          }}
                        >
                          <Tooltip
                            title="Good Parking"
                            style={{marginTop: "25%"}}
                          >
                            <IconButton onClick={() => this.onParkingGood(order.uuid)}>
                              <CheckCircleIcon
                                style={this.state.parkingGoodCSS}
                              />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Bad Parking">
                            <IconButton onClick={() => this.onParkingBad(order.uuid)}>
                              <CancelIcon style={this.state.parkingBadCSS}/>
                            </IconButton>
                          </Tooltip>
                        </div>
                      </Grid>
                      <Grid item md={12} lg={1}></Grid>
                    </Grid>
                  </CardContent>}
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  order: state.orderReducer.order,
});

const mapDispatchToProps = (dispatch) => ({
  getOrderByUuid: (uuid) => dispatch(getOrderByUuid(uuid)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withAuth0(OrderDetail));
