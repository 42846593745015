import React, { useEffect, useState } from "react";
import { withAuth0 } from "@auth0/auth0-react";
import {
  Avatar,
  Button,
  Dialog,
  DialogTitle,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  OutlinedInput,
  TextField,
  IconButton,
} from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";
import { Card, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import axios from "axios";
import { Formik } from "formik";
import * as Yup from "yup";
import { config } from "../../../auth/auth";
import { search as pricingList } from "../../../redux/pricing/action";
import { search as getCities } from "../../../redux/city/action";
import { determineVehicleIcon } from "../../../utils/utils";
import currencyList from "../../../data/currency-list.json";
import "./PricingStruture.css";

const host = process.env.REACT_APP_HOST;

const initialValues = {
  uuid: "",
  cityUuid: "",
  vehicleCategory: "",
  initialPrice: "",
  priceUnit: "",
  price: "",
  pausePrice:"",
  currency: "",
  freeMinutes:"",
};

const priceUnitList = [{ label: "MINUTES" }, { label: "HOURS" }];

const validationObj = Yup.object().shape({
  cityUuid: Yup.string().required(" "),
  vehicleCategory: Yup.string().required(" "),
  initialPrice: Yup.string()
    .required(" ")
    .matches(
      "^[0-9]+(.[0-9]{1,2})?$",
      "Must be a valid amount, exp: '12', '12.02', '12.3'"
    ),
  priceUnit: Yup.string().required(" "),
  price: Yup.string()
    .required(" ")
    .matches(
      "^[0-9]+(.[0-9]{1,2})?$",
      "Must be a valid amount, exp: '12', '12.02', '12.3'"
    ),
  pausePrice: Yup.string()
  .required(" ")
  .matches(
    "^[0-9]+(.[0-9]{1,2})?$",
    "Must be a valid amount, exp: '12', '12.02', '12.3'"
  ),
  currency: Yup.string().required(" "),
});

function PricingList({ pricingList, search, getCities, cities }) {
  const [pricingObj, setPricingObj] = useState({});
  const [open, setOpen] = useState(false);
  const [formValues, setFormValues] = useState(initialValues);
  const [vehicleTypeList, setVehicleTypeList] = useState([]);
  const [currentVehicle, setCurrentVehicle] = useState({});

  useEffect(() => {
    let params = new URLSearchParams();
    params.set("page", "0");
    params.set("size", "1000");
    !pricingList ? search(params) : createPricingObj();
    if (!cities) {
      getCities();
    }
  }, [pricingList, cities]);

  const createPricingObj = () => {
    let vehicleList = {};
    const pricingObj = pricingList?.content.reduce(function (obj, curr) {
      obj[curr.city.cityName] = obj[curr.city.cityName] || [];
      obj[curr.city.cityName].push(curr);
      vehicleList[curr.city.cityName] = vehicleList[curr.city.cityName] || [];
      if (
        vehicleList[curr.city.cityName].findIndex(
          (x) => x === curr.vehicleCategory
        ) === -1
      ) {
        vehicleList[curr.city.cityName].push(curr.vehicleCategory);
      }
      return obj;
    }, {});
    setPricingObj(pricingObj);
    setCurrentVehicle(vehicleList);
  };

  const openModal = (pricing) => {
    if (pricing && pricing.pausePrices) {
      if (pricing && pricing.prices.length>1) {
        setFormValues({
          ...pricing,
          cityUuid: pricing?.city.uuid,
          ...pricing?.prices[1],
          pausePrice: pricing.pausePrices[0]?.price,
          freeMinutes: pricing?.prices[0].time,
        });
      } else if (pricing && pricing.prices.length===1) {
        setFormValues({
          ...pricing,
          cityUuid: pricing?.city.uuid,
          pausePrice: pricing.pausePrices[0]?.price,
          ...pricing?.prices[0],
        });
      } 
      else {
        setFormValues(initialValues);
      }
    } else {
      if (pricing && pricing.prices.length>1) {
        setFormValues({
          ...pricing,
          cityUuid: pricing?.city.uuid,
          ...pricing?.prices[1],
          freeMinutes: pricing?.prices[0].time,
        });
      } else if (pricing && pricing.prices.length===1) {
        setFormValues({
          ...pricing,
          cityUuid: pricing?.city.uuid,
          ...pricing?.prices[0],
        });
      } 
      else {
        setFormValues(initialValues);
      }
    }
    setOpen(true);
  };

  const handleSubmit = (values) => {
    let method = "post";
    let url = `${host}/pricing/`;
    let body ={}
    if (values.freeMinutes && values.freeMinutes>0) {
      body = {
        cityUuid: values.cityUuid,
        currency: values.currency,
        initialPrice: values.initialPrice,
        minutesOfDailyMemberShip: 0,
        prices: [
          {
            price: 0,
            priceUnit: "MINUTES",
            time: values.freeMinutes,
            timeUnit: "MINUTES",
          },
          {
            price: values.price,
            priceUnit: values.priceUnit,
            time: null,
            timeUnit: null,
          },
        ],
        pausePrices: [
          {
            price: values.pausePrice,
            priceUnit: values.priceUnit,
            time: null,
            timeUnit: null,
          },
        ],
        vehicleCategory: values.vehicleCategory,
      };
    }
    else {
      body = {
        cityUuid: values.cityUuid,
        currency: values.currency,
        initialPrice: values.initialPrice,
        minutesOfDailyMemberShip: 0,
        prices: [
          {
            price: values.price,
            priceUnit: values.priceUnit,
            time: null,
            timeUnit: null,
          },
        ],
        pausePrices: [
          {
            price: values.pausePrice,
            priceUnit: values.priceUnit,
            time: null,
            timeUnit: null,
          },
        ],
        vehicleCategory: values.vehicleCategory,
      };
    }

    if (values.uuid) {
      method = "put";
      url = `${host}/pricing/${values.uuid}`;
    }

    return axios[method](url, body, config())
      .then((response) => search())
      .catch((e) => alert(e))
      .finally(() => {
        setOpen(false);
      });
  };

  return (
    <>
      <div className="heading-wrapper">
        {" "}
        <label className="headingLabel">Pricing Settings</label>
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={() => openModal()}
        >
          <AddCircleOutlineOutlinedIcon fontSize="small" />
        </IconButton>
      </div>
      {Object.keys(pricingObj).map((key, index) => (
        <Card className="PricingStruture" key={index}>
          <Card.Body>
            <Row>
              <Col>
                <label className="regionLabel">{key}</label>
              </Col>
            </Row>
            <div className="cards">
              {pricingObj[key].map((pricing, index) => (
                <div
                  className="VehicleCard"
                  onClick={() => openModal(pricing)}
                  key={index}
                >
                  <Row style={{ justifyContent: "center" }}>
                    <Avatar
                      className="vehicleIcon"
                      aria-label="recipe"
                      alt="Vehicle Photo"
                      src={determineVehicleIcon(pricing?.vehicleCategory)}
                      style={{
                        padding: 0,
                        height: "130px",
                        width: "130px",
                      }}
                    >
                      <span>{pricing?.vehicleCategory}</span>
                    </Avatar>
                  </Row>
                  <Row className="bikeLabel">{pricing.vehicleCategory}</Row>

                  <Row className="pricingFeeLabelOne">
                    <Col sm={7} style={{ fontSize: "15px" }}>
                      Activation Fee:
                    </Col>
                    <Col sm={5} className="pricingFee">
                      {pricing?.initialPrice} {pricing?.currency}
                    </Col>
                  </Row>
                  {pricing.prices.length > 1 ? 
                  <div>
                    <Row className="pricingFeeLabelTwo">
                      <Col sm={7} style={{ fontSize: "15px" }}>
                        Free Minutes:
                      </Col>
                      <Col sm={5} className="pricingFee">
                        {pricing?.prices.length ? pricing.prices[0]["time"] : 0}{" "}
                        Mintues
                      </Col>
                    </Row>
                    <Row className="pricingFeeLabelTwo">
                      <Col sm={7} style={{ fontSize: "15px" }}>
                        Unit Price:
                      </Col>
                      <Col sm={5} className="pricingFee">
                        {pricing?.prices.length ? pricing.prices[1]["price"] : 0}{" "}
                        {pricing?.currency}
                      </Col>
                    </Row>
                  </div>
                  :
                  <div>
                    <Row className="pricingFeeLabelTwo">
                      <Col sm={7} style={{ fontSize: "15px" }}>
                        Unit Price:
                      </Col>
                      <Col sm={5} className="pricingFee">
                        {pricing?.prices.length ? pricing.prices[0]["price"] : 0}{" "}
                        {pricing?.currency}
                      </Col>
                    </Row>
                  </div>}
                  {pricing.pausePrices ? 
                  <div>
                    <Row className="pricingFeeLabelTwo">
                      <Col sm={7} style={{ fontSize: "15px" }}>
                        Pause Unit Price:
                      </Col>
                      <Col sm={5} className="pricingFee">
                        {pricing?.pausePrices.length ? pricing.pausePrices[0]["price"] : 0}{" "}
                        {pricing?.currency}
                      </Col>
                    </Row>
                  </div>
                  :
                  <div>

                  </div>}
                </div>
              ))}
            </div>
          </Card.Body>
        </Card>
      ))}

      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="Pricing Action Modal"
        fullWidth={true}
      >
        <DialogTitle id="Pricing Action Modal" className="dialog-title">
          <label>{!formValues.uuid ? 'Add New Price' : 'Edit Price'}</label>
        </DialogTitle>
        <Formik
          initialValues={formValues}
          validationSchema={validationObj}
          onSubmit={(values) => handleSubmit(values)}
        >
          {(formik) => (
            <form
              noValidate
              autoComplete="off"
              onSubmit={formik.handleSubmit}
              onChange={formik.handleChange}
            >
              <div className="form-group">
                {!formik.values.uuid ? (
                  <div className="col-lg-12 form-field">
                    <FormControl variant="outlined" fullWidth={true}>
                      <InputLabel
                        error={
                          formik.errors.cityUuid && formik.touched.cityUuid
                        }
                        shrink
                      >
                        City
                      </InputLabel>
                      <Select
                        labelId="city-select-label"
                        id="city-select"
                        name="cityUuid"
                        value={formik.values.cityUuid ?? ""}
                        onChange={formik.handleChange}
                        label="City"
                        input={<OutlinedInput notched label="City" />}
                        onBlur={formik.handleBlur}
                        error={
                          formik.errors.cityUuid && formik.touched.cityUuid
                        }
                      >
                        {cities?.content.map(
                          (city, index) =>
                            city.vehiclesSupported?.length !==
                              currentVehicle[city.cityName]?.length && (
                              <MenuItem
                                key={index}
                                value={city.uuid}
                                onClick={() =>
                                  setVehicleTypeList(
                                    currentVehicle[city.cityName]
                                      ? city.vehiclesSupported.filter(
                                          (x) =>
                                            !currentVehicle[
                                              city.cityName
                                            ].includes(x)
                                        )
                                      : city.vehiclesSupported
                                  )
                                }
                              >
                                {city.cityName.toUpperCase()}
                              </MenuItem>
                            )
                        )}
                      </Select>
                    </FormControl>
                    {formik.errors.cityUuid && formik.touched.cityUuid ? (
                      <small className="c-red">{formik.errors.cityUuid}</small>
                    ) : null}
                  </div>
                ) : (
                  ""
                )}
                {!formik.values.uuid ? (
                  <div className="col-lg-12 form-field">
                    <FormControl variant="outlined" fullWidth={true}>
                      <InputLabel
                        error={
                          formik.errors.vehicleCategory &&
                          formik.touched.vehicleCategory
                        }
                        shrink
                      >
                        Vehicle
                      </InputLabel>
                      <Select
                        labelId="vehicle-select-label"
                        id="vehicle-select"
                        name="vehicleCategory"
                        value={formik.values.vehicleCategory ?? ""}
                        onChange={formik.handleChange}
                        label="Vehicle"
                        input={<OutlinedInput notched label="Vehicle" />}
                        onBlur={formik.handleBlur}
                        error={
                          formik.errors.vehicleCategory &&
                          formik.touched.vehicleCategory
                        }
                      >
                        {vehicleTypeList.length > 0 ? (
                          vehicleTypeList.map((vehicle, index) => (
                            <MenuItem key={index} value={vehicle}>
                              {vehicle}
                            </MenuItem>
                          ))
                        ) : (
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                        )}
                      </Select>
                    </FormControl>
                    {formik.errors.vehicleCategory &&
                    formik.touched.vehicleCategory ? (
                      <small className="c-red">
                        {formik.errors.vehicleCategory}
                      </small>
                    ) : null}
                  </div>
                ) : (
                  ""
                )}
                <div className="col-lg-12 form-field">
                  <TextField
                    id="initialPrice-id"
                    label="Activation Fee"
                    fullWidth
                    type="test"
                    name="initialPrice"
                    value={formik.values.initialPrice ?? ""}
                    onChange={formik.handleChange}
                    InputProps={{
                      readOnly: false,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                    error={
                      formik.errors.initialPrice && formik.touched.initialPrice
                    }
                  />
                  {formik.errors.initialPrice && formik.touched.initialPrice ? (
                    <small className="c-red">
                      {formik.errors.initialPrice}
                    </small>
                  ) : null}
                </div>
                <div className="col-lg-12 form-field">
                  <TextField
                    id="freeMinutes-id"
                    label="Free Minutes"
                    fullWidth
                    type="number"
                    name="freeMinutes"
                    value={formik.values.freeMinutes ?? ""}
                    onChange={formik.handleChange}
                    InputProps={{
                      readOnly: false,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                    error={
                      formik.errors.freeMinutes && formik.touched.freeMinutes
                    }
                  />
                  {formik.errors.freeMinutes && formik.touched.freeMinutes ? (
                    <small className="c-red">
                      {formik.errors.freeMinutes}
                    </small>
                  ) : null}
                </div>
                <div className="col-lg-12 form-field">
                  <FormControl variant="outlined" fullWidth={true}>
                    <InputLabel
                      error={
                        formik.errors.priceUnit && formik.touched.priceUnit
                      }
                      shrink
                    >
                      Billing Cycle
                    </InputLabel>
                    <Select
                      labelId="priceUnit-label"
                      id="priceUnit"
                      name="priceUnit"
                      value={formik.values.priceUnit ?? ""}
                      onChange={formik.handleChange}
                      label="Billing Cycle (Minutes)"
                      input={
                        <OutlinedInput
                          notched
                          label="Billing Cycle (Minutes)"
                        />
                      }
                      onBlur={formik.handleBlur}
                      error={
                        formik.errors.priceUnit && formik.touched.priceUnit
                      }
                    >
                      {priceUnitList.map((priceUnit, index) => (
                        <MenuItem key={index} value={priceUnit.label}>
                          {priceUnit.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {formik.errors.priceUnit && formik.touched.priceUnit ? (
                    <small className="c-red">{formik.errors.priceUnit}</small>
                  ) : null}
                </div>
                <div className="col-lg-12 form-field">
                  <TextField
                    id="price-id"
                    label="Unit Price"
                    fullWidth
                    type="test"
                    name="price"
                    value={formik.values.price ?? ""}
                    onChange={formik.handleChange}
                    InputProps={{
                      readOnly: false,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                    error={formik.errors.price && formik.touched.price}
                  />
                  {formik.errors.price && formik.touched.price ? (
                    <small className="c-red">{formik.errors.price}</small>
                  ) : null}
                </div>
                <div className="col-lg-12 form-field">
                  <TextField
                    id="pause-price-id"
                    label="Pause Unit Price"
                    fullWidth
                    type="test"
                    name="pausePrice"
                    value={formik.values.pausePrice ?? ""}
                    onChange={formik.handleChange}
                    InputProps={{
                      readOnly: false,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                    error={formik.errors.pausePrice && formik.touched.pausePrice}
                  />
                  {formik.errors.pausePrice && formik.touched.pausePrice ? (
                    <small className="c-red">{formik.errors.pausePrice}</small>
                  ) : null}
                </div>
                <div className="col-lg-12 form-field">
                  <FormControl variant="outlined" fullWidth={true}>
                    <InputLabel
                      error={formik.errors.currency && formik.touched.currency}
                      shrink
                    >
                      Currency
                    </InputLabel>
                    <Select
                      labelId="currency-label"
                      id="currency"
                      name="currency"
                      value={formik.values.currency ?? ""}
                      onChange={formik.handleChange}
                      label="currency"
                      input={<OutlinedInput notched label="currency" />}
                      onBlur={formik.handleBlur}
                      error={formik.errors.currency && formik.touched.currency}
                    >
                      {currencyList.map((currency, index) => (
                        <MenuItem key={index} value={currency.name}>
                          {currency.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {formik.errors.currency && formik.touched.currency ? (
                    <small className="c-red">{formik.errors.currency}</small>
                  ) : null}
                </div>
              </div>
              <div className="action-buttons">
                <Button variant="contained" color="primary" type="submit">
                  Save
                </Button>
                <Button
                  onClick={() => {
                    setOpen(false);
                  }}
                  style={{ color: grey[500] }}
                >
                  Cancel
                </Button>
              </div>
            </form>
          )}
        </Formik>
      </Dialog>
    </>
  );
}

const mapStateToProps = (state) => ({
  pricingList: state.pricingReducer.pricings,
  cities: state.cityReducer.cities,
});

const mapDispatchToProps = (dispatch) => ({
  search: (params) => dispatch(pricingList(params)),
  getCities: (params) => dispatch(getCities(params)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withAuth0(PricingList));
