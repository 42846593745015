import {TaskActionTypes} from "./task.types";

const INITIAL_STATE = {
  tasks: [],
  task: {},
  unfinishedTasks: undefined,
  finishedTasksToday: undefined,
  successfulTasksToday: undefined
};

const taskReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TaskActionTypes.SET_TASKS:
      return {
        ...state,
        tasks: action.payload,
      };
    case TaskActionTypes.SET_TASK:
      return {
        ...state,
        task: action.payload,
      };
    case TaskActionTypes.SET_FINISHED_TASKS_TODAY:
      return {
        ...state,
        finishedTasksToday: action.payload,
      };
    case TaskActionTypes.SET_UNFINISHED_TASK:
      return {
        ...state,
        unfinishedTasks: action.payload,
      };
    case TaskActionTypes.SET_SUCCESSFUL_TASKS_TODAY:
      return {
        ...state,
        successfulTasksToday: action.payload,
      };
    default:
      return state;
  }
};

export default taskReducer;