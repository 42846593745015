import React, {Component} from 'react'
import ReactEcharts from 'echarts-for-react'


export default class UserSatisfactionChart extends Component {
    
    getOption = ()=>{
        const {date} = this.props
        const {badFeedback} = this.props
        const {goodFeedback} = this.props
        const {excellentFeedback} = this.props
        const {label} = this.props
        const {yAxisLabel} = this.props

        let option = {
            xAxis: {
                type: 'category',
                boundaryGap: false,
                data: date,
                axisLabel: {
                    show: true,
                    textStyle: {
                        fontFamily: 'Urbanist'
                    }
                }
            },
            grid: {
                containLabel: true
            },
            legend: {
                type: 'plain',
                data: [
                  {name: 'Disappointed'},
                  {name: 'Mutual'},
                  {name: 'Happy'},
                ],
                orient: "horizontal",
              },
            yAxis: {
                type: 'value',
                axisLabel: {
                    formatter: function (value, index) {
                        if(yAxisLabel) {
                            return value + ' ' + yAxisLabel;
                        } else {
                            return value ;
                        }
                        
                    },
                    textStyle: {
                        fontFamily: 'Urbanist'
                    }
                }
            },
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                  type: 'cross',
                  crossStyle: {
                    color: '#333399'
                  }
                }
              },
            series: [
            {
                data: badFeedback,
                name: 'Disappointed',
                type: 'line',
                smooth: true,
                formatter: '{c0} ' + label,
                label: {
                    show: true,
                },
                textStyle: {
                    fontFamily: 'Urbanist'
                }
            },
            {
                data: goodFeedback,
                name: 'Mutual',
                type: 'line',
                smooth: true,
                formatter: '{c0} ' + label,
                label: {
                    show: true,
                },
                textStyle: {
                    fontFamily: 'Urbanist'
                }
            },
            {
                data: excellentFeedback,
                name: 'Happy',
                type: 'line',
                smooth: true,
                formatter: '{c0} ' + label,
                label: {
                    show: true,
                },
                textStyle: {
                    fontFamily: 'Urbanist'
                }
            }
        ]
        };
        return option;
    }
    render() {
        return (
            <ReactEcharts style={{width:'100%',height:'50vh'}} option={this.getOption()}/>
        )
    }
}

//https://echarts.apache.org/examples/zh/index.html