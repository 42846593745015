import React, {Component} from "react";
import {withAuth0} from "@auth0/auth0-react";
import {Fab, Tooltip} from "@material-ui/core";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import cyan from '@material-ui/core/colors/cyan';
import PropTypes from "prop-types";

const main_website_url = process.env.REACT_APP_MAIN_WEB_SITE;
const auth0_client_id = process.env.REACT_APP_AUTH0_CLIENT_ID;

class AccessDenied extends Component {
  static propTypes = {
    allowLogout: PropTypes.bool
  };

  render() {
    const {logout} = this.props.auth0;
    const { allowLogout } = this.props;

    return (
      <div className="container-fluid">
        <div className="text-center">
          <div className="error mx-auto" data-text="403">403</div>
          <p className="lead text-gray-800 mb-5">Access Denied</p>
          <p className="text-gray-500 mb-0">Please contact our Sales to unlock this page</p>
          <br/>
          {allowLogout &&
          <Tooltip title="Logout">
            <Fab style={{ background: cyan[500], color: "white" }} aria-label="Logout" onClick={() => logout({returnTo:main_website_url, client_id: auth0_client_id})}>
              <ExitToAppIcon />
            </Fab>
          </Tooltip>}

        </div>
      </div>
    )
  }
}
export default withAuth0(AccessDenied);