import {getAccessToken} from "../../auth/auth";
import axios from "axios";
import {ActionTypes} from "./topUpTemplate.types";

const host = process.env.REACT_APP_HOST

export const search = (queryParams) => {
  const config = {
    headers: {
      'Authorization': `Bearer ${getAccessToken()}`,
      'Content-Type': 'application/json'
    },
    params: queryParams
  }

  return async dispatch => {
    return axios.get(`${host}/topUpTemplate`, config)
      .then(
        response => dispatch(setTopUpTemplates(response.data)),
        err => console.log(err)
      );
  }
}

export const setTopUpTemplates = (payload) => (
    {
      type: ActionTypes.SET_TOP_UP_TEMPLATES,
      payload: payload,
    });
