import React, {Component} from "react";
import MUIDataTable from "mui-datatables";
import {withAuth0} from "@auth0/auth0-react";
import {Button, Tooltip} from "@material-ui/core";
import {connect} from "react-redux";
import moment from "moment";
import {search} from "../../../redux/voucher/action";
import {search as searchCities} from "../../../redux/city/action";
import {queryParams} from "../../../utils/utils";
import Status from "../../../components/common/Status";
import VoucherUserList from "./VoucherUserList";
import VoucherBatchCreate from "./VoucherBatchCreate";
import VoucherCreateModal from "./VoucherCreate/VoucherCreateModal";


const date_format = process.env.REACT_APP_DATE_FORMAT;
const datetime_format = process.env.REACT_APP_DATETIME_FORMAT;

class Voucher extends Component {
  constructor(props) {
    super(props);
    this.isFilterDialogOpen = false;
    this.tableRef = React.createRef();
    this.columnsDisplay = undefined;
  }

  componentDidMount() {
    this.refresh();
  }

  refresh = () => {
    const {search, searchCities} = this.props;

    // table state
    const state = this.tableRef?.current?.state;

    const params = queryParams(this.props, state);
    if (params.get("sort").includes('id')) {
      params.set("sort", params.get("sort").replace("id", "createdAt"));
    }

    search(params);
    searchCities();
  };

  onChangeColumnDisplay = () => {
    const state = this.tableRef?.current?.state;
    this.columnsDisplay = state.columns.reduce(
      (obj, item) => Object.assign(obj, {[item.name]: item.display}),
      {}
    );
  };

  isDisplay = (name) => {
    if (!this.columnsDisplay || !this.columnsDisplay.hasOwnProperty(name))
      return undefined;
    else return this.columnsDisplay[name];
  };

  displayVoucherType = (voucherType) => {
    switch (voucherType) {
      case "ORDER":
        return "Ride discount";
      case "TOPUP_TEMPLATE":
        return "Topup discount";
      default:
        return "Normal"
    }

  }

  render() {
    const {vouchers, cities} = this.props;
    if (!vouchers) return <React.Fragment>Loading...</React.Fragment>;

    const list = vouchers?.content;
    const totalElements = vouchers?.totalElements ?? 0;
    const rowsPerPage = vouchers?.pageable?.pageSize < 501 ? vouchers?.pageable?.pageSize : 10;
    const page = vouchers?.pageable?.pageNumber ?? 0;

    const cityList = [];
    cities?.content.map(c => cityList.push({
      label: c.cityName,
      description: c.cityName,
      value: c.uuid
    }));

    const columns = [
      {
        name: "uuid",
        label: "UUID",
        options: {
          filter: false,
          sort: false,
          display: this.isDisplay("uuid") ?? false,
          customFilterListOptions: {render: (v) => `UUID: ${v}`},
          filterType: "textField",
        },
      },
      {
        name: "discountType",
        label: "Discount Type",
        options: {
          filter: false,
          sort: false,
          display: false,
        },
      },
      {
        name: "code",
        label: "Voucher Code",
        options: {
          filter: true,
          sort: true,
          filterType: "textField",
          display: this.isDisplay("code") ?? true,
          customFilterListOptions: {render: (v) => `Voucher Code: ${v}`},
          filterOptions: {
            fullWidth: true,
          },
        },
      },
      {
        name: "amount",
        label: "Amount",
        options: {
          filter: false,
          sort: false,
          display: this.isDisplay("amount") ?? true,
          customBodyRender: (value, tableMeta, updateValue) => {
            const display = tableMeta.rowData[7] === "DISCOUNT" ? ((+tableMeta.rowData[12] * 100) + '%') : value;
            return (
              <Tooltip title={value}>
                <div>{display}</div>
              </Tooltip>
            );
          },
        },
      },
      {
        name: "startAt",
        label: "Start Time",
        options: {
          filter: false,
          sort: false,
          display: this.isDisplay("startAt") ?? false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <Tooltip
                title={value ? moment.utc(value).local().format(datetime_format) : ""}
              >
                <div>{value ? moment.utc(value).local().format(date_format) : ""}</div>
              </Tooltip>
            );
          },
        },
      },
      {
        name: "endAt",
        label: "End Time",
        options: {
          filter: false,
          sort: false,
          display: this.isDisplay("endAt") ?? false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <Tooltip
                title={value ? moment.utc(value).local().format(datetime_format) : ""}
              >
                <div>{value ? moment.utc(value).local().format(date_format) : ""}</div>
              </Tooltip>
            );
          },
        },
      },
      {
        name: "validTime",
        label: "Valid Time",
        options: {
          filter: false,
          sort: false,
          display: this.isDisplay("validTime") ?? true,
          customBodyRender: (value, tableMeta, updateValue) => {
            const startAt = tableMeta?.rowData[4]
              ? moment.utc(tableMeta?.rowData[4]).local().format(datetime_format)
              : "";
            const endAt = tableMeta?.rowData[5]
              ? moment.utc(tableMeta?.rowData[5]).local().format(datetime_format)
              : "";
            return (
              <Tooltip title="Valid Time">
                <div>{`${startAt} ~ ${endAt}`}</div>
              </Tooltip>
            );
          },
        },
      },
      {
        name: "type",
        label: "Voucher Type",
        options: {
          filter: false,
          sort: false,
          display: this.isDisplay("type") ?? true,
          customBodyRender: (value, tableMeta, updateValue) => {
            const display = value === "DISCOUNT" ? this.displayVoucherType(tableMeta.rowData[1]) : this.displayVoucherType(value);
            return (
              < Tooltip title={value}>
                <div>{display}</div>
              </Tooltip>
            );
          },
        },
      },
      {
        name: "count",
        label: "Usage Times",
        options: {
          filter: false,
          sort: false,
          display: this.isDisplay("count") ?? true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <Tooltip title={value}>
                <div>{value}</div>
              </Tooltip>
            );
          },
        },
      },
      {
        name: "quantity",
        label: "Valid Usage Times",
        options: {
          filter: false,
          sort: false,
          display: this.isDisplay("quantity") ?? true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <Tooltip title={value}>
                <div>{value}</div>
              </Tooltip>
            );
          },
        },
      },
      {
        name: "createdAt",
        label: "Created At",
        options: {
          filter: false,
          sort: true,
          display: this.isDisplay("createdAt") ?? true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <Tooltip title={value}>
                <div>{moment(value).format(datetime_format)}</div>
              </Tooltip>
            );
          },
        },
      },
      {
        name: "status",
        label: "Status",
        options: {
          filter: false,
          sort: false,
          display: this.isDisplay("status") ?? true,
          customBodyRender: (value, tableMeta, updateValue) => {
            const uuid = tableMeta.rowData[0];
            return (
              <Status
                checked={value === "ACTIVE" || value === "active"}
                activeUrl={`/voucher/${uuid}/active`}
                inactiveUrl={`/voucher/${uuid}/inactive`}
                onChanged={() => this.refresh()}
              />
            );
          },
        },
      },
      {
        name: "discountValue",
        label: "Discount Value",
        options: {
          filter: false,
          sort: false,
          display: false,
        },
      },
      {
        name: "Operations",
        options: {
          filter: false,
          sort: false,
          empty: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div>
                <VoucherUserList
                  uuid={tableMeta.rowData[0]}
                ></VoucherUserList>
                {/*<Delete
                  url={`/voucher/${tableMeta.rowData[0]}`}
                  callback={() => this.refresh()}
                />*/}
              </div>
            );
          },
        },
      },
    ];

    const options = {
      filter: true,
      confirmFilters: true,
      serverSide: true,
      filterType: "dropdown",
      responsive: "vertical",
      rowsPerPageOptions: [10, 20, 50, 100, 250, 500],
      rowsPerPage: rowsPerPage,
      count: totalElements,
      page: page,
      search: false,
      fixedHeader: true,
      tableBodyHeight: "900px",
      customToolbar: () => {
        return (
          <>
            <VoucherBatchCreate callback={() => this.refresh()} cities={cityList}/>
            <VoucherCreateModal callback={() => this.refresh()} cities={cityList}/>
          </>

        );
      },
      onTableChange: (action, tableState) => {
        switch (action) {
          case "viewColumnsChange":
            this.onChangeColumnDisplay();
            break;
          case "onFilterDialogOpen":
            this.isFilterDialogOpen = true;
            break;
          case "filterChange":
            if (this.isFilterDialogOpen) return;
            else this.refresh();
            break;
          case "onFilterDialogClose":
            this.isFilterDialogOpen = false;
          case "changePage":
          case "sort":
          case "changeRowsPerPage":
            this.refresh();
            break;
          default:
            console.log("Not handled action " + action);
        }
      },
      customFilterDialogFooter: (currentFilterList, applyNewFilters) => {
        return (
          <div style={{marginTop: "40px"}}>
            <Button
              variant="contained"
              style={{color: "white", backgroundColor: "#333399"}}
              onClick={() => {
                applyNewFilters();
              }}
            >
              Apply
            </Button>
          </div>
        );
      },
    };

    return (
      <React.Fragment>
        <MUIDataTable
          title={<h1 className="tableTitle">Voucher List & Discounts</h1>}
          data={list}
          columns={columns}
          options={options}
          ref={this.tableRef}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  vouchers: state.voucherReducer.vouchers,
  cities: state.cityReducer.cities
});

const mapDispatchToProps = (dispatch) => ({
  search: (params) => dispatch(search(params)),
  searchCities: (params) => dispatch(searchCities(params)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withAuth0(Voucher));
