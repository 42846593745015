import React from "react";
import PropTypes from 'prop-types';
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Card from "@material-ui/core/Card";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';
import Delete from "../../../../components/common/Delete";
import {fullName} from "../../../../utils/utils";
import {getUsers} from "../../../../redux/role/role.action";
import {connect} from "react-redux";
import {withAuth0} from "@auth0/auth0-react";
import RefreshIcon from '@material-ui/icons/Refresh';
import {IconButton, Tooltip} from "@material-ui/core";
import UserSelector from "./user-selector";

class RoleUsers extends React.Component {

  constructor(props) {
    super(props);
  }

  static propTypes = {
    role: PropTypes.any.isRequired,
    users: PropTypes.any.isRequired
  };

  refresh = () => {
    this.props.getUsers(this.props.role?.uuid);
  }

  render() {
    const {users, role} = this.props;
    if (!users || !role)
      return (<React.Fragment>Loading...</React.Fragment>);

    return (
      <Card>
        <CardHeader title="Users"
                    className="primary"
                    action={
                      <IconButton aria-label="settings" onClick={this.refresh}>
                        <RefreshIcon />
                      </IconButton>
                    }
        />
        <CardContent>
          <List>
            {users?.map((user, index) => {
              return (
                <React.Fragment>
                  <ListItem key={`role-users-${user.uuid}-key`}>
                    <ListItemAvatar>
                      <Avatar
                        aria-label="avatar"
                        alt="avatar"
                        src={user?.avatar}
                      >
                        {`${user?.firstName?.charAt(0).toUpperCase()}${user?.lastName?.charAt(0).toUpperCase()}`}
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={`${fullName(user)}`} secondary={`${user?.email}`}/>
                    <CardActions disableSpacing>
                      <Delete url={`/role/${role.uuid}/user/${user?.uuid}`} callback={this.refresh}/>
                    </CardActions>
                  </ListItem>
                  {(index !== users?.length - 1) && <Divider variant="inset" component="li"/>}
                </React.Fragment>
              )
            })}
            {(!users || users?.length === 0) ?? <div> No data</div>}
          </List>
        </CardContent>
      </Card>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  getUsers: (params) => dispatch(getUsers(params))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withAuth0(RoleUsers));
