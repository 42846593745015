import React, {Component} from "react";
import {withAuth0} from "@auth0/auth0-react";
import {
  getDrivingLicenceVerifingUsers,
  getDrivingLicense,
  validateDrivingLicence,
} from "../../../../redux/user/user.action";
import {connect} from "react-redux";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "@material-ui/core/Button";
import Card from "react-bootstrap/Card";
import Modal from "react-bootstrap/Modal";
import "../UserDetails/details.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import {faChevronLeft, faChevronRight,} from "@fortawesome/free-solid-svg-icons";
import {IconButton} from "@material-ui/core";

class DrivingLicense extends Component {
  constructor(props) {
    super(props);
    this.verifingUsers = [];
    this.selectedIndex = 0;
    this.confirmModalText = "";
    this.selectedAction = "";
    this.state = {
      showConfirmModal: false,
    };
  }

  componentDidMount() {
    const { LicensePath, users } = this.props;
    if (LicensePath) {
      if (users.content.length) {
        this.verifingUsers = users.content.filter((element) => {
          return element.drivingLicenceStatus !== "NO_UPLOAD";
        });
      }
      this.props.getDrivingLicense(LicensePath);
    } else {
      this.getVerifingUsers();
    }
  }

  getDrivingLicencePage(user) {
    if (user) {
      const apiPath = "/user/" + user.uuid + "/drivingLicence";
      this.props.getDrivingLicense(apiPath);
    }
  }

  getVerifingUsers() {
    const { LicensePath } = this.props;
    if (LicensePath) {
      if (this.verifingUsers.length) {
        this.selectedIndex = this.selectedIndex + 1;
        this.getDrivingLicencePage(this.verifingUsers[this.selectedIndex]);
      } else {
        this.props.getDrivingLicense(null);
      }
    } else {
      this.selectedIndex = 0;
      let params = new URLSearchParams("?drivingLicenceStatus=VERIFYING");
      getDrivingLicenceVerifingUsers(params).then((res) => {
        this.verifingUsers = res.data.content;
        if (this.verifingUsers.length) {
          this.getDrivingLicencePage(this.verifingUsers[this.selectedIndex]);
        } else {
          this.props.getDrivingLicense(null);
        }
      });
    }
  }

  onCloseConfirmModal = () => {
    this.setState({ showConfirmModal: false });
  };
  onConfirm = () => {
    const { drivingLicence } = this.props;
    if (this.selectedAction === "VALID") {
      validateDrivingLicence(`/drivingLicence/${drivingLicence.uuid}/approve`)
        .then((res) => {
          if (res.status === 200) {
            this.getVerifingUsers();
            this.setState({ showConfirmModal: false });
          } else {
            alert(res.error);
          }
        })
        .catch((error, response) => {
          this.setState({ showConfirmModal: false });
          alert(error.response.data.error);
        });
    } else {
      validateDrivingLicence(`/drivingLicence/${drivingLicence.uuid}/reject`)
        .then((res) => {
          if (res.status === 200) {
            this.getVerifingUsers();
            this.setState({ showConfirmModal: false });
          } else {
            alert(res.error);
          }
        })
        .catch((error) => {
          this.setState({ showConfirmModal: false });
          alert(error.response.data.error);
        });
    }
  };
  onSelectNextUser(index) {
    const user = this.verifingUsers[index];
    this.getDrivingLicencePage(user);
  }
  render() {
    const { drivingLicence } = this.props;
    const imagUrls = drivingLicence.imageUrls || [];
    const userName = drivingLicence.username;
    const chervonStyle = {
      position: "absolute",
      top: "40%",
      left: "20%",
    };

    if (!drivingLicence?.userUuid) {
      return <React.Fragment>No records found for verification</React.Fragment>;
    }
    return (
      <React.Fragment>
        <Card className="shadow">
          <Card.Header className="text-center">
            <h3>{userName}</h3>
          </Card.Header>
          <Card.Body style={{ padding: "50px 0px 50px 0px" }}>
            <Row>
              <Col xs={3} md={1}>
                <IconButton
                  aria-label="Left"
                  style={chervonStyle}
                  onClick={() => {
                    if (this.selectedIndex >= 1) {
                      this.selectedIndex = this.selectedIndex - 1;
                      this.onSelectNextUser(this.selectedIndex);
                    }
                  }}
                >
                  <FontAwesomeIcon icon={faChevronLeft} />
                </IconButton>
              </Col>
              {imagUrls.map((imageSrc) => {
                return (
                  <Col xs={3} sm={6} md={2} style={{ padding: "0px" }}>
                    <Card.Img variant="top" src={imageSrc} />
                  </Col>
                );
              })}
              <Col xs={3} md={1}>
                <IconButton
                  aria-label="Right"
                  style={chervonStyle}
                  disabled={
                    this.selectedIndex === this.verifingUsers.length - 1
                  }
                  onClick={() => {
                    if (
                      this.selectedIndex >= 0 &&
                      this.selectedIndex < this.verifingUsers.length
                    ) {
                      this.selectedIndex = this.selectedIndex + 1;
                      this.onSelectNextUser(this.selectedIndex);
                    }
                  }}
                >
                  <FontAwesomeIcon icon={faChevronRight} />
                </IconButton>
              </Col>
            </Row>
          </Card.Body>
          <Card.Footer className="text-center">
            {" "}
            <Button
              variant="contained"
              style={{ marginRight: "10px" }}
              color="primary"
              disableElevation
              onClick={() => {
                this.selectedAction = "VALID";
                this.confirmModalText =
                  "Are you sure to verify the driving license?";
                this.setState({
                  showConfirmModal: true,
                });
              }}
            >
              Valid
            </Button>
            <Button
              variant="contained"
              color="primary"
              disableElevation
              onClick={() => {
                this.selectedAction = "INVALID";
                this.confirmModalText = "Are you sure to reject the document?";
                this.setState({
                  showConfirmModal: true,
                });
              }}
            >
              Not Valid
            </Button>
          </Card.Footer>
        </Card>

        <Modal
          show={this.state.showConfirmModal}
          onHide={() => this.onCloseConfirmModal()}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Confirm</Modal.Title>
          </Modal.Header>
          <Modal.Body>{this.confirmModalText}</Modal.Body>
          <Modal.Footer className="text-right">
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={() => this.onConfirm()}
            >
              Yes
            </Button>
            <Button
              size="small"
              variant="secondary"
              onClick={() => this.onCloseConfirmModal()}
            >
              No
            </Button>
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  drivingLicence: state.usersData.drivingLicence,
  users: state.usersData.users,
});
const mapDispatchToProps = (dispatch) => ({
  getDrivingLicense: (path) => dispatch(getDrivingLicense(path)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withAuth0(DrivingLicense));
