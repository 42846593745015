import React from "react";
import PropTypes from "prop-types";
import {Button, Dialog, DialogActions, DialogTitle, Grid,} from "@material-ui/core";

import axios from "axios";
import {config} from "../../../../auth/auth";
import {grey} from "@material-ui/core/colors";
import CardContent from "@material-ui/core/CardContent";
import NotificationSelector from '../../../InformationSettings/NotificationList/NotificationSelector'

const host = process.env.REACT_APP_HOST

class RegionRuleNotificationModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            messageTemplateUuid: props.data?.messageTemplateUuid,
            open: this.props.open,
        };
    }

    static propTypes = {
        callback: PropTypes.func.isRequired,
        open: PropTypes.bool.isRequired,
        regionRuleUuid: PropTypes.string.isRequired
    };

    cancel = () => {
        this.setState({
            open: false,
            messageTemplateUuid: ""
        });
        this.props.callback(false);
    };

    confirm = (callback) => {
        if (this.state.uuid)
            this.updateNotification(callback);
        else
            this.createNewNotification(callback);
    };

    createNewNotification = (callback) => {
        const body = {
            regionRuleUuid: this.props.regionRuleUuid,
            messageTemplateUuid: this.state.messageTemplateUuid
        };
        return axios
            .post(`${host}/regionRuleNotification/`, body, config())
            .then((response) => callback(true))
            .catch((e) => alert(e))
            .finally(() => {
                this.setState({
                    open: false,
                    messageTemplateUuid: ""
                });
            });
    };

    updateNotification = (callback) => {
        const body = {
            messageTemplateUuid: this.state.messageTemplateUuid
        };
        return axios
            .put(`${host}/regionRuleNotification/${this.state.uuid}`, body, config())
            .then((response) => callback(true))
            .catch((e) => alert(e))
            .finally(() => {
                this.setState({
                    open: false,
                    messageTemplateUuid: ""
                });
            });
    };

    onChangeDelay = (e) => {
        this.setState({ timeDelay: e.target.value });
    };
    onChangeAction = (e) => {
        this.setState({ action: e.target.value });
    };

    onChangeMessageTemplate = (messageTemplateUuid) => {
        this.setState({ messageTemplateUuid: messageTemplateUuid });
    };

    // end

    componentDidUpdate(prevProps) {
        if (this.props?.open !== prevProps.open) {
            this.setState({
                 open: this.props?.open,
                 uuid: this.props?.data?.uuid,
                 messageTemplateUuid: this.props?.data?.messageTemplateUuid
            });
        }
    }
    render() {
        const { callback } = this.props;
        if (!callback) return <React.Fragment />;
        return (
            <React.Fragment>
                <Dialog
                    open={this.state.open}
                    onClose={this.handleClose}
                    aria-labelledby="Region Rule Notification Modal"
                    fullWidth={true}
                >
                    <DialogTitle
                        id="Region Rule Notification Modal"
                        style={{
                            color: "white",
                            backgroundColor: "#36b9cc",
                            textAlign: "center",
                        }}
                    >
                        <label>Region Rule Notification</label>
                    </DialogTitle>
                    <Grid container spacing={2}>
                        <Grid item sm={12} xs={12} md={12} lg={12}>
                            <CardContent style={{ paddingBottom: "0px" }}>

                                <NotificationSelector 
                                    onSelectChanged={this.onChangeMessageTemplate} 
                                    value={this.state.messageTemplateUuid}/>

                            </CardContent>
                        </Grid>
                    </Grid>

                    <DialogActions
                        style={{
                            justifyContent: "space-between",
                            padding: "10px 40px 15px 40px",
                        }}
                    >
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => this.confirm(callback)}
                        >
                            Save
                        </Button>
                        <Button onClick={this.cancel} style={{ color: grey[500] }}>
                            Cancel
                        </Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment >
        );
    }
}
export default RegionRuleNotificationModal;
