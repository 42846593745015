import React from "react";
import PropTypes from "prop-types";
import {Button, Dialog, DialogActions, DialogTitle, Grid,} from "@material-ui/core";

import axios from "axios";
import {config} from "../../../../auth/auth";
import {grey} from "@material-ui/core/colors";
import CardContent from "@material-ui/core/CardContent";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";

const host = process.env.REACT_APP_HOST

class RegionEditModel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      regionName: undefined,
      category: undefined,
      uuid: this.props.tableMeta?.rowData[0],
      speedLimit: undefined,
      status: undefined,
      open: false,
    };
  }

  static propTypes = {
    value: PropTypes.object,
    tableMeta: PropTypes.object.isRequired,
    callback: PropTypes.func.isRequired
  };

  handleClick = () => {
    this.setState({open: true});
  }

  cancel = () => {
    this.setState({open: false});
  };

  confirm = (callback) => {
    if (this.state.isLoading)
      return;
    const body = {
      regionName: this.state.regionName ?? this.props?.tableMeta?.rowData[1],
      category: this.state.category ?? this.props?.tableMeta?.rowData[3],
      speedLimit: this.state.speedLimit ?? this.props?.tableMeta?.rowData[5],
      status: this.state.status ?? this.props?.tableMeta?.rowData[4],
      discount: 0
    };
    return axios
      .put(`${host}/region/${this.state.uuid}`, body, config())
      .then((response) => callback())
      .catch((e) => alert(e))
      .finally(() => {
        this.setState({
          isLoading: false, 
          open: false
        });
        callback();
      });
  };

  onChangeRegionName = (e) => {
    this.setState({ regionName: e.target.value });
  };
  onChangeCategory = (e) => {
    this.setState({ category: e.target.value });
  };
  onChangeSpeedLimit = (e) => {
    this.setState({ speedLimit: e.target.value });
  };
  onChangeStatus = (e) => {
    this.setState({ status: e.target.value });
  };

  // end

  render() {
    const { value, tableMeta, callback } = this.props;
    const regionName = this.state.regionName ?? tableMeta?.rowData[1];
    const category = this.state.category ?? tableMeta?.rowData[3];
    const speedLimit = this.state.speedLimit ?? tableMeta?.rowData[5];
    const status = this.state.status ?? tableMeta?.rowData[4];
    if (!callback) return <React.Fragment />;
    return (
      <React.Fragment>
        <Tooltip title={"NEW"}>
          <IconButton onClick={this.handleClick}>
            <EditIcon />
          </IconButton>
        </Tooltip>
        <Dialog
          open={this.state.open}
          onClose={this.cancel}
          aria-labelledby="City Edit Modal"
          fullWidth={true}
        >
          <DialogTitle
            id="City Edit Modal"
            style={{
              color: "white",
              backgroundColor: "#36b9cc",
              textAlign: "center",
            }}
          >
            <label>Edit Region</label>
          </DialogTitle>
          <Grid container spacing={2}>
            <Grid item sm={12} xs={12} md={12} lg={12}>
              <CardContent style={{ paddingBottom: "0px" }}>
                <Grid container spacing={2} className="formLabel">
                  <Grid item sm={12} xs={12} md={12} lg={12}>
                    <TextField
                      id="regionName"
                      label="City Name"
                      fullWidth
                      value={regionName}
                      onChange={this.onChangeRegionName}
                      InputProps={{
                        readOnly: false,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={2} className="formLabel">
                  <Grid item sm={12} xs={12} md={12} lg={12}>
                    <TextField
                      id="category"
                      select
                      label="Category"
                      fullWidth
                      value={category}
                      onChange={this.onChangeCategory}
                      InputProps={{
                        readOnly: false,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                    >
                        <MenuItem key="OPERATING" value="OPERATING">
                            Operation Region
                        </MenuItem>
                        <MenuItem key="LOW_SPEED" value="LOW_SPEED">
                            Low Speed Region
                        </MenuItem>
                        <MenuItem key="NO_PARKING" value="NO_PARKING">
                            No Parking Region
                        </MenuItem>
                        <MenuItem key="FORBIDDEN" value="FORBIDDEN">
                            Forbidden Region
                        </MenuItem>
                    </TextField>
                  </Grid>
                </Grid>
                <Grid container spacing={2} className="formLabel">
                  <Grid item sm={12} xs={12} md={12} lg={12}>
                    <TextField
                      id="speedLimit"
                      select
                      label="Speed Limit"
                      value={speedLimit}
                      onChange={this.onChangeSpeedLimit}
                      InputProps={{
                        readOnly: false,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      fullWidth
                    >
                      <MenuItem key="LOW" value="LOW">
                        Low Speed
                      </MenuItem>
                      <MenuItem key="MEDIUM" value="MEDIUM">
                        Medium
                      </MenuItem>
                      <MenuItem key="HIGH" value="HIGH">
                        High
                      </MenuItem>
                    </TextField>
                  </Grid>
                </Grid>
                <Grid container spacing={2} className="formLabel">
                  <Grid item sm={12} xs={12} md={12} lg={12}>
                    <TextField
                      id="status"
                      select
                      label="Status"
                      value={status}
                      onChange={this.onChangeStatus}
                      InputProps={{
                        readOnly: false,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      fullWidth
                    >
                      <MenuItem key="ACTIVE" value="ACTIVE">
                        ACTIVE
                      </MenuItem>
                      <MenuItem key="INACTIVE" value="INACTIVE">
                        INACTIVE
                      </MenuItem>
                    </TextField>
                  </Grid>
                </Grid>
              </CardContent>
            </Grid>
          </Grid>

          <DialogActions
            style={{
              justifyContent: "space-between",
              padding: "10px 40px 15px 40px",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={() => this.confirm(callback)}
            >
              Save
            </Button>
            <Button onClick={this.cancel} style={{ color: grey[500] }}>
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}
export default RegionEditModel;
