import React, {Component} from "react";
import {withAuth0} from "@auth0/auth0-react";
import {connect} from "react-redux";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "@material-ui/core/Button";
import Card from "react-bootstrap/Card";
import Modal from "react-bootstrap/Modal";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import {faChevronLeft, faChevronRight,} from "@fortawesome/free-solid-svg-icons";
import {IconButton} from "@material-ui/core";
import {
  validateParkingPicture,
  getOrdersWithParkingPicturesToCheck, getOrderWithParkingPictureToCheck
} from "../../../../redux/order/action";

class ParkingPictureChecking extends Component {
  constructor(props) {
    super(props);
    this.verifyingOrders = [];
    this.confirmModalText = "";
    this.selectedAction = "";
    this.state = {
      selectedIndex: 0,
      showConfirmModal: false,
    };
  }

  componentDidMount() {
    this.getParkingPicturesToVerify();
  }


  getParkingPicturesToVerify = () => {
    this.state.selectedIndex = 0;
    let params = new URLSearchParams("?parkingCheck=NOT_CHECKED");
    getOrdersWithParkingPicturesToCheck(params).then((res) => {
      this.verifyingOrders = res.data.content;
      if (this.verifyingOrders.length) {
        this.getParkingPicturePage(this.verifyingOrders[this.state.selectedIndex]);
      } else {
        this.props.getOrderWithParkingPictureToCheck(null);
      }
    })
  }

  getParkingPicturePage(order) {
    if (order) {
      const apiPath = "/order/" + order.uuid;
      this.props.getOrderWithParkingPictureToCheck(apiPath);
    }
  }

  onCloseConfirmModal = () => {
    this.setState({showConfirmModal: false});
  };

  onConfirm = () => {
    const {orderWithParkingPictureToCheck: order} = this.props;
    if (this.selectedAction === 'VALID') {
      validateParkingPicture(`/order/${order.uuid}/parkingCheck/GOOD`)
        .then((res) => {
          if (res.status === 200) {
            this.getParkingPicturesToVerify();
          } else {
            alert(res.error);
          }
        })
        .catch((error) => {
          alert(error.response.data.error);
        });
    } else {
      validateParkingPicture(`/order/${order.uuid}/parkingCheck/BAD`)
        .then((res) => {
          if (res.status === 200) {
            this.getParkingPicturesToVerify();
          } else {
            alert(res.error);
          }
        })
        .catch((error) => {
          alert(error.response.data.error);
        });
    }
    this.setState({showConfirmModal: false});
  };

  onSelectNextPicture = (index) => {
    const order = this.verifyingOrders[index];
    this.getParkingPicturePage(order);
  }

  render() {
    const {orderWithParkingPictureToCheck: order} = this.props;

    const graphUrl = order?.graphUrl;

    const chervonStyle = {
      position: "absolute",
      top: "40%",
      left: "20%",
    };

    if (!order) {
      return <React.Fragment>No records found for verification</React.Fragment>;
    }

    return (
      <React.Fragment>
        <Card className="shadow">
          <Card.Header className="text-center">
            <h3>Parking Picture Checking</h3>
          </Card.Header>
          <Card.Body style={{padding: "10px 0px 10px 0px"}}>
            <Row>
              <Col xs={4} sm={3} md={1}>
                <IconButton
                  aria-label="Left"
                  style={chervonStyle}
                  disabled={
                    this.state.selectedIndex === 0
                  }
                  onClick={() => {
                    if (this.state.selectedIndex >= 1) {
                      this.state.selectedIndex = this.state.selectedIndex - 1;
                      this.onSelectNextPicture(this.state.selectedIndex);
                    }
                  }}
                >
                  <FontAwesomeIcon icon={faChevronLeft}/>
                </IconButton>
              </Col>
              <Col xs={4} sm={6} md={10} style={{
                padding: "0px",
                verticalAlign: "middle",
                display: "inline-block"
              }}>
                <Card.Img variant="top" src={graphUrl} style={{
                  width: "100%",
                  maxHeight: "670px",
                  height: "100%",
                  objectFit: "contain"
                }}/>
              </Col>
              <Col xs={4} sm={3} md={1}>
                <IconButton
                  aria-label="Right"
                  style={chervonStyle}
                  disabled={
                    this.state.selectedIndex === this.verifyingOrders.length - 1
                  }
                  onClick={() => {
                    if (this.state.selectedIndex >= 0 && this.state.selectedIndex < this.verifyingOrders.length) {
                      this.state.selectedIndex = this.state.selectedIndex + 1;
                      this.onSelectNextPicture(this.state.selectedIndex);
                    }
                  }}
                >
                  <FontAwesomeIcon icon={faChevronRight}/>
                </IconButton>
              </Col>
            </Row>
          </Card.Body>
          <Card.Footer className="text-center">
            {" "}
            <Button
              variant="contained"
              style={{marginRight: "10px"}}
              color="primary"
              disableElevation
              onClick={() => {
                this.selectedAction = "VALID";
                this.confirmModalText =
                  "Are you sure to verify the picture?";
                this.setState({
                  showConfirmModal: true,
                });
              }}
            >
              Valid
            </Button>
            <Button
              variant="contained"
              color="primary"
              disableElevation
              onClick={() => {
                this.selectedAction = "INVALID";
                this.confirmModalText = "Are you sure to reject the picture?";
                this.setState({
                  showConfirmModal: true,
                });
              }}
            >
              Not Valid
            </Button>
          </Card.Footer>
        </Card>

        <Modal
          show={this.state.showConfirmModal}
          onHide={() => this.onCloseConfirmModal()}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Confirm</Modal.Title>
          </Modal.Header>
          <Modal.Body>{this.confirmModalText}</Modal.Body>
          <Modal.Footer className="text-right">
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={() => this.onConfirm()}
            >
              Yes
            </Button>
            <Button
              size="small"
              variant="secondary"
              onClick={() => this.onCloseConfirmModal()}
            >
              No
            </Button>
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  orderWithParkingPictureToCheck: state.orderReducer.orderWithParkingPictureToCheck
});

const mapDispatchToProps = (dispatch) => ({
  getOrderWithParkingPictureToCheck: (params) => dispatch(getOrderWithParkingPictureToCheck(params))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withAuth0(ParkingPictureChecking));
