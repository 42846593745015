import React, {useEffect, useState} from 'react';
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip
} from "@material-ui/core";
import PinDropIcon from '@material-ui/icons/PinDrop';
import {grey} from "@material-ui/core/colors";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core/styles";
import axios from "axios";
import {config} from "../../../auth/auth";
import {getPlaces} from "../../../redux/places/action";
import {connect} from "react-redux";
import {withAuth0} from "@auth0/auth0-react";

const useStyles = makeStyles(theme => ({
  dialogContent: {
    textAlign: 'center'
  },
  dialogAction: {
    justifyContent: 'space-around'
  },
}))

const host = process.env.REACT_APP_HOST;

const PoiRelocation = ({setDraggable, pointOfInterest, position, setOpenSuccessModal, getPlaces}) => {
  const [openModal, setOpenModal] = useState(false);
  const [relocateBtn, setRelocateBtn] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const {shortName: name, id} = pointOfInterest;
  const classes = useStyles();

  const handleRelocateButton = async () => {
    setDraggable((prevState) => !prevState);

    setIsLoading(true);

    const response = await axios
      .put(`${host}/place/${id}/relocate`, position,  config())
      .catch((e) => alert(e));

    if (response?.status >= 200 && response?.status < 300) {
      getPlaces();
      setOpenSuccessModal({
        open: true,
        content: `Relocated ${name} Successfully.`
      });
    } else {
      setOpenSuccessModal({
        open: true,
        header: "Failed",
        content: `Failed to relocate ${name}.`
      });
    }
    setOpenModal(false)
    setRelocateBtn(false);
    setIsLoading(false);
  }

  const handleOKButton = () => {
    setDraggable((prevState) => !prevState);
    setRelocateBtn(true);
    setOpenModal(false)
  }

  const handleCancelButton = () => {
    setOpenModal(false)
    setRelocateBtn(false);
  }

  useEffect(() => {
    console.log("Position")
    console.log(position);
  }, [position])

  return (
    <React.Fragment>
      <Tooltip title="Relocate">
        <IconButton
          variant="contained"
          color="primary"
          onClick={() => setOpenModal(true)}
        >
          <PinDropIcon/>
        </IconButton>
      </Tooltip>
      <Dialog open={openModal} fullWidth>
        <DialogTitle
          id="Users Action Modal"
          style={{
            color: "white",
            backgroundColor: "#333399",
            textAlign: "left",
          }}
        >
          <label>RELOCATE {name}</label>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <Typography variant="h6" className="p-3">
            {!relocateBtn && "Click and hold Pin to relocate. After finish, press relocate button again."}
            {relocateBtn && "Do you want to finish relocating?"}
          </Typography>
        </DialogContent>
        <DialogActions className={classes.dialogAction}>
          {(!isLoading && !relocateBtn) && <Button
            style={{color: "white", backgroundColor: "#333399"}}
            onClick={() => handleOKButton()}>OK</Button>}
          {(!isLoading && relocateBtn) && <Button
            style={{color: "white", backgroundColor: "#333399"}}
            onClick={() => handleRelocateButton()}>Relocate</Button>}
          {!isLoading && <Button
            style={{color: grey[500]}}
            onClick={() => handleCancelButton()}>Cancel</Button>}
          {isLoading && <CircularProgress style={{width: 20, height: 20}}/>}
        </DialogActions>
      </Dialog>

    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  getPlaces: () => dispatch(getPlaces())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withAuth0(PoiRelocation));