import React, {Component} from "react";
import {withAuth0} from "@auth0/auth0-react";
import {connect} from "react-redux";
import {search} from "../../../../redux/role/role.action";
import {withRouter} from "react-router-dom";
import MUIDataTable from "mui-datatables";
import {Skeleton} from "@material-ui/lab";
import {queryParams} from "../../../../utils/utils";
import Create from "./create";
import {IconButton, Tooltip} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import UserSelector from "../RoleDetail/user-selector";
import PageSelector from "../RoleDetail/page-selector";


class RoleList extends Component {

  constructor(props) {
    super(props);
    this.isFilterDialogOpen = false;
    this.tableRef = React.createRef();
    this.columnsDisplay = undefined;
  }

  componentDidMount() {
    if (!this.props?.role)
      this.refresh();
  }

  refresh = () => {
    const {search, filters} = this.props;

    // table state
    const state = this.tableRef?.current?.state;

    const params = queryParams(this.props, state);

    filters?.map(f => params.set(f.key, f.value));

    search(params);
  }

  isDisplay = (name) => {
    if (!this.columnsDisplay || !this.columnsDisplay.hasOwnProperty(name))
      return undefined;
    else
      return this.columnsDisplay[name];
  }

  render() {
    const { roles } = this.props;
    if (!roles)
      return (<Skeleton variant="rect" width={"100%"} height={800}/>);

    const list = roles?.content;
    const totalElements = roles?.totalElements ?? 0;
    const rowsPerPage = roles?.pageable?.pageSize ?? 10;
    const page = roles?.pageable?.pageNumber ?? 0;

    const columns = [
      {
        name: "uuid",
        label: "UUID",
        options: {
          filter: false,
          sort: false,
          display: this.isDisplay("uuid") ?? false,
          filterType: "textField",
        },
      },
      {
        name: "name",
        label: "Name",
        index: 1,
        options: {
          filter: false,
          sort: false,
          display: this.isDisplay("email") ?? true,
          filterType: "textField",
        },
      },
      {
        name: "description",
        label: "Description",
        options: {
          setCellProps: () => ({ style: { minWidth: "600px", maxWidth: "600px" }}),
          filter: true,
          sort: false,
          display: this.isDisplay("firstName") ?? true,
          filterType: "textField",
        },
      },
      {
        name: "Operations",
        options: {
          filter: false,
          sort: false,
          empty: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div>
                <Tooltip title="Details">
                  <IconButton onClick={() => {this.props.history.push(`/role/${tableMeta.rowData[0]}`);}}>
                    <SearchIcon />
                  </IconButton>
                </Tooltip>
                <UserSelector roleUuid={`${tableMeta.rowData[0]}`} roleName={`${tableMeta.rowData[1]}`} callback={this.refresh}/>
                <PageSelector roleUuid={`${tableMeta.rowData[0]}`} roleName={`${tableMeta.rowData[1]}`} callback={this.refresh}/>
                {/*<Delete url={`/role/${tableMeta.rowData[0]}`} callback={this.refresh}/> */}
              </div>
            );
          },
        },
      },

    ];


    const options = {
      filter: false,
      confirmFilters: false,
      serverSide: true,
      sortOrder: this.state?.sortOrder,
      filterType: "checkbox",
      responsive: "vertical",
      rowsPerPageOptions: [10, 20, 50, 100],
      rowsPerPage: rowsPerPage,
      count: totalElements,
      page: page,
      fixedHeader: true,
      tableBodyHeight: "900px",
      viewColumns: false,
      download: false,
      print: false,
      search: false,
      selectableRows: "none",
      customToolbar: () => {
        return (
          <Create refresh={this.refresh} tableProps={this.props}/>
        );
      }
    };

    return (
      <React.Fragment>
        <MUIDataTable
          title={<h1 className="tableTitle">Roles</h1>}
          data={list}
          columns={columns}
          options={options}
          ref={this.tableRef}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  roles: state.roleReducer?.roles
});

const mapDispatchToProps = (dispatch) => ({
  search: (params) => dispatch(search(params)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withAuth0(RoleList)));
