import React, { Component } from 'react';
import { connect } from 'react-redux'
import { addToCart } from '../../redux/shoppingSpareParts/action'
import { withAuth0 } from "@auth0/auth0-react";
import Cart from './Cart'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

 class Home extends Component{
     
    handleClick = (id)=>{
        this.props.addToCart(id); 
    }
    
    render(){
        let itemList = this.props.items.map(item=>{
            return(
                <div className="spare-parts-card" key={item.id}>
                        <div className="spare-parts-card-image">
                            <img src={item.img} className = "spare-parts-img" alt={item.title}/>
                            <span className="spare-parts-card-title"> {item.title}</span>
                            <AddCircleOutlineIcon color="primary" cursor= "pointer" onClick={()=>{this.handleClick(item.id)}}></AddCircleOutlineIcon>
                        </div>

                        <div className="card-content">
                            <p>{item.desc}</p>
                            <p><b>Price: {item.price}$</b></p>
                        </div>
                 </div>

            )
        })

        return(
            <div className="container">
                <h3 className="center">Spare Parts MarketPlace</h3>
                <div className="spare-parts-box">
                    {itemList}
                </div>
                <br/>
                <h3 className="center">My Cart</h3>
                <Cart />
            </div>
        )
    }
}
const mapStateToProps = (state)=> ({
    items: state.shoppingSparePartsReducer.items
  });
const mapDispatchToProps= (dispatch)=> ({
    addToCart: (id)=>{dispatch(addToCart(id))}
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(withAuth0(Home));
