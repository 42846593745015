import React from 'react';
import {CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

import {makeStyles} from "@material-ui/core/styles";
import {grey} from "@material-ui/core/colors";

const useStyles = makeStyles(theme => ({
  dialogContent: {
    textAlign: 'center'
  },
  dialogAction: {
    justifyContent: 'space-around'
  },
}))

const ConfirmationModal = ({confirmDialog, setConfirmDialog, isLoading = false}) => {
  const classes = useStyles()

  return (
    <Dialog open={confirmDialog.isOpen} fullWidth>
      <DialogTitle
        id="Users Action Modal"
        style={{
          color: "white",
          backgroundColor: "#333399",
          textAlign: "left",
        }}
      >
        <label>{confirmDialog.title}</label>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Typography variant="h6" className="p-3">
          {confirmDialog.subTitle}
        </Typography>
      </DialogContent>
      <DialogActions className={classes.dialogAction}>
        {!isLoading && <Button
          style={{color: "white", backgroundColor: "#333399"}}
          onClick={confirmDialog.onClick}>Confirm</Button>}
        {!isLoading && <Button
          style={{color: grey[500]}}
          onClick={() => setConfirmDialog({isOpen: false})}>Cancel</Button>}
        {isLoading && <CircularProgress style={{width: 20, height: 20}}/>}
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationModal;