import React from "react";
import PropTypes from "prop-types";
import {Button, Dialog, DialogActions, DialogTitle, Grid,} from "@material-ui/core";

import axios from "axios";
import {config} from "../../../auth/auth";
import {grey} from "@material-ui/core/colors";
import CardContent from "@material-ui/core/CardContent";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import {search} from "../../../redux/city/action";
import {withAuth0} from "@auth0/auth0-react";
import {connect} from "react-redux";
import moment from 'moment';

const host = process.env.REACT_APP_HOST

class ParkingCreateModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      cityUuid: "",
      notification: "Please park the vehicle here",
      name: "Parking Space",
      qrCode: `${moment.now()}`,
      parkingUuid: "",
      lat: this.props.lat,
      lng: this.props.lng,
      status: "ACTIVE",
      capacity: 20,
      discount: 0,
      radius: 5,
      open: this.props.open,
    };
  }

  componentDidMount() {
    this.refreshCities();
  }

  static propTypes = {
    callback: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
  };

  cancel = () => {
    this.setState({
      discount: 0,
      open: false,
      regionName: "",
      category: "",
      cityUuid: "",
    });
    this.props.onCloseModal();
  };

  confirm = (callback) => {
    const { edit } = this.props;
    if (edit) {
      this.saveParking(callback);
    } else {
      this.createNewParking(callback);
    }
  };

  refreshCities = () => {
    let params = new URLSearchParams();
    params.set("page", "0");
    params.set("size", "1000");
    this.props.search(params);
  };


  saveParking = (callback) => {
    const {parking} = this.props;
    const body = {
      cityUuid: this.state.cityUuid,
      notification: this.state.notification,
      name: this.state.name,
      discount: this.state.discount,
      radius: this.state.radius,
      status: this.state.status,
      capacity: this.state.capacity
    };
    return axios
      .put(`${host}/parking/${parking.uuid}`, body, config())
      .then((response) => callback())
      .catch((e) => alert(e))
      .finally(() => {
        this.setState({
          discount: 0,
          open: false,
          isLoading: false,
          cityUuid: "",
          notification: "Please park the vehicle here",
          name: "Parking Space",
          qrCode: `${moment.now()}`,
          parkingUuid: "",
          lat: "",
          lng: "",
          status: "ACTIVE",
          capacity: 20,
          radius: 5,
        });
        this.props.onCloseModal();
      });
  };

  createNewParking = (callback) => {
    const { lat, lng } = this.props;
    const body = {
      capacity: this.state.capacity,
      cityUuid: this.state.cityUuid,
      discount: this.state.discount,
      lat: lat,
      lng: lng,
      name: this.state.name,
      notification: this.state.notification,
      qrCode: this.state.qrCode,
      radius: this.state.radius,
      status: this.state.status
    };

    return axios
      .post(`${host}/parking/`, body, config())
      .then((response) => callback())
      .catch((e) => alert(e))
      .finally(() => {
        this.setState({
          discount: 0,
          open: false,
          isLoading: false,
          cityUuid: "",
          notification: "Please park the vehicle here",
          name: "Parking Space",
          qrCode: `${moment.now()}`,
          parkingUuid: "",
          lat: "",
          lng: "",
          status: "ACTIVE",
          capacity: 20,
          radius: 5,
        });
        this.props.onCloseModal();
      });
  };

  onChangeCityUuid = (e) => {
    this.setState({ cityUuid: e.target.value });
  };
  onChangeNote = (e) => {
    this.setState({ notification: e.target.value });
  };
  onChangeLat = (e) => {
    this.setState({ lat: e.target.value });
  };
  onChangeLng = (e) => {
    this.setState({ lng: e.target.value });
  };
  onChangeName = (e) => {
    this.setState({name: e.target.value})
  };
  onChangeQrCode = (e) => {
    this.setState({qrCode: e.target.value})
  };
  onChangeStatus = (e) => {
    this.setState({status: e.target.value})
  };
  // end

  componentDidUpdate(prevProps) {
    if (this.props?.open !== prevProps.open) {
      this.setState({ open: this.props?.open });
    }
  }
  render() {
    const {
      callback,
      cities,
      lat,
      lng,
      uuid,
      qrCode
    } = this.props;
    if (!cities) return <React.Fragment />;
    return (
      <React.Fragment>
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="Parking Action Modal"
          fullWidth={true}
        >
          <DialogTitle
            id="Parking Action Modal"
            style={{
              color: "white",
              backgroundColor: "#36b9cc",
              textAlign: "center",
            }}
          >
            <label>Create/Edit Parking</label>
          </DialogTitle>
          <Grid container spacing={2}>
            <Grid item sm={12} xs={12} md={12} lg={12}>
              <CardContent style={{ paddingBottom: "0px" }}>
                {/* name */}
                <Grid container spacing={2} className="formLabel">
                  <Grid item sm={12} xs={12} md={12} lg={12}>
                    <TextField
                      id="name"
                      label="Parking Name"
                      fullWidth
                      type="text"
                      value={this.state.name}
                      onChange={this.onChangeName}
                      InputProps={{
                        readOnly: false,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
                {/* qr code */}
                <Grid container spacing={2} className="formLabel">
                  <Grid item sm={12} xs={12} md={12} lg={12}>
                    <TextField
                      id="qrCode"
                      label="Parking ID"
                      fullWidth
                      type="text"
                      value={this.state.qrCode}
                      onChange={this.onChangeQrCode}
                      InputProps={{
                        readOnly: false,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
                {/* status */}
                <Grid container spacing={2} className="formLabel">
                  <Grid item sm={12} xs={12} md={12} lg={12}>
                    <TextField
                      id="status"
                      select
                      label="Status"
                      value={this.state.status}
                      onChange={this.onChangeStatus}
                      InputProps={{
                        readOnly: false,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      fullWidth
                    >
                      <MenuItem
                        key="ACTIVE"
                        value="ACTIVE"
                      >
                        Active
                      </MenuItem>
                      <MenuItem
                        key="INACTIVE"
                        value="INACTIVE"
                      >
                        Inactive
                      </MenuItem>
                    </TextField>
                  </Grid>
                </Grid>
                {/* city */}
                <Grid container spacing={2} className="formLabel">
                  <Grid item sm={12} xs={12} md={12} lg={12}>
                    <TextField
                      id="cityUuid"
                      select
                      label="City"
                      value={this.state.cityUuid}
                      fullWidth
                      onChange={this.onChangeCityUuid}
                      InputProps={{
                        readOnly: false,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                    >
                      {cities?.content.map((option) => (
                        <MenuItem key={option.uuid} value={option.uuid}>
                          {option.cityName}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                </Grid>
                {/* latitude */}
                <Grid container spacing={2} className="formLabel">
                  <Grid item sm={12} xs={12} md={12} lg={12}>
                    <TextField
                      id="lat"
                      label="Latitude"
                      fullWidth
                      type="text"
                      value={lat}
                      onChange={this.onChangeLat}
                      InputProps={{
                        readOnly: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
                {/* longitude */}
                <Grid container spacing={2} className="formLabel">
                  <Grid item sm={12} xs={12} md={12} lg={12}>
                    <TextField
                      id="lng"
                      label="Longitude"
                      fullWidth
                      type="text"
                      value={lng}
                      onChange={this.onChangeLng}
                      InputProps={{
                        readOnly: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
                {/* note */}
                <Grid container spacing={2} className="formLabel">
                  <Grid item sm={12} xs={12} md={12} lg={12}>
                    <TextField
                      id="lng"
                      label="Note"
                      fullWidth
                      type="text"
                      value={this.state.notification}
                      onChange={this.onChangeNote}
                      InputProps={{
                        readOnly: false,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Grid>
          </Grid>
          <DialogActions
            style={{
              justifyContent: "space-between",
              padding: "10px 40px 15px 40px",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={() => this.confirm(callback)}
            >
              Save
            </Button>
            <Button onClick={this.cancel} style={{ color: grey[500] }}>
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  cities: state.cityReducer.cities
});

const mapDispatchToProps = (dispatch) => ({
  search: (params) => dispatch(search(params))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withAuth0(ParkingCreateModal));
