import React, {Component} from "react";
import {withAuth0} from "@auth0/auth0-react";
import {connect} from "react-redux";
import {search} from "../../../redux/notificate/action";
import InputLabel from "@material-ui/core/InputLabel";
import Select from '@material-ui/core/Select'
import MenuItem from "@material-ui/core/MenuItem";

class NotificationSelector extends Component {
  constructor(props) {
    super(props);
    this.state = {
        selected: props.value
    }
  }

  componentDidMount() {
    this.refresh();
  }

  refresh = () => {
    const {search} = this.props;
    let params = new URLSearchParams();
    params.set("page", "0");
    params.set("size", "1000");
    search(params);
  }

  handleChange = (e) => {
    this.setState ({selected: e.target.value})

    if (this.props.onSelectChanged){
        this.props.onSelectChanged(e.target.value);
    }
  };


  render() {
    String.prototype.trimEllip = function (length) {
        return this.length > length ? this.substring(0, length) + "..." : this;
      }

    const { notificationTemplates } = this.props;
    if (!notificationTemplates)
      return (<React.Fragment>Loading...</React.Fragment>);

    const list = notificationTemplates?.content ?? [];

    return (
      <React.Fragment>
        <InputLabel id="notification-select-helper-label">Message Template</InputLabel>
        <Select
          labelId="notification-select-helper-label"
          id="notification-select-helper"
          value={this.state.selected ?? ""}
          onChange={this.handleChange}
        >
            <MenuItem selected disabled value="">
                <em>Please select a message template</em>
            </MenuItem>
        {
            list.map( (item) =>
            <MenuItem value={item.uuid} key={item.uuid}>{item.subject}: {item.content.trimEllip(20)} </MenuItem>)
        }
        </Select>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  notificationTemplates: state.notificationReducer.notificationTemplates
});

const mapDispatchToProps = (dispatch) => ({
  search: (params) => dispatch(search(params)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withAuth0(NotificationSelector));
