import React from "react";
import PropTypes from 'prop-types';

class VehiclesSupported extends React.Component {
  static propTypes = {
    value: PropTypes.object.isRequired
  };

  render() {
    const { value } = this.props;
    if (!value)
      return (<React.Fragment/>);

    const content = value?.map(v => v.regionName).join(' / ');

    return (
      <React.Fragment>
        {content}
      </React.Fragment>
    );
  }
}

export default VehiclesSupported;

