import {ParkingActionTypes} from "./parkings.types";

const INITIAL_STATE = {
  parkings: [],
  parking: {},
};

const parkingsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ParkingActionTypes.SET_PARKINGS:
      return {
        ...state,
        parkings: action.payload,
      };
    case ParkingActionTypes.SET_PARKING:
      return {
        ...state,
        parking: action.payload,
      };
    default:
      return state;
  }
};

export default parkingsReducer;