import React from "react";
import PropTypes from 'prop-types';
import {determineVehicleImage} from "../../utils/utils";
import Avatar from "@material-ui/core/Avatar";
import CardHeader from "@material-ui/core/CardHeader";
import {sub} from "../../utils/utils";

class Vehicle extends React.Component {
  static propTypes = {
    vehicle: PropTypes.object,
    history: PropTypes.object.isRequired,
    hideUuid: PropTypes.bool.isRequired,
  };

  render() {
    const { vehicle, history, hideUuid} = this.props;
    if (!vehicle || !vehicle.uuid)
      return (<React.Fragment/>);

    let img = "";
    if (vehicle.graphUrl) {
      img = vehicle.graphUrl;
    }else {
      img = determineVehicleImage(vehicle.category)
    }

    return (
      <React.Fragment>
        <CardHeader
          avatar={
            <Avatar aria-label="recipe" alt="Vehicle Photo" src={img} style={{padding:0}}>
              V
            </Avatar>
          }
          title={`${sub(vehicle?.qrCode)}`}
          subheader={hideUuid ? "" : `UUID: ${sub(vehicle?.uuid)}`}
          onClick={() => history.push(`/vehicle/${vehicle?.uuid}`)}
          style={{cursor: "pointer", padding:0}}
        />
      </React.Fragment>
    );
  }
}

export default Vehicle;