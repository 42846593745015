import React, {Component} from "react";
import MUIDataTable from "mui-datatables";
import {withAuth0} from "@auth0/auth0-react";
import {Avatar, Button, FormControl, IconButton, InputLabel, MenuItem, Select, Tooltip} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import PaymentIcon from "@material-ui/icons/Payment";
import DeleteIcon from '@material-ui/icons/Delete';
import MoneyOffIcon from "@material-ui/icons/MoneyOff";
import NotificationsIcon from "@material-ui/icons/Notifications";
import LockIcon from "@material-ui/icons/Lock";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import {connect} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPassport} from "@fortawesome/free-solid-svg-icons";
import DrivingLicense from "../DrivingLicenseVerfication";
import Modal from "react-bootstrap/Modal";
import {queryParams} from "../../../../utils/utils";
import {search} from "../../../../redux/user/user.action";
import UserActionModal from "../UserModals/UserActions-modal";
import UnbindCard from "./unbind-card";
import axios from "axios";
import {config} from "../../../../auth/auth";
import {search as getCities} from "../../../../redux/city/action";
import moment from "moment";
import CustomToolbarSelect from "./CustomToolbarSelect";
import {withRouter} from 'react-router-dom';
import CustomToolbar from "../../../../components/List/CustomToolBar/CustomToolbar";

const date_format = process.env.REACT_APP_DATE_FORMAT;
const datetime_format = process.env.REACT_APP_DATETIME_FORMAT;

const host = process.env.REACT_APP_HOST

class UserList extends Component {
  constructor(props) {
    super(props);
    this.isFilterDialogOpen = false;
    this.tableRef = React.createRef();
    this.columnsDisplay = undefined;
    this.drivingLicensePath = "";
    this.tableTitle = "User List"
    this.state = {
      showDrivingLicense: false,
      openPopUp: false,
      clickedAction: "",
      userId: "",
    };
  }

  componentDidMount() {
    this.refresh();
    if (!this.props?.cities) {
      this.props.getCities();
    }
  }

  refresh = () => {
    const { search, filters } = this.props;
    if(this.props?.location && this.props.location?.search && this.props.location.search === "?userStatus=INACTIVE"){
      this.tableTitle = "Suspicious User List"
    } else if (this.props?.location && this.props.location?.search && this.props.location.search === "?userStatus=AWAITING_DELETE"){
      this.tableTitle = "Deleting User List"
    } else {
      this.tableTitle = "User List"
    }
    // table state
    const state = this.tableRef?.current?.state;

    const params = queryParams(this.props, state);

    filters?.map((f) => params.set(f.key, f.value));

    search(params);
  };

  onChangeColumnDisplay = () => {
    const state = this.tableRef?.current?.state;
    this.columnsDisplay = state.columns.reduce(
      (obj, item) => Object.assign(obj, { [item.name]: item.display }),
      {}
    );
  };

  isDisplay = (name) => {
    if (!this.columnsDisplay || !this.columnsDisplay.hasOwnProperty(name))
      return undefined;
    else return this.columnsDisplay[name];
  };

  onSelectDrivingLicense = (uuid) => {
    const { history } = this.props;
    const routePath = "/user/" + uuid + "/drivingLicence";
    if (history.location.search === "?drivingLicenceStatus=VERIFYING") {
      history.push(routePath);
    } else {
      this.drivingLicensePath = routePath;
      this.setState({ showDrivingLicense: true });
    }
  };

  onCloseDrivingLicenseModal = () => {
    this.refresh();
    this.setState({ showDrivingLicense: false });
  };

  handleModalCloseChange = () => {
    this.setState({
      openPopUp: false,
    });
  };

  cancel = () => {
    this.setState({
      amount: 0,
      open: false,
      subject: undefined,
      content: undefined,
    });
  };

  changeUserStatus = (status, userId) => {
    return axios
      .put(`${host}/user/${userId}`, { userStatus: status }, config())
      .then((response) => this.refresh())
      .catch((e) => alert(e));
  }

  deleteUser = (userId) => {
    return axios
      .put(`${host}/user/${userId}/confirm-delete`,  {}, config())
      .then((response) => this.refresh())
      .catch((e) => alert(e));
  }

  cancelDeleteUser = (userId) => {
    return axios
      .put(`${host}/user/${userId}/cancel-delete`,  {}, config())
      .then((response) => this.refresh())
      .catch((e) => alert(e));
  }

  render() {
    const { users, cities } = this.props;
    if (!users) return <React.Fragment>Loading...</React.Fragment>;

    const list = users?.content;
    const totalElements = users?.totalElements ?? 0;
    const rowsPerPage = users?.pageable?.pageSize < 251 ? users?.pageable?.pageSize : 10;
    const page = users?.pageable?.pageNumber ?? 0;

    const { showDrivingLicense } = this.state;
    const columns = [
      {
        name: "uuid",
        label: "User ID",
        options: {
          filter: false,
          sort: false,
          display: this.isDisplay("uuid") ?? true,
          customFilterListOptions: { render: (v) => `UUID: ${v}` },
          filterType: "textField",
        },
      },
      {
        name: "avatar",
        label: "Avatar",
        options: {
          filter: false,
          sort: false,
          display: this.isDisplay("avatar") ?? false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return <Avatar alt="Photo" src={value} > {tableMeta.tableData[tableMeta.rowIndex].firstName?.charAt(0) ?? "A"} </Avatar>;
          },
        },
      },
      {
        name: "email",
        label: "Email Address",
        index: 2,
        options: {
          filter: true,
          sort: false,
          display: this.isDisplay("email") ?? true,
          customFilterListOptions: { render: (v) => `Email: ${v}` },
          filterType: "textField",
        },
      },
      {
        name: "firstName",
        label: "First Name",
        options: {
          filter: true,
          sort: false,
          display: this.isDisplay("firstName") ?? true,
          customFilterListOptions: { render: (v) => `First Name: ${v}` },
          filterType: "textField",
        },
      },
      {
        name: "lastName",
        label: "Last Name",
        options: {
          filter: true,
          sort: false,
          display: this.isDisplay("lastName") ?? true,
          customFilterListOptions: { render: (v) => `Last Name: ${v}` },
          filterType: "textField",
        },
      },
      {
        name: "balance",
        label: "Credit Balance",
        options: {
          filter: false,
          sort: true,
          display: this.isDisplay("balance") ?? true,
          customFilterListOptions: { render: (v) => `Balance: ${v}` },
          filterType: "textField",
        },
      },
      {
        name: "phone",
        label: "Phone Number",
        options: {
          filter: true,
          sort: false,
          display: this.isDisplay("phone") ?? false,
          customFilterListOptions: { render: (v) => `Phone: ${v}` },
          filterType: "textField",
        },
      },
      {
        name: "regMethod",
        label: "Registration Method",
        options: {
          filter: false,
          sort: false,
          display: this.isDisplay("regMethod") ?? false,
        },
      },
      {
        name: "regTime",
        label: "Registration Time",
        options: {
          filter: false,
          sort: true,
          display: this.isDisplay("regTime") ?? true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <Tooltip
                title={
                  value ? moment.utc(value).local().format(datetime_format) : ""
                }
              >
                <div>
                  {value ? moment.utc(value).local().format(date_format) : ""}
                </div>
              </Tooltip>
            );
          },
        },
      },
      {
        name: "userStatus",
        label: "Status",
        options: {
          filter: false,
          sort: false,
          display: this.isDisplay("userStatus") ?? true,
        },
      },
      {
        name: "drivingLicenceStatus",
        label: "ID Verification",
        options: {
          filter: false,
          sort: false,
          display: this.isDisplay("drivingLicenceStatus") ?? false,
        },
      },
      {
        name: "countOfOrder",
        label: "No. of Rides",
        options: {
          filter: false,
          sort: false,
          display: this.isDisplay("countOfOrder") ?? false,
          filterType: "textField",
        },
      },
      {
        name: "city",
        label: "City",
        options: {
          filter: false,
          sort: false,
          display: this.isDisplay("city") ?? false,
        },
      },
      {
        name: "marketing",
        label: "Marketing Consent",
        options: {
          filter: false,
          sort: false,
          display: this.isDisplay("marketing") ?? false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div>
                {value!=undefined ? `${value}` : "No Choice"}
              </div>
            )
          }
        },
      },
      {
        name: "isMigrated",
        label: "Migrated",
        options: {
          filter: false,
          sort: true,
          display: this.isDisplay("isMigrated") ?? true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
                <div>
                  {value!=undefined ? `${value}` : "New Registrants"}
                </div>
            );
          },
        },
      },
      {
        name: "cityUuid",
        label: "City ID",
        options: {
          filter: true,
          filterType: "custom",
          sort: false,
          display: this.isDisplay("cityUuid") ?? false,
          customFilterListOptions: { render: (v) => {
              const city = this.props?.cities?.find(c => c.uuid === v[0]);
              return city && `City: ${city?.cityName},${city?.country}`;
            } },
          filterOptions: {
            display: (filterList, onChange, index, column) => {
              return (
                <FormControl>
                  <InputLabel htmlFor='select-multiple-chip'>
                    City
                  </InputLabel>
                  <Select
                    value={filterList[index][0] ?? ""}
                    onChange={event => {
                      filterList[index] = [event.target.value];
                      onChange(filterList[index], index, column);
                    }}
                  >
                    {(cities || []).map((city, index) => (
                      <MenuItem key={index} value={city.uuid}>
                        {city.cityName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              );
            }
          }
        }
      },
      {
        name: "Operations",
        options: {
          filter: false,
          sort: false,
          empty: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            if (tableMeta.tableData[tableMeta.rowIndex].userStatus === "AWAITING_DELETE") {
              return(
                <div>
                  <Tooltip title="Details">
                    <IconButton
                      aria-label="Details"
                      onClick={() => {
                        const {history} = this.props;
                        const routePath = "/user/" + tableMeta.tableData[tableMeta.rowIndex]["uuid"];
                        history.push(routePath);
                      }}
                      >
                        <SearchIcon/>
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Delete">
                    <IconButton
                      aria-label="Delete"
                      onClick={() => {
                        this.setState({
                          openPopUp: true,
                          clickedAction: "delete",
                          userId: tableMeta.tableData[tableMeta.rowIndex]["uuid"],
                        });
                      }}
                    >
                      <DeleteIcon/>
                    </IconButton>
                  </Tooltip>
              </div>

            )
            } else {
              return (
                <div>
                  <Tooltip title="Details">
                    <IconButton
                      aria-label="Details"
                      onClick={() => {
                        const { history } = this.props;
                        const routePath =
                          "/user/" +
                          tableMeta.tableData[tableMeta.rowIndex]["uuid"];
                        history.push(routePath);
                      }}
                    >
                      <SearchIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Driving License">
                    <IconButton
                      aria-label="Driving License"
                      disabled={
                        tableMeta.tableData[tableMeta.rowIndex]
                          .drivingLicenceStatus === "NO_UPLOAD"
                      }
                      onClick={() =>
                        this.onSelectDrivingLicense(
                          tableMeta.tableData[tableMeta.rowIndex].uuid
                        )
                      }
                    >
                      <FontAwesomeIcon icon={faPassport} />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Top Up">
                    <IconButton
                      aria-label="topUp"
                      onClick={() => {
                        this.setState({
                          openPopUp: true,
                          clickedAction: "TopUp",
                          userId: tableMeta.tableData[tableMeta.rowIndex].uuid,
                        });
                    
                      }}
                    >
                      <PaymentIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Penalty">
                    <IconButton
                      aria-label="Penalty"
                      onClick={() => {
                        this.setState({
                          openPopUp: true,
                          clickedAction: "penality",
                          userId: tableMeta.tableData[tableMeta.rowIndex].uuid,
                        });
                       
                      }}
                    >
                      <MoneyOffIcon/>
                    </IconButton>
                  </Tooltip>
                  <UnbindCard
                    userUuid={tableMeta.tableData[tableMeta.rowIndex].uuid}
                    isBindCard={tableMeta.tableData[tableMeta.rowIndex].isPaymentBind}
                    callback={() => this.refresh()}
                  />
                  <Tooltip title="Notification">
                    <IconButton
                      aria-label="Notification"
                      onClick={() => {
                        this.setState({
                          openPopUp: true,
                          clickedAction: "Notification",
                          userId: tableMeta.tableData[tableMeta.rowIndex].uuid,
                        });
                    
                      }}
                    >
                      <NotificationsIcon />
                    </IconButton>
                  </Tooltip>
                  {tableMeta.tableData[tableMeta.rowIndex].userStatus ===
                  "ACTIVE" ? (
                    <Tooltip title="Block">
                      <IconButton
                        aria-label="Block"
                        onClick={() => this.changeUserStatus("INACTIVE", tableMeta.tableData[tableMeta.rowIndex].uuid)}
                      >
                        <LockIcon />
                      </IconButton>
                    </Tooltip>
                  ) : (
                    <Tooltip title="Activate">
                      <IconButton
                        aria-label="Block"
                        onClick={() => this.changeUserStatus("ACTIVE", tableMeta.tableData[tableMeta.rowIndex].uuid)}
                      >
                        <LockOpenIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                </div>
              );
            }
          },
        },
      },
    ];

    const options = {
      search: false,
      filter: true,
      confirmFilters: true,
      serverSide: true,
      filterType: "checkbox",
      responsive: "vertical",
      rowsPerPageOptions: [10, 20, 50, 100, 250],
      rowsPerPage: rowsPerPage,
      count: totalElements,
      page: page,
      fixedHeader: true,
      tableBodyHeight: "900px",
      customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
        <CustomToolbarSelect
          selectedRows={selectedRows}
          displayData={displayData}
          setSelectedRows={setSelectedRows}
          callback={() => this.refresh()}
        />
      ),
      onTableChange: (action, tableState) => {
        switch (action) {
          case "viewColumnsChange":
            this.onChangeColumnDisplay();
            break;
          case "onFilterDialogOpen":
            this.isFilterDialogOpen = true;
            break;
          case "filterChange":
            if (this.isFilterDialogOpen) return;
            else this.refresh();
            break;
          case "onFilterDialogClose":
            this.isFilterDialogOpen = false;
          case "changePage":
          case "sort":
          case "changeRowsPerPage":
            this.refresh();
            break;
          default:
            console.log("Not handled action " + action);
        }
      },
      customFilterDialogFooter: (currentFilterList, applyNewFilters) => {
        return (
          <div style={{ marginTop: "40px" }}>
            <Button
              variant="contained"
              style={{ color: "white", backgroundColor: "#333399" }}
              onClick={() => {
                applyNewFilters();
              }}
            >
              Apply
            </Button>
          </div>
        );
      },
      customToolbar: () => {
        return (
          <CustomToolbar refresh={this.refresh} tableProps={this.props}/>
        );
      }
    };

    return (
      <React.Fragment>
        <MUIDataTable
          title={<h1 className="tableTitle">{this.tableTitle}</h1>}
          data={list}
          columns={columns}
          options={options}
          ref={this.tableRef}
        />

        <Modal
          show={showDrivingLicense}
          dialogClassName="drivingLicenceModal"
          onHide={() => this.onCloseDrivingLicenseModal()}
          animation={false}
          centered
          style={{ maxWidth: "90% !important" }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Driving Licence verification</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ padding: "0px" }}>
            <DrivingLicense LicensePath={this.drivingLicensePath} />
          </Modal.Body>
        </Modal>
        <UserActionModal
          open={this.state.openPopUp}
          action={this.state.clickedAction}
          userId={this.state.userId}
          onCloseModal={() => this.handleModalCloseChange()}
          callback={() => this.refresh()}
        ></UserActionModal>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  users: state.usersData.users,
  cities: state.cityReducer?.cities?.content,
});

const mapDispatchToProps = (dispatch) => ({
  search: (params) => dispatch(search(params)),
  getCities: (params) => dispatch(getCities(params)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withAuth0(UserList)));
