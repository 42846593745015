import React from "react";
import PropTypes from 'prop-types';
import {CircularProgress, IconButton, Switch, Tooltip} from "@material-ui/core";

import axios from "axios";
import {config} from "../../auth/auth";
import { withStyles } from '@material-ui/core/styles';
import {grey, purple} from '@material-ui/core/colors';

const host = process.env.REACT_APP_HOST

const PurpleSwitch = withStyles({
  switchBase: {
    color: grey[300],
    '&$checked': {
      color: "#333399",
    },
    '&$checked + $track': {
      backgroundColor: "#333399",
    },
  },
  checked: {},
  track: {},
})(Switch);

class Status extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false
    };
  }

  static propTypes = {
    checked: PropTypes.bool.isRequired,
    activeUrl:PropTypes.string.isRequired,
    inactiveUrl:PropTypes.string.isRequired,
    onChanged: PropTypes.func.isRequired
  };

  handleChange = (e) => {
    const { checked, activeUrl, inactiveUrl, onChanged} = this.props;
    this.setState({isLoading: true})
    let url = checked ? inactiveUrl : activeUrl;
    return axios.put(`${host}${url}`,null, config())
      .then(r => {})
      .catch(e => alert(e))
      .finally(() => {
        this.setState({isLoading: false});
        onChanged();
      });
  }


  render() {
    const {checked} = this.props;
    if (checked === undefined || checked === null)
      return (<React.Fragment/>);

    if (this.state.isLoading)
      return (
        <Tooltip title="Updating">
          <IconButton>
            <CircularProgress style={{width:20, height:20}}/>
          </IconButton>
        </Tooltip>
      );


    return (
      <Tooltip title="Status">
        <PurpleSwitch
          checked={checked}
          onClick={this.handleChange}
          name="Status"
        />
      </Tooltip>
    );
  }
}

export default Status;