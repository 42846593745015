import React from "react";
import {Marker, Popup} from "react-leaflet";
import {Icon} from "leaflet";
import {determine} from "./TaskIconDeterminer";
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import User from "../../../components/common/User";
import {determineVehicleImage} from "../../../utils/utils";
import CardActions from "@material-ui/core/CardActions";
import IconButton from "@material-ui/core/IconButton";
import {Tooltip} from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import {green, red } from "@material-ui/core/colors/";
import axios from "axios";
import {config} from "../../../auth/auth";
import SearchIcon from "@material-ui/icons/Search";
import {success} from "../../../redux/alert/alert.action"
import {connect} from "react-redux";
import { error } from "../../../redux/alert/alert.action";

const host = process.env.REACT_APP_HOST
class TaskMarker extends React.Component {
  static propTypes = {
    task: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
  }

  markTaskStatus(status) {
    return axios
      .put(`${host}/task/${this.props.task.uuid}/status?status=${status}`, {}, config())
      .then((response) => {
        this.props.refresh();
        this.props.success(`Task ${this.props.task.uuid} marked as ${status}`)
      })
      .catch((e) => {
        this.props.error(e?.response?.data?.message ? e?.response?.data?.message : e.toString())
      })
      .finally(() => {});
  }

  render() {
    const { task, history } = this.props;

    const imageUrl = determine(task);
    let icon = new Icon({iconUrl: `${imageUrl}`, iconSize: [25, 25]});

    const vehicle = task.vehicle;
    let img = "";
    if (vehicle?.graphUrl) {
      img = vehicle.graphUrl;
    }else {
      img = determineVehicleImage(vehicle?.category)
    }
   
    return (
      <Marker key={task.uuid} position={[
        task.lat,
        task.lng
      ]}
              icon = {icon}
      >
        <Popup >
          <Card style={{maxWidth: 445, width:"100%", minWidth:"300px"}}>
              <User
                key={`task-map-marker-card-header-${task.uuid}`}
                user={task?.assignee}
                history={this.props.history}/>
              <CardMedia
                key={`task-map-marker-card-media-${task.uuid}`}
                className={ vehicle?.category === 'EBIKE' || vehicle?.category === 'BIKE' || vehicle?.category === 'ECAR' ? "bikeCarImage" : "EscooterEmopedImage" }
                image={img}
                title={vehicle?.category}
              />

              <CardContent>
                <Typography variant="body2" color="textSecondary" component="p">

                  <b>Task:</b> {task?.uuid}<br/>
                  <b>Status:</b> {task?.status}<br/>
                  <b>Category:</b> {task?.category}<br/>
                  <b>Note:</b> {task?.note}<br/>
                </Typography>
              </CardContent>
              { task.status !== "FINISHED" && task.status !== "FAILED" && task.status !== "CANCELED"?
                <CardActions disableSpacing style={{ justifyContent: 'center' }}>
                  <Tooltip title="Details" onClick={() => { history.push(`/task/${task.uuid}`)}}>
                    <IconButton>
                      <SearchIcon style={{ color: '#000' }} />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={"Mark as Completed"} onClick={() => this.markTaskStatus("FINISHED")}>
                    <IconButton>
                      <CheckCircleIcon style={{ color: green[400] }} />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={"Mark as Failed"} onClick={() => this.markTaskStatus("FAILED")}>
                    <IconButton>
                      <CancelIcon style={{ color: red[900] }} />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={"Mark as Cancelled"} onClick={() => this.markTaskStatus("CANCELED")}>
                    <IconButton>
                      <img
                        aria-label="cancel"
                        style={{ width: 20, height: 20, margin: 2 }}
                        alt="cancel"
                        src={"/icon/cancel.svg"}
                      />
                    </IconButton>
                  </Tooltip>
                </CardActions> :
                  <CardActions disableSpacing style={{ justifyContent: 'center' }}>
                  <Tooltip title="Details" onClick={() => { history.push(`/task/${task.uuid}`)}}>
                    <IconButton>
                      <SearchIcon style={{ color: '#000' }} />
                    </IconButton>
                  </Tooltip>
                </CardActions>
              }
          </Card>
        </Popup>
      </Marker>
    );
  }
}

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = (dispatch) => ({
  success: (msg) => dispatch(success(msg)),
  error: (msg) => dispatch(error(msg))
});

export default connect(mapStateToProps, mapDispatchToProps)(TaskMarker);

