import React, {useState} from 'react';
import * as ReactDOM from "react-dom";
import Backdrop from "../../../Modal/Backdrop";
import classes from "../../../Modal/Modal.module.css";

import tempIcon from "../../../../images/Information_icon_orange.svg";
import PoiCreateModal from "./POICreateModal";
import {Marker} from "react-leaflet";
import {Icon} from "leaflet";
import PoiUploadIconModal from "./POIUploadIconModal";
import Success from "../../../PopUp/Success";

const PoiCreate = ({location, openModal, setOpenModal, setLocation}) => {
  const [openUploadIconModal, setOpenUploadIconModal] = useState(false);
  const [poiId, setPoiId] = useState('');
  const [successModal, setSuccessModal] = useState({
    open: false,
    header: undefined,
    content: ""
  });

  const icon = new Icon({
    iconUrl: `${tempIcon}`,
    iconSize: [25, 25],
  });

  const handleCloseBackdrop = () => {
    setOpenModal(false);
    setOpenUploadIconModal(false);
    setLocation({lat: '', lng: ''})
  }

  const handleOnCloseSuccessModal = () => {
    setSuccessModal({
      open: false,
      header: undefined,
      content: ""
    });
    setOpenUploadIconModal(false);
    setOpenModal(false);
  }

  return (
    <React.Fragment>
      <Marker
        key={`new-marker-key`}
        position={location}
        icon={icon}
      >
        {openModal && ReactDOM.createPortal(
          <Backdrop onClick={() => handleCloseBackdrop()}/>,
          document.getElementById('backdrop-root'))}

        {openModal && ReactDOM.createPortal(
          <div className={classes.modal}>
            {!openUploadIconModal &&
            <PoiCreateModal
              location={location}
              setOpenModal={setOpenModal}
              setLocation={setLocation}
              setOpenUploadIconModal={setOpenUploadIconModal}
              setPoiId={setPoiId}
            />}
            {openUploadIconModal &&
            <PoiUploadIconModal poiId={poiId} setOpenSuccessModal={setSuccessModal}/>}
            <Success
              open={successModal.open}
              header={successModal.header}
              content={successModal.content}
              onClose={() => handleOnCloseSuccessModal()}/>
          </div>,
          document.getElementById('overlay-root'))}
      </Marker>
    </React.Fragment>
  );
};
export default PoiCreate;