import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import AddIcon from "@material-ui/icons/Add";
import {withStyles} from "@material-ui/core/styles";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import PropTypes from "prop-types";
import axios from "axios";
import { connect } from "react-redux";

import {zendeskConfig} from "../../../auth/zendesk-auth";
import {CircularProgress} from "@material-ui/core";

const defaultToolbarStyles = {
  iconButton: {
  },
};
const host = process.env.REACT_APP_ZENDESK_URL

class CustomToolbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      open: false,
      priority: "low",
      type: "question",
      subject: undefined,
      content: undefined,
    };
  }

  static propTypes = {
    refresh: PropTypes.func.isRequired
  };

  handleClick = () => {
    this.setState({open: true});
  }

  cancel = () => {
    this.setState({open: false});
  };

  create = () => {
    const { refresh } = this.props;
    const {currentAdminUser} = this.props;

    if (this.state.isLoading)
      return;
    const body = {
      ticket: {
        priority: this.state.priority,
        type: this.state.type,
        subject: this.state.subject,
        comment: {
          body: this.state.content,
        },
        requester: {
          name: currentAdminUser.name? currentAdminUser.name : currentAdminUser.email,
          email: currentAdminUser.email
        }
      }
    }
    this.setState({isLoading: true})
    return axios.post(`${host}/tickets`, body, zendeskConfig())
      .then((response) => {})
      .catch(e => alert(e))
      .finally(() => {
        this.setState({isLoading: false, open: false, subject: undefined, content: undefined});
        refresh();
      });
  };

  handlePriorityChange = (e) => {
    this.setState({priority: e.target.value});
  };

  handelTypeChange = (e) => {
    this.setState({type: e.target.value});
  };

  handleSubjectChange = (e) => {
    this.setState({subject: e.target.value});
  };

  handleContentChange = (e) => {
    this.setState({content: e.target.value});
  };

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <Tooltip title={"NEW"}>
          <IconButton className={classes.iconButton} onClick={this.handleClick}>
            <AddIcon className={classes.deleteIcon} />
          </IconButton>
        </Tooltip>
        <Dialog open={this.state.open} onClose={this.cancel} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Create Ticket</DialogTitle>
          <DialogContent>
            <DialogContentText>
              To create a new ticket, please enter your subject, content, priority and ticket type here.
            </DialogContentText>
            <FormControl className={classes.formControl} fullWidth>
              <InputLabel htmlFor="max-width">Priority</InputLabel>
              <Select
                disabled={this.state.isLoading}
                value={this.state.priority}
                onChange={this.handlePriorityChange}
                inputProps={{
                  name: 'Priority',
                  id: 'Priority',
                }}
              >
                <MenuItem value="low">Low</MenuItem>
                <MenuItem value="normal">Normal</MenuItem>
                <MenuItem value="high">High</MenuItem>
                <MenuItem value="urgent">Urgent</MenuItem>
              </Select>
            </FormControl>
            <FormControl className={classes.formControl} fullWidth>
              <InputLabel htmlFor="max-width">Ticket Type</InputLabel>
              <Select
                disabled={this.state.isLoading}
                value={this.state.type}
                onChange={this.handelTypeChange}
                inputProps={{
                  name: 'Type',
                  id: 'Type',
                }}
              >
                <MenuItem value="question">General Question</MenuItem>
                <MenuItem value="task">New Requirement</MenuItem>
                <MenuItem value="incident">Incident</MenuItem>
              </Select>
            </FormControl>
            <TextField
              error={!this.state.subject}
              helperText={this.state.subject ? "" : "Required"}
              disabled={this.state.isLoading}
              autoFocus
              value={this.state.subject}
              margin="dense"
              id="subject"
              label="Subject"
              type="text"
              fullWidth
              onChange={this.handleSubjectChange}
            />
            <TextField
              error={!this.state.content}
              helperText={this.state.content ? "" : "Required"}
              disabled={this.state.isLoading}
              multiline
              value={this.state.content}
              rows="6"
              margin="dense"
              id="content"
              label="Content"
              type="text"
              fullWidth
              onChange={this.handleContentChange}
            />
          </DialogContent>
          <DialogActions>
              <React.Fragment>
                {this.state.isLoading ? <CircularProgress style={{width:20, height:20}}/> : ""}
                <Button
                  onClick={() => this.create()}
                  style={{color: "white", backgroundColor: "#36b9cc"}}
                  disabled={this.state.isLoading || !this.state.content || !this.state.subject}
                >
                  Create
                </Button>
                <Button
                  onClick={this.cancel}
                  color="primary"
                  disabled={this.state.isLoading}
                >
                  Cancel
                </Button>
              </React.Fragment>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }

}

const mapStateToProps = (state) => ({
  currentAdminUser: state.appData.currentAdminUser,
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(defaultToolbarStyles, { name: "CustomToolbar" })(CustomToolbar));
