import React, {Component} from "react";
import MUIDataTable from "mui-datatables";
import {withAuth0} from "@auth0/auth0-react";
import {
  Avatar,
  Button,
  Checkbox,
  FormControl,
  FormGroup,
  FormLabel,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Tooltip
} from "@material-ui/core";
import {connect} from "react-redux";
import moment from "moment";

import {search} from "../../../../redux/order/action";
import {determineFeedbackIcon, queryParams, sub} from "../../../../utils/utils";
import Vehicle from "../../../../components/common/Vehicle";
import PropTypes from "prop-types";
import SearchIcon from "@material-ui/icons/Search";
import Suspicious from "./Suspicious";
import Popover from "@material-ui/core/Popover";
import OrderMapPopUp from "./OrderMapPop";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import HighlightOff from "@material-ui/icons/HighlightOff";
import CardHeader from "@material-ui/core/CardHeader";
import GraphOverlay from "./graphOverlay";
import CustomToolbarSelect from "./CustomToolbarSelect";
import {withRouter} from 'react-router-dom';
import {search as getCities} from "../../../../redux/city/action";
import {DateTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import MomentUtils from '@date-io/moment';
import EndOrderPopUp from "../../../../components/PopUp/EndOrderPopUp/EndOrderPopUp.jsx";
import CustomToolbar from "../../../../components/List/CustomToolBar/CustomToolbar";

const date_format = process.env.REACT_APP_DATE_FORMAT;
const datetime_format = process.env.REACT_APP_DATETIME_FORMAT;
const LOCAL_DATE_TIME_FORMAT = process.env.REACT_APP_LOCALDATETIME_FORMAT;
const ORDER_STATUSES = ["PROCESSING", "UNLOCKING", "CANCELED", "UPLOADING_PHOTO", "REFUNDED", "SUCCEEDED"];
const PARKING_CHECK_STATUSES = ["GOOD", "BAD", "NOT_CHECKED"];

class OrderList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openPopover: false,
      anchorEl: null,
      openPopUp: false,
      clickedAction: "",
      selectedRecord: "",
      userId: "",
    };
    this.isFilterDialogOpen = false;
    this.tableRef = React.createRef();
    this.ref = React.createRef();
    this.columnsDisplay = undefined;
    this.selectedOrder = {};
    this.selectedField = undefined;
    this.tableTitle = "Order List"
  }

  static propTypes = {
    filters: PropTypes.object,
  };

  componentDidMount() {
    this.refresh();
    if (!this.props?.cities) {
      this.props.getCities();
    }
  }

  refresh = () => {
    const {search, filters} = this.props;

    if (this.props?.location && this.props.location?.search && this.props.location.search === "?isSuspicious=true") {
      this.tableTitle = "Suspicious Order List"
    } else {
      this.tableTitle = "Order List"
    }

    // table state
    const state = this.tableRef?.current?.state;

    const params = queryParams(this.props, state);

    filters?.map((f) => params.set(f.key, f.value));

    params.delete("startAt");
    // add startAt, status, parkingCheck, cost as filter
    if (state && state["columns"]) {
      const index = state["columns"].findIndex(s => s.name === 'startAt');
      const statusIndex = state["columns"].findIndex(s => s.name === 'status');
      const parkingCheckIndex = state["columns"].findIndex(s => s.name === 'parkingCheck');
      const actualPaymentIndex = state["columns"].findIndex(s => s.name === 'actualPayment');
      const values = state["filterList"][index];
      const statuses = state["filterList"][statusIndex];
      const parkingCheck = state["filterList"][parkingCheckIndex];
      const actualPayment = state["filterList"][actualPaymentIndex];
      if (values?.length !== 0) {
        const from = values[0] ?? moment().subtract(100, 'year');
        const until = values[1] ?? moment();
        params.set('startFrom', from.format(LOCAL_DATE_TIME_FORMAT));
        params.set('startUntil', until.format(LOCAL_DATE_TIME_FORMAT));
      }
      if (statuses?.length !== 0) {
        params.set("status", statuses);
      }
      if (parkingCheck?.length !== 0) {
        params.set("parkingCheck", parkingCheck);
      }
      if (actualPayment?.length !== 0) {
        const min = actualPayment[0] ?? "-9999999";
        const max = actualPayment[1] ?? "9999999";
        params.set('minActualPayment', min);
        params.set('maxActualPayment', max);
      }
    }
    search(params);
  };

  onChangeColumnDisplay = () => {
    const state = this.tableRef?.current?.state;
    this.columnsDisplay = state.columns.reduce(
      (obj, item) => Object.assign(obj, {[item.name]: item.display}),
      {}
    );
  };

  isDisplay = (name) => {
    if (!this.columnsDisplay || !this.columnsDisplay.hasOwnProperty(name))
      return undefined;
    else return this.columnsDisplay[name];
  };

  // popover events
  handleonLocationClick = (event, index, field) => {
    this.selectedOrder = {};
    const {orders} = this.props;
    const list = orders?.content;
    const order = list[index];
    this.selectedOrder = order;
    this.selectedField = field;
    this.setState({
      openPopover: true,
      anchorEl: event.currentTarget,
    });
  };

  handlePopOverClose = () => {
    this.setState({openPopover: false, anchorEl: null});
  };
  // close order actions modal
  handleModalCloseChange = () => {
    this.setState({
      openPopUp: false,
    });
  };

  render() {
    const {orders, history} = this.props;
    if (!orders) return <React.Fragment>Loading...</React.Fragment>;

    const list = orders?.content;
    const totalElements = orders?.totalElements ?? 0;
    const rowsPerPage = orders?.pageable?.pageSize < 251 ? orders?.pageable?.pageSize : 10;
    const page = orders?.pageable?.pageNumber ?? 0;

    const columns = [
      {
        name: "uuid",
        label: "Order ID",
        options: {
          filter: true,
          sort: false,
          display: this.isDisplay("uuid") ?? true,
          customFilterListOptions: {render: (v) => `UUID: ${v}`},
          filterType: "textField",
          filterOptions: {
            fullWidth: true,
          },
        },
      },
      {
        name: "user",
        label: "User Name",
        options: {
          filter: false,
          sort: false,
          display: this.isDisplay("userName") ?? true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return value ? (
              <CardHeader
                avatar={
                  <Avatar aria-label="user" alt="User Photo">
                    {`${value?.firstName
                      ?.charAt(0)
                      .toUpperCase()}${value?.lastName
                      ?.charAt(0)
                      .toUpperCase()}`}
                  </Avatar>
                }
                title={sub(`${value?.firstName} ${value?.lastName}`)}
                style={{cursor: "pointer", padding: 0}}
                onClick={() => history.push(`/user/${value?.uuid}`)}
              />
            ) : (
              ""
            );
          },
        },
      },
      {
        name: "vehicle",
        label: "Vehicle QR Code",
        options: {
          filter: false,
          sort: true,
          display: this.isDisplay("vehicle") ?? true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <Vehicle
                vehicle={value}
                history={this.props.history}
                hideUuid={true}
              />
            );
          },
        },
      },
      {
        name: "startAt",
        label: "Start Time",
        options: {
          filter: true,
          sort: true,
          filterType: 'custom',
          display: this.isDisplay("startAt") ?? true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <Tooltip title={value ? moment.utc(value).local().format(datetime_format) : ""}>
                <div>
                  {value ? moment.utc(value).local().format(datetime_format) : ""}
                </div>
              </Tooltip>
            );
          },
          customFilterListOptions: {
            render: v => {
              if (v[0] && v[1]) {
                return [`Start time: ${v[0]?.format(datetime_format)} ~ ${v[1]?.format(datetime_format)}`];
              } else if (v[0]) {
                return [`Start time: ${v[0]?.format(datetime_format)} ~ N/A`];
              } else if (v[1]) {
                return [`Start time: N/A ~ ${v[1]?.format(datetime_format)}`];
              }
              return [];
            },
            update: (filterList, filterPos, index) => {
              console.log('customFilterListOnDelete: ', filterList, filterPos, index);
              filterList[index] = [];
              return filterList;
            },
          },
          filterOptions: {
            names: [],
            display: (filterList, onChange, index, column) => (
              <div>
                <FormLabel>Start time range</FormLabel>
                <FormGroup row>
                  <DateTimePicker
                    renderInput={(props) => <TextField {...props} />}
                    label="from"
                    inputVariant="outlined"
                    value={filterList[index][0] ?? null}
                    defaultValue={null}
                    onChange={(newValue) => {
                      filterList[index][0] = newValue;
                      onChange(filterList[index], index, column);
                    }}
                    format={datetime_format}
                    style={{width: '45%', marginRight: '5%'}}
                  />
                  <DateTimePicker
                    renderInput={(props) => <TextField {...props} />}
                    label="until"
                    inputVariant="outlined"
                    value={filterList[index][1] ?? null}
                    defaultValue={null}
                    onChange={(newValue) => {
                      filterList[index][1] = newValue;
                      onChange(filterList[index], index, column);
                    }}

                    format={datetime_format}
                    style={{width: '45%'}}
                  />
                </FormGroup>
              </div>
            )
          }
        },
      },
      {
        name: "endAt",
        label: "End Time",
        options: {
          filter: false,
          sort: true,
          display: this.isDisplay("endAt") ?? true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <Tooltip
                title={
                  value ? moment.utc(value).local().format(datetime_format) : ""
                }
              >
                <div>
                  {value ? moment.utc(value).local().format(datetime_format) : ""}
                </div>
              </Tooltip>
            );
          },
        },
      },
      {
        name: "lockAt",
        label: "Lock Time",
        options: {
          filter: false,
          sort: false,
          display: this.isDisplay("lockAt") ?? false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <Tooltip
                title={
                  value ? moment.utc(value).local().format(datetime_format) : ""
                }
              >
                <div>
                  {value ? moment.utc(value).local().format(date_format) : ""}
                </div>
              </Tooltip>
            );
          },
        },
      },
      {
        name: "unlockAt",
        label: "Unlock Time",
        options: {
          filter: false,
          sort: false,
          display: this.isDisplay("unlockAt") ?? false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <Tooltip
                title={
                  value ? moment.utc(value).local().format(datetime_format) : ""
                }
              >
                <div>
                  {value ? moment.utc(value).local().format(date_format) : ""}
                </div>
              </Tooltip>
            );
          },
        },
      },
      {
        name: "startLocation",
        label: "Start Location",
        options: {
          filter: false,
          sort: false,
          display: this.isDisplay("startLocation") ?? false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <IconButton
                onClick={(event) => {
                  this.handleonLocationClick(
                    event,
                    tableMeta["rowIndex"],
                    "startLocation"
                  );
                }}
              >
                <LocationOnIcon/>
              </IconButton>
            );
          },
        },
      },
      {
        name: "endLocation",
        label: "End Location",
        options: {
          filter: false,
          sort: false,
          display: this.isDisplay("endLocation") ?? false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <IconButton
                onClick={(event) => {
                  this.handleonLocationClick(
                    event,
                    tableMeta["rowIndex"],
                    "endLocation"
                  );
                }}
              >
                <LocationOnIcon/>
              </IconButton>
            );
          },
        },
      },
      {
        name: "actualPayment",
        label: "Cost",
        options: {
          filter: true,
          sort: true,
          filterType: 'custom',
          customFilterListOptions: {
            render: v => {
              if (v[0] && v[1]) {
                return [`Min: ${v[0]}`, `Max: ${v[1]}`];
              } else if (v[0]) {
                return `Min: ${v[0]}`;
              } else if (v[1]) {
                return `Max: ${v[1]}`;
              }
              return [];
            },
            update: (filterList, filterPos, index) => {
              console.log('customFilterListOnDelete: ', filterList, filterPos, index);

              if (filterPos === 0) {
                filterList[index].splice(filterPos, 1, '');
              } else if (filterPos === 1) {
                filterList[index].splice(filterPos, 1);
              } else if (filterPos === -1) {
                filterList[index] = [];
              }

              return filterList;
            },
          },
          filterOptions: {
            display: (filterList, onChange, index, column) => (
              <div>
                <FormLabel>Cost</FormLabel>
                <FormGroup row>
                  <TextField
                    label='min'
                    value={filterList[index][0] || ''}
                    onChange={event => {
                      filterList[index][0] = event.target.value;
                      onChange(filterList[index], index, column);
                    }}
                    style={{width: '45%', marginRight: '5%'}}
                  />
                  <TextField
                    label='max'
                    value={filterList[index][1] || ''}
                    onChange={event => {
                      filterList[index][1] = event.target.value;
                      onChange(filterList[index], index, column);
                    }}
                    style={{width: '45%'}}
                  />
                </FormGroup>
              </div>
            ),
          },
          print: false,
        },
      },
      {
        name: "userSatisfaction",
        label: "Feedback",
        options: {
          filter: false,
          sort: false,
          display: this.isDisplay("userSatisfaction") ?? true,
          customBodyRender: (value, tableMeta, updateValue) => {
            let img = determineFeedbackIcon(value);
            return value ? (
              <CardHeader
                avatar={
                  <Avatar aria-label="Feedback Icon" alt="Feedback Face Icon" src={img}/>
                }
              />
            ) : (
              ""
            );
          },
        },
      },
      {
        name: "currency",
        label: "Currency",
        options: {
          filter: false,
          sort: false,
          display: this.isDisplay("currency") ?? false,
        },
      },
      {
        name: "status",
        label: "Order Status",
        options: {
          filter: true,
          sort: true,
          display: true,
          filterType: "custom",
          customFilterListOptions: {
            render: v => v.map(l => l.toUpperCase()),
            update: (filterList, filterPos, index) => {
              console.log('update');
              console.log(filterList, filterPos, index);
              filterList[index].splice(filterPos, 1);
              return filterList;
            }
          },
          filterOptions: {
            fullWidth: true,
            logic: (location, filters, row) => {
              if (filters.length) return !filters.includes(location);
              return false;
            },
            display: (filterList, onChange, index, column) => {
              return (
                <FormControl>
                  <InputLabel htmlFor='select-multiple-chip'>
                    Order Status
                  </InputLabel>
                  <Select MenuProps={{
                    getContentAnchorEl: () => null,
                  }}
                          multiple
                          value={filterList[index]}
                          renderValue={selected => selected.join(', ')}
                          onChange={event => {
                            filterList[index] = event.target.value;
                            onChange(filterList[index], index, column);
                          }}
                  >
                    {ORDER_STATUSES.map(item => (
                      <MenuItem key={item} value={item}>
                        <Checkbox
                          color='primary'
                          checked={filterList[index].indexOf(item) > -1}
                        />
                        <ListItemText primary={item}/>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              );
            }
          }
        },
      },
      {
        name: "parkingCheck",
        label: "Parking Check",
        options: {
          filter: true,
          sort: true,
          display: true,
          filterType: "custom",
          customFilterListOptions: {
            render: v => v.map(l => l.toUpperCase()),
            update: (filterList, filterPos, index) => {
              console.log('update');
              console.log(filterList, filterPos, index);
              filterList[index].splice(filterPos, 1);
              return filterList;
            }
          },
          filterOptions: {
            fullWidth: true,
            logic: (location, filters, row) => {
              if (filters.length) return !filters.includes(location);
              return false;
            },
            display: (filterList, onChange, index, column) => {
              return (
                <FormControl>
                  <InputLabel htmlFor='select-multiple-chip'>
                    Parking Check
                  </InputLabel>
                  <Select MenuProps={{
                    getContentAnchorEl: () => null,
                  }}
                          multiple
                          value={filterList[index]}
                          renderValue={selected => selected.join(', ')}
                          onChange={event => {
                            filterList[index] = event.target.value;
                            onChange(filterList[index], index, column);
                          }}
                  >
                    {PARKING_CHECK_STATUSES.map(item => (
                      <MenuItem key={item} value={item}>
                        <Checkbox
                          color='primary'
                          checked={filterList[index].indexOf(item) > -1}
                        />
                        <ListItemText primary={item}/>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              );
            }
          }
        },
      },
      /*  {
          name: "parkingCheck",
          label: "Parking Check",
          options: {
            filter: true,
            sort: true,
            display: this.isDisplay("parkingCheck") ?? true,
            customBodyRender: (value, tableMeta, updateValue) => {
              const graph = tableMeta.currentTableData[tableMeta.rowIndex]?.data[13];
              return <ParkingCheckStatus graph={graph} status={value}/>;
            },
          },
        },*/
      {
        name: "graphUrl",
        label: "Graph",
        options: {
          filter: false,
          sort: false,
          display: this.isDisplay("graphUrl") ?? true,
          customBodyRender: (value, tableMeta, updateValue) => {
            const uuid = tableMeta.currentTableData[tableMeta.rowIndex]?.data[0];
            return (
              <Tooltip title={value ? "Graph" : "No Graph"}>
                <GraphOverlay value={value} uuid={uuid} refresh={this.refresh}></GraphOverlay>
              </Tooltip>
            );
          },
        },
      },
      {
        name: "isSuspicious",
        label: "Suspicious",
        options: {
          filter: true,
          sort: false,
          display: this.isDisplay("isSuspicious") ?? false,
          customBodyRender: (value) => {
            return `${value}`
          }
        },
      },
      {
        name: "cityUuid",
        label: "City ID",
        options: {
          filter: true,
          filterType: "custom",
          sort: false,
          display: this.isDisplay("cityUuid") ?? false,
          customFilterListOptions: {
            render: (v) => {
              const city = this.props?.cities?.find(c => c.uuid === v[0]);
              return city && `City: ${city?.cityName},${city?.country}`;
            }
          },
          filterOptions: {
            display: (filterList, onChange, index, column) => {
              return (
                <FormControl>
                  <InputLabel htmlFor='select-multiple-chip'>
                    City
                  </InputLabel>
                  <Select
                    value={filterList[index][0] ?? ""}
                    onChange={event => {
                      filterList[index] = [event.target.value];
                      onChange(filterList[index], index, column);
                    }}
                  >
                    {(this.props?.cities || []).map((city, index) => (
                      <MenuItem key={index} value={city.uuid}>
                        {city.cityName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              );
            }
          }
        }
      },
      {
        name: "Operations",
        options: {
          filter: false,
          sort: false,
          empty: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div style={{display: "flex"}}>
                <Tooltip title="Details">
                  <IconButton
                    aria-label="Details"
                    onClick={() => {
                      this.props.history.push("/order/" + tableMeta.rowData[0]);
                    }}
                  >
                    <SearchIcon/>
                  </IconButton>
                </Tooltip>
                <CustomToolbarSelect
                  rowIndex={tableMeta.rowIndex}
                  callback={() => this.refresh()}
                  orderSuspiciousIcon={false}
                />
                <Suspicious
                  suspiciousStatus={tableMeta.rowData[14]}
                  orderUuid={tableMeta.rowData[0]}
                  userUuid={tableMeta.rowData[1]?.uuid}
                  callback={() => this.refresh()}
                />
                <EndOrderPopUp
                  refresh={() => this.refresh()}
                  uuid={tableMeta.rowData[0]}
                  status={tableMeta.rowData[11]}
                />
              </div>
            );
          },
        },
      },
    ];

    const options = {
      search: false,
      filter: true,
      confirmFilters: true,
      serverSide: true,
      filterType: "dropdown",
      responsive: "vertical",
      rowsPerPageOptions: [10, 20, 50, 100, 250],
      rowsPerPage: rowsPerPage,
      count: totalElements,
      page: page,
      fixedHeader: true,
      tableBodyHeight: "900px",
      customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
        <CustomToolbarSelect
          selectedRows={selectedRows}
          displayData={displayData}
          setSelectedRows={setSelectedRows}
          rowIndex={undefined}
          orderSuspiciousIcon={true}
          callback={() => this.refresh()}
        />
      ),
      onTableChange: (action, tableState) => {
        switch (action) {
          case "viewColumnsChange":
            this.onChangeColumnDisplay();
            break;
          case "onFilterDialogOpen":
            this.isFilterDialogOpen = true;
            break;
          case "filterChange":
            if (this.isFilterDialogOpen) return;
            else this.refresh();
            break;
          case "onFilterDialogClose":
            this.isFilterDialogOpen = false;
          case "changePage":
          case "sort":
          case "changeRowsPerPage":
            this.refresh();
            break;
          default:
            console.log("Not handled action " + action);
        }
      },
      customFilterDialogFooter: (currentFilterList, applyNewFilters) => {
        return (
          <div style={{marginTop: "40px"}}>
            <Button
              variant="contained"
              style={{color: "white", backgroundColor: "#36b9cc"}}
              onClick={() => {
                applyNewFilters();
              }}
            >
              Apply
            </Button>
          </div>
        );
      },
      onDownload: (buildHead, buildBody, columns, data) => {
        const dataNewFormat = data.reduce((acc, curr) => {
          curr.data.map((item, index) => {
            if (index === 2) {
              try {
                curr.data[index] = item.qrCode
              } catch (error) {
                curr.data[index] = item
              }
              ;
            } else if (index === 1) {
              try {
                curr.data[index] = `${item.firstName} ${item.lastName}`
              } catch (error) {
                curr.data[index] = item
              }
              ;
            }
          })
          acc.push(curr)
          return acc
        }, [])
        return "\uFEFF" + buildHead(columns) + buildBody(dataNewFormat);
      },
      customToolbar: () => {
        return (
          <CustomToolbar refresh={this.refresh} tableProps={this.props}/>
        );
      }
    };

    return (
      <React.Fragment>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <MUIDataTable
            title={<h1 className="tableTitle">{this.tableTitle}</h1>}
            data={list}
            columns={columns}
            options={options}
            ref={this.tableRef}
          />
        </MuiPickersUtilsProvider>


        {/* pop over */}
        <Popover
          id="vehicle_map"
          className="vehicle_popover"
          open={this.state.openPopover}
          anchorEl={this.state.anchorEl}
          onClose={this.handlePopOverClose}
          anchorOrigin={{
            vertical: "center",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "center",
            horizontal: "right",
          }}
        >
          <div className="card shadow mb-4">
            <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
              <h6 className="m-0 font-weight-bold" style={{color: '#333399'}}>Vehicle Map</h6>
              <div style={{padding: "4px"}}>
                <Tooltip title="close">
                  <Button onClick={() => this.handlePopOverClose()}>
                    <HighlightOff/>
                  </Button>
                </Tooltip>
              </div>
            </div>
            <div className="card-body">
              <OrderMapPopUp
                order={this.selectedOrder}
                field={this.selectedField}
              />
            </div>
          </div>
        </Popover>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  orders: state.orderReducer.orders,
  cities: state.cityReducer?.cities?.content,
});

const mapDispatchToProps = (dispatch) => ({
  search: (params) => dispatch(search(params)),
  getCities: (params) => dispatch(getCities(params)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withAuth0(OrderList)));
