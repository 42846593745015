import React from "react";
import {searchVehicleMapData} from "../../../../redux/vehicle/vehicle.action";
import {regionSearch} from "../../../../redux/region/action";
import {connect} from "react-redux";
import {withAuth0} from "@auth0/auth0-react";
import VehicleMapComponent from "../../../../components/Map/VehicleMap/index";

class VehicleMap extends React.Component {
  componentDidMount() {
    const vehicles = this.props.vehiclesMapData?.content;
    if (!vehicles)
      this.refresh();
  }

  refresh = () => {
    let params = new URLSearchParams();
    params.set("page", "0");
    params.set("size", "1000");
    this.props.searchVehicleMapData(params);
    this.props.regionSearch(params);
  };

  render() {
    const vehiclesMapData = this.props.vehiclesMapData?.content;
    const regions = this.props.regions?.content;
    if (!vehiclesMapData) return <React.Fragment>Loading...</React.Fragment>;

    return <VehicleMapComponent key="vehicle-information-vehicle-map-key" vehicles={vehiclesMapData} regions={regions} />;
  }
}

const mapStateToProps = (state) => ({
  vehiclesMapData: state.vehicleData.vehiclesMapData,
  regions: state.regionReducer.regions,
  realtimeLocation: state.vehicleData.realtimeLocation,
});

const mapDispatchToProps = (dispatch) => ({
  searchVehicleMapData: (params) => dispatch(searchVehicleMapData(params)),
  regionSearch: (params) => dispatch(regionSearch(params)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withAuth0(VehicleMap));
