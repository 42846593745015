import React, {useEffect, useRef, useState} from 'react';
import {connect} from "react-redux";
import {withAuth0} from "@auth0/auth0-react";
import {getPartnerSetting} from "../../../redux/partnerSetting/action";
import {Button} from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import CreateSettingModal from "./CreateSettingModal";
import UpdateMembershipAlarmModal from "./UpdateMembershipAlarmModal";
import UpdateBalanceAlarmModal from "./UpdateBalanceAlarmModal";

const PartnerSetting = ({getPartnerSetting, partnerSettings}) => {
  const [settings, setSettings] = useState([]);
  const tableRef = useRef();

  useEffect(() => {
    getPartnerSetting();
  }, [])

  useEffect(() => {
    setSettings(partnerSettings?.content);
  }, [partnerSettings]);

  if (!partnerSettings)
    return <React.Fragment>Loading...</React.Fragment>

  const refresh = () => {
    getPartnerSetting();
  }

  const state = tableRef?.current?.state;
  let columnsDisplay = undefined;
  let isFilterDialogOpen = false;

  const onChangeColumnDisplay = () => {
    const state = tableRef?.current?.state;
    columnsDisplay = state.columns.reduce((obj, item) => Object.assign(obj, {[item.name]: item.display}), {});
  }

  const isDisplay = (name) => {
    if (!columnsDisplay || !columnsDisplay.hasOwnProperty(name))
      return undefined;
    else
      return columnsDisplay[name];
  }

  const totalElements = partnerSettings?.totalElements ?? 0;
  const rowsPerPage = partnerSettings?.pageable?.pageSize ?? 10;
  const page = partnerSettings?.pageable?.pageNumber ?? 0;


  const columns = [
    {
      name: "uuid",
      label: "UUID",
      options: {
        filter: true,
        sort: false,
        display: false,
      },
    },
    {
      name: "cityUuid",
      label: "City ID",
      options: {
        filter: false,
        sort: false,
        display: isDisplay("cityUuid") ?? true,
      },
    },
    {
      name: "liveChatMessage",
      label: "Live Chat Message",
      options: {
        filter: false,
        sort: false,
        display: isDisplay("liveChatMessage") ?? true,
      },
    },
    {
      name: "isEnableMembershipAlarm",
      label: "Low Membership Limit Alarm",
      options: {
        filter: false,
        sort: false,
        display: isDisplay("isEnableMembershipAlarm") ?? true,
        customBodyRender: (value) => {
          return `${value}`
        }
      },
    },
    {
      name: "isEnableLowBalanceAlarm",
      label: "Low Balance Alarm",
      options: {
        filter: false,
        sort: false,
        display: isDisplay("isEnableLowBalanceAlarm") ?? true,
        customBodyRender: (value) => {
          return `${value}`
        }
      },
    },
    {
      name: "lowBalanceThreshold",
      label: "Low Balance Threshold",
      options: {
        filter: false,
        sort: false,
        display: isDisplay("lowBalanceThreshold") ?? true,
      },
    },
    {
      name: "lowMembershipLimitThreshold",
      label: "Membership Limit Threshold",
      options: {
        filter: false,
        sort: false,
        display: isDisplay("lowMembershipLimitThreshold") ?? true,
      },
    },
    {
      name: "Operations",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta) => {
          console.log(tableMeta);
          return (
            <div>
              <UpdateMembershipAlarmModal
                refresh={refresh}
                uuid={tableMeta.tableData[tableMeta.rowIndex]["uuid"]}
                currentLimit={tableMeta.tableData[tableMeta.rowIndex]["lowMembershipLimitThreshold"]}
              />
              <UpdateBalanceAlarmModal
                refresh={refresh}
                uuid={tableMeta.tableData[tableMeta.rowIndex]["uuid"]}
                currentLimit={tableMeta.tableData[tableMeta.rowIndex]["lowBalanceThreshold"]}
              />
            </div>
          );
        },
      },
    },
  ];

  const options = {
    search: false,
    filter: true,
    confirmFilters: true,
    serverSide: true,
    filterType: "dropdown",
    responsive: "vertical",
    rowsPerPageOptions: [10, 20, 50, 100],
    rowsPerPage: rowsPerPage,
    count: totalElements,
    page: page,
    fixedHeader: true,
    tableBodyHeight: "900px",
    onTableChange: (action) => {
      switch (action) {
        case "viewColumnsChange":
          onChangeColumnDisplay();
          break;
        case "onFilterDialogOpen":
          isFilterDialogOpen = true;
          break;
        case "filterChange":
          if (isFilterDialogOpen)
            return;
          else
            refresh();
          break;
        case "onFilterDialogClose":
          isFilterDialogOpen = false;
        case "changePage":
        case "sort":
        case "changeRowsPerPage":
          refresh();
          break;
        default:
          console.log("Not handled action " + action);
      }
    },
    customFilterDialogFooter: (currentFilterList, applyNewFilters) => {
      return (
        <div style={{marginTop: "40px"}}>
          <Button variant="contained"
                  style={{color: "white", backgroundColor: "#333399"}}
                  onClick={() => {
                    applyNewFilters()
                  }}
          >
            Apply
          </Button>
        </div>
      );
    },
    customToolbar: () => {
      return (
        <CreateSettingModal refresh={refresh}/>
      );
    }
  };

  return (
    <React.Fragment>
      <MUIDataTable
        title={<h1 className="tableTitle">Partner Settings</h1>}
        data={settings}
        columns={columns}
        options={options}
        ref={tableRef}
      />
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  partnerSettings: state.partnerSettingReducer.partnerSetting
});

const mapDispatchToProps = (dispatch) => ({
  getPartnerSetting: () => dispatch(getPartnerSetting())
})


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withAuth0(PartnerSetting));