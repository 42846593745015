import {ActionTypes} from "./types";
import axios from "axios";
import {getAccessToken} from "../../auth/auth";

const host = process.env.REACT_APP_HOST

export const getNews  = (queryParams) =>{
  const config = {
    headers: {
      'Authorization': `Bearer ${getAccessToken()}`,
      'Content-Type': 'application/json'
    },
    params: queryParams
  }

  return async dispatch => {
    return axios.get(`${host}/news`, config)
      .then(
        response =>dispatch(setNews(response.data)),
        err => console.log(err)
      );
  }
}

export const setNews = (payload) => ({
  type: ActionTypes.SET_NEWS,
  payload: payload,
});