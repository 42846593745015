import React from "react";
import {Skeleton} from "@material-ui/lab";
import {connect} from "react-redux";
import {withAuth0} from "@auth0/auth0-react";
import AreaChart from "../../../../components/Chart/AreaChart";

class OrderMetrics extends React.Component {

  render() {
    const {orderMetrics} = this.props;
    if (!orderMetrics) {
      return (<Skeleton variant="rect" width={"100%"} height={"50vh"}/>);
    }

    if (!orderMetrics.date || !orderMetrics.data){
      return (<React.Fragment>No data</React.Fragment>);
    }

    return (
      <AreaChart
        date={orderMetrics?.date}
        data={orderMetrics?.data}
        label=""
        areaStyle={{ color: "#99e6e6" }}
        lineStyle={{ color: "#33cccc" }}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  orderMetrics: state.businessReducer.orderMetrics,
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withAuth0(OrderMetrics));