import React, {useEffect, useState} from 'react';
import {Form, Formik} from "formik";
import {InputTextField, SelectField} from "../../../Form/FormElements";
import FormNavigation from "../../../Form/MultistepForm/FormNavigation";
import {Button, Card, CircularProgress, DialogTitle, Grid} from "@material-ui/core";
import * as Yup from "yup";
import {search} from "../../../../redux/city/action";
import {connect} from "react-redux";
import {withAuth0} from "@auth0/auth0-react";
import {makeStyles} from "@material-ui/core/styles";
import axios from "axios";
import {config} from "../../../../auth/auth";
import {getPlaces} from "../../../../redux/places/action";
import ConfirmationModal from "../../../Modal/ConfirmationModal";

const host = process.env.REACT_APP_HOST;

const useStyles = makeStyles(() => ({
  card: {
    borderRadius: '1rem'
  },
  activeBtn: {
    color: "white",
    backgroundColor: "#333399",
    '&:hover': {
      color: "#333399",
      backgroundColor: "white",
      borderColor: "#333399",
      border: '1px solid',
    }
  },
  neutralBtn: {
    color: "#333399",
    backgroundColor: "white",
    borderColor: "#333399",
    border: '1px solid',
    '&:hover': {
      color: "white",
      backgroundColor: "#333399",

      transition: '0.3s',
    }
  }
}))

const POI_TYPES = [
  {type: "HEALTH", description: "Health", value: "HEALTH"},
  {type: "LANDMARK", description: "Landmark", value: "LANDMARK"},
  {type: "NATURAL_FEATURE", description: "Natural feature", value: "NATURAL_FEATURE"},
  {type: "PLACE_OF_WORSHIP", description: "Place of worship", value: "PLACE_OF_WORSHIP"},
  {type: "FINANCE", description: "Finance", value: "FINANCE"},
  {type: "POLITICAL", description: "Political", value: "POLITICAL"},
  {type: "POST_BOX", description: "Post box", value: "POST_BOX"},
  {type: "TOWN_SQUARE", description: "Town square", value: "TOWN_SQUARE"},
  {type: "LOCALITY", description: "Locality", value: "LOCALITY"},
  {type: "BUS_STATION", description: "Bus station", value: "BUS_STATION"},
  {type: "TRAIN_STATION", description: "Train station", value: "TRAIN_STATION"},
  {type: "TRAIN_STwATION", description: "Train wstation", value: "TRAINw_STATION"},
  {type: "FOOD", description: "Food", value: "FOOD"}];

const PLACE_TYPES = [
  {description: "Active", value: "OPERATIONAL"},
  {description: "Closed", value: "CLOSED_TEMPORARILY"},
  {description: "Closed Temporarily", value: "CLOSED_PERMANENTLY"},
]

const validationSchema = Yup.object({
  shortName: Yup.string().required().label("Name "),
  description: Yup.string().required().label("Description "),
  cityId: Yup.number().required().label("City "),
  type: Yup.string().required().label("Category "),
});

const PoiEditModal = ({setOpenModal, pointOfInterest = {}, cities, search, getPlaces, setOpenSuccessModal}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false, title: '', subTitle: '', onClick: () => {
    }
  })

  const styles = useStyles();
  const {status: currentStatus, id} = pointOfInterest;

  useEffect(() => {
    search();
  }, [])

  const handleSubmit = async (values) => {
    const body = {
      shortName: values.shortName,
      description: values.description,
      cityId: values.cityId,
      type: values.type
    }

    setIsLoading(true);

    const response = await axios
      .put(`${host}/place/${id}`, body, config())
      .catch((e) => alert(e));

    if (response?.status >= 200 && response?.status < 300) {
      getPlaces();
      setOpenSuccessModal({
        open: true,
        content: `Updated ${values.shortName} Successfully.`
      });
    } else {
      setOpenSuccessModal({
        open: true,
        header: "Failed",
        content: `Failed to update ${values.shortName}.`
      });
    }
    setOpenModal(false);
    setIsLoading(false);
  }

  const cityTypes = [];
  if (cities) {
    cities?.content.map(city => cityTypes.push({
      value: city.id,
      description: city.cityName
    }));
  }

  const handlePlaceStatusChange = (status) => {
    setConfirmDialog({
      isOpen: true,
      title: 'Are you sure to change status?',
      subTitle: `New status:   ${status.description.toUpperCase()}`,
      onClick: () => changePlaceStatus(status.value)
    });
  }

  const changePlaceStatus = (status) => {
    axios
      .put(`${host}/place/${id}/status`, {status}, config())
      .catch((e) => alert(e))
      .finally(() => {
        getPlaces();
        setConfirmDialog({
          isOpen: false,
          title: '',
          subTitle: '',
          onClick: () => {
          }
        })
      });
  }

  return (
    <Card className={styles.card}>
      <DialogTitle
        id="Users Action Modal"
        style={{
          color: "white",
          backgroundColor: "#333399",
          textAlign: "left",
        }}
      >
        <label>Edit POI</label>
      </DialogTitle>
      <div className="card-body">
        <Formik
          initialValues={pointOfInterest}
          validationSchema={validationSchema}
          onSubmit={(values) => handleSubmit(values)}>
          {(formik) => (
            <Form>
              <Grid item xs={12}>
                <Grid container spacing={2} className="formLabel px-3" justify="center">
                  <Grid item xs={12}>
                    <InputTextField name="shortName" label="Name"/>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={2} justify="space-around" className="px-3">
                      {PLACE_TYPES.map((place) =>
                        (<Button key={place.value}
                                 className={place.value === currentStatus ? styles.activeBtn : styles.neutralBtn}
                                 onClick={() => handlePlaceStatusChange(place)}>
                          {place.description}
                        </Button>)
                      )}
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <InputTextField name="description" label="Description"/>
                  </Grid>
                  <Grid item xs={12}>
                    <SelectField name="type" label="Category" options={POI_TYPES}/>
                  </Grid>
                  <Grid item xs={12}>
                    <SelectField name="cityId" label="City" options={cityTypes}/>
                  </Grid>
                  {isLoading && <CircularProgress style={{width: 20, height: 20}}/>}
                </Grid>
              </Grid>
              {!isLoading && <FormNavigation onCancel={() => setOpenModal(false)}/>}
            </Form>
          )}
        </Formik>
      </div>
      <ConfirmationModal
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </Card>
  );
};

const mapStateToProps = (state) => ({
  cities: state.cityReducer.cities
});

const mapDispatchToProps = (dispatch) => ({
  search: (params) => dispatch(search(params)),
  getPlaces: () => dispatch(getPlaces())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withAuth0(PoiEditModal));