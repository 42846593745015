import React, {Component} from "react";
import {connect} from "react-redux";
import {
  getGroundTeamDetails,
  getDailyWorkingHours,
} from "../../../redux/groundTeamUser/groundTeamUser.action";
import {withAuth0} from "@auth0/auth0-react";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Avatar from "@material-ui/core/Avatar";
import {Grid, Tooltip} from "@material-ui/core";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import TextField from "@material-ui/core/TextField";
import "./details.css";
import moment from "moment";
import {sub} from "../../../utils/utils";
import TaskList from "../TaskList";
import {ViewState} from "@devexpress/dx-react-scheduler";
import {
  Scheduler,
  MonthView,
  Toolbar,
  DateNavigator,
  Appointments,
  TodayButton,
} from "@devexpress/dx-react-scheduler-material-ui";
import GroundTeamWorkRecordList from "../GroundTeamWorkRecord/GroundTeamWorkRecordList";
import GroundTeamEventLogList from "../GroundTeamEventLog/GroundTeamEventLogList";


const datetime_format = process.env.REACT_APP_DATETIME_FORMAT;

class GroundTeamDetails extends Component {
  constructor(props) {
    super(props);
    this.currentDate = moment(new Date()).format("YYYY-MM-DD");
  }

  componentDidMount() {
    this.getTeamDetails();
    this.getDailyWorkingHours();
  }

  getTeamDetails() {
    const { history } = this.props;
    this.props.getGroundTeamDetails(history.location.pathname);
    this.isDirty = false;
  }

  getDailyWorkingHours() {
    const uuid = this.props.location?.pathname.replace("/groundTeam/", "");
    const apiURL = `/groundTeam/${uuid}/work-record/calender`;
    this.props.getDailyWorkingHours(apiURL);
  }

  onCurrentDateChange = (date) => {};

  render() {
    const { groundTeamDetails, groundTeamWorkingHours } = this.props;
    if (!groundTeamDetails?.uuid) {
      return <React.Fragment>Loading...</React.Fragment>;
    }
    const schedulerData = [];
    if (groundTeamWorkingHours && groundTeamWorkingHours?.length) {
      groundTeamWorkingHours.map((ele) => {
        const workScheduleObj = {
          startDate: moment(ele?.startAt).format("YYYY-MM-DDTHH:mm"),
          endDate: moment(ele?.startAt)
            .add(ele?.workPeriod > 1 ? ele.workPeriod : 1, "hour")
            .format("YYYY-MM-DDTHH:mm"),
          title: ele?.workPeriod,
        };
        schedulerData.push(workScheduleObj);
      });
    }
    let name = sub(`${groundTeamDetails?.name}`);

    return (
      <React.Fragment>
        <Grid container spacing={2}>
          <Grid item sm={12} xs={12} md={12} lg={7}>
            <Card>
              <CardHeader
                avatar={
                  <Avatar
                    aria-label="User"
                    style={{width: 80, height: 80}}
                    alt="User Photo"
                    src={groundTeamDetails?.avatar}
                  >
                    {groundTeamDetails?.name?.charAt(0) ?? "V"}
                  </Avatar>
                }
                titleTypographyProps={{variant: "h5"}}
                title={name}
                subheader={`Ground Team ID: ${groundTeamDetails.uuid}`}
              />

              <CardActions disableSpacing>
                {/*<Tooltip title="Chat">
                  <IconButton
                    aria-label="Chat"
                    onClick={() => {
                      const { history } = this.props;
                      const routePath = "/ground-team/live-chat";
                      history.push(routePath);
                    }}
                  >
                    <FontAwesomeIcon icon={faComments} />
                  </IconButton>
                  </Tooltip>*/}
                {/* <Tooltip title="Block/Active">
                  <IconButton
                    onClick={() => {
                      alert("Testing Environment, Not Applicable Yet");
                    }}
                  >
                    <LockIcon />
                  </IconButton>
                  </Tooltip> */}
              </CardActions>
            </Card>
          </Grid>
          <Grid item sm={12} xs={12} md={12} lg={5}>
            <Card style={{height: "100%"}}>
              <CardContent>
                <Row>
                  <Col xs={12} md={4} className="userDetailsFormLabel">
                    <div className="text-center">
                      {" "}
                      <h6>Succcessful Tasks</h6>
                    </div>
                    <div className="text-center">
                      <h3 className="primary">
                        {groundTeamDetails?.countOfSuccessfulTask ?? 0}
                      </h3>
                    </div>
                  </Col>
                  <Col xs={12} md={4} className="userDetailsFormLabel">
                    <div className="text-center">
                      {" "}
                      <h6>No. Failed Tasks</h6>
                    </div>
                    <div className="text-center">
                      <h3 className="primary">
                        {" "}
                        {groundTeamDetails?.countOfFailedTask ?? 0}
                      </h3>
                    </div>
                  </Col>
                  <Col xs={12} md={4} className="userDetailsFormLabel">
                    <div className="text-center">
                      {" "}
                      <h6>Work Hours</h6>
                    </div>
                    <div className="text-center">
                      <h3 className="primary">
                        {groundTeamDetails?.workingHours ?? 0}
                      </h3>
                    </div>
                  </Col>
                </Row>
              </CardContent>
              <CardActions disableSpacing></CardActions>
            </Card>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item sm={12} xs={12} md={12} lg={12}>
            <Card>
              <CardHeader title="Details"/>
              <CardContent>
                <Row>
                  <Col xs={12} md={6} className="userDetailsFormRowCol">
                    <TextField
                      id="name"
                      label="Name"
                      className=""
                      value={groundTeamDetails?.name}
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                    />
                  </Col>
                  <Col xs={12} md={6} className="userDetailsFormRowCol">
                    <TextField
                      id="email"
                      label="Email"
                      fullWidth
                      value={groundTeamDetails?.email}
                      InputProps={{
                        readOnly: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                    />
                  </Col>
                  <Col xs={12} md={6} className="userDetailsFormRowCol">
                    <TextField
                      id="phone"
                      label="Phone"
                      fullWidth
                      value={groundTeamDetails?.phone}
                      InputProps={{
                        readOnly: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                    />
                  </Col>
                  <Col xs={12} md={6} className="userDetailsFormRowCol">
                    <TextField
                      id="status"
                      label="Status"
                      fullWidth
                      value={groundTeamDetails?.status}
                      InputProps={{
                        readOnly: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                    />
                  </Col>
                  <Col xs={12} md={6} className="userDetailsFormRowCol">
                    <TextField
                      id="employmentDate"
                      label="Employment Date"
                      fullWidth
                      type="date"
                      value={groundTeamDetails?.employmentDate}
                      InputProps={{
                        readOnly: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                    />
                  </Col>
                  <Col xs={12} md={6} className="userDetailsFormRowCol">
                    <TextField
                      id="workingHours"
                      label="Working Hours"
                      fullWidth
                      type="number"
                      value={groundTeamDetails?.workingHours ?? 0}
                      InputProps={{
                        readOnly: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                    />
                  </Col>
                  <Col xs={12} md={6} className="userDetailsFormRowCol">
                    <TextField
                      id="countOfSuccessfulTask"
                      label="No Successful Tasks"
                      fullWidth
                      type="number"
                      value={groundTeamDetails?.countOfSuccessfulTask ?? 0}
                      InputProps={{
                        readOnly: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                    />
                  </Col>
                  <Col xs={12} md={6} className="userDetailsFormRowCol">
                    <TextField
                      id="countOfFailedTask"
                      label="No Failed Tasks"
                      fullWidth
                      type="number"
                      value={groundTeamDetails?.countOfFailedTask ?? 0}
                      InputProps={{
                        readOnly: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                    />
                  </Col>
                </Row>
              </CardContent>
            </Card>
          </Grid>
          <Grid item md={12} lg={12}>
            <Card>
              <CardHeader title="Daily Working Hours"/>
              <CardContent>
                <Scheduler data={schedulerData}>
                  <ViewState
                    defaultCurrentDate={this.currentDate}
                    onCurrentDateChange={this.onCurrentDateChange}
                  />
                  <MonthView/>
                  <Toolbar/>
                  <DateNavigator/>
                  <TodayButton/>
                  <Appointments/>
                </Scheduler>
              </CardContent>
            </Card>
          </Grid>
          <Grid item lg={12}>
            <GroundTeamWorkRecordList
              uuid={this.props.location?.pathname.replace("/groundTeam/", "")}
              filters={[]}
            />
          </Grid>
          <Grid item lg={12}>
            <TaskList
              filters={[
                {
                  key: "assigneeUuid",
                  value: `${this.props.location?.pathname.replace("/groundTeam/", "")}`,
                },
              ]}
            />
          </Grid>
          <Grid item lg={12}>
            <GroundTeamEventLogList
              key={`vehicle-ground-team-event-log-list-${this.uuid}`}
              uuid={this.props.location?.pathname.replace("/groundTeam/", "")}
              filters={[]}
            />
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  groundTeamDetails: state.groundTeamUserReducer.groundTeamDetails,
  groundTeamWorkingHours: state.groundTeamUserReducer.groundTeamWorkingHours,
});
const mapDispatchToProps = (dispatch) => ({
  getGroundTeamDetails: (path) => dispatch(getGroundTeamDetails(path)),
  getDailyWorkingHours: (path) => dispatch(getDailyWorkingHours(path)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withAuth0(GroundTeamDetails));
