import {BatteryActionTypes} from "./battery.types";

const INITIAL_STATE = {
  batteries: undefined,
  battery: undefined,
};

const batteryReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case BatteryActionTypes.SET_BATTERIES:
            return {
              ...state,
              batteries: action.payload
            };
        case BatteryActionTypes.SET_BATTERY:
            return {
              ...state,
              battery: action.payload
            };
        default:
            return state;
    }
};

export default batteryReducer;
