import React from "react";
import {Marker} from "react-leaflet";
import PropTypes from "prop-types";
import ParkingPopup from "./ParkingPopup";
import {Icon} from "leaflet";

const parkingIcon = {
  ACTIVE: "/icon/active-parking.svg",
  INACTIVE: "/icon/inactive-parking.svg"
}


class ParkingMarker extends React.Component {
  static propTypes = {
    parking: PropTypes.object.isRequired,
  };
  onSuccessParkingCreation() {
    const { callback } = this.props;
    callback();
  }

  render() {
    const { parking } = this.props;
    console.log(parking.lat)
    if (!parking?.lat || !parking?.lng) {
      console.error("Error position", parking?.location);
      return <React.Fragment />;
    }

    const position = [parking?.lat, parking?.lng];

    const determineParkingIconUrl = (parking) => {
      return parking?.status === 'ACTIVE' ? parkingIcon['ACTIVE']
            : parkingIcon['INACTIVE'];
    };

    const iconUrl = determineParkingIconUrl(parking);

    const icon = new Icon({
      iconUrl: `${iconUrl}`,
      iconSize: [25, 25],
    });

    return (
      <Marker
        key={`marker-key-${parking?.uuid}`}
        position={position}
        icon={icon}
      >
        <ParkingPopup
          callback={() => this.onSuccessParkingCreation()}
          parking={parking}
        />
      </Marker>
    );
  }
}

export default ParkingMarker;
