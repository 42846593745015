import React from 'react';
import {DialogTitle, Grid} from "@material-ui/core";
import {CheckboxField, CustomSelect, DatePickerField, NumberField,} from "../../../../components/Form/FormElements";


const NormalVoucherCreate = ({cities}) => {

  return (
    <React.Fragment>
      <DialogTitle
        id="Users Action Modal"
        style={{
          color: "white",
          backgroundColor: "#333399",
          textAlign: "center",
        }}
      >
        <label>Create Voucher</label>
      </DialogTitle>
      <Grid container spacing={2} className="formLabel px-3">
        <Grid item xs={12}>
          <CustomSelect name="citiesUuid" options={cities} label="Cities"/>
        </Grid>
        <Grid item xs={12}>
          <NumberField readOnly={false} name="amount" label="Value of the Voucher"/>
        </Grid>
        <Grid item xs={12}>
          <CheckboxField readOnly={false} name="reusable" label="Reusable"/>
        </Grid>
        <Grid item xs={12}>
          <NumberField readOnly={false} name="quantity" label="Quantity"/>
        </Grid>
        <Grid item xs={12}>
          <DatePickerField name="startAt" label="From" readOnly={false}/>
        </Grid>
        <Grid item xs={12}>
          <DatePickerField name="endAt" label="To" readOnly={false}/>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default NormalVoucherCreate;