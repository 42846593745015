import axios from "axios";

import {config, getAccessToken} from "../../auth/auth";
import {BusinessActionTypes} from "./business.types";

const host = process.env.REACT_APP_HOST

export const search  = (queryParams) =>{
  const config = {
    headers: {
      'Authorization': `Bearer ${getAccessToken()}`,
      'Content-Type': 'application/json'
    },
    params: queryParams
  }

  return async dispatch => {
    return axios.get(`${host}/business`, config)
      .then(
        response =>dispatch(setBusinesses(response?.data)),
        err => console.log(err)
      );
  }
}

export const topup = (businessUuid, amount) => {
  return axios.post(`${host}/business/${businessUuid}/topup`, {amount: amount}, config());
}

export const active = (businessUuid) => {
  return axios.put(`${host}/business/${businessUuid}/active`, {}, config());
}

export const inactive = (businessUuid, amount) => {
  return axios.put(`${host}/business/${businessUuid}/inactive`, {}, config());
}

export const getOrderMetrics  = (businessUuid) => {
  return async dispatch => {
    return axios.get(`${host}/statistics/order/daily?businessUuid=${businessUuid}`, config())
      .then(
        response => dispatch(SetOrderMetrics(response.data)),
        err => console.log(err)
      );
  }
}

export const getBusinessByUuid  = (uuid) => {
  return async dispatch => {
    return axios.get(`${host}/business/${uuid}`, config())
      .then(
        response => dispatch(setBusiness(response.data)),
        err => console.log(err)
      );
  }
}

export const updateBusiness  = (business) => {
  const body = {
    businessAddress: business?.businessAddress,
    businessContact: business?.businessContact,
    businessName: business?.businessName,
    discount: business?.discount,
    verificationCriteria: business?.verificationCriteria,
    verificationMethod: business?.verificationMethod
  }
  return async dispatch => {
    return axios.put(`${host}/business/${business.uuid}`, body, config())
      .then(
        response => {},
        err => console.log(err)
      );
  }
}

export const setBusiness = (payload) => ({
  type: BusinessActionTypes.SET_BUSINESS,
  payload: payload,
});

export const SetOrderMetrics = (payload) => ({
  type: BusinessActionTypes.SET_ORDER_METRICS,
  payload: payload,
});

export const setBusinesses = (payload) => ({
  type: BusinessActionTypes.SET_BUSINESSES,
  payload: payload,
});