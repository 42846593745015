import React from "react";
import {LayerGroup, LayersControl, MapContainer, Marker, TileLayer,} from "react-leaflet";
import {Icon} from "leaflet";
import {useFullScreenHandle} from "react-full-screen";
import "react-leaflet-fullscreen/dist/styles.css";

const repairIcon = {
  EBIKE: "/icon/maintenance-pending-ebike.svg",
  EKICKSCOOTER: "/icon/maintenance-pending-kickscooter.svg/",
  EMOPED: "/icon/maintaining-moped.svg",
  BIKE: "/icon/maintaining-bike.svg",
}
const lowBatteryIcon = {
  EBIKE: "/icon/low-battery-ebike.svg",
  EKICKSCOOTER: "/icon/low-battery-kickscooter.svg",
  EMOPED: "/icon/low-battery-moped.svg",
  BIKE: "/icon/low-battery-bike.svg",
}
const normalIcon = {
  EBIKE: "/icon/available-ebike.svg",
  EKICKSCOOTER: "/icon/available-kickscooter.svg",
  EMOPED: "/icon/available-moped.svg",
  BIKE: "/icon/available-bike.svg",
}

export default function OrderMapPopUp(props) {
  const order = props.order;
  const field  = props.field;
  const position = order[field] ? [order[field]['lat'], order[field]['lng']]: [0,0];
  const vehicle = order?.vehicle;
  const handle = useFullScreenHandle();

  const determineVehicleIconUrl = (vehicle) => {
    if (vehicle?.status === 'MAINTAINING' || vehicle?.status === 'MAINTENANCE_PENDING')
      return repairIcon[`${vehicle?.category}`];
    else if (vehicle?.batteryLevel < 20)
      return lowBatteryIcon[`${vehicle?.category}`];
    else
      return normalIcon[`${vehicle?.category}`];
  }

  const iconUrl = determineVehicleIconUrl(vehicle);

  const icon = new Icon({
    iconUrl: `${iconUrl}`,
    iconSize: [25, 25]
  });

  return (
    <React.Fragment>
      <MapContainer
        center={position}
        zoom={13}
        scrollWheelZoom={true}
        key={`vehicle-detail-map-position-${JSON.stringify(position)}`}
      >
        <LayersControl position="topright">
          <LayersControl.BaseLayer checked name="Black And White">
            <TileLayer
              attribution='Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> Imagery © <a href="https://www.mapbox.com/">Mapbox</a>'
              url="https://api.mapbox.com/styles/v1/mapbox/dark-v10/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoidHJhY2RldmVsb3BlciIsImEiOiJja2szOGJteHMxOGxhMm9wYzFhbmgwazkyIn0._UOy-teYfb2kzsEMdr8YkA"
            />
          </LayersControl.BaseLayer>
          <LayersControl.BaseLayer name="Normal Map">
            <TileLayer
              attribution='Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> Imagery © <a href="https://www.mapbox.com/">Mapbox</a>'
              url="https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoidHJhY2RldmVsb3BlciIsImEiOiJja2szOGJteHMxOGxhMm9wYzFhbmgwazkyIn0._UOy-teYfb2kzsEMdr8YkA"
            />
          </LayersControl.BaseLayer>
          <LayersControl.BaseLayer name="Satellite Map">
            <TileLayer
              attribution='Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> Imagery © <a href="https://www.mapbox.com/">Mapbox</a>'
              url="https://api.mapbox.com/styles/v1/mapbox/satellite-v9/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoidHJhY2RldmVsb3BlciIsImEiOiJja2szOGJteHMxOGxhMm9wYzFhbmgwazkyIn0._UOy-teYfb2kzsEMdr8YkA"
            />
          </LayersControl.BaseLayer>
          <LayersControl.Overlay checked name="vehicle">
            <LayerGroup>
              <Marker
                key={`marker-key-${order.uuid}`}
                position={position}
                icon={icon}
              >
              </Marker>
            </LayerGroup>
          </LayersControl.Overlay>
        </LayersControl>
      </MapContainer>
    </React.Fragment>
  );
}
