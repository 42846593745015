import {AppActionTypes} from "./app.types";
import axios from "axios";

import {config, setAccessToken} from "../../auth/auth";

const host = process.env.REACT_APP_HOST
const auth0_audience = process.env.REACT_APP_AUTH0_AUDIENCE

export const getCurrentAdminUser = (auth0) => {
  return async dispatch => {

    const accessToken = await auth0.getAccessTokenSilently({
      audience: auth0_audience,
      scope: "read:current_user",
    });

    setAccessToken(accessToken);

    const config = {
      headers: {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
      }
    };

    const request = axios.get(`${host}/consoleUser/me`, config);

    return request.then(
      response => {
        dispatch(setCurrentAdminUser(response.data));
      },
      e => {
        if (e?.response?.status === 403 || e?.response?.status === 401) {
          dispatch(setCurrentAdminUser(e?.response?.status));
        }else {
          alert(JSON.stringify(e?.response));
        }
      }
    );
  }
}

export const getPageRoles = () => {
  return async dispatch => {
    return axios.get(`${host}/page`, config()).then(
      response => {dispatch(setPageRoles(response.data))},
      err => console.log(err)
    );
  }
}

export const setCustomerServiceChannels = (payload) => {
  return async dispatch => {
    dispatch(_setCustomerServiceChannels(payload));
  }
}
export const setLiveChatConfig = (payload) => {
  return async dispatch => {
    dispatch(_setLiveChatConfig(payload));
  }
}
export const setUnreadMessages = (payload) => {
  return async dispatch => {
    dispatch(_setUnreadMessages(payload));
  }
}

export const setUnreadMessagesCount = (payload) => {
  return async dispatch => {
    dispatch(_setUnreadMessagesCount(payload));
  }
}

export const setChatClient = (payload) => {
  return async dispatch => {
    dispatch(_setChatClient(payload));
  }
}

export const setFirebaseApp = (payload) => {
  return async dispatch => {
    dispatch(_setFirebaseApp(payload));
  }
}

export const setPageRoles = (payload) => ({
  type: AppActionTypes.SET_PAGE_ROLES,
  payload: payload,
});

export const _setCustomerServiceChannels = (payload) => ({
  type: AppActionTypes.SET_CUSTOMER_SERVICE_CHANNELS,
  payload: payload,
});

export const _setLiveChatConfig = (payload) => ({
  type: AppActionTypes.SET_LIVE_CHAT_CONFIG,
  payload: payload,
});

export const _setUnreadMessages = (payload) => ({
  type: AppActionTypes.SET_UNREAD_MESSAGES,
  payload: payload,
});

export const setCurrentAdminUser = (payload) => ({
  type: AppActionTypes.SET_CURRENT_ADMIN_USER,
  payload: payload,
});

export const _setUnreadMessagesCount = (payload) => ({
  type: AppActionTypes.SET_UNREAD_MESSAGES_COUNT,
  payload: payload,
});

export const _setChatClient = (payload) => ({
  type: AppActionTypes.SET_CHAT_CLIENT,
  payload: payload,
});

export const _setFirebaseApp = (payload) => ({
  type: AppActionTypes.SET_FIREBASE_APP,
  payload: payload,
});