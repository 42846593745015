import React from "react";
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions, DialogContent, DialogContentText,
  DialogTitle
} from "@material-ui/core";
import {green, red} from "@material-ui/core/colors";

class AlertTitle extends React.Component {
  render() {
    return null;
  }
}

AlertTitle.propTypes = {children: PropTypes.node};

class Switch extends React.Component {
  constructor(props) {
    super(props);
  }

  static propTypes = {
    open: PropTypes.bool.isRequired,
    header: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired
  };

  render() {
    const { header="Success", content, onClose, open} = this.props;

    return (
      <React.Fragment>
        <Dialog
          open={open}
          onClose={this.handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title" style={{color: `${header === 'Success' ? green[800] : red[500]}`}}>{header}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {content}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => {onClose()}}>
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

export default Switch;