import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import AddIcon from "@material-ui/icons/Add";
import {withStyles} from "@material-ui/core/styles";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from "prop-types";
import axios from "axios";
import {connect} from "react-redux";
import { search as getCities } from "../../../redux/city/action";

import {config} from "../../../auth/auth";
import {Avatar, CircularProgress, Grid} from "@material-ui/core";
import CardContent from "@material-ui/core/CardContent";
import "./details.css";
import {DateTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import MomentUtils from '@date-io/moment';
import moment from "moment";



const defaultToolbarStyles = {
  iconButton: {
  },
};

const datetime_format = process.env.REACT_APP_DATETIME_FORMAT;
const host = process.env.REACT_APP_HOST

class Create extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      open: false,
      subject: undefined,
      content: undefined,
      cityUuid: undefined,
      startAt: moment(moment.now()).local(),
      endAt: moment(moment(moment.now()).local()).add(30, 'days'),
      category: "POPUP",
      purpose: "GOVERNMENT",
      file: undefined
    };
  }

  static propTypes = {
    refresh: PropTypes.func.isRequired
  };

  componentDidMount() {
    if (!this.props?.cities) {
      this.props.getCities();
    }
  }

  handleClick = () => {
    this.setState({open: true});
  }

  cancel = () => {
    this.setState({open: false});
  };

  create = () => {
    const { refresh } = this.props;
    if (this.state.isLoading)
      return;
    const body = {
      subject: this.state.subject,
      content: this.state.content,
      cityUuid: this.state.cityUuid,
      startAt: moment(this.state.startAt).utc().format("YYYY-MM-DDTHH:mm"),
      endAt: moment(this.state.endAt).utc().format("YYYY-MM-DDTHH:mm"),
      category: this.state.category,
      purpose: this.state.purpose
    }
    this.setState({isLoading: true})
    return axios.post(`${host}/news`, body, config())
      .then((response) => {})
      .catch(e => alert(e))
      .finally(() => {
        this.setState({isLoading: false, open: false, subject: undefined, content: undefined, file:undefined, cityUuid: undefined});
        refresh();
      });
  };

  onChangeSubject = (e) => {
    this.setState({subject: e.target.value})
  };
  onChangeContent = (e) => {
    this.setState({content: e.target.value})
  };
  onChangeStartAt = (e) => {
    this.setState({startAt: e})
  };
  onChangeEndAt = (e) => {
    this.setState({endAt: e})
  };
  onChangeCategory = (e) => {
    this.setState({category: e.target.value})
  };
  onChangecityUuid = (e) => {
    this.setState({cityUuid: e.target.value})
  };

  render() {
    const { cities, classes } = this.props;

    return (
      <React.Fragment>
        <MuiPickersUtilsProvider utils={MomentUtils}>
        <Tooltip title={"NEW"}>
          <IconButton className={classes.iconButton} onClick={this.handleClick}>
            <AddIcon className={classes.deleteIcon} />
          </IconButton>
        </Tooltip>
        <Dialog open={this.state.open} onClose={this.cancel} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title" className="primary">
            <h4>Create News</h4>
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              To create a news, please enter here.
            </DialogContentText>
            <Grid container spacing={2}>
              <Grid item sm={12} xs={12} md={12} lg={12}>
                  <CardContent>
                    <Grid container spacing={2} className="formLabel">
                      <Grid item md={12} lg={12}>
                          <Select
                            id="news-create-category-id"
                            value={this.state.category}
                            onChange={this.onChangeCategory}
                            inputProps={{
                              name: 'Category',
                            }}
                            fullWidth
                            InputLabelProps={{
                              shrink: true,
                            }}
                            variant="outlined"
                          >
                            {/* <MenuItem value="BANNER">Banner News</MenuItem> */}
                            <MenuItem value="POPUP">Pop Up News</MenuItem>
                            {/* <MenuItem value="NOTIFICATION">Notification News</MenuItem> */}
                          </Select>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} className="formLabel">
                      <Grid item md={12} lg={12}>
                          <TextField
                            id="news-create-city-id"
                            select
                            label="City"
                            value={this.state.cityUuid}
                            fullWidth
                            onChange={this.onChangecityUuid}
                            InputProps={{
                              readOnly: false,
                            }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            variant="outlined"
                          >
                            <MenuItem key={"allcity"} value={undefined}>All City</MenuItem>
                            {(cities || []).map((city, index) => (
                              <MenuItem key={index} value={city.uuid}>
                                {city.cityName}
                              </MenuItem>
                            ))}
                          </TextField>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} className="formLabel">
                      <Grid item md={12} lg={12}>
                        <TextField
                          id="news-create-subject-id"
                          label="Subject"
                          fullWidth
                          value={this.state.subject}
                          onChange={this.onChangeSubject}
                          InputProps={{
                            readOnly: false,
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} className="formLabel">
                      <Grid item md={12} lg={12}>
                        <TextField
                          id="news-create-content-id"
                          label="Content"
                          fullWidth
                          value={this.state.content}
                          InputProps={{
                            readOnly: false,
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          variant="outlined"
                          onChange={this.onChangeContent}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} className="formLabel">
                      <Grid item md={12} lg={6}>
                        <DateTimePicker
                          label="Start Time"
                          inputVariant="outlined"
                          value= {this.state.startAt}
                          onChange={this.onChangeStartAt}
                          format={datetime_format}
                        />
                      </Grid>
                      <Grid item md={12} lg={6}>
                        <DateTimePicker
                          label="End Time"
                          inputVariant="outlined"
                          value={this.state.endAt}
                          onChange={this.onChangeEndAt}
                          format={datetime_format}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
              <React.Fragment>
                {this.state.isLoading ? <CircularProgress style={{width:20, height:20}}/> : ""}
                <Button
                  onClick={this.create}
                  style={{color: "white", backgroundColor: "#36b9cc"}}
                >
                  Create
                </Button>
                <Button
                  onClick={this.cancel}
                  color="primary"
                  disabled={this.state.isLoading}
                >
                  Cancel
                </Button>
              </React.Fragment>
          </DialogActions>
        </Dialog>
        </MuiPickersUtilsProvider>
      </React.Fragment>
    );
  }

}

const mapStateToProps = (state) => ({
  cities: state.cityReducer.cities?.content,
});
const mapDispatchToProps = (dispatch) => ({
  getCities: (params) => dispatch(getCities(params)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(defaultToolbarStyles, { name: "Create" })(Create));
