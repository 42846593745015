import React, {Component} from 'react'
import ReactEcharts from 'echarts-for-react'


export default class AreaChart extends Component {
    
    getOption = ()=>{
        const {date} = this.props
        const {data} = this.props
        const {label} = this.props
        const {areaStyle} = this.props
        const {lineStyle} = this.props
        const {yAxisLabel} = this.props

        let option = {
            xAxis: {
                type: 'category',
                boundaryGap: false,
                data: date,
                axisLabel: {
                    show: true,
                    textStyle: {
                        fontFamily: 'Urbanist'
                    }
                }
            },
            grid: {
                containLabel: true
            },
            yAxis: {
                type: 'value',
                axisLabel: {
                    formatter: function (value, index) {
                        if(yAxisLabel) {
                            return value + ' ' + yAxisLabel;
                        } else {
                            return value ;
                        }
                        
                    },
                    textStyle: {
                        fontFamily: 'Urbanist'
                    }
                }
            },
            tooltip:{
                        trigger: 'axis',
                        formatter: '{b0}: ' + '{c0} ' + label ,
                        textStyle: {
                            fontFamily: 'Urbanist'
                        }
                    },
            series: [{
                data: data,
                type: 'line',
                smooth: true,
                areaStyle: areaStyle,
                lineStyle: lineStyle,
                formatter: '{c0} ' + label,
                label: {
                    show: true,
                },
                textStyle: {
                    fontFamily: 'Urbanist'
                }
            }]
        };
        return option;
    }
    render() {
        return (
            <ReactEcharts style={{width:'100%',height:'50vh'}} option={this.getOption()}/>
        )
    }
}

//https://echarts.apache.org/examples/zh/index.html