import React, {Component} from "react";
import MUIDataTable from "mui-datatables";
import {withAuth0} from "@auth0/auth0-react";
import {Button, Tooltip,} from "@material-ui/core";
import {connect} from "react-redux";
import moment from "moment";
import {search} from "../../redux/consoleEventLog/consoleEventLog.action";
import {queryParams} from "../../utils/utils";
import PropTypes from "prop-types";
import ConsoleActionStatus from "./ConsoleActionStatus";
import CustomToolbar from "../../components/List/CustomToolBar/CustomToolbar";

const datetime_format = process.env.REACT_APP_DATETIME_FORMAT;

class ConsoleEventLogList extends Component {
  constructor(props) {
    super(props);
    this.isFilterDialogOpen = false;
    this.tableRef = React.createRef();
    this.columnsDisplay = undefined;
  }

  static propTypes = {
    filters: PropTypes.array,
  };

  componentDidMount() {
    this.refresh();
  }

  refresh = () => {
    const {search, filters} = this.props;

    // table state
    const state = this.tableRef?.current?.state;

    const params = queryParams(this.props, state);

    filters?.map((f) => params.set(f.key, f.value));

    search(params);
  };

  onChangeColumnDisplay = () => {
    const state = this.tableRef?.current?.state;
    this.columnsDisplay = state.columns.reduce(
      (obj, item) => Object.assign(obj, {[item.name]: item.display}),
      {}
    );
  };

  isDisplay = (name) => {
    if (!this.columnsDisplay || !this.columnsDisplay.hasOwnProperty(name))
      return undefined;
    else return this.columnsDisplay[name];
  };

  render() {
    const {consoleEventLogs} = this.props;
    if (!consoleEventLogs) return <React.Fragment>Loading...</React.Fragment>;
    const list = consoleEventLogs?.content;
    const totalElements = consoleEventLogs?.totalElements ?? 0;
    const rowsPerPage = consoleEventLogs?.pageable?.pageSize ?? 10;
    const page = consoleEventLogs?.pageable?.pageNumber ?? 0;


    const columns = [
      {
        name: "createdAt",
        label: "Action Time",
        options: {
          filter: false,
          sort: false,
          display: this.isDisplay("createdAt") ?? true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <Tooltip title={value ? moment.utc(value).local().format(datetime_format) : ""}>
                <div>{value ? moment.utc(value).local().format(datetime_format) : ""}</div>
              </Tooltip>
            );
          },
        },
      },
      {
        name: "eventType",
        label: "Action Object",
        options: {
          filter: true,
          sort: false,
          display: this.isDisplay("eventType") ?? true,
          customFilterListOptions: {render: (v) => `eventType: ${v}`},
        },
      },
      {
        name: "event",
        label: "Action",
        options: {
          filter: true,
          sort: false,
          display: this.isDisplay("event") ?? true,
          customFilterListOptions: {render: (v) => `event: ${v}`},
        },
      },
      {
        name: "orderUuid",
        label: "Related Order",
        options: {
          filter: false,
          sort: false,
          display: this.isDisplay("orderUuid") ?? true,
          customFilterListOptions: {render: (v) => `orderUuid: ${v}`},
        },
      },
      {
        name: "actionContent",
        label: "Operation Content",
        options: {
          filter: false,
          sort: false,
          display: this.isDisplay("actionContent") ?? true
        },
      },
      {
        name: "responseStatus",
        label: "Action Status",
        options: {
          filter: false,
          sort: false,
          display: this.isDisplay("actionContent") ?? true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (<ConsoleActionStatus status={value}/>);
          },
        },
      },
      {
        name: "createdByUserName",
        label: "Executor",
        options: {
          filter: false,
          sort: false,
          display: this.isDisplay("createdByUserName") ?? true
        }
      },
    ];

    const options = {
      search: false,
      viewColumns: false,
      filter: true,
      confirmFilters: true,
      serverSide: true,
      filterType: "dropdown",
      responsive: "vertical",
      rowsPerPageOptions: [10, 20, 50, 100],
      rowsPerPage: rowsPerPage,
      count: totalElements,
      page: page,
      customToolbarSelect: () => {
      },
      onTableChange: (action, tableState) => {
        switch (action) {
          case "viewColumnsChange":
            this.onChangeColumnDisplay();
            break;
          case "onFilterDialogOpen":
            this.isFilterDialogOpen = true;
            break;
          case "filterChange":
            if (this.isFilterDialogOpen) return;
            else this.refresh();
            break;
          case "onFilterDialogClose":
            this.isFilterDialogOpen = false;
          case "changePage":
          case "sort":
          case "changeRowsPerPage":
            this.refresh();
            break;
          default:
            console.log("Not handled action " + action);
        }
      },
      customFilterDialogFooter: (currentFilterList, applyNewFilters) => {
        return (
          <div style={{marginTop: "40px"}}>
            <Button
              variant="contained"
              style={{color: "white", backgroundColor: "#36b9cc"}}
              onClick={() => {
                applyNewFilters();
              }}
            >
              Apply
            </Button>
          </div>
        );
      },
      customToolbar: () => {
        return (
          <CustomToolbar refresh={this.refresh} tableProps={this.props}/>
        );
      }
    };

    return (
      <React.Fragment>
        <MUIDataTable
          title={"Console Action Logs"}
          data={list}
          columns={columns}
          options={options}
          ref={this.tableRef}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  consoleEventLogs: state.consoleEventLogReducer.consoleEventLogs,
});

const mapDispatchToProps = (dispatch) => ({
  search: (params) => dispatch(search(params)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withAuth0(ConsoleEventLogList));
