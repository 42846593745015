import React, {useMemo, useRef, useState} from 'react';
import {Marker} from "react-leaflet";
import {Icon} from "leaflet";
import PoiPopup from "./POIPopup";
import {determinePlaceImage} from "../../../utils/utils";
import {Point} from "leaflet/dist/leaflet-src.esm";

const PoiMarker = ({pointOfInterest}) => {
    const [draggable, setDraggable] = useState(false)
    const [position, setPosition] = useState({
      lat: pointOfInterest.lat,
      lng: pointOfInterest.lng
    })
    const markerRef = useRef(null)
    const eventHandlers = useMemo(
      () => ({
        dragend() {
          const marker = markerRef.current
          if (marker != null) {
            setPosition(marker.getLatLng())
          }
        },
      }),
      [],
    )

    const icon = new Icon({
      iconUrl: `${pointOfInterest.icon ? pointOfInterest.icon : determinePlaceImage(pointOfInterest.type)}`,
      iconSize: [30, 30],
      iconAnchor: new Point(0, 0),
      popupAnchor: new Point(16, 0),
    });

    return (
      <Marker
        draggable={draggable}
        eventHandlers={eventHandlers}
        ref={markerRef}
        key={`marker-key-${pointOfInterest?.uuid}`}
        position={position}
        icon={icon}
      >
        <PoiPopup pointOfInterest={pointOfInterest} setDraggable={setDraggable} position={position} />
      </Marker>
    );
  }
;

export default PoiMarker;