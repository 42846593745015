import React, {Component} from 'react'
import {faCalendar} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {connect} from "react-redux";
import {withAuth0} from "@auth0/auth0-react";
import {getDailyOrders} from "../../../redux/dashboard/dashboard.action";
import {Skeleton} from '@material-ui/lab';
import { withTranslation } from 'react-i18next';

const refresh_frequency = process.env.REACT_APP_REFRESH_FREQUENCY_MINUTES * 60000 ?? 600000

class DailyOrderCard extends Component {

  componentDidMount() {
    if (this.props?.dailyOrders === null || this.props?.dailyOrders === undefined)
      this.refresh();
  }

  refresh = () => {
    this.props.getDailyOrders();
    setTimeout(() => this.refresh(), refresh_frequency);
  }

  render() {
      const { dailyOrders, t } = this.props;
      if (dailyOrders === null || dailyOrders === undefined) {
        return (<div className="col-xl col-md mb-4"> <Skeleton variant="rect" width={"100%"} height={"100%"} /> </div>);
      }
      return (
          <div className="col-xl col-md mb-4">
              <div className="card border-left-primary shadow h-100 py-2">
                <div className="card-body">
                  <div className="row no-gutters align-items-center">
                    <div className="col mr-2">
                      <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">{t('small-cards.order')}</div>
                      <div className="h5 mb-0 font-weight-bold text-gray-800">{dailyOrders ?? 0}</div>
                    </div>
                    <div className="col-auto">
                      <FontAwesomeIcon icon={faCalendar} className="fa-2x text-gray-300"/>
                    </div>
                  </div>
                </div>
              </div>
          </div>
      )
    }
}

const mapStateToProps = (state) => ({
  dailyOrders: state.dashboardReducer.dailyOrders,
});

const mapDispatchToProps = (dispatch) => ({
  getDailyOrders: () => dispatch(getDailyOrders())
});

export default connect(mapStateToProps, mapDispatchToProps)(withAuth0(withTranslation()(DailyOrderCard)));
