import React from "react";
import RegionConfigurationSearchMap from "../../../../components/Map/RegionConfigurationSearchMap";
import OrderList from "../OrderList/OrderList";

class OrderSearch extends React.Component {

  render() {
    return (
      <div>
        <div className="row">
          <div className="col-xl col-lg">
            <div className="card shadow mb-4">
              <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                <h6 className="m-0 font-weight-bold" style={{color: '#333399'}}>Order Search Map</h6>
              </div>

              <div className="card-body">
                <RegionConfigurationSearchMap/>
                <br/>
                <OrderList/>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default OrderSearch;