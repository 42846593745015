const TASK_MAP = {
  ASSIGNED: {
    COLLECTION: "/icon/collect-unfinished.svg",
    DISTRIBUTION: "/icon/redistribution-unfinished.svg",
    LOW_BATTERY: "/icon/replace-battery-unfinished.svg",
    REPAIR: "/icon/repair-unfinished.svg",
    GENERAL_MAINTENANCE:"/icon/general-maintenance-assigned.svg",
    COVID_CLEANING:"/icon/covid-cleaning-assigned.svg"
  },
  FINISHED: {
    COLLECTION: "/icon/collect-finished.svg",
    DISTRIBUTION: "/icon/redistribution-finished.svg",
    LOW_BATTERY: "/icon/replace-battery-finished.svg",
    REPAIR: "/icon/repair-finished.svg",
    GENERAL_MAINTENANCE:"/icon/general-maintenance-finished.svg",
    COVID_CLEANING:"/icon/covid-cleaning-finished.svg"
  },
  UNASSIGNED: {
    COLLECTION:"/icon/collect-unassigned.svg",
    DISTRIBUTION:"/icon/redistribution-unassigned.svg",
    LOW_BATTERY:"/icon/replace-battery-unassigned.svg",
    REPAIR:"/icon/repair-unassigned.svg",
    GENERAL_MAINTENANCE:"/icon/general-maintenance-unassigned.svg",
    COVID_CLEANING:"/icon/covid-cleaning-unassigned.svg"
  },
  CANCELED: {
    COLLECTION:"/icon/task-failed.svg",
    DISTRIBUTION:"/icon/task-failed.svg",
    LOW_BATTERY:"/icon/task-failed.svg",
    REPAIR:"/icon/task-failed.svg",
    GENERAL_MAINTENANCE:"/icon/icon/task-failed.svg",
    COVID_CLEANING:"/icon/icon/task-failed.svg"
  },
  FAILED: {
    COLLECTION:"/icon/task-failed.svg",
    DISTRIBUTION:"/icon/task-failed.svg",
    LOW_BATTERY:"/icon/task-failed.svg",
    REPAIR:"/icon/task-failed.svg",
    GENERAL_MAINTENANCE:"/icon/task-failed.svg",
    COVID_CLEANING:"/icon/task-failed.svg"
  }

}

export const determine = (task) => {
  return TASK_MAP[task.status][task.category];
}