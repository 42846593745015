import React from 'react';
import {Button, ButtonGroup, DialogActions} from "@material-ui/core";
import {grey} from "@material-ui/core/colors";

const FormNavigation = (props) => {
  const {hasPrevious = false, isLastStep = true, onCancel, prevStep} = props;
  return (
    <DialogActions
      style={{
        justifyContent: "space-between",
        padding: "20px 70px 20px 70px",
      }}
    >
      <ButtonGroup>
        <Button
          variant="contained"
          style={{color: "white", backgroundColor: "#333399"}}
          type="submit"
        >
          {isLastStep ? "Submit" : "Continue"}
        </Button>
        {hasPrevious && <Button
          style={{color: "#333399", backgroundColor: "white", borderColor: "#333399"}}
          onClick={prevStep}
        >
          Back
        </Button>}
      </ButtonGroup>
      <Button onClick={onCancel} style={{color: grey[500]}}>
        Cancel
      </Button>
    </DialogActions>
  );
};

export default FormNavigation;