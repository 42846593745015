import {ActionTypes} from "./types";
import axios from "axios";
import {getAccessToken} from "../../auth/auth";

const host = process.env.REACT_APP_HOST

export const search  = (queryParams) =>{
  const config = {
    headers: {
      'Authorization': `Bearer ${getAccessToken()}`,
      'Content-Type': 'application/json'
    },
    params: queryParams
  }

  return async dispatch => {
    return axios.get(`${host}/membershipAccount`, config)
      .then(
        response =>dispatch(setMembershipAccount(response.data)),
        err => console.log(err)
      );
  }
}

export const setMembershipAccount = (payload) => ({
  type: ActionTypes.SET_MEMBERSHIPS_ACCOUNT_LIST,
  payload: payload,
});