import {AdminUserActionTypes} from "./adminUser.types";
import axios from "axios";
import {getAccessToken} from "../../auth/auth";

const host = process.env.REACT_APP_HOST
const maxItemSize = process.env.REACT_APP_MAX_ITEM_SIZE

export const getAdminUsers = (page=0, size=maxItemSize) => {
  const config = {
    headers: {
      'Authorization': `Bearer ${getAccessToken()}`,
      'Content-Type': 'application/json'
    },
    params: {
      page,
      size
    }
  }

  return async dispatch => {
    return axios.get(`${host}/consoleUser`, config)
      .then(
      response => dispatch(setAdminUsers(response.data)),
      err => console.log(err)
    );
  }
}

export const setAdminUsers = (payload) => ({
  type: AdminUserActionTypes.SET_ADMIN_USERS,
  payload: payload,
});