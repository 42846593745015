import Discounting from "../../../SystemConfiguration/Discounting/Discounting";
import Parking from "../../../SystemConfiguration/GeofencingSettings/Parking/Parking";

export const PAGES = {
  "ALL": "All Pages",
  "PARKING_PICTURE_CHECK": "Parking Picture Check",
  "LEGAL_INFORMATION": "Legal Information",
  "SOCIAL_MEDIA":"Social Media",
  "TASK_MAP": "Task Map",
  "USER_LIST": "User List",
  "TASK_LIST": "Task List",
  "REGION_LIST": "Region List",
  "VEHICLE_LIST": "Vehicle List",
  "CITY_LIST": "City List",
  "DASHBOARD": "KPI Dashboard",
  "TASK_CREATION": "Task Creation",
  "PRODUCT": "Product",
  "QUESTIONABLE_ORDER": "Questionable Order",
  "REGION_RULES": "Region Rules",
  "DRIVER_LICENSE_CHECK": "Driver License Check",
  "BATTERY": "Battery",
  "POPUP_NEWS": "Popup News",
  "GROUND_TEAM_LIST": "Ground Team List",
  "WORK_RECORD": "Work Record",
  "TASK_RULES": "Task Rules",
  "REGION_MAP": "Region Map",
  "SUSPICIOUS_USERS": "Suspicious Users",
  "DELETING_USERS": "Deleting Users",
  "ADMIN_USERS": "Admin Users",
  "CORPORATE": "Corporate",
  "ORDER_LIST": "Order List",
  "ADMIN_ROLES": "Admin Roles",
  "VEHICLE_MAP": "Vehicle Map",
  "PRICING_STRUCTURE": "Pricing Structure",
  "TOP_UP_TEMPLATE": "TopUp Template",
  "MIGRATION_PROCESS": "Migration Process",
  "VOUCHER": "Voucher",
  "DISCOUNTING": "Discounting",
  "PARKING": "Parking",
  "REDISTRIBUTION_TASK": "Redistribution Task",
  "BUSINESS": "Business",
  "MEMBERSHIP_STRUCTURE": "Membership Structure",
  "MEMBERSHIP_ACCOUNT_LIST": "Membership Account List",
  "NOTIFICATION_TEMPLATE": "Notification Template",
  "LIVE_CHAT": "Live Chat",
  "ORDER_MAP": "Order Map",
  "PARKING_LIST": "Parking List",
  "POINT_OF_INTEREST": "Point of Interest",
  "DOWNLOAD_DATA": "Download data"
}
