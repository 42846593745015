import React from "react";
import PropTypes from 'prop-types';
import Avatar from "@material-ui/core/Avatar";
import CardHeader from "@material-ui/core/CardHeader";
import moment from "moment";
import {sub} from "../../utils/utils";

import {IconButton, Tooltip} from "@material-ui/core";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import axios from "axios";
import {config} from "../../auth/auth";

const host = process.env.REACT_APP_HOST

class Location extends React.Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
    displayIcon: PropTypes.bool,
    displayLocation: PropTypes.bool,
    vehicleUuid: PropTypes.string.isRequired
  };

  onClickLocation = () => {
    axios.put(`${host}/vehicle/${this.props.vehicleUuid}/locate`, undefined, config());
  }

  render() {
    const { vehicleUuid, location, displayIcon, displayLocation } = this.props;
    if (!location)
      return (<React.Fragment/>);

    const display = displayIcon ?? true;
    const displayLoc = displayLocation ?? true;

    let title = `(${location.lat}, ${location.lng})`
    return (
      <React.Fragment>
        <CardHeader
          avatar={ display ? <IconButton title={title} onClick={() => this.onClickLocation()}><LocationOnIcon/></IconButton> : <React.Fragment/>}
          title=""
          subheader={displayLoc ? title : ""}
          style={{padding:0}}
        />
      </React.Fragment>
    );
  }
}

export default Location;