import React, {Component} from "react";
import PropTypes from "prop-types";
import MUIDataTable from "mui-datatables";
import {withAuth0} from "@auth0/auth0-react";
import {Button, Grid, IconButton, Tooltip} from "@material-ui/core";
import {connect} from "react-redux";
import {searchRegionRuleActions} from "../../../../redux/RegionRule/regionRule.action";
import Delete from "../../../../components/common/Delete";
import EditIcon from "@material-ui/icons/Edit";
import RegionRuleActionModal from "./region-rule-action"

class RegionRuleActions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false
    };
    this.isFilterDialogOpen = false;
    this.tableRef = React.createRef();
    this.columnsDisplay = undefined;
  }

  static propTypes = {
    regionRuleUuid: PropTypes.string.isRequired,
  };

  componentDidMount() {
    this.refresh();
  }

  refresh = () => {
    const { searchRegionRuleActions } = this.props;
    searchRegionRuleActions(this.props.regionRuleUuid);
  };

  handleActionModalClosed = (saved) => {
    this.setState({
      showModal: false,
    });
    if(saved)
      this.refresh();
  };

  onChangeColumnDisplay = () => {
    const state = this.tableRef?.current?.state;
    this.columnsDisplay = state.columns.reduce(
      (obj, item) => Object.assign(obj, { [item.name]: item.display }),
      {}
    );
  };

  isDisplay = (name) => {
    if (!this.columnsDisplay || !this.columnsDisplay.hasOwnProperty(name))
      return undefined;
    else return this.columnsDisplay[name];
  };

  render() {
    const { regionRuleActions } = this.props;
    if (!regionRuleActions) return <React.Fragment>Loading...</React.Fragment>;

    const list = regionRuleActions;
    const totalElements = regionRuleActions?.length ?? 0;

    const columns = [
      {
        name: "uuid",
        label: "UUID",
        options: {
          filter: true,
          sort: false,
          display: this.isDisplay("uuid") ?? false,
          customFilterListOptions: { render: (v) => `UUID: ${v}` },
          filterType: "textField",
        },
      },

      {
        name: "action",
        label: "Action",
        options: {
          filter: true,
          sort: true,
          display: this.isDisplay("action") ?? true,
        },
      },
      {
        name: "timeDelay",
        label: "Delay",
        options: {
          filter: true,
          sort: true,
          display: this.isDisplay("timeDelay") ?? true
        },
      },
      {
        name: "Operations",
        options: {
          filter: false,
          sort: false,
          empty: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div>
                <Tooltip title="Edit">
                  <IconButton
                    aria-label="Edit"
                    onClick={() => {
                      this.setState({
                        showModal: true,
                        actionData: {
                          uuid: tableMeta.rowData[0],
                          action: tableMeta.rowData[1],
                          timeDelay: tableMeta.rowData[2],
                        }
                      });
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                </Tooltip>
                <Delete
                  url={`/regionRuleAction/${tableMeta.rowData[0]}`}
                  callback={() => this.refresh()}
                />
              </div>
            );
          },
        },
      },
    ];

    const options = {
      filter: false,
      confirmFilters: false,
      serverSide: true,
      filterType: "dropdown",
      responsive: "vertical",
      pagination: false,
      download: false,
      print: false,
      search: false,
      count: totalElements,
      onTableChange: (action, tableState) => {
        switch (action) {
          case "viewColumnsChange":
            this.onChangeColumnDisplay();
            break;
          case "onFilterDialogOpen":
            this.isFilterDialogOpen = true;
            break;
          case "filterChange":
            if (this.isFilterDialogOpen) return;
            else this.refresh();
            break;
          case "onFilterDialogClose":
            this.isFilterDialogOpen = false;
          case "sort":
          default:
            //console.log("Not handled action " + action);
        }
      },
      customFilterDialogFooter: (currentFilterList, applyNewFilters) => {
        return (
          <div style={{ marginTop: "40px" }}>
            <Button
              variant="contained"
              style={{ color: "white", backgroundColor: "#36b9cc" }}
              onClick={() => {
                applyNewFilters();
              }}
            >
              Apply
            </Button>
          </div>
        );
      },
    };

    return (
      <React.Fragment>
        <Grid container spacing={2}>
          <Grid item sm={12} xs={12} md={12} lg={12}>
            <Button
              variant="contained"
              color="primary"
              style={{ float: "right" }}
              onClick={() => {
                this.setState({
                  showModal: true,
                  actionData: {
                    action: "SET_SPEED_LIMIT",
                    timeDelay: 0
                  }
                });
              }}
            >ADD</Button>
          </Grid>
        </Grid>
        <MUIDataTable
          data={list}
          columns={columns}
          options={options}
          ref={this.tableRef}
        />

        <RegionRuleActionModal
          open={this.state.showModal}
          callback={this.handleActionModalClosed}
          regionRuleUuid={this.props.regionRuleUuid}
          data={this.state.actionData}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  regionRuleActions: state.regionRuleReducer.regionRuleActions,
});

const mapDispatchToProps = (dispatch) => ({
  searchRegionRuleActions: (params) => dispatch(searchRegionRuleActions(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withAuth0(RegionRuleActions));
