import React, {Component} from "react";
import MUIDataTable from "mui-datatables";
import {withAuth0} from "@auth0/auth0-react";
import {Button, Tooltip} from "@material-ui/core";
import {connect} from "react-redux";
import moment from "moment";

import {search} from "../../../redux/notificationTask/action";
import {queryParams, sub} from "../../../utils/utils";
import Delete from "../../../components/common/Delete";
import Edit from "../NotificationList/Edit";
import Channel from "../NotificationList/Channel";
import CustomToolbar from "./CustomToolbar";
import ExecuteButton from "./ExecuteButton";
import NotificationTaskStatus from "./NotificationTaskStatus";

const date_format = process.env.REACT_APP_DATE_FORMAT;
const datetime_format = process.env.REACT_APP_DATETIME_FORMAT;

class NotificationTask extends Component {
  constructor(props) {
    super(props);
    this.isFilterDialogOpen = false;
    this.tableRef = React.createRef();
    this.columnsDisplay = undefined;
  }

  componentDidMount() {
    this.refresh();
  }

  refresh = () => {
    const {search} = this.props;

    // table state
    const state = this.tableRef?.current?.state;

    const params = queryParams(this.props, state);

    search(params);
  }

  onChangeColumnDisplay = () => {
    const state = this.tableRef?.current?.state;
    this.columnsDisplay = state.columns.reduce((obj, item) => Object.assign(obj, {[item.name]: item.display}), {});
  }

  isDisplay = (name) => {
    if (!this.columnsDisplay || !this.columnsDisplay.hasOwnProperty(name))
      return undefined;
    else
      return this.columnsDisplay[name];
  }

  render() {
    const { notificationTasks } = this.props;
    if (!notificationTasks)
      return (<React.Fragment>Loading...</React.Fragment>);
    
    const list = notificationTasks?.content ?? 0;
    const totalElements = notificationTasks?.totalElements ?? 0;
    const rowsPerPage = notificationTasks?.pageable?.pageSize ?? 10;
    const page = notificationTasks?.pageable?.pageNumber ?? 0;

    const columns = [
      {
        name: "uuid",
        label: "UUID",
        options: {
          filter: true,
          sort: false,
          display: this.isDisplay("uuid") ?? false,
          customFilterListOptions: { render: (v) => `UUID: ${v}` },
          filterType: "textField",
        },
      },
      {
        name: "createdAt",
        label: "Created",
        options: {
          filter: false,
          sort: true,
          display: this.isDisplay("createdAt") ??  false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <Tooltip title={value ? moment(value).format(datetime_format) : ""}>
                <div>{value ? moment(value).format(date_format) : ""}</div>
              </Tooltip>
            );
          },
        },
      },
      {
        name: "updatedAt",
        label: "Latest Updated",
        options: {
          filter: false,
          sort: true,
          display: this.isDisplay("updatedAt") ??  false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <Tooltip title={value ? moment(value).format(datetime_format) : ""}>
                <div>{value ? moment(value).format(date_format) : ""}</div>
              </Tooltip>
            );
          },
        },
      },
      {
        name: "targetType",
        label: "Type",
        options: {
          filter: false,
          sort: false,
          display: this.isDisplay("targetType") ??  true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <Tooltip title={value === 'ALL_USERS' ? 'Send notification to all users' : 'Send notification to all users of the city'}>
                <div>{value === 'ALL_USERS' ? 'All users' : 'All users of the city'}</div>
              </Tooltip>
            );
          },
        },
      },
      {
        name: "cityName",
        label: "City Name",
        options: {
          filter: false,
          sort: false,
          display: this.isDisplay("cityName") ??  true
        },
      },
      {
        name: "messageChannel",
        label: "Channel",
        options: {
          filter: true,
          sort: false,
          display: this.isDisplay("messageChannel") ??  true,
          customBodyRender: (value, tableMeta, updateValue) => {return (<Channel value={value}/>);},
        },
      },
      {
        name: "subject",
        label: "Subject",
        options: {
          filter: false,
          sort: false,
          display: this.isDisplay("subject") ??  true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <Tooltip title={value}>
                <div>{sub(value,25)}</div>
              </Tooltip>
            );
          },
        },
      },
      {
        name: "content",
        label: "Content",
        options: {
          filter: false,
          sort: false,
          display: this.isDisplay("content") ??  true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <Tooltip title={value}>
                <div>{sub(value,80)}</div>
              </Tooltip>
            );
          },
        },
      },
      {
        name: "status",
        label: "Status",
        index: 1,
        options: {
          filter: false,
          sort: false,
          display: this.isDisplay("status") ??  true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <NotificationTaskStatus status={value}/>
            );
          },
        },
      },
      {
        name: "total",
        label: "Total",
        index: 1,
        options: {
          filter: false,
          sort: false,
          display: this.isDisplay("total") ??  true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (`${value}`);
          },
        },
      },
      {
        name: "successTotal",
        label: "Progress",
        index: 1,
        options: {
          filter: false,
          sort: false,
          display: this.isDisplay("successTotal") ??  true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (`${((value / tableMeta.rowData[9])*100)?.toFixed(2)}%`);
          },
        },
      },
      {
        name: "Operations",
        options: {
          filter: false,
          sort: false,
          empty: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div>
                <ExecuteButton value={value} tableMeta={tableMeta} callback={() => this.refresh()}/>
                {/*<Edit value={value} tableMeta={tableMeta} callback={() => this.refresh()}/>*/}
                <Delete disabled={true} url={`/userMessageTask/${tableMeta.rowData[0]}`} callback={() => this.refresh()}/>
              </div>
            );
          },
        },
      },
    ];

    const options = {
      search: false,
      filter: true,
      confirmFilters: true,
      serverSide: true,
      filterType: "dropdown",
      responsive: "vertical",
      rowsPerPageOptions: [10, 20, 50, 100],
      rowsPerPage: rowsPerPage,
      count: totalElements,
      page: page,
      fixedHeader: true,
      selectableRows: "none",
      tableBodyHeight: "900px",
      onTableChange: (action, tableState) => {
        switch (action) {
          case "viewColumnsChange":
            this.onChangeColumnDisplay();
            break;
          case "onFilterDialogOpen":
            this.isFilterDialogOpen = true;
            break;
          case "filterChange":
            if (this.isFilterDialogOpen)
              return;
            else
              this.refresh();
            break;
          case "onFilterDialogClose":
            this.isFilterDialogOpen = false;
          case "changePage":
          case "sort":
          case "changeRowsPerPage":
            this.refresh();
            break;
          default:
            console.log("Not handled action " + action);
        }
      },
      customFilterDialogFooter: (currentFilterList, applyNewFilters) => {
        return (
          <div style={{marginTop: "40px"}}>
            <Button variant="contained"
                    style={{color: "white", backgroundColor: "#36b9cc"}}
                    onClick={() => {applyNewFilters()}}
            >
              Apply
            </Button>
          </div>
        );
      },
      customToolbar: () => {
        return (
          <CustomToolbar refresh={this.refresh} tableProps={this.props}/>
        );
      }
    };

    return (
      <React.Fragment>
        <MUIDataTable
          title={<h1 className="tableTitle">Notification Task</h1>}
          data={list}
          columns={columns}
          options={options}
          ref={this.tableRef}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  notificationTasks: state.notificationTaskReducer.notificationTasks
});

const mapDispatchToProps = (dispatch) => ({
  search: (params) => dispatch(search(params)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationTask);
