import React, {Component} from "react";
import MUIDataTable from "mui-datatables";
import {withAuth0} from "@auth0/auth0-react";
import {
  IconButton,
  Tooltip,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormLabel,
  FormGroup, Checkbox, FormControlLabel, ListItemText
} from "@material-ui/core";
import {connect} from "react-redux";
import {search} from "../../../redux/task/task.action";
import SearchIcon from "@material-ui/icons/Search";
import moment from "moment";
import {queryParams, sub} from "../../../utils/utils";
import PropTypes from "prop-types";
import Vehicle from "../../../components/common/Vehicle";
import User from "../../../components/common/User";
import CustomToolbarSelect from "./CustomToolbarSelect";
import {withRouter} from 'react-router-dom';
import GraphOverlay from "./graphOverlay";
import CustomToolbar from "../../../components/List/CustomToolBar/CustomToolbar";


const DATE_FORMAT = process.env.REACT_APP_DATE_FORMAT;
const DATETIME_FORMAT = process.env.REACT_APP_DATETIME_FORMAT;
const TASK_STATUSES = ['UNASSIGNED', 'ASSIGNED', 'CANCELED', 'FINISHED', 'FAILED']
const TASK_CATEGORIES = ['DISTRIBUTION', 'COLLECTION', 'REPAIR', 'LOW_BATTERY', 'GENERAL_MAINTENANCE', 'COVID_CLEANING']


class TaskList extends Component {
  constructor(props) {
    super(props);
    this.isFilterDialogOpen = false;
    this.tableRef = React.createRef();
    this.columnsDisplay = undefined;
  }

  static propTypes = {
    filters: PropTypes.object,
  };

  componentDidMount() {
    const savedState = localStorage.getItem("taskListSavedState");
    const savedParams = localStorage.getItem("taskListParams");
    this.refresh(savedState, savedParams);
  }

  refresh = (savedState, savedParams) => {
    const {search, filters} = this.props;
    if (savedState && filters===undefined) {
      search(new URLSearchParams(savedParams));
    } 
    else if (filters) {
      const state = this.tableRef?.current?.state;

      const params = queryParams(this.props, state);

      filters?.map(f => params.set(f.key, f.value));
      if (state && state["columns"]) {
        const statusIndex = state["columns"].findIndex(s => s.name === 'status');
        const categoryIndex = state["columns"].findIndex(s => s.name === 'category');
        const statuses = state["filterList"][statusIndex];
        const categories = state["filterList"][categoryIndex];
        if (statuses?.length !== 0) {
          params.set("status", statuses);
        }
        if (categories?.length !== 0) {
          params.set("category", categories);
        }
      }
      search(params);
    }
    else {
      const state = this.tableRef?.current?.state;

      const params = queryParams(this.props, state);

      filters?.map(f => params.set(f.key, f.value));
      if (state && state["columns"]) {
        const statusIndex = state["columns"].findIndex(s => s.name === 'status');
        const categoryIndex = state["columns"].findIndex(s => s.name === 'category');
        const statuses = state["filterList"][statusIndex];
        const categories = state["filterList"][categoryIndex];
        if (statuses?.length !== 0) {
          params.set("status", statuses);
        }
        if (categories?.length !== 0) {
          params.set("category", categories);
        }
      }
      search(params);
      localStorage.setItem("taskListParams", params);
      localStorage.setItem("taskListSavedState", true);
    }
  };

  onChangeColumnDisplay = () => {
    const state = this.tableRef?.current?.state;
    this.columnsDisplay = state.columns.reduce((obj, item) => Object.assign(obj, {[item.name]: item.display}), {});
  }

  isDisplay = (name) => {
    if (!this.columnsDisplay || !this.columnsDisplay.hasOwnProperty(name))
      return undefined;
    else
      return this.columnsDisplay[name];
  }

  render() {
    const {tasks} = this.props;
    if (!tasks)
      return (<React.Fragment>Loading...</React.Fragment>);

    const list = tasks?.content;
    const totalElements = tasks?.totalElements ?? 0;
    const rowsPerPage = tasks?.pageable?.pageSize ?? 10;
    const page = tasks?.pageable?.pageNumber ?? 0;

    const columns = [
      {
        name: "uuid",
        label: "Task ID",
        options: {
          filter: true,
          sort: false,
          display: this.isDisplay("uuid") ?? true,
          customFilterListOptions: {render: (v) => `UUID: ${v}`},
          filterList: localStorage.getItem('taskListFilterList') ? JSON.parse(localStorage.getItem('taskListFilterList'))[0] : [],
          filterType: "textField",
        },
      },
      {
        name: "vehicle",
        label: "Vehicle QR Code",
        options: {
          filter: true,
          sort: false,
          filterType: "textField",
          display: this.isDisplay("vehicle") ?? true,
          filterList: localStorage.getItem('taskListFilterList') ? JSON.parse(localStorage.getItem('taskListFilterList'))[1] : [],
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <Vehicle
                vehicle={value}
                history={this.props.history}
                hideUuid={true}
              />
            );
          },
        },
      },
      {
        name: "status",
        label: "Task Status",
        options: {
          filter: true,
          sort: true,
          display: true,
          filterType: "custom",
          customFilterListOptions: {
            render: v => v.map(l => l.toUpperCase()),
            update: (filterList, filterPos, index) => {
              filterList[index].splice(filterPos, 1);
              return filterList;
            }
          },
          filterList: localStorage.getItem('taskListFilterList') ? JSON.parse(localStorage.getItem('taskListFilterList'))[2] : [],
          filterOptions: {
            fullWidth: true,
            logic: (location, filters, row) => {
              if (filters.length) return !filters.includes(location);
              return false;
            },
            display: (filterList, onChange, index, column) => {
              return (
                <FormControl>
                  <InputLabel htmlFor='select-multiple-chip'>
                    Task Status
                  </InputLabel>
                  <Select MenuProps={{
                    getContentAnchorEl: () => null,
                  }}
                          multiple
                          value={filterList[index]}
                          renderValue={selected => selected.join(', ')}
                          onChange={event => {
                            filterList[index] = event.target.value;
                            onChange(filterList[index], index, column);
                          }}
                  >
                    {TASK_STATUSES.map(item => (
                      <MenuItem key={item} value={item}>
                        <Checkbox
                          color='primary'
                          checked={filterList[index].indexOf(item) > -1}
                          style={{marginLeft: '0px'}}
                        />
                        <ListItemText primary={item}/>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              );
            }
          }
        },
      },
      {
        name: "category",
        label: "Task Category",
        options: {
          filter: true,
          sort: true,
          display: true,
          filterType: "custom",
          filterList: localStorage.getItem('taskListFilterList') ? JSON.parse(localStorage.getItem('taskListFilterList'))[3] : [],
          customFilterListOptions: {
            render: v => v.map(l => l.toUpperCase()),
            update: (filterList, filterPos, index) => {
              filterList[index].splice(filterPos, 1);
              return filterList;
            }
          },
          filterOptions: {
            fullWidth: true,
            logic: (location, filters, row) => {
              if (filters.length) return !filters.includes(location);
              return false;
            },
            display: (filterList, onChange, index, column) => {
              return (
                <FormControl>
                  <InputLabel htmlFor='select-multiple-chip'>
                    Task Category
                  </InputLabel>
                  <Select MenuProps={{
                    getContentAnchorEl: () => null,
                  }}
                          multiple
                          value={filterList[index]}
                          renderValue={selected => selected.join(', ')}
                          onChange={event => {
                            filterList[index] = event.target.value;
                            onChange(filterList[index], index, column);
                          }}
                  >
                    {TASK_CATEGORIES.map(item => (
                      <MenuItem key={item} value={item}>
                        <Checkbox
                          color='primary'
                          checked={filterList[index].indexOf(item) > -1}
                          style={{marginLeft: '0px'}}
                        />
                        <ListItemText primary={item}/>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              );
            }
          }
        },
      },
      {
        name: "note",
        label: "Task Note",
        options: {
          filter: false,
          sort: false,
          display: this.isDisplay("note") ?? true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <Tooltip title={value}>
                <div>{sub(value, 25)}</div>
              </Tooltip>
            );
          },
        },
      },
      {
        name: "assignee",
        label: "Assigned Person",
        options: {
          filter: false,
          sort: false,
          display: this.isDisplay("assignee") ?? true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (value ? <User user={value} history={this.props.history}/> : "");
          },
        },
      },
      {
        name: "assigner",
        label: "Admin",
        options: {
          filter: false,
          sort: false,
          display: this.isDisplay("assigner") ?? true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return value ? (
              <User user={value} history={this.props.history}/>
            ) : (
              ""
            );
          },
        },
      },
      {
        name: "createdAt",
        label: "Created",
        options: {
          filter: false,
          sort: true,
          display: this.isDisplay("createdAt") ?? false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <Tooltip title={value ? moment(value).format(DATETIME_FORMAT) : ""}>
                <div>{value ? moment(value).format(DATE_FORMAT) : ""}</div>
              </Tooltip>
            );
          },
        },
      },
      {
        name: "updatedAt",
        label: "Latest Updated",
        options: {
          filter: false,
          sort: true,
          display: this.isDisplay("updatedAt") ?? false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <Tooltip title={value ? moment(value).format(DATETIME_FORMAT) : ""}>
                <div>{value ? moment(value).format(DATE_FORMAT) : ""}</div>
              </Tooltip>
            );
          },
        },
      },
      {
        name: "finishedAt",
        label: "Finished",
        options: {
          filter: false,
          sort: true,
          display: this.isDisplay("finishedAt") ?? false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <Tooltip title={value ? moment(value).format(DATETIME_FORMAT) : ""}>
                <div>{value ? moment(value).format(DATE_FORMAT) : ""}</div>
              </Tooltip>
            );
          },
        },
      },
      {
        name: "graphUrl",
        label: "Task Graph",
        options: {
          filter: false,
          sort: false,
          display: this.isDisplay("graphUrl") ?? true,
          customBodyRender: (value, tableMeta, updateValue) => {
            const uuid = tableMeta.currentTableData[tableMeta.rowIndex]?.data[0];
            return (
              <Tooltip title={value ? "Graph" : "No Graph"}>
                <GraphOverlay value={value}></GraphOverlay>
              </Tooltip>
            );
          },
        },
      },
      {
        name: "Operations",
        options: {
          filter: false,
          sort: false,
          empty: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div>
                <Tooltip title="Details">
                  <IconButton
                    onClick={() => {
                      this.props.history.push("/task/" + tableMeta.rowData[0]);
                    }}
                  >
                    <SearchIcon/>
                  </IconButton>
                </Tooltip>
              </div>
            );
          },
        },
      },
    ];

    const options = {
      search: false,
      filter: true,
      confirmFilters: true,
      serverSide: true,
      filterType: "dropdown",
      responsive: 'standard',
      rowsPerPageOptions: [10, 20, 50, 100],
      rowsPerPage: rowsPerPage,
      count: totalElements,
      page: page,
      fixedHeader: true,
      tableBodyHeight: "900px",
      customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
        <CustomToolbarSelect
          selectedRows={selectedRows}
          displayData={displayData}
          setSelectedRows={setSelectedRows}
          callback={() => this.refresh()}
        />
      ),
      onTableChange: (action, tableState) => {
        switch (action) {
          case "viewColumnsChange":
            this.onChangeColumnDisplay();
            break;
          case "onFilterDialogOpen":
            this.isFilterDialogOpen = true;
            break;
          case "filterChange":
            if (this.isFilterDialogOpen) return;
            else this.refresh();
            break;
          case "onFilterDialogClose":
            this.isFilterDialogOpen = false;
          case "changePage":
          case "sort":
          case "changeRowsPerPage":
            this.refresh();
            break;
          default:
            console.log("Not handled action " + action);
        }
      },
      customFilterDialogFooter: (currentFilterList, applyNewFilters) => {
        return (
          <div style={{marginTop: "40px"}}>
            <Button
              variant="contained"
              style={{color: "white", backgroundColor: "#36b9cc"}}
              onClick={() => {
                localStorage.setItem("taskListSavedState", false);
                applyNewFilters();
              }}
            >
              Apply
            </Button>
          </div>
        );
      },
      onDownload: (buildHead, buildBody, columns, data) => {
        const dataNewFormat = data.reduce((acc, curr) => {
          curr.data.map((item, index) => {
            if (index === 1) {
              try {
                curr.data[index] = item.qrCode
              } catch (error) {
                curr.data[index] = item
              }
              ;
            } else if (index === 5 || index === 6) {
              try {
                curr.data[index] = `${item.firstName} ${item.lastName}`
              } catch (error) {
                curr.data[index] = item
              }
              ;
            }
          })
          acc.push(curr)
          return acc
        }, [])
        return "\uFEFF" + buildHead(columns) + buildBody(dataNewFormat);
      },
      customToolbar: () => {
        return (
          <CustomToolbar refresh={this.refresh} tableProps={this.props}/>
        );
      },
      onFilterChange: (changedColumn, filterList) => {
        localStorage.setItem("taskListFilterList", JSON.stringify(filterList));
      },
    };

    return (
      <React.Fragment>
        <MUIDataTable
          title={<h1 className="tableTitle">Task List</h1>}
          data={list}
          columns={columns}
          options={options}
          ref={this.tableRef}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  tasks: state.taskData.tasks,
});

const mapDispatchToProps = (dispatch) => ({
  search: (params) => dispatch(search(params)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withAuth0(TaskList)));
