import React from "react";
import PropTypes from 'prop-types';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions, DialogContent, DialogContentText,
  DialogTitle,
  IconButton,
  Tooltip
} from "@material-ui/core";

import axios from "axios";
import {config} from "../../auth/auth";
import DeleteIcon from '@material-ui/icons/Delete';
import {grey, red} from "@material-ui/core/colors";

const host = process.env.REACT_APP_HOST

class Switch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      open: false
    };
  }

  static propTypes = {
    url: PropTypes.string.isRequired,
    callback: PropTypes.func.isRequired,
    disabled: PropTypes.bool
  };

  delete = (url, callback) => {
    if (this.state.isLoading)
      return;

    this.setState({isLoading: true})
    return axios.delete(`${host}${url}`,config())
      .then(r => callback())
      .catch(e => alert(e))
      .finally(() => {
        this.setState({isLoading: false,isConfirming: false})
      });
  };

  handleClickOpen = () => {
    this.setState({open: true});
  };

  disagree = () => {
    this.setState({open: false});
  };

  agree = (url, callback) => {
    this.setState({open: false});
    this.delete(url, callback);
  };

  render() {
    const { url, callback, disabled} = this.props;
    if (!url)
      return (<React.Fragment/>);

    let icon = <DeleteIcon/>;

    if (this.state.isLoading) {
      icon = <CircularProgress style={{width:20, height:20}}/>;
    }

    return (
      <React.Fragment>
        <Tooltip title='DELETE'>
          <IconButton onClick={this.handleClickOpen} disabled={disabled}>
            {icon}
          </IconButton>
        </Tooltip>
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">{"Are you absolutely sure?"}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              This action cannot be undone. This will be permanently delete.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.disagree} style={{ color: grey[500] }}>
              Cancel
            </Button>
            <Button onClick={() => this.agree(url, callback)} style={{ color: red[600] }}>
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

export default Switch;