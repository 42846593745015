import {AppActionTypes} from "./app.types";

const INITIAL_STATE = {
    currentAdminUser: undefined,
    pageRoles : undefined,
    customerServiceChannels: undefined,
    liveChatConfig: undefined,
    unreadMessages: [],
    unreadMessageCount: 0,
    chatClient: undefined,
    firebaseApp: undefined
};

const appReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case AppActionTypes.SET_CURRENT_ADMIN_USER:
            return {
                ...state,
                currentAdminUser: action.payload
            };
        case AppActionTypes.SET_PAGE_ROLES:
            return {
                ...state,
              pageRoles: action.payload
            };
        case AppActionTypes.SET_CUSTOMER_SERVICE_CHANNELS:
            return {
                ...state,
                customerServiceChannels: action.payload
            };
        case AppActionTypes.SET_LIVE_CHAT_CONFIG:
            return {
                ...state,
              liveChatConfig: action.payload
            };
        case AppActionTypes.SET_UNREAD_MESSAGES:
            return {
                ...state,
                unreadMessages: action.payload
            };
        case AppActionTypes.SET_UNREAD_MESSAGES_COUNT:
            return {
                ...state,
                unreadMessageCount: action.payload
            };
        case AppActionTypes.SET_CHAT_CLIENT:
            return {
                ...state,
                chatClient: action.payload
            };
        case AppActionTypes.SET_FIREBASE_APP:
            return {
                ...state,
              firebaseApp: action.payload
            };
        default:
            return state;
    }
};

export default appReducer;
