import React, {Component} from 'react'
import {connect} from "react-redux";
import {Snackbar} from "@material-ui/core";
import {close} from "../../redux/alert/alert.action";
import {Alert} from "@material-ui/lab";

const vertical = 'top';
const horizontal = 'center';

class SnackBarAlert extends Component {

  constructor(props) {
    super(props);

  }


  render() {
    const {open, severity, message, close} = this.props;
    if (!open) {
      return (<React.Fragment/>);
    }

    return (
      <Snackbar open={open} autoHideDuration={6000} onClose={close} anchorOrigin={{vertical, horizontal}}>
        <Alert variant="outlined" onClose={close} severity={severity} sx={{width: '100%'}}>
          {message}
        </Alert>
      </Snackbar>
    );
  }
}


const mapStateToProps = (state) => ({
  open: state.alertReducer.open,
  severity: state.alertReducer.severity,
  message: state.alertReducer.message,
});

const mapDispatchToProps = (dispatch) => ({
  close: () => dispatch(close())
});

export default connect(mapStateToProps, mapDispatchToProps)(SnackBarAlert);
