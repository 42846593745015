import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import {grey, red} from "@material-ui/core/colors";
import {connect} from "react-redux";
import {withAuth0} from "@auth0/auth0-react";
import {getPlaces} from "../../redux/places/action";

const useStyles = makeStyles(theme => ({
  dialogContent: {
    textAlign: 'center'
  },
  dialogAction: {
    justifyContent: 'space-around'
  },
}))

const ConfirmationCreatePoiModal = ({
                                      confirmationModal,
                                      setOpenUploadIconModal,
                                      setOpenConfirmationModal,
                                      setOpenModal,
                                      getPlaces,
                                      setLocation
                                    }) => {
  const classes = useStyles()

  const handleOnCancelButton = () => {
    setLocation({
      lat: "",
      lng: ""
    })
    getPlaces();
    setOpenConfirmationModal({isOpen: false});
    setOpenModal(false);
  }

  const handleOnUploadIconButton = () => {
    setLocation({
      lat: "",
      lng: ""
    })
    getPlaces();
    setOpenUploadIconModal(true);
    setOpenConfirmationModal({isOpen: false});
  }


  return (
    <Dialog open={confirmationModal.isOpen} fullWidth>
      <DialogTitle
        id="Users Action Modal"
        style={{
          color: "white",
          backgroundColor: "#333399",
          textAlign: "left",
        }}
      >
        <label style={{color: `${confirmationModal.success ? 'white' : red[500]}`}}
        >{confirmationModal.success ? "Success" : "Failed!"}</label>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Typography variant="h6" className="p-3">
          {confirmationModal.success ? "You successfully created a new Point of Interest. Would you like to upload an icon?"
            : "Creating a new Point of Interest was unsuccessful"}
        </Typography>
      </DialogContent>
      <DialogActions className={classes.dialogAction}>
        {confirmationModal.success && <Button
          style={{color: "white", backgroundColor: "#333399"}}
          onClick={() => handleOnUploadIconButton()}>Upload Icon</Button>}
        <Button
          style={{color: grey[500]}}
          onClick={() => handleOnCancelButton()}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  getPlaces: () => dispatch(getPlaces())
});


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withAuth0(ConfirmationCreatePoiModal));