import {ActionTypes} from "./types";

const INITIAL_STATE = {
  url: undefined,
  content: undefined
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ActionTypes.SET_URL:
      return {
        ...state,
        url: action.payload,
      };
    case ActionTypes.SET_CONTENT:
      return {
        ...state,
        content: action.payload
      };
    default:
      return state;
  }
};

export default reducer;