import {PlaceActionTypes} from "./types";
import axios from "axios";
import {config} from "../../auth/auth";

const host = process.env.REACT_APP_HOST

export const getPlaces  = () => {
  return async dispatch => {
    return axios.get(`${host}/place?size=100`, config())
      .then(
        response => dispatch(setPlaces(response.data)),
        err => console.log(err)
      );
  }
}

export const setPlaces = (places) => ({
  type: PlaceActionTypes.SET_PLACES,
  payload: places,
});