import React, {Component} from "react";
import RegionConfigurationMap from "../../../../components/Map/RegionConfigurationMap";
import {regionSearch} from "../../../../redux/region/action";
import {queryParams} from "../../../../utils/utils";
import {withAuth0} from "@auth0/auth0-react";
import {connect} from "react-redux";
import MUIDataTable from "mui-datatables";
import {Button, IconButton, Tooltip} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import EditIcon from "@material-ui/icons/Edit";
import RegionEditModel  from "./EditRegion";

class Region extends Component {
  constructor(props) {
    super(props);
    this.tableRef = React.createRef();
    this.columnsDisplay = undefined;
  }

  componentDidMount() {
    this.refresh();
  }

  refresh = () => {
    const {regionSearch} = this.props;
    const state = this.tableRef?.current?.state;

    const params = queryParams(this.props, state);
    regionSearch(params);
  };

  onSucessRegion() {
    this.refresh();
  }

  onChangeColumnDisplay = () => {
    const state = this.tableRef?.current?.state;
    this.columnsDisplay = state.columns.reduce(
      (obj, item) => Object.assign(obj, {[item.name]: item.display}),
      {}
    );
  };

  isDisplay = (name) => {
    if (!this.columnsDisplay || !this.columnsDisplay.hasOwnProperty(name)){
      return undefined;
    }
    else {
      return this.columnsDisplay[name];
    }
  };

  render() {
    const {regions} = this.props;
    if (!regions) return <React.Fragment/>;

    const list = regions?.content;
    const totalElements = regions?.totalElements ?? 0;
    const rowsPerPage = regions?.pageable?.pageSize ?? 10;
    const page = regions?.pageable?.pageNumber ?? 0;

    const columns = [
      {
        name: "uuid",
        label: "UUID",
        options: {
          filter: true,
          sort: false,
          display: this.isDisplay("uuid") ?? true,
          customFilterListOptions: {render: (v) => `UUID: ${v}`},
          filterType: "textField",
        },
      },
      {
        name: "regionName",
        label: "Region",
        options: {
          filter: false,
          sort: false,
          display: this.isDisplay("regionName") ?? true,
        },
      },
      {
        name: "discount",
        label: "Discount",
        options: {
          filter: false,
          sort: false,
          display: this.isDisplay("discount") ?? false,
        },
      },
      {
        name: "category",
        label: "Category",
        options: {
          filter: false,
          sort: false,
          display: this.isDisplay("category") ?? true,
        },
      },
      {
        name: "status",
        label: "Status",
        options: {
          filter: false,
          sort: false,
          display: this.isDisplay("status") ?? true,
        },
      },
      {
        name: "speedLimit",
        label: "Speed Limit",
        options: {
          filter: false,
          sort: false,
          display: this.isDisplay("speedLimit") ?? true,
        },
      },
      {
        name: "Operations",
        options: {
          filter: false,
          sort: false,
          empty: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div>
                <RegionEditModel value={value} tableMeta={tableMeta} callback={() => this.refresh()}/>
              </div>
            );
          },
        },
      },
    ];

    const options = {
      filter: false,
      search: false,
      confirmFilters: false,
      serverSide: true,
      filterType: "dropdown",
      responsive: "vertical",
      rowsPerPageOptions: [10, 20, 50, 100],
      rowsPerPage: rowsPerPage,
      count: totalElements,
      page: page,
      tableBodyHeight: "900px",
      onTableChange: (action, tableState) => {
        switch (action) {
          case "viewColumnsChange":
            this.onChangeColumnDisplay();
            break;
          case "onFilterDialogOpen":
            this.isFilterDialogOpen = true;
            break;
          case "filterChange":
            if (this.isFilterDialogOpen) return;
            else this.refresh();
            break;
          case "onFilterDialogClose":
            this.isFilterDialogOpen = false;
          case "changePage":
          case "sort":
          case "changeRowsPerPage":
            this.refresh();
            break;
          default:
            console.log("Not handled action " + action);
        }
      },
      customFilterDialogFooter: (currentFilterList, applyNewFilters) => {
        return (
          <div style={{marginTop: "40px"}}>
            <Button
              variant="contained"
              style={{color: "white", backgroundColor: "#36b9cc"}}
              onClick={() => {
                applyNewFilters();
              }}
            >
              Apply
            </Button>
          </div>
        );
      },
    };
    return (
      <div>
        <MUIDataTable
          title={"Region List"}
          data={list}
          columns={columns}
          options={options}
          ref={this.tableRef}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  regions: state.regionReducer.regions,
});

const mapDispatchToProps = (dispatch) => ({
  regionSearch: (params) => dispatch(regionSearch(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withAuth0(Region));
