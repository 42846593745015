import React, {Component} from "react";
import MUIDataTable from "mui-datatables";
import {withAuth0} from "@auth0/auth0-react";
import {connect} from "react-redux";
import {queryParams} from "../../../utils/utils";
import {
  Button,
  Checkbox,
  FormControl, FormGroup, FormLabel, Grid,
  IconButton, InputLabel, ListItemText, MenuItem, Select, TextField,
  Tooltip
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import {getGroundTeamUsers} from "../../../redux/groundTeamUser/groundTeamUser.action";
import InviteGroundTeamUser from "./InviteGroundTeamUser";
import moment from "moment";
import CustomToolbarSelect from "../../OperationCenter/VehicleInformation/VehicleList/CustomToolbarSelect";
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import ConsoleEventLogList from "../../SystemLog/ConsoleEventLogList";


const date_format = process.env.REACT_APP_DATE_FORMAT;
const GROUND_TEAM_STATUS = ["IDLE", "WORKING", "INACTIVE", "VERIFYING"];

class GroundTeam extends Component {
  constructor(props) {
    super(props);
    this.isFilterDialogOpen = false;
    this.tableRef = React.createRef();
    this.columnsDisplay = undefined;
  }

  componentDidMount() {
    const savedState = localStorage.getItem("groundTeamUserSavedState");
    const savedParams = localStorage.getItem("groundTeamUserParams");
    this.refresh(savedState, savedParams);
  }

  refresh = (savedState, savedParams) => {
    const { getGroundTeamUsers } = this.props;

    // table state
    if (savedState) {
      getGroundTeamUsers(new URLSearchParams(savedParams));
    } else {
      const state = this.tableRef?.current?.state;

      const params = queryParams(this.props, state);
      params.delete("employmentDate");
      // add startAt, status as filter
      if (state && state["columns"]) {
        const index = state["columns"].findIndex(s => s.name === 'employmentDate');
        const statusIndex = state["columns"].findIndex(s => s.name === 'status');
        const values = state["filterList"][index];
        const statuses = state["filterList"][statusIndex];
        if (values?.length !== 0) {
          const from = values[0] ?? moment().subtract(100, 'year');
          const until = values[1] ?? moment();
          params.set('startFrom', from.format(date_format));
          params.set('startUntil', until.format(date_format));
        }
        if (statuses?.length !== 0) {
          params.set("status", statuses);
        }
      }
      getGroundTeamUsers(params);
      localStorage.setItem("groundTeamUserParams", params);
      localStorage.setItem("groundTeamUserSavedState", true);
    }     
  };

  onChangeColumnDisplay = () => {
    const state = this.tableRef?.current?.state;
    this.columnsDisplay = state.columns.reduce(
      (obj, item) => Object.assign(obj, {[item.name]: item.display}),
      {}
    );
  };

  isDisplay = (name) => {
    if (!this.columnsDisplay || !this.columnsDisplay.hasOwnProperty(name))
      return undefined;
    else return this.columnsDisplay[name];
  };

  render() {
    const {groundTeamUsers} = this.props;
    if (!groundTeamUsers) return <React.Fragment>Loading...</React.Fragment>;
    const list = groundTeamUsers?.content;
    const totalElements = groundTeamUsers?.totalElements ?? 0;
    const rowsPerPage = groundTeamUsers?.pageable?.pageSize ?? 10;
    const page = groundTeamUsers?.pageable?.pageNumber ?? 0;

    const columns = [
      {
        name: "uuid",
        label: "Ground Team ID",
        options: {
          filter: true,
          sort: false,
          display: this.isDisplay("uuid") ?? true,
          customFilterListOptions: {render: (v) => `UUID: ${v}`},
          filterType: "textField",
          filterList: localStorage.getItem('groundTeamUserFilterList') ? JSON.parse(localStorage.getItem('groundTeamUserFilterList'))[0] : [],
        },
      },
      {
        name: "firstName",
        label: "First Name",
        options: {
          filter: true,
          sort: false,
          display: this.isDisplay("firstName") ?? true,
          filterType: "textField",
          filterList: localStorage.getItem('groundTeamUserFilterList') ? JSON.parse(localStorage.getItem('groundTeamUserFilterList'))[1] : [],
        },
      },
      {
        name: "lastName",
        label: "Last Name",
        options: {
          filter: true,
          sort: false,
          display: this.isDisplay("lastName") ?? true,
          filterType: "textField",
          filterList: localStorage.getItem('groundTeamUserFilterList') ? JSON.parse(localStorage.getItem('groundTeamUserFilterList'))[2] : [],
        },
      },
      {
        name: "employmentDate",
        label: "Employment Date",
        options: {
          filter: true,
          sort: true,
          filterType: 'custom',
          display: this.isDisplay("employmentDate") ?? true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <Tooltip title={value ? moment.utc(value).local().format(date_format) : ""}>
                <div>
                  {value ? moment.utc(value).local().format(date_format) : ""}
                </div>
              </Tooltip>
            );
          },
          customFilterListOptions: {
            render: v => {
              if (v[0] && v[1]) {
                return [`Start time: ${v[0]?.format(date_format)} ~ ${v[1]?.format(date_format)}`];
              } else if (v[0]) {
                return [`Start time: ${v[0]?.format(date_format)} ~ N/A`];
              } else if (v[1]) {
                return [`Start time: N/A ~ ${v[1]?.format(date_format)}`];
              }
              return [];
            },
            update: (filterList, filterPos, index) => {
              filterList[index] = [];
              return filterList;
            },
          },
          filterOptions: {
            names: [],
            display: (filterList, onChange, index, column) => (
              <div>
                <FormLabel>Start time range</FormLabel>
                <FormGroup row>
                  <DatePicker
                    renderInput={(props) => <TextField {...props} />}
                    label="from"
                    inputVariant="outlined"
                    value={filterList[index][0] ?? null}
                    defaultValue={null}
                    onChange={(newValue) => {
                      filterList[index][0] = newValue;
                      onChange(filterList[index], index, column);
                    }}
                    format={date_format}
                    style={{width: '45%', marginRight: '5%'}}
                  />
                  <DatePicker
                    renderInput={(props) => <TextField {...props} />}
                    label="until"
                    inputVariant="outlined"
                    value={filterList[index][1] ?? null}
                    defaultValue={null}
                    onChange={(newValue) => {
                      filterList[index][1] = newValue;
                      onChange(filterList[index], index, column);
                    }}

                    format={date_format}
                    style={{width: '45%'}}
                  />
                </FormGroup>
              </div>
            )
          }
        },
      },
      {
        name: "workingHours",
        label: "Working Hours",
        options: {
          filter: false,
          sort: false,
          display: this.isDisplay("workingHours") ?? true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <Tooltip title={value}>
                <div>{value ?? 0}</div>
              </Tooltip>
            );
          },
        },
      },
      {
        name: "countOfSuccessfulTask",
        label: "No Successful Tasks",
        options: {
          filter: false,
          sort: false,
          display: this.isDisplay("countOfSuccessfulTask") ?? true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <Tooltip title={value}>
                <div>{value ?? 0}</div>
              </Tooltip>
            );
          },
        },
      },
      {
        name: "countOfFailedTask",
        label: "No Failed Tasks",
        options: {
          filter: false,
          sort: false,
          display: this.isDisplay("countOfFailedTask") ?? true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <Tooltip title={value}>
                <div>{value ?? 0}</div>
              </Tooltip>
            );
          },
        },
      },
      {
        name: "status",
        label: "Status",
        options: {
          filter: true,
          sort: true,
          display: true,
          filterType: 'custom',
          customFilterListOptions: {
            render: v => v.map(l => l.toUpperCase()),
            update: (filterList, filterPos, index) => {
              filterList[index].splice(filterPos, 1);
              return filterList;
            },
          },
          filterList: localStorage.getItem('groundTeamUserFilterList') ? JSON.parse(localStorage.getItem('groundTeamUserFilterList'))[7] : [],
          filterOptions: {
            fullWidth: true,
            logic: (location, filters, row) => {
              if (filters.length) return !filters.includes(location);
              return false;
            },
            display: (filterList, onChange, index, column) => {
              return (
                <FormControl>
                  <InputLabel htmlFor='select-multiple-chip'>
                    Status
                  </InputLabel>
                  <Select
                    multiple
                    value={filterList[index]}
                    renderValue={selected => selected.join(', ')}
                    onChange={event => {
                      filterList[index] = event.target.value;
                      onChange(filterList[index], index, column);
                    }}
                  >
                    {GROUND_TEAM_STATUS.map(item => (
                      <MenuItem key={item} value={item}>
                        <Checkbox
                          color='primary'
                          checked={filterList[index].indexOf(item) > -1}
                        />
                        <ListItemText primary={item}/>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              );
            }
          }
        }
      },

      {
        name: "Operations",
        options: {
          filter: false,
          sort: false,
          empty: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div>
                <Tooltip title="Details">
                  <IconButton
                    aria-label="Details"
                    onClick={() => {
                      const {history} = this.props;
                      const routePath =
                        "/groundTeam/" +
                        tableMeta.tableData[tableMeta.rowIndex][0];
                      history.push(routePath);
                    }}
                  >
                    <SearchIcon/>
                  </IconButton>
                </Tooltip>
                {/*
                <Tooltip title="Chat">
                  <IconButton
                    aria-label="Chat"
                    onClick={() => {
                      alert("Test environment");
                    }}
                  >
                    <FontAwesomeIcon icon={faComments} />
                  </IconButton>
                </Tooltip>
                  */}
              </div>
            );
          },
        },
      },
    ];

    const options = {
      filter: true,
      confirmFilters: true,
      serverSide: true,
      filterType: "dropdown",
      responsive: "vertical",
      rowsPerPageOptions: [10, 20, 50, 100],
      rowsPerPage: rowsPerPage,
      count: totalElements,
      page: page,
      search: false,
      fixedHeader: true,
      tableBodyHeight: "900px",
      customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
        <CustomToolbarSelect
          selectedRows={selectedRows}
          displayData={displayData}
          setSelectedRows={setSelectedRows}
          callback={() => this.refresh()}
        />
      ),
      onTableChange: (action, tableState) => {
        switch (action) {
          case "viewColumnsChange":
            this.onChangeColumnDisplay();
            break;
          case "onFilterDialogOpen":
            this.isFilterDialogOpen = true;
            break;
          case "filterChange":
            if (this.isFilterDialogOpen) return;
            else this.refresh();
            break;
          case "onFilterDialogClose":
            this.isFilterDialogOpen = false;
          case "changePage":
          case "sort":
          case "changeRowsPerPage":
            this.refresh();
            break;
          default:
            console.log("Not handled action " + action);
        }
      },
      customFilterDialogFooter: (currentFilterList, applyNewFilters) => {
        return (
          <div style={{marginTop: "40px"}}>
            <Button
              variant="contained"
              style={{color: "white", backgroundColor: "#333399"}}
              onClick={() => {
                localStorage.setItem("groundTeamUserSavedState", false);
                applyNewFilters();
              }}
            >
              Apply
            </Button>
          </div>
        );
      },
      customToolbar: () => {
        return (
          <>
            <InviteGroundTeamUser callback={() => this.refresh()}/>
          </>
        );
      },
      onFilterChange: (changedColumn, filterList) => {
        localStorage.setItem("groundTeamUserFilterList", JSON.stringify(filterList));
      },
    };

    return (
      <React.Fragment>
        <Grid container spacing={2}>
          <Grid item lg={12}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
            <MUIDataTable
              title={<h1 className="tableTitle">Ground Team List</h1>}
              data={list}
              columns={columns}
              options={options}
              ref={this.tableRef}
            />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item lg={12}>
            <ConsoleEventLogList
              filters={[{key: "event", value: `INVITE_GROUND_TEAM`}]}
            />
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  groundTeamUsers: state.groundTeamUserReducer.groundTeamUsers,
});

const mapDispatchToProps = (dispatch) => ({
  getGroundTeamUsers: (params) => dispatch(getGroundTeamUsers(params)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withAuth0(GroundTeam));
