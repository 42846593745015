import React, {Component} from 'react';
import Header from './components/Header';
import Footer from './components/Footer';
import Sidebar from './components/Sidebar';
import KpiDashboard from './pages/KPIDashboard';
import {Redirect, Switch} from 'react-router-dom';
import TaskMap from './pages/TaskManagement/TaskMap/TaskMap';
import {withAuth0} from "@auth0/auth0-react";
import ProtectedRoute from './auth/protected-route.js';
import UserList from "./pages/OperationCenter/UserInformation/UserList/index";
import MembershipAccountList from './pages/OperationCenter/UserInformation/MembershipAccountList/index';
import RedistributionTask from './pages/TaskManagement/TaskCreation/RedistributionTask';
import AccessDenied from "./pages/Error/AccessDenied";
import RegionMap from './pages/SystemConfiguration/GeofencingSettings/Region/regionMap';
import RegionList from './pages/SystemConfiguration/GeofencingSettings/Region/regionList';
import AdminUsers from "./pages/AdminSettings/AdminUsers";
import {connect} from "react-redux";
import {
  getCurrentAdminUser,
  getPageRoles,
  setCustomerServiceChannels,
  setFirebaseApp,
  setLiveChatConfig
} from "./redux/app/app.action";
import UserDetails from "./pages/OperationCenter/UserInformation/UserDetails/userDetails.jsx";
import VehicleList from "./pages/OperationCenter/VehicleInformation/VehicleList";
import DrivingLicense from "./pages/OperationCenter/UserInformation/DrivingLicenseVerfication";
import VehicleDetail from "./pages/OperationCenter/VehicleInformation/VehicleDetail";
import GroundTeam from "./pages/TaskManagement/GroundTeamList";
import GroundTeamDetails from "./pages/TaskManagement/GroundTeamDetails";
import TaskList from "./pages/TaskManagement/TaskList";
import NotificationList from "./pages/InformationSettings/NotificationList";
import NotificationTask from "./pages/InformationSettings/NotificationTask";
import NewsComponent from "./pages/InformationSettings/News/News";
import OrderList from "./pages/OperationCenter/OrderInformation/OrderList/OrderList";
import OrderDetail from "./pages/OperationCenter/OrderInformation/OrderDetail";
import OrderMapComponent from "./pages/OperationCenter/OrderInformation/OrderMap/index";
import City from "./pages/SystemConfiguration/GeofencingSettings/City/City";
import RegionRuleList from "./pages/SystemConfiguration/OperationRules/RegionRules/region-rule-list";
import RegionRuleDetails from "./pages/SystemConfiguration/OperationRules/RegionRules/region-rule-details";
import TaskRuleList from "./pages/SystemConfiguration/OperationRules/TaskRules/task-rule-list";
import TaskRuleDetails from "./pages/SystemConfiguration/OperationRules/TaskRules/task-rule-details";
import Parking from "./pages/SystemConfiguration/GeofencingSettings/Parking/Parking";
import Discounting from "./pages/SystemConfiguration/Discounting/Discounting";
import MembershipStructure from "./pages/SystemConfiguration/MembershipStruture/MembershipStructure";
import PricingList from "./pages/SystemConfiguration/PricingStruture/PricingList";
import Voucher from "./pages/SystemConfiguration/Voucher/Voucher";
import VehicleSearch from "./pages/OperationCenter/VehicleInformation/VehicleSearch/VehicleSearch";
import OrderSearch from "./pages/OperationCenter/OrderInformation/OrderSearch/OrderSearch";
import ParkingPictureChecking
  from "./pages/OperationCenter/OrderInformation/ParkingPictureChecking/ParkingPictureChecking";
import VehicleMap from "./pages/OperationCenter/VehicleInformation/VehicleMap/VehicleMap";
import LiveChat from "./pages/CustomerService/LiveChat/LiveChat";
import TaskDetail from "./pages/TaskManagement/TaskDetails";
import TeamMetrics from "./pages/TaskManagement/TeamMetrics"
import firebase from "firebase/app";
import "firebase/database";
import BusinessList from "./pages/AdminSettings/Business/BusinessList/business-list";
import BusinessDetail from "./pages/AdminSettings/Business/BusinessDetail/business-detail";
import BatteryList from "./pages/Warranty/BatteryManagement/battery-list";
import BatteryDetail from "./pages/Warranty/BatteryManagement/battery-detail";
import LegalInformation from './pages/InformationSettings/LegalInformation/legal-information';
import ProductWarranty from './pages/Warranty/Product';
import ShoppingSpareParts from './pages/SpareParts';
import MigrationProcess from './pages/AdminSettings/MigrationProcess';
import RoleList from "./pages/AdminSettings/Role/RoleList/role-list";
import RoleDetail from "./pages/AdminSettings/Role/RoleDetail/role-detail";
import ParkingList from "./pages/SystemConfiguration/GeofencingSettings/Parking/parkingList"
import {hasPermission} from "./utils/permission-utils";
import Download from "./pages/AdminSettings/Download";
import TopUpTemplate from "./pages/SystemConfiguration/PricingStruture/topUpTemplate/TopUpTemplate";
import SnackBarAlert from "./components/Alert/alert";
import GroundTeamWorkRecordList from "./pages/TaskManagement/GroundTeamWorkRecord/GroundTeamWorkRecordList";
import PointOfInterest from "./pages/SystemConfiguration/GeofencingSettings/PointOfIntrest/PointOfInterest";
import SocialMedia from "./pages/InformationSettings/SocialMedia/SocialMedia";
import TracItSupport from './pages/AdminSettings/TracItSupport';
import Country from "./pages/SystemConfiguration/GeofencingSettings/Country/Country";
import PartnerSetting from "./pages/InformationSettings/PartnerSettings/PartnerSetting";

const firebaseConfig = {
    apiKey: "AIzaSyC1KxZ-VQ9SXFSYqjzU1l12_AAX7kJ_FsE",
    authDomain: "hopon-306723.firebaseapp.com",
    databaseURL: "https://hopon-306723-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "hopon-306723",
    storageBucket: "hopon-306723.appspot.com",
    messagingSenderId: "615860610836",
    appId: "1:615860610836:web:0e94643eb9f73083728401",
    measurementId: "G-V2WVJE6SFY"

};

const env = process.env.REACT_APP_ENV

class App extends Component {

    initFirebase = () => {
      const {setLiveChatConfig, currentAdminUser, firebaseApp, setFirebaseApp} = this.props;
      if (!firebaseApp) {
        // Initialize Firebase
        const firebaseApp = firebase.initializeApp(firebaseConfig);
        setFirebaseApp(firebaseApp);

        const channelsRef = firebase.database().ref(`${env}/console/live_chat/${currentAdminUser?.partnerUuid}`);
        channelsRef.on('value', (snapshot) => {
          const data = snapshot.val();
          setLiveChatConfig(data ?? "nope");
        });
      }
    }

    render() {
        const {loginWithRedirect, isAuthenticated, isLoading} = this.props.auth0;
        const {currentAdminUser, getCurrentAdminUser, pageRoles} = this.props;
        if (isLoading)
            return (
                <div>Loading...</div>
            );

        if (!isAuthenticated) {
            loginWithRedirect();
            return (
                <div>Authenticating...</div>
            );
        }

        if (!currentAdminUser) {
            getCurrentAdminUser(this.props.auth0);
            return (
                <div>Loading current user...</div>
            );
        }

      if (currentAdminUser === 403 || currentAdminUser === 401)
        return (<AccessDenied allowLogout={true}/>);

      if (!pageRoles) {
        this.props.getPageRoles();
          return (
              <div>Loading page settings...</div>
          );
      }

        this.initFirebase();

    return (
      <div id="wrapper" key={"app-wrapper-key"}>
        <Sidebar/>
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <Header/>
            <div className="container-fluid">
              <Switch>
                <ProtectedRoute path='/kpi-dashboard'                              component={hasPermission('DASHBOARD')             ? KpiDashboard           : AccessDenied}/>
                <ProtectedRoute path='/task-management/task-map'                   component={hasPermission('TASK_MAP')              ? TaskMap                : AccessDenied}/>
                <ProtectedRoute path='/user-information/user-list'                 component={hasPermission('USER_LIST')             ? UserList               : AccessDenied}/>
                <ProtectedRoute path='/user-information/drivingLicencePage'        component={hasPermission('DRIVER_LICENSE_CHECK')  ? DrivingLicense         : AccessDenied} />
                <ProtectedRoute path='/user/:id'                                   component={hasPermission('USER_LIST')             ? UserDetails            : AccessDenied} {...this.props}/>
                <ProtectedRoute path='/vehicle-information/vehicle-list'           component={hasPermission('VEHICLE_LIST')          ? VehicleList            : AccessDenied}/>
                <ProtectedRoute path='/vehicle-information/vehicle-map'            component={hasPermission('VEHICLE_MAP')           ? VehicleMap             : AccessDenied}/>
                <ProtectedRoute path='/vehicle-information/vehicle-search'         component={hasPermission('VEHICLE_LIST')          ? VehicleSearch          : AccessDenied}/>
                <ProtectedRoute path='/ground-team-information/ground-team-list'   component={hasPermission('GROUND_TEAM_LIST')      ? GroundTeam             : AccessDenied}/>
                <ProtectedRoute path='/groundTeam/:id'                             component={hasPermission('GROUND_TEAM_LIST')      ? GroundTeamDetails      : AccessDenied}/>
                <ProtectedRoute path='/ground-team-information/work-record'        component={hasPermission('WORK_RECORD')           ? GroundTeamWorkRecordList : AccessDenied}/>
                <ProtectedRoute path='/ground-team/live-chat'                      component={hasPermission('LIVE_CHAT')             ? LiveChat               : AccessDenied}/>
                <ProtectedRoute path='/task-management/task-creation'              component={hasPermission('TASK_CREATION')         ? RedistributionTask     : AccessDenied}/>
                <ProtectedRoute path='/task-management/redistribution-task'        component={hasPermission('TASK_CREATION')         ? RedistributionTask     : AccessDenied}/>
                <ProtectedRoute path='/geofencing-settings/region-map'             component={hasPermission('REGION_MAP')            ? RegionMap              : AccessDenied}/>
                <ProtectedRoute path='/geofencing-settings/region-list'            component={hasPermission('REGION_LIST')           ? RegionList             : AccessDenied}/>
                <ProtectedRoute path='/task-management/task-list'                  component={hasPermission('TASK_LIST')             ? TaskList               : AccessDenied}/>
                <ProtectedRoute path='/vehicle/:uuid'                              component={hasPermission('VEHICLE_LIST')          ? VehicleDetail          : AccessDenied} {...this.props}/>
                <ProtectedRoute path='/information-settings/notification-list'     component={hasPermission('NOTIFICATION_TEMPLATE') ? NotificationList       : AccessDenied}/>
                <ProtectedRoute path='/information-settings/notification-task'     component={hasPermission('NOTIFICATION_TASK')     ? NotificationTask       : AccessDenied}/>
                <ProtectedRoute path='/information-settings/legal-information'     component={hasPermission('LEGAL_INFORMATION')     ? LegalInformation       : AccessDenied}/>
                <ProtectedRoute path='/information-settings/social-media'          component={hasPermission('SOCIAL_MEDIA')          ? SocialMedia            : AccessDenied}/>
                <ProtectedRoute path='/information-settings/partner-setting'       component={hasPermission('NOTIFICATION_TEMPLATE') ? PartnerSetting         : AccessDenied}/>
                <ProtectedRoute path='/news/pop-up-news'                           component={hasPermission('POPUP_NEWS')            ? NewsComponent          : AccessDenied}/>
                <ProtectedRoute path='/order-information/order-list'               component={hasPermission('ORDER_LIST')            ? OrderList              : AccessDenied}/>
                <ProtectedRoute path='/order-information/order-map'                component={hasPermission('ORDER_MAP')             ? OrderMapComponent      : AccessDenied}/>
                <ProtectedRoute path='/order/:id'                                  component={hasPermission('ORDER_LIST')            ? OrderDetail            : AccessDenied}/>
                <ProtectedRoute path='/order-information/order-search'             component={hasPermission('ORDER_LIST')            ? OrderSearch            : AccessDenied}/>
                <ProtectedRoute path='/geofencing-settings/city'                   component={hasPermission('CITY_LIST')             ? City                   : AccessDenied}/>
                <ProtectedRoute path='/geofencing-settings/country'                component={hasPermission('COUNTRY_LIST')          ? Country                : AccessDenied}/>
                <ProtectedRoute path='/geofencing-settings/parking'                component={hasPermission('PARKING')               ? Parking                : AccessDenied}/>
                <ProtectedRoute path='/operation-rules/region-rule-list'           component={hasPermission('REGION_RULES')          ? RegionRuleList         : AccessDenied}/>
                <ProtectedRoute path='/operation-rules/region-rule/:uuid'          component={hasPermission('REGION_RULES')          ? RegionRuleDetails      : AccessDenied} {...this.props}/>
                <ProtectedRoute path='/operation-rules/new-region-rule'            component={hasPermission('REGION_RULES')          ? RegionRuleDetails      : AccessDenied}/>
                <ProtectedRoute path='/operation-rules/task-rule-list'             component={hasPermission('TASK_RULES')            ? TaskRuleList           : AccessDenied}/>
                <ProtectedRoute path='/operation-rules/task-rule/:uuid'            component={hasPermission('TASK_RULES')            ? TaskRuleDetails        : AccessDenied} {...this.props}/>
                <ProtectedRoute path='/operation-rules/new-task-rule'              component={hasPermission('TASK_RULES')            ? TaskRuleDetails        : AccessDenied}/>
                <ProtectedRoute path='/pricing-settings/pricing-structure'         component={hasPermission('PRICING_STRUCTURE')     ? PricingList            : AccessDenied}/>
                <ProtectedRoute path='/pricing-settings/membership-structure'      component={hasPermission('MEMBERSHIP_STRUCTURE')  ? MembershipStructure    : AccessDenied}/>
                <ProtectedRoute path='/pricing-settings/discounting'               component={hasPermission('DISCOUNTING')           ? Discounting            : AccessDenied}/>
                <ProtectedRoute path='/pricing-settings/voucher'                   component={hasPermission('VOUCHER')               ? Voucher                : AccessDenied}/>
                <ProtectedRoute path='/order-information/parking-picture-checking' component={hasPermission('PARKING_PICTURE_CHECK') ? ParkingPictureChecking : AccessDenied}/>
                <ProtectedRoute path='/customer-service/live-chat'                 component={hasPermission('LIVE_CHAT')             ? LiveChat               : AccessDenied}/>
                <ProtectedRoute path='/task/:id'                                   component={hasPermission('TASK_LIST')             ? TaskDetail             : AccessDenied}/>
                <ProtectedRoute path='/task-management/team-metrics'               component={hasPermission('TASK_LIST')             ? TeamMetrics            : AccessDenied}/>
                <ProtectedRoute path='/admin-settings/business'                    component={hasPermission('BUSINESS')              ? BusinessList           : AccessDenied}/>
                <ProtectedRoute path='/business/:uuid'                             component={hasPermission('BUSINESS')              ? BusinessDetail         : AccessDenied}/>
                <ProtectedRoute path='/warranty/battery-management'                component={hasPermission('BATTERY')               ? BatteryList            : AccessDenied}/>
                <ProtectedRoute path='/battery/:uuid'                              component={hasPermission('BATTERY')               ? BatteryDetail          : AccessDenied}/>
                <ProtectedRoute path='/warranty/product'                           component={hasPermission('PRODUCT')               ? ProductWarranty        : AccessDenied}/>
                <ProtectedRoute path='/shopping/spare-parts'                       component={hasPermission('DASHBOARD')             ? ShoppingSpareParts     : AccessDenied}/>
                <ProtectedRoute path='/admin-settings/migration-process'           component={hasPermission('MIGRATION_PROCESS')     ? MigrationProcess       : AccessDenied}/>
                <ProtectedRoute path='/admin-settings/admin-users'                 component={hasPermission('ADMIN_USERS')           ? AdminUsers             : AccessDenied}/>
                <ProtectedRoute path='/admin-settings/business'                    component={hasPermission('BUSINESS')              ? BusinessList           : AccessDenied}/>
                <ProtectedRoute path='/admin-settings/download-data'               component={hasPermission('DOWNLOAD_DATA')         ? Download               : AccessDenied}/>
                <ProtectedRoute path='/business/:uuid'                             component={hasPermission('BUSINESS')              ? BusinessDetail         : AccessDenied}/>
                <ProtectedRoute path='/admin-settings/role'                        component={hasPermission('ADMIN_ROLES')           ? RoleList               : AccessDenied}/>
                <ProtectedRoute path='/role/:uuid'                                 component={hasPermission('ADMIN_ROLES')           ? RoleDetail             : AccessDenied}/>
                <ProtectedRoute path='/geofencing-settings/parking-list'           component={hasPermission('PARKING_LIST')          ? ParkingList            : AccessDenied}/>
                <ProtectedRoute path='/geofencing-settings/point-of-interest'      component={hasPermission('POINT_OF_INTEREST')     ? PointOfInterest        : AccessDenied}/>
                <ProtectedRoute path='/pricing-settings/top-up-template'           component={hasPermission('TOP_UP_TEMPLATE')       ? TopUpTemplate          : AccessDenied}/>
                <ProtectedRoute path='/admin-settings/trac-it-support'             component={hasPermission('TRAC_IT_SUPPORT')       ? TracItSupport          : AccessDenied}/>
                <ProtectedRoute path='/user-information/membership-account-list'   component={hasPermission('MEMBERSHIP_ACCOUNT_LIST')    ? MembershipAccountList  : AccessDenied}/>

                <Redirect to="/kpi-dashboard"/>
              </Switch>
            </div>
          </div>
          <SnackBarAlert/>
          <Footer/>
         </div>
       </div>
    )
  }
}


const mapStateToProps = (state) => ({
    currentAdminUser: state.appData.currentAdminUser,
    customerServiceChannels: state.appData.customerServiceChannels,
    liveChatConfig: state.appData.liveChatConfig,
    firebaseApp: state.appData.firebaseApp,
    pageRoles: state.appData.pageRoles,
});

const mapDispatchToProps = (dispatch) => ({
  getCurrentAdminUser: (auth0) => dispatch(getCurrentAdminUser(auth0)),
  setCustomerServiceChannels: (payload) => dispatch(setCustomerServiceChannels(payload)),
  setLiveChatConfig: (payload) => dispatch(setLiveChatConfig(payload)),
  setFirebaseApp: (payload) => dispatch(setFirebaseApp(payload)),
  getPageRoles: (payload) => dispatch(getPageRoles(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withAuth0(App));
