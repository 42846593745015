import React from "react";
import PropTypes from 'prop-types';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions, DialogContent, DialogContentText,
  DialogTitle,
  IconButton,
  Tooltip
} from "@material-ui/core";

import axios from "axios";
import {config} from "../../../auth/auth";
import AddPhotoAlternateIcon from "@material-ui/icons/AddPhotoAlternate";
import {grey, teal} from "@material-ui/core/colors";

const host = process.env.REACT_APP_HOST

class UploadGraph extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      open: false,
      selectedFile:''
    };
  }

  static propTypes = {
    url: PropTypes.string.isRequired,
    callback: PropTypes.func.isRequired
  };

  onChangeFile = (e) => {
      this.setState({
          selectedFile: e.target.files[0]
      })
  }

  submit = (url, callback) => {
    const { refresh } = this.props;
    const data = new FormData() 
    data.append('file', this.state.selectedFile)
    if (this.state.isLoading)
      return;

    this.setState({isLoading: true})

    return axios.post(`${host}${url}`, data, config())
      .then((response) => {})
      .catch(e => alert(e))
      .finally(() => {
        this.setState({isLoading: false,open: false, selectedFile:''});
        refresh()
      });
  };

  handleClickOpen = () => {
    this.setState({open: true});
  };

  disagree = () => {
    this.setState({open: false});
  };

  agree = (url, callback) => {
    this.setState({open: false});
    this.submit(url, callback);
  };

  render() {
    const { url, callback} = this.props;
    if (!url)
      return (<React.Fragment/>);

    let icon = <AddPhotoAlternateIcon/>;

    if (this.state.isLoading) {
      icon = <CircularProgress style={{width:20, height:20}}/>;
    }

    return (
      <React.Fragment>
        <Tooltip title='Upload Graph'>
          <IconButton onClick={this.handleClickOpen}>
            {icon}
          </IconButton>
        </Tooltip>
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">{"Upload the News Graph"}</DialogTitle>
          <DialogContent>
            <DialogContentText>
                Please keep in mind, the graph should not be too big, otherwise it will affect the user experience.
                <div className="form-group col-md-6">
                                    <label className="text-white">Select File :</label>
                                    <input type="file" className="form-control" name="upload_file" onChange={this.onChangeFile} />
                </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.disagree} style={{ color: grey[500] }}>
              Cancel
            </Button>
            <Button onClick={() => this.agree(url, callback)} style={{ color: teal[600] }}>
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

export default UploadGraph;