import {ActionTypes} from "./types";

const INITIAL_STATE = {
  privacyPolicyUrl: undefined,
  privacyPolicyContent: undefined
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ActionTypes.SET_PRIVACY_POLICY_URL:
      return {
        ...state,
        privacyPolicyUrl: action.payload,
      };
    case ActionTypes.SET_PRIVACY_POLICY_CONTENT:
      return {
        ...state,
        privacyPolicyContent: action.payload
      };
    default:
      return state;
  }
};

export default reducer;