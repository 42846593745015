import React from "react";
import {Marker} from "react-leaflet";
import PropTypes from 'prop-types';
import VehiclePopup from "./VehiclePopup";
import {Icon} from "leaflet";
import firebase from "firebase";

const normalIcon = {
  EBIKE: "/icon/available-ebike.svg",
  EKICKSCOOTER: "/icon/available-kickscooter.svg",
  EMOPED: "/icon/available-moped.svg",
  BIKE: "/icon/available-bike.svg",
}

const damagedIcon = {
  EBIKE: "/icon/damaged-ebike.svg",
  EKICKSCOOTER: "/icon/damaged-kickscooter.svg",
  EMOPED: "/icon/damaged-moped.svg",
  BIKE: "/icon/damaged-bike.svg",
}

const lostIcon = {
  EBIKE: "/icon/lost-ebike.svg",
  EKICKSCOOTER: "/icon/lost-kickscooter.svg",
  EMOPED: "/icon/lost-moped.svg",
  BIKE: "/icon/lost-bike.svg",
}

const lowBatteryIcon = {
  EBIKE: "/icon/low-battery-ebike.svg",
  EKICKSCOOTER: "/icon/low-battery-kickscooter.svg",
  EMOPED: "/icon/low-battery-moped.svg",
  BIKE: "/icon/low-battery-bike.svg",
}

const maintenancePendingIcon = {
  EBIKE: "/icon/maintenance-pending-bike.svg",
  EKICKSCOOTER: "/icon/maintenance-pending-kickscooter.svg",
  EMOPED: "/icon/maintenance-pending-moped.svg",
  BIKE: "/icon/maintenance-pending-bike.svg",
}

const maintainingIcon = {
  EBIKE: "/icon/maintaining-ebike.svg",
  EKICKSCOOTER: "/icon/maintaining-kickscooter.svg",
  EMOPED: "/icon/maintaining-moped.svg",
  BIKE: "/icon/maintaining-bike.svg",
}

const reportedIcon = {
  EBIKE: "/icon/reported-ebike.svg",
  EKICKSCOOTER: "/icon/reported-kickscooter.svg",
  EMOPED: "/icon/reported-moped.svg",
  BIKE: "/icon/reported-bike.svg",
}

const reservedIcon = {
  EBIKE: "/icon/reserved-ebike.svg",
  EKICKSCOOTER: "/icon/reserved-kickscooter.svg",
  EMOPED: "/icon/reserved-moped.svg",
  BIKE: "/icon/reserved-bike.svg",
}

const runningIcon = {
  EBIKE: "/icon/running-ebike.svg",
  EKICKSCOOTER: "/icon/running-kickscooter.svg",
  EMOPED: "/icon/running-moped.svg",
  BIKE: "/icon/running-bike.svg",
}

const lat = process.env.REACT_APP_LAT || 51.505
const lng = process.env.REACT_APP_LNG || -0.09
const env = process.env.REACT_APP_ENV

class VehicleMarker extends React.Component {
  static propTypes = {
    vehicle: PropTypes.object.isRequired,
  };
  _isMounted = false;
  constructor(props) {
    super(props);
    this.vehicleRef = undefined;
    this.state = {
      lat: this.props?.vehicle?.location?.lat || lat,
      lng: this.props?.vehicle?.location?.lng || lng,
    }
  }


  componentDidMount() {
    this._isMounted = true;
    const {vehicle} = this.props;

    // listen firebase to get live vehicle position
    if (!this.vehicleRef) {
      this.vehicleRef = firebase.database().ref(`${env}/console/vehicle/${vehicle.uuid}`);
      this.vehicleRef.on('value', (snapshot) => {
        const location = snapshot.val();
        if (this._isMounted && location && location.lat && location.lng) {
          this.setState({
            lat: location?.lat,
            lng: location?.lng
          });
        }
      });
    }

  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const {vehicle} = this.props;

    if (!vehicle)
      return (<React.Fragment/>);

    if (!vehicle?.location?.lat || !vehicle?.location?.lng){
      console.error('Error position', vehicle?.location);
      return (<React.Fragment/>);
    }

    const lat = this.state.lat || vehicle?.location?.lat;
    const lng = this.state.lng || vehicle?.location?.lng;
    const position = [lat, lng];

    const determineVehicleIconUrl = (vehicle) => {
      return vehicle?.status === 'AVAILABLE' ? normalIcon[`${vehicle?.category}`]
            :vehicle?.status === 'RUNNING' ? runningIcon[`${vehicle?.category}`]
            :vehicle?.status === 'RESERVED' ? reservedIcon[`${vehicle?.category}`]
            :vehicle?.status === 'REPORTED' ? reportedIcon[`${vehicle?.category}`]
            :vehicle?.status === 'MAINTAINING' ? maintainingIcon[`${vehicle?.category}`]
            :vehicle?.status === 'MAINTENANCE_PENDING' ? maintenancePendingIcon[`${vehicle?.category}`]
            :vehicle?.status === 'DAMAGED' ? damagedIcon[`${vehicle?.category}`]
            :vehicle?.status === 'LOST' ? lostIcon[`${vehicle?.category}`]
            :lowBatteryIcon;            
    }

    const iconUrl = determineVehicleIconUrl(vehicle);

    const icon = new Icon({
      iconUrl: `${iconUrl}`,
      iconSize: [25, 25]
    });

    return (
      <Marker key={`marker-key-${vehicle?.uuid}`}
              position={position}
              icon={icon}
      >
        <VehiclePopup vehicle={vehicle}/>
      </Marker>
    );
  }
}

export default VehicleMarker;

