import React from 'react';
import PropTypes from "prop-types";
import {Button, Dialog, DialogActions, DialogTitle, Grid, IconButton, Tooltip} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import CardContent from "@material-ui/core/CardContent";
import TextField from "@material-ui/core/TextField";
import {grey} from "@material-ui/core/colors";
import moment from "moment";
import {config} from "../../../auth/auth";
import axios from "axios";

const host = process.env.REACT_APP_HOST;
const PHONE_NUMBER_REGEX = /^\+[0-9]{1,15}$/gm
const DATE_FORMAT = process.env.REACT_APP_DATE_FORMAT;

class InviteGroundTeamUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      employmentDate: "",
      openModal: false,
      isFirstNameError: false,
      firstNameMsg: "",
      isLastNameError: false,
      lastNameMsg: "",
      isEmailError: false,
      emailMsg: "",
      isPhoneError: false,
      phoneMsg: "",
      isEmploymentDateError: false,
      employmentDateMsg: "",
    };
  }

  static propTypes = {
    callback: PropTypes.func.isRequired,
  };

  inviteGroundTeamUser = (callback) => {
    if (this.isFormValid()) {
      const body = {
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        email: this.state.email,
        phone: this.state.phone,
        employmentDate: String(moment(this.state.employmentDate).format(DATE_FORMAT))
      };
      return axios
        .post(`${host}/groundTeam/`, body, config())
        .then((response) => callback())
        .catch((e) => alert(e))
        .finally(() => {
          this.closeModal();
          this.clearFields();
        });
    }
  }

  isFormValid = () => {
    let isFormValid = true;

    if (this.state.firstName.length === 0) {
      isFormValid = false;
      this.setState({
        isFirstNameError: true,
        firstNameMsg: "First name is mandatory"
      });
    }
    if (this.state.lastName.length === 0) {
      isFormValid = false;
      this.setState({
        isLastNameError: true,
        lastNameMsg: "Last name is mandatory"
      });
    }
    if (this.state.email.length === 0) {
      isFormValid = false;
      this.setState({
        isEmailError: true,
        emailMsg: "Email is mandatory"
      });
    }
    if (this.state.employmentDate.length === 0) {
      isFormValid = false;
      this.setState({
        isEmploymentDateError: true,
        employmentDateMsg: "Employment Date is mandatory",
      });
    }
    if (this.state.phone.length === 0) {
      isFormValid = false;
      this.setState({
        isPhoneError: true,
        phoneMsg: "Phone number is mandatory"
      });
    }
    if (!this.state.phone.match(PHONE_NUMBER_REGEX)) {
      isFormValid = false;
      this.setState({
        isPhoneError: true,
        phoneMsg: "Phone number must start with '+' and Country Code."
      });
    }
    return isFormValid;
  }

  onInviteButton = () => {
    this.setState({
      openModal: true,
    });
  };

  closeModal = () => {
    this.setState({
      openModal: false,
    })
  }

  onChange = (e) => {
    let target = e.target;
    let id = target.id

    if (id === "firstName") {
      this.setState({
        firstName: target.value,
        isFirstNameError: false,
        firstNameMsg: ""
      });
    }
    if (id === "lastName") {
      this.setState({
        lastName: target.value,
        isLastNameError: false,
        lastNameMsg: ""
      });
    }
    if (id === "email") {
      this.setState({
        email: target.value,
        isEmailError: false,
        emailMsg: ""
      });
    }
    if (id === "phone") {
      this.setState({
        phone: target.value,
        isPhoneError: false,
        phoneMsg: ""
      });
    }
    if (id === "employmentDate") {
      this.setState({
        employmentDate: target.value,
        isEmploymentDateError: false,
        employmentDateMsg: "",
      });
    }
  };

  clearFields = () => {
    this.setState({
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      employmentDate: "",
    })
  }

  render() {
    const {callback} = this.props;

    let inputField = (name, label, error, errorMsg, type) => {
      return <Grid container spacing={2}>
        <Grid item sm={12} xs={12} md={12} lg={12}>
          <CardContent style={{paddingBottom: "0px"}}>
            <Grid container spacing={2} className="formLabel">
              <Grid item sm={12} xs={12} md={12} lg={12}>
                <TextField
                  id={name}
                  label={label}
                  fullWidth
                  type={`${type ? "date" : "text"}`}
                  error={error}
                  helperText={errorMsg}
                  value={this.state[name]}
                  onChange={this.onChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </CardContent>
        </Grid>
      </Grid>
    }

    return (
      <React.Fragment>
        <Tooltip title="Invite Ground Team User">
          <IconButton
            variant="contained"
            color="primary"
            onClick={() => this.onInviteButton()}
          >
            <AddIcon/>
          </IconButton>
        </Tooltip>
        <Dialog
          open={this.state.openModal}
          onClose={this.handleClose}
          aria-labelledby="User Action Modal"
          fullWidth={true}
        >
          <DialogTitle
            id="User Action Modal"
            style={{
              color: "white",
              backgroundColor: "#333399",
              textAlign: "center",
            }}
          >
            <label>Invite Ground Team User</label>
          </DialogTitle>
          {inputField("firstName", "First Name", this.state.isFirstNameError, this.state.firstNameMsg)}
          {inputField("lastName", "Last Name", this.state.isLastNameError, this.state.lastNameMsg)}
          {inputField("email", "Email", this.state.isEmailError, this.state.emailMsg)}
          {inputField("phone", "Phone Number", this.state.isPhoneError, this.state.phoneMsg)}
          {inputField("employmentDate", "Employment Date", this.state.isEmploymentDateError, this.state.employmentDateMsg, "date")}
          <DialogActions
            style={{
              justifyContent: "space-between",
              padding: "10px 40px 15px 40px",
            }}
          >
            <Button
              variant="contained"
              style={{
                color: "white",
                backgroundColor: "#333399",
                textAlign: "center",
              }}
              onClick={() => this.inviteGroundTeamUser(callback)}
            >
              Invite
            </Button>
            <Button
              variant="outlined"
              color="primary"
              onClick={this.closeModal} style={{color: grey[500]}}>
              Cancel
            </Button>
          </DialogActions>
        </Dialog>

      </React.Fragment>
    );
  };
}

export default InviteGroundTeamUser;