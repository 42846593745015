import React from "react";
import PropTypes from 'prop-types';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions, DialogContent, DialogContentText,
  DialogTitle,
  IconButton,
  Tooltip
} from "@material-ui/core";

import axios from "axios";
import {config} from "../../../../auth/auth";
import {grey, red} from "@material-ui/core/colors";
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import ListItemText from "@material-ui/core/ListItemText";
import TextField from "@material-ui/core/TextField";
import {Autocomplete} from "@material-ui/lab";
import {getAdminUsers} from "../../../../redux/adminUser/adminUser.action";
import {connect} from "react-redux";
import {withAuth0} from "@auth0/auth0-react";
import {assign, getUnassignedUsers, setUnassignedUsers, setUsers} from "../../../../redux/role/role.action";
import {fullName} from "../../../../utils/utils";


const host = process.env.REACT_APP_HOST

class UserSelector extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      open: false,
      selectedUser: undefined
    };
  }

  static propTypes = {
    roleUuid: PropTypes.string.isRequired,
    roleName: PropTypes.string.isRequired,
    callback: PropTypes.func.isRequired
  };

  handleClickOpen = () => {
    this.setState({open: true});
  };

  onClickCancelBtn = () => {
    this.setState({open: false});
    if (this.props.unassignedUsers) {
      this.props.setUnassignedUsers(undefined);
    }
  };

  onClickAssignBtn = () => {
    this.setState({loading: true});
    this.props.assign(this.props.roleUuid, this.state.selectedUser.uuid)
    .then(() => {
      this.setState({open: false, isLoading: false, selectedUser: undefined});
      if (this.props.unassignedUsers)
        this.props.setUnassignedUsers(undefined);
      this.props.callback();
    }).catch((e) => {
      console.error(e);
    }).finally(() => {
      this.setState({loading: false});
    });
  };

  isDisabled = () => {
    return this.state.isLoading || !this.state.selectedUser;
  }

  onChangeUser = (event, value) => {
    this.setState({selectedUser: value});
  }

  render() {
    const {roleUuid, roleName, callback, getUnassignedUsers, unassignedUsers} = this.props;
    if (!roleName)
      return (<React.Fragment/>);

    if (!unassignedUsers && this.state.open) {
      getUnassignedUsers(roleUuid);
    }

    return (
      <React.Fragment>
        <Tooltip title='Add User'>
          <IconButton onClick={this.handleClickOpen}>
            <PersonAddIcon/>
          </IconButton>
        </Tooltip>
        <Dialog
          maxWidth={"sm"}
          fullWidth={true}
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="assign-role-2-user-dialog-title-id">{`Assign ${roleName} role to users`}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Select users you want to assign this role to.
            </DialogContentText>
            <DialogContentText>
              <Autocomplete
                id="assign-role-2-user-dropdown-id"
                style={{ width: "100%" }}
                options={unassignedUsers ?? []}
                autoHighlight
                value={this.state.selectedUser}
                disabled={this.state.loading ?? false}
                getOptionSelected={(option, value) => option.uuid === value}
                getOptionLabel={(option) => `${fullName(option)}`}
                renderOption={(option) => (
                  <React.Fragment>
                    <ListItem>
                      <ListItemAvatar>
                        <Avatar alt={`${fullName(option)}`} src={option?.avatar} />
                      </ListItemAvatar>
                      <ListItemText primary={`${fullName(option)}`} secondary={option?.email} />
                    </ListItem>
                  </React.Fragment>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="User"
                    variant="outlined"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {this.state.loading ? <CircularProgress color="inherit" size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
                onChange={this.onChangeUser}
              />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.onClickCancelBtn} style={{ color: grey[500] }}>
              Cancel
            </Button>
            <Button
              disabled={this.isDisabled()}
              onClick={this.onClickAssignBtn}
              style={{color: this.isDisabled() ? "grey": "white", backgroundColor: this.isDisabled() ? "": "#36b9cc"}}
            >
              Assign
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({ unassignedUsers: state.roleReducer.unassignedUsers });

const mapDispatchToProps = (dispatch) => ({
  getUnassignedUsers: (params) => dispatch(getUnassignedUsers(params)),
  setUsers: (params) => dispatch(setUsers(params)),
  setUnassignedUsers: (params) => dispatch(setUnassignedUsers(params)),
  assign: (roleUuid, consoleUserUuid) => dispatch(assign(roleUuid, consoleUserUuid)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withAuth0(UserSelector));