import React, {Component} from 'react';
import * as L from 'leaflet'
import 'leaflet-draw'

export default class RegionConfigurationSearchMap extends Component {

    componentDidMount() {
        // create map
        this.map = L.map('map', {
          center: [51.505, -0.09],
          zoom: 13,
          layers: [
            L.tileLayer('http://{s}.tile.osm.org/{z}/{x}/{y}.png', {
              attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            }),
          ]
        },{ drawControl: true });
        var center = [-33.8650, 151.2094];
        L.marker(center).addTo(this.map);

        var editableLayers = new L.FeatureGroup();
        this.map.addLayer(editableLayers);

        // define custom marker
        var MyCustomMarker = L.Icon.extend({
        options: {
            shadowUrl: null,
            iconAnchor: new L.Point(12, 12),
            iconSize: new L.Point(24, 24),
            iconUrl: 'https://upload.wikimedia.org/wikipedia/commons/6/6b/Information_icon4_orange.svg'
        }
        });

        var drawPluginOptions = {
        position: 'topright',
        draw: {
            polyline: {
            shapeOptions: {
                color: '#f357a1',
                weight: 10
            }
            },
            polygon: {
            allowIntersection: false, // Restricts shapes to simple polygons
            drawError: {
                color: '#e1e100', // Color the shape will turn when intersects
                message: '<strong>Polygon draw does not allow intersections!<strong> (allowIntersection: false)' // Message that will show when intersect
            },
            shapeOptions: {
                color: '#97009c'
            }
            },
            circle: false, // Turns off this drawing tool
            rectangle: {
            shapeOptions: {
                clickable: false
            }
            },
            marker: {
            icon: new MyCustomMarker()
            }
        },
        edit: {
            featureGroup: editableLayers, //REQUIRED!!
            remove: false
        }
        };

        // Initialise the draw control and pass it the FeatureGroup of editable layers
        var drawControl = new L.Control.Draw(drawPluginOptions);
        this.map.addControl(drawControl);


        var editableLayers = new L.FeatureGroup();
        this.map.addLayer(editableLayers);

        this.map.on('draw:created', function(e) {
        var type = e.layerType,
            layer = e.layer;

        if (type === 'marker') {
            layer.bindPopup('<div>' + layer.toGeoJSON()["geometry"]["coordinates"] +'</div>');
        }

        if (type === 'polygon') {
          layer.bindPopup('<div>' + layer.toGeoJSON()["geometry"]["coordinates"] +'</div>');
      }

        editableLayers.addLayer(layer);
        });

        
        }
        
      render() {
        return (
        <div id="map">

        </div>
        )
      }
    }

    