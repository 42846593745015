import React from "react";
import {red} from "@material-ui/core/colors";
import {IconButton, Tooltip} from "@material-ui/core";
import PaymentIcon from "@material-ui/icons/Payment";
import axios from "axios";
import {config} from "../../../../auth/auth";

const host = process.env.REACT_APP_HOST

class Refund extends React.Component {
  
    componentDidMount() {}
  
    onClickRefund = (orderUuid) => {
        const { callback} = this.props;
        alert('Refund all of amount of the order')
        return axios
        .get(`${host}/order/${orderUuid}/refund`, config())
        .catch((e) => alert(e))
        .finally(() => {
            callback();
          });
    }

  render() {
    const { orderUuid, status, callback } = this.props;

    return (
        <React.Fragment>
          {status === "REFUNDED" ? (
            <Tooltip title="Already Refunded">
              <IconButton
                aria-label="Block"
                onClick= {()=>{ alert('This order is already refunded'); }}
              >
                <PaymentIcon style={{color: red[500]}}/>
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title="Refund the user">
              <IconButton
                aria-label="Block"
                onClick={() => this.onClickRefund(orderUuid, callback)}
              >
                <PaymentIcon/>
              </IconButton>
            </Tooltip>
          )}
        </React.Fragment>
    );
  }
}

export default Refund;

