import React, {Component} from "react";
import {connect} from "react-redux";
import {getRegionRuleByUuid} from "../../../../redux/RegionRule/regionRule.action";
import {withAuth0} from "@auth0/auth0-react";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import {Button, Grid} from "@material-ui/core";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import moment from "moment";

import {config} from "../../../../auth/auth";
import axios from "axios";
import RegionRuleActions from "./region-rule-actions"
import RegionRuleNotifications from "./region-rule-notifications"

const datetime_format = process.env.REACT_APP_DATETIME_FORMAT;
const host = process.env.REACT_APP_HOST

class RegionRuleDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            uuid: null,
            description: "",
            fromRegion: "ANY",
            toRegion: "ANY",
            status: "ACTIVE",
            createdAt: "",
            updatedAt: "",
            isDirty:false
        };
    }

    componentDidMount() {
        this.refresh();
    }

    refresh = () => {

        if(this.state.creating === undefined && this.props.location.pathname.includes('new-region-rule')){
            this.setState({creating:true});
            return;
        }

        let uuid = this.state.uuid ?? this.props.location.pathname.replace("/operation-rules/region-rule/", "");
        var regionRuleDetails = this.props.getRegionRuleByUuid(uuid);
        regionRuleDetails
            .then((response) => {
                const regionRuleData = response?.payload;
                this.setState({
                    uuid: regionRuleData?.uuid,
                    name: regionRuleData?.name,
                    description: regionRuleData?.description,
                    fromRegion: regionRuleData?.oldRegionCategory ?? "ANY",
                    toRegion: regionRuleData?.newRegionCategory ?? "ANY",
                    status: regionRuleData?.status,
                    createdAt: moment(regionRuleData.createdAt).local().format(datetime_format),
                    updatedAt: moment(regionRuleData.updatedAt).local().format(datetime_format),
                    isDirty: false
                });
            })
            .catch((e) => alert(e))
            .finally(() => { });
    };

    saveDetails = (e) => {
        if(this.state.creating){
            this.createNewRule();
            return;
        }

        const body = {
            name: this.state.name,
            status: this.state.status,
            description: this.state.description,
            oldRegionCategory: this.state.fromRegion === "ANY" ? null : this.state.fromRegion,
            newRegionCategory: this.state.toRegion === "ANY" ? null : this.state.toRegion,
        };
        return axios
            .put(`${host}/regionRule/${this.state.uuid}`, body, config())
            .then((response) => {
                this.setState({isDirty:false});
                this.refresh();
            })
            .catch((e) => {
                this.refresh();
            })
            .finally(() => {});
    };

    createNewRule = () => {
        const body = {
            name: this.state.name,
            status: this.state.status,
            description: this.state.description,
            oldRegionCategory: this.state.fromRegion === "ANY" ? null : this.state.fromRegion,
            newRegionCategory: this.state.toRegion === "ANY" ? null : this.state.toRegion,
        };
        return axios
            .post(`${host}/regionRule/`, body, config())
            .then((response) =>{
                const regionRuleData = response?.data;
                this.setState({
                    creating:false,
                    uuid: regionRuleData.uuid,
                    createdAt: moment(regionRuleData.createdAt).local().format(datetime_format),
                    updatedAt: moment(regionRuleData.updatedAt).local().format(datetime_format),
                    isDirty:false
                })
            }
            )
            .catch((e) => {
                this.refresh();
            })
            .finally(() => {
            });
    }

    handleNameChange = (e) => {
        this.setState({ name: e.target.value, isDirty:true });
    };

    handleDescriptionChange = (e) => {
        this.setState({ description: e.target.value, isDirty:true });
    };

    handleStatusChange = (e) => {
        this.setState({ status: e.target.value, isDirty:true });
    };

    handleFromRegionChange = (e) => {
        this.setState({ fromRegion: e.target.value, isDirty:true });
    };

    handleToRegionChange = (e) => {
        this.setState({ toRegion: e.target.value, isDirty:true });
    };

    render() {
        let showActions = "Loading";
        let showNotifications = "Loading";
        if (this.state.uuid){
            showActions = <RegionRuleActions regionRuleUuid={this.state.uuid} />;
            showNotifications = <RegionRuleNotifications regionRuleUuid={this.state.uuid} />;
        }else if(this.state.creating){
            showActions = "";
            showNotifications = "";
        }

        return (
            <React.Fragment>
                <Grid container spacing={2}>
                    <Grid item md={12} lg={8}>
                        <Card style={{ height: "100%" }}>
                            <CardHeader title="Region Rule Details" className="primary" />
                            <CardContent>
                                <Row>
                                    <Col xs={12} md={6} className="detailsFormLabel">
                                        {" "}
                                        <TextField
                                            id="uuid"
                                            label="Region Rule UUID"
                                            className=""
                                            value={this.state?.uuid}
                                            fullWidth
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            variant="outlined"
                                        />
                                    </Col>
                                    <Col xs={12} md={6} className="detailsFormLabel">
                                        <InputLabel htmlFor="status">Status</InputLabel>
                                        <Select
                                            value={this.state.status}
                                            onChange={this.handleStatusChange}
                                            inputProps={{
                                                name: 'Status',
                                                id: 'status',
                                            }}
                                        >
                                            <MenuItem value="ACTIVE">ACTIVE</MenuItem>
                                            <MenuItem value="INACTIVE">INACTIVE</MenuItem>
                                        </Select>
                                    </Col>

                                </Row>
                                <Row>
                                    <Col xs={12} md={6} className="detailsFormLabel">
                                        {" "}
                                        <TextField
                                            id="name"
                                            label="Name"
                                            className=""
                                            value={this.state?.name}
                                            fullWidth
                                            InputProps={{
                                                readOnly: false,
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            variant="outlined"
                                            onChange={this.handleNameChange}
                                        />
                                    </Col>
                                    <Col xs={12} md={6} className="detailsFormLabel">
                                        {" "}
                                        <TextField
                                            id="description"
                                            label="Description"
                                            className=""
                                            value={this.state?.description}
                                            fullWidth
                                            InputProps={{
                                                readOnly: false,
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            variant="outlined"
                                            onChange={this.handleDescriptionChange}
                                        />
                                    </Col>

                                </Row>
                                <Row>
                                    <Col xs={12} md={6} className="detailsFormLabel">
                                        {" "}
                                        <TextField
                                            id="createdAt"
                                            label="Created Time"
                                            className=""
                                            value={this.state.createdAt}
                                            fullWidth
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            variant="outlined"
                                        />
                                    </Col>
                                    <Col xs={12} md={6} className="detailsFormLabel">
                                        {" "}
                                        <TextField
                                            id="updatedAt"
                                            label="Latest update time"
                                            className=""
                                            value={this.state.updatedAt}
                                            fullWidth
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            variant="outlined"
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} md={6} className="detailsFormLabel">
                                        {" "}
                                        <InputLabel htmlFor="from-region">From Region</InputLabel>
                                        <Select
                                            value={this.state.fromRegion}
                                            onChange={this.handleFromRegionChange}
                                            inputProps={{
                                                name: 'fromRegion',
                                                id: 'from-region',
                                            }}
                                        >
                                            <MenuItem value="ANY">ANY</MenuItem>
                                            <MenuItem value="NO_PARKING">NO_PARKING</MenuItem>
                                            <MenuItem value="FORBIDDEN">FORBIDDEN</MenuItem>
                                            <MenuItem value="LOW_SPEED">LOW_SPEED</MenuItem>
                                            <MenuItem value="OPERATING">OPERATING</MenuItem>
                                        </Select>
                                    </Col>
                                    <Col xs={12} md={6} className="detailsFormLabel">
                                        {" "}
                                        <InputLabel htmlFor="to-region">To Region</InputLabel>
                                        <Select
                                            value={this.state.toRegion}
                                            onChange={this.handleToRegionChange}
                                            inputProps={{
                                                name: 'toRegion',
                                                id: 'to-region',
                                            }}
                                        >
                                            <MenuItem value="ANY">ANY</MenuItem>
                                            <MenuItem value="NO_PARKING">NO_PARKING</MenuItem>
                                            <MenuItem value="FORBIDDEN">FORBIDDEN</MenuItem>
                                            <MenuItem value="LOW_SPEED">LOW_SPEED</MenuItem>
                                            <MenuItem value="OPERATING">OPERATING</MenuItem>
                                        </Select>
                                    </Col>

                                </Row>
                                <Row
                                    style={{ justifyContent: "flex-end", marginRight: "auto" }}
                                >
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        disabled={!this.state.isDirty}
                                        onClick={() => this.saveDetails()}
                                    >Save</Button>
                                </Row>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item md={12} lg={4}>
                        <Card style={{ height: "100%" }}>
                            <CardHeader title="Help" className="primary" />
                            <CardContent>
                                <h3>About the delays in action</h3>
                                The delays in action is always counted from the time the rule is being triggered.
                                If you define multiple actions with delays, you need to increase the delay based on the prevoius action.
                                e.g.
                                <br/><br/>
                                <li>ALARM, delay 0</li>
                                <li>SET_SPEED_LOW, delay 10</li>
                                <li>LOCK, delay 20</li>
                                <br/>
                                The actions will be exectued with 10 seconds gap inbetween.
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>

                <Grid container spacing={2}>
                    <Grid item md={12} lg={6}>
                        <Card style={{ height: "100%" }}>
                            <CardHeader title="Actions" className="primary" />
                            <CardContent>
                                {showActions}
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item md={12} lg={6}>
                        <Card style={{ height: "100%" }}>
                            <CardHeader title="Notifications" className="primary" />
                            <CardContent>
                                {showNotifications}
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </React.Fragment>
        );
    }

}


const mapStateToProps = (state) => ({
    regionRule: state.regionRuleReducer.regionRule
});

const mapDispatchToProps = (dispatch) => ({
    getRegionRuleByUuid: (uuid) => dispatch(getRegionRuleByUuid(uuid))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withAuth0(RegionRuleDetails));
