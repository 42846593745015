import React from "react";
import PropTypes from 'prop-types';
import Brightness1Icon from "@material-ui/icons/Brightness1";
import {green, grey, red, yellow} from "@material-ui/core/colors";

class TransactionStatus extends React.Component {
  static propTypes = {
    status: PropTypes.string.isRequired,
  };

  render() {
    const { status } = this.props;
    if (!status)
      return (<React.Fragment/>);
    let color;
    if(status === 'REFUNDED') { color = green[500] }
    else if(status === 'CONFIRMING') { color = yellow[500] }
    else if(status === 'SUCCEEDED') { color = green[500] }
    else if(status === 'REFUND_FAILURE') { color = yellow[500] }
    else if(status === 'CANCELED') { color = grey[900] }
    else if(status === 'FAILURE') { color = red[500] }
    else { color = grey[500] }
    return (
      <React.Fragment>
        <Brightness1Icon style={{width:16, height:16, color: color}}/>
        {status}
      </React.Fragment>
    );
  }
}

export default TransactionStatus;