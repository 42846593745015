import React, {useEffect, useRef, useState} from 'react';
import {Card, CircularProgress, DialogTitle, Grid} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import {makeStyles} from '@material-ui/core/styles';
import './POICreate.css'
import axios from "axios";
import {config} from "../../../../auth/auth";
import {connect} from "react-redux";
import {withAuth0} from "@auth0/auth0-react";
import {getPlaces} from "../../../../redux/places/action";
import {grey} from "@material-ui/core/colors";

import Cropper from "react-easy-crop";
import {dataURLtoFile} from "../../../../utils/dataURLtoFile";
import getCroppedImg from "../../../../utils/cropImage";

const host = process.env.REACT_APP_HOST;

const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      textAlign: "center",
    },
    imageHolder: {
      position: "relative",
      justifyContent: 'center',
      alignItems: 'center',
      height: '310px',
    },
    image: {
      border: "none",
      paddingTop: "5px",
      width: '300px',
      height: '300px',
      objectFit: 'cover'
    }
  }))
;

const PoiUploadIconModal = ({setOpenModal, getPlaces, setOpenSuccessModal, poiId: poiId}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [image, setImage] = useState();
  const [preview, setPreview] = useState();
  const [croppedArea, setCroppedArea] = React.useState(null);
  const [crop, setCrop] = React.useState({x: 0, y: 0});
  const [zoom, setZoom] = React.useState(1);
  const fileInputRef = useRef();
  const classes = useStyles();

  useEffect(() => {
    if (image) {
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onloadend = () => {
        setPreview(reader.result);
      };
      // reader.readAsDataURL(image);
    } else {
      setPreview(null);
    }
  }, [image]);


  const handleSubmit = async (e) => {
    const canvas = await getCroppedImg(preview, croppedArea);
    const canvasDataUrl = canvas.toDataURL("image/jpeg");
    const convertedUrlToFile = dataURLtoFile(
      canvasDataUrl,
      `${poiId}-cropped-image.jpeg`
    );
    const body = new FormData();
    body.append('file', convertedUrlToFile)

    setIsLoading(true);

    const response = await axios
      .put(`${host}/place/${poiId}/icon`, body, config())
      .catch((e) => alert(e));

    if (response?.status >= 200 && response?.status < 300) {
      getPlaces();
      setOpenSuccessModal({
        open: true,
        content: `Icon updated successfully.`
      });
    } else {
      setOpenSuccessModal({
        open: true,
        header: "Failed",
        content: `Failed to update icon.`
      });
    }

    if (setOpenModal) {
      setOpenModal(false);
    }
    setIsLoading(false);
  }


  const onCropComplete = (croppedAreaPercentage, croppedAreaPixels) => {
    setCroppedArea(croppedAreaPixels);
  }

  const onSelectFile = event => {
    const file = event.target.files[0];
    if (file && file.type.substr(0, 5) === "image") {
      setImage(file);
    } else {
      setImage(null);
    }
  }

  return (
    <Card>
      <DialogTitle
        id="Users Action Modal"
        style={{
          color: "white",
          backgroundColor: "#333399",
          textAlign: "left",
        }}
      >
        <label>Upload icon</label>
      </DialogTitle>
      <div className={classes.root}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div className={classes.imageHolder}>
              {!preview && <img
                src={preview}
                className={classes.image}
              />}
              {preview &&
              <Cropper
                image={preview}
                crop={crop}
                zoom={zoom}
                minZoom={0.01}
                classes={classes.image}
                cropSize={{width: 180, height: 180}}
                showGrid={false}
                onCropChange={setCrop}
                onZoomChange={setZoom}
                onCropComplete={onCropComplete}
              />}
            </div>
          </Grid>
          <Grid item xs={12} className="mb-3">
            {isLoading && <CircularProgress style={{width: 20, height: 20}}/>}
            {!isLoading && <Button
              variant="contained"
              disabled={isLoading}
              style={{color: "white", backgroundColor: `${isLoading ? grey[500] : "#333399"}`}}
              onClick={(event) => {
                event.preventDefault();
                fileInputRef.current.click();
              }}
            >
              Upload Icon
            </Button>}
            {preview && !isLoading && <Button
              variant="contained"
              disabled={isLoading}
              style={{color: "white", backgroundColor: `${isLoading ? grey[500] : "#333399"}`}}
              onClick={handleSubmit}
            >
              Submit
            </Button>}
            <input
              type="file"
              hidden
              ref={fileInputRef}
              accept="image/*"
              onChange={onSelectFile}
            />
          </Grid>
        </Grid>
      </div>
    </Card>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  getPlaces: () => dispatch(getPlaces())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withAuth0(PoiUploadIconModal));