import React, {Component} from "react";
import MUIDataTable from "mui-datatables";
import {withAuth0} from "@auth0/auth0-react";
import {Button, Tooltip,} from "@material-ui/core";
import {connect} from "react-redux";
import moment from "moment";
import {search} from "../../../../redux/transaction/transaction.action";
import {queryParams} from "../../../../utils/utils";
import Amount from "../../../../components/common/Amount";
import TransactionStatus from "./TransactionStatus";
import PropTypes from "prop-types";
import BankRefundPopUp from "../../../../components/PopUp/BankRefundPopUp/BankRefundPopUp";
import TransactionRefreshButton from "./TransactionRefreshButton";
import CustomToolbar from "../../../../components/List/CustomToolBar/CustomToolbar";

const date_format = process.env.REACT_APP_DATE_FORMAT;
const datetime_format = process.env.REACT_APP_DATETIME_FORMAT;

class TransactionList extends Component {
  constructor(props) {
    super(props);
    this.isFilterDialogOpen = false;
    this.tableRef = React.createRef();
    this.columnsDisplay = undefined;
  }

  static propTypes = {
    filters: PropTypes.object,
  };

  componentDidMount() {
    this.refresh();
  }

  refresh = () => {
    const { search, filters } = this.props;

    // table state
    const state = this.tableRef?.current?.state;

    const params = queryParams(this.props, state);

    filters?.map((f) => params.set(f.key, f.value));

    search(params);
  };

  onChangeColumnDisplay = () => {
    const state = this.tableRef?.current?.state;
    this.columnsDisplay = state.columns.reduce(
      (obj, item) => Object.assign(obj, { [item.name]: item.display }),
      {}
    );
  };

  isDisplay = (name) => {
    if (!this.columnsDisplay || !this.columnsDisplay.hasOwnProperty(name))
      return undefined;
    else return this.columnsDisplay[name];
  };

  render() {
    const { transactions } = this.props;
    if (!transactions) return <React.Fragment>Loading...</React.Fragment>;
    const list = transactions?.content;
    const totalElements = transactions?.totalElements ?? 0;
    const rowsPerPage = transactions?.pageable?.pageSize ?? 10;
    const page = transactions?.pageable?.pageNumber ?? 0;


    const columns = [
      {
        name: "uuid",
        label: "ID",
        options: {
          filter: true,
          sort: false,
          display: this.isDisplay("uuid") ?? true,
          customFilterListOptions: { render: (v) => `UUID: ${v}` },
          filterType: "textField",
        },
      },
      {
        name: "createdAt",
        label: "Created Time",
        options: {
          filter: false,
          sort: false,
          display: this.isDisplay("createdAt") ??  true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <Tooltip title={value ? moment.utc(value).local().format(datetime_format) : ""}>
                <div>{value ? moment.utc(value).local().format(date_format) : ""}</div>
              </Tooltip>
            );
          },
        },
      },
      {
        name: "amount",
        label: "Amount",
        options: {
          filter: false,
          sort: false,
          display: this.isDisplay("amount") ??  true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return(
              value ? <Amount value={value} currency={tableMeta?.rowData[6]?tableMeta?.rowData[6]:'SAR'} /> :0
            );
          },
        },
      },
      {
        name: "businessType",
        label: "Business Type",
        options: {
          filter: true,
          sort: false,
          display: this.isDisplay("businessType") ?? true,
          customFilterListOptions: { render: (v) => `businessType: ${v}` },
        },
      },
      {
        name: "transactionType",
        label: "Transaction Type",
        options: {
          filter: false,
          sort: false,
          display: this.isDisplay("transactionType") ?? false,
          customFilterListOptions: { render: (v) => `transactionType: ${v}` },
        },
      },
      {
        name: "status",
        label: "Status",
        options: {
          filter: true,
          sort: false,
          display: this.isDisplay("status") ??  true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return(<TransactionStatus status={value}/>);
          },
        },
      },
      {
        name: "currency",
        label: "Currency",
        options: {
          filter: false,
          sort: false,
          display: this.isDisplay("currency") ??  false,
        },
      },
      {
        name: "createdBy",
        label: "Creator",
        options: {
          filter: false,
          sort: false,
          display: this.isDisplay("createdBy") ??  true,
        },
      },
      {
        name: "Operations",
        options: {
          filter: false,
          sort: false,
          empty: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div>
                <BankRefundPopUp
                  refresh = {this.refresh}
                  uuid = {tableMeta?.rowData[0]}
                  businessType = {tableMeta?.rowData[3]}
                />
                <TransactionRefreshButton uuid={tableMeta?.rowData[0]}
                                          businessType={tableMeta?.rowData[3]}
                                          status={tableMeta?.rowData[5]}
                                          callback={this.refresh}/>
              </div>
            );
          },
        },
      },
    ];

    const options = {
      search:false,
      viewColumns: false,
      filter: true,
      confirmFilters: true,
      serverSide: true,
      filterType: "dropdown",
      responsive: "vertical",
      rowsPerPageOptions: [10, 20, 50, 100],
      rowsPerPage: rowsPerPage,
      count: totalElements,
      page: page,
      customToolbarSelect: () => {},
      onTableChange: (action, tableState) => {
        switch (action) {
          case "viewColumnsChange":
            this.onChangeColumnDisplay();
            break;
          case "onFilterDialogOpen":
            this.isFilterDialogOpen = true;
            break;
          case "filterChange":
            if (this.isFilterDialogOpen) return;
            else this.refresh();
            break;
          case "onFilterDialogClose":
            this.isFilterDialogOpen = false;
          case "changePage":
          case "sort":
          case "changeRowsPerPage":
            this.refresh();
            break;
          default:
            console.log("Not handled action " + action);
        }
      },
      customFilterDialogFooter: (currentFilterList, applyNewFilters) => {
        return (
          <div style={{ marginTop: "40px" }}>
            <Button
              variant="contained"
              style={{ color: "white", backgroundColor: "#36b9cc" }}
              onClick={() => {
                applyNewFilters();
              }}
            >
              Apply
            </Button>
          </div>
        );
      },
      customToolbar: () => {
        return (
          <CustomToolbar refresh={this.refresh} tableProps={this.props}/>
        );
      }
    };

    return (
      <React.Fragment>
        <MUIDataTable
          title={"Transaction List"}
          data={list}
          columns={columns}
          options={options}
          ref={this.tableRef}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  transactions: state.transactionReducer.transactions,
});

const mapDispatchToProps = (dispatch) => ({
  search: (params) => dispatch(search(params)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withAuth0(TransactionList));
