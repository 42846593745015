import React from "react";
import {Popup} from "react-leaflet";
import PropTypes from "prop-types";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import {determineVehicleImage} from "../../../utils/utils";
import {Button} from "@material-ui/core";
import TaskCreateModal from "./TaskCreate";
import {Link} from "react-router-dom";

class TaskPopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openPopUp: false,
      lat: "",
      lng: "",
      vehicleId: "",
      vehicleCategory: "",
    };
    this.editableLayers = undefined;
    this.map = undefined;
  }
  static propTypes = {
    vehicle: PropTypes.object.isRequired,
  };

  handleModalCloseChange = () => {
    this.setState({
      openPopUp: false,
      lat: "",
      lng: "",
      vehicleId: "",
    });
  };

  onSuccessTaskCreation() {
    const { callback } = this.props;
    this.setState({
      openPopUp: false,
      lat: "",
      lng: "",
      vehicleId: "",
    });
    callback()
  }
  onCreateTask(lat, lng, id, category) {
    this.setState({ openPopUp: true, lat: lat, lng: lng, vehicleId: id, vehicleCategory: category });
  }

  render() {
    const { vehicle } = this.props;
    let img = "";
    if (vehicle?.graphUrl) {
      img = vehicle.graphUrl;
    } else {
      img = determineVehicleImage(vehicle.category);
    }

    return (
      <Popup>
        <Card style={{ maxWidth: 445, width: "100%", minWidth: "250px" }}>
          <CardMedia
            key={`vehicle-map-marker-card-media-${vehicle.uuid}`}
            className={
              vehicle.category == "EBIKE" ||
              vehicle.category == "BIKE" ||
              vehicle.category == "ECAR"
                ? "bikeCarImage"
                : "EscooterEmopedImage"
            }
            image={img}
          />
          <CardContent>
            <Typography variant="body2" color="textSecondary" component="p">
              <b>Frame No.:</b> <Link to={`/vehicle/${vehicle?.uuid}`} activeClassName="current">{vehicle?.frameNo}</Link>
              <br />
              <b>QR Code:</b> {vehicle?.qrCode}
              <br />
              <b>Battery Level:</b>{" "}
              {vehicle?.batteryLevel ? `${vehicle?.batteryLevel}%` : ""}
              <br />
              <b>Status:</b> {vehicle?.status ? `${vehicle?.status}` : ""}
              <br />
              <b>Region:</b>{" "}
              {vehicle?.regionName ? `${vehicle?.regionName}` : ""}
              <br />
            </Typography>
            <Button variant="contained" onClick={() => this.onCreateTask(vehicle?.location?.lat,vehicle?.location?.lng,vehicle?.uuid,vehicle?.category)}>Create Task</Button>
            <TaskCreateModal
              open={this.state.openPopUp}
              lat={this.state.lat}
              lng={this.state.lng}
              vehicleId={this.state.vehicleId}
              vehicleCategory={this.state.vehicleCategory}
              onCloseModal={() => this.handleModalCloseChange()}
              callback={() => this.onSuccessTaskCreation()}
            ></TaskCreateModal>
            {/* style={{
                display: vehicle?.status !== "AVAILABLE" ? "block" : "none",
              }} */}
          </CardContent>
        </Card>
      </Popup>
    );
  }
}

export default TaskPopup;
