import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PieChart from "../../../components/Chart/PieChart";
import { getVehicleImageUrl } from "../../../utils/utils";
import IconButton from "@material-ui/core/IconButton";
import Avatar from "@material-ui/core/Avatar";
import { Tooltip } from "@material-ui/core";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import {getVehicleBatteryStatus} from "../../../redux/dashboard/dashboard.action";
import {connect} from "react-redux";
import {withAuth0} from "@auth0/auth0-react";
import {Skeleton} from "@material-ui/lab";
import { CSVLink } from "react-csv";
import vehicleTypeList from "../../../data/vehicle-type-list.json"
import { withTranslation } from 'react-i18next';

const refresh_frequency = process.env.REACT_APP_REFRESH_FREQUENCY_MINUTES * 60000 ?? 600000

class VehicleBatteryStatusCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedVehicle: ""
    }
  }

  componentDidMount() {
    if (!this.props?.vehicleBatteryStatus)
      this.refresh();
  }

  refresh = () => {
    this.props.getVehicleBatteryStatus();
    setTimeout(() => this.refresh(), refresh_frequency);
  }


  changeVehicle = (vehicleType) => {
    vehicleType === this.state.selectedVehicle ? 
      this.setState({selectedVehicle: ''}) : this.setState({selectedVehicle: vehicleType});
  }

  getSeriesName = () => {
    const selectedVehicleData = vehicleTypeList.find(x => x.label === this.state.selectedVehicle);
    if (selectedVehicleData) {
      return `${selectedVehicleData.title}`
    } else {
      return `All Vehicles`;
    }
  }

  render() {
    const {vehicleBatteryStatus,t} = this.props;
    const {selectedVehicle} = this.state;

    const statusList = [
      {label: 'LOW', name: "Over Warranty"},
      {label: 'MIDDLE', name: "Medium"},
      {label: 'HIGH', name: "Healthy"}
    ]

    if (!vehicleBatteryStatus) {
      return (<div className="col-xl col-lg"><Skeleton variant="rect" width={"100%"} height={500}/></div>);
    }
    const labelOptions = {
      show: true,
      position: 'outside',
      formatter: (params) => {
          return params.value > 0 ? `${params.value} (${params.percent}%)` : ''
      }
    }

    let statusSum = 0;
    const chartData = statusList.reduce((acc, curr, index) => {
      let statusValue = 0;
      if (selectedVehicle) {
        statusValue = (vehicleBatteryStatus[selectedVehicle]&&vehicleBatteryStatus[selectedVehicle][curr.label]) ? vehicleBatteryStatus[selectedVehicle][curr.label] : 0;
      } else {
        for (const [_, value] of Object.entries(vehicleBatteryStatus)) {
          statusValue = statusValue + (value[curr.label] || 0);
        }
      }
      acc.push({value: statusValue, name: curr.name});
      statusSum = statusSum + acc[index].value;
      return acc;
    }, []);

    const reportData = chartData.reduce((acc, curr, index) => {
      acc.push({
        Vehicle: this.getSeriesName(),
        Status: curr.name,
        Value: curr.value,
        Percentage: `${((curr.value * 100) / statusSum).toFixed(2)}%`,
      });
      return acc;
    }, [])

    return (
      <div className="col-xl col-lg">
        <div className="card shadow mb-4">
          <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
            <h6 className="m-0 font-weight-bold" style={{color: '#333399'}}> Battery Health Monitor</h6>
          </div>

          <div className="card-body">
            <PieChart
              seriesName={"Battery Healthy Status:"}
              label={chartData.map(x => x.name)}
              labelOptions={labelOptions}
              showData={chartData}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  vehicleBatteryStatus: state.dashboardReducer.vehicleBatteryStatus,
});

const mapDispatchToProps = (dispatch) => ({
  getVehicleBatteryStatus: () => dispatch(getVehicleBatteryStatus())
});

export default connect(mapStateToProps, mapDispatchToProps)(withAuth0(withTranslation()(VehicleBatteryStatusCard)));








