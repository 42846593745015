import React from "react";
import PropTypes from "prop-types";
import {Button, Dialog, DialogActions, DialogTitle, Grid,} from "@material-ui/core";

import axios from "axios";
import {config} from "../../../auth/auth";
import {grey} from "@material-ui/core/colors";
import CardContent from "@material-ui/core/CardContent";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";

const host = process.env.REACT_APP_HOST

class RegionCreateModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      discount: 0,
      regionName: "",
      category: "",
      cityUuid: "",
      status: "",
      speedLimit: "HIGH",
      open: this.props.open,
    };
    this.regionData = {};
  }

  static propTypes = {
    callback: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
  };

  cancel = () => {
    this.setState({
      discount: 0,
      open: false,
      regionName: "",
      category: "",
      cityUuid: "",
    });
    this.props.onCloseModal();
  };

  confirm = (callback) => {
    const { edit } = this.props;
    if (edit) {
      this.saveRegion(callback);
    } else {
      this.createNewRegion(callback);
    }
  };

  saveRegion = (callback) => {
    const { regionDetails } = this.props;
    const body = {
      discount: this.state.discount,
      regionName: this.state.regionName,
      category: this.state.category,
      status: this.state.status,
      speedLimit: this.state.speedLimit,
    };
    this.regionData = body;
    return axios
      .put(`${host}/region/${regionDetails.uuid}`, body, config())
      .then((response) => callback())
      .catch((e) => alert(e))
      .finally(() => {
        this.setState({
          discount: 0,
          open: false,
          regionName: "",
          category: "",
          cityUuid: "",
          speedLimit: "HIGH",
          status: "",
        });
        this.props.callback();
      });
  };

  createNewRegion = (callback) => {
    const { coordinates } = this.props;
    const body = {
      discount: this.state.discount,
      regionName: this.state.regionName,
      category: this.state.category,
      cityUuid: this.state.cityUuid,
      speedLimit: this.state.speedLimit,
      boundary: coordinates,
    };
    this.regionData = body;
    return axios
      .post(`${host}/region/`, body, config())
      .then((response) => callback())
      .catch((e) => alert(e))
      .finally(() => {
        this.setState({
          discount: 0,
          open: false,
          regionName: "",
          speedLimit: "HIGH",
          category: "",
          cityUuid: "",
        });
        this.props.callback();
      });
  };

  onChangeDiscount = (e) => {
    this.setState({ discount: parseInt(e.target.value) });
  };
  onChangeCategory = (e) => {
    this.setState({ category: e.target.value });
  };
  onChangeRegionName = (e) => {
    this.setState({ regionName: e.target.value });
  };
  onChangecityUuid = (e) => {
    this.setState({ cityUuid: e.target.value });
  };
  onChangeStatus = (e) => {
    this.setState({ status: e.target.value });
  };
  onChangeSpeedLimit = (e) => {
    this.setState({ speedLimit: e.target.value });
  };
  // end

  componentDidUpdate(prevProps) {
    if (this.props?.open !== prevProps.open) {
      if (this.props.edit) {
        if (this.props.regionDetails) {
          this.setState({
            discount: this.props.regionDetails?.discount,
            regionName: this.props.regionDetails?.regionName,
            category: this.props.regionDetails?.category,
            status: this.props.regionDetails?.status,
            speedLimit: this.props.regionDetails?.speedLimit,
          });
        }
      }
      this.setState({ open: this.props?.open });
    }
  }
  render() {
    const { callback, coordinates, cities, edit } = this.props;
    if (!coordinates && coordinates.length == 0) return <React.Fragment />;
    return (
      <React.Fragment>
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="User Action Modal"
          fullWidth={true}
        >
          <DialogTitle
            id="User Action Modal"
            style={{
              color: "white",
              backgroundColor: "#333399",
              textAlign: "center",
            }}
          >
            <label
              style={{
                display: !edit ? "block" : "none",
              }}
            >
              Create Region
            </label>
            <label
              style={{
                display: edit ? "block" : "none",
              }}
            >
              Edit Region
            </label>
          </DialogTitle>
          <Grid container spacing={2}>
            <Grid item sm={12} xs={12} md={12} lg={12}>
              <CardContent style={{ paddingBottom: "0px" }}>
                <Grid container spacing={2} className="formLabel">
                  <Grid item sm={12} xs={12} md={12} lg={12}>
                    <TextField
                      id="region-id"
                      label="Region Name"
                      fullWidth
                      value={this.state.regionName}
                      onChange={this.onChangeRegionName}
                      InputProps={{
                        readOnly: false,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={2}
                  className="formLabel"
                  style={{
                    display: !edit ? "block" : "none",
                  }}
                >
                  <Grid item sm={12} xs={12} md={12} lg={12}>
                    <TextField
                      id="cityUuid"
                      select
                      label="City"
                      value={this.state.cityUuid}
                      fullWidth
                      onChange={this.onChangecityUuid}
                      InputProps={{
                        readOnly: false,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                    >
                      {cities?.content.map((option) => (
                        <MenuItem key={option.uuid} value={option.uuid}>
                          {option.cityName}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                </Grid>
                <Grid container spacing={2} className="formLabel">
                  <Grid item sm={12} xs={12} md={12} lg={12}>
                    <TextField
                      id="category"
                      select
                      label="Category"
                      value={this.state.category}
                      onChange={this.onChangeCategory}
                      InputProps={{
                        readOnly: false,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      fullWidth
                    >
                      <MenuItem key="OPERATING" value="OPERATING">
                        OPERATION REGION
                      </MenuItem>
                      <MenuItem key="NO_PARKING" value="NO_PARKING">
                        NO PARKING
                      </MenuItem>
                      <MenuItem key="FORBIDDEN" value="FORBIDDEN">
                        FORBIDDEN
                      </MenuItem>
                      <MenuItem key="LOW_SPEED" value="LOW_SPEED">
                        LOW SPEED
                      </MenuItem>
                    </TextField>
                  </Grid>
                </Grid>
                <Grid container spacing={2} className="formLabel">
                  <Grid item sm={12} xs={12} md={12} lg={12}>
                    <TextField
                      id="speedLimit"
                      select
                      label="Speed Limit"
                      value={this.state.speedLimit}
                      onChange={this.onChangeSpeedLimit}
                      InputProps={{
                        readOnly: false,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      fullWidth
                    >
                      <MenuItem key="LOW" value="LOW">
                        Low Speed
                      </MenuItem>
                      <MenuItem key="MEDIUM" value="MEDIUM">
                        Medium
                      </MenuItem>
                      <MenuItem key="HIGH" value="HIGH">
                        High
                      </MenuItem>
                    </TextField>
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={2}
                  className="formLabel"
                  style={{
                    display: edit ? "block" : "none",
                  }}
                >
                  <Grid item sm={12} xs={12} md={12} lg={12}>
                    <TextField
                      id="status"
                      select
                      label="Status"
                      value={this.state.status}
                      onChange={this.onChangeStatus}
                      InputProps={{
                        readOnly: false,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      fullWidth
                    >
                      <MenuItem key="ACTIVE" value="ACTIVE">
                        ACTIVE
                      </MenuItem>
                      <MenuItem key="INACTIVE" value="INACTIVE">
                        INACTIVE
                      </MenuItem>
                    </TextField>
                  </Grid>
                </Grid>
                {/*<Grid container spacing={2} className="formLabel">
                  <Grid item sm={12} xs={12} md={12} lg={12}>
                    <TextField
                      id="discount-id"
                      label="Discount"
                      fullWidth
                      type="number"
                      value={this.state.discount}
                      onChange={this.onChangeDiscount}
                      InputProps={{
                        readOnly: false,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                    />
                  </Grid>
                    </Grid> */}
              </CardContent>
            </Grid>
          </Grid>

          <DialogActions
            style={{
              justifyContent: "space-between",
              padding: "10px 40px 15px 40px",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={() => this.confirm(callback)}
            >
              Save
            </Button>
            <Button onClick={this.cancel} style={{ color: grey[500] }}>
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}
export default RegionCreateModal;
