import React, {Component} from "react";
import {connect} from "react-redux";
import {withAuth0} from "@auth0/auth0-react";
import {
  getBusinessByUuid,
  getOrderMetrics,
  setBusiness,
  updateBusiness
} from "../../../../redux/business/business.action";
import {Button, CircularProgress, Grid, InputAdornment, Tooltip} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import Avatar from "@material-ui/core/Avatar";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import moment from "moment";
import TextField from "@material-ui/core/TextField";
import Form from "react-bootstrap/Form";
import OrderMetrics from "./business-detail-order-metrics";
import TransactionList from "../../../OperationCenter/TransactionInformation/TransactionList/TransactionList";
import UserList from "../../../OperationCenter/UserInformation/UserList";
import BusinessTopupIcon from "./business-detail-topup";
import BusinessStatusIcon from "./business-detail-status";
import DiscountSlider from "../../../../components/common/DiscountSlider";
import Loading from "../../../../components/common/Loading";

const dateFormat = process.env.REACT_APP_DATE_FORMAT

class BusinessDetail extends Component {
  constructor(props) {
    super(props);
    this.isDirty = false;
    this.state = {
      loading: false
    }
  }

  uuid = () => {
    return this.props.location.pathname.replace("/business/", "");
  }

  componentDidMount() {
    this.refresh();
    this.props.getOrderMetrics(this.uuid());
  }

  refresh = () => {
    this.props.getBusinessByUuid(this.uuid());
    this.isDirty = false;
  };

  onChangeDetails = (fieldName, value) => {
    let updated = Object.assign({}, this.props.business, {[fieldName]: value});
    this.props.setBusiness(updated);
    this.isDirty = true;
  }

  onClickSaveBtn = () => {
    this.setState({loading: true});
    this.props.updateBusiness(this.props.business)
      .then(() => {
        this.refresh();
      }).catch((e) => {
        console.error(e);
      })
      .finally(() => {
        this.setState({loading: false});
      });
  }

  render() {
    const {business} = this.props;

    if (!business)
      return <React.Fragment> Loading... </React.Fragment>

    const startCollaborationFrom = moment(business.createdAt).format(dateFormat);

    return (
      <React.Fragment>
        <Grid container spacing={2}>
          <Grid item md={12} lg={8}>
            <Card>
              <CardHeader
                avatar={
                  <Avatar
                    aria-label="Business"
                    style={{ width: 80, height: 80 }}
                    alt="Business Logo"
                    src={business?.logo}
                  >
                    {business?.businessName?.charAt(0) ?? "B"}
                  </Avatar>
                }
                titleTypographyProps={{ variant: "h5" }}
                title={business?.businessName}
                subheader={`Start Collaboration From: ${startCollaborationFrom}`}
              />

              <CardActions disableSpacing>
                <BusinessTopupIcon businessUuid={business?.uuid} refresh={this.refresh}/>
                <BusinessStatusIcon businessUuid={business?.uuid} businessStatus={business?.status} refresh={this.refresh}/>
              </CardActions>
            </Card>
          </Grid>
          <Grid item md={12} lg={4}>
            <Card style={{ height: "100%" }}>
              <CardContent>
                <Row>
                  <Col xs={12} md={4} className="padding10">
                    <div className="text-center">
                      <h4>Total Users</h4>
                    </div>
                    <div className="text-center">
                      <h3 className="primary">
                        {business?.countOfUser}
                      </h3>
                    </div>
                  </Col>
                  <Col xs={12} md={4} className="padding10">
                    <div className="text-center">
                      <h4>Total Orders</h4>
                    </div>
                    <div className="text-center">
                      <h3 className="primary">
                        {business?.countOfOrder}
                      </h3>
                    </div>
                  </Col>
                  <Col xs={12} md={4} className="padding10">
                    <div className="text-center">
                      <h4>Balance</h4>
                    </div>
                    <div className="text-center">
                      <h3 className="primary">
                        {business?.balance}
                      </h3>
                    </div>
                  </Col>
                </Row>
              </CardContent>
              <CardActions disableSpacing></CardActions>
            </Card>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item md={12} lg={8}>
            <Card className="height100">
              <CardHeader title="Details" className="primary" />
              <CardContent>
                <Row>
                  <Col xs={12} md={6} className="padding10">
                    <TextField
                      id="business-detail-name-id"
                      label="Name"
                      value={business?.businessName}
                      fullWidth
                      InputProps={{
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      onChange={(e) => {
                        this.onChangeDetails("businessName", e.target.value)
                      }}
                    />
                  </Col>
                  <Col xs={12} md={6} className="padding10">
                    <Tooltip title={`${business?.businessAddress}`}>
                      <TextField
                        id="business-detail-Address-id"
                        label="Address"
                        value={business?.businessAddress}
                        fullWidth
                        InputProps={{
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        variant="outlined"
                        onChange={(e) => {
                          this.onChangeDetails("businessAddress", e.target.value)
                        }}
                      />
                    </Tooltip>

                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={6} className="padding10">
                    <TextField
                      id="business-detail-Contact-id"
                      label="Contact"
                      value={business?.businessContact}
                      fullWidth
                      InputProps={{
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      onChange={(e) => {
                        this.onChangeDetails("businessContact", e.target.value)
                      }}
                    />
                  </Col>
                  <Col xs={12} md={6} className="padding10">
                    <TextField
                      id="business-detail-verificationMethod-id"
                      label="Verification Method"
                      value={business?.verificationMethod}
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      disabled
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={6} className="padding10">
                    <TextField
                      id="business-detail-verificationCriteria-id"
                      label="Verification Criteria"
                      value={business?.verificationCriteria}
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      disabled
                    />
                  </Col>
                  <Col xs={12} md={6} className="padding10">
                    <TextField
                      id="business-detail-balance-id"
                      label="Balance"
                      value={business?.balance}
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      disabled
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={6} className="padding10">
                    <TextField
                      id="business-detail-status-id"
                      label="Status"
                      value={business?.status}
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      disabled
                    />
                  </Col>
                  <Col xs={12} md={6} className="padding10">
                    <Form>
                      <Form.Check
                        name="isPaymentBind"
                        label="Payment Binded"
                        id="paymentBind"
                        className="paymentBind"
                        feedbackTooltip
                        checked={business?.isPaymentBind}
                        onChange={() => {}}
                        disabled
                      />
                    </Form>
                  </Col>
                </Row>
               {/* <Row>
                  <Col xs={12} md={6} className="padding10">
                    <DiscountSlider
                      value={business?.discount && parseInt(business?.discount * 100)}
                      onChange={(e, newValue) => {
                        this.onChangeDetails("discount", newValue/100)
                      }}/>
                  </Col>
                    </Row> */}
                <Row style={{ justifyContent: "flex-end", marginRight: "auto" }}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => this.onClickSaveBtn()}
                    disabled={this.state?.loading || !this.isDirty}
                  >
                    {this.state.loading ? <CircularProgress style={{width:22, height:22}} className="white"/> : 'Save'}
                  </Button>
                </Row>
              </CardContent>
            </Card>
          </Grid>
          <Grid item md={12} lg={4}>
            <Card className="height100">
              <CardHeader title="Order Metrics" className="primary" />
              <CardContent>
                <OrderMetrics key={`business-details-order-metrics-${business?.uuid}`} business={business}/>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item lg={12}>
            <UserList
              key={`business-details-user-list-${business?.uuid}`}
              filters={[{key: "businessUuid", value: `${business?.uuid}`}]}/>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item lg={12}>
            <TransactionList
              key={`business-details-transaction-list-${business?.uuid}`}
              filters={[{ key: "businessUuid", value: `${business?.uuid}` }]}/>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  business: state.businessReducer.business,
});

const mapDispatchToProps = (dispatch) => ({
  getBusinessByUuid: (uuid) => dispatch(getBusinessByUuid(uuid)),
  getOrderMetrics: (uuid) => dispatch(getOrderMetrics(uuid)),
  setBusiness: (payload) => dispatch(setBusiness(payload)),
  updateBusiness: (payload) => dispatch(updateBusiness(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withAuth0(BusinessDetail));
