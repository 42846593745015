export const DashboardActionTypes = {
  SET_DAILY_ORDERS: "SET_DAILY_ORDERS",
  SET_REVENUE_CURRENT_MONTH: "SET_REVENUE_CURRENT_MONTH",
  SET_DAILY_TASKS: "SET_DAILY_TASKS",
  SET_DAILY_OPERATIONAL_VEHICLES: "SET_DAILY_OPERATIONAL_VEHICLES",
  SET_REVENUE: "SET_REVENUE",
  SET_VEHICLE_STATUS: "SET_VEHICLE_STATUS",
  SET_VEHICLE_BATTERY_STATUS: "SET_VEHICLE_BATTERY_STATUS",
  SET_ORDER: "SET_ORDER",
  SET_NEW_USER_REGISTRATION: "SET_NEW_USER_REGISTRATION",
  SET_MIGRATION_PROGRESS: "SET_MIGRATION_PROGRESS",
  SET_USER_SATISFACTION: "SET_USER_SATISFACTION",
  SET_TOPUP: "SET_TOPUP"
};










