import React from "react";
import PropTypes from 'prop-types';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Tooltip
} from "@material-ui/core";
import {grey} from "@material-ui/core/colors";
import TextField from "@material-ui/core/TextField";
import {Autocomplete} from "@material-ui/lab";
import {connect} from "react-redux";
import {withAuth0} from "@auth0/auth0-react";
import {addPageToRole, getUnassignedPages, setPages, setUnassignedPages} from "../../../../redux/role/role.action";
import PostAddIcon from '@material-ui/icons/PostAdd';
import {PAGES} from "./pages";


class PageSelector extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      open: false,
      selectedPage: undefined
    };
  }

  static propTypes = {
    roleUuid: PropTypes.string.isRequired,
    roleName: PropTypes.string.isRequired,
    callback: PropTypes.func.isRequired
  };

  handleClickOpen = () => {
    this.setState({open: true});
  };

  onClickCancelBtn = () => {
    this.setState({open: false});
    if (this.props.unassignedPages) {
      this.props.setUnassignedPages(undefined);
    }
  };

  onClickAssignBtn = () => {
    this.setState({loading: true});
    this.props.addPageToRole(this.props.roleUuid, this.state.selectedPage)
    .then(() => {
      this.setState({open: false, isLoading: false, selectedPage: undefined});
      if (this.props.unassignedPages)
        this.props.setUnassignedPages(undefined);
      this.props.callback();
    }).catch((e) => {
      console.error(e);
    }).finally(() => {
      this.setState({loading: false});
    });
  };

  isDisabled = () => {
    return this.state.isLoading || !this.state.selectedPage;
  }

  onChangePage = (event, value) => {
    this.setState({selectedPage: value});
  }

  render() {
    const {roleUuid, roleName, callback, getUnassignedPages, unassignedPages} = this.props;
    if (!roleName)
      return (<React.Fragment/>);

    if (!unassignedPages && this.state.open) {
      getUnassignedPages(roleUuid);
    }

    return (
      <React.Fragment>
        <Tooltip title='Add Page'>
          <IconButton onClick={this.handleClickOpen}>
            <PostAddIcon/>
          </IconButton>
        </Tooltip>
        <Dialog
          maxWidth={"sm"}
          fullWidth={true}
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="assign-role-2-page-dialog-title-id">{`Assign ${roleName} role to the page`}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Select page you want to assign this role to.
            </DialogContentText>
            <DialogContentText>
              <Autocomplete
                id="assign-role-2-page-dropdown-id"
                style={{ width: "100%" }}
                options={unassignedPages ?? []}
                autoHighlight
                value={this.state.selectedPage}
                disabled={this.state.loading ?? false}
                getOptionLabel={(option) => `${PAGES[option]}`}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Page"
                    variant="outlined"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {this.state.loading ? <CircularProgress color="inherit" size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
                onChange={this.onChangePage}
              />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.onClickCancelBtn} style={{ color: grey[500] }}>
              Cancel
            </Button>
            <Button
              disabled={this.isDisabled()}
              onClick={this.onClickAssignBtn}
              style={{color: this.isDisabled() ? "grey": "white", backgroundColor: this.isDisabled() ? "": "#36b9cc"}}
            >
              Assign
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({ unassignedPages: state.roleReducer.unassignedPages });

const mapDispatchToProps = (dispatch) => ({
  getUnassignedPages: (params) => dispatch(getUnassignedPages(params)),
  setPages: (params) => dispatch(setPages(params)),
  setUnassignedPages: (params) => dispatch(setUnassignedPages(params)),
  addPageToRole: (roleUuid, page) => dispatch(addPageToRole(roleUuid, page)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withAuth0(PageSelector));