import React from "react";
import PropTypes from 'prop-types';
import {CircularProgress, IconButton, Switch, Tooltip} from "@material-ui/core";

import axios from "axios";
import {config} from "../../../auth/auth";
import {withStyles} from '@material-ui/core/styles';
import {grey} from '@material-ui/core/colors';

const host = process.env.REACT_APP_HOST

const PurpleSwitch = withStyles({
  switchBase: {
    color: grey[300],
    '&$checked': {
      color: "#36b9cc",
    },
    '&$checked + $track': {
      backgroundColor: "#36b9cc",
    },
  },
  checked: {},
  track: {},
})(Switch);

class Status extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false
    };
  }

  static propTypes = {
    status: PropTypes.string.isRequired,
    uuid: PropTypes.string.isRequired,
    callback: PropTypes.func.isRequired
  };

  handleChange = (e, status, uuid, callback) => {
    const operation = e.target.checked ? 'active' : 'block';
    this.setState({isLoading: true})
    return axios.put(`${host}/messageTemplate/${uuid}/${operation}`,null, config())
      .then(r => {})
      .catch(e => alert(e))
      .finally(() => {
        this.setState({isLoading: false});
        callback();
      });
  }


  render() {
    const { status, uuid, callback} = this.props;
    if (!status)
      return (<React.Fragment/>);

    if (this.state.isLoading) {
      return (<Tooltip title={status}>
        <IconButton>
          <CircularProgress style={{width:20, height:20}}/>
        </IconButton>
      </Tooltip>);
    }

    return (
      <Tooltip title={status}>
        <PurpleSwitch
          checked={status === 'ACTIVE'}
          onClick={(e) => {this.handleChange(e, status, uuid, callback)}}
          name="Status"
        />
      </Tooltip>
    );
  }
}

export default Status;