import React, {Component} from 'react';
import {search} from "../../../../redux/topUpTemplate/topUpTemplate.action";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {withAuth0} from "@auth0/auth0-react";
import "./topUpTemplate.css";
import TopUpTemplateGroup from "./TopUpTemplateGroup";
import {groupByKey} from "../../../../utils/utils";

class TopUpTemplate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false
    }
  }

  componentDidMount() {
    this.refresh();
  }

  refresh = () => {
    if (this.state.isLoading) {
      return;
    }
    this.setState({isLoading: true});
    this.props.search({"size": 1000})
      .finally(() => this.setState({isLoading: false}));
  };

  render() {
    if (!this.props.topUpTemplates) {
      this.refresh();
      return <React.Fragment/>
    }

    const groupByCityName = groupByKey(this.props.topUpTemplates?.content, "cityUuid") ?? {};
    const topUpTemplates = Object.values(groupByCityName).map(list => list.sort((a, b) => a.topUpAmount - b.topUpAmount));

    return (
      
      <div key="topup-template-key-1">
        <div>
          <h1 className="h3 mb-2 text-gray-800">TopUp Template</h1>
          <p className="mb-4">
            In each field, edit the amount of each option where the user can select from on their device
            when topping up their balance Wallet page.
          </p>
        </div>

        {topUpTemplates?.map(t => (
          <TopUpTemplateGroup topUpTemplates={t} postSaveBtn={this.refresh} />
        ))}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  topUpTemplates: state.topUpTemplateReducer.topUpTemplates,
});

const mapDispatchToProps = (dispatch) => ({
  search: (params) => dispatch(search(params)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withAuth0(TopUpTemplate)));
