import React, {useEffect, useState} from 'react';
import {LayerGroup, LayersControl, MapContainer,TileLayer, useMapEvents,} from "react-leaflet";
import {connect} from "react-redux";
import {withAuth0} from "@auth0/auth0-react";
import {getPlaces} from "../../../../redux/places/action";
import PoiMarker from "../../../../components/Map/PointOfInterestIMap/POIMarker";

import PoiCreate from "../../../../components/Map/PointOfInterestIMap/PointOfInterestCreate/POICreate";

const {BaseLayer, Overlay} = LayersControl;

const MyMapComponent = ({places}) => {
  const [openModal, setOpenModal] = useState(false);
  const [location, setLocation] = useState({
    lat: "",
    lng: ""
  });

  const map = useMapEvents({
    click(e) {
      setLocation({
        lat: e.latlng["lat"],
        lng: e.latlng["lng"]
      });
      setOpenModal(true);
    }
  })

  return (
    <Overlay checked>
      <LayerGroup>
        {
          places?.map(place => <PoiMarker key={place.uuid} pointOfInterest={place}/>
          )}
      </LayerGroup>
      <PoiCreate openModal={openModal} setOpenModal={setOpenModal} location={location} setLocation={setLocation}/>
    </Overlay>
  )
}
const PointOfInterestConfigurationMap = ({getPlaces, places}) => {
  const [cityCoordinates, setCityCoordinates] = useState({
    lat: 22.402795,
    lng: 39.078354,
  });

  useEffect(() => {
    getPlaces();
  }, []);

  return (
    <div className="col-xl col-lg">
      <div className="card shadow mb-4">
        <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
          <h6 className="m-0 font-weight-bold" style={{color: '#333399'}}>Point of Interest Map</h6>
        </div>

        <div className="card-body">
          <MapContainer
            center={cityCoordinates}
            zoom={13}
            scrollWheelZoom={true}
            doubleClickZoom={false}
            style={{height: "90vh"}}
          >
            <LayersControl position="topright">
              <BaseLayer checked name="Black And White">
                <TileLayer
                  attribution='Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> Imagery © <a href="https://www.mapbox.com/">Mapbox</a>'
                  url="https://api.mapbox.com/styles/v1/mapbox/dark-v10/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoidHJhY2RldmVsb3BlciIsImEiOiJja2szOGJteHMxOGxhMm9wYzFhbmgwazkyIn0._UOy-teYfb2kzsEMdr8YkA"
                />
              </BaseLayer>
              <BaseLayer name="Normal Map">
                <TileLayer
                  attribution='Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> Imagery © <a href="https://www.mapbox.com/">Mapbox</a>'
                  url="https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoidHJhY2RldmVsb3BlciIsImEiOiJja2szOGJteHMxOGxhMm9wYzFhbmgwazkyIn0._UOy-teYfb2kzsEMdr8YkA"
                />
              </BaseLayer>
              <BaseLayer name="Google Map">
                <TileLayer
                  attribution='Map data &copy; <a href="https://www.google.com/maps">Google Map</a> Imagery © <a href="https://www.google.com/">Google</a>'
                  url="http://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}"
                  subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
                />
              </BaseLayer>
              <MyMapComponent places={places?.content}/>
            </LayersControl>
          </MapContainer>
        </div>
      </div>
    </div>

  );
};

const mapStateToProps = (state) => ({
  places: state.placesReducer.places,
});

const mapDispatchToProps = (dispatch) => ({
  getPlaces: () => dispatch(getPlaces()),
});


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withAuth0(PointOfInterestConfigurationMap));