import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import PropTypes from "prop-types";
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import axios from "axios";
import {config, getAccessToken} from "../../../auth/auth";
import Loading from "../../../components/common/Loading";

const host = process.env.REACT_APP_HOST

class ExecuteButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
    };
  }

  static propTypes = {
    value: PropTypes.object,
    tableMeta: PropTypes.object.isRequired,
    callback: PropTypes.func.isRequired
  };


  handleClick = () => {
    if (this.state.isLoading)
      return;
    const uuid = this.props.tableMeta?.rowData[0];

    this.setState({isLoading: true})

    return axios.post(`${host}/userMessageTask/${uuid}/start`,null, config())
      .then((response) => {
        this.props.callback();
      }).catch(e => {
        console.error("Failed to trigger notification task", e);
        alert(e?.response?.data?.message ?? e);
      }).finally(() => {
        this.setState({isLoading: false});
      });
  }

  render() {
    const {value, tableMeta, callback} = this.props;

    const status = tableMeta?.rowData[8];
    const isDisabled = status !== 'WAITING';
    const title = isDisabled ? `Can't trigger ${status} task` : 'Trigger task'

    if (this.state.isLoading) {
      return (<Loading />)
    }

    return (
      <React.Fragment>
        <Tooltip title={title}>
          <span>
            <IconButton onClick={this.handleClick} disabled={isDisabled}>
              <PlayArrowIcon/>
            </IconButton>
          </span>
        </Tooltip>
      </React.Fragment>
    );
  }

}

export default ExecuteButton;
