import React from "react";
import PropTypes from "prop-types";
import {Skeleton} from "@material-ui/lab";
import IconButton from "@material-ui/core/IconButton";
import PaymentIcon from "@material-ui/icons/Payment";
import {Tooltip} from "@material-ui/core";
import AmountInputPopup from "../../../../components/PopUp/amount-input-popup";
import Loading from "../../../../components/common/Loading";
import {topup} from "../../../../redux/business/business.action";


class BusinessTopupIcon extends React.Component {

  static propTypes = {
    businessUuid: PropTypes.string.isRequired,
    refresh: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      loading:false,
      open: false
    };
  }

  onClickTopupBtn = () => {
    this.setState({open: true});
  }

  onConfirmTopup = (amount) => {
    if (amount === null || amount === undefined) {
      alert("Amount must not be empty.");
    }
    if (amount <= 0) {
      alert("Amount must be greater than 0.");
    }
    this.setState({
      open: false,
      loading: true
    });

    // send TOPUP request
    topup(this.props.businessUuid, amount)
      .then(() => {
        this.props.refresh();
      })
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        setTimeout(() => {this.setState({loading: false});}, 500);
      });
  }

  onCloseTopup = () => {
    this.setState({open: false});
  }

  render() {
    const {businessUuid} = this.props;

    if (!businessUuid)
      return (<Skeleton variant="circle" width={30} height={30} />);

    if (this.state.loading)
      return (<Loading />);

    return (
      <React.Fragment>
        <Tooltip title="TOPUP">
          <IconButton onClick={this.onClickTopupBtn}>
            <PaymentIcon />
          </IconButton>
        </Tooltip>

        <AmountInputPopup
          open={this.state.open}
          subject={"Top Up"}
          callback={this.onConfirmTopup}
          close={this.onCloseTopup}
        />
      </React.Fragment>
    );
  }
}

export default BusinessTopupIcon;