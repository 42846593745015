import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import {withStyles} from "@material-ui/core/styles";
import {green, red, grey} from "@material-ui/core/colors/";
import Modal from "react-bootstrap/Modal";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import {connect} from "react-redux";
import {withAuth0} from "@auth0/auth0-react";
import {
    Button,
} from "@material-ui/core";
import BuildIcon from '@material-ui/icons/Build';
import {faPercent} from '@fortawesome/free-solid-svg-icons'
import {faDrawPolygon} from '@fortawesome/free-solid-svg-icons'
import {faCity} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {search} from "../../../../redux/city/action";
import {regionSearch} from "../../../../redux/region/action";
import {updateVehicle} from "../../../../redux/vehicle/vehicle.action";
import DiscountSlider from "../../../../components/common/DiscountSlider";
import Alert from '@material-ui/lab/Alert';

const defaultToolbarSelectStyles = {
    iconButton: {},
    iconContainer: {
        marginRight: "24px"
    },
    inverseIcon: {
        transform: "rotate(90deg)"
    }
};

class CustomToolbarSelect extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            showErrorModal: false,
            status: undefined,
            open: false,
            discount: 0,
            regionUuid: undefined,
            cityUuid: undefined,
        };
        this.selectedField = undefined;
        this.title = undefined;
        this.citiesUpdateError = [];
        this.selectedCityName = undefined;
        this.citiesError = false;
    }
    componentDidMount() {
        this.refresh();
    }
    refresh = () => {
        let params = new URLSearchParams();
        params.set("page", "0");
        params.set("size", "1000");
        this.props.search(params);
        this.props.regionSearch(params);
    };
 
    // Close Modal
    onCloseModal = () => {
        this.setState({showModal: false})
    }
     // Close Modal
     onCloseCitiesErrorModal = () => {
        this.setState({showErrorModal: false})
        const {callback} = this.props;
        callback();
        
    }
    // Confirm
    onConfirm = () => {
        const {vehicles, selectedRows} = this.props;
        if(this.selectedField === 'cityUuid') {
            this.citiesUpdateError = []
        }    
        const vehiclesData = vehicles ?. content;
        selectedRows.data.map((row, index) => {
            const rowData = vehiclesData[row.index];
            this.onClickSaveBtn(rowData, index);

        })
    }

    onClickSaveBtn = (rowData, index) => {
        const {selectedRows,cities} = this.props;
        const count = selectedRows.data.length - 1;
        let doCallBack = false;
        if(index === count) {
            doCallBack = true;
        }
        this.setState({loading: true});
        rowData[this.selectedField] = this.selectedValue;
        if(this.selectedField === 'cityUuid') {
            const cityObj = cities?.content.find((element) => element.uuid === this.selectedValue);
            this.selectedCityName = cityObj?.cityName
            const vehiclesSupported = cityObj?.vehiclesSupported;
            const vehicleCategory = rowData['category'];
            const index = vehiclesSupported.indexOf(vehicleCategory);
            if(index >= 0) {
                this.updateVehicle(rowData, doCallBack);
            } else {
                const i = this.citiesUpdateError.indexOf(vehicleCategory) > -1;
                if(!i) {
                   this.citiesUpdateError.push(vehicleCategory);
                }
                this.citiesError = true;
                this.setState({showModal: false})
                this.setState({loading: false})
                this.setState({showErrorModal: true})
            }
        } else {
           this.updateVehicle(rowData, doCallBack);
        }
     
    }

    updateVehicle = (rowData, doCallBack) => {
        const {callback} = this.props;
        this.props.updateVehicle(rowData).then(() => {
           if(doCallBack) {
               if(this.citiesError) {
                this.setState({showModal: false})
                this.setState({loading: false})
                } else {
                    callback();
                    this.setState({showModal: false})
                    this.setState({loading: false})
                }
              
                
           }
        }).catch((e) => {
            console.error(e);
        }). finally(() => {});
    }


    onChangeStatus = (e) => {
        this.selectedField = 'status';
        this.setState({status: e.target.value});
        this.selectedValue = e.target.value;
    };

    onChangeCity = (e) => {
        this.selectedField = 'cityUuid';
        this.setState({cityUuid: e.target.value});
        this.selectedValue = e.target.value;
    };

    onChangeRegion = (e) => {
        this.selectedField = 'regionUuid';
        this.setState({regionUuid: e.target.value});
        this.selectedValue = e.target.value;
    };

    onChangeDiscount = (fieldName, value) => {
        this.selectedField = 'discount';
        this.setState({discount: value});
        this.selectedValue = value;
    };

    onClickStatus = (e) => {
      this.selectedField = 'status';
      this.title = "Status";
      this.setState({showModal: true})
    };

    onClickCity = (e) => {
        this.selectedField = 'cityUuid';
        this.title = "City";
        this.setState({showModal: true});
    };

    onclickRegion = (e) => {
        this.selectedField = 'regionUuid';
        this.title = "Region";
        this.setState({showModal: true});
    };

    onClickDiscount = (fieldName, value) => {
        this.selectedField = 'discount';
        this.title = "Discount";
        this.setState({showModal: true});
        this.selectedValue = 0;
    };

    // Close Confirmation Modal
    closeConfirmModal = () => {
        this.setState({open: false})
    }

    render() {
        const {classes, regions, cities} = this.props;

        return (
            <div className={
                classes.iconContainer
            }>
                <Tooltip title={"Change Status"}>
                    <IconButton className={
                            classes.iconButton
                        }
                        onClick={
                            this.onClickStatus
                    }>
                        <BuildIcon className={
                            classes.icon
                        }/>
                    </IconButton>
                </Tooltip>
                <Tooltip title={"Change City"}>
                    <IconButton className={
                            classes.iconButton
                        }
                        onClick={
                            this.onClickCity
                    }>
                        <FontAwesomeIcon icon={faCity}/>
                    </IconButton>
                </Tooltip>
                <Tooltip title={"Change Region"}>
                    <IconButton className={
                            classes.iconButton
                        }
                        onClick={
                            this.onclickRegion
                    }>
                        <FontAwesomeIcon icon={faDrawPolygon}/>
                    </IconButton>
                </Tooltip>
                <Tooltip title={"Change Discount"}>
                    <IconButton className={
                            classes.iconButton
                        }
                        onClick={
                            this.onClickDiscount
                    }>
                        <FontAwesomeIcon icon={faPercent}/>
                    </IconButton>
                </Tooltip>

                <Modal show={
                        this.state.showModal
                    }
                    onHide={
                        () => this.onCloseModal()
                    }
                    animation={false}>
                    <Modal.Header closeButton>
                        <Modal.Title>Change {this.title}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={
                        {padding: "0px"}
                    }>

                        {/* status */}
                        <div style={{padding: "15px", display: this.selectedField === 'status' ? "block" : "none"}}>
                            <TextField id="status" label="Status"
                                value={this.state.status}
                                select
                                onChange={this.onChangeStatus}
                                fullWidth
                                InputLabelProps={{shrink: true}}
                                variant="outlined">
                                <MenuItem key="AVAILABLE" value="AVAILABLE">
                                    Available
                                </MenuItem>
                                <MenuItem key="RUNNING" value="RUNNING">
                                    Running
                                </MenuItem>
                                <MenuItem key="RESERVED" value="RESERVED">
                                    Reserved
                                </MenuItem>
                                <MenuItem key="REPORTED" value="REPORTED">
                                    Reported
                                </MenuItem>
                                <MenuItem key="MAINTAINING" value="MAINTAINING">
                                    Maintaining
                                </MenuItem>
                                <MenuItem key="MAINTENANCE_PENDING" value="MAINTENANCE_PENDING">
                                Maintenance Pending
                                </MenuItem>
                                <MenuItem key="DAMAGED" value="DAMAGED">
                                    Damaged
                                </MenuItem>
                                <MenuItem key="LOST" value="LOST">
                                    Lost
                                </MenuItem>
                            </TextField>
                        </div>

                        {/* Discount Slider */}
                        <div style={{ margin: "30px", display: this.selectedField === 'discount' ? "block" : "none"}}>
                          <DiscountSlider
                            value={this.state.discount && parseInt(this.state.discount * 100)}
                            onChange={(e, newValue) => {
                              this.onChangeDiscount("discount", newValue/100)
                            }}/>
                         </div>
                        
                        {/* change Region */}
                        <div style={{ padding: "15px", display: this.selectedField === 'regionUuid' ? "block" : "none"}}>
                          <TextField
                                id="region"
                                select
                                label="Region"
                                value={this.state.regionUuid}
                                onChange={this.onChangeRegion}
                                fullWidth
                                InputProps={{
                                  readOnly: false,
                                }}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                variant="outlined"
                                style={{ marginTop: "15px" }}
                              >
                                {regions?.content.map((option) => (
                                  <MenuItem key={option.uuid} value={option.uuid}>
                                    {option.regionName}                                   
                                  </MenuItem>
                                ))}
                          </TextField>
                      </div>

                        {/* change city */}
                        <div style={{ padding: "15px", display: this.selectedField === 'cityUuid' ? "block" : "none"}}>
                          <TextField
                                id="city"
                                select
                                label="City"
                                value={this.state.cityUuid}
                                onChange={this.onChangeCity}
                                fullWidth
                                InputProps={{
                                  readOnly: false,
                                }}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                variant="outlined"
                                style={{ marginTop: "15px" }}
                              >
                               {cities?.content.map((option) => 
                                        <MenuItem key={option.uuid} value={option.uuid}>
                                        {option.cityName}
                                        </MenuItem>
                                    )}
                          </TextField>
                      </div>


                      

                    </Modal.Body>
                    <Modal.Footer style={{justifyContent:"space-between"}}>
                       <Button variant="contained" color="primary" size="small"
                            onClick={
                                () => this.onConfirm()
                        }>
                            Confirm
                        </Button>
                        <Button size="small" variant="secondary"
                            onClick={
                                () => this.onCloseModal()
                        }>
                            Cancel
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={
                        this.state.showErrorModal
                    }
                    onHide={
                        () => this.onCloseCitiesErrorModal()
                    }
                    animation={false}>
                            <Modal.Header closeButton>
                                <Modal.Title>City update errors</Modal.Title>
                            </Modal.Header>
                                <Modal.Body style={
                                    {padding: "0px"}
                                }>
                                {this.citiesUpdateError?.map((option) => 
                                   <Alert severity="error">{this.selectedCityName} does not support {option}</Alert>  
                                 )}
                                    
                                </Modal.Body>
                    <Modal.Footer>
                        <Button size="small" variant="secondary"
                            onClick={
                                () => this.onCloseCitiesErrorModal()
                        }>
                            ok
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({vehicles: state.vehicleData.vehicles, cities: state.cityReducer.cities, regions: state.regionReducer.regions});


const mapDispatchToProps = (dispatch) => ({
    search: (params) => dispatch(search(params)),
    updateVehicle: (payload) => dispatch(updateVehicle(payload)),
    regionSearch: (params) => dispatch(regionSearch(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withAuth0(withStyles(defaultToolbarSelectStyles, {name: "CustomToolbarSelect"})(CustomToolbarSelect)));
