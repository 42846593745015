import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import AddIcon from "@material-ui/icons/Add";
import {withStyles} from "@material-ui/core/styles";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from "prop-types";
import axios from "axios";
import {config} from "../../../../auth/auth";
import {CircularProgress, Grid} from "@material-ui/core";
import CardContent from "@material-ui/core/CardContent";
import "./details.css";
import {isEmpty} from "../../../../utils/utils";

const defaultToolbarStyles = {
  iconButton: {
  },
};

const host = process.env.REACT_APP_HOST

class Create extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      open: false,
      name: undefined,
      description: undefined
    };
  }

  static propTypes = {
    refresh: PropTypes.func.isRequired
  };

  handleClick = () => {
    this.setState({open: true});
  }

  cancel = () => {
    this.setState({open: false});
  };

  create = () => {
    const { refresh } = this.props;
    if (this.state.isLoading)
      return;
    const body = {
      name: this.state.name,
      description: this.state.description
    }
    this.setState({isLoading: true})
    return axios.post(`${host}/role/`, body, config())
      .then((response) => {
        this.setState({name: undefined, description: undefined});
      })
      .catch(e => alert(e))
      .finally(() => {
        this.setState({isLoading: false, open: false});
        refresh();
      });
  };


  onChangeName = (e) => {this.setState({name: e.target.value})};

  onChangeDescription = (e) => {this.setState({description: e.target.value})};

  isDisabled = () => {
    return this.state.isLoading || isEmpty(this.state?.name) || isEmpty(this.state?.description);
  }

  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <Tooltip title={"Create Role"}>
          <IconButton className={classes.iconButton} onClick={this.handleClick} className="primary">
            <AddIcon className={classes.deleteIcon} />
          </IconButton>
        </Tooltip>
        <Dialog open={this.state.open} onClose={this.cancel} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title" className="primary">
            <h4>Create Role</h4>
          </DialogTitle>
          <DialogContent>
            <Grid container spacing={2} className="create-container">
              <Grid item sm={12} xs={12} md={12} lg={12}>
                <CardContent>

                  <Grid container spacing={2} className="formLabel">
                    <Grid item md={12} lg={12}>
                      <TextField
                        id="role-create-name-id"
                        label="Name"
                        fullWidth
                        value={this.state.name}
                        onChange={this.onChangeName}
                        InputProps={{
                          readOnly: false,
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} className="formLabel">
                    <Grid item md={12} lg={12}>
                      <TextField
                        id="role-create-description-id"
                        label="Description"
                        fullWidth
                        value={this.state.description}
                        InputProps={{
                          readOnly: false,
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        variant="outlined"
                        onChange={this.onChangeDescription}
                      />
                    </Grid>
                  </Grid>

                </CardContent>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <React.Fragment>
              {this.state.isLoading ? <CircularProgress style={{width:20, height:20}}/> : ""}
              <Button
                disabled={this.isDisabled()}
                onClick={this.create}
                style={{color: this.isDisabled() ? "grey": "white", backgroundColor: this.isDisabled() ? "": "#36b9cc"}}
              >
                Create
              </Button>
              <Button
                onClick={this.cancel}
                color="primary"
                disabled={this.state.isLoading}
              >
                Cancel
              </Button>
            </React.Fragment>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }

}

export default withStyles(defaultToolbarStyles, { name: "Create" })(Create);
