import React, { useState, useEffect } from "react";
import moment from "moment";
import { Row, Col } from "react-bootstrap";
import { Grid, Card, CardContent, CardActions } from "@material-ui/core";
import Amount from "../../../../components/common/Amount";

export default function TimePeriod({ order }) {
  const [timePeriod, setTimePeriod] = useState(0);

  useEffect(() => {
    if (order) {
      if (order.endAt) {
        setTimePeriod(getTimePeriodValue());
        return;
      } else {
        let myInterval = setInterval(() => {
          setTimePeriod(getTimePeriodValue());
        }, 1000);
        return () => {
          clearInterval(myInterval);
        };
      }
    }
  }, [order]);

  const getTimePeriodValue = () => {
    const startDate = moment.utc(order?.startAt).local();
    const currentTime = order.endAt
      ? moment.utc(order.endAt).local()
      : moment.utc().local();
    const milliseconds = currentTime.diff(startDate);
    const seconds = Math.floor((milliseconds / 1000) % 60);
    const minutes = Math.floor((milliseconds / (1000 * 60)) % 60);
    const hours = Math.floor(milliseconds / (1000 * 60 * 60));
    const secondString = seconds < 10 ? "0" + seconds : seconds;
    const minuteString = minutes < 10 ? "0" + minutes : minutes;
    const hourString = hours < 10 ? "0" + hours : hours;
    return `${hourString}:${minuteString}:${secondString}`;
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12} lg={12}>
          <Card style={{ height: "100%" }}>
            <CardContent>
              <Row>
                <Col
                  xs={12}
                  md={order && order.cost ? 5 : 12}
                  className="userDetailsFormLabel"
                >
                  <div className="text-center">
                    {" "}
                    <h4>Time Period</h4>
                  </div>
                  <div className="text-center">
                    <h3 className="primary">{timePeriod}</h3>
                  </div>
                </Col>
                {order && order.cost && (
                  <Col xs={12} md={7} className="userDetailsFormLabel">
                    <div className="text-center">
                      {" "}
                      <h4>Actual Payment</h4>
                    </div>
                    <div className="text-center">
                      <h3 className="primary">
                        <Amount
                          value={order?.actualPayment}
                          currency={order?.currency}
                        />
                      </h3>
                    </div>
                  </Col>
                )}
              </Row>
            </CardContent>
            <CardActions disableSpacing></CardActions>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}
