import React from "react";
import PropTypes from "prop-types";
import AddIcon from "@material-ui/icons/Add";
import {Button, CircularProgress, Dialog, DialogActions, DialogTitle, Grid, IconButton} from "@material-ui/core";
import CardContent from "@material-ui/core/CardContent";
import TextField from "@material-ui/core/TextField";
import {grey} from "@material-ui/core/colors";
import {getAllCountries} from "../../../../redux/country/action";
import {connect} from "react-redux";
import {Autocomplete} from "@material-ui/lab";
import Box from "@material-ui/core/Box";
import axios from "axios";
import {config} from "../../../../auth/auth";
import {error} from "../../../../redux/alert/alert.action";

const host = process.env.REACT_APP_HOST

class CountryCreate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isoCode: undefined,
      open: false,
    };
  }

  static propTypes = {
    callback: PropTypes.func.isRequired,
  };

  onChangeIsoCode = (event, value) => {
    this.setState({ isoCode: value.isoCode });
  }

  onClickCreateBtn = () => {
    this.setState({ open: true });
  }

  onClickSaveBtn = () => {
    const body = {
      isoCode: this.state.isoCode
    };
    return axios
      .post(`${host}/country`, body, config())
      .then((response) => this.props.callback())
      .catch((e) => this.props.error(`${e?.response?.data?.error}: ${e?.response?.data?.message}`))
      .finally(() => {
        this.setState({
          isLoading: false,
          isoCode: undefined,
          open: false
        });
      });
  }

  onClickCancelBtn = () => {
    this.setState({ open: false });
  }

  render() {
    const {allCountries, getAllCountries} = this.props;

    if (this.state.open && !allCountries) {
      getAllCountries();
    }

    return (
      <React.Fragment>
        <IconButton
          variant="contained"
          color="primary"
          style={{ float: "right" }}
          onClick={this.onClickCreateBtn}
        >
          <AddIcon/>
        </IconButton>

        <Dialog
          open={this.state.open}
          onClose={this.onClickCancelBtn}
          aria-labelledby="Country Action Modal"
          fullWidth={true}
        >
          <DialogTitle
            id="Country Action Modal"
            style={{
              color: "white",
              backgroundColor: "#36b9cc",
              textAlign: "center",
            }}
          >
            <label>Create Country</label>
          </DialogTitle>
          <Grid container spacing={2}>
            <Grid item sm={12} xs={12} md={12} lg={12}>
              <CardContent style={{ paddingBottom: "0px" }}>
                <Grid container spacing={2} className="formLabel">
                  <Grid item sm={12} xs={12} md={12} lg={12}>
                    <Autocomplete
                      id="country-create-iso-select-id"
                      style={{ width: "100%" }}
                      options={allCountries ?? []}
                      autoHighlight
                      value={undefined}
                      disabled={this.state.isLoading ?? false}
                      getOptionSelected={(option, value) => option.isoCode === value}
                      getOptionLabel={(option) => `${option?.isoCode}-${option?.name}`}
                      renderOption={(option) => (
                        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }}>
                          <img
                            loading="lazy"
                            width="20"
                            src={`https://flagcdn.com/w20/${option?.isoCode?.toLowerCase()}.png`}
                            srcSet={`https://flagcdn.com/w40/${option?.isoCode?.toLowerCase()}.png 2x`}
                            alt=""
                          />
                          -{option?.isoCode}-{option?.name}
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Country"
                          variant="outlined"
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <React.Fragment>
                                {this.state.isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            ),
                          }}
                        />
                      )}
                      onChange={this.onChangeIsoCode}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Grid>
          </Grid>

          <DialogActions
            style={{
              justifyContent: "space-between",
              padding: "10px 40px 15px 40px",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={() => this.onClickSaveBtn()}
            >
              Save
            </Button>
            <Button onClick={this.onClickCancelBtn} style={{ color: grey[500] }}>
              Cancel
            </Button>
          </DialogActions>
        </Dialog>

      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  allCountries: state.countryReducer.allCountries,
});

const mapDispatchToProps = (dispatch) => ({
  getAllCountries: () => dispatch(getAllCountries()),
  error: (msg) => dispatch(error(msg))
});

export default connect(mapStateToProps, mapDispatchToProps)(CountryCreate);