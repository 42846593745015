import React, {Component} from "react";
import MUIDataTable from "mui-datatables";
import {withAuth0} from "@auth0/auth0-react";
import {Avatar, Button, Tooltip} from "@material-ui/core";
import {connect} from "react-redux";
import moment from "moment";

import { getNews } from "../../../redux/news/action";
import { search } from "../../../redux/city/action";
import {queryParams, sub} from "../../../utils/utils";
import Status from "../../../components/common/Status";
import AddPhotoAlternateIcon from '@material-ui/icons/AddPhotoAlternate';
import Create from "./Create";
import Delete from "../../../components/common/Delete";
import UploadGraph from "./UploadGraph";

const date_format = process.env.REACT_APP_DATE_FORMAT;
const datetime_format = process.env.REACT_APP_DATETIME_FORMAT;

class NewsComponent extends Component {
  constructor(props) {
    super(props);
    this.isFilterDialogOpen = false;
    this.tableRef = React.createRef();
    this.columnsDisplay = undefined;
  }

  componentDidMount() {
    this.refresh();
  }

  refresh = () => {
    const {getNews} = this.props;

    // table state
    const state = this.tableRef?.current?.state;

    const params = queryParams(this.props, state);

    getNews(params);
  }

  onChangeColumnDisplay = () => {
    const state = this.tableRef?.current?.state;
    this.columnsDisplay = state.columns.reduce((obj, item) => Object.assign(obj, {[item.name]: item.display}), {});
  }

  isDisplay = (name) => {
    if (!this.columnsDisplay || !this.columnsDisplay.hasOwnProperty(name))
      return undefined;
    else
      return this.columnsDisplay[name];
  }

  render() {
    const { news, cities } = this.props;
    if (!news)
      return (<React.Fragment>Loading...</React.Fragment>);
    
    const list = news?.content;
    const totalElements = news?.totalElements ?? 0;
    const rowsPerPage = news?.pageable?.pageSize ?? 10;
    const page = news?.pageable?.pageNumber ?? 0;

    const columns = [
      {
        name: "uuid",
        label: "UUID",
        options: {
          filter: true,
          sort: false,
          display: this.isDisplay("uuid") ?? false,
          customFilterListOptions: { render: (v) => `UUID: ${v}` },
          filterType: "textField",
        },
      },
      {
        name: "graphUrl",
        label: "Graph",
        options: {
          filter: false,
          sort: false,
          display: this.isDisplay("graphUrl") ?? true,
          customBodyRender: (value, tableMeta, updateValue) => {return (
            <Tooltip title={value ? "Graph" : "No Graph"}>
              <Avatar alt="Photo" src={value} >
                <AddPhotoAlternateIcon/>
              </Avatar>
            </Tooltip>
          );},
        },
      },
      {
        name: "createdAt",
        label: "Created",
        options: {
          filter: false,
          sort: true,
          display: this.isDisplay("createdAt") ??  false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <Tooltip title={value ? moment.utc(value).local().format(datetime_format) : ""}>
                <div>{value ? moment.utc(value).local().format(datetime_format) : ""}</div>
              </Tooltip>
            );
          },
        },
      },
      {
        name: "updatedAt",
        label: "Latest Updated",
        options: {
          filter: false,
          sort: true,
          display: this.isDisplay("updatedAt") ??  false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <Tooltip title={value ? moment.utc(value).local().format(datetime_format) : ""}>
                <div>{value ? moment.utc(value).local().format(datetime_format): ""}</div>
              </Tooltip>
            );
          },
        },
      },
      {
        name: "subject",
        label: "Subject",
        options: {
          filter: false,
          sort: false,
          display: this.isDisplay("subject") ??  true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <Tooltip title={value}>
                <div>{sub(value,25)}</div>
              </Tooltip>
            );
          },
        },
      },
      {
        name: "content",
        label: "Content",
        options: {
          filter: false,
          sort: false,
          display: this.isDisplay("content") ??  true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <Tooltip title={value}>
                <div>{sub(value,80)}</div>
              </Tooltip>
            );
          },
        },
      },
      {
        name: "category",
        label: "Category",
        options: {
          filter: true,
          sort: false,
          display: this.isDisplay("category") ??  false
        },
      },
      {
        name: "startAt",
        label: "Start Time",
        options: {
          filter: false,
          sort: true,
          display: this.isDisplay("startAt") ??  true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <Tooltip title={value ? moment.utc(value).local().format(datetime_format) : ""}>
                <div>{value ? moment.utc(value).local().format(datetime_format) : ""}</div>
              </Tooltip>
            );
          },
        },
      },
      {
        name: "endAt",
        label: "End Time",
        options: {
          filter: false,
          sort: true,
          display: this.isDisplay("endAt") ??  true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <Tooltip title={value ? moment.utc(value).local().format(datetime_format) : ""}>
                <div>{value ? moment.utc(value).local().format(datetime_format) : ""}</div>
              </Tooltip>
            );
          },
        },
      },
      {
        name: "validTime",
        label: "Valid Time",
        options: {
          filter: false,
          sort: true,
          display: this.isDisplay("validTime") ??  false,
          customBodyRender: (value, tableMeta, updateValue) => {
            const startAt = tableMeta?.rowData[7]? moment.utc(tableMeta?.rowData[7]).local().format(datetime_format) : "";
            const endAt = tableMeta?.rowData[8] ? moment.utc(tableMeta?.rowData[8]).local().format(datetime_format) : "";
            return (
              <Tooltip title="Valid Time">
                <div>{`${startAt} ~ ${endAt}`}</div>
              </Tooltip>
            );
          },
        },
      },
      {
        name: "cityName",
        label: "City",
        options: {
          filter: false,
          sort: false,
          display: this.isDisplay("cityName") ??  true
        },
      },
      {
        name: "status",
        label: "Status",
        options: {
          filter: true,
          sort: false,
          display: this.isDisplay("status") ??  true,
          customBodyRender: (value, tableMeta, updateValue) => {
            const uuid = tableMeta.rowData[0];
            return (
              <Status
                checked={value === "ACTIVE" || value === "active"}
                activeUrl={`/news/${uuid}/active`}
                inactiveUrl={`/news/${uuid}/inactive`}
                onChanged={() => this.refresh()}
              />
            );
          },
        },
      },
      {
        name: "Operations",
        options: {
          filter: false,
          sort: false,
          empty: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div>
                <Delete url={`/news/${tableMeta.rowData[0]}`} callback={() => this.refresh()}/>
                <UploadGraph url={`/news/${tableMeta.rowData[0]}/graph`} refresh={() => this.refresh()}/>
              </div>
            );
          },
        },
      },
    ];

    const options = {
      filter: true,
      search: false,
      confirmFilters: true,
      serverSide: true,
      filterType: "dropdown",
      responsive: "vertical",
      rowsPerPageOptions: [10, 20, 50, 100],
      rowsPerPage: rowsPerPage,
      count: totalElements,
      page: page,
      onTableChange: (action, tableState) => {
        switch (action) {
          case "viewColumnsChange":
            this.onChangeColumnDisplay();
            break;
          case "onFilterDialogOpen":
            this.isFilterDialogOpen = true;
            break;
          case "filterChange":
            if (this.isFilterDialogOpen)
              return;
            else
              this.refresh();
            break;
          case "onFilterDialogClose":
            this.isFilterDialogOpen = false;
          case "changePage":
          case "sort":
          case "changeRowsPerPage":
            this.refresh();
            break;
          default:
            console.log("Not handled action " + action);
        }
      },
      customFilterDialogFooter: (currentFilterList, applyNewFilters) => {
        return (
          <div style={{marginTop: "40px"}}>
            <Button variant="contained"
                    style={{color: "white", backgroundColor: "#36b9cc"}}
                    onClick={() => {applyNewFilters()}}
            >
              Apply
            </Button>
          </div>
        );
      },
      customToolbar: () => {
        return (
          <Create refresh={this.refresh} tableProps={this.props}/>
        );
      }
    };

    return (
      <React.Fragment>
        <MUIDataTable
          title={"PopUp News List"}
          data={list}
          columns={columns}
          options={options}
          ref={this.tableRef}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  news: state.newsReducer.news,
  cities: state.cityReducer.cities
});

const mapDispatchToProps = (dispatch) => ({
  search: (params) => dispatch(search(params)),
  getNews: (params) => dispatch(getNews(params)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withAuth0(NewsComponent));
