import React, { Component } from 'react'
import TrcLogo from './trc-logo.png'

export default class Footer extends Component {
    render() {
        return (
            <footer className="sticky-footer bg-white">
                <div className="container my-auto">
                <div className="copyright text-center my-auto">
                    <span>Copyright &copy;  <img src={TrcLogo} alt='Trc Logo' style={{width:'3%'}}/>  2023</span>
                </div>
                </div>
            </footer>
        )
    }
}
