export const AppActionTypes = {
  SET_CURRENT_ADMIN_USER: 'SET_CURRENT_ADMIN_USER',
  SET_PAGE_ROLES: 'SET_PAGE_ROLES',
  SET_CUSTOMER_SERVICE_CHANNELS: 'SET_CUSTOMER_SERVICE_CHANNELS',
  SET_LIVE_CHAT_CONFIG: 'SET_LIVE_CHAT_CONFIG',
  SET_UNREAD_MESSAGES: 'SET_UNREAD_MESSAGES',
  SET_UNREAD_MESSAGES_COUNT: 'SET_UNREAD_MESSAGES_COUNT',
  SET_CHAT_CLIENT: 'SET_CHAT_CLIENT',
  SET_FIREBASE_APP: 'SET_FIREBASE_APP',
};
