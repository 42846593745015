import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PieChart from "../../Chart/PieChart";
import IconButton from "@material-ui/core/IconButton";
import { Tooltip } from "@material-ui/core";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import {getMigrationProgress} from "../../../redux/dashboard/dashboard.action";
import {connect} from "react-redux";
import {withAuth0} from "@auth0/auth0-react";
import {Skeleton} from "@material-ui/lab";
import { CSVLink } from "react-csv";

const refresh_frequency = process.env.REACT_APP_REFRESH_FREQUENCY_MINUTES * 60000 ?? 600000

class MigrationProcessCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  componentDidMount() {
    if (!this.props?.vehicleBatteryStatus)
      this.refresh();
  }

  refresh = () => {
    this.props.getMigrationProgress();
    setTimeout(() => this.refresh(), refresh_frequency);
  }

  render() {
    const { migrationProgress } = this.props;

    const statusList = [
      {label: 'nonMigrated', name: 'Non-Migrated'},
      {label: 'migrated', name: 'Migrated'},
    ]

    if (!migrationProgress) {
      return (<div className="col-xl col-lg"><Skeleton variant="rect" width={"100%"} height={500}/></div>);
    }
    const labelOptions = {
      show: true,
      position: 'outside',
      formatter: (params) => {
          return params.value > 0 ? `${params.value} (${params.percent}%)` : ''
      }
    }

    let statusSum = 0;
    const chartData = statusList.reduce((acc, curr, index) => {
      let statusValue = 0;
      statusValue = (migrationProgress&&migrationProgress[curr.label])
      acc.push({value: statusValue, name: curr.name});
      statusSum = statusSum + acc[index].value;
      return acc;
    }, []);

    const reportData = chartData.reduce((acc, curr, index) => {
      acc.push({
        Status: curr.name,
        Value: curr.value,
        Percentage: `${((curr.value * 100) / statusSum).toFixed(2)}%`,
      });
      return acc;
    }, [])

    return (
      <div className="col-xl col-lg">
        <div className="card shadow mb-4">
          <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
            <h6 className="m-0 font-weight-bold" style={{color: '#333399'}}> 
              {" "}
              Migration Process
            </h6>
            <div >
              <CSVLink data={reportData} filename={"Vehicle Battery Status.csv"}>
                <Tooltip title="Download">
                  <IconButton
                    size="small"
                    aria-label="Download"
                    style={{ padding: 10 }}
                  >
                    <FontAwesomeIcon icon={faDownload} />
                  </IconButton>
                </Tooltip>
              </CSVLink>
            </div>
          </div>

          <div className="card-body">
            <PieChart
              seriesName={"Migration Status:"}
              label={chartData.map(x => x.name)}
              labelOptions={labelOptions}
              showData={chartData}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  migrationProgress: state.dashboardReducer.migrationProgress,
});

const mapDispatchToProps = (dispatch) => ({
  getMigrationProgress: () => dispatch(getMigrationProgress())
});

export default connect(mapStateToProps, mapDispatchToProps)(withAuth0(MigrationProcessCard));








