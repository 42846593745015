import {DashboardActionTypes} from "./dashboard.types";

const INITIAL_STATE = {
  dailyOrders: undefined,
  revenueCurrentMonth: undefined,
  dailyTasks: undefined,
  dailyOperationalVehicles: undefined,
  revenue: undefined,
  vehicleStatus: undefined,
  vehicleBatteryStatus: undefined,
  order: undefined,
  newUserRegistration: undefined,
  migrationProgress: undefined
};

const dashboardReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case DashboardActionTypes.SET_DAILY_ORDERS:
      return {...state,
        dailyOrders: action.payload
      };
    case DashboardActionTypes.SET_REVENUE_CURRENT_MONTH:
      return {...state,
        revenueCurrentMonth: action.payload
      };
    case DashboardActionTypes.SET_DAILY_TASKS:
      return {...state,
        dailyTasks: action.payload
      };
    case DashboardActionTypes.SET_DAILY_OPERATIONAL_VEHICLES:
      return {...state,
        dailyOperationalVehicles: action.payload
      };
    case DashboardActionTypes.SET_REVENUE:
      return {...state,
        revenue: action.payload
      };
    case DashboardActionTypes.SET_TOPUP:
      return {...state,
        topUp: action.payload
      };
    case DashboardActionTypes.SET_VEHICLE_STATUS:
      return {...state,
        vehicleStatus: action.payload
      };
    case DashboardActionTypes.SET_VEHICLE_BATTERY_STATUS:
      return {...state,
        vehicleBatteryStatus: action.payload
      };
    case DashboardActionTypes.SET_ORDER:
      return {...state,
        order: action.payload
      };
    case DashboardActionTypes.SET_NEW_USER_REGISTRATION:
      return {...state,
        newUserRegistration: action.payload
      };
    case DashboardActionTypes.SET_MIGRATION_PROGRESS:
      return {...state,
        migrationProgress: action.payload
      };
    case DashboardActionTypes.SET_USER_SATISFACTION:
      return {...state,
        userSatisfaction: action.payload
      };
    default:
      return state;
  }
};

export default dashboardReducer;