import {ActionTypes} from "./types";
import axios from "axios";
import {getAccessToken} from "../../auth/auth";

const host = process.env.REACT_APP_HOST

export const search = (queryParams) => {

  const config = {
    headers: {
      'Authorization': `Bearer ${getAccessToken()}`,
      'Content-Type': 'application/json'
    },
    params: queryParams ? queryParams : {
      page: 0,
      size: 1000
    }
  }

  return async dispatch => {
    return axios.get(`${host}/city`, config)
      .then(
        response => dispatch(setCities(response.data)),
        err => console.log(err)
      );
  }
}

export const setCities = (payload) => ({
  type: ActionTypes.SET_CITIES,
  payload: payload,
});