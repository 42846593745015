import {BusinessActionTypes} from "./business.types";

const INITIAL_STATE = {
  businesses: undefined,
  business: undefined,
  orderMetrics: undefined
};

const businessReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case BusinessActionTypes.SET_BUSINESSES:
            return {
              ...state,
              businesses: action.payload
            };
        case BusinessActionTypes.SET_BUSINESS:
            return {
              ...state,
              business: action.payload
            };
        case BusinessActionTypes.SET_ORDER_METRICS:
            return {
              ...state,
              orderMetrics: action.payload
            };
        default:
            return state;
    }
};

export default businessReducer;
