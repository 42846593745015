import React, {useRef, useState} from "react";
import {Popup} from "react-leaflet";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import {CardActionArea, Grid} from "@material-ui/core";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import {makeStyles} from "@material-ui/core/styles";

import customPopUp from './POIPopup.css';
import PoiEdit from "./PointOfInterestEdit/POIEdit";
import PoiRelocation from "./POIRelocation";
import PoiUploadIcon from "./PointOfInterestCreate/POIUploadIcon";
import Success from "../../PopUp/Success";
import * as ReactDOM from "react-dom";
import Backdrop from "../../Modal/Backdrop";
import PoiDelete from "./PointOfInterestDelete/POIDelete";

const useStyles = makeStyles({
  root: {
    margin: '0',
    '& p': {
      margin: '0',
    }
  },
  cardAction: {
    margin: 0,
    padding: 0
  }

});

const determineType = (type) => {
  switch (type) {
    case "HEALTH":
      return "Health"
    case "LANDMARK":
      return "Landmark"
    case "NATURAL_FEATURE":
      return "Natural feature"
    case "PLACE_OF_WORSHIP":
      return "Place of worship"
    case "FINANCE":
      return "Finance"
    case "POLITICAL":
      return "Political"
    case "POST_BOX":
      return "Post box"
    case "TOWN_SQUARE":
      return "Town square"
    case "LOCALITY":
      return "Locality"
    case "BUS_STATION":
      return "Bus station"
    case "TRAIN_STATION":
      return "Train station"
    case "FOOD":
      return "Food"
    default:
      return "Point of interest"
  }
}

const PoiPopup = ({pointOfInterest, setDraggable, position}) => {
  const popupRef = useRef();
  const [openSuccessModal, setOpenSuccessModal] = useState({
    open: false,
    header: undefined,
    content: ""
  });
  const [backdropOpen, setBackdropOpen] = useState(false);
  const {shortName: name, type, status, description, cityName, id} = pointOfInterest;
  const classes = useStyles();

  const currentStatus = status === "OPERATIONAL" ? 'Open' : 'Close';


  return (
    <Popup ref={popupRef}
           className={customPopUp}
           onClose={() => setBackdropOpen(false)}
           onOpen={() => setBackdropOpen(true)}>
      {backdropOpen && ReactDOM.createPortal(
        <Backdrop popup='true' onClick={() => {
          popupRef.current._closeButton.click()
          setBackdropOpen(false);
        }}/>,
        document.getElementById('backdrop-root'))}
      <Box sx={{flexGrow: 1}}>
        <Grid container spacing={2}>
          {/*<Grid item xs={4} style={{border: "1px solid red"}}>*/}
          <Grid item xs={4}>
            <CardActionArea>
              <CardMedia
                component="img"
                height="100"
                image={pointOfInterest.icon}
                alt="Point of interest icon"
              />
              <CardContent>
              </CardContent>
            </CardActionArea>
          </Grid>
          <Grid item xs={8}>
            <CardActionArea>
              <CardContent className={classes.cardAction}>
                <Typography gutterBottom variant="h6" align="left" className={classes.root}>
                  {name}
                </Typography>
                <Typography gutterBottom variant="body2" color="textSecondary" component="p">
                  {cityName}
                </Typography>
                <Typography gutterBottom variant="body2" color="textSecondary" component="p" className="pt-1">
                  {currentStatus}
                </Typography>
                <Typography gutterBottom variant="body2" color="textSecondary" component="p" className="pt-1">
                  {determineType(type)}
                </Typography>
                <Typography gutterBottom variant="body2" color="textSecondary" component="p" className="pt-3">
                  {description}
                </Typography>
              </CardContent>
            </CardActionArea>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={4} className="pb-0">
            {/*Space for Associated POI mark*/}
          </Grid>
          <Grid item xs={8} className="pb-0">
            <Grid container justify="flex-end">
              <PoiUploadIcon
                poiId={id}
                setOpenSuccessModal={setOpenSuccessModal}
              />
              <PoiEdit
                pointOfInterest={pointOfInterest}
                setOpenSuccessModal={setOpenSuccessModal}
              />
              <PoiDelete poiId={id} name={name} setOpenSuccessModal={setOpenSuccessModal}/>
              <PoiRelocation setDraggable={setDraggable} pointOfInterest={pointOfInterest} position={position} setOpenSuccessModal={setOpenSuccessModal}/>
              <Success
                open={openSuccessModal.open}
                header={openSuccessModal.header}
                content={openSuccessModal.content}
                onClose={() => setOpenSuccessModal({
                  open: false,
                  header: undefined,
                  content: ""
                })}
              />
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Popup>
  );
};

export default PoiPopup;