import React from 'react';
import * as ReactDOM from "react-dom";
import Backdrop from "../../../Modal/Backdrop";
import classes from "../../../Modal/Modal.module.css";
import RegionDeleteModal from "./regionDeleteModal";

const RegionDelete = ({region, openModal, onCloseModal, callback}) => {

  return (
    <React.Fragment>
      {openModal && ReactDOM.createPortal(
        <Backdrop onClick={() => onCloseModal(false)}/>,
        document.getElementById('backdrop-root'))}

      {openModal && ReactDOM.createPortal(
        <div className={classes.modal}>
          <RegionDeleteModal region={region} onCancel={onCloseModal} callback={callback}/>
        </div>,
        document.getElementById('overlay-root'))}
    </React.Fragment>
  );
};

export default RegionDelete;