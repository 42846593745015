import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";
import TrashIcon from "@material-ui/icons/Delete";
import { red, grey } from "@material-ui/core/colors/";
import {getAdminUsers} from "../../../redux/adminUser/adminUser.action";
import axios from "axios";
import { config } from "../../../auth/auth";
import { connect } from "react-redux";
import { withAuth0 } from "@auth0/auth0-react";
import {
  Button,
  Dialog,
  DialogActions, DialogContent, DialogContentText,
  DialogTitle,
} from "@material-ui/core";

const host = process.env.REACT_APP_HOST;

const defaultToolbarSelectStyles = {
  iconButton: {
  },
  iconContainer: {
    marginRight: "24px",
  },
  inverseIcon: {
    transform: "rotate(90deg)",
  },
};

class CustomToolbarSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false
    };
    this.selectedAction = undefined;
  }

  // Delete Admin users
  handleClickDeleted= () => {
    this.selectedAction = 'DELETE';
    this.setState({open: true})
  };

  // For delete admin users
  confirmAction = () => {
    const { adminUsers, selectedRows } = this.props;
    const adminuser = adminUsers?.content;
    selectedRows.data.map((row, index)=> {
      const rowData = adminuser[row.index];
      if(this.selectedAction === 'DELETE') { 
        this.deleteAdminUser(rowData, index);
      } else {
        this.changeStatus(rowData, index);
      }
      
    })
  }
  // end


  // Delete admin users
  deleteAdminUser = (rowData, index) => {
    const { selectedRows, callback } = this.props;
    return axios
      .delete(`${host}/consoleUser/${rowData.uuid}`, config())
      .then((response) => {
          const count = selectedRows.data.length - 1;
          if(index === count) {
            callback();
            this.setState({open: false})
          }
      })
      .catch((e) => alert(e))
      .finally(() => {});
  }

  // Close Confirmation Modal
  closeConfirmModal = () => {
    this.setState({open: false})
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.iconContainer}>
        <Tooltip title={"Delete selected"}>
            <IconButton className={classes.iconButton} onClick={this.handleClickDeleted}>
              <TrashIcon className={classes.icon} />
            </IconButton>
        </Tooltip> 

          <Dialog
            open={this.state.open}
            onClose={() => this.closeConfirmModal()}
            aria-labelledby="responsive-dialog-title"
            >
            <DialogTitle id="responsive-dialog-title">{"Are you absolutely sure?"}</DialogTitle>
              <DialogContent>
                <DialogContentText>
                    <span   style={{
                            display: this.selectedAction === 'DELETE' ? "block" : "none",
                        }}>This action cannot be undone. This will be permanently delete.</span>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => this.closeConfirmModal()} style={{ color: grey[500] }}>
                  Cancel
                </Button>
                <Button onClick={() => this.confirmAction()} style={{ color: red[600] }}>
                  Delete
                </Button>
              </DialogActions>
          </Dialog>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({ adminUsers: state.adminUsersData.adminUsers });

const mapDispatchToProps = (dispatch) => ({
  getAdminUsers: (params) => dispatch(getAdminUsers(params)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withAuth0(withStyles(defaultToolbarSelectStyles, { name: "CustomToolbarSelect" })(CustomToolbarSelect)));