import React, {useState} from 'react';
import {Dialog, IconButton, Tooltip} from "@material-ui/core";
import MultiStepForm, {FormStep} from "../../../components/Form/MultistepForm/MultiStepForm";
import * as Yup from "yup";
import EditIcon from "@material-ui/icons/Edit";
import SelectUpdateModal from "./Update/SelectUpdateModal";
import UpdateMembershipLimit from "./Update/UpdateMembershipLimit";
import UpdateMembershipDetails from "./Update/UpdateMembershipDetails";
import axios from "axios";
import {config} from "../../../auth/auth";
import Success from "../../../components/PopUp/Success";

const host = process.env.REACT_APP_HOST;

const editTypeValidation = Yup.object({
  type: Yup.string().required().label("Type ")
});

const membershipLimitValidation = Yup.object({
  limitationDay: Yup.number()
    .min(1, "Min value is 1")
    .max(100000, "Max quantity is 1000").required().label("Limitation "),
  limitationPeriod: Yup.number()
    .min(1, "Min value is 1")
    .max(100000, "Max quantity is 1000").required().label("Limitation "),
});

const MembershipDetailsValidation = Yup.object({});

const MembershipEditModal = ({callback, membership}) => {
  const [openModal, setOpenModal] = useState(false);
  const [response, setResponse] = useState(true);
  const [openSuccessModal, setOpenSuccessModal] = useState({
    open: false,
    header: undefined,
    content: ""
  });

  const membershipDayLimitation = membership?.limits?.filter(limit => limit.limitUnit === "DAY")[0]?.limitation;
  const membershipPeriodLimitation = membership?.limits?.filter(limit => limit.limitUnit === "PERIOD")[0]?.limitation;
  const membershipDayUuid = membership?.limits?.filter(limit => limit.limitUnit === "DAY")[0]?.uuid;
  const membershipPeriodUuid = membership?.limits?.filter(limit => limit.limitUnit === "PERIOD")[0]?.uuid;

  const membershipBody = {
    ...membership,
    type: '',
    limitationDayUuid: membershipDayUuid,
    limitationDay: membershipDayLimitation,
    limitationPeriodUuid: membershipPeriodUuid,
    limitationPeriod: membershipPeriodLimitation
  }

  const updateMembership = async (values) => {
    let limitationDayRequest = null;
    let limitationPeriodRequest = null;

    if (values.type === 'Limits') {
      if (membershipDayLimitation !== values.limitationDay) {
        const bodyDayRequest = {
          membershipUuid: values.uuid,
          uuid: values.limitationDayUuid,
          limitation: values.limitationDay,
          limitUnit: "DAY"
        }
        limitationDayRequest = bodyDayRequest.uuid ?
          axios.put(`${host}/membershipLimit/${values.limitationDayUuid}`, bodyDayRequest, config()) :
          axios.post(`${host}/membershipLimit/`, bodyDayRequest, config());
      }

      if (membershipPeriodLimitation !== values.limitationPeriod) {
        const bodyPeriodRequest = {
          membershipUuid: values.uuid,
          uuid: values.limitationPeriodUuid,
          limitation: values.limitationPeriod,
          limitUnit: "PERIOD"
        }
        limitationPeriodRequest = bodyPeriodRequest.uuid ?
          axios.put(`${host}/membershipLimit/${values.limitationPeriodUuid}`, bodyPeriodRequest, config()) :
          axios.post(`${host}/membershipLimit/`, bodyPeriodRequest, config());
      }

      await axios.all([limitationDayRequest, limitationPeriodRequest])
        .then(
          axios.spread((day, period) => {
            if (day?.status < 200 || day?.status > 299 || period?.status < 200 || period?.status > 299) {
              setResponse(false);
            }
          })
        ).catch((error) => alert(error));

    } else {
      const body = {
        price: values.price,
        currency: values.currency,
        description: values.description,
        period: values.period,
        periodUnit: values.periodUnit,
        status: values.status
      }

      await axios.put(`${host}/membership/${values.uuid}`, body, config())
        .then(res => {
          if (res?.status < 200 || res?.status > 299) {
            setResponse(false);
          }})
        .catch((error) => alert(error));
    }

    if (response) {
      setOpenSuccessModal({
        open: true,
        content: `Membership updated successfully.`
      });
      callback();
    } else {
      setOpenSuccessModal({
        open: true,
        header: "Failed",
        content: `Failed to update Membership.`
      });
    }
  }

  const onCloseSuccessModal = () => {
    setOpenSuccessModal({
      open: false,
      header: undefined,
      content: ""
    });
    setOpenModal(false);
  }

  return (

    <React.Fragment>
      <Tooltip title="Edit Membership">
        <IconButton
          variant="contained"
          color="primary"
          onClick={() => setOpenModal(true)}
        >
          <EditIcon/>
        </IconButton>
      </Tooltip>
      <Dialog
        open={openModal}
        aria-labelledby="User Action Modal"
        fullWidth={true}
      >
        <MultiStepForm
          exceptionStep="Limits"
          setOpenModal={setOpenModal}
          initialValues={membershipBody}
          onSubmit={(values) => updateMembership(values)}
        >
          <FormStep stepName="UpdateMembershipLimits"
                    validationSchema={membershipLimitValidation}>
            <UpdateMembershipLimit/>
          </FormStep>
          <FormStep stepName="EditType"
                    validationSchema={editTypeValidation}>
            <SelectUpdateModal/>
          </FormStep>
          <FormStep stepName="UpdateMembershipDetails"
                    validationSchema={MembershipDetailsValidation}>
            <UpdateMembershipDetails/>
          </FormStep>
        </MultiStepForm>
        <Success
          open={openSuccessModal.open}
          header={openSuccessModal.header}
          content={openSuccessModal.content}
          onClose={onCloseSuccessModal}/>
      </Dialog>
    </React.Fragment>
  );
};

export default MembershipEditModal;