import {RoleActionTypes} from "./role.types";

const INITIAL_STATE = {
  roles: undefined,
  role: undefined,
  unassignedUsers: undefined,
  unassignedPages: undefined,
  users: undefined,
  pages: undefined,
};

const roleReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case RoleActionTypes.SET_ROLES:
            return {
              ...state,
              roles: action.payload
            };
        case RoleActionTypes.SET_ROLE:
            return {
              ...state,
              role: action.payload
            };
        case RoleActionTypes.SET_UNASSIGNED_USERS:
            return {
              ...state,
              unassignedUsers: action.payload
            };
        case RoleActionTypes.SET_UNASSIGNED_PAGES:
            return {
              ...state,
              unassignedPages: action.payload
            };
        case RoleActionTypes.SET_USERS:
            return {
              ...state,
              users: action.payload
            };
        case RoleActionTypes.SET_PAGES:
            return {
              ...state,
              pages: action.payload
            };
        default:
            return state;
    }
};

export default roleReducer;
