import React from "react";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { IconButton, Tooltip } from "@material-ui/core";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { grey } from "@material-ui/core/colors/";
import LinkIcon from "@material-ui/icons/Link"
import PropTypes from "prop-types";
import axios from "axios";

import {config} from "../../../auth/auth";
import {CircularProgress, Grid} from "@material-ui/core";
import CardContent from "@material-ui/core/CardContent";
import "./details.css";
import moment from "moment";


const datetime_format = process.env.REACT_APP_DATETIME_FORMAT;
const host = process.env.REACT_APP_HOST

class UserMigratePopUp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      open: false,
      existingUserUUID: undefined,
      uuid: this.props.uuid,
    };
  }

  static propTypes = {
    refresh: PropTypes.func.isRequired
  };

  cancel = () => {
    this.setState({open: false});
  };

  migrate = () => {
    const { refresh } = this.props;
    if (this.state.isLoading)
      return;
    const body = {
      mainUserUuid: `${this.state.existingUserUUID}`,
    }
    this.setState({isLoading: true})
    return axios.put(`${host}/user/${this.state.uuid}/merge-auth`, body, config())
      .then((response) => {})
      .catch(e => alert(e))
      .finally(() => {
        this.setState({isLoading: false, open: false, existingUserUUID: undefined });
        refresh();
      });
  };

  onChangeUuid = (e) => {
    this.setState({existingUserUUID: e.target.value})
  };

  componentDidUpdate(prevProps) {
    if (this.props?.open !== prevProps.open) {
      this.setState({ open: this.props?.open });
    }
  }

  render() {
    const { isMigrated, uuid } = this.props;

    return (
      <React.Fragment>
        <Tooltip title={"Migrate to the Existing User"}>
          <IconButton
            onClick={() => {
              this.setState({
                open: true,
              });
            }}
          >
            <LinkIcon
              style={{
                color: grey[800],
              }}
            />
          </IconButton>
        </Tooltip>
        <Dialog 
            open={this.state.open} 
            onClose={this.cancel} 
            aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title" className="primary">
            <h4>Migrate to the Existing Users</h4>
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <div style={{color: 'red'}}>THIS ACTION CANNOT BE REVERTED BACK!</div>
              Please double check whether the user information is right. After you match the user information,
              then the current user information will be transferred and deleted.
            </DialogContentText>
            <Grid container spacing={2}>
              <Grid item sm={12} xs={12} md={12} lg={12}>
                  <CardContent>
                    <Grid container spacing={2} className="formLabel">
                      <Grid item md={12} lg={12}>
                        <TextField
                          id="news-create-content-id"
                          label="Existing User UUID"
                          fullWidth
                          value={this.state.existingUserUUID}
                          InputProps={{
                            readOnly: false,
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          variant="outlined"
                          onChange={this.onChangeUuid}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
              <React.Fragment>
                {this.state.isLoading ? <CircularProgress style={{width:20, height:20}}/> : ""}
                <Button
                  onClick={this.migrate}
                  style={{color: "white", backgroundColor: "red"}}
                >
                  Migrate
                </Button>
                <Button
                  onClick={this.cancel}
                  color="primary"
                  disabled={this.state.isLoading}
                >
                  Cancel
                </Button>
              </React.Fragment>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }

}

export default UserMigratePopUp;
