import React, {Component} from 'react'
import TaskCreationPopUp from '../../../../components/PopUp/TaskCreationPopUp'
import ParkingConfigurationMap from "../../../../components/Map/ParkingConfigurationMap";

export default class RedistributionTask extends Component {

  state = {
    isOpen:false,
    category:''
  }
  togglePopup = (category) => {
    const newOpen = !this.state.isOpen
    const newCategory = category
    this.setState({isOpen:newOpen, category:newCategory})
  }

  render() {
    return (
      <div>
        <h1 className="h3 mb-2 text-gray-800">Parkings Spot Configuration</h1>
        <p>You can put the point on the map and set up parking spots and the user will see the parking spot on the App.</p>

        {this.state.isOpen && <TaskCreationPopUp
          category={this.state.category}
          handleClose={this.togglePopup}
        />}
        <br/>
        <div className="row">
          <ParkingConfigurationMap togglePopup={this.togglePopup}/>
        </div>
      </div>
    )
  }
}
