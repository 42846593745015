import React, {Component} from "react";
import MUIDataTable from "mui-datatables";
import {withAuth0} from "@auth0/auth0-react";
import {Button, IconButton, Tooltip} from "@material-ui/core";
import {connect} from "react-redux";
import {search} from "../../../../redux/country/action";
import {queryParams} from "../../../../utils/utils";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import CountryCreate from "./CountryCreate";
import CountryUpdate from "./CountryUpdate";
import VehiclesSupported from "../City/VehiclesSupported";
import Box from "@material-ui/core/Box";

class Country extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openPopUp: false,
    };
    this.isFilterDialogOpen = false;
    this.tableRef = React.createRef();
    this.columnsDisplay = undefined;
  }

  componentDidMount() {
    this.refresh();
  }

  refresh = () => {
    const { search } = this.props;

    // table state
    const state = this.tableRef?.current?.state;

    const params = queryParams(this.props, state);

    search(params);
  };

  onChangeColumnDisplay = () => {
    const state = this.tableRef?.current?.state;
    this.columnsDisplay = state.columns.reduce(
      (obj, item) => Object.assign(obj, { [item.name]: item.display }),
      {}
    );
  };

  isDisplay = (name) => {
    if (!this.columnsDisplay || !this.columnsDisplay.hasOwnProperty(name))
      return undefined;
    else return this.columnsDisplay[name];
  };

  render() {
    const { countries } = this.props;
    if (!countries) return <React.Fragment>Loading...</React.Fragment>;

    const list = countries?.content;
    const totalElements = countries?.totalElements ?? 0;
    const rowsPerPage = countries?.pageable?.pageSize ?? 10;
    const page = countries?.pageable?.pageNumber ?? 0;

    const columns = [
      {
        name: "uuid",
        label: "UUID",
        options: {
          filter: true,
          sort: false,
          display: this.isDisplay("uuid") ?? false,
          customFilterListOptions: { render: (v) => `UUID: ${v}` },
          filterType: "textField",
        },
      },
      {
        name: "isoCode",
        label: "Flag",
        options: {
          filter: false,
          sort: false,
          display: this.isDisplay("isoCode") ?? true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return(
              <Box sx={{ '& > img': { mr: 2, flexShrink: 0 } }}>
                <img
                  loading="lazy"
                  width="20"
                  src={`https://flagcdn.com/w20/${value?.toLowerCase()}.png`}
                  srcSet={`https://flagcdn.com/w40/${value?.toLowerCase()}.png 2x`}
                  alt=""
                />
              </Box>)
            ;
          },
        },
      },{
        name: "isoCode",
        label: "ISO Code",
        options: {
          filter: false,
          sort: false,
          display: this.isDisplay("isoCode") ?? true,
        },
      },
      {
        name: "name",
        label: "Name",
        options: {
          filter: false,
          sort: false,
          display: this.isDisplay("name") ?? true,
        },
      },
      {
        name: "uuid",
        label: " ",
        options: {
          filter: false,
          sort: false,
          empty: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (<CountryUpdate callback={this.refresh} isoCode={tableMeta?.rowData[1]} uuid={value}/>);
          },
        },
      },
    ];

    const options = {
      filter: false,
      search: false,
      confirmFilters: false,
      serverSide: true,
      filterType: "dropdown",
      responsive: "vertical",
      rowsPerPageOptions: [10, 20, 50, 100],
      rowsPerPage: rowsPerPage,
      count: totalElements,
      page: page,
      fixedHeader: true,
      tableBodyHeight: "900px",
      onTableChange: (action, tableState) => {
        switch (action) {
          case "viewColumnsChange":
            this.onChangeColumnDisplay();
            break;
          case "onFilterDialogOpen":
            this.isFilterDialogOpen = true;
            break;
          case "filterChange":
            if (this.isFilterDialogOpen) return;
            else this.refresh();
            break;
          case "onFilterDialogClose":
            this.isFilterDialogOpen = false;
          case "changePage":
          case "sort":
          case "changeRowsPerPage":
            this.refresh();
            break;
          default:
            console.log("Not handled action " + action);
        }
      },
      customFilterDialogFooter: (currentFilterList, applyNewFilters) => {
        return (
          <div style={{ marginTop: "40px" }}>
            <Button
              variant="contained"
              style={{ color: "white", backgroundColor: "#36b9cc" }}
              onClick={() => {
                applyNewFilters();
              }}
            >
              Apply
            </Button>
          </div>
        );
      },
      customToolbar: () => <CountryCreate callback={this.refresh}/>
    };

    return (
      <React.Fragment>
        <MUIDataTable
          title={<h1 className="tableTitle">Country List</h1>}
          data={list}
          columns={columns}
          options={options}
          ref={this.tableRef}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  countries: state.countryReducer.countries,
});

const mapDispatchToProps = (dispatch) => ({
  search: (params) => dispatch(search(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withAuth0(Country));
