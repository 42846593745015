import React from "react";
import PropTypes from 'prop-types';
import {IconButton, Tooltip} from "@material-ui/core";
import {red} from "@material-ui/core/colors";
import EmailIcon from '@material-ui/icons/Email';
import SmsIcon from '@material-ui/icons/Sms';
import PhonelinkRingIcon from '@material-ui/icons/PhonelinkRing';
import DeviceUnknownIcon from '@material-ui/icons/DeviceUnknown';
import WarningIcon from '@material-ui/icons/Warning';

class Channel extends React.Component {
  static propTypes = {
    value: PropTypes.string.isRequired
  };

  render() {
    const { value } = this.props;
    let icon;
    let title;
    if (value === 'EMAIL') {
      icon = <EmailIcon/>;
      title = 'Email';
    }else if (value === 'SMS') {
      icon = <SmsIcon/>;
      title = 'SMS';
    }else if (value === 'APP_NOTIFICATION') {
      icon = <PhonelinkRingIcon/>;
      title = 'App Notification';
    }else if (value === 'SYSTEM_NOTIFICATION') {
      icon = <WarningIcon/>;
      title = 'System Notification';
    }else {
      icon = <DeviceUnknownIcon style={{ color: red[600] }}/>;
      title = 'Unknown';
    }
    return (
      <React.Fragment>
        <Tooltip title={title}>
          <IconButton>
            {icon}
          </IconButton>
        </Tooltip>
      </React.Fragment>
    );
  }
}

export default Channel;

