import React, {useState} from 'react';
import {IconButton, Tooltip} from "@material-ui/core";
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import * as ReactDOM from "react-dom";
import Backdrop from "../../../Modal/Backdrop";
import classes from "../../../Modal/Modal.module.css";
import PoiUploadIconModal from "./POIUploadIconModal";

const PoiUploadIcon = ({setOpenSuccessModal, poiId}) => {
  const [openModal, setOpenModal] = useState(false);

  return (
    <React.Fragment>
      <Tooltip title="Edit">
        <IconButton
          variant="contained"
          color="primary"
          onClick={() => setOpenModal(true)}
        >
          <PhotoCameraIcon/>
        </IconButton>
      </Tooltip>
      {openModal && ReactDOM.createPortal(
        <Backdrop onClick={() => setOpenModal(false)}/>,
        document.getElementById('backdrop-root'))}
      {openModal && ReactDOM.createPortal(
        <div className={classes.modal}>
          <PoiUploadIconModal poiId={poiId} setOpenModal={setOpenModal} setOpenSuccessModal={setOpenSuccessModal}/>
        </div>,
        document.getElementById('overlay-root'))}

    </React.Fragment>
  );
};

export default PoiUploadIcon;