import {Backdrop, CircularProgress} from "@material-ui/core";
import React from 'react';

const style = {
  zIndex: 1000,
  color: '#fff',
};

export const useBlockUI = () => {
  const [isBlocked, setIsBlocked] = React.useState(false);

  const blockUI = () => setIsBlocked(true);
  const unBlockUI = () => setIsBlocked(false);

  const RenderBlockUI = () => (
    <React.Fragment>
      {isBlocked &&
      <Backdrop style={style} open={isBlocked}>
        <CircularProgress color="inherit"/>
      </Backdrop>
      }
    </React.Fragment>
  );

  return {blockUI, unBlockUI, RenderBlockUI}
};