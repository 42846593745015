import React from "react";
import {useMap} from "react-leaflet";
import * as L from "leaflet";
import RegionPopup from "./regionPopup";
import ReactDOMServer from "react-dom/server";

export default function VehicleRegions(props) {
  const map = useMap();

// Alter Coordinates
  const flipCoOrdinates = (coords) => {
    let latLngCoordinates = [];
    if (coords.length) {
      coords.map((coord, i) =>
        latLngCoordinates.push([coords[i][1], coords[i][0]])
      );
    }
    return latLngCoordinates;
  };

  // remove geojson Layers
  const removeGeoJsonLayers = () => {
    map.eachLayer((layer) => {
      if (layer.myTag && layer.myTag === "regionGeoJSON") {
        map.removeLayer(layer);
      }
    });
  };

  // Load Regions
  const loadRegions = () => {
    const regions = props.regions;
    const regionData = regions;
    if (
      regionData === null ||
      regionData === undefined ||
      regionData.length === 0
    ) {
      return true;
    }
    removeGeoJsonLayers();
    const tempRegionObj = {
      OPERATING: [],
      LOW_SPEED: [],
      NO_PARKING: [],
      FORBIDDEN: [],
    };
    regionData.forEach((region) => {
      let coordinatesData = flipCoOrdinates(region.boundary) || [];
      let geoJSON = {
        type: "Feature",
        properties: {
          category: region.category,
          regionName: region.regionName,
          status: region.status,
          discount: region.discount,
          uuid: region.uuid,
          speedLimit: region.speedLimit,
        },
        geometry: {
          type: "Polygon",
          coordinates: [coordinatesData],
        },
      };
      if (region.category === "OPERATING") {
        tempRegionObj["OPERATING"].push(geoJSON);
      } else if (region.category === "LOW_SPEED") {
        tempRegionObj["LOW_SPEED"].push(geoJSON);
      } else if (region.category === "NO_PARKING") {
        tempRegionObj["NO_PARKING"].push(geoJSON);
      } else if (region.category === "FORBIDDEN") {
        tempRegionObj["FORBIDDEN"].push(geoJSON);
      }
    });
    const allRegions = [].concat(
      tempRegionObj["OPERATING"],
      tempRegionObj["LOW_SPEED"],
      tempRegionObj["NO_PARKING"],
      tempRegionObj["FORBIDDEN"]
    );
    if (allRegions.length) {
      addRegionToMap(allRegions, map);
    }
  };
  const addRegionToMap = (regionArray, map) => {
    const geojsonLayer = L.geoJSON(regionArray, {
      style: (feature) => {
        switch (feature.properties.category) {
          case "OPERATION_REGION":
            return { color: "#BEFFC1" };
          case "NO_PARKING":
            return { color: "#FFFEBE" };
          case "FORBIDDEN":
            return { color: "#FFBEBE" };
          case "LOW_SPEED":
            return { color: "#BEECFF" };
        }
      },
      onEachFeature: (feature, layer) => {
        if (feature.properties) {
          layer.myTag = "regionGeoJSON";
          layer.bindPopup(
            ReactDOMServer.renderToString(
              <RegionPopup region={feature.properties} />
            )
          );
        }
      },
    });
    geojsonLayer.addTo(map);
  };
  loadRegions();
  return null;
}
