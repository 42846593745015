import {TaskActionTypes} from "./task.types";
import axios from "axios";
import {config, getAccessToken} from "../../auth/auth";

const host = process.env.REACT_APP_HOST
const maxItemSize = process.env.REACT_APP_MAX_ITEM_SIZE

export const search  = (queryParams) =>{
  const config = {
    headers: {
      'Authorization': `Bearer ${getAccessToken()}`,
      'Content-Type': 'application/json'
    },
    params: queryParams
  }

  return async dispatch => {
    return axios.get(`${host}/task`, config)
      .then(
        response => dispatch(setTasks(response.data)),
        err => console.log(err)
      );
  }
}

export const getTaskByUuid  = (uuid) => {
  return async dispatch => {
    return axios.get(`${host}/task/${uuid}`, config())
      .then(
        response => dispatch(setTask(response.data)),
        err => console.log(err)
      );
  }
}

export const getUnfinishedTasks  = (page=0, size=maxItemSize) => {
  const config = {
    headers: {
      'Authorization': `Bearer ${getAccessToken()}`,
      'Content-Type': 'application/json'
    },
    params: {
      page,
      size
    }
  }

  return async dispatch => {
    return axios.get(`${host}/task/unfinished`, config)
      .then(
        response =>
          dispatch(setUnfinishedTasks(response.data)),
        err => console.log(err)
      );
  }
}

export const getFinishedTasksToday  = (page=0, size=maxItemSize) => {
  const config = {
    headers: {
      'Authorization': `Bearer ${getAccessToken()}`,
      'Content-Type': 'application/json'
    },
    params: {
      page,
      size
    }
  }

  return async dispatch => {
    return axios.get(`${host}/task/finishedToday`, config)
      .then(
        response =>
          dispatch(setFinishedTasksToday(response.data)),
        err => console.log(err)
      );
  }
}

export const getSuccessfulTasksToday = (queryParams) => {
  const config = {
    headers: {
      'Authorization': `Bearer ${getAccessToken()}`,
      'Content-Type': 'application/json'
    },
    params: queryParams
  }

  return async dispatch => {
    return axios.get(`${host}/task`, config)
      .then(
        response =>
          dispatch(setSuccessfulTasksToday(response.data)),
        err => console.log(err)
      );
  }
}

export const setTasks = (payload) => ({
  type: TaskActionTypes.SET_TASKS,
  payload: payload,
});
export const setTask = (task) => ({
  type: TaskActionTypes.SET_TASK,
  payload: task,
});
export const setUnfinishedTasks = (task) => ({
  type: TaskActionTypes.SET_UNFINISHED_TASK,
  payload: task,
});
export const setFinishedTasksToday = (task) => ({
  type: TaskActionTypes.SET_FINISHED_TASKS_TODAY,
  payload: task,
});
export const setSuccessfulTasksToday = (task) => ({
  type: TaskActionTypes.SET_SUCCESSFUL_TASKS_TODAY,
  payload: task,
});