import {ActionTypes} from "./taskRule.types";

const INITIAL_STATE = {
    taskRules: undefined,
    taskRule: undefined,
    taskRuleActions: undefined,
    taskRuleNotifications: undefined
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ActionTypes.SET_TASK_RULES:
      return {
        ...state,
        taskRules: action.payload,
      };
    case ActionTypes.SET_TASK_RULE:
    return {
        ...state,
        taskRule: action.payload,
    };
    case ActionTypes.SET_TASK_RULE_ACTIONS:
    return {
        ...state,
        taskRuleActions: action.payload,
    };
    case ActionTypes.SET_TASK_RULE_NOTIFICATIONS:
    return {
        ...state,
        taskRuleNotifications: action.payload,
    };
    default:
      return state;
  }
};

export default reducer;