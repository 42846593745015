import React, {Component} from 'react'
import {faDollarSign} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {getRevenueCurrentMonth} from "../../../redux/dashboard/dashboard.action";
import {connect} from "react-redux";
import {withAuth0} from "@auth0/auth0-react";
import {Skeleton} from "@material-ui/lab";
import { withTranslation } from 'react-i18next';
import {getCurrency} from "../../../redux/partners/action";

const refresh_frequency = process.env.REACT_APP_REFRESH_FREQUENCY_MINUTES * 60000 ?? 600000

class RevenueCurrentMonthCard extends Component {

  componentDidMount() {
    if (this.props?.revenueCurrentMonth === null || this.props?.revenueCurrentMonth === undefined)
      this.refresh();
  }

  refresh = () => {
    this.props?.getRevenueCurrentMonth();
    this.props?.getCurrency();
    setTimeout(() => this.refresh(), refresh_frequency);
  }

  render() {
    const {revenueCurrentMonth,t, partnerCurrency} = this.props;
    if (revenueCurrentMonth === null || revenueCurrentMonth === undefined) {
      return (<div className="col-xl col-md mb-4"> <Skeleton variant="rect" width={"100%"} height={"100%"} /> </div>);
    }
    return (
      <div className="col-xl col-md mb-4">
          <div className="card border-left-success shadow h-100 py-2">
            <div className="card-body">
              <div className="row no-gutters align-items-center">
                <div className="col mr-2">
                  <div className="text-xs font-weight-bold text-success text-uppercase mb-1">{t('small-cards.revenue')}
                  </div>
                  <div className="h5 mb-0 font-weight-bold text-gray-800">{partnerCurrency} {revenueCurrentMonth}</div>
                </div>
                <div className="col-auto">
                  <FontAwesomeIcon icon={faDollarSign} className="fa-2x text-gray-300"/>
                </div>
              </div>
            </div>
          </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  revenueCurrentMonth: state.dashboardReducer.revenueCurrentMonth,
  partnerCurrency: state.partnerReducer.partnerCurrency
});

const mapDispatchToProps = (dispatch) => ({
  getRevenueCurrentMonth: () => dispatch(getRevenueCurrentMonth()),
  getCurrency: () => dispatch(getCurrency())
});

export default connect(mapStateToProps, mapDispatchToProps)(withAuth0(withTranslation()(RevenueCurrentMonthCard)));
