import {ActionTypes} from "./types";
import axios from "axios";
import {config} from "../../auth/auth";


const host = process.env.REACT_APP_HOST

export const search  = (queryParams) =>{
  return async dispatch => {
    return axios.get(`${host}/userMessageTask`, config(queryParams))
      .then(
        response => {
          dispatch(setNotificationTasks(response.data));
        },
        err => console.log(err)
      );
  }
}

export const get  = (uuid) =>{
  return async dispatch => {
    return axios.get(`${host}/userMessageTask/${uuid}`, config())
      .then(
        response => {
          dispatch(setNotificationTask(response.data));
        },
        err => console.log(err)
      );
  }
}

export const setNotificationTasks = (payload) => ({
  type: ActionTypes.SET_NOTIFICATION_TASKS,
  payload: payload,
});

export const setNotificationTask = (payload) => ({
  type: ActionTypes.SET_NOTIFICATION_TASK,
  payload: payload,
});