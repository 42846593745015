import React from "react";
import PieChart from "../../../components/Chart/PieChart";
import PropTypes from "prop-types";
import moment from "moment";
import {Skeleton} from "@material-ui/lab";

class WarrantyLeft extends React.Component {
  static propTypes = {
    battery: PropTypes.object.isRequired,
  };


  render() {
    const {battery} = this.props;
    if (!battery) {
      return (<Skeleton variant="rect" width={"100%"} height={"50vh"}/>);
    }

    var usage;
    var left;
    if (battery?.chargingCycle && battery?.warrantyTimes){
      usage = battery?.chargingCycle/battery?.warrantyTimes * 100;
    }else if (battery?.warrantyPeriod){
      const start = moment(battery?.warrantyPeriod);
      const end = moment();
      const duration = moment.duration(end.diff(start));
      const month = duration.asMonths();
      usage = month/battery?.warrantyPeriod * 100;
    }else {
      usage = 0;
    }

    left = 100 - usage;

    const seriesName = `Warranty Left`;
    const label = ['Usage', 'Warranty Left'];
    const showData = [
      {value: left,  name: 'Warranty Left'},
      {value: usage, name: 'Usage'}
    ];

    return (
      <PieChart
        seriesName={seriesName}
        label={label}
        showData={showData}/>
    );
  }
}

export default WarrantyLeft;