import {ActionTypes} from "./taskRule.types";
import axios from "axios";
import {config, getAccessToken} from "../../auth/auth";

const host = process.env.REACT_APP_HOST

export const search = (queryParams) => {
    const config = {
        headers: {
            'Authorization': `Bearer ${getAccessToken()}`,
            'Content-Type': 'application/json'
        },
        params: queryParams
    }

    return async dispatch => {
        return axios.get(`${host}/taskRule`, config)
            .then(
                response => dispatch(setTaskRules(response.data)),
                err => console.log(err)
            );
    }
}

export const getTaskRuleByUuid = (uuid) => {
    return async dispatch => {
        return axios.get(`${host}/taskRule/${uuid}`, config())
            .then(
                response => dispatch(setTaskRule(response.data)),
                err => console.log(err)
            );
    }
}

export const searchTaskRuleActions  = (task_rule_uuid) => {
    return async dispatch => {
      return axios.get(`${host}/taskRuleAction/?taskRuleUuid=${task_rule_uuid}`, config())
        .then(
          response => dispatch(setTaskRuleActions(response.data)),
          err => console.log(err)
        );
    }
  }

export const searchTaskRuleNotifications  = (task_rule_uuid) => {
    return async dispatch => {
      return axios.get(`${host}/taskRuleNotification/?taskRuleUuid=${task_rule_uuid}`, config())
        .then(
          response => dispatch(setTaskRuleNotifications(response.data)),
          err => console.log(err)
        );
    }
  }

export const setTaskRules = (payload) => ({
    type: ActionTypes.SET_TASK_RULES,
    payload: payload,
});

export const setTaskRule = (payload) => ({
    type: ActionTypes.SET_TASK_RULE,
    payload: payload,
});

export const setTaskRuleActions = (payload) => ({
    type: ActionTypes.SET_TASK_RULE_ACTIONS,
    payload: payload,
});

export const setTaskRuleNotifications = (payload) => ({
    type: ActionTypes.SET_TASK_RULE_NOTIFICATIONS,
    payload: payload,
});