import React from "react";
import PropTypes from 'prop-types';
import {red} from "@material-ui/core/colors";
import {IconButton, Tooltip} from "@material-ui/core";
import InfoIcon from '@material-ui/icons/Info';
import axios from "axios";
import {config} from "../../../../auth/auth";

const host = process.env.REACT_APP_HOST

class Suspicious extends React.Component {
  constructor(props) {
    super(props);
  }

  static propTypes = {
    value: PropTypes.bool.isRequired,
  };


  onClickSuspicious = (suspiciousStatus, orderUuid) => {
    const {callback} = this.props;

    return axios.put(`${host}/order/${orderUuid}/suspicious/${suspiciousStatus}`, '', config())
      .catch((e) => alert(e))
      .finally(callback);
  }

  render() {
    const {suspiciousStatus, orderUuid, userUuid} = this.props;
    if (suspiciousStatus === undefined || suspiciousStatus === null)
      return (<React.Fragment/>);

    return (
      <React.Fragment>
        {suspiciousStatus ? (
          <Tooltip title="Mark as Normal">
            <IconButton
              aria-label="Block"
              onClick={() => this.onClickSuspicious(false, orderUuid, userUuid)}
            >
              <InfoIcon style={{color: red[500]}}/>
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip title="Mark as Suspicious">
            <IconButton
              aria-label="Block"
              onClick={() => this.onClickSuspicious(true, orderUuid, userUuid)}
            >
              <InfoIcon/>
            </IconButton>
          </Tooltip>
        )}
      </React.Fragment>
    );
  }
}

export default Suspicious;

