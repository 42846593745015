/*
 * @Author: your name
 * @Date: 2021-01-09 21:01:52
 * @LastEditTime: 2021-01-09 21:05:46
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /tracmobility-console/src/auth/auth0-provider-with-history.js
 */

import React from "react";
import {Auth0Provider} from "@auth0/auth0-react";
import {withRouter} from "react-router-dom";

const auth0_domain = process.env.REACT_APP_AUTH0_DOMAIN
const auth0_client_id = process.env.REACT_APP_AUTH0_CLIENT_ID
const auth0_audience = process.env.REACT_APP_AUTH0_AUDIENCE

class Auth0ProviderWithHistory extends React.Component {

  onRedirectCallback = (appState) => {
    this.props.history.push(appState?.returnTo || window.location.pathname);
  };

  render() {
    return (
      <Auth0Provider
      domain={auth0_domain}
      clientId={auth0_client_id}
      redirectUri={window.location.origin}
      audience={auth0_audience}
      scope="read:current_user update:current_user_metadata"
      cacheLocation="localstorage"
      useRefreshTokens={true}
      >
        {this.props.children}
      </Auth0Provider>
    );
  }
}

export default withRouter(Auth0ProviderWithHistory);