import {ActionTypes} from "./types";
import axios from "axios";
import {config, getAccessToken} from "../../auth/auth";

const host = process.env.REACT_APP_HOST

export const search  = (queryParams) =>{
  const config = {
    headers: {
      'Authorization': `Bearer ${getAccessToken()}`,
      'Content-Type': 'application/json'
    },
    params: queryParams
  }

  return async dispatch => {
    return axios.get(`${host}/order`, config)
      .then(
        response => dispatch(setOrders(response.data)),
        err => console.log(err)
      );
  }
}

export const getOrderByUuid  = (uuid) => {
  return async dispatch => {
    return axios.get(`${host}/order/${uuid}`, config())
      .then(
        response => dispatch(setOrder(response.data)),
        err => console.log(err)
      );
  }
}

export const validateParkingPicture = (url) => {
  const config = {
    headers: {
      'Authorization': `Bearer ${
        getAccessToken()
      }`,
      'Content-Type': 'application/json'
    }
  }
  return axios.put(`${host}${url}`, {}, config);
}

export const getOrdersWithParkingPicturesToCheck = (queryParams) => {
  const config = {
    headers: {
      'Authorization': `Bearer ${getAccessToken()}`,
      'Content-Type': 'application/json'
    },
    params: queryParams
  }
  return axios.get(`${host}/order`, config);
}

export const getOrderWithParkingPictureToCheck = (path) => {
  if (path) {
    const config = {
      headers: {
        'Authorization': `Bearer ${
          getAccessToken()
        }`,
        'Content-Type': 'application/json'
      }
    }
    let url = `${host}${path}`;
    return async dispatch => {
      const request = axios.get(url, config);
      return request.then(response => {
        dispatch(setOrderWithParkingPictureToCheck(response.data));
      }, err => {
        console.log(err);
      });
    }
  } else {
    return async dispatch => {
      return dispatch(setOrderWithParkingPictureToCheck({}));
    }
  }
}

export const searchRoutes = (queryParams) =>{
  const config = {
    headers: {
      'Authorization': `Bearer ${getAccessToken()}`,
      'Content-Type': 'application/json'
    },
    params: queryParams
  }

  return async dispatch => {
    return axios.get(`${host}/order/map`, config)
      .then(
        response => dispatch(setOrderRoutes(response.data)),
        err => console.log(err)
      );
  }
}

export const setOrders = (payload) => ({
  type: ActionTypes.SET_ORDERS,
  payload: payload,
});
export const setOrder = (order) => ({
  type: ActionTypes.SET_ORDER,
  payload: order,
});
export const setOrderRoutes = (payload) => ({
  type: ActionTypes.SET_ORDER_ROUTES,
  payload: payload,
});
export const setOrderWithParkingPictureToCheck = (payload) => ({
    type: ActionTypes.SET_ORDER_WITH_PARKING_PICTURE_TO_CHECK,
    payload: payload,
  })