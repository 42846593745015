import React from "react";

class Discounting extends React.Component {
  
  render() {
    return (
      <React.Fragment>
        Discounting
      </React.Fragment>
    );
  }

}

export default Discounting;