export const getAccessToken = () => {
  const accessToken = sessionStorage.getItem('access_token');
  if (!accessToken)
    throw new Error('No access token found');
  return accessToken;
}

export const setAccessToken = (accessToken) => {
  sessionStorage.setItem('access_token', accessToken);
}

export const config = (queryParams=undefined) => {
    return {
      headers: {
        'Authorization': `Bearer ${getAccessToken()}`,
        'Content-Type': 'application/json'
      },
      params: queryParams
    };
}
