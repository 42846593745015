import React, {Component} from "react";
import {connect} from "react-redux";
import {getTaskRuleByUuid} from "../../../../redux/TaskRule/taskRule.action";
import {withAuth0} from "@auth0/auth0-react";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import {Button, Grid} from "@material-ui/core";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import moment from "moment";

import {config} from "../../../../auth/auth";
import axios from "axios";
import TaskRuleActions from "./task-rule-actions"
import TaskRuleNotifications from "./task-rule-notifications"

const datetime_format = process.env.REACT_APP_DATETIME_FORMAT;
const host = process.env.REACT_APP_HOST

class TaskRuleDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            uuid: null,
            description: "",
            type: "",
            parameter: "0",
            status: "ACTIVE",
            vehicleStatus: "NONE",
            taskCategory: "NONE",
            createdAt: "",
            updatedAt: "",
            isDirty:false
        };
    }

    componentDidMount() {
        this.refresh();
    }

    refresh = () => {

        if( this.state.creating === undefined && this.props.location.pathname.includes('new-task-rule')){
            this.setState({creating:true});
            return;
        }

        let uuid = this.state.uuid ?? this.props.location.pathname.replace("/operation-rules/task-rule/", "");
        var taskRuleDetails = this.props.getTaskRuleByUuid(uuid);
        taskRuleDetails
            .then((response) => {
                const taskRuleData = response?.payload;
                this.setState({
                    uuid: taskRuleData?.uuid,
                    name: taskRuleData?.name,
                    description: taskRuleData?.description,
                    type: taskRuleData?.type ?? "LOW_BATTERY",
                    parameter: taskRuleData?.parameter ?? "0",
                    status: taskRuleData?.status,
                    vehicleStatus: taskRuleData.vehicleStatus ?? "NONE",
                    taskCategory: taskRuleData.taskCategory ?? "NONE",
                    createdAt: moment(taskRuleData.createdAt).local().format(datetime_format),
                    updatedAt: moment(taskRuleData.updatedAt).local().format(datetime_format),
                    isDirty: false
                });
            })
            .catch((e) => alert(e))
            .finally(() => { });
    };

    saveDetails = (e) => {
        if(this.state.creating){
            this.createNewRule();
            return;
        }

        if(!this.validate_input())
            return;

        const body = {
            name: this.state.name,
            status: this.state.status,
            description: this.state.description,
            type: this.state.type,
            parameter: this.state.parameter,
            vehicleStatus: this.state.vehicleStatus === "NONE" ? null : this.state.vehicleStatus,
            taskCategory: this.state.taskCategory === "NONE" ? null : this.state.taskCategory,
        };
        return axios
            .put(`${host}/taskRule/${this.state.uuid}`, body, config())
            .then((response) => {
                this.setState({isDirty:false});
                this.refresh();
            })
            .catch((e) => {
                this.refresh();
            })
            .finally(() => {});
    };

    validate_input = () => {
        if(!this.state.name){
            alert("Please enter a name");
            return false;
        }

        if(!this.state.type){
            alert("Please select a type");
            return false;
        }

        if(this.state.type!=="OUT_OF_REGION" && this.state.parameter<=0){
            alert("Please set the parameter");
            return false;
        }

        return true;
    }

    createNewRule = () => {
        if(!this.validate_input())
            return;

        const body = {
            name: this.state.name,
            status: this.state.status,
            description: this.state.description,
            type: this.state.type,
            parameter: this.state.parameter,
            vehicleStatus: this.state.vehicleStatus === "NONE" ? null : this.state.vehicleStatus,
            taskCategory: this.state.taskCategory === "NONE" ? null : this.state.taskCategory,
        };
        return axios
            .post(`${host}/taskRule/`, body, config())
            .then((response) =>{
                const taskRuleData = response?.data;
                this.setState({
                    creating:false,
                    uuid: taskRuleData.uuid,
                    createdAt: moment(taskRuleData.createdAt).local().format(datetime_format),
                    updatedAt: moment(taskRuleData.updatedAt).local().format(datetime_format),
                    isDirty:false
                })
            }
            )
            .catch((e) => {
                this.refresh();
            })
            .finally(() => {
            });
    }

    handleNameChange = (e) => {
        this.setState({ name: e.target.value, isDirty:true });
    };

    handleDescriptionChange = (e) => {
        this.setState({ description: e.target.value, isDirty:true });
    };

    handleStatusChange = (e) => {
        this.setState({ status: e.target.value, isDirty:true });
    };

    handleTypeChange = (e) => {
        this.setState({ type: e.target.value, isDirty:true });
    };

    handlePrameterChange = (e) => {
        this.setState({ parameter: e.target.value, isDirty:true });
    };

    handleVehicleStatusChange = (e) => {
        this.setState({ vehicleStatus: e.target.value, isDirty:true });
    };

    handleTaskCategoryChange = (e) => {
        this.setState({ taskCategory: e.target.value, isDirty:true });
    };

    can_enable_save = () => {
        if(!this.state.isDirty)
            return false;

        return this.state.name && this.state.type && (this.state.type==="OUT_OF_REGION" || this.state.parameter>0);
    }

    render() {
        let showActions = "Loading";
        let showNotifications = "Loading";
        if (this.state.uuid){
            showActions = <TaskRuleActions taskRuleUuid={this.state.uuid} />;
            showNotifications = <TaskRuleNotifications taskRuleUuid={this.state.uuid} />;
        }else if(this.state.creating){
            showActions = "";
            showNotifications = "";
        }

        return (
            <React.Fragment>
                <Grid container spacing={2}>
                    <Grid item md={12} lg={8}>
                        <Card style={{ height: "100%" }}>
                            <CardHeader title="Task Rule Details" className="primary" />
                            <CardContent>
                                <Row>
                                    <Col xs={12} md={6} className="detailsFormLabel">
                                        {" "}
                                        <TextField
                                            id="uuid"
                                            label="Task Rule UUID"
                                            className=""
                                            value={this.state?.uuid}
                                            fullWidth
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            variant="outlined"
                                        />
                                    </Col>
                                    <Col xs={12} md={6} className="detailsFormLabel">
                                        <InputLabel htmlFor="status">Status</InputLabel>
                                        <Select
                                            value={this.state.status}
                                            onChange={this.handleStatusChange}
                                            inputProps={{
                                                name: 'Status',
                                                id: 'status',
                                            }}
                                        >
                                            <MenuItem value="ACTIVE">ACTIVE</MenuItem>
                                            <MenuItem value="INACTIVE">INACTIVE</MenuItem>
                                        </Select>
                                    </Col>

                                </Row>
                                <Row>
                                    <Col xs={12} md={6} className="detailsFormLabel">
                                        {" "}
                                        <TextField
                                            id="name"
                                            label="Name"
                                            className=""
                                            value={this.state?.name}
                                            fullWidth
                                            InputProps={{
                                                readOnly: false,
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            variant="outlined"
                                            onChange={this.handleNameChange}
                                        />
                                    </Col>
                                    <Col xs={12} md={6} className="detailsFormLabel">
                                        {" "}
                                        <TextField
                                            id="description"
                                            label="Description"
                                            className=""
                                            value={this.state?.description}
                                            fullWidth
                                            InputProps={{
                                                readOnly: false,
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            variant="outlined"
                                            onChange={this.handleDescriptionChange}
                                        />
                                    </Col>

                                </Row>
                                <Row>
                                    <Col xs={12} md={6} className="detailsFormLabel">
                                        {" "}
                                        <TextField
                                            id="createdAt"
                                            label="Created Time"
                                            className=""
                                            value={this.state.createdAt}
                                            fullWidth
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            variant="outlined"
                                        />
                                    </Col>
                                    <Col xs={12} md={6} className="detailsFormLabel">
                                        {" "}
                                        <TextField
                                            id="updatedAt"
                                            label="Latest update time"
                                            className=""
                                            value={this.state.updatedAt}
                                            fullWidth
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            variant="outlined"
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} md={6} className="detailsFormLabel">
                                        {" "}
                                        <InputLabel htmlFor="type">Type</InputLabel>
                                        <Select
                                            value={this.state.type}
                                            onChange={this.handleTypeChange}
                                            inputProps={{
                                                name: 'type',
                                                id: 'type',
                                            }}
                                        >
                                            <MenuItem value="BATTERY_LEVEL">Battery Level less than</MenuItem>
                                            <MenuItem value="LAST_USED">Unused Days</MenuItem>
                                            <MenuItem value="OUT_OF_REGION">Out of Region</MenuItem>
                                            <MenuItem value="ALARM">Vehicle Alarm Type</MenuItem>
                                        </Select>
                                    </Col>
                                    <Col xs={12} md={6} className="detailsFormLabel">
                                        {" "}
                                        <TextField
                                            id="parameter"
                                            label="Parameter"
                                            className=""
                                            value={this.state.parameter}
                                            disabled = {this.state.type==="OUT_OF_REGION"}
                                            fullWidth
                                            type="number"
                                            InputProps={{
                                                readOnly: false,
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            variant="outlined"
                                            onChange={this.handlePrameterChange}
                                        />
                                    </Col>

                                </Row>
                                <Row
                                    style={{ justifyContent: "flex-end", marginRight: "auto" }}
                                >
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        disabled={!this.can_enable_save()}
                                        onClick={() => this.saveDetails()}
                                    >Save</Button>
                                </Row>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item md={12} lg={4}>
                        <Card style={{ height: "100%" }}>
                            <CardHeader title="Help" className="primary" />
                            <CardContent>
                                <h3>About the delays in action</h3>
                                The delays in action is always counted from the time the rule is being triggered.
                                If you define multiple actions with delays, you need to increase the delay based on the prevoius action.
                                e.g.
                                <br/><br/>
                                <li>ALARM, delay 0</li>
                                <li>SET_SPEED_LOW, delay 10</li>
                                <li>LOCK, delay 20</li>
                                <br/>
                                The actions will be exectued with 10 seconds gap inbetween.
                                <br/><br/>
                                <h4>Vehicle Alarm Type</h4>
                                <li>1: Illegal Movement</li>
                                <li>2: Illegal Removal</li>
                                <li>3: Falldown</li>
                                <li>6: Broken Down</li>
                                <li>7: Battery Removed</li>
                                <br/>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>

                <Grid container spacing={2}>
                    <Grid item md={12} lg={6}>
                        <Card style={{ height: "100%" }}>
                            <CardHeader title="Vehicle Status" className="primary" />
                            <CardContent>
                                    {" "}
                                        <InputLabel htmlFor="vehicleStatus">Change Vehicle Status To</InputLabel>
                                        <Select
                                            value={this.state.vehicleStatus}
                                            onChange={this.handleVehicleStatusChange}
                                            inputProps={{
                                                name: 'vehicleStatus',
                                                id: 'vehicleStatus',
                                            }}
                                        >
                                            <MenuItem value="NONE">Don't change status</MenuItem>
                                            <MenuItem value="REPORTED">Reported</MenuItem>
                                            <MenuItem value="MAINTENANCE_PENDING">Maintenance Pending</MenuItem>
                                            <MenuItem value="DAMAGED">Damaged</MenuItem>
                                            <MenuItem value="LOST">Lost</MenuItem>
                                        </Select>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item md={12} lg={6}>
                        <Card style={{ height: "100%" }}>
                            <CardHeader title="Task" className="primary" />
                            <CardContent>
                                {" "}
                                        <InputLabel htmlFor="taskCategory">Create a Task for</InputLabel>
                                        <Select
                                            value={this.state.taskCategory}
                                            onChange={this.handleTaskCategoryChange}
                                            inputProps={{
                                                name: 'taskCategory',
                                                id: 'taskCategory',
                                            }}
                                        >
                                            <MenuItem value="NONE">Don't create task</MenuItem>
                                            <MenuItem value="DISTRIBUTION">Distribution</MenuItem>
                                            <MenuItem value="COLLECTION">Collection</MenuItem>
                                            <MenuItem value="REPAIR">Repairing</MenuItem>
                                            <MenuItem value="LOW_BATTERY">Battery Recharging</MenuItem>
                                            <MenuItem value="COVID_CLEANING">COVID Cleaning</MenuItem>
                                        </Select>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item md={12} lg={6}>
                        <Card style={{ height: "100%" }}>
                            <CardHeader title="Vehicle Actions" className="primary" />
                            <CardContent>
                                {showActions}
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item md={12} lg={6}>
                        <Card style={{ height: "100%" }}>
                            <CardHeader title="Notifications" className="primary" />
                            <CardContent>
                                {showNotifications}
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>

            </React.Fragment>
        );
    }

}


const mapStateToProps = (state) => ({
    taskRule: state.taskRuleReducer.taskRule
});

const mapDispatchToProps = (dispatch) => ({
    getTaskRuleByUuid: (uuid) => dispatch(getTaskRuleByUuid(uuid))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withAuth0(TaskRuleDetails));
