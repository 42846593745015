import React from 'react';
import {
  DialogTitle,
  FormGroup,
} from "@material-ui/core";
import {InputTextField, NumberField} from "../../../../components/Form/FormElements";

const DiscountCodeModal = () => {

  return (
    <React.Fragment>
      <DialogTitle
        id="User Action Modal"
        style={{
          color: "white",
          backgroundColor: "#333399",
          textAlign: "center",
        }}
      >
        <label>Generate Code</label>
      </DialogTitle>
      <FormGroup row
                 style={{
                   justifyContent: "space-between",
                   padding: "10px 40px 15px 40px",
                 }}>
        <InputTextField
          name="code"
          label="Enter 8 letters"
          style={{width: '100%'}}
          upperCase
        />
      </FormGroup>
    </React.Fragment>
  );
};

export default DiscountCodeModal;