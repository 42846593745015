import React from "react";
import PropTypes from 'prop-types';

const VEHICLE_CATEGORY = {
  BIKE:"Bike",
  EBIKE:"E-Bike",
  EKICKSCOOTER:"E-Kickscooter",
  EMOPED:"E-Moped",
  ECAR:"E-Car",
}


class VehiclesSupported extends React.Component {
  static propTypes = {
    value: PropTypes.object.isRequired
  };

  render() {
    const { value } = this.props;
    if (!value)
      return (<React.Fragment/>);


    const vehiclesSupported = value.map(e => VEHICLE_CATEGORY[`${e}`]).join(' / ');

    return (
      <React.Fragment>
        {vehiclesSupported}
      </React.Fragment>
    );
  }
}

export default VehiclesSupported;

