import React from "react";
import {Marker} from "react-leaflet";
import PropTypes from "prop-types";
import TaskPopup from "./TaskPopup";
import {Icon} from "leaflet";

const normalIcon = {
  EBIKE: "/icon/available-ebike.svg",
  EKICKSCOOTER: "/icon/available-kickscooter.svg",
  EMOPED: "/icon/available-moped.svg",
  BIKE: "/icon/available-bike.svg",
}

const damagedIcon = {
  EBIKE: "/icon/damaged-ebike.svg",
  EKICKSCOOTER: "/icon/damaged-kickscooter.svg",
  EMOPED: "/icon/damaged-moped.svg",
  BIKE: "/icon/damaged-bike.svg",
}

const lostIcon = {
  EBIKE: "/icon/lost-ebike.svg",
  EKICKSCOOTER: "/icon/lost-kickscooter.svg",
  EMOPED: "/icon/lost-moped.svg",
  BIKE: "/icon/lost-bike.svg",
}

const lowBatteryIcon = {
  EBIKE: "/icon/low-battery-ebike.svg",
  EKICKSCOOTER: "/icon/low-battery-kickscooter.svg",
  EMOPED: "/icon/low-battery-moped.svg",
  BIKE: "/icon/low-battery-bike.svg",
}

const maintenancePendingIcon = {
  EBIKE: "/icon/maintenance-pending-bike.svg",
  EKICKSCOOTER: "/icon/maintenance-pending-kickscooter.svg",
  EMOPED: "/icon/maintenance-pending-moped.svg",
  BIKE: "/icon/maintenance-pending-bike.svg",
}

const maintainingIcon = {
  EBIKE: "/icon/maintaining-ebike.svg",
  EKICKSCOOTER: "/icon/maintaining-kickscooter.svg",
  EMOPED: "/icon/maintaining-moped.svg",
  BIKE: "/icon/maintaining-bike.svg",
}

const reportedIcon = {
  EBIKE: "/icon/reported-ebike.svg",
  EKICKSCOOTER: "/icon/reported-kickscooter.svg",
  EMOPED: "/icon/reported-moped.svg",
  BIKE: "/icon/reported-bike.svg",
}

const reservedIcon = {
  EBIKE: "/icon/reserved-ebike.svg",
  EKICKSCOOTER: "/icon/reserved-kickscooter.svg",
  EMOPED: "/icon/reserved-moped.svg",
  BIKE: "/icon/reserved-bike.svg",
}

const runningIcon = {
  EBIKE: "/icon/running-ebike.svg",
  EKICKSCOOTER: "/icon/running-kickscooter.svg",
  EMOPED: "/icon/running-moped.svg",
  BIKE: "/icon/running-bike.svg",
}



class TaskMarker extends React.Component {
  static propTypes = {
    vehicle: PropTypes.object.isRequired,
  };
  onSuccessTaskCreation() {
    const { callback } = this.props;
    callback();
  }

  render() {
    const { vehicle } = this.props;

    if (!vehicle.location?.lat || !vehicle.location?.lng) {
      console.error("Error position", vehicle?.location);
      return <React.Fragment />;
    }

    const position = [vehicle?.location?.lat, vehicle?.location?.lng];

    const determineVehicleIconUrl = (vehicle) => {
      return vehicle?.status === 'AVAILABLE' ? normalIcon[`${vehicle?.category}`]
            :vehicle?.status === 'RUNNING' ? runningIcon[`${vehicle?.category}`]
            :vehicle?.status === 'RESERVED' ? reservedIcon[`${vehicle?.category}`]
            :vehicle?.status === 'REPORTED' ? reportedIcon[`${vehicle?.category}`]
            :vehicle?.status === 'MAINTAINING' ? maintainingIcon[`${vehicle?.category}`]
            :vehicle?.status === 'MAINTENANCE_PENDING' ? maintenancePendingIcon[`${vehicle?.category}`]
            :vehicle?.status === 'DAMAGED' ? damagedIcon[`${vehicle?.category}`]
            :vehicle?.status === 'LOST' ? lostIcon[`${vehicle?.category}`]
            :lowBatteryIcon;  
    };

    const iconUrl = determineVehicleIconUrl(vehicle);

    const icon = new Icon({
      iconUrl: `${iconUrl}`,
      iconSize: [25, 25],
    });

    return (
      <Marker
        key={`marker-key-${vehicle?.uuid}`}
        position={position}
        icon={icon}
      >
        <TaskPopup
          callback={() => this.onSuccessTaskCreation()}
          vehicle={vehicle}
        />
      </Marker>
    );
  }
}

export default TaskMarker;
