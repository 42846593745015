import React, {useEffect, useState} from 'react';
import {Card, DialogContent, DialogTitle, Grid} from "@material-ui/core";
import {Form, Formik} from "formik";
import ConfirmationModal from "../../../Modal/ConfirmationModal";
import {connect} from "react-redux";
import {withAuth0} from "@auth0/auth0-react";
import {regionSearch} from "../../../../redux/region/action";
import Typography from "@material-ui/core/Typography";
import * as Yup from "yup";
import {SelectField} from "../../../Form/FormElements";
import FormNavigation from "../../../Form/MultistepForm/FormNavigation";
import axios from "axios";
import {config} from "../../../../auth/auth";

const host = process.env.REACT_APP_HOST;

const initialValues = {
  alternativeRegion: ''
}

const validationSchema = Yup.object({
  alternativeRegion: Yup.string().required().label("Alternative Region "),
});

const RegionDeleteModal = ({region, regionSearch, regions, onCancel, callback}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false, title: '', subTitle: '', onClick: () => {
    }
  })

  useEffect(() => {
    let params = new URLSearchParams();
    params.set("page", "0");
    params.set("size", "1000");
    params.set("cityUuid", region?.cityUuid)
    regionSearch(params);
  }, []);

  const alternativeRegions = [];

  regions?.content?.forEach((reg) => {
    alternativeRegions.push({
      label: reg.regionName,
      description: reg.regionName,
      value: reg.uuid
    })
  });

  const handleSubmit = (values) => {
    const alternativeRegion = alternativeRegions.find(regions => regions.value === values.alternativeRegion);
    setConfirmDialog({
      isOpen: true,
      title: `Are you sure to delete the region: ${region.regionName}?`,
      subTitle: `Vehicles will be transferred to:   ${alternativeRegion?.description}`,
      onClick: () => handleDeleteRegion(region.uuid, values.alternativeRegion),
    })
  }

  const handleDeleteRegion = async (uuid, alternativeRegionUuid) => {
    setIsLoading(true);
     await axios
      .delete(`${host}/region/${uuid}?alternativeRegionUuid=${alternativeRegionUuid}`, config())
      .catch((e) => alert(e));

    callback();
    onCancel(false);
    setIsLoading(false);
  }

  return (
    <Card style={{borderRadius: '1rem'}}>
      <DialogTitle
        id="Users Action Modal"
        style={{
          color: "white",
          backgroundColor: "#333399",
          textAlign: "left",
        }}
      >
        <label>Delete Region</label>
      </DialogTitle>
      <DialogContent style={{textAlign: "center"}}>
        <Typography variant="h6" className="p-3">
          Please select one of the regions below where your vehicles will be transferred.
        </Typography>
      </DialogContent>
      <div className="card-body">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values) => handleSubmit(values)}>
          {(formik) => (
            <Form>
              <Grid item sm={12} xs={12} md={12} lg={12}>
                <Grid container spacing={2} className="formLabel px-3" justify="center">
                  <Grid item sm={12} xs={12} md={12} lg={12}>
                    <SelectField name="alternativeRegion" label="Alternative Region" options={alternativeRegions}/>
                  </Grid>
                </Grid>
              </Grid>
              <FormNavigation onCancel={() => onCancel(false)}/>
            </Form>
          )}
        </Formik>
      </div>
      <ConfirmationModal
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
        isLoading={isLoading}
      />
    </Card>
  );
};

const mapStateToProps = (state) => ({
  regions: state.regionReducer.regions,
});

const mapDispatchToProps = (dispatch) => ({
  regionSearch: (params) => dispatch(regionSearch(params)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withAuth0(RegionDeleteModal));
