import React from "react";
import PropTypes from "prop-types";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid,} from "@material-ui/core";

import axios from "axios";
import {config} from "../../../../auth/auth";
import {grey} from "@material-ui/core/colors";
import CardContent from "@material-ui/core/CardContent";
import TextField from "@material-ui/core/TextField";
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";

const host = process.env.REACT_APP_HOST

class UserActionModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      amount: 0,
      subject: "",
      content: "",
      open: this.props.open,
      configurations: config(),
      errorMessage: undefined
    };
  }

  static propTypes = {
    callback: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
  };

  cancel = () => {
    this.setState({
      amount: 0,
      open: false,
      subject: undefined,
      content: undefined,
    });
    this.props.onCloseModal();
  };

  confirm = (callback) => {
    const {action, userId} = this.props;
    let body = {
      amount: this.state.amount,
      userUuid: userId
    };
    let url = "";
    switch (action) {
      case "TopUp":
        url = `${host}/payment/topup`;
        break;
      case "penality":
        url = `${host}/payment/penalty`;
        break;
      case "Notification":
        url = `${host}/user/${userId}/sendMessageToUser`;
        body = {
          "title": this.state.subject,
          "body": this.state.content
        };
        let configurations = Object.assign({}, this.state.configurations)
        configurations.headers["Content-Type"] = "application/json"
        this.setState(configurations)
        break;
      case "delete":
        url = `${host}/user/${userId}/confirm-delete`;
        break;
      default:
        break;
    }
    if(action === "delete"){
      return axios
        .put(url, {}, this.state.configurations)
        .then(response => callback())
        .catch((e) => alert(e.response.data.details[0]))
        .finally(() => {
          this.setState({
            amount: 0,
            open: false,
            subject: "",
            content: "",
            errorMessage: ""
          });
          this.props.onCloseModal();
        });
    } else {
      return axios
        .post(url, body, this.state.configurations)
        .then((response) => callback())
        .catch((e) => alert(e.response.data.details[0]))
        .finally(() => {
          this.setState({
            amount: 0,
            open: false,
            subject: "",
            content: "",
            errorMessage: ""
          });
          this.props.onCloseModal();
        });
      }
  };

  onChangeAmount = (event,action) => {
    const value = event.target.value;
    this.setState({amount: value});
    if (value === null || value === undefined || value === "") {
      this.setState({errorMessage: "Required"});
    }else if (value <= 0) {
      this.setState({errorMessage: "Must be greater than 0"});
    } else if(action === "TopUp" && value > 1000){
      this.setState({errorMessage: "Must be less than 1000"});
    } else {
      this.setState({errorMessage: undefined});
    }
  };
  onChangeSubject = (e) => {
    this.setState({subject: e.target.value});
  };
  onChangeContent = (e) => {
    this.setState({content: e.target.value});
  };

  // end

  componentDidUpdate(prevProps) {
    if (this.props?.open !== prevProps.open) {
      this.setState({open: this.props?.open});
    }
  }

  render() {
    const {callback, action, userId} = this.props;
    let actionHeading = "";
    if (action === "TopUp") {
      actionHeading = "Top Up the Users";
    } else if (action === "penality") {
      actionHeading = "Penalise the Users";
    } else if (action === "Notification") {
      actionHeading = "Notify the Users";
    } else if (action === "delete") {
      actionHeading = "Delete the Users";
    }
    if (!userId) return <React.Fragment/>;
    if (action === "delete") {
      return (
        <React.Fragment>
            <Dialog
              open={this.state.open}
              onClose={this.handleClose}
              aria-labelledby="User Action Modal"
              fullWidth={true}
            >
              <DialogTitle
                id="User Action Modal"
                style={{
                  color: "white",
                  backgroundColor: "#36b9cc",
                  textAlign: "center",
                }}
              >
                {actionHeading}
              </DialogTitle>
              <DialogContent>
                <Grid container spacing={2}>
                  <Grid item sm={12} xs={12} md={12} lg={12}>
                    <CardContent style={{paddingBottom: '0px', font: 'Poppins'}}>
                      <Grid container spacing={2} className="formLabel">
                        Are you sure you want to delete this user?
                      </Grid>
                      <Grid container spacing={2} className="formLabel" style={{color:'#FF0404'}}>
                        This user account will be deleted permanently and can not be retrieved. 
                      </Grid>
                      <Grid container spacing={2} className="formLabel">
                        Please make sure to refund all remaining balance in the account before deleting.
                      </Grid>
                      <Grid container spacing={2} className="formLabel">
                        <FormGroup>
                          <FormControl>
                            <FormControlLabel
                              control={
                                <Checkbox color="black" required />
                              }
                              label="User’s remaining balance is refunded on the Console"
                            />
                          </FormControl>
                          <FormControl>
                            <FormControlLabel
                              control={
                                <Checkbox color="black" required />
                              }
                              label="User’s remaining balance is refunded via cash/bank transfer"
                            />
                          </FormControl>
                        </FormGroup>
                      </Grid>
                      <Grid container spacing={2} className="formLabel" style={{color:'#FF0404'}}>
                        Please note it is your responsibility to process the refund.
                      </Grid>
                      <Grid container spacing={2} className="formLabel">
                      </Grid>
                    </CardContent>
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions
                style={{
                  justifyContent: "space-between",
                  padding: "10px 40px 15px 40px",
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => this.confirm(callback)}
                >
                  Accept and Delete
                </Button>
                <Button onClick={this.cancel} style={{color: grey[500]}}>
                  Cancel
                </Button>
              </DialogActions>
            </Dialog>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <Dialog
            open={this.state.open}
            onClose={this.handleClose}
            aria-labelledby="User Action Modal"
            fullWidth={true}
          >
            <DialogTitle
              id="User Action Modal"
              style={{
                color: "white",
                backgroundColor: "#36b9cc",
                textAlign: "center",
              }}
            >
              {actionHeading}
            </DialogTitle>
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item sm={12} xs={12} md={12} lg={12}>
                  <CardContent style={{paddingBottom: '0px'}}>
                    <Grid container spacing={2} className="formLabel">
                      <Grid
                        item
                        sm={12} xs={12} md={12} lg={12}
                        style={{
                          display:
                            action === "TopUp" || action === "penality"
                              ? "block"
                              : "none",
                        }}
                      >
                        <TextField
                          error={this.state?.errorMessage}
                          helperText={this.state?.errorMessage ?? ""}
                          id="amount-id"
                          label="Amount"
                          fullWidth
                          type="number"
                          value={this.state.amount}
                          onChange={(e) => this.onChangeAmount(e,action)}
                          InputProps={{
                            readOnly: false,
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} className="formLabel">
                      <Grid
                        item
                        sm={12} xs={12} md={12} lg={12}
                        style={{
                          display:
                            action !== "TopUp" && action !== "penality"
                              ? "block"
                              : "none",
                        }}
                      >
                        <TextField
                          id="subject-id"
                          label="Subject"
                          fullWidth
                          value={this.state.subject}
                          onChange={this.onChangeSubject}
                          InputProps={{
                            readOnly: false,
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} className="formLabel">
                      <Grid
                        item
                        sm={12} xs={12} md={12} lg={12}
                        style={{
                          display:
                            action !== "TopUp" && action !== "penality"
                              ? "block"
                              : "none",
                        }}
                      >
                        <TextField
                          id="content-id"
                          label="Content"
                          fullWidth
                          value={this.state.content}
                          InputProps={{
                            readOnly: false,
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          variant="outlined"
                          onChange={this.onChangeContent}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions
              style={{
                justifyContent: "space-between",
                padding: "10px 40px 15px 40px",
              }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={() => this.confirm(callback)}
              >
                confirm
              </Button>
              <Button onClick={this.cancel} style={{color: grey[500]}}>
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
        </React.Fragment>
      );
    }
  }
}

export default UserActionModal;
