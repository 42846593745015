import React from 'react';
import PointOfInterestConfigurationMap from "./PointOfInterestConfigurationMap";

const PointOfInterest = () => {
  return (
    <React.Fragment>
      <h1 className="h3 mb-2 text-gray-800">Point of Interest Configuration</h1>
      <p>To create a visible POI on the user app, put the pin on this map and set up the promotion.</p>
      <div className="row">
        <PointOfInterestConfigurationMap/>
      </div>
    </React.Fragment>
  );
};

export default PointOfInterest;