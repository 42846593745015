import React from "react";
import PropTypes from 'prop-types';
import {Avatar, Tooltip} from "@material-ui/core";
import LocationCityIcon from '@material-ui/icons/LocationCity';

class BusinessLogo extends React.Component {
  static propTypes = {
    logo: PropTypes.string.isRequired
  };

  render() {
    const { logo } = this.props;

    return (
      <React.Fragment>
        <Tooltip title="Business logo">
          {logo ?
            <Avatar alt="Business logo" src={logo} />
            :
            <Avatar> <LocationCityIcon/> </Avatar>
          }
        </Tooltip>
      </React.Fragment>
    );
  }
}

export default BusinessLogo;