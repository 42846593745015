import React from 'react';
import {DialogTitle, Grid} from "@material-ui/core";
import {SelectField} from "../../../../components/Form/FormElements";


const VOUCHER_TYPE = [
  {type: "NORMAL", description: "Normal", value: "SYSTEM_TOPUP"},
  {type: "DISCOUNT", description: "Discount", value: "DISCOUNT"}];


const VoucherTypeModal = ({}) => {

  return (
    <React.Fragment>
        <DialogTitle
          id="User Action Modal"
          style={{
            color: "white",
            backgroundColor: "#333399",
            textAlign: "center",
          }}
        >
          <label>Create New</label>
        </DialogTitle>
          <Grid item sm={12} xs={12} md={12} lg={12}>
              <Grid container spacing={2} className="formLabel px-3">
                <Grid item sm={12} xs={12} md={12} lg={12}>
                      <SelectField name="type" label="Voucher Type" options={VOUCHER_TYPE}/>
                </Grid>
              </Grid>
          </Grid>
    </React.Fragment>
  );
};

export default VoucherTypeModal;