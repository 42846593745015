import React from 'react';
import {DialogTitle, Grid} from "@material-ui/core";
import {
  CheckboxField,
  CustomSelect,
  DatePickerField,
  NumberField,
  SelectField
} from "../../../../components/Form/FormElements";

const DISCOUNT_TYPE = [
  {type: "RIDE", description: "Ride discount", value: "ORDER"},
  {type: "TOPUP", description: "Top-up discount", value: "TOPUP_TEMPLATE"}];

const DISCOUNT_RATE = [
  {type: "5", description: "5%", value: 0.05},
  {type: "10", description: "10%", value: 0.10},
  {type: "15", description: "15%", value: 0.15},
  {type: "20", description: "20%", value: 0.20},
  {type: "25", description: "25%", value: 0.25},
  {type: "30", description: "30%", value: 0.30},
  {type: "35", description: "35%", value: 0.35},
  {type: "40", description: "40%", value: 0.40},
  {type: "45", description: "45%", value: 0.45},
  {type: "50", description: "50%", value: 0.50},
  {type: "55", description: "55%", value: 0.55},
  {type: "60", description: "60%", value: 0.60},
  {type: "65", description: "65%", value: 0.65},
  {type: "70", description: "70%", value: 0.70},
  {type: "75", description: "75%", value: 0.75},
  {type: "80", description: "80%", value: 0.80},
  {type: "85", description: "85%", value: 0.85},
  {type: "90", description: "90%", value: 0.90},
  {type: "95", description: "95%", value: 0.95},
  {type: "100", description: "100%", value: 1.00}];


const DiscountModal = ({cities}) => {

  return (
    <React.Fragment>
      <DialogTitle
        id="User Action Modal"
        style={{
          color: "white",
          backgroundColor: "#333399",
          textAlign: "center",
        }}
      >
        <label>Create Discount</label>
      </DialogTitle>
      <Grid container spacing={2} className="formLabel px-3">
        <Grid item xs={12}>
          <CustomSelect name="citiesUuid" options={cities} label="Cities"/>
        </Grid>
        <Grid item xs={12}>
          <SelectField name="discountType" label="Discount Type" options={DISCOUNT_TYPE}/>
        </Grid>
        <Grid item xs={12}>
          <SelectField name="discountValue" label="Discount Rate" options={DISCOUNT_RATE}/>
        </Grid>
        <Grid item xs={12}>
          <CheckboxField readOnly={false} name="reusable" label="Reusable"/>
        </Grid>
        <Grid item xs={12}>
          <NumberField readOnly={false} name="quantity" label="Quantity"/>
        </Grid>
        <Grid item xs={12}>
          <DatePickerField name="startAt" label="From" readOnly={false}/>
        </Grid>
        <Grid item xs={12}>
          <DatePickerField name="endAt" label="To" readOnly={false}/>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default DiscountModal;