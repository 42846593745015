import React from "react";
import { useState } from 'react';
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import {LayerGroup, LayersControl, MapContainer, TileLayer,} from "react-leaflet";
import "./index.css";
import {FullScreen, useFullScreenHandle} from "react-full-screen";
import {faExpandArrowsAlt} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import "react-leaflet-fullscreen/dist/styles.css";
import {vehiclesGroupByStatus} from "../../../utils/utils";
import VehicleMarker from "./VehicleMarker";
import VehicleRegions from "./VehicleRegions";
import { useTranslation } from 'react-i18next';
import Legend from "./Legend";

export default function VehicleMap(props) {
  const vehicles = props.vehicles;
  const regions = props.regions;
  const position = [22.402795,39.078354];
  const handle = useFullScreenHandle();
  const vehiclesGroupByCategory_Status = vehiclesGroupByStatus(vehicles, "status");
  const vehicleCategories = [
    "Available Bike",
    "Bike in Use",
    "Bike Under Maintenance",
    "Bike Reported",
    "Available E-Kickscooter",
    "E-Kickscooter in Use",
    "E-Kickscooter Under Maintenance",
    "E-Kickscooter Reported"
  ];
  const { t, i18n } = useTranslation();
  const [map, setMap] = useState(null);



  return (
    <div className="col-xl col-lg">
      <div className="card shadow mb-4">
        <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
          <h6 className="m-0 font-weight-bold" style={{color: '#333399'}}>{t('vehicle-live-map.title')}</h6>
          <div style={{ padding: "4px" }}>
            <Tooltip title="Full Screen">
              <Button onClick={handle.enter}>
                <FontAwesomeIcon
                  icon={faExpandArrowsAlt}
                  className="fa-2x text-gray-300"
                />
              </Button>
            </Tooltip>
          </div>
        </div>
        <div className="card-body">
          <FullScreen handle={handle}>
            <MapContainer
              center={position}
              zoom={13}
              scrollWheelZoom={true}
              style={{ height: "100vh" }}
              whenCreated={setMap}
            >
              <LayersControl position="topright">
                <LayersControl.BaseLayer checked name="Black And White">
                  <TileLayer
                    attribution='Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> Imagery © <a href="https://www.mapbox.com/">Mapbox</a>'
                    url="https://api.mapbox.com/styles/v1/mapbox/dark-v10/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoidHJhY2RldmVsb3BlciIsImEiOiJja2szOGJteHMxOGxhMm9wYzFhbmgwazkyIn0._UOy-teYfb2kzsEMdr8YkA"
                  />
                </LayersControl.BaseLayer>
                <LayersControl.BaseLayer name="Normal Map">
                  <TileLayer
                    attribution='Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> Imagery © <a href="https://www.mapbox.com/">Mapbox</a>'
                    url="https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoidHJhY2RldmVsb3BlciIsImEiOiJja2szOGJteHMxOGxhMm9wYzFhbmgwazkyIn0._UOy-teYfb2kzsEMdr8YkA"
                  />
                </LayersControl.BaseLayer>
                <LayersControl.BaseLayer name="Satellite Map">
                  <TileLayer
                    attribution='Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> Imagery © <a href="https://www.mapbox.com/">Mapbox</a>'
                    url="https://api.mapbox.com/styles/v1/mapbox/satellite-v9/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoidHJhY2RldmVsb3BlciIsImEiOiJja2szOGJteHMxOGxhMm9wYzFhbmgwazkyIn0._UOy-teYfb2kzsEMdr8YkA"
                  />
                </LayersControl.BaseLayer>

                {vehicleCategories?.map((category) => (
                  <LayersControl.Overlay
                    checked
                    name={category}
                    key={`vehicle-map-layer-control-overlay-key-${category}`}
                  >
                    <LayerGroup>
                      {vehiclesGroupByCategory_Status[`${category}`]?.map(
                        (vehicle) => (
                          <VehicleMarker
                            vehicle={vehicle}
                            key={`vehicle-map-marker-key-${vehicle?.uuid}`}
                          />
                        )
                      )}
                    </LayerGroup>
                  </LayersControl.Overlay>
                ))}
              </LayersControl>
              <VehicleRegions regions={regions}></VehicleRegions>
              <Legend map={map} />
            </MapContainer>
          </FullScreen>
        </div>
      </div>
    </div>
  );
}
