import React from "react";
import PropTypes from "prop-types";
import {Button, Dialog, DialogActions, DialogTitle, Grid, IconButton, MenuItem, Tooltip,} from "@material-ui/core";
import axios from "axios";
import {config} from "../../../auth/auth";
import {grey} from "@material-ui/core/colors";
import CardContent from "@material-ui/core/CardContent";
import TextField from "@material-ui/core/TextField";
import AddIcon from "@material-ui/icons/Add";
import currencyList from "../../../data/currency-list.json";

const host = process.env.REACT_APP_HOST;
const ALL_CITIES = "All Cities";

class MembershipCreate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      openModal: false,
      membershipName: "",
      description: "",
      currency: "",
      membershipPrice: 0,
      membershipPeriod: 1,
      membershipPeriodUnit: "DAY",
      cityUuid: ""
    };
  }

  static propTypes = {
    callback: PropTypes.func.isRequired,
    membershipName: PropTypes.string.isRequired,
    currency: PropTypes.string.isRequired,
    membeshipPrice: PropTypes.number.isRequired,
    membershipPeriod: PropTypes.number.isRequired,
    membershipPeriodUnit: PropTypes.string.isRequired
  };

  cancel = () => {
    this.setState({
      isLoading: false,
      openModal: false,
      membershipName: "",
      description: "",
      currency: "",
      membershipPrice: 0,
      membershipPeriod: 1,
      membershipPeriodUnit: "DAY",
      cityUuid: ""
    });
  };

  createMembership = (callback) => {
    const body = {
      name: this.state.membershipName,
      description: this.state.description,
      currency: this.state.currency,
      price: this.state.membershipPrice,
      period: this.state.membershipPeriod,
      periodUnit: this.state.membershipPeriodUnit,
      cityUuid: this.state.cityUuid === ALL_CITIES ? "" : this.state.cityUuid,
      status: "ACTIVE"
    };
    return axios
      .post(`${host}/membership/`, body, config())
      .then((response) => callback())
      .catch((e) => alert(e))
      .finally(() => {
        this.cancel();
      });
  };

  onCreateMembership = () => {
    this.setState({
      openModal: true,
    });
  };

  onChangeMembershipName = (e) => {
    this.setState({membershipName: e.target.value});
  };
  onChangeDescription = (e) => {
    this.setState({description: e.target.value});
  };
  onChangeCurrency = (e) => {
    this.setState({currency: e.target.value});
  };
  onChangeMembershipPrice = (e) => {
    this.setState({membershipPrice: parseFloat(e.target.value)});
  };
  onChangeMembershipPeriod = (e) => {
    this.setState({membershipPeriod: parseInt(e.target.value)});
  };
  onChangeMembershipPeriodUnit = (e) => {
    this.setState({membershipPeriodUnit: e.target.value});
  };
  onChangeCityUuid = (e) => {
    this.setState({cityUuid: e.target.value});
  };

  // end

  render() {
    const {callback, cities} = this.props;

    if (!cities) {
      return <React.Fragment/>;
    }

    return (
      <React.Fragment>
        <Tooltip title="Create New Membership">
          <IconButton
            variant="contained"
            color="primary"
            onClick={() => this.onCreateMembership()}
          >
            <AddIcon/>
          </IconButton>
        </Tooltip>
        <Dialog
          open={this.state.openModal}
          onClose={this.handleClose}
          aria-labelledby="User Action Modal"
          fullWidth={true}
        >
          <DialogTitle
            id="User Action Modal"
            style={{
              color: "white",
              backgroundColor: "#333399",
              textAlign: "center",
            }}
          >
            <label>Add New Membership</label>
          </DialogTitle>
          <Grid container spacing={2}>
            <Grid item sm={12} xs={12} md={12} lg={12}>
              <CardContent style={{paddingBottom: "0px"}}>

                {/* membership name */}
                <Grid container spacing={2} className="formLabel">
                  <Grid item xs={12}>
                    <TextField
                      id="membership-name-id"
                      label="Membership Name" fullWidth
                      value={this.state.membershipName}
                      onChange={this.onChangeMembershipName}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                    />
                  </Grid>
                </Grid>

                {/* membership description */}
                <Grid container spacing={2} className="formLabel">
                  <Grid item xs={12}>
                    <TextField
                      id="membership-name-id"
                      label="Membership Description" fullWidth
                      value={this.state.description}
                      onChange={this.onChangeDescription}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                    />
                  </Grid>
                </Grid>

                {/* membership city Uuid */}
                <Grid container spacing={2} className="formLabel">
                  <Grid item md={12} lg={12}>
                    <TextField
                      id="membership-create-city-id"
                      select
                      label="City"
                      value={this.state.cityUuid}
                      fullWidth
                      onChange={this.onChangeCityUuid}
                      InputProps={{
                        readOnly: false,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                    >
                      <MenuItem key={"allcity"} value={ALL_CITIES}>All Cities</MenuItem>
                      {cities.content.map((city, index) => (
                        <MenuItem key={index} value={city.uuid}>
                          {city.cityName}
                        </MenuItem>
                      ))}

                    </TextField>
                  </Grid>
                </Grid>

                {/* membership period */}
                <Grid container spacing={2} className="formLabel">
                  <Grid item xs={12}>
                    <TextField
                      id="membership-period-id"
                      label="Membership Period"
                      fullWidth
                      type="number"
                      value={this.state.membershipPeriod}
                      onChange={this.onChangeMembershipPeriod}
                      InputProps={{
                        readOnly: false,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                    />
                  </Grid>
                </Grid>

                {/* membership period unit */}
                <Grid container spacing={2} className="formLabel">
                  <Grid item xs={12}>
                    <TextField
                      id="membership-period-unit-id"
                      label="Membership Period Unit"
                      name="Membership Period Unit"
                      fullWidth
                      value={this.state.membershipPeriodUnit}
                      onChange={this.onChangeMembershipPeriodUnit}
                      InputProps={{
                        readOnly: false,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      select
                    >
                      <MenuItem value="DAY">Day</MenuItem>
                      <MenuItem value="WEEK">Week</MenuItem>
                    </TextField>
                  </Grid>
                </Grid>

                {/* membership price */}
                <Grid container spacing={2} className="formLabel">
                  <Grid item xs={12}>
                    <TextField
                      id="membership-price-id"
                      label="Membership Price"
                      type="number"
                      fullWidth
                      value={this.state.membershipPrice}
                      onChange={this.onChangeMembershipPrice}
                      InputProps={{
                        readOnly: false,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                    />
                  </Grid>
                </Grid>

                {/* currency */}
                <Grid container spacing={2} className="formLabel">
                  <Grid item xs={12}>
                    <TextField
                      id="membership-currency-id"
                      label="Currency"
                      name="Currency"
                      fullWidth
                      value={this.state.currency}
                      onChange={this.onChangeCurrency}
                      InputProps={{
                        readOnly: false,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      select
                    >
                      {currencyList.map((currency, index) => (
                        <MenuItem key={index} value={currency.name}>
                          {currency.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                </Grid>
              </CardContent>
            </Grid>
          </Grid>

          <DialogActions
            style={{
              justifyContent: "space-between",
              padding: "10px 40px 15px 40px",
            }}
          >
            <Button
              style={{color: "white", backgroundColor: "#333399"}}
              onClick={() => this.createMembership(callback)}
            >
              Save
            </Button>
            <Button
              variant="outlined"
              color="primary"
              onClick={this.cancel} style={{color: grey[500]}}>
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

export default MembershipCreate;
