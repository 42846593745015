import React, {useEffect, useState} from 'react';
import {Modal} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import {Form, Formik} from "formik";
import {CheckboxField, InputTextField, NumberField, SelectField} from "../../../components/Form/FormElements";
import Button from "@material-ui/core/Button";
import * as Yup from "yup";
import axios from "axios";
import {config} from "../../../auth/auth";
import {search} from "../../../redux/city/action";
import {connect} from "react-redux";
import {withAuth0} from "@auth0/auth0-react";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(1),
  },
}));

const validationSchema = Yup.object({
  liveChatMessage: Yup.string().required().label("Chat Message "),
  cityUuid: Yup.string(),
  isEnableMembershipAlarm: Yup.bool().oneOf([true, false], "Required"),
  isEnableLowBalanceAlarm: Yup.bool().oneOf([true, false], "Required"),
  lowBalanceThreshold: Yup.number()
    .min(1, "Min value is 1")
    .max(1000, "Max limit is 1000").label("Low Balance Threshold "),
  lowMembershipLimitThreshold: Yup.number()
    .min(1, "Min value is 1")
    .max(1000, "Max limit is 1000").label("Low Membership Limit Threshold "),
})

const host = process.env.REACT_APP_HOST

const CreateSettingModal = ({cities, search, refresh}) => {
  const [showModal, setShowModal] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    search();
  }, [])

  const cityTypes = [];
  if (cities) {
    cities?.content.map(city => cityTypes.push({
      value: city.uuid,
      description: city.cityName
    }));
  }

  const handleOpen = () => {
    setShowModal(true);
  }
  const handleClose = () => {
    setShowModal(false);
  }

  const handleSubmit = (values) => {
    return axios
      .post(`${host}/partner-setting/`, values, config())
      .catch((e) => alert(e))
      .finally(() => {
        setShowModal(false);
        refresh();
      });
  }

  return (
    <React.Fragment>
      <Tooltip title={"NEW"}>
        <IconButton onClick={() => handleOpen()}>
          <AddIcon/>
        </IconButton>
      </Tooltip>
      <Modal
        open={showModal}
        onClose={handleClose}
        className={classes.modal}
      >
        <div className="card shadow mb-4 " key="terms-and-conditions-key">
          <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
            <h4 className="m-0 font-weight-bold" style={{color: '#333399'}}>
              Create Partner Settings
            </h4>
          </div>
          <div className="card-body">
            <p className="mb-2">
              This page allows you Create Partner Settings For Notification
            </p>
            <Formik
              initialValues={{}}
              validationSchema={validationSchema}
              onSubmit={(values) => handleSubmit(values)}>
              {(formik) => (
                <Form>
                  <InputTextField name="liveChatMessage" label="Live Chat Message"/>
                  <CheckboxField name="isEnableMembershipAlarm" label="Activate Low Membership Limit Alarm" row/>
                  <CheckboxField name="isEnableLowBalanceAlarm" label="Activate Low Balance Alarm" row/>
                  <SelectField name="cityUuid" label="City" options={cityTypes}/>
                  <NumberField readOnly={false} name="lowBalanceThreshold" label="Low Balance"/>
                  <NumberField readOnly={false} name="lowMembershipLimitThreshold" label="Low Membership Limit"/>
                  <Button
                    variant="contained"
                    style={{color: "white", backgroundColor: "#333399"}}
                    type="submit"
                  >Submit</Button>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  cities: state.cityReducer.cities
});

const mapDispatchToProps = (dispatch) => ({
  search: (params) => dispatch(search(params)),
});


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withAuth0(CreateSettingModal));
