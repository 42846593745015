import React, {useState} from 'react';
import {CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import {grey, red} from "@material-ui/core/colors";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import axios from "axios";
import {config} from "../../../../auth/auth";
import {makeStyles} from "@material-ui/core/styles";
import {getPlaces} from "../../../../redux/places/action";
import {connect} from "react-redux";
import {withAuth0} from "@auth0/auth0-react";

const useStyles = makeStyles(theme => ({
  dialogContent: {
    textAlign: 'center'
  },
  dialogAction: {
    justifyContent: 'space-around'
  },
}))

const host = process.env.REACT_APP_HOST;

const PoiDeleteModal = ({poiId, name, setOpenSuccessModal, setOpenModal, openModal, getPlaces}) => {
  const [isLoading, setIsLoading] = useState(false);
  const classes = useStyles();

  const handleCancelButton = () => {
    setOpenModal(false);
  }

  const handleDelete = async () => {
    setIsLoading(true);
    const response = await axios
      .delete(`${host}/place/${poiId}`, config())
      .catch((e) => alert(e));

    if (response?.status >= 200 && response?.status < 300) {
      getPlaces();
      setOpenSuccessModal({
        open: true,
        content: `Deleted ${name} Successfully.`
      });
    } else {
      setOpenSuccessModal({
        open: true,
        header: "Failed",
        content: `Failed to delete ${name}.`
      });
    }
    setOpenModal(false);
    setIsLoading(false);
  }

  return (
    <Dialog open={openModal} fullWidth>
      <DialogTitle
        id="Users Action Modal"
        style={{
          color: "white",
          backgroundColor: "#333399",
          textAlign: "left",
        }}
      >
        <label style={{color: red[500]}}>DELETE {name}</label>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Typography variant="h6" className="p-3" style={{color: red[500]}}>
          Are you sure you want to delete {name}? You can not reverse this action.
        </Typography>
      </DialogContent>
      <DialogActions className={classes.dialogAction}>
        {!isLoading && <Button
          style={{color: "white", backgroundColor: "#333399"}}
          onClick={() => handleDelete()}>Delete</Button>}
        {!isLoading && <Button
          style={{color: grey[500]}}
          onClick={() => handleCancelButton()}>Cancel</Button>}
        {isLoading && <CircularProgress style={{width: 20, height: 20}}/>}
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  getPlaces: () => dispatch(getPlaces())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withAuth0(PoiDeleteModal));