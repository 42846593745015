import React, {useState} from 'react';
import {connect} from "react-redux";
import {withAuth0} from "@auth0/auth0-react";
import {
  MenuItem,
  TextField
} from "@material-ui/core";
import {getAccessToken} from "../../../auth/auth";
import axios from "axios";
import moment from "moment";
import {useBlockUI} from "../../../components/BlockUI/use-block-ui";

const DATA_CATEGORY = ['Transaction', 'Order', 'Vehicle', 'User'];
const host = process.env.REACT_APP_HOST
const date_format = process.env.REACT_APP_DATE_FORMAT;
const LOCAL_DATE_TIME_FORMAT = process.env.REACT_APP_LOCALDATETIME_FORMAT;

const Download = (props) => {
  const [createdFrom, setCreatedFrom] = useState(moment().format(date_format));
  const [createdUntil, setCreatedUntil] = useState(moment().format(date_format));
  const [category, setCategory] = useState([]);
  const [error, setError] = useState(false);
  const [categoryErr, setCategoryErr] = useState(false);
  const [isLoading, setIsLoading] = useState(false)
  const {blockUI, unBlockUI, RenderBlockUI} = useBlockUI();

  const handleSubmitForm = (event) => {
    event.preventDefault();
    if (!isRequestValid()) {
      return;
    }
    downloadExcelFile();
  }

  const isRequestValid = () => {
    if (moment(createdUntil).diff(createdFrom, 'months', true) > 3) {
      setError(true);
      return false;
    }
    if (category.length === 0) {
      setCategoryErr(true);
      return false
    }
    return true;
  }

  const downloadExcelFile = () => {
    let params = new URLSearchParams();
    params.set("createdFrom", String(moment(createdFrom).format(LOCAL_DATE_TIME_FORMAT)));
    params.set("createdUntil", String(moment(createdUntil).format(LOCAL_DATE_TIME_FORMAT)));
    const config = {
      responseType: 'blob',
      headers: {
        'Authorization': `Bearer ${getAccessToken()}`
      },
      params: params
    }

    setIsLoading(true);
    blockUI();
    axios.get(`${host}/${category?.toLowerCase()}/export`, config)
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${category}.xlsx`);
        document.body.appendChild(link);
        link.click();
      }).catch(e => {
      alert(e);
    }).finally(() => {
      unBlockUI();
      setIsLoading(false);
    });
  }


  const handleChangeCategory = (e) => {
    setCategoryErr(false);
    setCategory(e.target.value);
  }

  const handleChangeCreatedFrom = (e) => {
    setError(false);
    setCreatedFrom(e.target.value);
  }

  const handleChangeCreatedUntil = (e) => {
    setError(false);
    setCreatedUntil(e.target.value);
  }

  const showPopUpMessage = moment(createdUntil).diff(moment(createdFrom), 'days', false) >= 14;

  return (
    <React.Fragment>
      <RenderBlockUI/>
      <div className="card shadow mb-4">
        <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
          <h4 className="m-0 font-weight-bold" style={{color: '#333399'}}>
            Download Data
          </h4>
        </div>
        <div className="card-body">
          <p className="mb-4">
            This page allows you to download all of your data.
          </p>
          <form onSubmit={handleSubmitForm}>
            <div className="form-group">
              <div className="row">
                <div className="col-lg-12">
                  <TextField
                    id="createdFrom"
                    label="Start Time"
                    type="date"
                    error={error}
                    helperText={error && "The differance between dates must be less than 3 months"}
                    fullWidth
                    value={createdFrom}
                    onChange={handleChangeCreatedFrom}
                    InputProps={{
                      readOnly: false,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                  />
                </div>
                <div className="col-lg-12 mt-4">
                  <TextField
                    id="createdUntil"
                    label="End Time"
                    type="date"
                    error={error}
                    helperText={error && "The differance between dates must be less than 3 months"}
                    fullWidth
                    value={createdUntil}
                    onChange={handleChangeCreatedUntil}
                    InputProps={{
                      readOnly: false,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                  />
                </div>
                <div className="col-lg-12 mt-4">
                  <TextField
                    id="currencies"
                    select
                    label="Data Category"
                    value={category}
                    error={categoryErr}
                    helperText={categoryErr && "The value can't be empty."}
                    onChange={handleChangeCategory}
                    InputProps={{
                      readOnly: false,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                    fullWidth
                  >
                    {(DATA_CATEGORY || []).map((category, index) => (
                      <MenuItem key={category} value={category}>
                        {category}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
              </div>
              <br/>
            </div>
            <div>
              {showPopUpMessage &&
              <p style={{color: '#7dc45f'}}>
                Please note that this action will take a few minutes (2min-10min) to be executed as the server will need
                to fetch a huge volume of data. To avoid the delay you can reduce the range of the date and try
                again.</p>}
              <button type="submit" className="btn mt-2"
                      style={{color: '#fff', backgroundColor: '#333399', borderColor: '#333399'}} disabled={isLoading}>
                Download
              </button>
            </div>
          </form>
        </div>
      </div>
    </React.Fragment>
  );
};

export default connect()(withAuth0(Download));

