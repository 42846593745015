import React, { Component } from 'react'
import {withAuth0} from "@auth0/auth0-react";
import MigrationProcessCard from '../../../components/ChartCard/MigrationProcessCard';

class MigrationProcess extends Component {
    render() {
        return (
            <MigrationProcessCard/>
        )
    }
}

export default withAuth0(MigrationProcess);