import { useEffect } from 'react';
import L from 'leaflet';

function Legend({ map }) {    
    const getColor = d => {
        return d === "Available"
          ? "#03989e"
          : d === "Maintaining"
          ? "#ed1c24"
          : d === "Maintenance Pending"
          ? "#F4878B"
          : d === "Running"
          ? "#008037"
          : "";
      };
    useEffect(() => {
        if (map){
            const legend = L.control({ position: "bottomright" });
    
            legend.onAdd = () => {
                const div = L.DomUtil.create("div", "info legend");
                const grades = ["Available", "Running", "Maintenance Pending", "Maintaining"];
                let labels = [];
                let from;
                let to;

                for (let i = 0; i < grades.length; i++) {
                    from = grades[i];
                    to = grades[i + 1];

                    labels.push(
                    '<i style="background:' +
                        getColor(from) +
                        '"></i> ' +
                        from
                    );
                }
                div.innerHTML = labels.join("<br>");
                return div;
            };
            legend.addTo(map);
        }
    }
    ,[map]);
    return null
}

export default Legend