import React from 'react';
import {DialogTitle, Grid} from "@material-ui/core";
import {SelectField} from "../../../../components/Form/FormElements";

const OPTIONS = [
  {type: "Limits", description: "Limits", value: "Limits"},
  {type: "Details", description: "Details", value: "Details"}];

const SelectUpdateModal = () => {
  return (
    <React.Fragment>
      <DialogTitle
        id="User Action Modal"
        style={{
          color: "white",
          backgroundColor: "#333399",
          textAlign: "center",
        }}
      >
        <label>What would you like to update</label>
      </DialogTitle>
      <Grid item sm={12} xs={12} md={12} lg={12}>
        <Grid container spacing={2} className="formLabel px-3">
          <Grid item sm={12} xs={12} md={12} lg={12}>
            <SelectField name="type" label="Type of update" options={OPTIONS}/>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default SelectUpdateModal;