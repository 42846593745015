import React, {useState} from 'react';
import {
  IconButton,
  Tooltip
} from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/Delete';
import * as ReactDOM from "react-dom";
import Backdrop from "../../../Modal/Backdrop";
import PoiDeleteModal from "./POIDeleteModal";
import classes from '../../../Modal/Modal.module.css'

const PoiDelete = ({poiId, name, setOpenSuccessModal}) => {
  const [openModal, setOpenModal] = useState(false);

  return (
    <React.Fragment>
      <Tooltip title="Delete">
        <IconButton
          variant="contained"
          style={{color: 'orangered'}}
          onClick={() => setOpenModal(true)}
        >
          <DeleteIcon/>
        </IconButton>
      </Tooltip>
      {openModal && ReactDOM.createPortal(
        <Backdrop onClick={() => setOpenModal(false)}/>,
        document.getElementById('backdrop-root'))}

      {openModal && ReactDOM.createPortal(
        <div className={classes.modal}>
          <PoiDeleteModal poiId={poiId} name={name}
                          setOpenSuccessModal={setOpenSuccessModal}
                          setOpenModal={setOpenModal}
                          openModal={openModal}/>
        </div>,
        document.getElementById('overlay-root'))}

    </React.Fragment>
  );
};

export default PoiDelete;