/*
 * @Author: your name
 * @Date: 2021-01-09 20:52:52
 * @LastEditTime: 2021-01-09 21:15:38
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /tracmobility-console/src/auth/protected-route.js
 */
import React from "react";
import {Route} from "react-router-dom";
import {withAuthenticationRequired} from "@auth0/auth0-react";

export default class ProtectedRoute extends React.Component {
  render() {
    return (
      <Route
        component={withAuthenticationRequired(this.props.component, {
          onRedirecting: () => <div>Loading...</div>,
        })}
        {...this.props.args}
      />
    );
  }
}