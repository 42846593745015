import {GroundTeamEventLogTypes} from "./groundTeamEventLog.types";
import axios from "axios";
import { getAccessToken } from "../../auth/auth";

const host = process.env.REACT_APP_HOST

export const search  = (queryParams) =>{
  const config = {
    headers: {
      'Authorization': `Bearer ${getAccessToken()}`,
      'Content-Type': 'application/json'
    },
    params: queryParams
  }

  return async dispatch => {
    return axios.get(`${host}/groundTeamEventLog`, config)
      .then(
        response => dispatch(setGroundTeamEventLogs(response.data)),
        err => console.log(err)
      );
  }
}

export const setGroundTeamEventLogs = (payload) => ({
  type: GroundTeamEventLogTypes.SET_GROUND_TEAM_EVENT_LOGS,
  payload: payload,
});