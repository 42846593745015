import axios from "axios";

import {config, getAccessToken} from "../../auth/auth";
import {BatteryActionTypes} from "./battery.types";

const host = process.env.REACT_APP_HOST

export const search  = (queryParams) =>{
  const config = {
    headers: {
      'Authorization': `Bearer ${getAccessToken()}`,
      'Content-Type': 'application/json'
    },
    params: queryParams
  }

  return async dispatch => {
    return axios.get(`${host}/battery`, config)
      .then(
        response =>dispatch(setBatteries(response?.data)),
        err => console.log(err)
      );
  }
}

export const getBatteryByUuid  = (uuid) => {
  return async dispatch => {
    return axios.get(`${host}/battery/${uuid}`, config())
      .then(
        response => dispatch(setBattery(response.data)),
        err => console.log(err)
      );
  }
}

export const setBattery = (payload) => ({
  type: BatteryActionTypes.SET_BATTERY,
  payload: payload,
});

export const setBatteries = (payload) => ({
  type: BatteryActionTypes.SET_BATTERIES,
  payload: payload,
});