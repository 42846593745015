import {UserActionTypes} from "./user.types";
import axios from "axios";
import {config, getAccessToken} from "../../auth/auth";

const host = process.env.REACT_APP_HOST

export const search  = (queryParams) =>{
    const config = {
        headers: {
          'Authorization': `Bearer ${getAccessToken()}`,
          'Content-Type': 'application/json'
        },
        params: queryParams
    }

  return async dispatch => {
    return axios.get(`${host}/user`, config)
      .then(
        response =>dispatch(setUsers(response.data)),
        err => console.log(err)
      );
  }
}

export const setBusiness = (userUuid, businessUuid) => {
    return axios.put(`${host}/user/${userUuid}/business`, {businessUuid: businessUuid}, config());
}

export const getUserDetails = (path) => {
    const config = {
        headers: {
            'Authorization': `Bearer ${
                getAccessToken()
            }`,
            'Content-Type': 'application/json'
        }
    }
    let url = `${host}${path}`;
    return async dispatch => {
        const request = axios.get(url, config);

        return request.then(response => {
            dispatch(setUserDetails(response.data));
        }, err => {
            console.log(err);
        });
    }
}

export const getDrivingLicense = (path) => {
    if (path) {
        const config = {
            headers: {
                'Authorization': `Bearer ${
                    getAccessToken()
                }`,
                'Content-Type': 'application/json'
            }
        }
        let url = `${host}${path}`;
        return async dispatch => {
            const request = axios.get(url, config);

            return request.then(response => {
                dispatch(setDrivingLicense(response.data));
            }, err => {
                console.log(err);
            });
        }
    } else {
        return async dispatch => {
           return dispatch(setDrivingLicense({}));
        }

    }

}

export const updateUser = (user) => {
  const body = {
    discount: user?.discount
  }
  return async dispatch => {
    return axios.put(`${host}/user/${user.uuid}`, body, config())
      .then(
        response => {},
        err => console.log(err)
      );
  }
}

export const validateDrivingLicence = (url) => {
    const config = {
        headers: {
            'Authorization': `Bearer ${
                getAccessToken()
            }`,
            'Content-Type': 'application/json'
        }
    }
    return axios.put(`${host}${url}`, {}, config);
}


export const getDrivingLicenceVerifingUsers = (queryParams) => {
    const config = {
        headers: {
            'Authorization': `Bearer ${
                getAccessToken()
            }`,
            'Content-Type': 'application/json'
        },
        params: queryParams
    }
    return axios.get(`${host}/user`, config);
}

export const unbindCard = (userUuid) => {
  return axios.delete(`${host}/user/${userUuid}/card`,config());
}

export const setUsers = (users) => ({type: UserActionTypes.SET_USERS, payload: users});
export const setDrivingLicense = (drivingLicenceDetails) => ({type: UserActionTypes.SET_DRIVING_LICENSE, payload: drivingLicenceDetails});
export const setUserDetails = (userDetails) => ({type: UserActionTypes.SET_USER_DETAILS, payload: userDetails});
