import React from "react";
import PropTypes from 'prop-types';
import Avatar from "@material-ui/core/Avatar";
import CardHeader from "@material-ui/core/CardHeader";
import moment from "moment";
import {sub} from "../../utils/utils";
import Typography from '@material-ui/core/Typography'

const date_format = process.env.REACT_APP_DATE_FORMAT;

class User extends React.Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  };

  render() {
    const { user, history } = this.props;
    if (!user || !user.uuid)
      return (<React.Fragment/>);

    let name = sub(`${user?.firstName} ${user?.lastName}`);
    const subheader = moment(user?.createdAt).format(date_format)
    return (
      <CardHeader
        avatar={
          <Avatar aria-label="recipe" alt="Vehicle Photo">
            {`${user?.firstName?.charAt(0).toUpperCase()}${user?.lastName?.charAt(0).toUpperCase()}`}
          </Avatar>
        }
        title={name}
        style={{padding:0}}
      />
    );
  }
}

export default User;