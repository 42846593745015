import React, {useState, useEffect} from "react";
import Box from '@material-ui/core/Box';
import {
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select, TextField
} from "@material-ui/core";
import Success from "../../../components/PopUp/Success";
import {connect} from "react-redux";
import {withAuth0} from "@auth0/auth0-react";
import {
  getTermsAndConditions,
  postTermsAndConditions,
} from "../../../redux/TermsAndConditions/action";
import "./legal-information.css";
import {Formik} from "formik";
import {
  getPrivacyPolicy,
  postPrivacyPolicy
} from "../../../redux/PrivacyPolicy/action";
import {search as getCities} from "../../../redux/city/action";
import * as Yup from "yup";


const validationObj = Yup.object().shape({
  cityUuid: Yup.string().required(" "),
  termsURL: Yup.string().required(" "),
  privacyURL: Yup.string().required(" "),
});


function LegalInformation({
                            termsURL,
                            postTermsAndConditions,
                            getTermsAndConditions,
                            privacyURL,
                            getPrivacyPolicy,
                            postPrivacyPolicy,
                            cityUuid,
                            cities,
                            getCities
                          }) {
  const [formValues, setFormValues] = useState({termsURL, privacyURL, cityUuid});
  const [open, setOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    getTermsAndConditions();
    getPrivacyPolicy();
    getCities();
  }, [])

  useEffect(() => {
    setFormValues({termsURL, privacyURL, cityUuid: ''});
  }, [termsURL]);

  const handleSubmit = (values) => {
    setIsLoading(true);
    const bodyTC = {
      cityUuid: values.cityUuid,
      content: '',
      url: values.termsURL
    }
    const bodyPP = {
      cityUuid: values.cityUuid,
      content: '',
      url: values.privacyURL
    }

    // update terms and condition
    postTermsAndConditions(bodyTC)
      .then(() => setOpen(true))
      .finally(() => setIsLoading(false));

    // update privacy policy
    postPrivacyPolicy(bodyPP)
      .then(() => setOpen(true))
      .finally(() => setIsLoading(false));
  };

  const onCloseSuccessPopup = () => {
    setOpen(false);
  }

  return (
    <>
      <div className="card shadow mb-4" key="terms-and-conditions-key">
        <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
          <h4 className="m-0 font-weight-bold" style={{color: '#333399'}}>
            Legal Information Configuration
          </h4>
        </div>
        <div className="card-body">
          <p className="mb-4">
            This page allows you to configure the legal information showing on the App.
          </p>

          <Formik
            initialValues={formValues}
            validationSchema={validationObj}
            onSubmit={(values) => handleSubmit(values)}
          >
            {(formik) => (
              <form
                noValidate
                autoComplete="off"
                onSubmit={formik.handleSubmit}
                onChange={formik.handleChange}
              >
                <div className="form-group">
                  <div className="col-lg-12">
                    <TextField
                      id="termsURL"
                      type="text"
                      fullWidth
                      label="Terms and Condition Link"
                      name="termsURL"
                      value={formik.values.termsURL ?? ""}
                      onChange={formik.handleChange}
                      InputProps={{
                        readOnly: false,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      error={
                        formik.errors.termsURL && formik.touched.termsURL
                      }
                    />
                  </div>
                  <div className="col-lg-12 mt-4">
                    <TextField
                      id="privacyURL"
                      type="text"
                      name="privacyURL"
                      fullWidth
                      label="Privacy Policy Link"
                      className="form-control search-slt"
                      value={formik.values.privacyURL ?? ""}
                      onChange={formik.handleChange}
                      InputProps={{
                        readOnly: false,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      error={
                        formik.errors.privacyURL && formik.touched.privacyURL
                      }
                    />
                  </div>
                  <Box pt={2}>
                    <div className="col-lg-12 mt-4">
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel error={
                          formik.errors.cityUuid && formik.touched.cityUuid
                        }
                                    shrink>
                          City
                        </InputLabel>
                        <Select
                          labelId="city-select-label"
                          id="city"
                          name="cityUuid"
                          label="City"
                          value={formik.values.cityUuid ?? ""}
                          onChange={formik.handleChange}
                          input={<OutlinedInput notched label="City"/>}
                          onBlur={formik.handleBlur}
                        >
                          {(cities || []).map((city, index) => (
                            <MenuItem
                              key={index}
                              value={city.uuid}
                            >{city.cityName}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  </Box>
                  <br/>
                </div>
                <button type="submit" className="btn btn-info mt-5">
                  Submit
                </button>
              </form>
            )}
          </Formik>
          {isLoading ? (
            <IconButton disabled>
              <CircularProgress style={{width: 20, height: 20}}/>
            </IconButton>
          ) : (
            ""
          )}
        </div>
      </div>
      <Success
        open={open}
        header="Success"
        content="Upgrade Legal Information Successfully."
        onClose={() => onCloseSuccessPopup()}
      />
    </>
  );
}

const mapStateToProps = (state) => ({
  termsURL: state.termsAndConditionsReducer.url,
  privacyURL: state.privacyPolicyReducer.privacyPolicyUrl,
  cities: state.cityReducer.cities?.content
});

const mapDispatchToProps = (dispatch) => ({
  getTermsAndConditions: () => dispatch(getTermsAndConditions()),
  postTermsAndConditions: (body) => dispatch(postTermsAndConditions(body)),
  getPrivacyPolicy: () => dispatch(getPrivacyPolicy()),
  postPrivacyPolicy: (body) => dispatch(postPrivacyPolicy(body)),
  getCities: (params) => dispatch(getCities(params)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withAuth0(LegalInformation));
