import React, {useEffect, useState} from 'react';
import {search} from "../../../../redux/membership/action";
import {connect} from "react-redux";
import {withAuth0} from "@auth0/auth0-react";
import {CircularProgress, Dialog, DialogTitle, Grid, IconButton, Tooltip} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import {Form, Formik} from "formik";
import * as Yup from "yup";
import axios from "axios";
import {config} from "../../../../auth/auth";
import Success from "../../../../components/PopUp/Success";
import {InputTextField, SelectField} from "../../../../components/Form/FormElements";
import FormNavigation from "../../../../components/Form/MultistepForm/FormNavigation";

const host = process.env.REACT_APP_HOST;

const initialValues = {
  username: "",
  userUuid: "",
  membershipUuid: ""
}

const validationSchema = Yup.object({
  username: Yup.string().required().label("User Name "),
  userUuid: Yup.string().required().label("User Uuid "),
  membershipUuid: Yup.string().required().label("Membership "),
});

const AssignMembership = ({memberships, search, callback}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [responseModal, setResponseModal] = useState({
    open: false,
    header: undefined,
    content: ""
  });


  useEffect(() => {
    search({status: 'ACTIVE'});
  }, [])

  const membershipList = [];
  if (memberships) {
    memberships?.content.map(m => membershipList.push({
      value: m.uuid,
      label: m.name,
      description: m.name
    }))
  }

  const handleSubmit = async (values) => {
    const body = {
      userUuid: values.userUuid,
    }

    setIsLoading(true);

    const userResponse = await axios
      .get(`${host}/user/${body.userUuid}`, config())
      .catch((e) => console.log(e));

    if (userResponse?.status >= 200 && userResponse?.status < 300) {

      if (values.username.includes(userResponse.data.firstName) && values.username.includes(userResponse.data.lastName)) {
        const response = await axios
          .put(`${host}/membership/${values.membershipUuid}/assignMembership`, body, config())
          .catch((e) => console.log(e));

        if (response?.status >= 200 && response?.status < 300) {
          setResponseModal({
            open: true,
            content: `Membership assigned to user successfully.`
          });
          callback();
        } else {
          setResponseModal({
            open: true,
            header: "Failed",
            content: `User already has active membership.`
          });
        }

      } else {
        setResponseModal({
          open: true,
          header: "Failed",
          content: `${values.username} does not match details with User UUID: ${values.userUuid}.`
        });
      }

    } else {
      setResponseModal({
        open: true,
        header: "Failed",
        content: `Failed to assign membership.`
      });
    }

    setIsLoading(false);
  }

  return (
    <>
      <Tooltip title="Assign Membership">
        <IconButton
          variant="contained"
          color="primary"
          onClick={() => setOpenModal(true)}
        >
          <AddIcon/>
        </IconButton>
      </Tooltip>
      <Dialog
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby="Assign Membership Modal"
        fullWidth={true}
      >
        <DialogTitle
          id="Assign Membership Modal"
          style={{
            color: "white",
            backgroundColor: "#333399",
            textAlign: "center",
          }}
        >
          <label>Assign Membership</label>
        </DialogTitle>

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values) => handleSubmit(values)}>
          {(formik) => (
            <Form>
              <Grid container spacing={2} className="formLabel px-3">
                <Grid item xs={12}>
                  <InputTextField name="username" label="User Name"/>
                </Grid>
                <Grid item xs={12}>
                  <InputTextField name="userUuid" label="User Uuid"/>
                </Grid>
                <Grid item xs={12}>
                  <SelectField name="membershipUuid" label="Membership" options={membershipList}/>
                </Grid>
                <Grid item xs={12}>
                  {isLoading && <CircularProgress style={{width: 20, height: 20}}/>}
                  {!isLoading && <FormNavigation onCancel={() => setOpenModal(false)}/>}
                </Grid>
              </Grid>

            </Form>
          )}
        </Formik>
        <Success
          open={responseModal.open}
          header={responseModal.header}
          content={responseModal.content}
          onClose={() => setResponseModal({
            ...responseModal, open: false
          })}
        />
      </Dialog>
    </>
  );
};

const mapStateToProps = (state) => ({
  memberships: state.membershipReducer.memberships,
});

const mapDispatchToProps = (dispatch) => ({
  search: (params) => dispatch(search(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withAuth0(AssignMembership));