import React from "react";
import PropTypes from "prop-types";
import {Button, Dialog, DialogActions, DialogTitle, Grid,} from "@material-ui/core";

import axios from "axios";
import {config} from "../../../../auth/auth";
import {grey} from "@material-ui/core/colors";
import CardContent from "@material-ui/core/CardContent";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'

const host = process.env.REACT_APP_HOST

class RegionRuleActionModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            action: props.actionData?.action,
            timeDelay: props.actionData?.timeDelay ?? 0,
            open: this.props.open,
        };
    }

    static propTypes = {
        callback: PropTypes.func.isRequired,
        open: PropTypes.bool.isRequired,
        regionRuleUuid: PropTypes.string.isRequired
    };

    cancel = () => {
        this.setState({
            open: false,
            action: "",
            timeDelay: 0
        });
        this.props.callback(false);
    };

    confirm = (callback) => {
        if (this.state.uuid)
            this.updateAction(callback);
        else
            this.createNewAction(callback);
    };

    createNewAction = (callback) => {
        const body = {
            regionRuleUuid: this.props.regionRuleUuid,
            action: this.state.action,
            timeDelay: this.state.timeDelay,
        };
        return axios
            .post(`${host}/regionRuleAction/`, body, config())
            .then((response) => callback(true))
            .catch((e) => alert(e))
            .finally(() => {
                this.setState({
                    open: false,
                    action: "",
                    timeDelay: 0
                });
            });
    };

    updateAction = (callback) => {
        const body = {
            action: this.state.action,
            timeDelay: this.state.timeDelay,
        };
        return axios
            .put(`${host}/regionRuleAction/${this.state.uuid}`, body, config())
            .then((response) => callback(true))
            .catch((e) => alert(e))
            .finally(() => {
                this.setState({
                    open: false,
                    action: "",
                    timeDelay: 0
                });
            });
    };

    onChangeDelay = (e) => {
        this.setState({ timeDelay: e.target.value });
    };
    onChangeAction = (e) => {
        this.setState({ action: e.target.value });
    };

    // end

    componentDidUpdate(prevProps) {
        if (this.props?.open !== prevProps.open) {
            this.setState({
                 open: this.props?.open,
                 uuid: this.props?.data?.uuid,
                 action: this.props?.data?.action,
                 timeDelay: this.props?.data?.timeDelay ?? 0
            });
        }
    }
    render() {
        const { callback } = this.props;
        if (!callback) return <React.Fragment />;
        return (
            <React.Fragment>
                <Dialog
                    open={this.state.open}
                    onClose={this.handleClose}
                    aria-labelledby="Region Rule Action Modal"
                    fullWidth={true}
                >
                    <DialogTitle
                        id="Region Rule Action Modal"
                        style={{
                            color: "white",
                            backgroundColor: "#36b9cc",
                            textAlign: "center",
                        }}
                    >
                        <label>Region Rule Action</label>
                    </DialogTitle>
                    <Grid container spacing={2}>
                        <Grid item sm={12} xs={12} md={12} lg={12}>
                            <CardContent style={{ paddingBottom: "0px" }}>

                                <Grid container spacing={2} className="formLabel">
                                    <Grid item sm={12} xs={12} md={12} lg={12}>
                                        <InputLabel htmlFor="action">Action</InputLabel>
                                        <Select
                                            value={this.state.action}
                                            onChange={this.onChangeAction}
                                            inputProps={{
                                                name: 'Action',
                                                id: 'action',
                                            }}
                                        >
                                            <MenuItem key="SET_SPEED_LIMIT" value="SET_SPEED_LIMIT">SET_SPEED_LIMIT</MenuItem>
                                            <MenuItem key="ALARM" value="ALARM">ALARM</MenuItem>
                                            <MenuItem key="LOCK" value="LOCK">LOCK</MenuItem>
                                            <MenuItem key="UNLOCK" value="UNLOCK">UNLOCK</MenuItem>
                                            <MenuItem key="HEADLIGHT_ON" value="HEADLIGHT_ON">HEADLIGHT_ON</MenuItem>
                                            <MenuItem key="HEADLIGHT_OFF" value="HEADLIGHT_OFF">HEADLIGHT_OFF</MenuItem>
                                        </Select>
                                    </Grid>
                                </Grid>

                                <Grid container spacing={2} className="formLabel">
                                    <Grid item sm={12} xs={12} md={12} lg={12}>
                                        <TextField
                                            id="timeDelay"
                                            label="Delay"
                                            fullWidth
                                            type="number"
                                            value={this.state.timeDelay}
                                            onChange={this.onChangeDelay}
                                            InputProps={{
                                                readOnly: false,
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Grid>
                    </Grid>

                    <DialogActions
                        style={{
                            justifyContent: "space-between",
                            padding: "10px 40px 15px 40px",
                        }}
                    >
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => this.confirm(callback)}
                        >
                            Save
                        </Button>
                        <Button onClick={this.cancel} style={{ color: grey[500] }}>
                            Cancel
                        </Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment >
        );
    }
}
export default RegionRuleActionModal;
