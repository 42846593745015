import React from "react";
import {CircularProgress, IconButton, Tooltip} from "@material-ui/core";

class Loading extends React.Component {

  render() {
    return (
      <Tooltip title="Loading">
        <IconButton disabled={true}>
          <CircularProgress style={{width:20, height:20}} className="primary"/>
        </IconButton>
      </Tooltip>
    );
  }
}

export default Loading;