import React, {useState} from 'react';

import {config} from "../../../auth/auth";
import Modal from "react-bootstrap/Modal";
import Button from "@material-ui/core/Button";
import HighlightIcon from '@material-ui/icons/Highlight';
import {ButtonGroup, IconButton, Tooltip} from "@material-ui/core";
import {grey} from "@material-ui/core/colors";
import axios from "axios";

const host = process.env.REACT_APP_HOST

const HeadlightControl = ({uuid}) => {
  const [showModal, setShowModal] = useState(false);

  let icon = <HighlightIcon style={{color: grey[600]}}/>;

  const onClickHandle = () => {
    setShowModal(true);
  }

  let headlight = <Tooltip title="Headlight">
    <IconButton onClick={onClickHandle}>
      {icon}
    </IconButton>
  </Tooltip>

  const sendHeadlightAction = (action) => {
    axios.put(`${host}/vehicle/${uuid}/${action}`, {}, config());
    setShowModal(false);
  }


  return (
    <React.Fragment>
      <Modal
        show={showModal}
        onHide={() => setShowModal((prevState) => !prevState)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm</Modal.Title>
        </Modal.Header>
        <Modal.Body>What action do you want to perform on headlight?</Modal.Body>
        <Modal.Footer className="text-center" style={{
          justifyContent: "space-between",
        }}>
          <ButtonGroup>
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={() => sendHeadlightAction("lightOn")}
            >
              Light ON
            </Button>
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={() => sendHeadlightAction("lightOff")}
            >
              Light OFF
            </Button>
          </ButtonGroup>
          <Button
            size="small"
            variant="default"
            onClick={() => setShowModal(false)}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
      {headlight}

    </React.Fragment>
  );
};

export default HeadlightControl;