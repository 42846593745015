import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import AddIcon from "@material-ui/icons/Add";
import {withStyles} from "@material-ui/core/styles";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import PropTypes from "prop-types";
import axios from "axios";

import {config} from "../../../auth/auth";
import {CircularProgress, FormControlLabel, Grid, Switch} from "@material-ui/core";
import moment from "moment";
import {DateTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import CitySelector from "../../../components/common/CitySelector";
import RefreshIcon from '@material-ui/icons/Refresh';

const defaultToolbarStyles = {
  iconButton: {
  },
};
const host = process.env.REACT_APP_HOST
const DATETIME_FORMAT = process.env.REACT_APP_DATETIME_FORMAT;

const DEFAULT = {
  subject: undefined,
  content: undefined,
  targetType: 'ALL_USERS',
  uuid: undefined,
  isStartAuto: true,
  messageChannel: "APP_NOTIFICATION",
  startAt: moment()
}

class CustomToolbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      open: false,
      notificationTask: DEFAULT
    };
  }

  static propTypes = {
    refresh: PropTypes.func.isRequired
  };

  handleClick = () => {
    this.setState({open: true});
  }

  cancel = () => {
    this.setState({open: false});
  };

  create = () => {
    const { refresh, error } = this.props;
    if (this.state.isLoading)
      return;
    this.setState({isLoading: true})
    return axios.post(`${host}/userMessageTask`, this.state.notificationTask, config())
      .then((response) => {
        this.setState({open: false, notificationTask: DEFAULT});
        refresh();
      }).catch(e => {
        console.error("Failed to create notification task", e);
        alert(e?.response?.data?.message ?? e);
      }).finally(() => {
        this.setState({isLoading: false});
      });
  };

  handleChange = (key, value) => {
    this.setState(prevState => {
      let notificationTask = Object.assign({}, prevState.notificationTask);
      notificationTask[key] = value;
      return { notificationTask };
    })
  };

  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <Tooltip title={"REFRESH"}>
            <IconButton className={classes.iconButton} onClick={this.props.refresh}>
              <RefreshIcon className={classes.deleteIcon} />
            </IconButton>
          </Tooltip>
          <Tooltip title={"NEW"}>
            <IconButton className={classes.iconButton} onClick={this.handleClick}>
              <AddIcon className={classes.deleteIcon} />
            </IconButton>
          </Tooltip>
          <Dialog open={this.state.open} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Create Notification Task</DialogTitle>
            <DialogContent>
              <DialogContentText>
                To create a new notification task, please enter your subject and content here.
              </DialogContentText>
              <FormControl className={classes.formControl} fullWidth>
                <InputLabel htmlFor="max-width">Channel</InputLabel>
                <Select
                  disabled={this.state.isLoading}
                  value={this.state.notificationTask.messageChannel ?? ""}
                  onChange={(e) => this.handleChange("messageChannel", e?.target?.value)}
                  inputProps={{
                    name: 'Channel',
                    id: 'Channel',
                  }}
                  fullWidth
                  variant="outlined"
                  style={{ marginTop: "15px" }}
                >
                  <MenuItem value="APP_NOTIFICATION">Notification</MenuItem>
                </Select>
              </FormControl>
              <Grid container spacing={2}>
                <Grid item sm={6}>
                  <FormControl className={classes.formControl} fullWidth>
                    <InputLabel htmlFor="max-width">Target Group</InputLabel>
                    <Select
                      disabled={this.state.isLoading}
                      value={this.state.notificationTask.targetType ?? ""}
                      onChange={(e) => this.handleChange("targetType", e?.target?.value)}
                      inputProps={{
                        name: 'targetType',
                        id: 'targetType',
                      }}
                      fullWidth
                      variant="outlined"
                      style={{ marginTop: "15px" }}
                    >
                      <MenuItem value="ALL_USERS">All Users</MenuItem>
                      <MenuItem value="USERS_OF_CITY">City</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item sm={6}>
                  {this.state.notificationTask.targetType === 'USERS_OF_CITY' &&
                    <CitySelector
                      cityUuid={this.state.notificationTask.uuid}
                      onChangedCity={(cityUuid) => this.handleChange("uuid", cityUuid)}
                    />
                  }
                </Grid>
              </Grid>

              <TextField
                disabled={this.state.isLoading}
                autoFocus
                value={this.state.notificationTask.subject ?? ""}
                margin="dense"
                id="subject"
                label="Subject"
                type="text"
                fullWidth
                InputProps={{
                  readOnly: false,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                style={{ marginTop: "15px" }}
                onChange={(e) => this.handleChange("subject", e?.target?.value)}
              />
              <TextField
                disabled={this.state.isLoading}
                multiline
                value={this.state.notificationTask.content ?? ""}
                rows="6"
                margin="dense"
                id="content"
                label="Content"
                type="text"
                fullWidth
                InputProps={{
                  readOnly: false,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                style={{ marginTop: "15px" }}
                onChange={(e) => this.handleChange("content", e?.target?.value)}
              />

              <Grid container spacing={2}>
                <Grid item sm={6}>
                  <FormControlLabel
                    control={
                      <Switch checked={this.state.notificationTask.isStartAuto}
                              onChange={(e) => this.handleChange("isStartAuto", e?.target?.checked)}
                              name="Trigger Automatically" />
                    }
                    label="Trigger Automatically"
                  />
                </Grid>
                <Grid item sm={6}>
                  {this.state.notificationTask?.isStartAuto &&
                  <DateTimePicker
                    label="Trigger date time"
                    inputVariant="outlined"
                    value={this.state.notificationTask?.startAt ?? null}
                    onChange={(e) => this.handleChange("startAt", e)}
                    format={DATETIME_FORMAT}
                    fullWidth
                    style={{ marginTop: "15px" }}
                  />
                  }
                </Grid>
              </Grid>

            </DialogContent>
            <DialogActions>
                <React.Fragment>
                  {this.state.isLoading ? <CircularProgress style={{width:20, height:20}}/> : ""}
                  <Button
                    onClick={() => this.create()}
                    style={{color: "white", backgroundColor: "#36b9cc"}}
                    disabled={this.state.isLoading || !this.state.notificationTask?.content || !this.state.notificationTask?.subject}
                  >
                    Create
                  </Button>
                  <Button
                    onClick={this.cancel}
                    color="primary"
                    disabled={this.state.isLoading}
                  >
                    Cancel
                  </Button>
                </React.Fragment>
            </DialogActions>
          </Dialog>
        </MuiPickersUtilsProvider>
      </React.Fragment>
    );
  }

}

export default withStyles(defaultToolbarStyles, { name: "CustomToolbar" })(CustomToolbar);
