import React, {Component, useContext, useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {useHistory} from "react-router-dom";
import Avatar from "@material-ui/core/Avatar";
export const CustomChannelPreview = (props) => {
    const history = useHistory();
    const {channel, setActiveChannel, displayTitle, displayImage} = props;
    const {messages} = channel.state;
    const messagePreview = messages[messages.length - 1] ?. text.slice(0, 30);
    const unreadCount = channel.state.unreadCount;
    const last_message_at = channel ?. data ?. last_message_at;
    const navigateToChat = (channel) => {
        setActiveChannel(channel);
        history.push('/customer-service/live-chat')
    }
    if (unreadCount) {
        return (

            <Link className="dropdown-item d-flex align-items-center"
                onClick={
                    (channel) => navigateToChat(channel)
            }>
                <div className="dropdown-list-image mr-3">

                    <Avatar aria-label="recipe" alt="Vehicle Photo">

                        {

                        `${

                            displayTitle?. charAt(0).toUpperCase()

                        }`

                    } </Avatar>


                    <div className="status-indicator bg-danger"></div>

                </div>
              
                <div className="font-weight-bold">
                    {
                    displayTitle || 'Unnamed Channel'
                }
                    <div className="text-truncate">
                        {messagePreview} </div>
                    <div className="small text-gray-800">
                        {last_message_at}</div>
                </div>
            </Link>
        )
    } else {
        return null;
    }

}
