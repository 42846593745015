import React from "react";
import PropTypes from 'prop-types';
import {Avatar, Tooltip} from "@material-ui/core";
import {determineVehicleImage} from "../../../../utils/utils";

class VehicleGraph extends React.Component {
  static propTypes = {
    value: PropTypes.string.isRequired
  };

  render() {
    const { value } = this.props;

    let title = value ? `${value}` : "Default Graph";

    let img = determineVehicleImage(value);
    
    return (
      <React.Fragment>
        <Tooltip title={title}>
          <Avatar alt="Photo" src={img} />
        </Tooltip>
      </React.Fragment>
    );
  }
}

export default VehicleGraph;