import React from "react";
import {CircularProgress, IconButton, Tooltip} from "@material-ui/core";
import SyncIcon from "@material-ui/icons/Sync";
import PropTypes from "prop-types";
import {refresh} from "../../../../redux/transaction/transaction.action";
import {success} from "../../../../redux/alert/alert.action";
import {error} from "../../../../redux/alert/alert.action";
import {connect} from "react-redux";


class TransactionRefreshButton extends React.Component {

  static propTypes = {
    uuid: PropTypes.string.isRequired,
    businessType: PropTypes.string.isRequired,
    status: PropTypes.object.isRequired,
    callback: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
    };
  }

  onClickRefreshBtn = () => {
    if (this.state.isLoading)
      return;

    this.setState({isLoading: true});

    refresh(this.props.uuid).then(response => {
      this.props.callback();
      this.props.success("Success");
    }).catch(e => {
      console.error(e);
      this.props.error(e?.response?.data?.message ? e?.response?.data?.message : e.toString());
    }).finally(() => {
      setTimeout(() => this.setState({isLoading: false}), 500);
    });
  }

  render() {
    const {businessType, status} = this.props;

    // https://tracmobility.atlassian.net/wiki/spaces/TTP/pages/114524174/Transaction+Status
    const isDisabled = !(businessType === 'TOPUP' && (status === 'PROCESSING' || status === 'CONFIRMING'));

    return (
      <React.Fragment>
        <Tooltip title="Refresh Transaction Payment Information">
          <IconButton aria-label="Block"
                      disabled={isDisabled}
                      onClick={this.onClickRefreshBtn}
          >
            {this.state.isLoading ? <CircularProgress size={18}/> : <SyncIcon/>}
          </IconButton>
        </Tooltip>
      </React.Fragment>
    );
  }

}

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = (dispatch) => ({
  success: (msg) => dispatch(success(msg)),
  error: (msg) => dispatch(error(msg))
});

export default connect(mapStateToProps, mapDispatchToProps)(TransactionRefreshButton);