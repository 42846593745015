import React, {Component} from "react";
import MUIDataTable from "mui-datatables";
import {withAuth0} from "@auth0/auth0-react";
import {Button, Grid, Tooltip} from "@material-ui/core";
import {connect} from "react-redux";
import {queryParams} from "../../../utils/utils";
import {getAdminUsers} from "../../../redux/adminUser/adminUser.action";
import Delete from "../../../components/common/Delete";
import CustomToolbarSelect from "./CustomToolbarSelect";
import moment from "moment";
import InviteConsoleUser from "./InviteConsoleUser";
import ConsoleEventLogList from "../../SystemLog/ConsoleEventLogList";


const date_format = process.env.REACT_APP_DATE_FORMAT;
const datetime_format = process.env.REACT_APP_DATETIME_FORMAT;

class AdminUsers extends Component {
  constructor(props) {
    super(props);
    this.isFilterDialogOpen = false;
    this.tableRef = React.createRef();
    this.columnsDisplay = undefined;
  }

  componentDidMount() {
    this.refresh();
  }

  refresh = () => {
    const {getAdminUsers} = this.props;

    // table state
    const state = this.tableRef?.current?.state;

    const params = queryParams(this.props, state);

    getAdminUsers(params);
  }

  onChangeColumnDisplay = () => {
    const state = this.tableRef?.current?.state;
    this.columnsDisplay = state.columns.reduce((obj, item) => Object.assign(obj, {[item.name]: item.display}), {});
  }

  isDisplay = (name) => {
    if (!this.columnsDisplay || !this.columnsDisplay.hasOwnProperty(name))
      return undefined;
    else
      return this.columnsDisplay[name];
  }

  render() {
    const {adminUsers} = this.props;

    if (!adminUsers)
      return (<React.Fragment>Loading...</React.Fragment>);

    const list = adminUsers?.content ?? 0;
    const totalElements = adminUsers?.totalElements ?? 0;
    const rowsPerPage = adminUsers?.pageable?.pageSize ?? 10;
    const page = adminUsers?.pageable?.pageNumber ?? 0;

    const columns = [
      {
        name: "uuid",
        label: "UUID",
        options: {
          filter: true,
          sort: false,
          display: this.isDisplay("uuid") ?? true,
          customFilterListOptions: {render: (v) => `UUID: ${v}`},
          filterType: "textField",
        },
      },
      {
        name: "email",
        label: "Email",
        index: 2,
        options: {
          filter: true,
          sort: false,
          display: this.isDisplay("email") ?? true,
          customFilterListOptions: {render: (v) => `Email: ${v}`},
          filterType: "textField",
        },
      },
      {
        name: "firstName",
        label: "First Name",
        options: {
          filter: true,
          sort: false,
          display: this.isDisplay("firstName") ?? true,
          customFilterListOptions: {render: (v) => `First Name: ${v}`},
          filterType: "textField",
        },
      },
      {
        name: "lastName",
        label: "Last Name",
        options: {
          filter: true,
          sort: false,
          display: this.isDisplay("lastName") ?? true,
          customFilterListOptions: {render: (v) => `Last Name: ${v}`},
          filterType: "textField",
        },
      },
      {
        name: "createdAt",
        label: "Created At",
        options: {
          filter: false,
          sort: true,
          display: this.isDisplay("createdAt") ?? true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <Tooltip title={value ? moment(value).format(datetime_format) : ""}>
                <div>{value ? moment(value).format(date_format) : ""}</div>
              </Tooltip>
            );
          },
        },
      },
      {
        name: "Operations",
        options: {
          filter: false,
          sort: false,
          empty: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div>
                <Delete url={`/consoleUser/${tableMeta.rowData[0]}`} callback={() => this.refresh()}/>
              </div>
            );
          },
        },
      },

    ];


    const options = {
      filter: true,
      search: false,
      confirmFilters: true,
      serverSide: true,
      sortOrder: this.state?.sortOrder,
      filterType: "checkbox",
      responsive: "vertical",
      rowsPerPageOptions: [10, 20, 50, 100],
      rowsPerPage: rowsPerPage,
      count: totalElements,
      page: page,
      fixedHeader: true,
      tableBodyHeight: "900px",
      customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
        <CustomToolbarSelect
          selectedRows={selectedRows}
          displayData={displayData}
          setSelectedRows={setSelectedRows}
          callback={() => this.refresh()}
        />
      ),
      onTableChange: (action, tableState) => {
        switch (action) {
          case "viewColumnsChange":
            this.onChangeColumnDisplay();
            break;
          case "onFilterDialogOpen":
            this.isFilterDialogOpen = true;
            break;
          case "filterChange":
            if (this.isFilterDialogOpen)
              return;
            else
              this.refresh();
            break;
          case "onFilterDialogClose":
            this.isFilterDialogOpen = false;
          case "changePage":
          case "sort":
          case "changeRowsPerPage":
            this.refresh();
            break;
          default:
            console.log("Not handled action " + action);
        }
      },
      customFilterDialogFooter: (currentFilterList, applyNewFilters) => {
        return (
          <div style={{marginTop: "40px"}}>
            <Button variant="contained"
                    style={{color: "white", backgroundColor: "#36b9cc"}}
                    onClick={() => {
                      applyNewFilters()
                    }}
            >
              Apply
            </Button>
          </div>
        );
      },
      customToolbar: () => {
        return (
          <>
            <InviteConsoleUser callback={() => this.refresh()}/>
          </>
        );
      }
    };
    return (
      <React.Fragment>
        <Grid container spacing={2}>
          <Grid item lg={12}>
            <MUIDataTable
              title={<h1 className="tableTitle">Admin User List</h1>}
              data={list}
              columns={columns}
              options={options}
              ref={this.tableRef}
            />
          </Grid>
          <Grid item lg={12}>
            <ConsoleEventLogList
              filters={[{key: "event", value: `INVITE_CONSOLE_USER`}]}
            />
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({adminUsers: state.adminUsersData.adminUsers});

const mapDispatchToProps = (dispatch) => ({
  getAdminUsers: (params) => dispatch(getAdminUsers(params)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withAuth0(AdminUsers));
