import React from "react";
import PropTypes from 'prop-types';
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Card from "@material-ui/core/Card";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';
import Delete from "../../../../components/common/Delete";
import {fullName} from "../../../../utils/utils";
import {getPages, getUsers} from "../../../../redux/role/role.action";
import {connect} from "react-redux";
import {withAuth0} from "@auth0/auth0-react";
import RefreshIcon from '@material-ui/icons/Refresh';
import {IconButton, Tooltip} from "@material-ui/core";
import UserSelector from "./user-selector";
import {PAGES} from "./pages";

class RolePages extends React.Component {

  constructor(props) {
    super(props);
  }

  static propTypes = {
    role: PropTypes.any.isRequired,
    pages: PropTypes.any.isRequired
  };

  refresh = () => {
    this.props.getPages(this.props.role?.uuid);
  }

  render() {
    const {pages, role} = this.props;
    if (!pages || !role)
      return (<React.Fragment>Loading...</React.Fragment>);

    return (
      <Card>
        <CardHeader title="Pages"
                    className="primary"
                    action={
                      <IconButton aria-label="settings" onClick={this.refresh}>
                        <RefreshIcon />
                      </IconButton>
                    }
        />
        <CardContent>
          <List>
            {pages?.map((page, index) => {
              return (
                <React.Fragment>
                  <ListItem key={`role-pages-${page}-key`}>
                    <ListItemText primary={`${PAGES[page]}`}/>
                    <CardActions disableSpacing>
                      <Delete url={`/role/${role.uuid}/page/${page}`} callback={this.refresh}/>
                    </CardActions>
                  </ListItem>
                  {(index !== pages?.length - 1) && <Divider component="li"/>}
                </React.Fragment>
              );
            })}
            {(!pages || pages?.length === 0) ?? <div> No data</div>}
          </List>
        </CardContent>
      </Card>
    );
  }
}

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = (dispatch) => ({
  getPages: (params) => dispatch(getPages(params))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withAuth0(RolePages));
