import React from "react";
import PropTypes from "prop-types";
import {Button, Dialog, DialogActions, DialogTitle, Grid,} from "@material-ui/core";

import axios from "axios";
import {config} from "../../../../auth/auth";
import {grey} from "@material-ui/core/colors";
import CardContent from "@material-ui/core/CardContent";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";

const host = process.env.REACT_APP_HOST

class ParkingMapEditModel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      parkingName: undefined,
      notification: undefined,
      uuid: this.props.parking.uuid,
      status: undefined,
      open: false,
    };
  }

  static propTypes = {
    parking: PropTypes.object,
    callback: PropTypes.func.isRequired
  };

  handleClick = () => {
    this.setState({open: true});
  }

  cancel = () => {
    this.setState({open: false});
  };

  confirm = (callback) => {
    if (this.state.isLoading)
      return;
    const body = {
      name: this.state.parkingName ?? this.props?.parking?.name,
      notification: this.state.notification ?? this.props?.parking?.notification,
      status: this.state.status ?? this.props?.parking?.status,
      cityUuid: this.props?.parking?.cityUuid,
      capacity: 20,
      radius:5,
      discount:0
    };
    return axios
      .put(`${host}/parking/${this.props.parking.uuid}`, body, config())
      .then((response) => callback())
      .catch((e) => alert(e))
      .finally(() => {
        this.setState({
          isLoading: false, 
          open: false
        });
        callback();
      });
  };

  onChangeParkingName = (e) => {
    this.setState({ parkingName: e.target.value });
  };
  onChangeNotification = (e) => {
    this.setState({ notification: e.target.value });
  };
  onChangeStatus = (e) => {
    this.setState({ status: e.target.value });
  };

  // end

  render() {
    const { parking, callback } = this.props;
    const parkingName = this.state.parkingName ?? parking?.name;
    const notification = this.state.notification ?? parking?.notification;
    const status = this.state.status ?? parking?.status;
    if (!callback) return <React.Fragment />;
    return (
      <React.Fragment>
        <Tooltip title={"NEW"}>
          <IconButton onClick={this.handleClick}>
            <EditIcon />
          </IconButton>
        </Tooltip>
        <Dialog
          open={this.state.open}
          onClose={this.cancel}
          aria-labelledby="Parking Edit Modal"
          fullWidth={true}
        >
          <DialogTitle
            id="Parking Edit Modal"
            style={{
              color: "white",
              backgroundColor: "#36b9cc",
              textAlign: "center",
            }}
          >
            <label>Edit Parking</label>
          </DialogTitle>
          <Grid container spacing={2}>
            <Grid item sm={12} xs={12} md={12} lg={12}>
              <CardContent style={{ paddingBottom: "0px" }}>
                <Grid container spacing={2} className="formLabel">
                  <Grid item sm={12} xs={12} md={12} lg={12}>
                    <TextField
                      id="parkingName"
                      label="Parking Name"
                      fullWidth
                      value={parkingName}
                      onChange={this.onChangeParkingName}
                      InputProps={{
                        readOnly: false,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={2} className="formLabel">
                  <Grid item sm={12} xs={12} md={12} lg={12}>
                    <TextField
                      id="notification"
                      label="Parking Note"
                      fullWidth
                      value={notification}
                      onChange={this.onChangeNotification}
                      InputProps={{
                        readOnly: false,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={2} className="formLabel">
                  <Grid item sm={12} xs={12} md={12} lg={12}>
                    <TextField
                      id="status"
                      select
                      label="Status"
                      value={status}
                      onChange={this.onChangeStatus}
                      InputProps={{
                        readOnly: false,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      fullWidth
                    >
                      <MenuItem key="ACTIVE" value="ACTIVE">
                        ACTIVE
                      </MenuItem>
                      <MenuItem key="INACTIVE" value="INACTIVE">
                        INACTIVE
                      </MenuItem>
                    </TextField>
                  </Grid>
                </Grid>
              </CardContent>
            </Grid>
          </Grid>

          <DialogActions
            style={{
              justifyContent: "space-between",
              padding: "10px 40px 15px 40px",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={() => this.confirm(callback)}
            >
              Save
            </Button>
            <Button onClick={this.cancel} style={{ color: grey[500] }}>
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}
export default ParkingMapEditModel;
