import {ActionTypes} from "./types";
import axios from "axios";

const host = 'https://tracmobility.zendesk.com/api/v2'

export const search  = () =>{
  const config = {
    withAuthorization: true,
    headers: {
      'Authorization': 'Bearer 39873044cc4e73ec342bc70f0661604ed2c1d8d481e5150300d9272182e05e4b'
    }
  }

  return async dispatch => {
    return axios.get(`${host}/organizations/1900137375113/tickets?sort_by=id&sort_order=desc`, config)
      .then(
        response => {
          dispatch(setZendeskTicket(response.data));
        },
        err => console.log(err)
      );
  }
}

export const setZendeskTicket = (payload) => ({
  type: ActionTypes.SET_ZENDESK_TICKETS,
  payload: payload,
});