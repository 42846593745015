import React from "react";
import PropTypes from "prop-types";
import {IconButton, Tooltip} from "@material-ui/core";
import {green, grey, red, yellow} from "@material-ui/core/colors";
import PersonIcon from "@material-ui/icons/Person";

class UserStatus extends React.Component {
  static propTypes = {
    status: PropTypes.string.isRequired,
  };

  render() {
    const { status } = this.props;
    if (!status) return <React.Fragment />;
    let color;
    if (status === "ACTIVE") {
      color = green[500];
    } else if (status === "INACTIVE") {
      color = red[500];
    } else if (status === "FRAUD") {
      color = yellow[900];
    } else {
      color = grey[500];
    }
    return (
      <React.Fragment>
        <Tooltip title={status}>
          <IconButton>
          <PersonIcon style={{ color: color }} />
          </IconButton>
        </Tooltip>
      </React.Fragment>
    );
  }
}

export default UserStatus;
