import React from "react";
import {AlertTypes} from "./alert.types";

const INITIAL_STATE = {
 open: false,
 severity: "error",
 message: undefined
};

const alertReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AlertTypes.SET_OPEN:    return {...state, open:     action.payload};
    case AlertTypes.SET_SEVERITY:return {...state, severity: action.payload};
    case AlertTypes.SET_MESSAGE: return {...state, message:  action.payload};
    default:
      return state;
  }
};

export default alertReducer;