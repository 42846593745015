import React from "react";
import PropTypes from "prop-types";
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  IconButton,
  Tooltip
} from "@material-ui/core";
import CardContent from "@material-ui/core/CardContent";
import TextField from "@material-ui/core/TextField";
import {grey} from "@material-ui/core/colors";
import {connect} from "react-redux";
import axios from "axios";
import {config} from "../../../../auth/auth";
import {error} from "../../../../redux/alert/alert.action";
import PaymentIcon from "@material-ui/icons/Payment";
import MenuItem from "@material-ui/core/MenuItem";

const host = process.env.REACT_APP_HOST

class MembershipAccountExtend extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      minutes: 0,
      open: false,
    };
  }

  static propTypes = {
    callback: PropTypes.func.isRequired,
    uuid: PropTypes.string.isRequired
  };

  onChangeExtendMinutes = (e) => {
    this.setState({ minutes: e.target.value });
  }

  onClickEditBtn = () => {
    this.setState({ open: true });
  }

  onClickSaveBtn = (callback) => {
    const body = {
      minutes: this.state.minutes,
      userUuid: this.props.uuid
    };
    return axios
      .put(`${host}/membership/extendMembership`, body, config())
      .then((response) => {})
      .catch((e) => this.props.error(`${e?.response?.data?.error}: ${e?.response?.data?.message}`))
      .finally(() => {
        this.setState({
          minutes: 0,
          open: false
        });
        callback();
      });
  }

  onClickCancelBtn = () => {
    this.setState({ open: false });
  }

  render() {
    const { callback } = this.props;
    return (
      <React.Fragment>
        <Tooltip title="Extend">
          <IconButton
            variant="contained"
            color="primary"
            style={{ float: "center" }}
            onClick={this.onClickEditBtn}
          >
            <PaymentIcon/>
          </IconButton>
        </Tooltip>
        <Dialog
          open={this.state.open}
          onClose={this.onClickCancelBtn}
          aria-labelledby="Membership Account Extend Modal"
          fullWidth={true}
        >
          <DialogTitle
            id="Membership Account Extend Modal"
            style={{
              color: "white",
              backgroundColor: "#333399",
              textAlign: "center",
            }}
          >
            <label>Extend Membership</label>
          </DialogTitle>
          <Grid container spacing={2}>
            <Grid item sm={12} xs={12} md={12} lg={12}>
              <CardContent style={{ paddingBottom: "0px" }}>
                <Grid container spacing={2} className="formLabel">
                  <Grid item sm={12} xs={12} md={12} lg={12}>
                    <TextField id="extendBy" label="Extend By"
                      select
                      onChange={this.onChangeExtendMinutes}
                      fullWidth
                      InputLabelProps={{shrink: true}}
                      variant="outlined">
                      <MenuItem key="10-minutes" value={10}>
                          10 Minutes
                      </MenuItem>
                      <MenuItem key="RUNNING" value={20}>
                          20 Minutes
                      </MenuItem>
                      <MenuItem key="RESERVED" value={30}>
                          30 Minutes
                      </MenuItem>
                      <MenuItem key="REPORTED" value={40}>
                          40 Minutes
                      </MenuItem>
                    </TextField>
                  </Grid>
                </Grid>
              </CardContent>
            </Grid>
          </Grid>

          <DialogActions
            style={{
              justifyContent: "space-between",
              padding: "10px 40px 15px 40px",
            }}
          >
            <Button
              variant="contained"
              onClick={() => this.onClickSaveBtn(callback)}
            >
              Add
            </Button>
            <Button onClick={this.onClickCancelBtn} style={{ color: grey[500] }}>
              Cancel
            </Button>
          </DialogActions>
        </Dialog>

      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = (dispatch) => ({
  error: (msg) => dispatch(error(msg))
});

export default connect(mapStateToProps, mapDispatchToProps)(MembershipAccountExtend);