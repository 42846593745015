import React, { useState, useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Overlay from "react-bootstrap/Overlay";
import Popover from "react-bootstrap/Popover";
import AddPhotoAlternateIcon from "@material-ui/icons/AddPhotoAlternate";
import { Avatar } from "@material-ui/core";
import CardMedia from "@material-ui/core/CardMedia";

export default function GraphOverlay({value}) {
  const [show, setShow] = useState(false);
  const [target, setTarget] = useState(null);
  const ref = useRef(null);

  const handleOnGraphClick = (event) => {
    setShow((prevState) => !prevState);
    setTarget(event.target);
  };


  return (
    <div ref={ref}>
      <Avatar
        alt="Photo"
        src={value}
        onClick={handleOnGraphClick}
      >
        <AddPhotoAlternateIcon/>
      </Avatar>
      {value && <Overlay
        show={show}
        target={target}
        placement="left"
        rootClose
        onHide={() => setShow((prevState) => !prevState)}
        container={ref.current}
        containerPadding={20}
      >
        <Popover>
          <Popover.Title as="h1">Task Picture</Popover.Title>
          <Popover.Content>
            <CardMedia
              component="img"
              alt="Task Picture"
              image={value}
              title="Task Picture"
              style={{width: "250px"}}
            />
          </Popover.Content>
        </Popover>
      </Overlay>}
    </div>
  );
}
