import React from "react";
import PropTypes from "prop-types";
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Tooltip,
} from "@material-ui/core";
import moment from "moment";
import axios from "axios";
import {config} from "../../../auth/auth";
import {grey} from "@material-ui/core/colors";
import CardContent from "@material-ui/core/CardContent";
import TextField from "@material-ui/core/TextField";
import {randomString} from "../../../utils/utils";
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';

const host = process.env.REACT_APP_HOST;

class VoucherBatchCreate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      amount: 1,
      code: randomString(6, randomString.num),
      startAt: moment(new Date()).format("YYYY-MM-DDTHH:mm"),
      endAt: moment(new Date()).add(1, "days").format("YYYY-MM-DDTHH:mm"),
      reusable: false,
      quantity: 2,
      openModal: false,
      cityNames: []
    };
  }

  static propTypes = {
    callback: PropTypes.func.isRequired,
  };

  cancel = () => {
    this.setState({
      amount: this.state.amount,
      code: this.state.code,
      startAt: moment(new Date()).format("YYYY-MM-DDTHH:mm"),
      endAt: moment(new Date()).add(1, "days").format("YYYY-MM-DDTHH:mm"),
      reusable: false,
      quantity: this.state.quantity,
      openModal: false,
      cityNames: []
    });
  };

  createVouchers = (callback) => {
    if (this.state.amount < 1) {
      alert("Min amount is 1");
    } else if (moment(this.state.startAt).isAfter(moment(this.state.endAt))) {
      alert("Start date must be before end date");
    } else {
      let citiesUuids = this.props.cities.filter(city => this.state.cityNames.includes(city.label))
        .map(city => city.value);
      const body = {
        type: "SYSTEM_TOPUP",
        code: this.state.code,
        amount: this.state.amount,
        startAt: moment(this.state.startAt).utc().format("YYYY-MM-DDTHH:mm"),
        endAt: moment(this.state.endAt).utc().format("YYYY-MM-DDTHH:mm"),
        reusable: false,
        quantity: this.state.quantity,
        citiesUuid: citiesUuids
      };
      return axios
        .post(`${host}/voucher/batch`, body, config())
        .then((response) => callback())
        .catch((e) => alert(e))
        .finally(() => {
          this.cancel();
        });
    }
  };

  onCreateVoucher = () => {
    this.setState({
      openModal: true,
    });
  };

  onChangeAmount = (e) => {
    this.setState({amount: parseInt(e.target.value)});
  };
  onChangeCode = (e) => {
    this.setState({code: e.target.value});
  };
  onChangeQuantity = (e) => {
    this.setState({quantity: parseInt(e.target.value)});
  };
  onChangeReusable = (e) => {
    this.setState({reusable: e.target.value});
  };
  onChangeFromDate = (e) => {
    if (moment(e.target.value).isAfter(this.state.startAt)) {
      this.setState({startAt: e.target.value});
      this.setState({endAt: ""});
    } else {
      this.setState({
        startAt: moment(new Date()).format("YYYY-MM-DDTHH:mm"),
      });
    }
  };
  onChangeToDate = (e) => {
    if (moment(e.target.value).isAfter(this.state.startAt)) {
      this.setState({endAt: e.target.value});
    } else {
      this.setState({
        endAt: moment(this.state.startAt).add(1, "days").format("YYYY-MM-DDTHH:mm"),
      });
    }
  };

  onChangeMultiSelect = (e) => {
    let value = e.target.value;
    this.setState({cityNames: value})
  }

  // end

  render() {
    const {callback, cities} = this.props;
    return (
      <React.Fragment>
        <Tooltip title="Create Batch Vouchers">
          <IconButton
            variant="contained"
            color="primary"
            onClick={() => this.onCreateVoucher()}
          >
            <PlaylistAddIcon/>
          </IconButton>
        </Tooltip>
        <Dialog
          open={this.state.openModal}
          onClose={this.handleClose}
          aria-labelledby="User Action Modal"
          fullWidth={true}
        >
          <DialogTitle
            id="User Action Modal"
            style={{
              color: "white",
              backgroundColor: "#333399",
              textAlign: "center",
            }}
          >
            <label>Create Batch Vouchers</label>
          </DialogTitle>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <CardContent style={{paddingBottom: "0px"}}>
                {/* amount */}
                <Grid container spacing={2} className="formLabel">
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <InputLabel id="mutiple-select-label">
                        Cities
                      </InputLabel>
                      <Select
                        labelId="mutiple-select-label"
                        name="cityNames"
                        fullWidth
                        multiple
                        value={this.state.cityNames}
                        renderValue={selected => selected.join(', ')}
                        onChange={this.onChangeMultiSelect}
                      >
                        {cities.map(item => (
                          <MenuItem key={item.label} value={item.label}>
                            <Checkbox
                              color='primary'
                              value={item.label}
                              checked={this.state.cityNames.indexOf(item.label) > -1}
                            />
                            <ListItemText primary={item.label}/>
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      id="amount-id"
                      label="Amount"
                      fullWidth
                      type="number"
                      value={this.state.amount}
                      onChange={this.onChangeAmount}
                      InputProps={{
                        readOnly: false,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={2} className="formLabel">
                  <Grid item xs={12}>
                    <TextField
                      id="quantity-id"
                      label="Quantity"
                      fullWidth
                      type="number"
                      value={this.state.quantity}
                      onChange={this.onChangeQuantity}
                      InputProps={{
                        readOnly: false,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={2} className="formLabel">
                  <Grid item xs={12}>
                    <TextField
                      id="From-local"
                      label="From"
                      fullWidth
                      type="datetime-local"
                      value={this.state.startAt}
                      variant="outlined"
                      onChange={this.onChangeFromDate}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                </Grid>

                {/* endAt */}
                <Grid container spacing={2} className="formLabel">
                  <Grid item xs={12}>
                    <TextField
                      id="To-local"
                      label="To"
                      fullWidth
                      type="datetime-local"
                      value={this.state.endAt}
                      variant="outlined"
                      onChange={this.onChangeToDate}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Grid>
          </Grid>

          <DialogActions
            style={{
              justifyContent: "space-between",
              padding: "10px 40px 15px 40px",
            }}
          >
            <Button
              variant="contained"
              onClick={() => this.createVouchers(callback)}
              style={{color: "white", backgroundColor: "#333399"}}
            >
              Save
            </Button>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => this.cancel()} style={{color: grey[500]}}>
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

export default VoucherBatchCreate;
