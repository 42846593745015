import React, {Component} from "react";
import {search} from "../../../../redux/parkings/parkings.action";
import {queryParams} from "../../../../utils/utils";
import {withAuth0} from "@auth0/auth0-react";
import {connect} from "react-redux";
import MUIDataTable from "mui-datatables";
import {Button} from "@material-ui/core";
import ParkingEditModel  from "./EditParking";

class Parking extends Component {
  constructor(props) {
    super(props);
    this.tableRef = React.createRef();
    this.columnsDisplay = undefined;
  }

  componentDidMount() {
    this.refresh();
  }

  refresh = () => {
    const { search } = this.props;

    // table state
    const state = this.tableRef?.current?.state;

    const params = queryParams(this.props, state);

    search(params);
  };

  onChangeColumnDisplay = () => {
    const state = this.tableRef?.current?.state;
    this.columnsDisplay = state.columns.reduce(
      (obj, item) => Object.assign(obj, {[item.name]: item.display}),
      {}
    );
  };

  isDisplay = (name) => {
    if (!this.columnsDisplay || !this.columnsDisplay.hasOwnProperty(name)){
      return undefined;
    }
    else {
      return this.columnsDisplay[name];
    }
  };

  render() {
    const {parkings} = this.props;
    if (!parkings) return <React.Fragment/>;

    const list = parkings?.content;
    const totalElements = parkings?.totalElements ?? 0;
    const rowsPerPage = parkings?.pageable?.pageSize <101 ? parkings?.pageable?.pageSize : 10;
    const page = parkings?.pageable?.pageNumber ?? 0;

    const columns = [
      {
        name: "uuid",
        label: "Parking ID",
        options: {
          filter: true,
          sort: false,
          display: this.isDisplay("uuid") ?? true,
          customFilterListOptions: {render: (v) => `Parking ID: ${v}`},
          filterType: "textField",
        },
      },
      {
        name: "name",
        label: "Name",
        options: {
          filter: false,
          sort: false,
          display: this.isDisplay("name") ?? true,
        },
      },
      {
        name: "notification",
        label: "Note",
        options: {
          filter: false,
          sort: false,
          display: this.isDisplay("notification") ?? true,
        },
      },
      {
        name: "qrCode",
        label: "Parking No",
        options: {
          filter: false,
          sort: false,
          display: this.isDisplay("qrCode") ?? false,
        },
      },
      {
        name: "status",
        label: "Status",
        options: {
          filter: false,
          sort: false,
          display: this.isDisplay("status") ?? true,
        },
      },
      {
        name: "cityName",
        label: "City",
        options: {
          filter: false,
          sort: false,
          display: this.isDisplay("cityName") ?? true,
        },
      },
      {
        name: "cityUuid",
        label: "City ID",
        options: {
          filter: false,
          sort: false,
          display: this.isDisplay("cityUuid") ?? false,
        },
      },
      {
        name: "Operations",
        options: {
          filter: false,
          sort: false,
          empty: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div>
                <ParkingEditModel value={value} tableMeta={tableMeta} callback={() => this.refresh()}/>
              </div>
            );
          },
        },
      },
    ];

    const options = {
      filter: false,
      search: false,
      confirmFilters: false,
      serverSide: true,
      filterType: "dropdown",
      responsive: "vertical",
      rowsPerPageOptions: [10, 20, 50, 100],
      rowsPerPage: rowsPerPage,
      count: totalElements,
      page: page,
      onTableChange: (action, tableState) => {
        switch (action) {
          case "viewColumnsChange":
            this.onChangeColumnDisplay();
            break;
          case "onFilterDialogOpen":
            this.isFilterDialogOpen = true;
            break;
          case "filterChange":
            if (this.isFilterDialogOpen) return;
            else this.refresh();
            break;
          case "onFilterDialogClose":
            this.isFilterDialogOpen = false;
          case "changePage":
          case "sort":
          case "changeRowsPerPage":
            this.refresh();
            break;
          default:
            console.log("Not handled action " + action);
        }
      },
      customFilterDialogFooter: (currentFilterList, applyNewFilters) => {
        return (
          <div style={{marginTop: "40px"}}>
            <Button
              variant="contained"
              style={{color: "white", backgroundColor: "#36b9cc"}}
              onClick={() => {
                applyNewFilters();
              }}
            >
              Apply
            </Button>
          </div>
        );
      },
    };
    return (
      <div>
        <MUIDataTable
          title={"Parking List"}
          data={list}
          columns={columns}
          options={options}
          ref={this.tableRef}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  parkings: state.parkingsReducer.parkings,
});

const mapDispatchToProps = (dispatch) => ({
  search: (params) => dispatch(search(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withAuth0(Parking));
