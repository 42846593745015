import React, {Component} from "react";
import MUIDataTable from "mui-datatables";
import {withAuth0} from "@auth0/auth0-react";
import {Button, IconButton, Tooltip} from "@material-ui/core";
import {connect} from "react-redux";
import {search} from "../../../../redux/business/business.action";
import {queryParams} from "../../../../utils/utils";
import PropTypes from "prop-types";
import {Skeleton} from "@material-ui/lab";
import BusinessLogo from "./business-logo";
import SearchIcon from "@material-ui/icons/Search";
import Create from "./create";

class BusinessList extends Component {
  constructor(props) {
    super(props);
    this.isFilterDialogOpen = false;
    this.tableRef = React.createRef();
    this.columnsDisplay = undefined;
  }

  static propTypes = {
    filters: PropTypes.object,
  };

  componentDidMount() {
    if (!this.props.businesses)
      this.refresh();
  }

  refresh = () => {
    const {search, filters} = this.props;

    // table state
    const state = this.tableRef?.current?.state;

    const params = queryParams(this.props, state);

    filters?.map(f => params.set(f.key, f.value));

    search(params);
  }

  onChangeColumnDisplay = () => {
    const state = this.tableRef?.current?.state;
    this.columnsDisplay = state.columns.reduce((obj, item) => Object.assign(obj, {[item.name]: item.display}), {});
  }

  isDisplay = (name) => {
    if (!this.columnsDisplay || !this.columnsDisplay.hasOwnProperty(name))
      return undefined;
    else
      return this.columnsDisplay[name];
  }

  render() {
    const { businesses } = this.props;
    if (!businesses)
      return (<Skeleton variant="rect" width={"100%"} height={800}/>);

    const list = businesses?.content;
    const totalElements = businesses?.totalElements ?? 0;
    const rowsPerPage = businesses?.pageable?.pageSize ?? 10;
    const page = businesses?.pageable?.pageNumber ?? 0;

    const columns = [
      {
        name: "uuid",
        label: "Business ID",
        options: {
          filter: false,
          sort: false,
          display: false,
          customFilterListOptions: { render: (v) => `UUID: ${v}` },
          filterType: "textField",
        },
      },
      {
        name: "logo",
        label: "Logo",
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return <BusinessLogo logo={value} />;
          },
        },
      },
      {
        name: "businessName",
        label: "Name",
        options: {
          filter: false,
          sort: false,
          filterType: "textField",
        },
      },
      {
        name: "businessAddress",
        label: "Address",
        options: {
          filter: false,
          sort: false,
          filterType: "textField",
        },
      },
      {
        name: "businessContact",
        label: "Contact",
        options: {
          filter: false,
          sort: false,
          filterType: "textField",
        },
      },
      {
        name: "balance",
        label: "Balance",
        options: {
          filter: false,
          sort: false,
          filterType: "textField",
        },
      },
      {
        name: "usersCount",
        label: "No. of Users",
        options: {
          filter: false,
          sort: false,
          filterType: "textField",
          customBodyRender: (value, tableMeta, updateValue) => {
            return value ?? 0;
          },
        },
      },
      {
        name: "Operations",
        options: {
          filter: false,
          sort: false,
          empty: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div>
                <Tooltip title="Details">
                  <IconButton
                    onClick={() => {
                      this.props.history.push(
                        `/business/${tableMeta.rowData[0]}`
                      );
                    }}
                  >
                    <SearchIcon />
                  </IconButton>
                </Tooltip>
              </div>
            );
          },
        },
      },
    ];

    const options = {
      viewColumns: false,
      download: false,
      print: false,
      search: false,
      filter: false,
      confirmFilters: false,
      serverSide: true,
      filterType: "dropdown",
      responsive: "vertical",
      selectableRows: "none",
      rowsPerPageOptions: [10, 20, 50, 100],
      rowsPerPage: rowsPerPage,
      count: totalElements,
      page: page,
      fixedHeader: true,
      tableBodyHeight: "900px",
      onTableChange: (action, tableState) => {
        switch (action) {
          case "viewColumnsChange":
            this.onChangeColumnDisplay();
            break;
          case "onFilterDialogOpen":
            this.isFilterDialogOpen = true;
            break;
          case "filterChange":
            if (this.isFilterDialogOpen)
              return;
            else
              this.refresh();
            break;
          case "onFilterDialogClose":
            this.isFilterDialogOpen = false;
          case "changePage":
          case "sort":
          case "changeRowsPerPage":
            this.refresh();
            break;
          default:
            console.log("Not handled action " + action);
        }
      },
      customFilterDialogFooter: (currentFilterList, applyNewFilters) => {
        return (
          <div style={{marginTop: "40px"}}>
            <Button variant="contained"
                    style={{color: "white", backgroundColor: "#36b9cc"}}
                    onClick={() => {applyNewFilters()}}
            >
              Apply
            </Button>
          </div>
        );
      },
      customToolbar: () => {
        return (
          <Create refresh={this.refresh} tableProps={this.props}/>
        );
      }
    };

    return (
      <React.Fragment>
        <MUIDataTable
          title={<h1 className="tableTitle">Business List</h1>}
          data={list}
          columns={columns}
          options={options}
          ref={this.tableRef}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  businesses: state.businessReducer.businesses,
});

const mapDispatchToProps = (dispatch) => ({
  search: (params) => dispatch(search(params)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withAuth0(BusinessList));
