import {combineReducers} from 'redux';
import userReducer from './user/user.reducer'
import adminUserReducer from "./adminUser/adminUser.reducer";
import appReducer from "./app/app.reducer";
import vehicleReducer from "./vehicle/vehicle.reducer";
import taskReducer from "./task/task.reducer";
import notificationReducer from "./notificate/reducer";
import notificationTaskReducer from "./notificationTask/reducer";
import termsAndConditionsReducer from "./TermsAndConditions/reducer";
import newsReducer from "./news/reducer";
import privacyPolicyReducer from "./PrivacyPolicy/reducer";
import orderReducer from "./order/reducer";
import cityReducer from "./city/reducer";
import countryReducer from "./country/reducer";
import pricingReducer from "./pricing/reducer";
import membershipReducer from "./membership/reducer";
import voucherReducer from "./voucher/reducer";
import transactionReducer from "./transaction/transaction.reducer";
import regionReducer from "./region/reducer";
import DashboardReducer from "./dashboard/dashboard.reducer";
import regionRuleReducer from "./RegionRule/regionRule.reducer";
import taskRuleReducer from "./TaskRule/taskRule.reducer";
import businessReducer from "./business/business.reducer";
import batteryReducer from "./battery/battery.reducer";
import groundTeamUserReducer from "./groundTeamUser/groundTeamUser.reducer";
import shoppingSparePartsReducer from "./shoppingSpareParts/reducer"
import roleReducer from "./role/role.reducer"
import parkingsReducer from "./parkings/parkings.reducer";
import topUpTemplate from "./topUpTemplate/topUpTemplate.reducer";
import partnersReducer from "./partners/reducer";
import alertReducer from "./alert/alert.reducer";
import consoleEventLogReducer from './consoleEventLog/consoleEventLog.reducer';
import groundTeamEventLogReducer from './groundTeamEventLog/groundTeamEventLog.reducer';
import placesReducer from "./places/reducer";
import zendeskTicketsReducer from "./zendeskTicket/reducer";
import membershipAccountReducer from "./membershipAccount/reducer";
import partnerSettingReducer from "./partnerSetting/reducer";


export default combineReducers({
  usersData: userReducer,
  adminUsersData: adminUserReducer,
  appData: appReducer,
  vehicleData: vehicleReducer,
  taskData: taskReducer,
  termsAndConditionsReducer: termsAndConditionsReducer,
  privacyPolicyReducer: privacyPolicyReducer,
  notificationReducer: notificationReducer,
  notificationTaskReducer: notificationTaskReducer,
  newsReducer: newsReducer,
  orderReducer: orderReducer,
  cityReducer: cityReducer,
  countryReducer: countryReducer,
  regionRuleReducer: regionRuleReducer,
  pricingReducer: pricingReducer,
  membershipReducer: membershipReducer,
  voucherReducer: voucherReducer,
  transactionReducer: transactionReducer,
  regionReducer: regionReducer,
  taskRuleReducer: taskRuleReducer,
  dashboardReducer: DashboardReducer,
  businessReducer: businessReducer,
  batteryReducer: batteryReducer,
  groundTeamUserReducer: groundTeamUserReducer,
  shoppingSparePartsReducer: shoppingSparePartsReducer,
  roleReducer: roleReducer,
  parkingsReducer: parkingsReducer,
  placesReducer: placesReducer,
  topUpTemplateReducer: topUpTemplate,
  partnerReducer: partnersReducer,
  partnerSettingReducer: partnerSettingReducer,
  alertReducer: alertReducer,
  consoleEventLogReducer: consoleEventLogReducer,
  groundTeamEventLogReducer: groundTeamEventLogReducer,
  zendeskTicketsReducer: zendeskTicketsReducer,
  membershipAccountReducer: membershipAccountReducer
});
