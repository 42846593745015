import React from "react";
import PropTypes from 'prop-types';
import TextField from "@material-ui/core/TextField";
import {Grid} from "@material-ui/core";
import CardContent from "@material-ui/core/CardContent";
import Input from "../../../../components/common/Input";

class TopUpTemplateOption extends React.Component {

  static propTypes = {
    header: PropTypes.string.isRequired,
    uuid: PropTypes.string.isRequired,
    topUpAmount: PropTypes.number,
    bonusAmount: PropTypes.number,
    onChange: PropTypes.func,
  };

  render() {
    return (
      <Grid item sm={6}>
        <div className="col-xl col-lg ">
          <div className="card shadow mb-4" style={{width: '100%'}}>
            <div className="card-header-one py-3 d-flex flex-row align-items-center justify-content-between">
              <h6 className="m-0 font-weight-bold" style={{color: '#FFFFFF'}}>{this.props.header}</h6>
            </div>
            <div className="card-body">
              <Grid container>
                <Grid item sm={12}>
                  <CardContent style={{padding: "0px"}}>
                    <Grid container spacing={2} className="formLabel">
                      <Grid item sm={6} >
                        <Input id="topUpAmount"
                               label="TopUp Amount"
                               type="number"
                               value={this.props.topUpAmount ?? 0}
                               onChange={(id, value) => this.props.onChange(this.props.uuid, "topUpAmount", value)}
                        />
                      </Grid>

                      <Grid item sm={6}>
                        <Input id="bonusAmount"
                               label="Bonus Amount"
                               type="number"
                               value={this.props.bonusAmount ?? 0}
                               onChange={(id, value) => this.props.onChange(this.props.uuid, "bonusAmount", value)}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </Grid>
    );
  }
}

export default TopUpTemplateOption;