import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";
import { grey} from "@material-ui/core/colors/";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import axios from "axios";
import { getAccessToken } from "../../../../auth/auth";
import { connect } from "react-redux";
import { withAuth0 } from "@auth0/auth0-react";
import {
  Button,
  Dialog,
  DialogActions, DialogContent,
  DialogTitle,
  Grid
} from "@material-ui/core";
import CardContent from "@material-ui/core/CardContent";
import PaymentIcon from "@material-ui/icons/Payment";
import MoneyOffIcon from "@material-ui/icons/MoneyOff";
import NotificationsIcon from "@material-ui/icons/Notifications";
import LockOpenIcon from "@material-ui/icons/LockOpen";

const host = process.env.REACT_APP_HOST;

const defaultToolbarSelectStyles = {
  iconButton: {
  },
  iconContainer: {
    marginRight: "24px",
  },
  inverseIcon: {
    transform: "rotate(90deg)",
  },
};

class CustomToolbarSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      amount: 0,
      content:"",
      subject:"",
      status:'ACTIVE',
      openStatusDialog: false
    };
    this.selectedAction = undefined;
    this.requestArray = [];
  }
  componentDidMount() {
  }

  // change user Information TOPup, penality, notification
  handleClickAction = (clickedAction) => {
    this.setState({
      open: true
    });
    this.clickedAction = clickedAction;
  }
  confirmAction = () => {
    this.requestArray = [];
    const { users, selectedRows } = this.props;
    const usersData = users?.content;
    selectedRows.data.map((row, index)=> {
      const rowData = usersData[row.index];
      this.applyAction(rowData, index);
    })
    this.confirmSelectedAction();
  }
  applyAction = (rowData, index) => {
      const accessToken =  getAccessToken();
      const Config_Headers ={
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
       }
      let body = {
        amount: this.state.amount,
        userUuid: rowData.uuid
      };
      let API_URL = "";
      let API_METHOD = 'post';
      switch (this.clickedAction) {
        case "TopUp":
          API_URL = `${host}/payment/topup`;
          break;
        case "penality":
          API_URL = `${host}/payment/penality`;
          break;
        case "status":
          API_URL = `${host}/user/${rowData.uuid}`;
          body = {
            userStatus: this.state.status
          };
          API_METHOD = 'put';
          break;
        case "Notification":
          Config_Headers['Content-Type'] = 'text/plain';
          API_URL = `${host}/user/${rowData.uuid}/sendMessage?title=${this.state.subject}`;
          body = this.state.content;
          break;
        default:
          break;
      }
      const axiosRequest = axios({
        method: API_METHOD,
        url: API_URL,
        data: body,
        headers: Config_Headers
      })
      this.requestArray.push(axiosRequest);
    }
    confirmSelectedAction = () =>{
      const { callback} = this.props;
      axios.all(this.requestArray)
      .then(responseArr => {
      }).catch((e) => alert(e))
        .finally(() => {
          this.setState({
            open: false, 
            amount: 0,
            content:"",
            subject:"",
            status:'ACTIVE',
          });
          this.selectedAction = undefined;
          this.requestArray = [];
          callback();
        });
    }
    // end

    // state change variables
    onChangeAmount = (e) => {
      this.setState({ amount: e.target.value });
    };
    onChangeSubject = (e) => {
      this.setState({ subject: e.target.value });
    };
    onChangeContent = (e) => {
      this.setState({ content: e.target.value });
    };
    onChangeStatus = (e) => {
      this.setState({ status: e.target.value });
    };
    // end


  // Close Confirmation Modal
  cancel = () => {
    this.setState({open: false})
  }
  // end

  render() {
    const { classes, callback } = this.props;
    let actionHeading = "";
    if (this.clickedAction === "TopUp") {
      actionHeading = "Top Up the Users";
    } else if (this.clickedAction === "penalty") {
      actionHeading = "Penalise the Users";
    } else if (this.clickedAction === "Notification") {
      actionHeading = "Notify the Users";
    }  else if (this.clickedAction === "status") {
      actionHeading = "User status update";
    }
    return (
      <div className={classes.iconContainer}>
        <Tooltip title={"Top Up"}>
            <IconButton className={classes.iconButton} onClick={()=> {this.handleClickAction('TopUp')}}>
                  <PaymentIcon className={classes.icon} />
            </IconButton>
        </Tooltip>
        <Tooltip title={"Penality"}>
            <IconButton className={classes.iconButton} onClick={()=> {this.handleClickAction('penality')}}>
                  <MoneyOffIcon className={classes.icon}/>
            </IconButton>
        </Tooltip>
        <Tooltip title={"Notification"}>
          <IconButton className={classes.iconButton} onClick={()=> {this.handleClickAction('Notification')}}>
                  <NotificationsIcon className={classes.icon} />
          </IconButton>
        </Tooltip>
        <Tooltip title={"Make User active"}>
            <IconButton className={classes.iconButton} onClick={()=>{
              this.handleClickAction('status')}
              }>
                 <LockOpenIcon className={classes.icon} />
                  
            </IconButton>
        </Tooltip>
        <Dialog
              open={this.state.open}
              onClose={() => this.cancel()}
              aria-labelledby="User Action Modal"
              fullWidth={true}
            >
              <DialogTitle
                id="User Action Modal"
                style={{
                  color: "white",
                  backgroundColor: "#36b9cc",
                  textAlign: "center",
                }}
              >
                {actionHeading}
              </DialogTitle>
              <DialogContent>
                <Grid container spacing={2}>
                  <Grid item sm={12} xs={12} md={12} lg={12}>
                    <CardContent style={{paddingBottom:'0px'}}>
                      <Grid container spacing={2} className="formLabel"
                       style={{
                        display:
                        this.clickedAction === "TopUp" || this.clickedAction === "penality" && this.clickedAction !== "status"
                            ? "block"
                            : "none",
                      }}
                      >
                        <Grid
                          item
                          sm={12} xs={12} md={12} lg={12}
                         >
                          <TextField
                            id="amount-id"
                            label="Amount"
                            fullWidth
                            type="number"
                            value={this.state.amount}
                            onChange={this.onChangeAmount}
                            InputProps={{
                              readOnly: false,
                            }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            variant="outlined"
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={2} className="formLabel"  style={{
                            display:
                            this.clickedAction !== "TopUp" && this.clickedAction !== "penality" && this.clickedAction !== "status"
                                ? "block"
                                : "none",
                          }}>
                        <Grid
                          item
                          sm={12} xs={12} md={12} lg={12}
                          >
                          <TextField
                            id="subject-id"
                            label="Subject"
                            fullWidth
                            value={this.state.subject}
                            onChange={this.onChangeSubject}
                            InputProps={{
                              readOnly: false,
                            }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            variant="outlined"
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={2} className="formLabel"    style={{
                            display:
                            this.clickedAction !== "TopUp" && this.clickedAction !== "penality" && this.clickedAction !== "status"
                                ? "block"
                                : "none",
                          }}>
                        <Grid
                          item
                          sm={12} xs={12} md={12} lg={12}
                       
                        >
                          <TextField
                            id="content-id"
                            label="Content"
                            fullWidth
                            value={this.state.content}
                            InputProps={{
                              readOnly: false,
                            }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            variant="outlined"
                            onChange={this.onChangeContent}
                          />
                        </Grid>
                      </Grid>

                      <Grid container spacing={2} className="formLabel"
                       style={{
                        display:
                        this.clickedAction !== "TopUp" && this.clickedAction !== "penality" && this.clickedAction === "status"
                            ? "block"
                            : "none",
                           }}
                        >
                        <Grid item sm={12} xs={12} md={12} lg={12}>
                          <TextField
                            id="userStatus"
                            select
                            label="Status"
                            value={this.state.status}
                            onChange={this.onChangeStatus}
                            InputProps={{
                              readOnly: false,
                            }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            variant="outlined"
                            fullWidth
                          >
                            <MenuItem key="ACTIVE" value="ACTIVE">
                              Active
                            </MenuItem>
                            <MenuItem key="INACTIVE" value="INACTIVE">
                              Inactive
                            </MenuItem>
                            <MenuItem key="FRAUD" value="FRAUD">
                              Fraud
                            </MenuItem>
                          </TextField>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions
                style={{
                  justifyContent: "space-between",
                  padding: "10px 40px 15px 40px",
                  }}
                >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => this.confirmAction(callback)}
                >
                  confirm
                </Button>
                <Button onClick={() => this.cancel()} style={{ color: grey[500] }}>
                  Cancel
                </Button>
              </DialogActions>
            </Dialog>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  users: state.usersData.users,
});

export default connect(
  mapStateToProps,
  null
)(withAuth0(withStyles(defaultToolbarSelectStyles, { name: "CustomToolbarSelect" })(CustomToolbarSelect)));