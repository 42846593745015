import React from "react";
import PropTypes from 'prop-types';
import {search} from "../../redux/city/action";
import {connect} from "react-redux";
import {withAuth0} from "@auth0/auth0-react";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";

class CitySelector extends React.Component {
  static propTypes = {
    cityUuid: PropTypes.string,
    onChangedCity: PropTypes.func.isRequired
  };

  componentDidMount() {
    if (!this.props?.cities) {
      let params = new URLSearchParams();
      params.set("page", "0");
      params.set("size", "1000");
      this.props?.search();
    }
  }

  render() {
    const { cities, cityUuid, onChangedCity } = this.props;
    if (!cities)
      return (<React.Fragment>Loading...</React.Fragment>);

    var value;
    if (cityUuid) {
      value = cityUuid;
    }else {
      value = cities.content[0].uuid;
    }

    return (
      <TextField
        id="cityUuid"
        select
        label="City"
        value={value}
        onChange={(e) => {onChangedCity(e.target.value)}}
        inputProps={{
          readOnly: false,
        }}
        fullWidth
        variant="outlined"
        style={{ marginTop: "15px" }}
      >
        {cities?.content.map((option) => (
          <MenuItem key={option.uuid} value={option.uuid}>
            {option.cityName}
          </MenuItem>
        ))}
      </TextField>
    );
  }
}

const mapStateToProps = (state) => ({
  cities: state.cityReducer.cities
});

const mapDispatchToProps = (dispatch) => ({
  search: (params) => dispatch(search(params))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withAuth0(CitySelector));