import React, {Component} from "react";
import MUIDataTable from "mui-datatables";
import {withAuth0} from "@auth0/auth0-react";
import {Avatar, Button, FormControl, InputLabel, MenuItem, Select, Tooltip} from "@material-ui/core";
import {connect} from "react-redux";
import {search} from "../../../../redux/membershipAccount/action";
import {queryParams, sub} from "../../../../utils/utils";
import moment from "moment";
import MembershipAccountExtend from "./MembershipAccountExtend";
import CardHeader from "@material-ui/core/CardHeader";
import MembershipAcoountRefund from "./MembershipAcoountRefund";
import AssignMembership from "./AssignMembership";
import {search as getCities} from "../../../../redux/city/action";

const date_format = process.env.REACT_APP_DATE_FORMAT;
const datetime_format = process.env.REACT_APP_DATETIME_FORMAT;

class MembershipAccountList extends Component {
  constructor(props) {
    super(props);
    this.isFilterDialogOpen = false;
    this.tableRef = React.createRef();
    this.columnsDisplay = undefined;
  }

  componentDidMount() {
    const {getCities} = this.props;
    getCities();
    this.refresh();
  }

  refresh = () => {
    const {search, filters} = this.props;

    // table state
    const state = this.tableRef?.current?.state;
    const params = queryParams(this.props, state);
    filters?.map((f) => params.set(f.key, f.value));

    params.delete("username");
    if (state && state["columns"]) {
      // Membership Plan name
      const nameIndex = state["columns"].findIndex(s => s.name === 'membershipName');
      const nameValue = state["filterList"][nameIndex];
      if (nameValue?.length !== 0) {
        params.set("membershipName", nameValue);
      }
      // UserUuid
      const userUuidIndex = state["columns"].findIndex(s => s.name === 'username');
      const userUuidValue = state["filterList"][userUuidIndex];
      if (userUuidValue?.length !== 0) {
        params.set("userUuid", userUuidValue);
      }
    }

    search(params);
  };

  onChangeColumnDisplay = () => {
    const state = this.tableRef?.current?.state;
    this.columnsDisplay = state.columns.reduce(
      (obj, item) => Object.assign(obj, {[item.name]: item.display}),
      {}
    );
  };

  isDisplay = (name) => {
    if (!this.columnsDisplay || !this.columnsDisplay.hasOwnProperty(name))
      return undefined;
    else return this.columnsDisplay[name];
  };

  render() {
    const {membershipAccounts, history, cities} = this.props;
    if (!membershipAccounts) return <React.Fragment>Loading...</React.Fragment>;

    const list = membershipAccounts?.content;
    const totalElements = membershipAccounts?.totalElements ?? 0;
    const rowsPerPage = membershipAccounts?.pageable?.pageSize ?? 10;
    const page = membershipAccounts?.pageable?.pageNumber ?? 0;

    const columns = [
      {
        name: "uuid",
        label: "UUID",
        options: {
          filter: false,
          sort: false,
          display: this.isDisplay("uuid") ?? true,
          customFilterListOptions: {render: (v) => `UUID: ${v}`},
          filterType: "textField",
          customBodyRender: (value) => {
            return (
              <Tooltip title={value}>
                <div>{value.substring(0, 6)}</div>
              </Tooltip>
            );
          },
        },
      },
      {
        name: "userUuid",
        label: "User UUID",
        options: {
          filter: false,
          sort: false,
          display: this.isDisplay("userUuid") ?? false
        }
      },
      {
        name: "username",
        label: "User UUID",
        options: {
          filter: true,
          sort: false,
          display: this.isDisplay("userName") ?? true,
          filterType: "textField",
          customBodyRender: (value, tableMeta) => {
            return value ? (
              <CardHeader
                avatar={
                  <Avatar aria-label="user" alt="User Photo">
                    {`${value?.charAt(0)
                      .toUpperCase()}${value?.charAt(5)
                      .toUpperCase()}`}
                  </Avatar>
                }
                title={sub(`${value}`)}
                style={{cursor: "pointer", padding: 0}}
                onClick={() => history.push(`/user/${tableMeta.rowData[1]}`)}
              />
            ) : (
              ""
            );
          },
        },
      },
      {
        name: "membershipName",
        label: "Membership Plan",
        options: {
          filter: true,
          sort: false,
          display: this.isDisplay("membershipName") ?? true,
          customFilterListOptions: {render: (v) => `Membership Plan: ${v}`},
          filterType: "textField",
          filterOptions: {
            fullWidth: true,
          }
        },
      },
      {
        name: "startAt",
        label: "Start Date",
        options: {
          filter: false,
          sort: false,
          display: this.isDisplay("startAt") ?? true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <Tooltip title={value ? moment(value).format(datetime_format) : ""}>
                <div>{value ? moment(value).format(date_format) : ""}</div>
              </Tooltip>
            );
          },
        },
      },
      {
        name: "endAt",
        label: "End Date",
        options: {
          filter: false,
          sort: false,
          display: this.isDisplay("endAt") ?? true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <Tooltip title={value ? moment(value).format(datetime_format) : ""}>
                <div>{value ? moment(value).format(date_format) : ""}</div>
              </Tooltip>
            );
          },
        },
      },
      {
        name: "status",
        label: "Status",
        options: {
          filter: true,
          sort: false,
          display: this.isDisplay("status") ?? true,
          filterType: "custom",
          customFilterListOptions: {render: (v) => `Status: ${v}`},
          filterOptions: {
            fullWidth: true,
            display: (filterList, onChange, index, column) => {
              return (
                <FormControl>
                  <InputLabel htmlFor='select-multiple-chip'>
                    Status
                  </InputLabel>
                  <Select
                    value={filterList[index][0] ?? ""}
                    onChange={event => {
                      filterList[index] = [event.target.value];
                      onChange(filterList[index], index, column);
                    }}
                  >
                    <MenuItem key={"ACTIVE"} value={"ACTIVE"}>ACTIVE</MenuItem>
                    <MenuItem key={"INACTIVE"} value={"INACTIVE"}>EXPIRED</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              );
            }
          }
        }
      },
      {
        name: "currentUsageDay",
        label: "Current Usage Day",
        options: {
          filter: false,
          sort: false,
          display: this.isDisplay("currentUsageDay") ?? false
        }
      },
      {
        name: "currentUsageTotal",
        label: "Current Usage Total",
        options: {
          filter: false,
          sort: false,
          display: this.isDisplay("currentUsageTotal") ?? false
        }
      },
      {
        name: "dailyLimitMinutes",
        label: "Daily Usage",
        options: {
          filter: false,
          sort: false,
          display: this.isDisplay("dailyLimitMinutes") ?? true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <Tooltip>
                <div>{tableMeta.rowData[7]}/{value} Minutes</div>
              </Tooltip>
            );
          },
        },
      },
      {
        name: "totalLimitMinutes",
        label: "Total Usage",
        options: {
          filter: false,
          sort: false,
          display: this.isDisplay("totalLimitMinutes") ?? true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <Tooltip>
                <div>{tableMeta.rowData[8]}/{value} Minutes</div>
              </Tooltip>
            );
          },
        },
      },
      {
        name: "cityUuid",
        label: "City ID",
        options: {
          filter: true,
          filterType: "custom",
          sort: false,
          display: false,
          customFilterListOptions: {
            render: (v) => {
              const city = this.props?.cities?.find(c => c.uuid === v[0]);
              return city && `City: ${city?.cityName},${city?.country}`;
            }
          },
          filterOptions: {
            display: (filterList, onChange, index, column) => {
              return (
                <FormControl>
                  <InputLabel htmlFor='select-multiple-chip'>
                    City
                  </InputLabel>
                  <Select
                    value={filterList[index][0] ?? ""}
                    onChange={event => {
                      filterList[index] = [event.target.value];
                      onChange(filterList[index], index, column);
                    }}
                  >
                    {(cities || []).map((city, index) => (
                      <MenuItem key={index} value={city.uuid}>
                        {city.cityName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              );
            }
          }
        }
      },
      {
        name: "operations",
        label: "Operations",
        options: {
          filter: false,
          sort: false,
          empty: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div>
                <MembershipAccountExtend callback={this.refresh} uuid={value}/>
                <MembershipAcoountRefund
                  uuid={tableMeta.rowData[0]}
                  refresh={() => this.refresh()}
                  status={tableMeta.rowData[6]}
                />
              </div>
            );
          },
        },
      },
    ];

    const options = {
      filter: true,
      confirmFilters: false,
      serverSide: true,
      filterType: "dropdown",
      responsive: "vertical",
      rowsPerPageOptions: [10, 20, 50, 100],
      rowsPerPage: rowsPerPage,
      count: totalElements,
      page: page,
      search: false,
      fixedHeader: true,
      tableBodyHeight: "900px",
      onTableChange: (action, tableState) => {
        switch (action) {
          case "viewColumnsChange":
            this.onChangeColumnDisplay();
            break;
          case "onFilterDialogOpen":
            this.isFilterDialogOpen = true;
            break;
          case "filterChange":
            if (this.isFilterDialogOpen) return;
            else this.refresh();
            break;
          case "onFilterDialogClose":
            this.isFilterDialogOpen = false;
          case "changePage":
          case "sort":
          case "changeRowsPerPage":
            this.refresh();
            break;
          default:
            console.log("Not handled action " + action);
        }
      },
      customFilterDialogFooter: (currentFilterList, applyNewFilters) => {
        return (
          <div style={{marginTop: "40px"}}>
            <Button
              variant="contained"
              style={{color: "white", backgroundColor: "#333399"}}
              onClick={() => {
                applyNewFilters();
              }}
            >
              Apply
            </Button>
          </div>
        );
      },
      customToolbar: () => {
        return (
          <AssignMembership callback={() => this.refresh()}/>
        );
      }
    };

    return (
      <React.Fragment>
        <MUIDataTable
          title={<h1 className="tableTitle">Membership Account List</h1>}
          data={list}
          columns={columns}
          options={options}
          ref={this.tableRef}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  membershipAccounts: state.membershipAccountReducer.membershipAccounts,
  cities: state.cityReducer.cities?.content,
});

const mapDispatchToProps = (dispatch) => ({
  search: (params) => dispatch(search(params)),
  getCities: (params) => dispatch(getCities(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withAuth0(MembershipAccountList));
