import React from "react";
import {Popup} from "react-leaflet";
import PropTypes from "prop-types";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import {Button, Tooltip} from "@material-ui/core";
import CardActions from "@material-ui/core/CardActions";
import IconButton from "@material-ui/core/IconButton";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import { green, red } from "@material-ui/core/colors/";
import axios from "axios";
import {config} from "../../../auth/auth";
import EditIcon from '@material-ui/icons/Edit';
import ParkingEditModel from "../../../pages/SystemConfiguration/GeofencingSettings/Parking/EditParking";
import ParkingMapEditModel from "../../../pages/SystemConfiguration/GeofencingSettings/Parking/EditParkingOnMap";

const host = process.env.REACT_APP_HOST

class ParkingPopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openPopUp: false,
      lat: "",
      lng: "",
      parkingId: "",
      qrCode: ""
    };
    this.editableLayers = undefined;
    this.map = undefined;
  }
  static propTypes = {
    parking: PropTypes.object.isRequired,
  };

  handleModalCloseChange = () => {
    this.setState({
      openPopUp: false,
      lat: "",
      lng: "",
      parkingId: "",
    });
  };
  markParkingStatus(status) {
    const { parking } = this.props
    const body = {
      cityUuid: parking.cityUuid,
      notification: parking.notification,
      name: parking.name,
      discount: parking.discount,
      radius: parking.radius,
      status: status,
      capacity: parking.capacity
    };
    return axios
      .put(`${host}/parking/${parking.uuid}`, body, config())
      .then((response) => {
        this.props.callback();
      })
      .catch((e) => alert(e))
      .finally(() => {});
  }
  deleteParking() {
    return axios
      .delete(`${host}/parking/${this.props.parking.uuid}`, config())
      .then((response) => {
        this.props.callback();
      })
      .catch((e) => alert(e))
      .finally(() => {});
  }

  render() {
    const { parking } = this.props;

    return (
      <Popup>
        <Card style={{ maxWidth: 445, width: "100%", minWidth: "250px" }}>
          <CardContent>
            <Typography variant="body2" color="textSecondary" component="p">
              <b>Parking Id: </b>{parking?.uuid}
              <br />
              <b>QR Code: </b> {parking?.qrCode}
              <br />
              <b>Status: </b> {parking?.status ? `${parking?.status}` : ""}
              <br />
              <b>Parking Name: </b> {parking?.name}
              <br />
              <b>Note: </b>{parking?.notification ? `${parking?.notification}` : ""}
              <br />
              <b>City: </b>{parking?.cityName ? `${parking?.cityName}` : ""}
              <br />
            </Typography>
            <CardActions disableSpacing style={{ justifyContent: 'center' }}>
              <ParkingMapEditModel parking={parking} callback={() => this.props.callback()} />
              <Tooltip title={"Mark as Active"} onClick={() => this.markParkingStatus("ACTIVE")}>
                <IconButton>
                  <CheckCircleIcon style={{ color: green[400] }} />
                </IconButton>
              </Tooltip>
              <Tooltip title={"Mark as Inactive"} onClick={() => this.markParkingStatus("INACTIVE")}>
                <IconButton>
                  <CancelIcon style={{ color: red[900] }} />
                </IconButton>
              </Tooltip>
              <Tooltip title={"Delete the parking space"} onClick={() => this.deleteParking()}>
                <IconButton>
                  <img
                    aria-label="cancel"
                    style={{ width: 20, height: 20, margin: 2 }}
                    alt="cancel"
                    src={"/icon/cancel.svg"}
                  />
                </IconButton>
              </Tooltip>
            </CardActions>
          </CardContent>
        </Card>
      </Popup>
    );
  }
}

export default ParkingPopup;
