import {ParkingActionTypes} from "./parkings.types";
import axios from "axios";
import {config, getAccessToken} from "../../auth/auth";

const host = process.env.REACT_APP_HOST

export const search  = (queryParams) =>{
  const config = {
    headers: {
      'Authorization': `Bearer ${getAccessToken()}`,
      'Content-Type': 'application/json'
    },
    params: queryParams
  }

  return async dispatch => {
    return axios.get(`${host}/parking`, config)
      .then(
        response => dispatch(setParkings(response.data)),
        err => console.log(err)
      );
  }
}

export const getParkingByUuid  = (uuid) => {
  return async dispatch => {
    return axios.get(`${host}/parking/${uuid}`, config())
      .then(
        response => dispatch(setParking(response.data)),
        err => console.log(err)
      );
  }
}

export const setParkings = (payload) => ({
  type: ParkingActionTypes.SET_PARKINGS,
  payload: payload,
});
export const setParking = (parking) => ({
  type: ParkingActionTypes.SET_PARKING,
  payload: parking,
});